import { api } from "@api/api";
import { Currency } from "@lib/enums/generic";

export interface GetCurrencyRes {
  currency: Currency;
}

const getCurrency = async () => {
  const { data } = await api.get<GetCurrencyRes>("/fetchCurrency");
  return data;
};

export default getCurrency;
