import DataCheck from "@components/common/DataCheck";
import React, { useState } from "react";

export default function ReportIssue() {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div id="report-issue-page">
      <div className="google-form-wrapper">
        <DataCheck isLoading={isLoading} loadingIndicator="spinner" />
        <iframe
          className="google-form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfYsMXHBIEpKVaeCYCer2zhBhsBeatCvYfGHtqDOrtBojadHw/viewform?embedded=true"
          title="Report an Issue Form"
          frameBorder={0}
          marginHeight={0}
          marginWidth={0}
          onLoad={() => setIsLoading(false)}>
          Loading…
        </iframe>
      </div>
    </div>
  );
}
