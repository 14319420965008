import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import Button from "@components/form/Button";
import { IoArrowForward } from "react-icons/io5";
import useRegisterContext from "../context/useRegisterContext";
import RegisterResendCodeBar from "../RegisterResendCodeBar";

export default function EmailConfirmStep() {
  const { values, validations, onChange, onBlur, emailConfirmIsLoading } = useRegisterContext();

  return (
    <>
      <div>
        <div className="form-box">
          <p className="text">
            We have sent a confirmation code to{" "}
            <a href={`mailto:${values.email}`}>{values.email}</a>.
          </p>
          <p className="text">Be sure to check your spam folder.</p>
        </div>
        <RegisterResendCodeBar
          type="confirmation"
          email={values.email}
          disabled={emailConfirmIsLoading}
        />
      </div>

      <FormRow className="email-confirm-code-form-row">
        <FormEntry
          label="CONFIRMATION CODE"
          validation={validations.emailConfirmCode}
          showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            name="emailConfirmCode"
            placeholder="******"
            type="number"
            value={values.emailConfirmCode}
            onChange={onChange}
            onBlur={onBlur}
            disabled={emailConfirmIsLoading}
          />
        </FormEntry>
      </FormRow>

      <div className="auth-button-row">
        <Button
          size="large"
          color="gray-1"
          textColor="white"
          type="submit"
          to="/"
          disabled={emailConfirmIsLoading}>
          Cancel
        </Button>
        <Button
          size="large"
          color="purple"
          textColor="white"
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={emailConfirmIsLoading}>
          Confirm
        </Button>
      </div>
    </>
  );
}
