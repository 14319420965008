import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import cigJoinSession, { CIGJoinSessionRes } from "../cigJoinSession";

const useCIGJoinSession = (availabilityID?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CIGJoinSessionRes | null>(
    null,
  );

  const send = useCallback(async () => {
    if (availabilityID == null) return reqState;
    setLoading();
    try {
      const data = await cigJoinSession(availabilityID);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [availabilityID, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [availabilityID, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useCIGJoinSession;
