import { parseTime } from "@lib/utils/generic";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";

const useTimeLeft = (to: Dayjs, from?: Dayjs) => {
  const calcTimeLeft = useCallback(() => dayjs(to).diff(dayjs(from), "seconds"), [from, to]);

  const [timeLeft, setTimeLeft] = useState(calcTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calcTimeLeft());
    }, 500);
    return () => clearInterval(interval);
  }, [calcTimeLeft]);

  return { timeLeft, ...parseTime(timeLeft) };
};

export default useTimeLeft;
