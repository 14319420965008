import useForceRerender from "@hooks/useForceRerender";
import { getAudioInputDevices } from "@lib/utils/generic";
import { useEffect, useState } from "react";

const useAudioInputDevices = (): [MediaDeviceInfo[], () => void] => {
  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);

  const [number, rerender] = useForceRerender();

  const get = async () => setAudioInputDevices(await getAudioInputDevices());

  useEffect(() => {
    get();
  }, [number]);

  return [audioInputDevices, rerender];
};

export default useAudioInputDevices;
