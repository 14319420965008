import { FormElement } from "@components/form/Form";
import ToggleSwitch, { ToggleSwitchProps } from "@components/form/ToggleSwitch";
import { UIColor } from "@lib/types/generic";
import { useState } from "react";
import { Group, Showcase } from "./StyleGuide";

const ToggleSwitches = () => {
  const sizes = [
    "extra-small",
    "small",
    "medium",
    "large",
    "extra-large",
  ] as ToggleSwitchProps["size"][];

  const [value, setValue] = useState(false);
  const [color, setColor] = useState<UIColor>("purple");
  const [textColor, setTextColor] = useState<UIColor>("white");
  const [thumbColor, setThumbColor] = useState<UIColor>("white");
  const [variant, setVariant] = useState<ToggleSwitchProps["variant"]>("contained");
  const [disabled, setDisabled] = useState<boolean>(false);

  const props = {
    value,
    onChange: ({ value }: FormElement) => setValue(value),
    color,
    textColor,
    thumbColor,
    variant,
    label: "Toggle",
    disabled,
  };

  return (
    <Showcase
      title="Toggle Switches"
      className="toggle-switched"
      colors={[
        {
          color: color,
          label: "Color",
          onChange: setColor,
        },
        {
          color: textColor,
          label: "Text Color",
          onChange: setTextColor,
        },
        {
          color: thumbColor,
          label: "Thumb Color",
          onChange: setThumbColor,
        },
      ]}
      selects={[
        {
          value: variant ?? "contained",
          optionsArray: ["contained", "outlined", "flat", "transparent"],
          label: "Variant",
          onChange: setVariant,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
      ]}>
      {sizes.map(size => (
        <Group key={size}>
          <ToggleSwitch size={size} {...props} />
        </Group>
      ))}
    </Showcase>
  );
};

export default ToggleSwitches;
