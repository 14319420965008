import {
  RequestAvailabilityDay,
  RequestAvailableTime,
} from "@api/private/update/updateExperienceCustom";
import Divider from "@components/common/Divider";
import Button from "@components/form/Button";
import { FormElement } from "@components/form/Form";
import FormGroup from "@components/form/FormGroup";
import IconButton from "@components/form/IconButton";
import TimePicker from "@components/form/TimePicker";
import ToggleSwitch from "@components/form/ToggleSwitch";
import dayjs from "dayjs";
import React, { forwardRef } from "react";
import { IoClose } from "react-icons/io5";

const dayNames = {
  [RequestAvailabilityDay.Monday]: "Monday",
  [RequestAvailabilityDay.Tuesday]: "Tuesday",
  [RequestAvailabilityDay.Wednesday]: "Wednesday",
  [RequestAvailabilityDay.Thursday]: "Thursday",
  [RequestAvailabilityDay.Friday]: "Friday",
  [RequestAvailabilityDay.Saturday]: "Saturday",
  [RequestAvailabilityDay.Sunday]: "Sunday",
};

interface Props {
  name?: string;
  value: RequestAvailableTime[];
  disabled?: boolean;

  onChange?: (target: FormElement<RequestAvailableTime[]>) => void;
}

const availabilityMatches = (
  availability1: RequestAvailableTime,
  availability2: RequestAvailableTime,
) =>
  availability1.day === availability2.day &&
  availability1.start === availability2.start &&
  availability1.end === availability2.end;

const ExperiencesManageAvailabilitySelector = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { name = "", value, disabled, onChange = () => {} } = props;

  const add = (availability: RequestAvailableTime) => {
    onChange({
      name,
      value: [...value, availability],
    });
  };

  const remove = (i: number) => {
    onChange({
      name,
      value: [...value.slice(0, i), ...value.slice(i + 1)],
    });
  };

  const change = (i: number, availability: RequestAvailableTime) => {
    onChange({
      name,
      value: [...value.slice(0, i), availability, ...value.slice(i + 1)],
    });
  };

  const removeDay = (day: RequestAvailabilityDay) => {
    const newValue = [...value];
    for (const availability of value) {
      if (day !== availability.day) continue;

      const i = newValue.findIndex(currAvailability =>
        availabilityMatches(availability, currAvailability),
      );
      if (i < 0) continue;
      newValue.splice(i, 1);
    }

    onChange({
      name,
      value: newValue,
    });
  };

  return (
    <div className="experiences-manage-availability-selector" ref={ref}>
      {Object.keys(dayNames).map((day, i) => {
        return (
          <Group
            key={i}
            value={value}
            disabled={disabled}
            groupDay={day as RequestAvailabilityDay}
            onAdd={add}
            onRemove={remove}
            onChange={change}
            onRemoveDay={removeDay}
          />
        );
      })}
    </div>
  );
});

export default ExperiencesManageAvailabilitySelector;

interface GroupProps {
  value: RequestAvailableTime[];
  disabled?: boolean;

  groupDay: RequestAvailabilityDay;

  onAdd: (availability: RequestAvailableTime) => void;
  onRemove: (i: number) => void;
  onChange: (i: number, availability: RequestAvailableTime) => void;
  onRemoveDay: (day: RequestAvailabilityDay) => void;
}

const Group = (props: GroupProps) => {
  const { value, disabled, groupDay, onAdd, onRemove, onChange, onRemoveDay } = props;

  const dayName = dayNames[groupDay];

  const filteredValue = value.filter(({ day }) => day === groupDay);

  return (
    <div className="availability-group">
      <div className="switch-row">
        <p className="day-name">{dayName}</p>
        <ToggleSwitch
          size="small"
          color="black-1"
          thumbColor="black-4"
          textColor="white"
          selectedColor="purple"
          selectedThumbColor="black-2"
          value={filteredValue.length > 0}
          disabled={disabled}
          onChange={({ value }) =>
            value
              ? onAdd({ day: groupDay, start: "09:00:00", end: "17:00:00" })
              : onRemoveDay(groupDay)
          }
        />
      </div>

      {filteredValue.length > 0 && (
        <div className="times">
          {value.map(({ day, start, end }, i) =>
            day === groupDay ? (
              <div className="time-group" key={i}>
                <FormGroup>
                  <TimePicker
                    color="black-4"
                    textColor="white"
                    name="start"
                    value={dayjs(`2000-0-0T${start}`)}
                    disabled={disabled}
                    maxTime={dayjs(`2000-0-0T${end}`).subtract(15, "mins")}
                    onChange={({ value }) =>
                      onChange(i, { day, start: value.format("HH:mm:ss"), end })
                    }
                    minuteInterval={15}
                  />
                  <Divider color="gray-1" />
                  <TimePicker
                    color="black-4"
                    textColor="white"
                    name="end"
                    value={dayjs(`2000-0-0T${end}`)}
                    disabled={disabled}
                    minTime={dayjs(`2000-0-0T${start}`).add(15, "mins")}
                    onChange={({ value }) =>
                      onChange(i, { day, start, end: value.format("HH:mm:ss") })
                    }
                    minuteInterval={15}
                  />
                </FormGroup>

                <IconButton
                  color="black-4"
                  iconColor="white"
                  icon={<IoClose />}
                  disabled={disabled}
                  onClick={() => onRemove(i)}
                />
              </div>
            ) : null,
          )}

          <Button
            size="medium"
            color="purple"
            textColor="white"
            disabled={disabled}
            onClick={() => onAdd({ day: groupDay, start: "09:00:00", end: "17:00:00" })}>
            Add
          </Button>
        </div>
      )}
    </div>
  );
};
