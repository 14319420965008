import { api } from "@api/api";
import { Currency } from "@lib/enums/generic";
import { GamePlatformSelection, RequestAvailableTime } from "../update/updateExperienceCustom";

export interface CigFullCustomExperience {
  anyGame: boolean;
  anyTime: boolean;

  pricePerPeriod: number;
  currency: Currency;

  streamOptionEnabled: boolean;
  streamPricePerPeriod: number;
  customerOffersPrice: boolean;

  timezoneName: string;

  gamePlatformSelections: GamePlatformSelection[];
  requestAvailableTimes: RequestAvailableTime[];
}

const getCigFullCustomExperience = async () => {
  const { data } = await api.post<CigFullCustomExperience>("/getCIGFullRequestSessionConfig");
  return data;
};

export default getCigFullCustomExperience;
