import HowItWorksSplash from "@components/splash/sections/HowItWorksSplash";
import { FaFileContract } from "react-icons/fa";
import { IoGameController, IoPeople } from "react-icons/io5";
import { RiMoneyPoundCircleFill } from "react-icons/ri";

const HowItWorks = () => {
  return (
    <HowItWorksSplash
      steps={[
        {
          title: "Sign up to Yakkr",
          icon: <FaFileContract />,
        },
        {
          title: "Set your preferred games, availability, and rates",
          icon: <IoGameController />,
        },
        {
          title: "Let your audience know they can game with you",
          icon: <IoPeople />,
        },
        {
          title: "We'll handle the rest, connecting you seamlessly, and paying you weekly",
          icon: <RiMoneyPoundCircleFill />,
        },
      ]}
    />
  );
};

export default HowItWorks;
