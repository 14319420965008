import DataCheck from "@components/common/DataCheck";
import Checkbox from "@components/form/Checkbox";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import IncrementPicker from "@components/form/IncrementPicker";
import { numberToCurrency, pluralise } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import { IoAdd, IoRemove } from "react-icons/io5";
import useRequestExperienceContext from "../context/useRequestExperienceContext";
import ExperiencesRequestLabel from "../ExperiencesRequestLabel";

export default function Tickets() {
  const { breakpoints } = useGlobalContext();
  const {
    values,
    validations,
    onChange,
    onBlur,
    customExperience,
    sessionCost,
    sessionCostIsLoading,
    sessionCostError,
    priceIncTax,
    currency,
  } = useRequestExperienceContext();

  const { streamOptionEnabled } = customExperience ?? {};

  const { discountThresholds, streamPriceIncTax } = sessionCost ?? {};
  const { tierOne, tierTwo } = discountThresholds ?? {};

  const streamOptionEntry = streamOptionEnabled && (
    <FormEntry
      label={
        breakpoints.sm ? undefined : <ExperiencesRequestLabel label={"Want it livestreamed?"} />
      }
      validation={validations.streamOption}
      className="stream-option-entry">
      <div className="checkbox-outer">
        <Checkbox
          variant="outlined"
          size="extra-small"
          color="gray-2"
          textColor="white"
          checkmarkColor="gray-2"
          label={
            <>
              <span>Livestreamed</span>
              <span>
                {streamPriceIncTax != null &&
                  currency &&
                  numberToCurrency(streamPriceIncTax, currency)}
              </span>
            </>
          }
          name="streamOption"
          value={values.streamOption}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
    </FormEntry>
  );

  const ticketsEntry = (
    <FormEntry validation={validations.tickets} className="tickets-entry">
      <IncrementPicker
        size="large"
        color="black-3"
        textColor="white"
        name="tickets"
        value={values.tickets}
        onBlur={onBlur}
        backIcon={<IoRemove />}
        forwardIcon={<IoAdd />}
        backDisabled={values.tickets <= 1}
        forwardDisabled={values.tickets >= 10}
        onBack={() => onChange({ name: "tickets", value: values.tickets - 1 })}
        onForward={() => onChange({ name: "tickets", value: values.tickets + 1 })}
        valueSuffix={pluralise("ticket", values.tickets)}
      />
    </FormEntry>
  );

  return (
    <section className="form-section tickets">
      <FormRow className="length-stream-row">
        <FormEntry
          label={<ExperiencesRequestLabel label="How long?" />}
          validation={validations.sessionLength}
          className="session-length-entry">
          <IncrementPicker
            size="large"
            color="black-2"
            textColor="white"
            name="sessionLength"
            value={values.sessionLength}
            onBlur={onBlur}
            backIcon={<IoRemove />}
            forwardIcon={<IoAdd />}
            backDisabled={values.sessionLength <= 15}
            onBack={() => onChange({ name: "sessionLength", value: values.sessionLength - 15 })}
            onForward={() => onChange({ name: "sessionLength", value: values.sessionLength + 15 })}
            valueSuffix="mins"
          />
        </FormEntry>
        {!breakpoints.sm && streamOptionEntry}
      </FormRow>

      <FormRow>
        <FormEntry label={<ExperiencesRequestLabel label="Want to bring your friends?" />}>
          <div className="tickets-breakdown">
            {!breakpoints.sm && (
              <div className="head">
                <p>Tickets</p>
                <p>Price</p>
              </div>
            )}
            <div className="body">
              <DataCheck
                isLoading={sessionCostIsLoading}
                error={sessionCostError}
                loadingIndicator="gloss">
                <div className="row">
                  <p>1{breakpoints.sm && " ticket"}</p>
                  <p>
                    {sessionCost && numberToCurrency(sessionCost.singleTicketPriceIncTax, currency)}
                  </p>
                </div>
                <div className="row">
                  <p>2 - 5{breakpoints.sm && " tickets"}</p>
                  <p>
                    {tierOne && (
                      <>
                        <span className="discount">-{tierOne.discountProportion * 100}%</span>{" "}
                        {numberToCurrency(tierOne.priceIncTax, currency)}
                      </>
                    )}
                  </p>
                </div>
                <div className="row">
                  <p>6 - 10{breakpoints.sm && " tickets"}</p>
                  <p>
                    {tierTwo && (
                      <>
                        <span className="discount">-{tierTwo.discountProportion * 100}%</span>{" "}
                        {numberToCurrency(tierTwo.priceIncTax, currency)}
                      </>
                    )}
                  </p>
                </div>
              </DataCheck>
            </div>
            {!breakpoints.sm && (
              <div className="configuration">
                <p>Quantity</p>
                {ticketsEntry}
              </div>
            )}
          </div>
        </FormEntry>
      </FormRow>

      {breakpoints.sm && (
        <>
          <FormRow>{ticketsEntry}</FormRow>
          <FormRow>{streamOptionEntry}</FormRow>
          <FormRow>
            <FormEntry className="cost-entry">
              <DataCheck
                isLoading={sessionCostIsLoading}
                error={sessionCostError}
                loadingIndicator="gloss">
                <p className="label">Total</p>
                <p className="value">
                  {priceIncTax != null && numberToCurrency(priceIncTax, currency)}
                </p>
              </DataCheck>
            </FormEntry>
          </FormRow>
        </>
      )}
    </section>
  );
}
