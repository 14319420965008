import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getBooking, { GetBookingBody, MyBooking } from "../getBooking";

const useGetBooking = (body?: GetBookingBody, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<MyBooking | null>(null);

  const send = useCallback(
    async (bodyOverride?: GetBookingBody) => {
      const _body = bodyOverride ?? body;
      if (_body == null || (_body.bookingID == null && _body.holdingID == null)) return reqState;
      setLoading();
      try {
        const booking = await getBooking(_body);
        return setSuccess(booking);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [body, reqState, setError, setLoading, setSuccess],
  );

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [body?.bookingID, body?.holdingID, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetBooking;
