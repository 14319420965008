import { getMsgFromApiErr } from "@api/api";
import getCigNextAvailability from "@api/public/get/getCigNextAvailability";
import useRequestState from "@hooks/useRequestState";
import { Session } from "@lib/types/session";
import { useCallback, useEffect } from "react";

const useGetCigNextAvailability = (cigID?: string, userID?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<Session[]>([]);

  const send = useCallback(
    async (currNextToken?: string) => {
      if (cigID == null) return reqState;
      setLoading(!!currNextToken);
      try {
        const { items, nextToken } = await getCigNextAvailability(cigID, userID, currNextToken);
        if (currNextToken) return setSuccess([...reqState.data, ...items], nextToken);
        return setSuccess(items, nextToken);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [reqState, cigID, userID, setError, setLoading, setSuccess],
  );

  const next = useCallback(async () => {
    if (reqState.nextToken) return send(reqState.nextToken);
  }, [reqState, send]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [cigID, userID, autoSend]);

  return { ...reqState, data: reqState.data, send, next };
};

export default useGetCigNextAvailability;
