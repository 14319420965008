import React, { forwardRef, useState } from "react";
import diamond from "@images/icons/diamond.svg";
import dayjs from "dayjs";
import { CSSProps } from "@lib/types/generic";
import Pill from "@components/common/Pill";
import { IoInformation, IoVideocam, IoWarning } from "react-icons/io5";
import { parseTime, pluralise } from "@lib/utils/generic";
import IconButton from "@components/form/IconButton";
import Ticket, { TicketProps } from "./Ticket";
import Slide from "@components/transitions/Slide";
import { FaHandsHelping } from "react-icons/fa";
import { PlatformPartial } from "@api/public/get/getCompatibility";
import DataCheck from "@components/common/DataCheck";

export interface TicketData {
  ticketId?: string;
  img?: string;
  cigName?: string;
  title?: string;
  extraInfo?: string;

  slotsAvailable?: number;
  gameName?: string;
  startDate?: string;
  endDate?: string;

  exclusive?: boolean;
  charity?: boolean;
  charityName?: string | null;

  free?: boolean;

  compatiblePlatforms?: {
    id: string;
    platform: PlatformPartial;
  }[];
  compatibilityIsLoading?: boolean;
  compatibilityError?: string | null;
}

interface Props extends CSSProps, TicketData, Omit<TicketProps, "topContent" | "bottomContent"> {}

export type TicketBookProps = Props;

const TicketBook = forwardRef<HTMLDivElement, TicketBookProps>((props, ref) => {
  const {
    cigName,
    title,
    extraInfo,

    slotsAvailable,
    gameName,
    startDate,
    endDate,

    exclusive,
    charity,
    charityName,

    free,

    compatiblePlatforms = [],
    compatibilityIsLoading,
    compatibilityError,

    className = "",

    ...ticketProps
  } = props;

  const { hours, minutes } = parseTime(dayjs(endDate).diff(startDate) / 1000);

  const [showingInfo, setShowingInfo] = useState(false);

  const moreInfo = (
    <div className="more-info-wrapper">
      <div className="more-info-inner">
        <h3 className="title">Details</h3>

        <div className="more-info-inner-wrapper">
          <div className="extra-info-wrapper">
            <p className="extra-info">
              <span>{extraInfo || `${cigName} hasn't provided any extra info`}</span>
            </p>
          </div>

          <div className="platforms-wrapper">
            <h4 className="platforms-title">Join on these platforms</h4>
            <ul className="platforms-list">
              <DataCheck
                isLoading={compatibilityIsLoading}
                error={compatibilityError}
                color="white"
                loadingIndicator="spinner">
                {compatiblePlatforms.map(({ platform: { shortName, icon } }, i) => (
                  <li className="platform" key={i}>
                    <div
                      className="icon"
                      style={{
                        maskImage: `url(${icon})`,
                        WebkitMaskImage: `url(${icon})`,
                      }}
                    />
                    <p className="name">{shortName}</p>
                  </li>
                ))}
              </DataCheck>
            </ul>
          </div>

          {charity && (
            <div className="charity-wrapper">
              <h4 className="charity-title">Charity</h4>
              <div className="charity">
                <FaHandsHelping /> <p>{charityName}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <Ticket
      {...ticketProps}
      ref={ref}
      className={`ticket-book ${className}`}
      topContent={
        <>
          <div className="content-wrapper">
            <Pill
              color="red"
              textColor="white"
              size={ticketProps.size === "large" ? "small" : "extra-small"}
              startIcon={<IoWarning />}
              rounded>
              {slotsAvailable ?? "N/A"} {pluralise("ticket", slotsAvailable ?? 0)} left
            </Pill>

            <div className="cig-name-wrapper">
              {exclusive && <img src={diamond} alt="Diamond" crossOrigin="anonymous" />}
              <h1 className="cig-name">{cigName}</h1>
            </div>
          </div>

          <Slide in={showingInfo}>{moreInfo}</Slide>

          <IconButton
            className="more-info-button"
            color="white"
            iconColor="black"
            rounded
            size={ticketProps.size === "large" ? "small" : "extra-small"}
            icon={<IoInformation />}
            onClick={() => setShowingInfo(showingInfo => !showingInfo)}
          />
        </>
      }
      bottomContent={
        <div className="content-wrapper">
          <div className="details-wrapper">
            <div className="titles">
              <p className="main-title">{title || ""}</p>
              <p className="sub-title">{gameName}</p>
            </div>

            <div className="meta-wrapper">
              <p className="date">{dayjs(startDate).format("D MMMM")}</p>
              <p className="time">
                {dayjs(startDate).format("HH:mm")}
                {" - "}
                {dayjs(endDate).format("HH:mm")}
              </p>
            </div>
          </div>

          <div className="pills">
            <Pill
              size={ticketProps.size === "small" ? "extra-small" : "small"}
              color="black-1"
              textColor="white"
              variant="contained"
              startIcon={<IoVideocam />}
              rounded>
              ROOM
            </Pill>
            <Pill
              size={ticketProps.size === "small" ? "extra-small" : "small"}
              color="black-1"
              textColor="white"
              variant="contained"
              rounded>
              {hours > 0 && `${hours}H`} {minutes > 0 && `${minutes}M`}
            </Pill>
          </div>
        </div>
      }
    />
  );
});

export default TicketBook;
