import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Button from "@components/form/Button";
import useFeedbackContext from "../context/useFeedbackContext";
import { IoArrowForward } from "react-icons/io5";
import Input from "@components/form/Input";
import Checkbox from "@components/form/Checkbox";

export default function MessageStep() {
  const {
    values,
    validations,
    onChange,
    onBlur,

    messageIsLoading,

    buttonSize,
  } = useFeedbackContext();

  return (
    <>
      <div className="form-rows">
        <FormRow>
          <FormEntry validation={validations.message} relativePosition="left">
            <Input
              variant="contained"
              color="black-4"
              textColor="white"
              size="medium"
              name="message"
              value={values.message}
              onChange={onChange}
              onBlur={onBlur}
              placeholder="Review and send a message"
              multiline
              className="message-input"
            />
          </FormEntry>
        </FormRow>
        <FormRow>
          <FormEntry validation={validations.showDisplayName} relativePosition="left">
            <Checkbox
              variant="contained"
              color="black-4"
              textColor="gray-2"
              checkmarkColor="white"
              size="medium"
              name="showDisplayName"
              value={values.showDisplayName}
              onChange={onChange}
              onBlur={onBlur}
              label="Show my display name"
            />
          </FormEntry>
        </FormRow>
      </div>

      <div className="button-wrapper">
        <Button
          size={buttonSize}
          color="purple"
          textColor="white"
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={messageIsLoading}>
          Send
        </Button>
        <Button
          size={buttonSize}
          color="black-3"
          textColor="white"
          justifyContent="center"
          type="submit"
          disabled={messageIsLoading}
          className="skip">
          Not Now
        </Button>
      </div>
    </>
  );
}
