import Checkbox, { CheckboxProps } from "@components/form/Checkbox";
import { FormElement } from "@components/form/Form";
import { UIColor } from "@lib/types/generic";
import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { Group, Showcase } from "./StyleGuide";

const Checkboxes = () => {
  const sizes = [
    "extra-small",
    "small",
    "medium",
    "large",
    "extra-large",
  ] as CheckboxProps["size"][];

  const [value, setValue] = useState(false);
  const [color, setColor] = useState<UIColor>("purple");
  const [textColor, setTextColor] = useState<UIColor>("white");
  const [iconColor, setIconColor] = useState<UIColor>("white");
  const [variant, setVariant] = useState<CheckboxProps["variant"]>("contained");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [rounded, setRounded] = useState<boolean>(false);

  const props = {
    value,
    onChange: ({ value }: FormElement) => setValue(value),
    color,
    textColor,
    checkmarkColor: iconColor,
    variant,
    label: "Checkbox",
    disabled,
    rounded,
  };

  return (
    <Showcase
      title="Checkboxes"
      className="checkboxes"
      colors={[
        {
          color: color,
          label: "Color",
          onChange: setColor,
        },
        {
          color: textColor,
          label: "Text Color",
          onChange: setTextColor,
        },
        {
          color: iconColor,
          label: "Icon Color",
          onChange: setIconColor,
        },
      ]}
      selects={[
        {
          value: variant ?? "contained",
          optionsArray: ["contained", "outlined", "flat", "transparent"],
          label: "Variant",
          onChange: setVariant,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
        {
          value: rounded,
          label: "Rounded",
          onChange: setRounded,
        },
      ]}>
      {sizes.map(size => (
        <Group key={size}>
          <Checkbox size={size} {...props} />
          <Checkbox size={size} {...props} icon={<IoClose />} />
        </Group>
      ))}
    </Showcase>
  );
};

export default Checkboxes;
