import React from "react";

interface Props {
  label: string;
}

export default function ExperiencesRequestLabel(props: Props) {
  const { label } = props;

  return <p className="label">{label}</p>;
}
