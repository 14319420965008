import IncrementPicker, { IncrementPickerProps } from "@components/form/IncrementPicker";
import { UIColor } from "@lib/types/generic";
import { useState } from "react";
import { Group, Showcase } from "./StyleGuide";

const IncrementPickers = () => {
  const sizes = [
    "extra-small",
    "small",
    "medium",
    "large",
    "extra-large",
  ] as IncrementPickerProps["size"][];

  const [value, setValue] = useState(0);
  const [color, setColor] = useState<UIColor>("purple");
  const [variant, setVariant] = useState<IncrementPickerProps["variant"]>("contained");
  const [textColor, setTextColor] = useState<UIColor>("white");
  const [iconColor, setIconcolor] = useState<UIColor>("white");
  const [rounded, setRounded] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const props = {
    value,
    color,
    variant,
    textColor,
    iconColor,
    rounded,
    disabled,
    onBack: () => setValue(value - 1),
    onForward: () => setValue(value + 1),
  };

  return (
    <Showcase
      title="Increment Pickers"
      className="increment-pickers"
      colors={[
        {
          color: color,
          label: "Color",
          onChange: setColor,
        },
        {
          color: textColor,
          label: "Text Color",
          onChange: setTextColor,
        },
        {
          color: iconColor,
          label: "Icon Color",
          onChange: setIconcolor,
        },
      ]}
      selects={[
        {
          value: variant ?? "contained",
          optionsArray: ["contained", "outlined", "flat", "transparent"],
          label: "Variant",
          onChange: setVariant,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
        {
          value: rounded,
          label: "Rounded",
          onChange: setRounded,
        },
      ]}
      inputs={[
        {
          value: value,
          label: "Value",
          onChange: setValue,
        },
      ]}>
      {sizes.map(size => (
        <Group key={size}>
          <IncrementPicker size={size} {...props} />
          <IncrementPicker size={size} {...props} valuePrefix="Prefix" />
          <IncrementPicker size={size} {...props} valueSuffix="Suffix" />
          <IncrementPicker
            size={size}
            {...props}
            type="number"
            onChange={({ value }) => setValue(+value)}
            onBlur={({ value }) => setValue(+value)}
          />
        </Group>
      ))}
    </Showcase>
  );
};

export default IncrementPickers;
