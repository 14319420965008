import React, { ReactNode } from "react";

interface Props {
  title?: string;
  subTitle?: string;
  actions?: ReactNode;
}

export default function ExperiencesHeader(props: Props) {
  const { title, subTitle, actions } = props;

  return (
    <div className="experiences-header">
      <div className="left">
        <h3 className="title">{title}</h3>
        <h4 className="sub-title">{subTitle}</h4>
      </div>
      <div className="right">{actions}</div>
    </div>
  );
}
