import { ReactElement, ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";

export const isEventMessage = (message: any): message is EventMessage => message.isEventMessage;

export default class EventMessage {
  public isEventMessage: boolean = true;
  public sid: string;
  public body: ReactNode;
  public dateCreated: Date;
  public icon?: ReactElement;

  constructor(body: ReactNode, icon?: ReactElement) {
    this.sid = uuidv4();
    this.body = body;
    this.dateCreated = new Date();
    this.icon = icon;
  }
}
