import SlideOnIntersect from "@components/common/SlideOnIntersect";
import React, { ReactNode } from "react";
import BecomeTalentOldHeader from "../BecomeTalentOldHeader";

export default function Benefits() {
  return (
    <section className="section benefits">
      <BecomeTalentOldHeader
        title={
          <>
            Sweet <span>benefits</span> for you
          </>
        }
        subTitle="This isn't another Esports coaching site. We don't all want to be pro players. But we do all love a late night gaming session. Play, laugh and (probably) lose together!"
        center
      />

      <div className="box-wrapper">
        <Box
          tagLine="All Creators"
          title={
            <>
              <span>Connect</span> with your audience
            </>
          }
          content="Meeting fans can sometimes be a bit awkward, right? Instead, make an authetic experience through your mutual love of gaming!"
          timeout={500}
        />

        <Box
          tagLine="All Creators"
          title={
            <>
              Extra <span>$</span> in your pocket
            </>
          }
          content="Earning income as a Creator is tough! Brand deals can be hard to come by (unless you want to pump NFT's). Supplement your income by hosting community gaming sessions."
          timeout={700}
        />

        <Box
          tagLine="Streamers"
          title={
            <>
              <span>More than</span> ad-free viewing
            </>
          }
          content="Set yourself apart from other Creators that are connecting with fans whimsically or not at all. Provide more than emotes and ad-free viewing."
          timeout={800}
        />

        <Box
          tagLine="Streamers"
          title={
            <>
              Managing <span>viewer requests</span>
            </>
          }
          content="Viewers keep asking to play? We get it. They love your content and want to get more access to you. Now you can give them that ability."
          timeout={850}
        />

        <Box
          tagLine="Influencers"
          title={
            <>
              <span>Diversify</span> your content
            </>
          }
          content="Diversifying your content can attract new followers and grow your audience size. With the gaming industry booming, create new content alongside your fans without needing to be a Streamer or video editor!"
          timeout={875}
        />

        <Box
          tagLine="Celebrities"
          title={
            <>
              Supporting <span>good causes</span>
            </>
          }
          content="You've earned your money, why not help out a charity close to your heart? It's a win-win. Raise money for charity and give memorable experiences to your fans."
          timeout={900}
        />
      </div>
    </section>
  );
}

interface BoxProps {
  tagLine: ReactNode;
  title: ReactNode;
  content: ReactNode;
  timeout: number;
}

const Box = ({ tagLine, title, content, timeout }: BoxProps) => (
  <SlideOnIntersect direction="right" timeout={timeout}>
    <div className="box">
      <p className="tag-line">{tagLine}</p>
      <h3 className="title">{title}</h3>
      <p className="text">{content}</p>
    </div>
  </SlideOnIntersect>
);
