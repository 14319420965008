import React, { forwardRef } from "react";
import diamond from "@images/icons/diamond.svg";
import qrCode from "@images/misc/qr-code.png";
import dayjs from "dayjs";
import Square from "@components/shapes/Square";
import { CSSProps } from "@lib/types/generic";
import Ticket, { TicketProps } from "./Ticket";
import YakkrBanner from "@components/common/YakkrBanner";

export interface TicketData {
  ticketId?: string;
  img?: string;
  cigName?: string;
  customerName?: string;
  gameName?: string;
  startDate?: string;
  endDate?: string;
  exclusive?: boolean;
  chromaColor?: chroma.Color;
  index?: number;
}

interface Props extends CSSProps, TicketData, Omit<TicketProps, "topContent" | "bottomContent"> {}

export type TicketRegularProps = Props;

const TicketRegular = forwardRef<HTMLDivElement, TicketRegularProps>((props, ref) => {
  const {
    cigName,
    customerName,
    gameName,
    startDate,
    endDate,
    exclusive,
    chromaColor,
    index = 0,

    className = "",

    ...ticketProps
  } = props;

  return (
    <Ticket
      {...ticketProps}
      ref={ref}
      className={`ticket-regular ${className}`}
      topContent={
        <>
          {chromaColor && (
            <div className="color-tab" style={{ backgroundColor: chromaColor.css() }}>
              <p>{index + 1}</p>
            </div>
          )}

          <div className="banners">
            <YakkrBanner exclusive={exclusive} />
          </div>

          <div className="top-squares">
            <Square color="purple-4" />
            <Square color="purple-4" />
          </div>

          <div className="bottom-squares">
            <Square color="blue" />
            <Square color="blue" />
          </div>

          <div className="cig-name-wrapper">
            {exclusive && <img src={diamond} alt="Diamond" crossOrigin="anonymous" />}
            <h1 className="cig-name">{cigName}</h1>
          </div>
        </>
      }
      bottomContent={
        <div className="content-wrapper">
          <div className="details-wrapper">
            <div className="titles">
              <p className="main-title">{customerName}</p>
              <p className="sub-title">{gameName}</p>
            </div>

            <div className="meta-wrapper">
              <p className="date">
                {dayjs(startDate).format("DD MMMM HH:mm")}
                {" - "}
                {dayjs(endDate).format("DD MMMM HH:mm")}
              </p>
            </div>
          </div>

          <img className="qr-code" src={qrCode} alt="QR Code" crossOrigin="anonymous" />
        </div>
      }
    />
  );
});

export default TicketRegular;
