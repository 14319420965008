import useGetAvailabilityWithID from "@api/private/get/hooks/useGetAvailabilityWithID";
import useQuery from "@hooks/useQuery";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useChatRoom from "./useChatRoom";
import useIsJoinable from "../../hooks/useIsJoinable";
import useJoinRoom from "./useJoinRoom";
import useVideoRoom from "./useVideoRoom";
import { RoomParticipantType } from "../RoomContextProvider";
import useRoomSocket from "./useRoomSocket";

export type ChannelNumber = 1 | 2 | 3 | 4 | 5;

const useRoom = () => {
  const { sessionId } = useParams<Params>();

  const type = useQuery<RoomParticipantType>("type") ?? undefined;
  const ticketId = useQuery<string>("ticketId") ?? undefined;
  const accessCode = useQuery<string>("accessCode") ?? undefined;

  const [rulesAccepted, setRulesAccepted] = useState(type === "cig");
  const [channelNumber, setChannelNumber] = useState<ChannelNumber>(1);

  const {
    send: getSession,
    data: session,
    isLoading: sessionIsLoading,
    error: sessionError,
  } = useGetAvailabilityWithID(sessionId, true);

  const isJoinable = useIsJoinable(session, type);

  const canJoin = !!session && isJoinable && rulesAccepted;

  const {
    join: joinRoom,
    joinDetails,

    isLoading: joinIsLoading,
    error: joinError,
  } = useJoinRoom(sessionId, ticketId, accessCode, type, canJoin);

  const {
    chatSID,
    videoRoom1Authorisation,
    videoRoom2Authorisation,
    videoRoom3Authorisation,
    videoRoom4Authorisation,
    videoRoom5Authorisation,
  } = joinDetails ?? {};

  const videoRoomAuthCodes = [
    videoRoom1Authorisation,
    videoRoom2Authorisation,
    videoRoom3Authorisation,
    videoRoom4Authorisation,
    videoRoom5Authorisation,
  ];

  const {
    connect: chatRoomConnect,
    chatRoom,
    chatClient,
    isLoading: chatRoomIsLoading,
    error: chatRoomError,
  } = useChatRoom(videoRoom1Authorisation, chatSID);

  useEffect(() => {
    if (!chatRoom && canJoin) chatRoomConnect();
    // eslint-disable-next-line
  }, [chatRoom, canJoin, videoRoom1Authorisation, chatSID]);
  // must omit chatRoomConnect to prevent loop

  const {
    connect: videoRoomConnect,
    videoRoom,
    isLoading: videoRoomIsLoading,
    error: videoRoomError,
  } = useVideoRoom(videoRoomAuthCodes[channelNumber - 1], canJoin, type === "cig");

  const {
    connect: roomSocketConnect,
    roomSocket,
    isLoading: roomSocketIsLoading,
    error: roomSocketError,
  } = useRoomSocket(session?.id, !!chatRoom && !!videoRoom);

  const handleWindowFocus = useCallback(() => {
    if (!roomSocket) roomSocketConnect();
  }, [roomSocket, roomSocketConnect]);

  useEffect(() => {
    if (!roomSocket) return;

    const interval = setInterval(() => roomSocket.send("heartbeat"), 1000 * 60 * 5);
    roomSocket.addEventListener("close", roomSocketConnect);
    roomSocket.addEventListener("error", roomSocketConnect);
    window.addEventListener("focus", handleWindowFocus);

    return () => {
      clearInterval(interval);
      roomSocket.removeEventListener("close", roomSocketConnect);
      roomSocket.removeEventListener("error", roomSocketConnect);
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, [roomSocket, roomSocketConnect, handleWindowFocus]);

  const changeChannel = useCallback(
    (channelNumber: ChannelNumber) => {
      if (!videoRoom) return;
      videoRoom.once("disconnected", async () => {
        setChannelNumber(channelNumber);
        await joinRoom();
      });
      videoRoom.disconnect();
    },
    [videoRoom, joinRoom],
  );

  const retrySession = useCallback(() => {
    getSession();
  }, [getSession]);

  const retry = useCallback(async () => {
    await joinRoom();
    videoRoomConnect();
    chatRoomConnect();
    roomSocketConnect();
  }, [joinRoom, videoRoomConnect, chatRoomConnect, roomSocketConnect]);

  const retryRoom = useCallback(() => {
    if (videoRoom) {
      videoRoom.disconnect();
      videoRoom.once("disconnected", retry);
    } else {
      retry();
    }
  }, [retry, videoRoom]);

  return {
    type,

    session,
    sessionError,
    sessionIsLoading,

    joinDetails,
    joinIsLoading,
    joinError,

    videoRoom,
    videoRoomIsLoading,
    videoRoomError,

    chatRoom,
    chatClient,
    chatRoomIsLoading,
    chatRoomError,

    roomSocket,
    roomSocketIsLoading,
    roomSocketError,

    rulesAccepted,
    setRulesAccepted,

    channelNumber,

    changeChannel,
    retryRoom,
    retrySession,
  };
};

export default useRoom;

interface Params {
  sessionId?: string;
}
