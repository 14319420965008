import { log } from "@lib/utils/generic";
import { ReactElement, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// import dayjs from "dayjs";
// import { Currency } from "@lib/enums/generic";
// import GiftedSessionAlert from "../alerts/variants/GiftedSessionAlert";
// import PurchasedSessionAlert from "../alerts/variants/PurchasedSessionAlert";
// import SessionSoonAlert from "../alerts/variants/SessionSoonAlert";
// import TippedAlert from "../alerts/variants/TippedAlert";

export interface OverlayAlertProps {
  onFinish?: () => void;
}

export interface OverlayAlertItem {
  key: string;
  component: ReactElement;
}

const useCigOverlayAlerts = () => {
  const [alerts, setAlerts] = useState<OverlayAlertItem[]>([]);

  const spliceAlert = (alert: OverlayAlertItem) => {
    setAlerts(alerts => {
      const index = alerts.indexOf(alert);
      if (index < 0) return alerts;
      const snackbarsCopy = [...alerts];
      snackbarsCopy.splice(index, 1);
      return snackbarsCopy;
    });
  };

  const pushAlert = (alert: OverlayAlertItem) => {
    log("Pushing alert: ", alert);
    if (alerts.find(({ key }) => alert.key === key)) return;
    setAlerts(alerts => [...alerts, { ...alert, key: uuidv4() }]);
  };

  return { alerts, setAlerts, spliceAlert, pushAlert };
};

export default useCigOverlayAlerts;
