import { GamePlatformSelection } from "@api/private/update/updateExperiencePackage";
import { Game } from "@api/public/get/getGames";
import DataCheck from "@components/common/DataCheck";
import Divider from "@components/common/Divider";
import HoverTooltip from "@components/common/HoverTooltip";
import Button from "@components/form/Button";
import { FormElement } from "@components/form/Form";
import FormGroup from "@components/form/FormGroup";
import IconButton from "@components/form/IconButton";
import { Option } from "@components/form/Options";
import Search from "@components/form/Search";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { IoClose, IoDesktopOutline, IoGameController } from "react-icons/io5";

interface Props {
  name?: string;
  value: GamePlatformSelection[];
  disabled?: boolean;

  games: Game[];
  gamesIsLoading: boolean;
  gamesError?: string;

  onChange?: (target: FormElement<GamePlatformSelection[]>) => void;
}

const ExperiencesManageGameSelector = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    name = "",
    value,
    disabled,
    games,
    gamesIsLoading,
    gamesError,
    onChange = () => {},
  } = props;

  const filteredGames = useMemo(
    () => games.filter(({ id }) => value.find(({ gameID }) => gameID === id) == null),
    [games, value],
  );

  const add = () => {
    if (filteredGames.length === 0) return;

    onChange({
      name,
      value: [
        ...value,
        {
          gameID: "",
          platformID: "",
        },
      ],
    });
  };

  const remove = (i: number) => {
    onChange({
      name,
      value: [...value.slice(0, i), ...value.slice(i + 1)],
    });
  };

  const change = (i: number, gamePlatform: GamePlatformSelection) => {
    onChange({
      name,
      value: [...value.slice(0, i), gamePlatform, ...value.slice(i + 1)],
    });
  };

  return (
    <div className="experiences-manage-game-selector" ref={ref}>
      <DataCheck isLoading={gamesIsLoading} error={gamesError} loadingIndicator="gloss">
        {value.map(({ gameID, platformID }, i) => {
          const filteredGames = games.filter(
            ({ id }) => value.find(({ gameID }) => gameID === id) == null || id === gameID,
          );

          return (
            <Group
              key={i}
              games={filteredGames}
              gameID={gameID}
              platformID={platformID}
              disabled={disabled}
              onRemove={() => remove(i)}
              onChange={gamePlatform => change(i, gamePlatform)}
            />
          );
        })}

        <HoverTooltip
          content={<p className="text-center">No more games available to add</p>}
          killTooltip={filteredGames.length > 0}>
          <div>
            <Button
              size="large"
              color="purple"
              textColor="white"
              onClick={add}
              disabled={filteredGames.length === 0 || disabled}
              className="add-button">
              Add
            </Button>
          </div>
        </HoverTooltip>
      </DataCheck>
    </div>
  );
});

export default ExperiencesManageGameSelector;

interface GroupProps {
  games: Game[];
  gameID: string;
  platformID: string;

  disabled?: boolean;

  onRemove: () => void;
  onChange: (gamePlatform: GamePlatformSelection) => void;
}

const Group = (props: GroupProps) => {
  const { games, gameID, platformID, disabled, onRemove, onChange } = props;

  const [gameSearchValue, setGameSerchValue] = useState("");
  const [platformSearchValue, setPlatformSearchValue] = useState("");

  const gameOptions = games.map<Option>(({ id, longName }) => ({
    value: id,
    label: longName,
  }));

  const platformOptions =
    games
      .find(({ id }) => id === gameID)
      ?.platforms.items.map<Option>(({ platform: { id, name } }) => ({
        value: id,
        label: name,
      })) ?? [];

  useEffect(() => {
    const game = games.find(({ id }) => gameID === id);
    const platform = game?.platforms.items.find(({ platform: { id } }) => platformID === id);

    if (!platform && gameID !== "") {
      onChange({
        gameID,
        platformID: "",
      });
      setPlatformSearchValue("");
    }

    // eslint-disable-next-line
  }, [games.length, gameID, platformID]);
  // cannot use games directly to prevent loop

  return (
    <div className="game-platform-group">
      <FormGroup>
        <Search
          color="black-4"
          textColor="white"
          name="gameID"
          placeholder="Game"
          startIcon={<IoGameController />}
          results={gameOptions}
          value={gameID}
          searchValue={gameSearchValue}
          disabled={disabled}
          onChange={({ value }) => setGameSerchValue(value)}
          onSelect={({ value }) => onChange({ gameID: value, platformID })}
          autoFilter
          sortResults
        />
        <Divider color="gray-1" />
        <Search
          color="black-4"
          textColor="white"
          name="platformID"
          placeholder="Platform"
          startIcon={<IoDesktopOutline />}
          results={platformOptions}
          value={platformID}
          searchValue={platformSearchValue}
          disabled={disabled}
          onChange={({ value }) => setPlatformSearchValue(value)}
          onSelect={({ value }) => onChange({ gameID, platformID: value })}
          autoFilter
          sortResults
        />
      </FormGroup>

      <IconButton
        color="black-4"
        iconColor="white"
        icon={<IoClose />}
        disabled={disabled}
        onClick={onRemove}
      />
    </div>
  );
};
