import Avatar from "@components/common/Avatar";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

interface Props {
  img: string;
  name: string;
  role: string;

  about: string;
  favouriteGame: string;
  funFact: string;

  isNew?: boolean;
}

const TeamMember = (props: Props) => {
  const {
    img,
    name,
    role,

    about,
    favouriteGame,
    funFact,

    isNew,
  } = props;

  const { breakpoints } = useGlobalContext();

  return (
    <div className="team-member">
      {isNew && <p className="new-tag">NEW</p>}

      <Avatar src={img} alt={name} variant="rounded" size={breakpoints.sm ? 100 : 115} />
      <p className="name">{name}</p>
      <p className="role">{role}</p>

      <p className="label">ABOUT</p>
      <p className="value">{about}</p>

      <p className="label">FAVOURITE GAME</p>
      <p className="value">{favouriteGame}</p>

      <p className="label">FUN FACT</p>
      <p className="value">{funFact}</p>
    </div>
  );
};

export default TeamMember;
