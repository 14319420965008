import { api } from "@api/api";

export interface CIGJoinSessionRes {
  videoRoom1Authorisation: string;
  videoRoom2Authorisation: string;
  videoRoom3Authorisation: string;
  videoRoom4Authorisation: string;
  videoRoom5Authorisation: string;
  chatSID: string;
}

const cigJoinSession = async (availabilityID: string) => {
  const { data } = await api.post<CIGJoinSessionRes>("/cigJoinSession", {
    availabilityID,
  });
  return data;
};

export default cigJoinSession;
