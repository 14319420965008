import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import cancelCustomerExperienceRequest from "../cancelCustomerExperienceRequest";

const useCancelCustomerExperienceRequest = (requestId?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState(null);

  const send = useCallback(async () => {
    if (requestId == null) return reqState;
    setLoading();
    try {
      await cancelCustomerExperienceRequest(requestId);
      return setSuccess(null);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [requestId, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCancelCustomerExperienceRequest;
