import { api, APIResponseData } from "@api/api";
import { Session } from "@lib/types/session";
import { Game } from "./getGames";

export enum HomeSectionType {
  Cigs = "CIGS",
  Games = "GAMES",
  UpcomingEvents = "UPCOMING_SESSIONS",
}

export interface HomeSectionCigs {
  type: HomeSectionType.Cigs;
  title?: string;
  subTitle?: string;
  cigs?: {
    id: string;

    displayName: string;
    tagLine: string;
    category: string;
    subCategory: string;
    country: string;

    profilePictureURL: string | null;

    featured: boolean;

    availability: { items: Session[] };
  }[];
}

export interface HomeSectionGames {
  type: HomeSectionType.Games;
  title?: string;
  subTitle?: string;
  games?: { items: Game[] };
}

export interface HomeSectionEvents {
  title?: string;
  type: HomeSectionType.UpcomingEvents;
  sessions?: Session[];
}

export type HomeSectionData = HomeSectionCigs | HomeSectionGames | HomeSectionEvents;

const getHome = async (userID?: string) => {
  const { data } = await api.get<APIResponseData<HomeSectionData>>("/home", { params: { userID } });
  return data.data;
};

export default getHome;
