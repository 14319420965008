import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

const useUserIsOAuth = () => {
  const { user } = useGlobalContext();

  const groups: string[] = user?.signInUserSession?.idToken?.payload["cognito:groups"] ?? [];

  const isOAuth = groups.find(group => group.includes("_Google") || group.includes("_Facebook"));

  return isOAuth;
};

export default useUserIsOAuth;
