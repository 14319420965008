import { useCallback, useEffect, useState } from "react";
import useGlobalContext from "../globalContext/hooks/useGlobalContext";

const useIsKeyboardOpen = () => {
  const { breakpoints } = useGlobalContext();
  const [keyboardOpen, setKeyboardOpen] = useState(document.activeElement?.tagName === "INPUT");

  const handle = useCallback(() => {
    setKeyboardOpen(document.activeElement?.tagName === "INPUT");
  }, []);

  useEffect(() => {
    window.addEventListener("click", handle);

    return () => {
      window.removeEventListener("click", handle);
    };
  }, [handle]);

  useEffect(() => {
    handle();
  }, [breakpoints, handle]);

  return keyboardOpen;
};

export default useIsKeyboardOpen;
