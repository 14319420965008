import { api } from "@api/api";
import { ClaimReason, ClaimState } from "@lib/enums/generic";

export interface Claim {
  id: string;
  bookingID: string;
  reason: string;
  state: ClaimState;
  reasonClass: ClaimReason;
  createdAt: string;
}

const getCustomerClaims = async () => {
  const { data } = await api.post<Claim[]>("/getCustomerClaims");
  return data;
};

export default getCustomerClaims;
