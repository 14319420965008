import { RefObject, useEffect } from "react";

const useOnScroll = (
  ref?: RefObject<HTMLElement>,
  callback = (event: Event) => {},
  options?: boolean | AddEventListenerOptions,
  disable?: boolean,
) => {
  useEffect(() => {
    if (disable) return;
    const current = ref?.current ?? document;
    current.addEventListener("scroll", callback, options);
    return () => current.removeEventListener("scroll", callback, options);
  }, [callback, ref, options, disable]);
};

export default useOnScroll;
