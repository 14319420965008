import { FormElement } from "@components/form/Form";
import Slider, { SliderProps } from "@components/form/Slider";
import { UIColor } from "@lib/types/generic";
import { useState } from "react";
import { Group, Showcase } from "./StyleGuide";

const Sliders = () => {
  const sizes = ["extra-small", "small", "medium", "large", "extra-large"] as SliderProps["size"][];

  const [value, setValue] = useState(0);
  const [activeTrackColor, setActiveTrackColor] = useState<UIColor>("white");
  const [inactiveTrackColor, setInactiveTrackColor] = useState<UIColor>("gray-3");
  const [thumbColor, setThumbColor] = useState<UIColor>("purple");
  const [disabled, setDisabled] = useState<boolean>(false);

  const props = {
    value,
    onChange: ({ value }: FormElement) => setValue(value),
    activeTrackColor,
    inactiveTrackColor,
    thumbColor,
    label: "Toggle",
    disabled,
  };

  return (
    <Showcase
      title="Sliders"
      className="sliders"
      colors={[
        {
          color: activeTrackColor,
          label: "Active Track Color",
          onChange: setActiveTrackColor,
        },
        {
          color: inactiveTrackColor,
          label: "Inactive Track Color",
          onChange: setInactiveTrackColor,
        },
        {
          color: thumbColor,
          label: "Thumb Color",
          onChange: setThumbColor,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
      ]}
      inputs={[
        {
          value: value,
          label: "Value",
          onChange: setValue,
        },
      ]}>
      {sizes.map(size => (
        <Group key={size}>
          <Slider size={size} {...props} />
        </Group>
      ))}
    </Showcase>
  );
};

export default Sliders;
