import useGetCigFlowState from "@api/private/get/hooks/useGetCigFlowState";
import useUpdateCigFlowState from "@api/private/update/hooks/useUpdateCigFlowState";
import { CIGProfile } from "@api/public/get/getCigProfile";
import { CigFlowState } from "@lib/enums/generic";
import { createParams } from "@lib/utils/generic";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useCigFlowState = (cigProfile: CIGProfile | null) => {
  const history = useHistory();
  const location = useLocation();

  const isCig = !!cigProfile;

  const [cigFlowState, setCigFlowStateInState] = useState<CigFlowState>(CigFlowState.Unknown);

  const {
    isLoading: getIsLoading,
    error: getError,
    send: getApiCigFlowState,
  } = useGetCigFlowState();

  const {
    send: updateCigFlowState,
    isLoading: updateIsLoading,
    error: updateError,
  } = useUpdateCigFlowState();

  const getCigFlowState = useCallback(async () => {
    if (!isCig) return;
    if (location.pathname === "/cig/register") return;

    const { data } = await getApiCigFlowState();
    const { state } = data ?? {};

    if (!state) return;
    setCigFlowStateInState(state);

    // eslint-disable-next-line
  }, [isCig]);
  // Must omit getApiCigFlowState and location.pathname to prevent unwanted updates

  useEffect(() => {
    getCigFlowState();
  }, [getCigFlowState]);

  const setCigFlowState = useCallback(
    (cigFlowState: CigFlowState) => {
      setCigFlowStateInState(cigFlowState);
      updateCigFlowState(cigFlowState);
    },
    [updateCigFlowState],
  );

  const progressCigFlowState = (expectedCurrentState: CigFlowState) => {
    if (expectedCurrentState !== cigFlowState) return;

    switch (cigFlowState) {
      case CigFlowState.Unknown: {
        setCigFlowState(CigFlowState.StripeSetup);
        break;
      }
      case CigFlowState.StripeSetup: {
        if (!cigProfile) break;
        setCigFlowState(CigFlowState.ExperiencesSetup);
        history.push("/experiences");
        break;
      }
      case CigFlowState.ExperiencesSetup: {
        if (!cigProfile) break;
        setCigFlowState(CigFlowState.ProfileSetup);
        history.push(
          `/@${cigProfile.displayName}${createParams({
            action: "edit",
          })}`,
        );
        break;
      }
      case CigFlowState.ProfileSetup: {
        if (!cigProfile) break;
        setCigFlowState(CigFlowState.PreFinished);
        history.push(
          `/@${cigProfile.displayName}${createParams({
            action: "none",
          })}`,
        );
        break;
      }
      case CigFlowState.PreFinished: {
        setCigFlowState(CigFlowState.Finished);
        break;
      }
    }
  };

  const isLoading = getIsLoading || updateIsLoading;
  const error = getError ?? updateError;

  return {
    data: cigFlowState,
    isLoading,
    error,
    set: setCigFlowState,
    progressCigFlowState,
  };
};

export default useCigFlowState;
