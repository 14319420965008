import FormRow from "@components/form/FormRow";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { CSSProps } from "@lib/types/generic";
import { forwardRef, PropsWithChildren, ReactNode } from "react";
import Modal from "../modals/Modal";

interface Props extends CSSProps {
  active?: boolean;
  onClose?: () => void;

  title?: ReactNode;
  titleOverride?: ReactNode;
  prompt?: ReactNode;
  buttons?: ReactNode;

  preventKill?: boolean;

  isLoading?: boolean;

  modalClassName?: string;
}

const ActionPanel = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    active,
    onClose = () => {},

    title,
    titleOverride,
    prompt,
    buttons,

    preventKill,

    isLoading,

    children,

    modalClassName = "",
    className = "",
    id,
    style,
  } = props;

  const { breakpoints } = useGlobalContext();

  return (
    <Modal
      active={active}
      onClose={onClose}
      isLoading={isLoading}
      killModal={!breakpoints.xl && !preventKill}
      onlyCloseButton
      className={`action-panel-modal ${modalClassName}`}>
      <div ref={ref} className={`action-panel ${className}`} id={id} style={style}>
        <div className="action-panel-header">
          {titleOverride ? titleOverride : <h3 className="title">{title}</h3>}
        </div>

        {breakpoints.xl || active ? (
          <>
            <div className="action-panel-form">{children}</div>

            <FormRow className="button-row">
              <div className="button-wrapper">{buttons}</div>
            </FormRow>
          </>
        ) : (
          <div className="closed-prompt">
            <p className="prompt-text">{prompt}</p>
          </div>
        )}
      </div>
    </Modal>
  );
});

export default ActionPanel;
