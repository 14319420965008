import useFormManager from "@components/form/hooks/useFormManager";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useEffect } from "react";
import { LoginModalData } from "../context/LoginContextProvider";
import useLoginHandles from "./useLoginHandles";

const initialValues = {
  email: "",
  password: "",
  staySignedIn: true,
};

export type LoginValues = typeof initialValues;

const useCigLogin = () => {
  const { setAuthModal } = useGlobalContext();

  const { email, ...authModalData } = useAuthModalData<LoginModalData>();

  if (email) initialValues.email = email;

  const formManager = useFormManager(initialValues, "login");

  const { values, validations, onChange, onBlur, onChangeMultiple, resetValidations } = formManager;

  const [loginHandle, loginIsLoading] = useLoginHandles(formManager);

  useEffect(
    () => setAuthModal("login", { ...authModalData, email: values.email }),
    // eslint-disable-next-line
    [values.email],
  );
  // must omit authModalData and setAuthModal to prevent loop

  return {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles: [loginHandle],
    resetValidations,

    loginIsLoading,
  };
};

export default useCigLogin;
