import IconButton from "@components/form/IconButton";
import Fade from "@components/transitions/Fade";
import useElementSize from "@hooks/useElementSize";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, { PropsWithChildren, useLayoutEffect, useRef, useState } from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";

interface Props {
  title?: string;
  gap?: string | number;
}

export default function ScrollCarousel(props: PropsWithChildren<Props>) {
  const { title = "", gap = 24, children } = props;

  const { breakpoints } = useGlobalContext();

  const [scrollLeft, setScrollLeft] = useState(1);

  const rowRef = useRef<HTMLDivElement>(null);
  const goBack = (full?: boolean) => {
    const current = rowRef.current;
    if (current == null) return;
    const left = full ? 0 : current.scrollLeft - window.innerWidth / 2;
    current.scrollTo({ left, behavior: "smooth" });
  };
  const goForward = (full?: boolean) => {
    const current = rowRef.current;
    if (current == null) return;
    const left = full ? current.scrollWidth : current.scrollLeft + window.innerWidth / 2;
    current.scrollTo({ left, behavior: "smooth" });
  };

  useLayoutEffect(() => {
    setScrollLeft(0);
  }, []);

  const { width, scrollWidth } = useElementSize(rowRef);

  return (
    <div className="scroll-carousel">
      {title && (
        <div className="scroll-carousel-header">
          <h4 className="title">{title}</h4>
        </div>
      )}
      <div className="row-wrapper">
        <div
          className="row"
          style={{ gap }}
          ref={rowRef}
          onScroll={({ currentTarget }) => setScrollLeft(currentTarget.scrollLeft)}>
          {children}
        </div>
        <Fade in={scrollLeft > 0} appear>
          <div>
            <div className="icon-button-wrapper back">
              <IconButton
                size={breakpoints.sm ? "small" : "medium"}
                color="black-4"
                icon={<IoArrowBack />}
                onMouseUp={() => goBack()}
                onDoubleClick={() => goBack(true)}
              />
            </div>
          </div>
        </Fade>
        <Fade
          in={rowRef.current != null && scrollLeft < scrollWidth - width}
          appear
          properties="background-color, border-radius, opacity">
          <div>
            <div className="icon-button-wrapper forward">
              <IconButton
                size={breakpoints.sm ? "small" : "medium"}
                color="black-4"
                icon={<IoArrowForward />}
                onMouseUp={() => goForward()}
                onDoubleClick={() => goForward(true)}
              />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
