import Checkbox from "@components/form/Checkbox";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import { privacyUrl, termsUrl } from "@lib/constants/generic";
import AltAuthBar from "@components/common/AltAuthBar";
import React from "react";
import { Link } from "react-router-dom";
import useCigRegisterContext from "../context/useCigRegisterContext";
import Divider from "@components/common/Divider";
import Button from "@components/form/Button";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

export default function RegisterStep() {
  const { breakpoints } = useGlobalContext();

  const {
    values,
    validations,
    onChange,
    onBlur,
    category,
    inputSize,
    checkboxSize,
    buttonSize,
    validationPosition,

    registerIsLoading,
  } = useCigRegisterContext();

  const submitButton = (
    <Button
      color="purple"
      textColor="white"
      size={buttonSize}
      type="submit"
      justifyContent="center"
      isLoading={registerIsLoading}>
      Sign up
    </Button>
  );

  return (
    <>
      <div>
        <small>
          Already have an account? <Link to={decodeURI(`/cig/login?cig=${category}`)}>Sign in</Link>
        </small>

        <AltAuthBar />
      </div>

      <div className="or-divider">
        <Divider color="black-3" />
        <p>OR</p>
      </div>

      <FormRow className="break">
        <FormEntry
          label="EMAIL"
          validation={validations.email}
          relativePosition={validationPosition}>
          <Input
            color="black-2"
            textColor="white"
            size={inputSize}
            name="email"
            placeholder="example@email.com"
            type="email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
            disabled={registerIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label="PASSWORD"
          validation={validations.password}
          relativePosition={validationPosition}>
          <Input
            color="black-2"
            textColor="white"
            size={inputSize}
            name="password"
            placeholder="Password"
            type="password"
            value={values.password}
            onChange={onChange}
            onBlur={onBlur}
            disabled={registerIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label="CONFIRM PASSWORD"
          validation={validations.confirmPassword}
          relativePosition={validationPosition}>
          <Input
            color="black-2"
            textColor="white"
            size={inputSize}
            name="confirmPassword"
            placeholder="Password"
            type="password"
            value={values.confirmPassword}
            onChange={onChange}
            onBlur={onBlur}
            disabled={registerIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow className="button-row">
        <FormEntry validation={validations.terms} relativePosition="top">
          <Checkbox
            variant="contained"
            color="black-3"
            textColor="purple-5"
            checkmarkColor="gray-3"
            size={checkboxSize}
            name="terms"
            label={
              <span>
                I agree to the Yakkr{" "}
                <a target="_blank" rel="noreferrer" href={privacyUrl} className="text-underline">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a target="_blank" rel="noreferrer" href={termsUrl} className="text-underline">
                  Terms of Service
                </a>
                .
              </span>
            }
            value={values.terms}
            onChange={onChange}
            onBlur={onBlur}
            disabled={registerIsLoading}
          />
        </FormEntry>

        {!breakpoints.sm && submitButton}
      </FormRow>

      {breakpoints.sm && <div className="button-row">{submitButton}</div>}
    </>
  );
}
