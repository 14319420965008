import BackgroundImage from "@components/common/BackgroundImage";
import DataCheck from "@components/common/DataCheck";
import useCigProfileContext from "../context/hooks/useCigProfileContext";
import bannerPlaceholder from "@images/misc/banner_placeholder.jpg";
import profilePlaceholder from "@images/misc/profile_placeholder.jpg";
import Avatar from "@components/common/Avatar";
import Rating from "@components/common/Rating";
import Button from "@components/form/Button";
import {
  IoArrowForward,
  IoClose,
  IoRadioButtonOnOutline,
  IoSave,
  IoSettings,
  IoSparkles,
} from "react-icons/io5";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { classNames } from "@lib/utils/generic";
import IconButton from "@components/form/IconButton";
import HoverTooltip from "@components/common/HoverTooltip";
import DialogueModal from "@components/modals/DialogueModal";
import { useState } from "react";
import Input from "@components/form/Input";
import FilePicker from "@components/form/FilePicker";
import FormEntry from "@components/form/FormEntry";
import Pill from "@components/common/Pill";

const Banner = () => {
  const { breakpoints } = useGlobalContext();

  const {
    isOwnProfile,
    cigProfile,
    reviews,
    getIsLoading,
    reviewsIsLoading,
    getError,
    reviewsError,
    editMode,
    setEditMode,
    handleSave,
    saveIsLoading,
    resetValues,
    changesMade,

    values,
    validations,
    onChange,
    onBlur,

    bannerPictureFile,
    profilePictureFile,
    setBannerPictureFile,
    setProfilePictureFile,
  } = useCigProfileContext();

  const [resetModalActive, setResetModalActive] = useState(false);

  const { displayName, tagLine, shortDescription } = cigProfile ?? {};

  const bannerPictureURL = values.bannerPictureURL ?? bannerPlaceholder;
  const profilePictureURL = values.profilePictureURL ?? profilePlaceholder;

  const averageRating = Math.ceil(
    reviews.reduce((acc, { score }) => acc + score, 0) / reviews.length / 2,
  );

  const tagLinePlaceholder = "Hey, I'm on Yakkr";
  const shortDescriptionPlaceholder =
    "Book a session or join an event, and we start playing games together!";

  const discard = () => {
    setEditMode(false);

    resetValues();
  };

  return (
    <section className={classNames("section", "banner", editMode && "edit-mode")}>
      <FilePicker
        name="bannerPictureURL"
        file={bannerPictureFile}
        onFileChange={setBannerPictureFile}
        onChange={onChange}
        onBlur={onBlur}
        disabled={!editMode}
        isLoading={saveIsLoading}
        whitelistedFileTypes={["jpeg"]}
        className="banner-file-picker">
        <BackgroundImage src={bannerPictureURL} opacity={1} isLoading={getIsLoading} />
      </FilePicker>

      <div className="content">
        <div className="avatar-and-details">
          <FilePicker
            name="profilePictureURL"
            file={profilePictureFile}
            onFileChange={setProfilePictureFile}
            onChange={onChange}
            onBlur={onBlur}
            disabled={!editMode}
            isLoading={saveIsLoading}
            whitelistedFileTypes={["jpeg"]}
            className="profile-file-picker">
            <Avatar
              src={profilePictureURL}
              size={breakpoints.sm ? 150 : breakpoints.md ? 200 : breakpoints.lg ? 225 : 250}
              variant="rounded"
              isLoading={getIsLoading}
            />

            {isOwnProfile && !saveIsLoading && !getIsLoading && !editMode && (
              <HoverTooltip
                relativePosition="right"
                content={
                  <p
                    className="text-center"
                    style={{
                      maxWidth: 200,
                    }}>
                    {cigProfile?.accepted
                      ? "You have been accepted and your profile is visible."
                      : "Your profile will become visible once you have been accepted."}
                  </p>
                }>
                <Pill
                  size={breakpoints.sm ? "extra-small" : "small"}
                  variant="transparent"
                  color="black-1"
                  iconColor={cigProfile?.accepted ? "green" : "white"}
                  textColor="white"
                  startIcon={<IoRadioButtonOnOutline />}
                  className="live-indicator">
                  {cigProfile?.accepted ? "Public" : "Pending"}
                </Pill>
              </HoverTooltip>
            )}
          </FilePicker>

          <div className={classNames("details", (!isOwnProfile || getIsLoading) && "top-margin")}>
            {isOwnProfile && !getIsLoading && (
              <>
                <div className="buttons">
                  <Button
                    size="small"
                    color={editMode ? "purple" : "gray-3"}
                    textColor={editMode ? "white" : "black-1"}
                    endIcon={editMode ? <IoSave /> : <IoSettings />}
                    rounded={editMode}
                    className="edit-button"
                    onClick={editMode ? handleSave : () => setEditMode(true)}
                    isLoading={saveIsLoading}
                    justifyContent="center">
                    {editMode ? "Save Profile" : "Edit Profile"}
                  </Button>
                  {!editMode && (
                    <Button
                      size="small"
                      color="purple"
                      textColor="white"
                      endIcon={<IoSparkles />}
                      to="/experiences"
                      justifyContent="center">
                      Experiences
                    </Button>
                  )}
                  {editMode && (
                    <HoverTooltip content={<p>Cancel</p>}>
                      <IconButton
                        size="small"
                        color="red"
                        iconColor="white"
                        rounded
                        icon={<IoClose />}
                        onClick={changesMade ? () => setResetModalActive(true) : discard}
                        disabled={saveIsLoading}
                      />
                    </HoverTooltip>
                  )}
                </div>
              </>
            )}
            <DataCheck error={getError ?? reviewsError}>
              <h1 className="display-name">
                <DataCheck
                  isLoading={getIsLoading}
                  loadingContent={<>&nbsp;</>}
                  loadingIndicator="gloss">
                  {displayName}
                </DataCheck>
              </h1>
              <div className="rating-wrapper">
                <Rating value={averageRating} color="yellow" size="extra-small" />
                <p className="ratings">
                  {reviews.length >= 10 ? <>({reviews.length})</> : null}
                  {reviews.length === 0 && " No reviews yet"}
                </p>

                <DataCheck isLoading={reviewsIsLoading} loadingIndicator="gloss" />
              </div>
              {editMode ? (
                <FormEntry
                  validation={validations.tagLine}
                  relativePosition="top"
                  showValidationBeneath={false}>
                  <Input
                    size="medium"
                    variant="transparent"
                    color="black-4"
                    textColor="white"
                    name="tagLine"
                    value={values.tagLine}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={tagLinePlaceholder}
                    wrapperClassName="tag-line-input"
                    disabled={saveIsLoading}
                  />
                </FormEntry>
              ) : (
                <p className="tag-line">
                  <DataCheck
                    isLoading={getIsLoading}
                    loadingContent={<>&nbsp;</>}
                    loadingIndicator="gloss">
                    {tagLine || tagLinePlaceholder}
                  </DataCheck>
                </p>
              )}
              {editMode ? (
                <FormEntry
                  validation={validations.shortDescription}
                  relativePosition="bottom"
                  showValidationBeneath={false}>
                  <Input
                    size="medium"
                    variant="transparent"
                    color="black-4"
                    textColor="white"
                    name="shortDescription"
                    value={values.shortDescription}
                    onChange={onChange}
                    onBlur={onBlur}
                    multiline
                    placeholder={shortDescriptionPlaceholder}
                    wrapperClassName="description-input"
                    disabled={saveIsLoading}
                  />
                </FormEntry>
              ) : (
                <p className="description">
                  <DataCheck
                    isLoading={getIsLoading}
                    loadingContent={<>&nbsp;</>}
                    loadingIndicator="gloss">
                    {shortDescription || shortDescriptionPlaceholder}
                  </DataCheck>
                </p>
              )}
            </DataCheck>
          </div>
        </div>

        <div className="cta">
          <Button
            size={breakpoints.md ? "large" : "extra-large"}
            color="purple"
            textColor="white"
            endIcon={<IoArrowForward />}
            className="book-now-button"
            justifyContent="center"
            href="#experiences">
            Book now
          </Button>

          <Button
            variant="flat"
            size="small"
            color="gray-2"
            textColor="gray-1"
            href="#how-it-works">
            How does it work?
          </Button>
        </div>
      </div>

      <DialogueModal
        active={resetModalActive}
        title="Unsaved Changes!"
        subTitle="Your changes will be lost, are you sure?"
        onConfirm={discard}
        onClose={() => setResetModalActive(false)}
        titleDesign="condensed"
        buttonsDesign="flat"
        className="discard-modal"
      />
    </section>
  );
};

export default Banner;
