import { api } from "@api/api";

export interface ProfilePictureUploadUrl {
  key: string;
  url: string;
  processedImageURL: string;
}

const getProfilePictureUploadUrl = async () => {
  const { data } = await api.post<ProfilePictureUploadUrl>("/getProfilePictureUploadUrl");
  return data;
};

export default getProfilePictureUploadUrl;
