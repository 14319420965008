import Checkbox from "@components/form/Checkbox";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import ExperiencesManageGameSelector from "../../../ExperiencesManageGameSelector";
import ExperiencesManageLabel from "../../../ExperiencesManageLabel";
import useUpdateExperiencePackageContext from "../context/useUpdateExperiencePackageContext";

export default function Games() {
  const { values, validations, onChange, onBlur, games, gamesIsLoading, gamesError } =
    useUpdateExperiencePackageContext();

  return (
    <div className="form-section">
      <FormRow>
        <FormEntry
          label={
            <ExperiencesManageLabel
              label="2. Pick your games"
              subLabel="Choose what games fans can request to play with you."
            />
          }
          validation={validations.gamePlatformSelections}
          relativePosition="top">
          <Checkbox
            size="medium"
            color="black-4"
            textColor="purple-5"
            checkmarkColor="gray-3"
            name="anyGame"
            label="Enable all games"
            value={values.anyGame}
            onChange={onChange}
            onBlur={onBlur}
            wrapperClassName="any-checkbox"
          />
          <ExperiencesManageGameSelector
            name="gamePlatformSelections"
            value={values.gamePlatformSelections}
            games={games}
            disabled={values.anyGame}
            gamesIsLoading={gamesIsLoading}
            gamesError={gamesError}
            onChange={onChange}
          />
        </FormEntry>
      </FormRow>
    </div>
  );
}
