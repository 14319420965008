import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getStreamNotifications, { StreamNotification } from "../getStreamNotifications";

const useGetStreamNotifications = (cigId?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<StreamNotification[]>([]);

  const send = useCallback(async () => {
    if (cigId == null) return reqState;
    setLoading();
    try {
      const banners = await getStreamNotifications(cigId);
      return setSuccess(banners);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [cigId, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [cigId, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetStreamNotifications;
