import Accordion from "@components/common/Accordion";
import Checkbox from "@components/form/Checkbox";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import { Option } from "@components/form/Options";
import Select from "@components/form/Select";
import { colors } from "@lib/constants/colors";
import { CSSProps, UIColor } from "@lib/types/generic";
import {
  capitalize,
  checkIsDarkColor,
  checkIsLightColor,
  classNames,
  toTitleCase,
} from "@lib/utils/generic";
import React, { PropsWithChildren } from "react";
import Buttons from "./Buttons";
import Checkboxes from "./Checkboxes";
import CountrySearchs from "./CountrySearches";
import CurrencyInputs from "./CurrencyInputs";
import DatePickers from "./DatePickers";
import IconButtons from "./IconButtons";
import IncrementPickers from "./IncrementPickers";
import Inputs from "./Inputs";
import NumberInputs from "./NumberInputs";
import PercentInputs from "./PercentInputs";
import Pills from "./Pills";
import RatingSelectors from "./RatingSelectors";
import Searches from "./Searches";
import Selects from "./Selects";
import Sliders from "./Sliders";
import Tabss from "./Tabss";
import TimePickers from "./TimePickers";
import ToggleSwitches from "./ToggleSwitches";

export default function StyleGuide() {
  return (
    <div id="style-guide-page">
      <div className="showcases">
        <Buttons />
        <Pills />
        <IconButtons />
        <Inputs />
        <Selects />
        <Checkboxes />
        <ToggleSwitches />
        <Sliders />
        <RatingSelectors />
        <IncrementPickers />
        <DatePickers />
        <TimePickers />

        <NumberInputs />
        <CurrencyInputs />
        <PercentInputs />

        <CountrySearchs />

        <Searches />
        <Tabss />
      </div>
    </div>
  );
}

const colorOptions: Option[] = Object.entries(colors).map(([value]) => ({
  label: toTitleCase(value, " "),
  value,
  color: value as UIColor,
  textColor: checkIsLightColor(value as UIColor) ? "black" : "white",
}));

interface ShowcaseProps extends CSSProps {
  title: string;
  colors?: Array<{
    color: UIColor;
    label: string;
    onChange: (value: UIColor) => void;
  }>;
  selects?: Array<{
    value: any;
    optionsArray?: any[];
    options?: Option[];
    label: string;
    onChange: (value: any) => void;
  }>;
  checkboxes?: Array<{
    value: boolean;
    label: string;
    onChange: (value: boolean) => void;
  }>;
  inputs?: Array<{
    value: any;
    label: string;
    onChange: (value: any) => void;
  }>;
}

export const Showcase = ({
  children,
  title,
  colors = [],
  selects = [],
  checkboxes = [],
  inputs = [],

  className,
  id,
  style,
}: PropsWithChildren<ShowcaseProps>) => (
  <Accordion title={title}>
    <div
      className={classNames(
        "showcase",
        colors[0]?.color && checkIsDarkColor(colors[0]?.color) && "is-dark-color",
        className,
      )}
      style={style}
      id={id}>
      <div className="left-wrapper">
        <h1 className="title">{title}</h1>
        {colors.map(({ color, label, onChange }) => (
          <FormRow key={label}>
            <FormEntry label={label}>
              <Select
                variant="outlined"
                color="gray-3"
                textColor="gray-3"
                optionTextColor="black-4"
                options={colorOptions}
                value={color}
                onChange={({ value }) => onChange(value)}
              />
            </FormEntry>
          </FormRow>
        ))}

        {selects.map(({ value, optionsArray, options, label, onChange }) => (
          <FormRow key={label}>
            <FormEntry label={label}>
              <Select
                variant="outlined"
                color="gray-3"
                textColor="gray-3"
                optionTextColor="black-4"
                options={
                  options ??
                  optionsArray?.map(option => ({
                    value: option,
                    label: capitalize(option),
                  })) ??
                  []
                }
                value={value}
                onChange={({ value }) => onChange(value)}
              />
            </FormEntry>
          </FormRow>
        ))}

        {checkboxes.map(({ value, label, onChange }) => (
          <FormRow key={label}>
            <FormEntry>
              <Checkbox
                variant="outlined"
                color="gray-3"
                textColor="white"
                checkmarkColor="gray-3"
                value={value}
                onChange={({ value }) => onChange(value)}
                label={label}
              />
            </FormEntry>
          </FormRow>
        ))}

        {inputs.map(({ value, label, onChange }) => (
          <FormRow key={label}>
            <FormEntry label={label}>
              <Input
                variant="outlined"
                color="gray-3"
                textColor="gray-3"
                value={value}
                onChange={({ value }) => onChange(value)}
              />
            </FormEntry>
          </FormRow>
        ))}
      </div>
      {children}
    </div>
  </Accordion>
);

export const Group = ({ children }: PropsWithChildren<{}>) => (
  <div className="group">{children}</div>
);
