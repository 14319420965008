import { pathHasLayout } from "@lib/utils/generic";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const useBodyClassManager = () => {
  const location = useLocation();
  const hasLayout = pathHasLayout(location.pathname);

  useLayoutEffect(() => {
    if (hasLayout) document.body.classList.remove("no-background");
    else document.body.classList.add("no-background");
  }, [hasLayout]);
};

export default useBodyClassManager;
