import React, {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  RefObject,
  useRef,
} from "react";
import useIntersection from "../../hooks/useIntersection";

interface Props {
  onIntersect: IntersectionObserverCallback;
  nodeRef?: RefObject<HTMLElement>;
  useParent?: boolean;
}

export default function Intersection(props: PropsWithChildren<Props>) {
  const { children, onIntersect, nodeRef, useParent } = props;

  const defaultRef = useRef<HTMLDivElement | null>(null);

  const ref = nodeRef ?? defaultRef;

  useIntersection(ref, onIntersect, undefined, useParent);

  return (
    <>
      {Children.map(
        children,
        child =>
          isValidElement(child) &&
          cloneElement(child as any, {
            ref: defaultRef,
          }),
      )}
    </>
  );
}
