import useCreateReport from "@api/private/create/hooks/useCreateReport";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { CreateReportValues } from "../ReportModal";

const useCreateReportHandles: UseFormHandles<CreateReportValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { isLoading, send: createReport } = useCreateReport(values.userId, values.reason);

  const handle = async () => {
    let failed = validateFields(["reason", "userId"]);

    if (!failed) {
      const { error } = await createReport();

      if (error) {
        failed = validateCustom("generic", validator => {
          validator.list.push({
            type: "error",
            text: error,
          });
        });
      }
    }

    return failed;
  };

  return [handle, isLoading];
};

export default useCreateReportHandles;
