import { api } from "@api/api";
import { NotificationType } from "@lib/enums/generic";

export interface Notification {
  id: string;
  type: NotificationType;
  message: string;
  date: string;
  read: number;
}

const getNotifications = async () => {
  const { data } = await api.post<Notification[]>("/getNotifications");
  return data;
};

export default getNotifications;
