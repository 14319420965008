import Button from "@components/form/Button";
import SplashTitle from "@components/splash/SplashTitle";
import { IoArrowForward } from "react-icons/io5";

const Cta2 = () => {
  return (
    <section className="section cta-2">
      <SplashTitle center>Let's get started</SplashTitle>

      <Button
        size="large"
        color="purple"
        textColor="white"
        endIcon={<IoArrowForward />}
        href="#streamers">
        View all streamers
      </Button>
    </section>
  );
};

export default Cta2;
