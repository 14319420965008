import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createCIGPaymentPortalLink, {
  CIGPaymentPortalLinkObject,
} from "../createCIGPaymentPortalLink";

const useCreateCIGPaymentPortalLink = () => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CIGPaymentPortalLinkObject | null>(null);

  const send = useCallback(async () => {
    setLoading();
    try {
      const data = await createCIGPaymentPortalLink();
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateCIGPaymentPortalLink;
