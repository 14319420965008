import BackgroundImage from "@components/common/BackgroundImage";
import Button, { ButtonProps } from "@components/form/Button";
import { classNames } from "@lib/utils/generic";
import React, { ReactNode } from "react";
import { IoArrowForward } from "react-icons/io5";
import background from "@images/misc/experience_card_background.jpg";
import { UIColor } from "@lib/types/generic";
import HoverTooltip from "@components/common/HoverTooltip";

interface Props {
  title: string;
  to: string;

  color?: UIColor;
  width?: number | string;
  buttonSize?: ButtonProps["size"];

  disabled?: boolean;
  disabledReason?: ReactNode;
}

export default function ExperienceUpdateCard(props: Props) {
  const { title, to, color = "white", width = 475, buttonSize, disabled, disabledReason } = props;

  return (
    <div
      className={classNames("experience-update-card", color)}
      style={{
        minWidth: width,
        width: width,
        fontSize: width,
      }}>
      <div className="experience-update-card-inner">
        <div className="imagery">
          <BackgroundImage src={background} opacity={0} />
        </div>

        <div className="details">
          <div className="text-row">
            <p className="name">{title}</p>
            <p className="status">Not setup</p>
          </div>
          <HoverTooltip
            killTooltip={!disabled || !disabledReason}
            content={
              <p style={{ width: 250 }} className="text-center">
                {disabledReason}
              </p>
            }>
            <div>
              <Button
                size={buttonSize}
                color="purple"
                textColor="white"
                justifyContent="center"
                endIcon={<IoArrowForward />}
                to={to}
                disabled={disabled}>
                Setup
              </Button>
            </div>
          </HoverTooltip>
        </div>
      </div>
    </div>
  );
}
