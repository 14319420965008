import FormModal from "@components/modals/FormModal";
import Form from "@components/form/Form";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import useFormManager from "@components/form/hooks/useFormManager";
import Input from "@components/form/Input";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/utils/generic";
import React, { useCallback, useEffect } from "react";
import useShareTicketHandles from "./hooks/useShareTicketHandles";

interface Props {
  active?: boolean;
  bookingId?: string;
  ticketId?: string;

  onClose?: () => void;
  onCompletion?: () => void;
}

const initialValues = {
  bookingId: "",
  ticketId: "",
  email: "",
};

export type ShareTicketValues = typeof initialValues;

export default function ShareTicketModal(props: Props) {
  const { active, bookingId, ticketId, onClose = () => {}, onCompletion = () => {} } = props;

  const { pushSnackbar } = useGlobalContext();

  if (bookingId) initialValues.bookingId = bookingId;

  const formManager = useFormManager(initialValues, "shareTicket");

  const { values, validations, onBlur, onChange, resetValidations, setValues } = formManager;

  const [handle, isLoading] = useShareTicketHandles(formManager);

  useEffect(() => {
    if (ticketId) onChange({ name: "ticketId", value: ticketId });
    // eslint-disable-next-line
  }, [ticketId]);
  // must omit onChange to prevent loop

  const handleCompletion = useCallback(() => {
    pushSnackbar(createSuccessSnackbar("Ticket shared", 3000));
    setValues({ ...initialValues, bookingId: bookingId ?? "", ticketId: ticketId ?? "" });
    onCompletion();
    onClose();
  }, [ticketId, bookingId, pushSnackbar, onCompletion, onClose, setValues]);

  return (
    <FormModal
      active={active}
      form="share-ticket"
      onClose={onClose}
      title="Share Ticket"
      subTitle="Input the email of the person who you would like to share this ticket with."
      confirmButtonText="Send"
      confirmButtonProps={{ isLoading }}>
      <Form
        id="share-ticket"
        handle={handle}
        validation={validations.generic}
        resetValidations={resetValidations}
        onCompletion={handleCompletion}
        resetOnCompletion>
        <FormRow>
          <FormEntry label="Email" validation={validations.email} showValidationBeneath>
            <Input
              type="email"
              name="email"
              placeholder="example@email.com"
              value={values.email}
              size="medium"
              onBlur={onBlur}
              onChange={onChange}
            />
          </FormEntry>
        </FormRow>
      </Form>
    </FormModal>
  );
}
