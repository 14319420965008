import Button from "@components/form/Button";
import FormRow from "@components/form/FormRow";
import { flankDiscordUrl } from "@lib/constants/generic";
import { FaDiscord } from "react-icons/fa";
import { IoArrowForward } from "react-icons/io5";
import useFlankSignUpContext from "../context/useFlankSignUpContext";

export default function CompletionStep() {
  const { onClose } = useFlankSignUpContext();

  return (
    <>
      <FormRow className="completion-text-row">
        <p>
          We'll be in touch as soon as soon as our platform is live.
          <br />
          <br />
          Join our discord to be notified.
        </p>
      </FormRow>

      <div className="auth-button-row">
        <Button
          color="discord-blue"
          textColor="white"
          size="large"
          href={flankDiscordUrl}
          openInNewTab
          startIcon={<FaDiscord />}
          endIcon={<IoArrowForward />}>
          Join Discord
        </Button>
        <Button
          color="gray-1"
          textColor="white"
          size="large"
          justifyContent="center"
          onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  );
}
