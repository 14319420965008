import { RefObject, useEffect, useState } from "react";
import useForceRerender from "./useForceRerender";

const useMouseIsOver = (
  ref: RefObject<HTMLElement | SVGElement>,
  useParent?: boolean,
): [boolean, () => void, () => void] => {
  const [isOver, setIsOver] = useState(false);

  const [value, reregister] = useForceRerender();

  const handleOver = (state: boolean) => () => {
    setIsOver(state);
  };

  const handleOverTrue = handleOver(true);
  const handleOverFalse = handleOver(false);

  useEffect(() => {
    let current = ref.current;
    if (current == null) return;

    if (useParent) current = current.parentElement;
    if (current == null) return;

    current.addEventListener("mouseenter", handleOverTrue);
    current.addEventListener("mousemove", handleOverTrue);
    current.addEventListener("mouseleave", handleOverFalse);

    return () => {
      if (current == null) return;
      current.removeEventListener("mouseenter", handleOverTrue);
      current.removeEventListener("mousemove", handleOverTrue);
      current.removeEventListener("mouseleave", handleOverFalse);
    };
  }, [ref, useParent, value, handleOverTrue, handleOverFalse]);

  const reset = () => setIsOver(false);

  return [isOver, reset, reregister];
};

export default useMouseIsOver;
