import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { FreeSessionValues } from "../FreeSessionModal";

const useFreeSessionHandles: UseFormHandles<FreeSessionValues> = ({ validateFields }) => {
  const handle = async () => {
    let failed = validateFields(["email"]);

    return failed;
  };

  return [handle];
};

export default useFreeSessionHandles;
