import useGetExperienceRequests from "@api/private/get/hooks/useGetExperienceRequests";
import useGetCigProfile from "@api/public/get/hooks/useGetCigProfile";
import useGetGames from "@api/public/get/hooks/useGetGames";
import Avatar from "@components/common/Avatar";
import DataCheck from "@components/common/DataCheck";
import Button from "@components/form/Button";
import useQuery from "@hooks/useQuery";
import { numberToCurrency, parseTime, pluralise } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs from "dayjs";
import React from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { useParams } from "react-router-dom";

export default function ExperienceRequestPaymentCompletion() {
  const { requestId } = useParams<Params>();
  const state = useQuery<"success" | "error" | "cancel">("state") ?? "success";

  const { userExtension } = useGlobalContext();

  const {
    data: experienceRequests,
    isLoading: experienceRequestsIsLoading,
    error: experienceRequestsError,
  } = useGetExperienceRequests(true);

  const { data: games, isLoading: gamesIsLoading, error: gamesError } = useGetGames(true);

  const experienceRequest = experienceRequests.find(
    experienceRequest => experienceRequest.requestID === requestId,
  );

  const {
    cigID,
    gameID,
    platformID,

    packageName,

    start,
    end,

    tickets,
    streamOption,
    customerPrice,
    customerCurrency,
  } = experienceRequest ?? {};

  const game = games.find(game => game.id === gameID);

  const platform = game?.platforms.items.find(
    platform => platform.platform.id === platformID,
  )?.platform;

  const {
    data: cigProfile,
    isLoading: cigProfileIsLoading,
    error: cigProfileError,
  } = useGetCigProfile(cigID, undefined, userExtension?.id, true);

  const sessionLength = dayjs(end).diff(start, "minutes") ?? 0;

  const { hours, minutes } = parseTime(sessionLength * 60);

  const errorMessage = {
    cancel: "Request cancelled",
    error: "We were unable to complete your request",
    success: null,
  };

  const error = errorMessage[state] ?? experienceRequestsError;

  return (
    <div id="experiences-payment-completion-page">
      <DataCheck
        error={error}
        fullPage
        errorContent={
          <Button textColor="white" variant="flat" to="/" startIcon={<IoArrowBack />}>
            Leave
          </Button>
        }>
        <div className="request-summary">
          <h1 className="title">Your booking request has been received</h1>
          <h2 className="sub-title">
            <DataCheck
              isLoading={cigProfileIsLoading || experienceRequestsIsLoading}
              loadingIndicator="gloss">
              We'll send you an email once {cigProfile?.displayName} has accepted your request. No
              payment will be taken from your account until confirmed.
            </DataCheck>
          </h2>

          <div className="containers">
            <div className="container cig-summary">
              <DataCheck
                isLoading={cigProfileIsLoading || experienceRequestsIsLoading}
                error={cigProfileError}
                loadingIndicator="gloss">
                <Avatar
                  src={cigProfile?.profilePictureURL}
                  alt={cigProfile?.displayName}
                  size={48}
                />

                <div className="cig-details">
                  <p className="display-name">{cigProfile?.displayName}</p>
                  <p className="tag-line">{cigProfile?.tagLine}</p>
                </div>
              </DataCheck>
            </div>

            <div className="container data details">
              <DataCheck isLoading={experienceRequestsIsLoading} loadingIndicator="gloss">
                <div className="data-row">
                  <DataCheck
                    isLoading={gamesIsLoading || experienceRequestsIsLoading}
                    error={gamesError}
                    loadingIndicator="gloss">
                    <p className="label">Game</p>
                    <p className="value">{game?.longName}</p>
                  </DataCheck>
                </div>

                <div className="data-row">
                  <DataCheck
                    isLoading={gamesIsLoading || experienceRequestsIsLoading}
                    error={gamesError}
                    loadingIndicator="gloss">
                    <p className="label">Platform</p>
                    <p className="value">{platform?.name}</p>
                  </DataCheck>
                </div>

                <div className="data-row">
                  <p className="label">Package</p>
                  <p className="value">{packageName}</p>
                </div>

                <div className="data-row">
                  <p className="label">Livestreamed</p>
                  <p className="value">{streamOption ? "Yes" : "No"}</p>
                </div>

                <div className="data-row">
                  <p className="label">Date</p>
                  <p className="value">
                    {start && end && (
                      <>
                        {dayjs(start).format("DD MMMM YYYY")}
                        <br />
                        {dayjs(start).format("HH:mmA")} - {dayjs(end).format("HH:mmA")}
                      </>
                    )}
                  </p>
                </div>

                <div className="data-row">
                  <p className="label">Duration</p>
                  <p className="value">
                    {sessionLength > 0 && (
                      <>
                        {hours > 0 && `${hours}H`} {minutes > 0 && `${minutes}M`}
                      </>
                    )}
                  </p>
                </div>

                <div className="data-row">
                  <p className="label">Quantity</p>
                  <p className="value">
                    {tickets && tickets > 0 && tickets} {pluralise("ticket", tickets ?? 0)}
                  </p>
                </div>
              </DataCheck>
            </div>

            <div className="container data price">
              <DataCheck isLoading={experienceRequestsIsLoading} loadingIndicator="gloss">
                <div className="data-row">
                  <p className="label">Total</p>
                  <p className="value">
                    {customerPrice != null && customerCurrency != null
                      ? numberToCurrency(customerPrice, customerCurrency)
                      : "N/A"}
                  </p>
                </div>
              </DataCheck>
            </div>
          </div>

          <Button
            color="purple"
            textColor="white"
            size="large"
            endIcon={<IoArrowForward />}
            justifyContent="center"
            to="/my-experiences">
            View your requests
          </Button>
        </div>
      </DataCheck>
    </div>
  );
}

interface Params {
  requestId: string;
}
