import Modal from "@components/modals/Modal";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useState } from "react";
import ForgotPasswordContextProvider from "./context/ForgotPasswordContextProvider";
import ForgotPasswordInner from "./ForgotPasswordInner";

const ForgotPasswordModal = () => {
  const { authModal, setAuthModal } = useGlobalContext();

  const [step, setStep] = useState<number>(0);

  const active = authModal.type === "forgot-password";
  const close = () => setAuthModal(null);

  return (
    <Modal
      active={active}
      onClose={close}
      onlyCloseButton
      askBeforeClosing={step > 0}
      askModalProps={{
        title: "Recovery Incomplete!",
        subTitle: "Your password recovery isn't complete, are you sure you want to leave?",
        cancelButtonText: "Stay",
        confirmButtonText: "Leave",
      }}
      id="forgot-password-modal"
      className="auth-modal">
      <ForgotPasswordContextProvider onStepChange={setStep}>
        <ForgotPasswordInner />
      </ForgotPasswordContextProvider>
    </Modal>
  );
};

export default ForgotPasswordModal;
