import { RefObject, useCallback, useEffect, useState } from "react";

const useElementSize = (ref?: RefObject<HTMLElement | SVGElement>) => {
  const [size, setSize] = useState({ width: 0, height: 0, scrollWidth: 0, scrollHeight: 0 });

  const handleResize = useCallback(() => {
    const current = ref?.current ?? document.body;
    if (current == null) return;

    setSize({
      width: current.clientWidth,
      height: current.clientHeight,
      scrollWidth: current.scrollWidth,
      scrollHeight: current.scrollHeight,
    });
  }, [ref]);

  useEffect(() => {
    const current = ref?.current ?? document.body;
    if (current == null) return;

    const resizeObs = new ResizeObserver(handleResize);
    resizeObs.observe(current);
    const mutationObs = new MutationObserver(handleResize);
    mutationObs.observe(current, {
      attributes: false,
      characterData: false,
      childList: true,
      subtree: false,
    });

    return () => {
      resizeObs.disconnect();
      mutationObs.disconnect();
    };
  }, [handleResize, ref]);

  return size;
};

export default useElementSize;
