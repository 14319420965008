import { RefObject, useEffect } from "react";

const useOnIntersection = (ref?: RefObject<HTMLElement | SVGElement>, callback?: () => void) => {
  useEffect(() => {
    const current = ref?.current ?? document.body;
    if (!current || !callback) return;

    const observer = new IntersectionObserver(callback);
    observer.observe(current);

    return () => observer.disconnect();
  }, [ref, callback]);
};

export default useOnIntersection;
