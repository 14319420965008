import AuthWrapper from "@components/layout/auth/AuthWrapper";
import ConsultancySignUpForm from "./ConsultancySignUpForm";
import useConsultancySignUpContext from "./context/useConsultancySignUpContext";

const ConsultancySignUpInner = () => {
  const { step } = useConsultancySignUpContext();

  return (
    <AuthWrapper
      title={["Sign up", "Thanks for registering your interest. "][step]}
      isLoading={false}
      noAltAuth>
      <ConsultancySignUpForm />
    </AuthWrapper>
  );
};

export default ConsultancySignUpInner;
