import { cumulativeOffset } from "@lib/utils/generic";
import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useState } from "react";
import useGlobalContext from "../globalContext/hooks/useGlobalContext";
import useLastMousePosition from "./useLastMousePosition";
import useOnScroll from "./useOnScroll";

export type RelativePosition =
  | "top"
  | "top right"
  | "right"
  | "bottom right"
  | "bottom"
  | "bottom left"
  | "left"
  | "top left"
  | "center";

export type Position = "portal-absolute" | "portal-fixed" | "absolute" | "mouse";

const useRelativeCoordsAndSize = (
  rootRef: MutableRefObject<HTMLElement | null>,
  relativePosition: RelativePosition,
  position?: Position,
  disable?: boolean,
) => {
  const { breakpoints } = useGlobalContext();

  const mousePosition = useLastMousePosition();

  const [coords, setCoords] = useState<{ x: number; y: number }>();
  const [size, setSize] = useState({ width: 0, height: 0 });

  const positionRoot = useCallback(() => {
    const current = rootRef.current;
    if (current == null) return;

    let { width, height, left, top: viewTop } = current.getBoundingClientRect();
    const { top: docTop } = cumulativeOffset(current);

    let top = 0;

    if (position === "mouse") {
      top = mousePosition.y;
      left = mousePosition.x;
      width = 0;
      height = 0;
    } else {
      top = position === "portal-fixed" ? viewTop : docTop;
    }

    const coords = { x: left, y: top };
    switch (relativePosition) {
      case "top":
        coords.x += width / 2;
        break;
      case "top right":
        coords.x += width;
        break;
      case "right":
        coords.x += width;
        coords.y += height / 2;
        break;
      case "bottom right":
        coords.x += width;
        coords.y += height;
        break;
      case "bottom":
        coords.x += width / 2;
        coords.y += height;
        break;
      case "bottom left":
        coords.y += height;
        break;
      case "left":
        coords.y += height / 2;
        break;
      case "center":
        coords.x += width / 2;
        coords.y += height / 2;
        break;
    }

    coords.x = Math.floor(coords.x)
    coords.y = Math.floor(coords.y)
    
    width = Math.floor(width)
    height = Math.floor(height)

    setCoords(coords);
    setSize({ width, height });
  }, [rootRef, relativePosition, position, mousePosition]);

  useEffect(() => {
    const current = rootRef.current;
    if (current == null) return;

    const handleChange = () => {
      if (position === "mouse") return;
      positionRoot();
      setTimeout(() => positionRoot(), 300);
    };

    const resizeObserver = new ResizeObserver(handleChange);
    const mutationObserver = new MutationObserver(handleChange);
    resizeObserver.observe(current);
    mutationObserver.observe(current, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: false,
      attributeFilter: ["style", "class", "id"],
    });

    return () => {
      if (current != null) resizeObserver.unobserve(current);
      mutationObserver.disconnect();
    };
    // need rootRef.current to cause updates
    // eslint-disable-next-line
  }, [rootRef.current, relativePosition, position]);

  useOnScroll(undefined, () => positionRoot(), true, disable);

  useLayoutEffect(
    () => positionRoot(),
    // need rootRef.current to cause updates
    // eslint-disable-next-line
    [rootRef.current, breakpoints],
  );

  return { coords, size, reCalculate: positionRoot };
};

export default useRelativeCoordsAndSize;
