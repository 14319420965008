import FanTestimonialsSplash from "@components/splash/sections/FanTestimonialsSplash";

import peachishImg from "@images/becomeTalent/fanTestimonials/peachish.jpg";
import zootocaImg from "@images/becomeTalent/fanTestimonials/zootoca.jpg";
import minaImg from "@images/becomeTalent/fanTestimonials/mina.jpg";

const FanTestimonials = () => {
  return (
    <FanTestimonialsSplash
      title="What have your fans got to say?"
      fanTestimonials={[
        {
          avatarImg: peachishImg,
          displayName: "Peachish",
          text: (
            <>
              It was super fun!
              <br />
              <br />
              We did a funny mode so there was no chance to tryhard and pretty balanced. Lots of
              funny moments, mostly CanceL just being goofy.
            </>
          ),
        },
        {
          avatarImg: zootocaImg,
          displayName: "Zootoca",
          text: (
            <>
              It was an amazing experience to be able to play with strong players like them.
              <br />
              <br />I try to join every yakkr session I can!
            </>
          ),
        },
        {
          avatarImg: minaImg,
          displayName: "Mina",
          text: (
            <>
              It was really fun and entertaining! The great thing about it was all the interactions
              and being able to joke around.
              <br />
              <br />
              I'll definitely book again.
            </>
          ),
        },
      ]}
    />
  );
};

export default FanTestimonials;
