import DataCheck from "@components/common/DataCheck";
import IconButton from "@components/form/IconButton";
import Fade from "@components/transitions/Fade";
import useMouseIsOver from "@hooks/useMouseIsOver";
import usePrevious from "@hooks/usePrevious";
import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import { forwardRef, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import {
  IoPauseCircleOutline,
  IoPlayCircleOutline,
  IoVolumeHigh,
  IoVolumeMute,
} from "react-icons/io5";

interface Props extends CSSProps {
  src?: string;
  autoPlay?: boolean;

  bgColor?: string;

  isLoading?: boolean;
  error?: string;

  doReset?: boolean;

  onPlay?: () => void;
}

export type ClipProps = Props;

const Clip = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, forwardedRef) => {
  const {
    src,
    autoPlay,

    bgColor = "black-3",

    isLoading,
    error,

    doReset,

    onPlay = () => {},

    className,
    children,
    ...cssProps
  } = props;

  const videoRef = useRef<HTMLVideoElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const [isBuffering, setIsBuffering] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [muted, setMuted] = useState(autoPlay);

  const prevSrc = usePrevious(src);

  const [isHovering] = useMouseIsOver(overlayRef);

  useEffect(() => {
    if (src !== prevSrc) setIsBuffering(true);
  }, [src, prevSrc]);

  const handleLoad = useCallback(() => {
    setIsBuffering(false);
  }, [setIsBuffering]);

  const play = useCallback(() => {
    const { current } = videoRef;
    if (!current) return;
    current.play();
  }, []);

  const pause = useCallback(() => {
    const { current } = videoRef;
    if (!current) return;
    current.pause();
  }, []);

  const restart = useCallback(() => {
    const { current } = videoRef;
    if (!current) return;
    current.currentTime = 0;
  }, []);

  const reset = useCallback(() => {
    pause();
    restart();
  }, [pause, restart]);

  const toggleAudio = () => setMuted(muted => !muted);

  useEffect(() => {
    if (doReset) reset();
  }, [doReset, reset]);

  return (
    <div
      className={classNames("clip", `${bgColor}-bg`, className)}
      ref={forwardedRef}
      {...cssProps}>
      <video
        src={src}
        onProgress={handleLoad}
        onPlaying={() => {
          setIsPlaying(true);
          onPlay();
        }}
        onPause={() => setIsPlaying(false)}
        onEnded={restart}
        controls={false}
        muted={muted}
        autoPlay={autoPlay}
        playsInline
        ref={videoRef}
      />
      <DataCheck isLoading={isBuffering || isLoading} error={error} loadingIndicator="spinner">
        <div className="overlay" ref={overlayRef}>
          <Fade in={isPlaying}>
            <IconButton
              variant="flat"
              icon={muted ? <IoVolumeMute /> : <IoVolumeHigh />}
              color="white"
              size="small"
              rounded
              className="audio-button"
              onClick={toggleAudio}
            />
          </Fade>

          <Fade in={isPlaying && isHovering}>
            <IconButton
              variant="flat"
              icon={<IoPauseCircleOutline />}
              color="white"
              size="extra-large"
              rounded
              className="pause-button"
              onClick={pause}
            />
          </Fade>

          <Fade in={!isPlaying}>
            <IconButton
              variant="flat"
              icon={<IoPlayCircleOutline />}
              color="white"
              size="extra-large"
              rounded
              className="play-button"
              onClick={play}
            />
          </Fade>

          <Fade in={!isPlaying}>
            <div className="content">{children}</div>
          </Fade>
        </div>
      </DataCheck>
    </div>
  );
});

export default Clip;
