import { classNames, isArray } from "@lib/utils/generic";
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { CSSProps } from "../../lib/types/generic";
import { ButtonProps } from "./Button";
import { FormElement } from "./Form";

interface SingleProps extends Props {
  type?: "single";
  value?: string | number;
  onChange?: (target: FormElement<string | number>) => void;
  onBlur?: (target: FormElement<string | number>) => void;
}

interface MultiProps extends Props {
  type?: "multi";
  value?: Array<string | number>;
  onChange?: (target: FormElement<Array<string | number>>) => void;
  onBlur?: (target: FormElement<Array<string | number>>) => void;
}

interface Props extends CSSProps {
  disabled?: boolean;
  name?: string;
  selectedButtonProps?: ButtonProps;
}

export type ButtonSelectProps = SingleProps | MultiProps;

function isMultiProps(props: ButtonSelectProps): props is MultiProps {
  return props.type === "multi";
}

const ButtonSelect = forwardRef<HTMLDivElement, PropsWithChildren<ButtonSelectProps>>(
  (props, ref) => {
    const {
      children,
      disabled = false,
      selectedButtonProps,

      name = "",
      value: _value,

      style,
      id,
      className = "",
    } = props;

    const [value, setValue] = useState(_value);

    const handleClick = (buttonName: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      let newValue = isArray(value) ? [...value] : value;

      if (isArray(newValue)) {
        const index = newValue.indexOf(buttonName);
        if (index > -1) newValue.splice(index, 1);
        else newValue.push(buttonName);
      } else {
        newValue = buttonName;
      }

      setValue(newValue);

      if (props.onChange == null) return;
      if (isMultiProps(props) && isArray(newValue)) props.onChange({ name, value: newValue });
      else if (!isMultiProps(props) && !isArray(newValue))
        props.onChange({ name, value: newValue });
    };

    const handleBlur = () => {
      if (value == null || props.onBlur == null) return;

      if (isMultiProps(props) && isArray(value)) props.onBlur({ name, value });
      else if (!isMultiProps(props) && !isArray(value)) props.onBlur({ name, value });
    };

    useEffect(() => {
      setValue(_value);
    }, [_value]);

    useEffect(() => {
      if (disabled) {
        if (isArray(value)) setValue([]);
        else setValue(undefined);
      }
      // eslint-disable-next-line
    }, [disabled]);
    // prevents infinite loop

    return (
      <div
        ref={ref}
        className={classNames("button-select", disabled && "disabled", className)}
        id={id}
        style={style}>
        {Children.map(children, child => {
          if (!isValidElement(child)) return;

          const selected = isArray(value)
            ? value.includes(child.props.name)
            : value === child.props.name;

          return cloneElement(child as any, {
            onClick: handleClick(child.props.name),
            onBlur: handleBlur,
            disabled: disabled || child.props.disabled,
            active: selected,
            ...(selected ? selectedButtonProps : {}),
          });
        })}
      </div>
    );
  },
);

export default ButtonSelect;
