import React from "react";
import GradientIcon, { GradientIconInnerProps, GradientIconProps } from "./GradientIcon";

const IoCardGradient = (props: GradientIconProps) => (
  <GradientIcon {...props}>
    {({ gradientDefinition, label, ...cssProps }: GradientIconInnerProps) => (
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        {...cssProps}>
        <path
          d="M32 376C32 390.852 37.9 405.096 48.402 415.598C58.9041 426.1 73.1479 432 88 432H424C438.852 432 453.096 426.1 463.598 415.598C474.1 405.096 480 390.852 480 376V222H32V376ZM98 300C98 292.044 101.161 284.413 106.787 278.787C112.413 273.161 120.044 270 128 270H176C183.956 270 191.587 273.161 197.213 278.787C202.839 284.413 206 292.044 206 300V320C206 327.956 202.839 335.587 197.213 341.213C191.587 346.839 183.956 350 176 350H128C120.044 350 112.413 346.839 106.787 341.213C101.161 335.587 98 327.956 98 320V300ZM424 80H88C73.1479 80 58.9041 85.9 48.402 96.402C37.9 106.904 32 121.148 32 136V162H480V136C480 121.148 474.1 106.904 463.598 96.402C453.096 85.9 438.852 80 424 80V80Z"
          fill={label ? `url(#${label})` : undefined}
        />
        {gradientDefinition && (
          <defs>
            <linearGradient
              id={label}
              gradientUnits="userSpaceOnUse"
              gradientTransform={`rotate(${gradientDefinition.rotation})`}>
              {gradientDefinition.stopColors.map(({ stopColor, offset }, i) => (
                <stop key={i} offset={offset} stopColor={stopColor} />
              ))}
            </linearGradient>
          </defs>
        )}
      </svg>
    )}
  </GradientIcon>
);

export default IoCardGradient;
