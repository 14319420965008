import { forwardRef, ReactElement, useRef, useState } from "react";
import { useEffect } from "react";
import { CSSProps, JustifyContent, UIColor } from "../../lib/types/generic";
import { FormElement } from "./Form";
import { Position, RelativePosition } from "../../hooks/useRelativeCoordsAndSize";
import { Dayjs } from "dayjs";
import { IoCalendar, IoChevronDown } from "react-icons/io5";
import useOnMouseUp from "@hooks/useOnMouseUp";
import Button from "./Button";
import { classNames } from "@lib/utils/generic";
import IncrementPicker from "./IncrementPicker";
import RelativePortal from "@components/common/RelativePortal";
import SimpleCalendar from "./SimpleCalendar";

interface Props extends CSSProps {
  position?: Position;
  relativePosition?: RelativePosition;
  color?: UIColor;
  textColor?: UIColor;
  pickerColor?: UIColor;
  pickerTextColor?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  startIcon?: ReactElement;
  endIcon?: ReactElement;

  name?: string;
  maxDate?: Dayjs;
  minDate?: Dayjs;

  justifyContent?: JustifyContent;
  rounded?: boolean;
  disabled?: boolean;
  disableHover?: boolean;
  isLoading?: boolean;
  error?: string;
  value: Dayjs;

  onChange?: (target: FormElement<Dayjs>) => void;
  onClose?: (value: Dayjs) => void;
}

export type DatePickerProps = Props;

const DatePicker = forwardRef<HTMLButtonElement & HTMLAnchorElement, Props>((props, ref) => {
  const {
    position = "absolute",
    relativePosition = "bottom",
    color = "white",
    textColor = "black",
    pickerColor = color,
    pickerTextColor = textColor,
    variant = "contained",
    size = "medium",
    startIcon = <IoCalendar />,
    endIcon = <IoChevronDown className="chevron" />,

    name,
    maxDate,
    minDate,

    justifyContent = "space-between",
    rounded,
    disabled,
    disableHover,
    isLoading,
    error,

    value,
    onChange = () => {},
    onClose = () => {},

    className = "",
    id,
    style,
  } = props;

  const [active, setActive] = useState(false);
  const [handled, setHandled] = useState(false);

  const nodes = (
    <Inner
      color={pickerColor}
      textColor={pickerTextColor}
      variant={variant}
      name={name}
      maxDate={maxDate}
      minDate={minDate}
      rounded={rounded}
      value={value}
      onChange={onChange}
      onClose={value => {
        if (handled) return;
        setHandled(true);
        onClose(value);
        setActive(false);
      }}
    />
  );

  useEffect(() => {
    if (handled) setTimeout(() => setHandled(false), 50);
  }, [handled]);

  return (
    <div className="date-picker-wrapper">
      <RelativePortal
        active={active}
        content={nodes}
        relativePosition={relativePosition}
        position={position}
        className="z-select">
        <div>
          <Button
            ref={ref}
            color={color}
            textColor={textColor}
            variant={variant}
            size={size}
            startIcon={startIcon}
            endIcon={endIcon}
            name={name}
            type="button"
            justifyContent={justifyContent}
            rounded={rounded}
            disabled={error != null || disabled}
            disableHover={disableHover}
            isLoading={isLoading}
            active={active}
            className={classNames("date-picker", className)}
            id={id}
            style={style}
            onClick={() => {
              if (handled) return;
              setHandled(true);
              setActive(true);
            }}>
            {value.format("MMM DD YYYY")}
          </Button>
        </div>
      </RelativePortal>
    </div>
  );
});

export default DatePicker;

interface InnerProps {
  color?: UIColor;
  textColor?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent";

  name?: string;
  maxDate?: Dayjs;
  minDate?: Dayjs;

  rounded?: boolean;
  value: Dayjs;

  onChange?: (target: FormElement<Dayjs>) => void;
  onClose?: (value: Dayjs) => void;
}

const Inner = (props: InnerProps) => {
  const {
    color = "white",
    textColor = "black",
    variant = "contained",

    name,
    maxDate,
    minDate,

    rounded,

    value,

    onChange = () => {},
    onClose = () => {},
  } = props;

  const [currentMonth, setCurrentMonth] = useState(value.startOf("month"));

  const goToPrevMonth = () => setCurrentMonth(currentMonth.subtract(1, "month"));
  const goToNextMonth = () => setCurrentMonth(currentMonth.add(1, "month"));

  const datePickerRef = useRef<HTMLDivElement>(null);

  useOnMouseUp(
    undefined,
    () => {
      const activeElement = document.activeElement;
      const datePicker = datePickerRef.current;

      const datePickerFocussed =
        datePicker?.contains(activeElement) || datePicker === activeElement;

      if (datePickerFocussed) return;
      onClose(value);
    },
    undefined,
  );

  useEffect(() => {
    const { current } = datePickerRef;
    if (current) current.focus();
  }, []);

  return (
    <div
      className={classNames(
        "date-picker-calendar",
        color,
        `${textColor}-text`,
        variant,
        rounded && "rounded",
      )}>
      <div className="date-picker-calendar-inner" ref={datePickerRef} tabIndex={0}>
        <IncrementPicker
          size="medium"
          color={color}
          textColor={textColor}
          iconColor={textColor}
          variant="flat"
          value={currentMonth.format("MMMM YYYY")}
          rounded={rounded}
          onBack={goToPrevMonth}
          onForward={goToNextMonth}
          className="date-picker-increment-picker"
        />
        <SimpleCalendar
          color={color}
          headTextColor={textColor}
          bodyTextColor={textColor}
          variant={variant}
          size="small"
          name={name}
          maxDate={maxDate}
          minDate={minDate}
          rounded={rounded}
          month={currentMonth}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
