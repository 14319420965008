import useOnMouseMove from "@hooks/useOnMouseMove";
import useOnMouseUp from "@hooks/useOnMouseUp";
import useOnTouchEnd from "@hooks/useOnTouchEnd";
import useOnTouchMove from "@hooks/useOnTouchMove";
import { Session } from "@lib/types/session";
import { createErrorSnackbar, getCoordsFromGestureEvent } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";

const useBlockSelector = (
  availability: Session[],
  goToNextPeriod: () => void,
  goToPrevPeriod: () => void,
) => {
  const { pushSnackbar } = useGlobalContext();

  const [selecting, setSelecting] = useState(false);
  const [anchorDate, setAnchorDate] = useState<Dayjs | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [xDirection, setXDirection] = useState(0);
  const [yDirection, setYDirection] = useState(0);
  const [yAcceleration, setYAcceleration] = useState(1);
  const [selectError, setSelectError] = useState<string | null>(null);
  const [overlappingSessionId, setOverlappingSessionId] = useState<string | null>(null);

  useEffect(() => {
    if (selectError) pushSnackbar(createErrorSnackbar(selectError, 5000));
    // eslint-disable-next-line
  }, [selectError]);
  // must omit pushSnackbar to prevent loop

  const blocksRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLElement>(document.body);

  const scroll = useCallback((offset: number) => {
    const current = blocksRef.current;
    if (current == null) return;
    const { scrollTop } = current;

    current.scrollTo({
      top: scrollTop + offset,
    });
  }, []);

  const onMouseMove = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const current = blocksRef.current;
      if (current == null) return;

      const coords = getCoordsFromGestureEvent(event, true);
      if (coords == null) return;

      const { height, y, width, x } = current.getBoundingClientRect();
      const posY = coords.y - y;
      const posX = coords.x - x;

      const tollerance = 30;

      const isAtBottom = posY + tollerance > height && selecting;
      const isAtTop = posY - tollerance < 0 && selecting;
      const isAtLeft = posX - tollerance < 0 && selecting;
      const isAtRight = posX + tollerance > width && selecting;

      const offset = isAtTop ? -tollerance : isAtBottom ? tollerance : 0;

      setYDirection(offset / 16);

      if (isAtRight) return setXDirection(1);
      if (isAtLeft) return setXDirection(-1);
      setXDirection(0);
    },
    [selecting],
  );

  const resetErrors = () => {
    setSelectError(null);
    setOverlappingSessionId(null);
  };

  const checkOverlapping = useCallback(
    (startDate: Dayjs | null, endDate: Dayjs | null) => {
      if (!startDate || !endDate) return;
      const overlappingSession = getIsOverlapping(availability, startDate, endDate);
      if (overlappingSession) {
        setOverlappingSessionId(overlappingSession.id);
        setSelectError("Sessions cannot overlap");
      }
      return !!overlappingSession;
    },
    [availability],
  );

  const setDates = useCallback(
    (date: Dayjs, override?: boolean) => {
      if ((selecting || override) && anchorDate != null) {
        const newStartDate = date.isSameOrBefore(anchorDate) ? date : anchorDate;
        const newEndDate = (date.isSameOrBefore(anchorDate) ? anchorDate : date).add(15, "minutes");

        if (checkOverlapping(newStartDate, newEndDate)) return;
        resetErrors();

        if (!newStartDate?.isSame(startDate)) setStartDate(newStartDate);
        if (!newEndDate?.isSame(endDate)) setEndDate(newEndDate);
      }
    },
    [selecting, anchorDate, endDate, startDate, checkOverlapping],
  );

  const startSelecting = (date: Dayjs, onlyStartDate?: boolean) => {
    if (selecting) return false;

    if (checkOverlapping(date, date.add(15, "minutes"))) return;
    resetErrors();

    if (!onlyStartDate) setSelecting(true);
    if (!onlyStartDate) setEndDate(date.add(15, "minutes"));
    setStartDate(date);
    setAnchorDate(date);
    return true;
  };

  const stopSelecting = (date: Dayjs, override?: boolean) => {
    setYAcceleration(0);
    setDates(date, override);
    setSelecting(false);
  };

  const resetSelection = () => {
    setSelecting(false);
    setStartDate(null);
    setEndDate(null);
    setAnchorDate(null);
  };

  useOnMouseMove(bodyRef, onMouseMove);
  useOnTouchMove(bodyRef, onMouseMove);
  useOnMouseUp(bodyRef, () => setSelecting(false));
  useOnTouchEnd(bodyRef, () => setSelecting(false));

  useEffect(() => {
    if (yDirection === 0) setYAcceleration(1);
    if (yDirection === 0 || selectError != null) return;
    scroll(yDirection * 10);
    const intervalId = setInterval(() => {
      scroll(yDirection * 10 * yAcceleration);
      setYAcceleration(yAcceleration => yAcceleration * 1.5);
    }, 100);
    return () => clearInterval(intervalId);
  }, [yDirection, yAcceleration, selectError, scroll]);

  useEffect(() => {
    if (xDirection === 0 || selectError != null) return;
    const intervalId = setInterval(() => {
      if (xDirection > 0) {
        goToNextPeriod();
      } else if (xDirection < 0) {
        goToPrevPeriod();
      }
      if (xDirection !== 0) {
        blocksRef.current?.dispatchEvent(new TouchEvent("touchstart"));
      }
    }, 750);
    return () => clearInterval(intervalId);
  }, [xDirection, selectError, goToPrevPeriod, goToNextPeriod]);

  return {
    selecting,
    blocksRef,
    startDate,
    endDate,
    selectError,
    overlappingSessionId,
    xDirection,
    yDirection,
    startSelecting,
    stopSelecting,
    setDates,
    setStartDate,
    setEndDate,
    resetErrors,
    resetSelection,
  };
};

const getIsOverlapping = (availability: Session[], selectionStart: Dayjs, selectionEnd: Dayjs) => {
  return availability.find(
    ({ from, to }) => dayjs(to).isAfter(selectionStart) && dayjs(from).isBefore(selectionEnd),
  );
};

export default useBlockSelector;
