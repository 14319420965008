import { api } from "@api/api";

interface CancelCIGAvailabilityRes {}

const cancelCigAvailability = async (availabilityID: string) => {
  const { data } = await api.post<CancelCIGAvailabilityRes>("/cigCancelAvailability", {
    availabilityID,
  });
  return data;
};

export default cancelCigAvailability;
