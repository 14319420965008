import React, { ReactNode } from "react";
import BecomeTalentOldHeader from "../BecomeTalentOldHeader";
import DataCheck from "@components/common/DataCheck";
import SlideOnIntersect from "@components/common/SlideOnIntersect";

import flexImg from "@images/becomeTalentOld/why/flex.png";
import playImg from "@images/becomeTalentOld/why/play.png";
import moneyImg from "@images/becomeTalentOld/why/money.png";

export default function Why() {
  return (
    <section className="section why">
      <BecomeTalentOldHeader
        title={
          <>
            You should start playing through Yakkr.
            <br />
            'Why?' you ask...
          </>
        }
        subTitle="From organising the session through to rage quitting the game, Yakkr is the place to host your exclusive gaming interactions."
      />

      <div className="cards-wrapper">
        <Card
          img={flexImg}
          title="Flex"
          content={
            <>
              Schedule sessions that suit you and your fans.
              <br />
              <br />
              Tell your followers and let them reserve their place.
              <br />
              <br />
              Jump in a late night lobby together.
              <br />
              It's on!
            </>
          }
          timeout={500}
        />

        <Card
          img={playImg}
          title="Play"
          content={
            <>
              Join the private chat room and get playing, laughing and being trash at the game
              together.
              <br />
              <br />
              You have full control of who is in the game space and chat.
              <br />
              <br />
              Just wholesome, good ol' fashioned gaming.
            </>
          }
          timeout={700}
        />

        <Card
          img={moneyImg}
          title="Mon-eyy!"
          content={
            <>
              You're the Talent. We just give you the tools to play how you wish.
              <br />
              <br />
              Charge a fee, play for free, receive tips or raise for charity.
              <br />
              <br />
              Earn however you want using our suite of income tools.
            </>
          }
          timeout={800}
        />
      </div>
    </section>
  );
}

interface CardProps {
  img: string;
  title: string;
  content: ReactNode;
  timeout: number;
}

const Card = ({ img, title, content, timeout }: CardProps) => (
  <SlideOnIntersect direction="right" timeout={timeout}>
    <div className="card">
      <div className="card-img-wrapper">
        <DataCheck imgs={[img]} loadingIndicator="gloss">
          <img src={img} alt="Flex" className="card-img" />
        </DataCheck>
      </div>

      <div className="content-wrapper">
        <h3 className="title">{title}</h3>
        <p className="text">{content}</p>
      </div>
    </div>
  </SlideOnIntersect>
);
