import { useState } from "react";

export type AuthModalType = "login" | "register" | "forgot-password" | null;
export type AuthModalData<D = Record<any, any>> = D;

export type AuthModalDetails<D = Record<any, any>> = {
  type: AuthModalType;
  data: AuthModalData<D>;
};
export type SetAuthModal = (type: AuthModalType, data?: AuthModalData) => void;

const useAuthModal = () => {
  const [type, setType] = useState<AuthModalType>(null);
  const [data, setData] = useState<AuthModalData>({});

  const setAuthModal: SetAuthModal = (type, data = {}) => {
    setType(type);
    setData(data);
  };

  const authModal: AuthModalDetails = {
    type,
    data,
  };

  return { authModal, setAuthModal };
};

export default useAuthModal;
