import { GamePlatformSelection } from "@api/private/update/updateExperienceCustom";
import { FormValidation } from "@lib/types/form";
import { UpdateExperienceCustomValues } from "@pages/experiences/manage/update/custom/hooks/useExperienceCustomUpdate";
import dayjs from "dayjs";
import { Validator } from "../Validator";

const globalValidators = {
  firstName: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(100);
  },
  surname: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(100);
  },
  email: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").regex.isEmail();
  },
  password: (validation: FormValidation, value: any) => {
    new Validator(validation, value)
      .exists()
      .is("string")
      .length.lessThanOrEqualTo(100)
      .length.greaterThanOrEqualTo(8);
  },
  confirmPassword: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(100);
  },
  displayName: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(100);
  },
  dob: (validation: FormValidation, value: any) => {
    new Validator(validation, value)
      .exists()
      .is("object")
      .custom(value => {
        const dob = dayjs(value);
        const now = dayjs();

        if (!dob.isValid()) {
          return "Invalid date";
        } else if (now.subtract(18, "years").isBefore(dob)) {
          return "You must be at least 18 years old to use Yakkr";
        }
      });
  },
  terms: (validation: FormValidation, value: any) => {
    new Validator(validation, value)
      .exists()
      .is("boolean")
      .custom(value => {
        if (value !== true) {
          validation.list.push({
            text: "You must agree to the terms of service",
            type: "error",
          });
        }
      });
  },
  emailConfirmCode: (validation: FormValidation, value: any) => {
    new Validator(validation, value)
      .exists()
      .is("string")
      .length.equalTo(6)
      .custom(() => {
        if (isNaN(parseInt(value))) return "Must be made up of numbers only";
      });
  },
  confirmText: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string");
  },
  streamOptionPricePerPeriod: (
    validation: FormValidation,
    value: any,
    { streamOptionEnabled }: UpdateExperienceCustomValues,
  ) =>
    new Validator(validation, value)
      .skipIf(!streamOptionEnabled)
      .exists()
      .is("number")
      .number.greaterThanOrEqualTo(2.5),
  gamePlatformSelections: (validation: FormValidation, value: any, values: any) =>
    new Validator(validation, value)
      .skipIf(values.anyGame)
      .exists()
      .is("array")
      .length.greaterThan(0)
      .custom((value: GamePlatformSelection[]) => {
        if (values.anyGame) return;
        for (const { gameID, platformID } of value) {
          if (!gameID) validation.list.push({ type: "error", text: "Game cannot be empty" });
          if (!platformID)
            validation.list.push({ type: "error", text: "Platform cannot be empty" });
        }
      }),
};

export default globalValidators;
