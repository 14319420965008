import { FeedbackValues } from "./useFeedback";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import useCreateTipCheckout from "@api/private/create/hooks/useCreateTipCheckout";
import useCreateReviewMessage from "@api/private/create/hooks/useCreateReviewMessage";
import useCreateReviewRating from "@api/private/create/hooks/useCreateReviewRating";

const useFeedbackHandles: UseFormHandles<FeedbackValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { send: sendRating, isLoading: ratingIsLoading } = useCreateReviewRating(
    values.sessionId,
    values.rating * 2,
  );
  const { send: sendTip, isLoading: tipIsLoading } = useCreateTipCheckout(
    values.sessionId,
    values.amount,
  );
  const { send: sendMessage, isLoading: messageIsLoading } = useCreateReviewMessage(
    values.sessionId,
    values.showDisplayName,
    values.message,
  );

  const ratingHandle = async () => {
    let failed = false;

    failed = validateFields(["rating"]);

    if (!failed) {
      const { error } = await sendRating();

      if (error) {
        failed = true;

        validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    return failed;
  };

  const tipHandle = async () => {
    let failed = false;

    failed = validateFields(["amount"]);

    if (!failed) {
      const { error, data } = await sendTip();

      if (error) {
        failed = true;

        validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }

      if (data) window.open(data.url);
    }

    return failed;
  };

  const messageHandle = async () => {
    let failed = false;

    failed = validateFields(["message", "showDisplayName"]);

    if (!failed) {
      const { error } = await sendMessage();

      if (error) {
        failed = true;

        validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    return failed;
  };

  return [ratingHandle, tipHandle, messageHandle, ratingIsLoading, tipIsLoading, messageIsLoading];
};

export default useFeedbackHandles;
