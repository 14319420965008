import { RefObject, useCallback, useEffect } from "react";

const useOnMouseUp = (
  ref?: RefObject<HTMLElement>,
  callback = (event: MouseEvent) => {},
  button?: number,
  disabled?: boolean,
) => {
  const handleMouseUp = useCallback(
    (event: MouseEvent) => {
      if (button == null) return callback(event);
      if (event.button === button) return callback(event);
    },
    [button, callback],
  );

  useEffect(() => {
    const current = ref?.current ?? document.body;
    if (current == null) return;
    if (!disabled) current.addEventListener("mouseup", handleMouseUp);
    return () => current.removeEventListener("mouseup", handleMouseUp);
  }, [handleMouseUp, ref, disabled]);
};

export default useOnMouseUp;
