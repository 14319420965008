import {
  cloneElement,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { classNames, pathHasLayout } from "../../lib/utils/generic";

import Avatar from "@components/common/Avatar";
import {
  IoBook,
  IoCard,
  IoDocumentText,
  IoEllipsisVertical,
  IoHelp,
  IoInformation,
  IoInformationCircle,
} from "react-icons/io5";
import { aboutSiteUrl, assetPackUrl, helpSiteUrl } from "@lib/constants/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import Button from "@components/form/Button";
import IconButton from "@components/form/IconButton";
import HoverTooltip from "@components/common/HoverTooltip";
import Logo from "@components/common/Logo";
import Divider from "@components/common/Divider";
import Menu from "@components/form/Menu";
import NavCta from "./NavCta";
import Drawer from "@components/common/Drawer";
import YakkrBanner from "@components/common/YakkrBanner";
import IoHomeGradient from "@components/icons/IoHomeGradient";
import IoPersonGradient from "@components/icons/IoPersonGradient";
import IoCalendarGradient from "@components/icons/IoCalendarGradient";
import IoSettingsGradient from "@components/icons/IoSettingsGradient";
import IoSparklesGradient from "@components/icons/IoSparklesGradient";
import Changelog from "./Changelog";
import useStorageItem from "@hooks/sessionStore/useStorageItem";
import Tooltip from "@components/common/Tooltip";

interface Props {
  type: "permanent" | "temporary";
}

export default function Nav(props: Props) {
  const { type = "permanent" } = props;

  const location = useLocation();

  const {
    breakpoints,
    user,
    userExtension,
    cigProfile,
    drawerActive,
    drawerExpanded,
    setDrawerActive,
    setDrawerExpanded,

    customerPendingExperienceRequests,

    descendingChangelog,
  } = useGlobalContext();

  const lastVersionItem = useStorageItem<string>(
    "last-version-seen",
    descendingChangelog[0].version,
  );

  const unseenVersionsCount = descendingChangelog.findIndex(
    ({ version }) => lastVersionItem.item.data === version,
  );

  const [drawerTransitioning, setDrawerTransitioning] = useState(drawerActive);

  useEffect(() => setDrawerActive(false), [location, setDrawerActive]);

  const isLoggedIn = !!user;
  const isCig = !!cigProfile;

  const displayName = (isCig ? cigProfile.displayName : userExtension?.displayName) ?? "Unknown";

  const hasLayout = pathHasLayout(location.pathname);
  if (!hasLayout) return null;

  return (
    <Drawer
      position="fixed"
      type={type}
      className="nav-drawer"
      style={{ height: window.innerHeight }}
      noCloseButton
      drawerActive={drawerActive}
      drawerExpanded={drawerExpanded}
      onClose={() => setDrawerActive(false)}
      onTransitionStart={() => setDrawerTransitioning(true)}
      onTransitionFinish={() => setDrawerTransitioning(false)}
      setDrawerExpanded={setDrawerExpanded}>
      {drawerExpanded ? (
        <YakkrBanner color="white" to="/" onClick={() => setDrawerActive(false)} />
      ) : (
        <Logo color="white" to="/" onClick={() => setDrawerActive(false)} />
      )}
      <ul className="nav-tabs">
        <NavTab path="/" exactMatch icon={<IoHomeGradient />}>
          Home
        </NavTab>

        {isCig && (
          <NavTab path="/my-profile" icon={<IoPersonGradient />}>
            Profile
          </NavTab>
        )}

        <NavTab path="/my-experiences" icon={<IoSparklesGradient />}>
          Experiences
          {customerPendingExperienceRequests.length > 0 && (
            <> ({customerPendingExperienceRequests.length})</>
          )}
        </NavTab>

        <NavTab path="/bookings" icon={<IoCalendarGradient />}>
          Bookings
        </NavTab>

        <NavTab path="/settings" icon={<IoSettingsGradient />}>
          Settings
        </NavTab>

        {isCig && (
          <NavTab path="/settings/payment" icon={<IoCard />} isSubTab>
            Payment
          </NavTab>
        )}

        <Divider color="black-4" />

        <Menu
          relativePosition={breakpoints.sm ? "bottom" : "right"}
          variant="contained"
          color="black-3"
          content={
            <>
              <Button
                size="small"
                variant="contained"
                color="black-3"
                textColor="white"
                startIcon={<IoBook />}
                justifyContent="flex-start"
                href={assetPackUrl}
                openInNewTab>
                Get more bookings
              </Button>
              <Button
                size="small"
                variant="contained"
                color="black-3"
                textColor="white"
                startIcon={<IoHelp />}
                justifyContent="flex-start"
                href={helpSiteUrl}
                openInNewTab>
                Help
              </Button>
              <Button
                size="small"
                variant="contained"
                color="black-3"
                textColor="white"
                startIcon={<IoInformation />}
                justifyContent="flex-start"
                href={aboutSiteUrl}
                openInNewTab>
                About
              </Button>
              <Button
                size="small"
                variant="contained"
                color="black-3"
                textColor="white"
                startIcon={<IoDocumentText />}
                justifyContent="flex-start"
                to="/report-issue">
                Report an Issue
              </Button>
            </>
          }>
          <NavTab icon={<IoEllipsisVertical />}>More</NavTab>
        </Menu>

        {isCig && (
          <Changelog>
            <NavTab icon={<IoDocumentText />}>
              <Tooltip
                color="black-1"
                noWrap
                active={unseenVersionsCount > 0}
                killTooltip={drawerTransitioning || !drawerActive || !drawerExpanded}
                relativePosition={breakpoints.sm ? "bottom" : "right"}
                content={
                  <div className="flex-row align-center" style={{ gap: 8 }}>
                    <IoInformationCircle className="text-white" style={{ fontSize: 32 }} />
                    <p>
                      Version {descendingChangelog[0].version} is out!
                      <br />
                      Check out what's changed.
                    </p>
                  </div>
                }
                position="portal-fixed"
                onClose={() => lastVersionItem.set(descendingChangelog[0].version)}
                className="z-ribbon">
                <span>Updates{unseenVersionsCount > 0 && <> ({unseenVersionsCount})</>}</span>
              </Tooltip>
            </NavTab>
          </Changelog>
        )}

        <li className="flex-grow" />

        {!isCig && drawerExpanded && <NavCta />}

        {isLoggedIn && (
          <NavTab
            icon={
              <Avatar src={cigProfile?.profilePictureURL} alt={`${displayName} Avatar`} size={24} />
            }
            path={isCig ? "/my-profile" : undefined}>
            {displayName}
          </NavTab>
        )}
      </ul>
    </Drawer>
  );
}

interface NavTabProps {
  path?: string;
  href?: string;

  pathMatch?: string;
  exactMatch?: boolean;

  icon: ReactElement;
  isSubTab?: boolean;

  onClick?: () => void;
}

const NavTab = forwardRef<any, PropsWithChildren<NavTabProps>>((props, ref) => {
  const {
    path,
    href,

    pathMatch = path,
    exactMatch,

    icon,
    isSubTab,

    onClick,

    children,
  } = props;

  const { breakpoints, drawerExpanded } = useGlobalContext();

  const { pathname } = useLocation();

  const pathSelected =
    !!pathMatch && (exactMatch ? pathname === pathMatch : pathname.startsWith(pathMatch));

  const processedIcon = cloneElement(icon, {
    gradient: pathSelected ? "gradient-faded-horizontal" : undefined,
  });

  return (
    <div
      className={classNames("nav-tab-wrapper", isSubTab && "sub-tab", pathSelected && "selected")}>
      {drawerExpanded ? (
        <Button
          ref={ref}
          variant="contained"
          size={breakpoints.sm ? "medium" : "large"}
          color="black-2"
          textColor={pathSelected ? "white" : "gray-2"}
          iconColor="gray-2"
          startIcon={processedIcon}
          active={pathSelected}
          justifyContent="flex-start"
          onClick={onClick}
          readOnly={!path && !href && !onClick}
          className="nav-tab"
          {...(path ? { to: path } : { href, openInNewTab: true })}>
          {children}
        </Button>
      ) : (
        <HoverTooltip ref={ref} content={<p>{children}</p>} relativePosition="right">
          <IconButton
            variant="contained"
            size="medium"
            color="black-2"
            iconColor="gray-2"
            icon={processedIcon}
            active={pathSelected}
            onClick={onClick}
            readOnly={!path && !href && !onClick}
            className="nav-tab"
            {...(path ? { to: path } : { href, openInNewTab: true })}
          />
        </HoverTooltip>
      )}
    </div>
  );
});
