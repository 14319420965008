import useCombinedRefs from "@hooks/useCombinedRefs";
import { classNames } from "@lib/utils/generic";
import React, {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";

interface Props {
  in?: boolean;
  appear?: boolean;
  timeout?: number;
  unmountOnExit?: boolean;
  mountOnEnter?: boolean;
  periodic?: boolean;
}

const Shake = forwardRef<HTMLElement, PropsWithChildren<Props>>((props, forwardedRef) => {
  const {
    in: _in = false,
    appear,
    timeout = 500,

    unmountOnExit = false,
    mountOnEnter = false,
    periodic = false,

    children,
  } = props;

  const nodeRef = useRef<HTMLElement>(null);
  const ref = useCombinedRefs(nodeRef, forwardedRef);

  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setPlaying(_in);

    if (_in) {
      const timeoutId = setTimeout(() => {
        setPlaying(false);
      }, timeout);

      return () => clearTimeout(timeoutId);
    }
  }, [_in, timeout, setPlaying]);

  useEffect(() => {
    if (!periodic) return;
    const intervalId = setInterval(() => setPlaying(playing => !playing), timeout);
    return () => clearInterval(intervalId);
  }, [periodic, timeout]);

  return (
    <CSSTransition
      classNames="shake"
      in={playing}
      appear={appear}
      timeout={timeout}
      unmountOnExit={unmountOnExit}
      mountOnEnter={mountOnEnter}
      nodeRef={ref}>
      {isValidElement(children) &&
        cloneElement(Children.only(children) as any, {
          className: classNames("shake-transition", children.props.className),
          ref,
        })}
    </CSSTransition>
  );
});

export default Shake;
