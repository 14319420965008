import Checkbox from "@components/form/Checkbox";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import { flankPrivacyUrl } from "@lib/constants/generic";
import Button from "@components/form/Button";
import useFlankSignUpContext from "../context/useFlankSignUpContext";
import { IoArrowForward } from "react-icons/io5";
import Input from "@components/form/Input";

export default function SignUpStep() {
  const {
    values,
    validations,
    onChange,
    onBlur,

    signUpIsLoading,
  } = useFlankSignUpContext();

  return (
    <>
      <FormRow>
        <FormEntry label="EMAIL" validation={validations.email} showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            name="email"
            placeholder="example@email.com"
            type="email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
            disabled={signUpIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label="TWITCH DISPLAY NAME"
          validation={validations.twitchUsername}
          showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            name="twitchUsername"
            placeholder="Dave"
            value={values.twitchUsername}
            onChange={onChange}
            onBlur={onBlur}
            disabled={signUpIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry validation={validations.terms} showValidationBeneath>
          <Checkbox
            size="medium"
            color="peach-2"
            textColor="peach-2"
            checkmarkColor="black-1"
            name="terms"
            label={
              <span>
                I agree to the Flank{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={flankPrivacyUrl}
                  className="text-underline">
                  Privacy Policy
                </a>
                .
              </span>
            }
            value={values.terms}
            onChange={onChange}
            onBlur={onBlur}
            disabled={signUpIsLoading}
          />
        </FormEntry>
      </FormRow>

      <div className="auth-button-row">
        <Button
          color="peach-2"
          textColor="black-1"
          size="large"
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={signUpIsLoading}>
          Register
        </Button>
      </div>
    </>
  );
}
