import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import forgotPasswordSubmit from "../forgotPasswordSubmit";

const useForgotPasswordSubmit = (email?: string, confirmCode?: string, newPassword?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<null>(null);

  const send = useCallback(async () => {
    if (email == null || confirmCode == null || newPassword == null) {
      return setError("Email, code and password are required");
    }

    setLoading();
    try {
      await forgotPasswordSubmit(email, confirmCode, newPassword);
      return setSuccess(null);
    } catch (err: any) {
      return setError(err.message);
    }
  }, [email, confirmCode, newPassword, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useForgotPasswordSubmit;
