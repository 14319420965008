import useResendBookingEmail from "@api/public/misc/hooks/useResendBookingEmail";
import Button from "@components/form/Button";
import FormError from "@components/form/FormError";
import useRedirectToLogin from "@hooks/useRedirectToLogin";
import useRedirectToRegister from "@hooks/useRedirectToRegister";
import { bookingEmail } from "@lib/constants/generic";
import { createSuccessSnackbar } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, { useState } from "react";
import { IoArrowForward, IoCloseCircle, IoMail, IoPersonCircle, IoTicket } from "react-icons/io5";
import ClaimTicketsModal from "../ClaimTicketsModal";

interface Props {
  id: string;
}

export default function TicketsUnclaimed(props: Props) {
  const { id } = props;

  const { user, pushSnackbar } = useGlobalContext();

  const redirectToLogin = useRedirectToLogin();
  const redirectToRegister = useRedirectToRegister();

  const { isLoading, error, send: resendBookingEmail } = useResendBookingEmail(id);

  const [claimModalActive, setClaimModalActive] = useState(false);

  const isLoggedIn = !!user;

  const resend = async () => {
    const { error } = await resendBookingEmail();
    if (!error) pushSnackbar(createSuccessSnackbar("Booking email resent", 3000));
  };

  return (
    <>
      <div className="guest-content">
        <div className="icon-wrapper">
          <IoCloseCircle className="cross-icon" />
        </div>
        <div className="titles-wrapper">
          <h1 className="title">Tickets Unclaimed</h1>
          <h2 className="sub-title">
            {isLoggedIn ? (
              <>Claim your tickets so you can play use them with this account</>
            ) : (
              <>To claim your tickets and play your session, you have two options</>
            )}
          </h2>
        </div>

        {isLoggedIn ? (
          <div className="cards-wrapper">
            <div className="card">
              <IoTicket className="card-icon" />

              <div className="text-wrapper">
                <h3 className="title">Claim Your Tickets</h3>
                <p className="text">Your tickets will be bound to this account.</p>

                <p className="text small">
                  Your claim code <br /> <b>{id}</b>
                </p>
              </div>

              <div className="buttons-wrapper">
                <Button
                  color="purple"
                  size="medium"
                  textColor="white"
                  endIcon={<IoArrowForward />}
                  justifyContent="center"
                  onClick={() => setClaimModalActive(true)}>
                  Claim
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="cards-wrapper">
            <div className="card">
              <IoPersonCircle className="card-icon" />

              <div className="text-wrapper">
                <h3 className="title">Connect Tickets to Your Account</h3>
                <p className="text">Your tickets will be bound to your account.</p>
              </div>

              <div className="buttons-wrapper">
                <Button
                  color="purple"
                  size="medium"
                  textColor="white"
                  endIcon={<IoArrowForward />}
                  justifyContent="center"
                  onClick={() => redirectToLogin()}>
                  Login
                </Button>
                <Button
                  color="purple"
                  variant="outlined"
                  size="medium"
                  textColor="white"
                  endIcon={<IoArrowForward />}
                  justifyContent="center"
                  onClick={() => redirectToRegister()}>
                  Create an account
                </Button>
              </div>
            </div>
            <div className="card">
              <IoMail className="card-icon" />

              <div className="text-wrapper">
                <h3 className="title">Follow Email Instructions</h3>
                <p className="text">
                  Look for an email from <b>{bookingEmail}</b> to claim your tickets
                </p>

                <p className="text small">Not showing up?</p>

                <FormError textOverride={error} />
              </div>

              <div className="buttons-wrapper">
                <Button
                  color="purple"
                  size="medium"
                  textColor="white"
                  endIcon={<IoArrowForward />}
                  justifyContent="center"
                  isLoading={isLoading}
                  onClick={resend}>
                  Resend
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ClaimTicketsModal
        active={claimModalActive}
        linkingID={id}
        onClose={() => setClaimModalActive(false)}
      />
    </>
  );
}
