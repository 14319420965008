import TestimonialsSplash from "@components/splash/sections/TestimonialsSplash";

import landingAvatar1 from "@images/landing/flank/testimonials/testimonial_avatar_1.jpg";
import landingAvatar2 from "@images/landing/flank/testimonials/testimonial_avatar_2.jpg";
import landingAvatar3 from "@images/landing/flank/testimonials/testimonial_avatar_3.jpg";

const Testimonials = () => {
  return (
    <TestimonialsSplash
      testimonials={[
        {
          avatarImg: landingAvatar1,
          displayName: (
            <>
              CancelDota <span className="text-gray-2">| Twitch Partner</span>
            </>
          ),
          text: (
            <>
              Being able to keep all the money from a brand deal is something I really wasn't
              expecting. Count me in.
            </>
          ),
        },
        {
          avatarImg: landingAvatar2,
          displayName: (
            <>
              Ar1seDota <span className="text-gray-2">| Twitch Partner</span>
            </>
          ),
          text: (
            <>
              I'm fed up of agency fees but I don't have time to sort my own sponsors so this looks
              like a great fit for me.
            </>
          ),
        },
        {
          avatarImg: landingAvatar3,
          displayName: (
            <>
              Nickyyz <span className="text-gray-2">| Streamer</span>
            </>
          ),
          text: (
            <>
              Lower level Twitch streamers are often forgotten about by brands. But we have such an
              engaged audience. Great to see a company being built to get deals for us.
            </>
          ),
        },
      ]}
    />
  );
};

export default Testimonials;
