import { JustifyContent, UIColor } from "@lib/types/generic";
import { classNames, preventAndStop } from "@lib/utils/generic";
import React, { ReactElement, useLayoutEffect, useRef } from "react";
import Button from "./Button";
import { FormElement } from "./Form";

type NewType = UIColor;

export type Option = {
  value: string | number;
  label: string;
  color?: UIColor;
  textColor?: NewType;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  disabled?: boolean;
  justifyContent?: JustifyContent;
};

interface Props {
  color?: UIColor;
  textColor?: UIColor;
  optionColor?: UIColor;
  optionTextColor?: UIColor;
  variant?: "contained" | "outlined" | "transparent" | "flat";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  name?: string;

  disabled?: boolean;
  justifyContent?: JustifyContent;

  value?: string | number;
  options?: Option[];

  onSelect?: (target: FormElement<Option>) => void;
}

export type OptionsProps = Props;

export default function Options(props: Props) {
  const {
    color = "white",
    textColor = "black",
    optionColor = color,
    optionTextColor = textColor,
    variant = "contained",
    size = "medium",
    name,

    disabled,
    justifyContent = "space-between",

    value,
    options = [],

    onSelect = () => {},
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const { current } = ref;
    if (!current) return;
    const { scrollHeight } = current;
    const index = options.findIndex(option => value === option.value) - 1;

    if (index <= 0 || options.length === 0) return;

    const top = (scrollHeight / options.length) * index;

    current.scrollTo({
      top,
    });

    // eslint-disable-next-line
  }, []);
  // Must only trigger on mount

  return (
    <div className={classNames("options", color, variant, size)} onMouseDown={preventAndStop}>
      <div ref={ref} className="options-inner">
        {options.map((option, i) => (
          <Button
            key={i}
            color={option.color ?? optionColor}
            textColor={option.textColor ?? optionTextColor}
            variant="contained"
            size={size}
            startIcon={option.startIcon}
            endIcon={option.endIcon}
            justifyContent={option.justifyContent ?? justifyContent}
            disabled={disabled || option.disabled}
            active={value === option.value}
            className="option"
            onMouseDown={() => {
              onSelect({ type: "search", name: name ?? "", value: option });
            }}>
            {option.label}
          </Button>
        ))}
      </div>
    </div>
  );
}
