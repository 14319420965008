import useFormManager from "@components/form/hooks/useFormManager";
import useQuery from "@hooks/useQuery";
import { CIG } from "@lib/enums/generic";
import useCigLoginHandles from "./useCigLoginHandles";

const initialValues = {
  email: "",
  password: "",
  staySignedIn: true,
};

export type CigLoginValues = typeof initialValues;

const useCigLogin = (category: CIG) => {
  const email = useQuery("email");

  if (email) initialValues.email = email;

  const formManager = useFormManager(initialValues, "login");

  const { values, validations, onChange, onBlur, onChangeMultiple, resetValidations } = formManager;

  const [loginHandle, loginIsLoading] = useCigLoginHandles(formManager, category);

  return {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles: [loginHandle],
    resetValidations,

    loginIsLoading,
  };
};

export default useCigLogin;
