import { CSSProps, JustifyContent, UIColor } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import { cloneElement, CSSProperties, PropsWithChildren, MouseEvent, forwardRef } from "react";

interface Props extends CSSProps {
  color?: UIColor;
  textColor?: UIColor;
  iconColor?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  justifyContent?: JustifyContent;
  alignLeft?: boolean;
  alignRight?: boolean;
  rounded?: boolean;
  noWrap?: boolean;

  spanClassName?: string;

  onMouseEnter?: (event: MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void;
}

export type PillProps = Props;

const Pill = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    color = "white",
    textColor = "black",
    iconColor = textColor,
    variant = "contained",
    size = "medium",
    startIcon,
    endIcon,

    justifyContent = "space-between",
    alignLeft,
    alignRight,
    rounded,

    noWrap = false,

    spanClassName,

    children,
    style,
    id,
    className,

    ...events
  } = props;

  const inner = (
    <span className="pill-inner">
      {startIcon &&
        cloneElement(startIcon, {
          ...startIcon.props,
          className: classNames("pill-icon", startIcon.props.className),
        })}
      <span className={spanClassName}>{children}</span>
      {endIcon &&
        cloneElement(endIcon, {
          ...endIcon.props,
          className: classNames("pill-icon", endIcon.props.className),
        })}
    </span>
  );

  return (
    <span
      ref={ref}
      className={classNames(
        "pill",
        noWrap && "no-wrap",
        (startIcon || endIcon || justifyContent !== "space-between") && "do-justify",
        alignLeft && "align-left",
        alignRight && "align-right",
        rounded && "rounded",
        color,
        `${textColor}-text`,
        `${iconColor}-icon`,
        variant,
        size,
        className,
      )}
      id={id}
      style={
        {
          "--justify-content": justifyContent,
          ...style,
        } as CSSProperties
      }
      {...events}>
      {inner}
    </span>
  );
});

export default Pill;
