import React, { forwardRef, PropsWithChildren, useEffect, useRef } from "react";
import ParticipantView from "./ParticipantView";
import Title from "./Title";
import Controls from "./Controls";
import useMouseIsOver from "@hooks/useMouseIsOver";
import IconButton from "@components/form/IconButton";
import { IoEye, IoEyeOff } from "react-icons/io5";
import Slide from "@components/transitions/Slide";
import useRoomContext from "../roomContext/hooks/useRoomContext";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import ParticipantAudio from "./ParticipantAudio";
import { CSSProps } from "@lib/types/generic";
import useCombinedRefs from "@hooks/useCombinedRefs";
import ParticipantSwitchButton from "./ParticipantSwitchButton";
import CameraSwitchButton from "./CameraSwitchButton";
import RoomParticipant from "../objects/RoomParticipant";
import { classNames } from "@lib/utils/generic";

interface Props extends CSSProps {
  showBranding?: boolean;
  showControls?: boolean;
  showSwitch?: boolean;
  fixedControls?: boolean;
  participant?: RoomParticipant;
  noParticipants?: boolean;
  size?: "small" | "large";
  isFullscreen?: boolean;
  mountAudio?: boolean;
  onClick?: () => void;
  goFullscreen?: () => void;
}

const ViewPort = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, forwardedRef) => {
  const {
    showBranding = false,
    showControls = false,
    showSwitch = false,
    fixedControls = false,
    participant,
    noParticipants,
    size = "large",
    isFullscreen,
    mountAudio,
    children,
    className = "",
    id,
    style,

    onClick,
    goFullscreen,
  } = props;

  let {
    gridParticipants,
    gridStreamsVisible = true,
    setGridStreamsVisible,
    localIdentity,
    drawerTab,
    viewParticipant,
  } = useRoomContext();

  const viewPortRef = useRef<HTMLDivElement>(null);

  const ref = useCombinedRefs<HTMLDivElement>(viewPortRef, forwardedRef);

  const [isOver, reset] = useMouseIsOver(ref);

  const { breakpoints } = useGlobalContext();
  const { participants, videoRoom } = useRoomContext();

  const isLocal = participant?.identity === localIdentity;

  useEffect(() => {
    if (!isOver) return;
    const timeoutId = setTimeout(() => reset(), 3000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [isOver]);
  // reset is omitted to prevent unwanted updates that worsen UX

  return (
    <div
      className={classNames("view-port-wrapper", className)}
      id={id}
      style={style}
      onClick={onClick}>
      <div
        className={classNames(
          "view-port",
          drawerTab != null && "drawer-open",
          isFullscreen && "fullscreen",
        )}
        ref={ref}>
        <ParticipantView
          isMain
          participant={participant}
          noParticipants={noParticipants}
          size={size}
          isFullscreen={isFullscreen}
          showBranding={showBranding}>
          {showControls && (
            <>
              <Title visible={isOver} fixed={fixedControls} />
              <Controls
                visible={isOver}
                fixed={fixedControls}
                isFullscreen={isFullscreen}
                goFullscreen={goFullscreen}
              />
            </>
          )}
          <div className="action-buttons">
            {showSwitch && !isFullscreen && <ParticipantSwitchButton />}
            {isLocal && breakpoints.md && <CameraSwitchButton />}
          </div>
        </ParticipantView>

        {mountAudio &&
          participants
            .filter(({ identity }) => videoRoom?.localParticipant.identity !== identity)
            .map(participant => (
              <ParticipantAudio key={participant.identity} participant={participant} />
            ))}

        {gridParticipants.length > 0 && (
          <Slide
            in={showControls && gridStreamsVisible && size === "large"}
            appear
            direction="left"
            properties="transform, opacity, right"
            unmountOnExit={false}
            mountOnEnter={false}>
            <div
              className={classNames(
                "grid-streams-wrapper",
                gridParticipants.length > 5 ? "two-columns" : "one-column",
              )}>
              <div className="grid-streams">
                {gridParticipants.map(participant => (
                  <ParticipantView
                    key={participant.identity}
                    isFullscreen={isFullscreen}
                    participant={participant}
                    pauseVideo={!gridStreamsVisible}
                    size="small"
                    onClick={() => viewParticipant(participant.identity)}
                  />
                ))}
              </div>
              <Slide in={isOver && showControls && size === "large"} appear direction="left">
                <IconButton
                  color="white"
                  iconColor="black"
                  size="small"
                  rounded
                  icon={gridStreamsVisible ? <IoEyeOff /> : <IoEye />}
                  onClick={() => setGridStreamsVisible(!gridStreamsVisible)}
                  className="grid-streams-toggle"
                />
              </Slide>
            </div>
          </Slide>
        )}
        {children}
      </div>
    </div>
  );
});
export default ViewPort;
