import Button from "@components/form/Button";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs from "dayjs";
import React, { useState } from "react";
import bannerPlaceholder from "@images/misc/banner_placeholder.jpg";
import DataCheck from "@components/common/DataCheck";
import {
  IoArrowForward,
  IoCopy,
  IoEnter,
  IoLockOpen,
  IoPencil,
  IoRocket,
  IoSend,
} from "react-icons/io5";
import { Booking } from "./Bookings";
import { useHistory } from "react-router-dom";
import DialogueModal from "@components/modals/DialogueModal";
import {
  createErrorSnackbar,
  createOpenRoomPath,
  createReviewPath,
  createRoomPath,
  generateSessionCalendarLink,
} from "@lib/utils/generic";
import useTicketShareStatusStore from "@hooks/sessionStore/useTicketShareStatusStore";
import useShareSession from "./hooks/useShareSession";
import BackgroundImage from "@components/common/BackgroundImage";
import useSetSessionToOpen from "@api/private/update/hooks/useSetSessionToOpen";
import useCopyToClipboard from "@hooks/useCopyToClipboard";

interface Props {
  error?: string | null;
  isLoading?: boolean;
  booking?: Booking;
}

export default function BookingViewerBanner(props: Props) {
  const { error, isLoading, booking } = props;

  const { cigProfile } = useGlobalContext();

  const {
    bookingId,
    sessionId,
    ticketId,
    gameName,
    from,
    to,
    cigID,
    slotsAvailable,
    maxSlots,
    bannerImg,
    cigName,
    accessCode,
  } = booking ?? {};

  const isOwn = cigProfile?.id === cigID;

  return (
    <div className="booking-banner">
      <DataCheck isLoading={isLoading} error={error} loadingIndicator="gloss">
        <BackgroundImage src={bannerImg ?? bannerPlaceholder} />
        <div className="wrapper">
          <div className="content">
            {booking ? (
              isOwn ? (
                <CIGContent
                  gameName={gameName}
                  maxSlots={maxSlots}
                  slotsAvailable={slotsAvailable}
                  from={from}
                  to={to}
                  sessionId={sessionId}
                  cigName={cigName}
                  accessCode={accessCode}
                />
              ) : (
                <CustomerContent
                  gameName={gameName}
                  cigName={cigName}
                  from={from}
                  to={to}
                  bookingId={bookingId}
                  ticketId={ticketId}
                  sessionId={sessionId}
                />
              )
            ) : (
              <h3 className="title text-white">Select a Booking</h3>
            )}
          </div>
        </div>
      </DataCheck>
    </div>
  );
}

interface CIGContentProps {
  gameName?: string;
  maxSlots?: number;
  slotsAvailable?: number;
  from?: string;
  to?: string;
  sessionId?: string;
  cigName?: string;
  accessCode?: string | null;
}

const CIGContent = ({
  gameName,
  maxSlots = 0,
  slotsAvailable = 0,
  from,
  to,
  sessionId,
  cigName,
  accessCode: propsAccessCode,
}: CIGContentProps) => {
  const { pushSnackbar } = useGlobalContext();

  const unbooked = slotsAvailable === maxSlots;

  const notStarted = dayjs(from).subtract(2, "minutes").isAfter(dayjs());
  const isOver = dayjs(to).isBefore(dayjs());
  const inProgress = !notStarted && !isOver;

  const shareUrl = generateSessionCalendarLink(cigName ?? "", sessionId ?? "", from ?? "");

  const { send: shareSession, isLoading: shareIsLoading } = useShareSession(
    dayjs(from),
    dayjs(to),
    gameName,
    shareUrl,
  );

  const {
    data: openSessionData,
    isLoading: openSessionIsLoading,
    send: openSession,
  } = useSetSessionToOpen(sessionId);

  const accessCode = openSessionData?.accessCode ?? propsAccessCode;

  const copyToClipboard = useCopyToClipboard();

  const copyOpenSessionLink = (accessCode: string) =>
    sessionId &&
    copyToClipboard(`${window.location.origin}${createOpenRoomPath(sessionId, accessCode)}`);

  const openSessionHandle = async () => {
    const { error, data } = await openSession();
    if (error) return pushSnackbar(createErrorSnackbar(error, 5000));
    if (data) copyOpenSessionLink(data.accessCode);
  };

  return (
    <>
      {unbooked ? (
        !notStarted ? (
          <h3 className="title">
            <span className="text-white">Let's get more people in the room.</span>
            <br />
            Unlock your session to share an open invite.
          </h3>
        ) : (
          <h3 className="title">
            <span className="text-white">Let's get more people in the room.</span>
            <br />
            Try sharing your sessions with your fans!
          </h3>
        )
      ) : (
        <h3 className="title wrap">
          {isOver ? "You played" : "You're playing"} <span className="text-white">{gameName}</span>
          <br />
          with <span className="text-white">{maxSlots - slotsAvailable} of your fans</span>{" "}
          <span className="text-white">{inProgress ? "right now" : dayjs(from).fromNow()}</span>
        </h3>
      )}

      <div className="buttons">
        {unbooked && notStarted && (
          <Button
            variant="contained"
            color="white"
            endIcon={<IoSend />}
            onClick={shareSession}
            isLoading={shareIsLoading}>
            Share
          </Button>
        )}

        <Button
          variant="contained"
          color="purple"
          textColor="white"
          to={`/bookings/room/${sessionId}?type=cig`}
          endIcon={<IoRocket />}
          disabled={isOver}>
          Launch Room
        </Button>

        {!notStarted &&
          (accessCode ? (
            <Button
              variant="contained"
              color="black-3"
              textColor="white"
              endIcon={<IoCopy />}
              onClick={() => copyOpenSessionLink(accessCode)}>
              Copy Access Link
            </Button>
          ) : (
            <Button
              variant="contained"
              color="black-3"
              textColor="white"
              endIcon={<IoLockOpen />}
              onClick={openSessionHandle}
              isLoading={openSessionIsLoading}>
              Open Session
            </Button>
          ))}
      </div>
    </>
  );
};

interface CustomerContentProps {
  gameName?: string;
  cigName?: string;
  from?: string;
  to?: string;
  bookingId?: string;
  ticketId?: string;
  sessionId?: string;
}

const CustomerContent = ({
  gameName,
  cigName,
  from,
  to,
  bookingId,
  ticketId,
  sessionId,
}: CustomerContentProps) => {
  const notStarted = dayjs(from).isAfter(dayjs());
  const isOver = dayjs(to).isBefore(dayjs());
  const inProgress = !notStarted && !isOver;

  const history = useHistory();

  const [modalActive, setModalActive] = useState(false);

  const { addTicketId } = useTicketShareStatusStore(sessionId);

  return (
    <>
      <h3 className="title wrap">
        {isOver ? "You played" : "You're playing"} <span className="text-white">{gameName}</span>
        <br />
        with <span className="text-white">{cigName}</span>{" "}
        <span className="text-white">{inProgress ? "right now" : dayjs(from).fromNow()}</span>
      </h3>
      <div className="buttons">
        {isOver ? (
          <Button
            variant="contained"
            color="white"
            to={createReviewPath(sessionId ?? "", ticketId, bookingId)}
            endIcon={<IoPencil />}>
            Leave Review
          </Button>
        ) : (
          <Button
            variant="contained"
            color="white"
            onClick={() => setModalActive(true)}
            endIcon={<IoEnter />}>
            Join Room
          </Button>
        )}
        <Button
          variant="contained"
          color="white"
          to={`/bookings/payment-completion/${bookingId}?type=booking&noOverlay=true`}
          endIcon={<IoArrowForward />}>
          Go to Tickets
        </Button>
      </div>

      <DialogueModal
        title="Please note"
        subTitle="Joining this way will use the first ticket you own, so please ensure you haven't shared this ticket with anyone."
        titleDesign="condensed"
        color="purple"
        noIcon
        active={modalActive}
        onConfirm={() => {
          if (ticketId != null) addTicketId(ticketId);
          history.push(createRoomPath(sessionId ?? "", ticketId, bookingId));
        }}
        onClose={() => setModalActive(false)}
      />
    </>
  );
};
