import useShareTicket from "@api/private/share/hooks/useShareTicket";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { ShareTicketValues } from "../ShareTicketModal";

const useShareTicketHandles: UseFormHandles<ShareTicketValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { isLoading, send: shareTicket } = useShareTicket(
    values.bookingId,
    values.ticketId,
    values.email,
  );

  const handle = async () => {
    let failed = validateFields(["bookingId", "ticketId", "email"]);

    if (!failed) {
      const { error } = await shareTicket();

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }
    }

    return failed;
  };

  return [handle, isLoading];
};

export default useShareTicketHandles;
