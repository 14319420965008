import { cloneElement, forwardRef, ReactElement, ReactNode } from "react";
import { CSSProps, UIColor } from "../../lib/types/generic";
import { IoCheckmarkSharp } from "react-icons/io5";
import { FormElement } from "./Form";
import { classNames } from "@lib/utils/generic";

interface Props extends CSSProps {
  color?: UIColor;
  textColor?: UIColor;
  checkmarkColor?: UIColor;
  variant?: "contained" | "outlined" | "transparent" | "flat";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  icon?: ReactElement;
  label?: ReactNode;
  name?: string;
  value?: boolean;
  disabled?: boolean;
  disableHover?: boolean;
  readOnly?: boolean;
  rounded?: boolean;

  wrapperClassName?: string;

  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
}

export type CheckboxProps = Props;

const Checkbox = forwardRef<HTMLLabelElement, Props>((props, ref) => {
  const {
    color = "white",
    textColor = "white",
    checkmarkColor = "black",
    variant = "contained",
    size = "medium",
    icon = <IoCheckmarkSharp />,
    label,
    name,
    value,
    disabled,
    readOnly,
    disableHover = readOnly,
    rounded,

    wrapperClassName,

    onChange = () => {},
    onBlur = () => {},

    style,
    id,
    className = "",
  } = props;

  return (
    <label
      ref={ref}
      className={classNames(
        "checkbox-wrapper",
        size,
        disabled && "disabled",
        readOnly && "read-only",
        wrapperClassName,
      )}>
      <div className="box-wrapper">
        <input
          type="checkbox"
          name={name}
          onChange={({ target }) => {
            onChange({ ...target, name: name ?? "", value: target.checked });
            onBlur({ ...target, name: name ?? "", value: target.checked });
          }}
          style={style}
          checked={value != null ? value : undefined}
          disabled={disabled}
          readOnly={readOnly}
          id={id}
          className={classNames(
            "checkbox",
            color,
            variant,
            disableHover && "hover-disabled",
            rounded && "rounded",
            className,
          )}
        />
        {cloneElement(icon, {
          className: classNames("checkmark", `${checkmarkColor}-checkmark`),
        })}
      </div>
      {label && <div className={classNames("checkbox-label", `${textColor}-text`)}>{label}</div>}
    </label>
  );
});

export default Checkbox;
