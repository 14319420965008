import CigEventSpread from "@components/sections/CigEventSpread";
import useElementSize from "@hooks/useElementSize";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs from "dayjs";
import { ReactNode, useRef } from "react";
import SplashTitle from "../SplashTitle";

interface Props {
  title?: ReactNode;
  events?: Array<{
    id: string;
    title: string;
    extraInfo: string | null;
    game: {
      shortName: string;
      primaryImageURL: string | null;
    };
    cig: {
      displayName: string;
      tagLine: string;
      profilePictureURL: string | null;
    };
    from: string;
    to: string;
    slotsAvailable: number;
    maxSlots: number;
  }>;
  isLoading?: boolean;
}

export default function UpcomingEventsSplash(props: Props) {
  const {
    title = (
      <>
        <span className="light-gradient">Upcoming events</span>
      </>
    ),
    events = [],
    isLoading,
  } = props;

  const { breakpoints } = useGlobalContext();

  const buttonSize = breakpoints.md ? "medium" : breakpoints.lg ? "small" : "large";

  const ref = useRef<HTMLDivElement>(null);
  const { width } = useElementSize(ref);

  const columns = breakpoints.sm ? 1 : breakpoints.md ? 2 : 1;
  const gap = 32;
  const eventWidth = (width - gap * (columns - 1)) / columns;

  if (events.length === 0 && !isLoading) return null;

  return (
    <section className="section upcoming-events">
      <SplashTitle center>{title}</SplashTitle>

      <div className="events" ref={ref}>
        {isLoading
          ? new Array(2)
              .fill(null)
              .map((_, i) => (
                <CigEventSpread
                  key={i}
                  width={eventWidth}
                  buttonSize={buttonSize}
                  isMobile={breakpoints.md}
                  isLoading
                />
              ))
          : events.map(
              ({ id, title, extraInfo, game, cig, from, to, slotsAvailable, maxSlots }, i) => (
                <CigEventSpread
                  key={i}
                  width={eventWidth}
                  buttonSize={buttonSize}
                  sessionId={id}
                  title={title}
                  extraDetails={extraInfo ?? ""}
                  gameName={game.shortName}
                  gameImg={game.primaryImageURL}
                  cigName={cig.displayName}
                  cigImg={cig.profilePictureURL}
                  cigTagLine={cig.tagLine}
                  startDate={dayjs(from)}
                  endDate={dayjs(to)}
                  totalTickets={maxSlots}
                  ticketsLeft={slotsAvailable}
                  isMobile={breakpoints.md}
                />
              ),
            )}
      </div>
    </section>
  );
}
