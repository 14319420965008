import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useUserReFetch from "./useGetUserReFetch";

const useCigIsAccepted = () => {
  const { cigProfile } = useGlobalContext();

  useUserReFetch();

  return !!cigProfile?.accepted;
};

export default useCigIsAccepted;
