import { useCallback } from "react";
import useRoomContext from "../../../roomContext/hooks/useRoomContext";

const useHandleReport = (identity: string) => {
  const { openReportModal } = useRoomContext();

  const handleReport = useCallback(() => {
    openReportModal(identity);
  }, [identity, openReportModal]);

  return { handleReport };
};

export default useHandleReport;
