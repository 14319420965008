import Button from "@components/form/Button";
import FluentMagicWand from "@components/icons/FluentMagicWand";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import LandingTestimonial from "../../LandingTestimonial";

import avatar2 from "@images/landing/consultancy/avatars/avatar2.png";
import { IoArrowForward } from "react-icons/io5";

const Explanation = () => {
  const { breakpoints } = useGlobalContext();

  const buttonSize = breakpoints.sm ? "medium" : "large";

  return (
    <section className="pane explanation">
      <div className="inner">
        <p className="flow-text">
          Look, <span>we get it.</span> Growing a successful Twitch stream to a full time career is
          hard. In fact, it's very hard.
          <br />
          <br />
          <br />
          Did you know that <span>only 0.85% of all streamers ever make it as a partner?</span>
          <br />
          <br />
          <br />
          Many talented affiliates who've grinded their stream are eventually forced to give up on
          their dreams of achieving Partner due to the lack of support available.
        </p>

        <FluentMagicWand className="flow-icon" />

        <h2 className="flow-sub-title text-purple">But, imagine a service...</h2>

        <p className="flow-text">
          <br />
          <br />
          That gives you exclusive access to some of the biggest streamers on Twitch,{" "}
          <span>personalised growth strategies</span> and <span>collaboration opportunities</span>{" "}
          with like minded affiliates.
          <br />
          <br />
          <br />
          Sounds ideal right? Well that's why we set up Yakkr Growth - our service designed to
          kickstart your streaming career, helping you achieve Partner status, and making it as{" "}
          <span>quick & easy as possible!</span>
        </p>

        <LandingTestimonial avatarImg={avatar2} displayName="Nickyyz" tagLine="Twitch Affiliate">
          Sometimes I don't really know what to do. I kind of just stream and hope people pop in.
          Looking forward to actually having a strategy to grow.
        </LandingTestimonial>

        <div className="button-row">
          <Button
            size={buttonSize}
            color="purple"
            textColor="white"
            endIcon={<IoArrowForward />}
            href="#sign-up"
            justifyContent="center">
            Sign Up Now
          </Button>
          <Button size={buttonSize} color="gray-3" textColor="white" href="#how-it-works">
            I need more info
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Explanation;
