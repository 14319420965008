import { getMsgFromApiErr } from "@api/api";
import getCigProfile, { CIGProfile } from "@api/public/get/getCigProfile";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";

const useGetCigProfile = (
  cigID?: string,
  cigDisplayName?: string,
  userID?: string,
  autoSend?: boolean,
) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CIGProfile | null>(null);

  const send = useCallback(
    async (cigIDOverride?: string, cigDisplayNameOverride?: string) => {
      const _cigID = cigIDOverride ?? cigID;
      const _cidDisplayName = cigDisplayNameOverride ?? cigDisplayName;

      if (_cigID == null && _cidDisplayName == null) return reqState;
      setLoading();
      try {
        const cigProfile = await getCigProfile(_cigID, _cidDisplayName, userID);
        return setSuccess(cigProfile);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [cigID, cigDisplayName, userID, reqState, setError, setLoading, setSuccess],
  );

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [cigID, cigDisplayName, userID, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetCigProfile;
