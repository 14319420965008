import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const createReportValidators = {
  userId: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").exists();
  },
  reason: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").exists().length.lessThanOrEqualTo(1000);
  },
};

export default createReportValidators;
