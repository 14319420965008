import { api } from "@api/api";
import { Session } from "@lib/types/session";

const getCigAvailability = async (
  cigID: string,
  fromDate: string,
  toDate: string,
  userID?: string,
) => {
  const { data } = await api.get<Session[]>("/availability", {
    params: { cigID, fromDate, toDate, userID },
  });
  return data;
};

export default getCigAvailability;
