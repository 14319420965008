import { GamePlatformSelection } from "@api/private/update/updateExperiencePackage";
import { Game } from "@api/public/get/getGames";

const useLongestGame = (games: Game[], gamePlatformSelections: GamePlatformSelection[]) => {
  return getLongestGame(games, gamePlatformSelections);
};

export default useLongestGame;

export const getLongestGame = (games: Game[], gamePlatformSelections: GamePlatformSelection[]) => {
  let longestGame: Game | null = null;

  const filteredGames = games.filter(({ id }) =>
    gamePlatformSelections.find(({ gameID }) => id === gameID),
  );

  for (const game of filteredGames) {
    if (game.estimatedMaxLengthMins == null) continue;
    if (
      !longestGame ||
      (longestGame.estimatedMaxLengthMins != null &&
        game.estimatedMaxLengthMins > longestGame.estimatedMaxLengthMins)
    ) {
      longestGame = game;
    }
  }

  return longestGame;
};
