import { api } from "@api/api";

interface SetExperienceRequestStateRes {}

const setExperienceRequestState = async (requestSessionID: string, accept: boolean) => {
  const { data } = await api.post<SetExperienceRequestStateRes>(
    "/cigAcceptOrDeclineSessionRequest",
    {
      requestSessionID,
      accept,
    },
  );
  return data;
};

export default setExperienceRequestState;
