import { api } from "@api/api";

export interface BindUserToTicketsRes {
  message: string;
  bookingID: string;
}

const bindUserToTickets = async (linkingID: string) => {
  const { data } = await api.post<BindUserToTicketsRes>("/bindUserToTickets", {
    linkingID,
  });
  return data;
};

export default bindUserToTickets;
