import DataCheck from "@components/common/DataCheck";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { SortDirection } from "@lib/enums/generic";
import { sortMap } from "@lib/utils/generic";
import dayjs from "dayjs";
import React, { useState } from "react";
import BookingList from "./BookingList";
import { Booking } from "./Bookings";
import Button from "@components/form/Button";
import ClaimTicketsModal from "./ClaimTicketsModal";
import ToggleSwitch from "@components/form/ToggleSwitch";
import HoverTooltip from "@components/common/HoverTooltip";

interface Props {
  error?: string | null;
  isLoading?: boolean;
  bookings?: Booking[];
  claimLinkingID?: string;
}

export default function BookingSelector(props: Props) {
  const { error, isLoading, claimLinkingID } = props;

  const { cigProfile } = useGlobalContext();

  const [showBookedOnly, setShowBookedOnly] = useState(false);
  const [claimModalActive, setClaimModalActive] = useState(claimLinkingID != null);

  let bookings = (props.bookings ?? []).sort(sortMap("from", SortDirection.Ascending));
  if (showBookedOnly)
    bookings = bookings.filter(
      ({ slotsAvailable, maxSlots, private: privateSession }) =>
        slotsAvailable !== maxSlots || privateSession,
    );

  const upcomingBookings = bookings.filter(({ from }) => dayjs(from).isSameOrAfter(dayjs()));
  const ongoingbookings = bookings.filter(({ to, from }) => dayjs().isBetween(to, from));
  const previousBookings = bookings.filter(({ to }) => dayjs(to).isBefore(dayjs()));

  const isCig = !!cigProfile;

  return (
    <div className="booking-selector">
      <div className="main-title-wrapper">
        <div className="group">
          <h2 className="main-title">My Bookings</h2>
          {isCig && (
            <HoverTooltip
              content={
                <p className="text-center">
                  <b>Showing</b>
                  <br />
                  {showBookedOnly ? "Booked Sessions Only" : "All Sessions"}
                </p>
              }>
              <ToggleSwitch
                color="white"
                thumbColor="white"
                size="small"
                variant="outlined"
                selectedThumbColor="black-1"
                selectedVariant="contained"
                value={showBookedOnly}
                onChange={({ value }) => setShowBookedOnly(value)}
              />
            </HoverTooltip>
          )}
        </div>
        <Button
          size="small"
          color="white"
          variant="contained"
          onClick={() => setClaimModalActive(true)}>
          Claim Tickets
        </Button>
      </div>

      <div className="sections-wrapper">
        <DataCheck error={error} isLoading={isLoading} loadingIndicator="spinner" color="white">
          <div className="sections">
            <BookingList title="Upcoming" bookings={upcomingBookings} />
          </div>

          <div className="sections">
            <BookingList title="Ongoing" bookings={ongoingbookings} />
          </div>

          <div className="sections">
            <BookingList
              title="Past"
              bookings={previousBookings.sort(sortMap("from", SortDirection.Descending))}
            />
          </div>
        </DataCheck>
      </div>

      <ClaimTicketsModal
        active={claimModalActive}
        linkingID={claimLinkingID}
        onClose={() => setClaimModalActive(false)}
      />
    </div>
  );
}
