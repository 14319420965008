import { ConsultancyPackageType } from "@api/public/misc/registerConsultancyInterest";
import Modal from "@components/modals/Modal";
import ConsultancySignUpInner from "./ConsultancySignUpInner";
import ConsultancySignUpContextProvider from "./context/ConsultancySignUpContextProvider";

interface Props {
  selectedPackage?: ConsultancyPackageType;
  onClose: () => void;
}

const ConsultancySignUpModal = (props: Props) => {
  const { selectedPackage, onClose } = props;

  return (
    <Modal
      active={!!selectedPackage}
      onClose={onClose}
      onlyCloseButton
      id="consultancy-sign-up-modal"
      className="auth-modal">
      <ConsultancySignUpContextProvider selectedPackage={selectedPackage} onClose={onClose}>
        <ConsultancySignUpInner />
      </ConsultancySignUpContextProvider>
    </Modal>
  );
};

export default ConsultancySignUpModal;
