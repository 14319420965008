import config, { isDev } from "@config/config";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import useGAConsent from "./useGAConsent";

const useGoogleAnalytics = () => {
  const { consented } = useGAConsent();

  useEffect(() => {
    if (!consented) return;
    ReactGA.initialize([
      {
        trackingId: config.gaKey,
        gtagOptions: {
          ...(isDev ? { debug_mode: true } : {}),
        },
      },
    ]);
  }, [consented]);
};

export default useGoogleAnalytics;
