import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import registerConsultancyInterest, {
  ConsultancyPackageType,
  RegisterConsultancyInterestRes,
} from "../registerConsultancyInterest";

const useRegisterConsultancyInterest = (
  email?: string,
  twitchUsername?: string,
  packageChosen?: ConsultancyPackageType,
) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<RegisterConsultancyInterestRes | null>(null);

  const send = useCallback(async () => {
    if (email == null || twitchUsername == null || packageChosen == null) return reqState;
    setLoading();
    try {
      const data = await registerConsultancyInterest(email, twitchUsername, packageChosen);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [email, twitchUsername, packageChosen, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useRegisterConsultancyInterest;
