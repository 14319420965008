import useFormManager from "@components/form/hooks/useFormManager";
import useFeedbackHandles from "./useFeedbackHandles";

const initialValues = {
  sessionId: "",
  bookingId: "",
  message: "",
  amount: 0,
  rating: 0,
  showDisplayName: true,
};

export type FeedbackValues = typeof initialValues;

const useFeedback = () => {
  const formManager = useFormManager(initialValues, "feedback");

  const { values, validations, onChange, onBlur, onChangeMultiple, resetValidations } = formManager;

  const [ratingHandle, tipHandle, messageHandle, ratingIsLoading, tipIsLoading, messageIsLoading] =
    useFeedbackHandles(formManager);

  return {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles: [ratingHandle, tipHandle, messageHandle],
    resetValidations,

    ratingIsLoading,
    tipIsLoading,
    messageIsLoading,
  };
};

export default useFeedback;
