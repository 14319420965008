import { getVolumeLevel } from "@lib/utils/volumeMeter";
import { useEffect, useRef, useState } from "react";
import { AudioTrack } from "twilio-video";

const useParticipantIsSpeaking = (audioTrack?: AudioTrack) => {
  const [isSpeaking, setIsSpeaking] = useState(false);

  const shutdownRef = useRef<Function | null>(null);

  useEffect(() => {
    const run = async () => {
      if (!audioTrack) return;
      const shutdownAnalyser = await getVolumeLevel(audioTrack, level => {
        setIsSpeaking(level > 1000);
      });
      shutdownRef.current = shutdownAnalyser;
    };
    run();
  }, [audioTrack]);

  useEffect(() => {
    const shutdown = shutdownRef.current;
    shutdown && shutdown();
  }, []);

  return isSpeaking;
};

export default useParticipantIsSpeaking;
