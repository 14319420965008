import ProgressBar from "@components/common/ProgressBar";
import React, { PropsWithChildren, ReactNode } from "react";

interface Props {
  title: ReactNode;
  subTitle: ReactNode;
  progressPercent?: number;
}

export default function CigAuthWrapper(props: PropsWithChildren<Props>) {
  const { title, subTitle, progressPercent = 0, children } = props;

  return (
    <div className="cig-auth-wrapper">
      <div className="content-wrapper">
        <ProgressBar progressColor="purple" barColor="black-3" percent={progressPercent} />

        <h1 className="title">{title}</h1>
        <h2 className="sub-title">{subTitle}</h2>

        {children}
      </div>
      {/* <div className="illustration-wrapper"></div> */}
    </div>
  );
}
