import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import updateExperienceCustom, {
  GamePlatformSelection,
  RequestAvailableTime,
  UpdateExperienceCustomRes,
} from "../updateExperienceCustom";

const useUpdateExperienceCustom = (
  timezoneName: string,
  customerOffersPrice: boolean,
  pricePerPeriod: number,
  streamOptionEnabled: boolean,
  streamOptionPricePerPeriod: number,
  gamePlatformSelections: GamePlatformSelection[],
  requestAvailableTimes: RequestAvailableTime[],
  anyGame: boolean,
  anyTime: boolean,
) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<
    UpdateExperienceCustomRes | undefined
  >(undefined);

  const send = useCallback(async () => {
    if (
      timezoneName == null ||
      customerOffersPrice == null ||
      pricePerPeriod == null ||
      streamOptionEnabled == null ||
      streamOptionPricePerPeriod == null ||
      gamePlatformSelections == null ||
      requestAvailableTimes == null ||
      anyGame == null ||
      anyTime == null
    )
      return reqState;

    setLoading();
    try {
      const data = await updateExperienceCustom(
        timezoneName,
        customerOffersPrice,
        pricePerPeriod,
        streamOptionEnabled,
        streamOptionPricePerPeriod,
        gamePlatformSelections,
        requestAvailableTimes,
        anyGame,
        anyTime,
      );
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [
    timezoneName,
    customerOffersPrice,
    pricePerPeriod,
    streamOptionEnabled,
    streamOptionPricePerPeriod,
    gamePlatformSelections,
    requestAvailableTimes,
    anyGame,
    anyTime,
    reqState,
    setError,
    setLoading,
    setSuccess,
  ]);

  return { ...reqState, data: reqState.data, send };
};

export default useUpdateExperienceCustom;
