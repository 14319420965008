import { HomeSectionData, HomeSectionType } from "@api/public/get/getHome";
import DataCheck from "@components/common/DataCheck";
import CigCardCarouselSplash from "@components/splash/sections/CigCardCarouselSplash";
import GamesCarouselSplash from "@components/splash/sections/GamesCarouselSplash";
import UpcomingEventsSplash from "@components/splash/sections/UpcomingEventsSplash";

interface Props {
  section: HomeSectionData;
  isLoading?: boolean;
  error?: string;
}

export default function HomeSection(props: Props) {
  const { section, isLoading, error } = props;

  if (error)
    return (
      <section className="section error">
        <DataCheck error={error} />
      </section>
    );

  switch (section.type) {
    case HomeSectionType.Cigs: {
      const { title, cigs } = section;
      return <CigCardCarouselSplash title={title} cigs={cigs} isLoading={isLoading} />;
    }
    case HomeSectionType.UpcomingEvents: {
      const { title, sessions } = section;
      return <UpcomingEventsSplash title={title} events={sessions} isLoading={isLoading} />;
    }
    case HomeSectionType.Games: {
      const { title, games } = section;
      return <GamesCarouselSplash title={title} games={games?.items} isLoading={isLoading} />;
    }
    default: {
      return null;
    }
  }
}
