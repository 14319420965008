import { useCallback, useEffect, useState } from "react";

const getLastLowestChild = (
  element: Element | Document = document.body,
  skipClasses?: string[],
  skipId?: string,
): Element | null => {
  const children = element.children;
  for (let i = children.length; i--; i >= 0) {
    const child = children[i];
    if (skipClasses?.some(className => child.classList.contains(className)) || child.id === skipId)
      continue;
    if (child.children.length > 0) {
      const lowestChild = getLastLowestChild(child, skipClasses);
      if (lowestChild) return lowestChild;
    }
    return child;
  }
  return null;
};

const usePageDestruction = () => {
  const root = document.getElementById("root");

  const [destructInterval, setDestructInterval] = useState<NodeJS.Timeout | null>(null);
  const [elements, setElements] = useState<Element[]>([]);

  const reset = useCallback(() => {
    document.body.style.overflow = "";
    if (destructInterval) clearInterval(destructInterval);
    elements.forEach(child => child.classList.remove("drop-transition"), []);
  }, [elements, destructInterval]);

  const destruct = useCallback(
    () =>
      new Promise<boolean>(resolve => {
        if (!root) return;
        document.body.style.overflow = "hidden";

        const interval = setInterval(() => {
          const child = getLastLowestChild(root, ["drop-transition"], "portal-root");
          if (child) {
            child.classList.add("drop-transition");
            setElements(elements => [...elements, child]);
          }

          if (child == null) {
            clearInterval(interval);
            resolve(true);
          }
        }, 25);

        setDestructInterval(interval);
      }),
    [root, setElements, setDestructInterval],
  );

  useEffect(() => {
    return () => {
      destructInterval && clearInterval(destructInterval);
    };
  }, [destructInterval]);

  return { destruct, reset };
};

export default usePageDestruction;
