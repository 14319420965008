import useGetCustomerClaims from "@api/private/get/hooks/useGetCustomerClaims";
import React from "react";
import { useParams } from "react-router-dom";
import Claims from "./sections/Claims";
import CreateAClaim from "./sections/CreateAClaim";
import ClaimDetails from "./sections/ClaimDetails";
import useQuery from "@hooks/useQuery";

export default function SupportTab() {
  const { id } = useParams<Params>();
  const action = useQuery<"create" | "view">("action") ?? "view";

  const claimId = action === "view" ? id : undefined;
  const bookingId = action === "create" && id != null ? id : undefined;

  const isSelected = claimId != null;

  const { data: claims, isLoading, error, send: getClaims } = useGetCustomerClaims(true);

  return (
    <div className="sections-wrapper support">
      {isSelected && (
        <div className="sections">
          <ClaimDetails claim={claims.find(({ id }) => id === claimId)} />
        </div>
      )}
      {!isSelected && (
        <div className="sections">
          <CreateAClaim initialBookingId={bookingId} onSubmit={getClaims} />
          <Claims claims={claims} isLoading={isLoading} error={error} />
        </div>
      )}
    </div>
  );
}

interface Params {
  id?: string;
}
