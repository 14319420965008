import useGetCigPendingExperienceRequests from "@api/private/get/hooks/useGetCigPendingExperienceRequests";
import { ExperienceRequestState } from "@lib/enums/generic";

const useCigExperienceRequests = (isCig: boolean) => {
  const { data, isLoading, error, send } = useGetCigPendingExperienceRequests(isCig);

  return {
    data,
    pendingData: data.filter(({ state }) => state === ExperienceRequestState.Pending),
    isLoading,
    error,
    send: async () => {
      if (!isCig) return;
      return await send();
    },
  };
};

export default useCigExperienceRequests;
