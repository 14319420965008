import RoomContextProvider from "./roomContext/RoomContextProvider";
import RoomInner from "./RoomInner";
import useForceRerender from "@hooks/useForceRerender";

export default function Room() {
  const [number, rerender] = useForceRerender();

  return (
    <RoomContextProvider key={number} rerender={rerender}>
      <RoomInner />
    </RoomContextProvider>
  );
}
