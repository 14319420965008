import { Config } from "./config";
import awsExports from "./integration/development.aws-exports";

const config: Config = {
  apiURL: "https://api.yakkr.co.uk/",
  wsUrl: "wss://u9hi257ai8.execute-api.eu-west-2.amazonaws.com/dev",
  awsExports,
  gaKey: "G-NT6TF5ZJSN",
  hjid: 3064169,
  hjsv: 6,
};

export default config;
