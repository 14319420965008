import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getCurrency, { GetCurrencyRes } from "../getCurrency";

const useGetCurrency = (autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<
    GetCurrencyRes | undefined
  >(undefined);

  const send = useCallback(async () => {
    setLoading();
    try {
      const currency = await getCurrency();
      setSuccess(currency);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetCurrency;
