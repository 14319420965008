import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";

import GuardedRoute from "@components/routing/GuardedRoute";

import Home from "@pages/home/Home";
import Bookings from "@pages/bookings/Bookings";
// import CIGSplash from "@pages/cigSplash/CIGSplash"; // Legacy
import CIGProfile from "@pages/cigProfile/CIGProfile";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createErrorSnackbar, createWarnSnackbar } from "@lib/utils/generic";
import useUserIsCig from "@hooks/session/useUserIsCig";
import useUserIsRegistered from "@hooks/session/useUserIsRegistered";
import { useCallback, useEffect } from "react";
import PaymentCompletion from "@pages/bookings/payment-completion/PaymentCompletion";
import Room from "@pages/bookings/room/Room";
import Settings from "@pages/settings/Settings";
import useRedirectToLogin from "@hooks/useRedirectToLogin";
import CIGOverlay from "@pages/cigProfile/overlay/CIGOverlay";
import Feedback from "@pages/bookings/room/feedback/Feedback";
import ReportIssue from "@pages/reportIssue/ReportIssue";
import { isDev } from "@config/config";
import StyleGuide from "@pages/styleGuide/StyleGuide";
import BecomeTalentOld from "@pages/becomeTalentOld/BecomeTalentOld";
import CigRegister from "@pages/cigAuth/cigRegister/CigRegister";
import { AuthState } from "@lib/enums/generic";
import useStripeIsSetup from "@hooks/session/useStripeIsSetup";
import CigLogin from "@pages/cigAuth/cigLogin/CigLogin";
import BecomeTalent from "@pages/becomeTalent/BecomeTalent";
import ExperiencesRequest from "@pages/experiences/request/ExperiencesRequest";
import ExperiencesManageSelect from "@pages/experiences/manage/select/ExperiencesManageSelect";
import UpdateExperiencePackage from "@pages/experiences/manage/update/package/UpdateExperiencePackage";
import UpdateExperienceCustom from "@pages/experiences/manage/update/custom/UpdateExperienceCustom";
import ExperiencesRequests from "@pages/experiences/requests/ExperiencesRequests";
import ExperiencesPaymentCompletion from "@pages/myExperiences/paymentCompletion/ExperiencesPaymentCompletion";
import MyExperiences from "@pages/myExperiences/MyExperiences";
import ConsultancyLanding from "@pages/landing/consultancy/ConsultancyLanding";
import FlankLanding from "@pages/landing/flank/FlankLanding";

export default function Routes() {
  const {
    user,
    cigProfile,
    initialLoading,

    authState,

    pushSnackbar,
    setLoading,
    signOut,
  } = useGlobalContext();

  const history = useHistory();
  const location = useLocation();

  const isLoggedIn = user != null;
  const isRegistered = useUserIsRegistered();
  const isCig = useUserIsCig();
  const isStripeSetup = useStripeIsSetup();

  const redirectToLogin = useRedirectToLogin();

  const notLoggedIn = useCallback(() => {
    pushSnackbar(createErrorSnackbar("You must be logged in to do that", 3000));
    redirectToLogin();
  }, [redirectToLogin, pushSnackbar]);

  const alreadyLoggedIn = useCallback(() => {
    pushSnackbar(createWarnSnackbar("You're already logged in", 3000));
    history.push("/");
  }, [history, pushSnackbar]);

  const notCig = useCallback(() => {
    pushSnackbar(createErrorSnackbar("You must be Yakkr Talent to do that", 3000));
    history.push("/register/splash");
  }, [history, pushSnackbar]);

  const alreadyCig = useCallback(() => {
    pushSnackbar(createWarnSnackbar("You're already Yakkr Talent", 3000));
    history.push("/");
  }, [history, pushSnackbar]);

  const ownProfile = useCallback(() => {
    const query = location.search;
    if (!cigProfile) notCig();
    else if (cigProfile.id) history.push(`/@${cigProfile.displayName}${query}`);
  }, [history, location, cigProfile, notCig]);

  useEffect(() => setLoading(initialLoading), [initialLoading, setLoading]);

  useEffect(() => window.scrollTo({ top: 0 }), [location.pathname]);

  return (
    <Switch>
      <Route exact path="/" component={Home} />

      <GuardedRoute
        guarded={isRegistered && isCig && isStripeSetup && authState !== AuthState.CigRegistering}
        disable={initialLoading && authState !== AuthState.CigRegistering}
        onGuard={alreadyCig}
        exact
        path="/cig/register"
        component={CigRegister}
      />

      <GuardedRoute
        guarded={isLoggedIn && authState !== AuthState.CigLoggingIn}
        disable={initialLoading && authState !== AuthState.CigLoggingIn}
        onGuard={alreadyLoggedIn}
        exact
        path="/cig/login"
        component={CigLogin}
      />

      {/* Legacy redirect */}
      <GuardedRoute
        guarded
        onGuard={() => history.push(`/become-talent`)}
        exact
        path="/register/splash"
      />

      {/* Legacy <GuardedRoute
        guarded={isCig}
        disable={initialLoading}
        onGuard={alreadyCig}
        exact
        path="/register/choose"
        component={CIGSplash}
      /> */}

      {/* Legacy redirect */}
      <GuardedRoute
        guarded
        onGuard={() => history.push(`/become-talent`)}
        exact
        path="/register/become-talent"
      />
      <GuardedRoute
        guarded={isCig}
        disable={initialLoading}
        onGuard={alreadyCig}
        exact
        path="/become-talent-old"
        component={BecomeTalentOld}
      />
      <GuardedRoute
        guarded={isCig}
        disable={initialLoading}
        onGuard={alreadyCig}
        exact
        path="/become-talent"
        component={BecomeTalent}
      />

      <GuardedRoute
        guarded
        disable={initialLoading || !isLoggedIn}
        onGuard={signOut}
        exact
        path="/logout"
      />

      <GuardedRoute
        guarded={true}
        onGuard={() => history.push("/settings/personal")}
        exact
        path="/settings"
        component={Settings}
      />
      <GuardedRoute
        guarded={!isLoggedIn}
        disable={initialLoading}
        onGuard={notLoggedIn}
        path="/settings/:tab"
        exact
        component={Settings}
      />
      <GuardedRoute
        guarded={!isLoggedIn}
        disable={initialLoading}
        onGuard={notLoggedIn}
        path="/settings/:tab/:id"
        component={Settings}
      />

      <GuardedRoute
        guarded={!isLoggedIn}
        disable={initialLoading}
        onGuard={notLoggedIn}
        exact
        path="/bookings"
        component={Bookings}
      />
      <Route exact path="/bookings/payment-completion/:id" component={PaymentCompletion} />
      <GuardedRoute
        guarded={!isLoggedIn}
        disable={initialLoading}
        onGuard={notLoggedIn}
        exact
        path="/bookings/room/:sessionId"
        component={Room}
      />
      <GuardedRoute
        guarded={!isLoggedIn}
        disable={initialLoading}
        onGuard={notLoggedIn}
        exact
        path="/bookings/room/:sessionId/post"
        component={Feedback}
      />
      <GuardedRoute
        guarded={!isLoggedIn}
        disable={initialLoading}
        onGuard={notLoggedIn}
        exact
        path="/bookings/:id"
        component={Bookings}
      />

      {/* Request */}
      <GuardedRoute
        exact
        path="/experiences/request/:cigId"
        component={ExperiencesRequest}
        guarded={!isLoggedIn}
        disable={initialLoading}
        onGuard={notLoggedIn}
      />
      <GuardedRoute
        exact
        path="/experiences/request/:cigId/:packageId"
        component={ExperiencesRequest}
        guarded={!isLoggedIn}
        disable={initialLoading}
        onGuard={notLoggedIn}
      />

      {/* Manage */}
      <GuardedRoute
        exact
        path="/experiences"
        component={ExperiencesManageSelect}
        guarded={!isCig}
        disable={initialLoading}
        onGuard={notCig}
      />
      <GuardedRoute
        exact
        path="/experiences/update/custom"
        component={UpdateExperienceCustom}
        guarded={!isCig}
        disable={initialLoading}
        onGuard={notCig}
      />
      <GuardedRoute
        exact
        path="/experiences/update/package"
        component={UpdateExperiencePackage}
        guarded={!isCig}
        disable={initialLoading}
        onGuard={notCig}
      />
      <GuardedRoute
        exact
        path="/experiences/update/package/:packageId"
        component={UpdateExperiencePackage}
        guarded={!isCig}
        disable={initialLoading}
        onGuard={notCig}
      />
      <GuardedRoute
        exact
        path="/experiences/requests"
        component={ExperiencesRequests}
        guarded={!isCig}
        disable={initialLoading}
        onGuard={notCig}
      />
      <GuardedRoute
        exact
        path="/experiences/payment-completion/:requestId"
        component={ExperiencesPaymentCompletion}
        guarded={!isLoggedIn}
        disable={initialLoading}
        onGuard={notLoggedIn}
      />

      {/* Requests */}
      <GuardedRoute
        exact
        path="/my-experiences"
        component={MyExperiences}
        guarded={!isLoggedIn}
        disable={initialLoading}
        onGuard={notLoggedIn}
      />

      <Route exact path="/report-issue" component={ReportIssue} />

      {isDev && <Route exact path="/style-guide" component={StyleGuide} />}

      <GuardedRoute
        exact
        path="/cig-profile/my-profile"
        guarded
        onGuard={ownProfile}
        disable={initialLoading}
      />

      <Route exact path="/cig-profile/:cigId" component={CIGProfile} />
      <Route exact path="/cig-profile/:cigId/overlay" component={CIGOverlay} />

      <GuardedRoute
        exact
        path="/my-profile"
        guarded
        onGuard={ownProfile}
        disable={initialLoading}
      />

      <Route exact path="/@:cigDisplayName" component={CIGProfile} />
      <Route exact path="/@:cigDisplayName/overlay" component={CIGOverlay} />

      <Route exact path="/landing/consultancy" component={ConsultancyLanding} />

      <Route exact path="/landing/flank" component={FlankLanding} />

      <Redirect to="/" />
    </Switch>
  );
}
