import dayjs from "dayjs";
import { RoomParticipantType } from "../roomContext/RoomContextProvider";
import useRoomContext from "../roomContext/hooks/useRoomContext";
import { Session } from "@lib/types/session";

const useIsJoinable = (overrideSession?: Session, overrideType?: RoomParticipantType) => {
  const { session: contextSession, type: contextType } = useRoomContext();

  const session = overrideSession ?? contextSession;
  const type = overrideType ?? contextType;

  const from = session?.from;

  const isCig = type === "cig";
  const isJoinable = isCig
    ? dayjs(from).isBefore(dayjs().add(2, "minutes"))
    : dayjs(from).isBefore(dayjs());

  return isJoinable;
};

export default useIsJoinable;
