import { api } from "@api/api";

export interface ResendBookingEmailRes {}

const resendBookingEmail = async (linkingID?: string) => {
  const { data } = await api.get<ResendBookingEmailRes>("/resendBookingEmail", {
    params: {
      linkingID,
    },
  });
  return data;
};

export default resendBookingEmail;
