import useCancelCustomerBooking from "@api/private/cancel/hooks/useCancelCustomerBooking";
import DialogueModal from "@components/modals/DialogueModal";
import Section from "@components/section/Section";
import SectionBar from "@components/section/SectionBar";
import Button from "@components/form/Button";
import Menu from "@components/form/Menu";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createErrorSnackbar, createSuccessSnackbar } from "@lib/utils/generic";
import React, { useState } from "react";
import { IoWarningOutline } from "react-icons/io5";

interface Props {
  bookingId?: string;
  error?: string | null;
  isLoading?: boolean;
  onCancel?: () => void;
}

export default function CustomerCancel(props: Props) {
  const { bookingId, error, isLoading, onCancel = () => {} } = props;

  const { breakpoints, pushSnackbar } = useGlobalContext();

  const [confirmModalActive, setConfirmModalActive] = useState(false);

  const { isLoading: cancelIsLoading, send: cancelCustomerBooking } =
    useCancelCustomerBooking(bookingId);

  const handleCancel = async () => {
    const { error } = await cancelCustomerBooking();
    if (error) {
      pushSnackbar(createErrorSnackbar(`Cancel failed, something went wrong.\n${error}`, 5000));
    } else {
      onCancel();
      pushSnackbar(createSuccessSnackbar("Booking cancelled", 2500));
    }
  };

  return (
    <Section
      className="booking-cancel"
      dataCheckProps={{ error, isLoading, loadingIndicator: "gloss" }}>
      <SectionBar className="bg-red">
        <p className="text-white flex-row align-center" style={{ gap: 8 }}>
          <IoWarningOutline /> Need to cancel?
        </p>
        <Menu
          relativePosition="left"
          variant="flat"
          color="white"
          size="small"
          content={
            <Button
              variant="contained"
              size={breakpoints.sm ? "small" : "medium"}
              color="white"
              textColor="black"
              onClick={() => setConfirmModalActive(true)}>
              Click to continue
            </Button>
          }>
          <Button color="white" isLoading={cancelIsLoading}>
            Cancel
          </Button>
        </Menu>
      </SectionBar>

      <DialogueModal
        active={confirmModalActive}
        onClose={() => setConfirmModalActive(false)}
        onConfirm={handleCancel}
        confirmButtonText="Cancel Booking"
        title="Please Note"
        subTitle={
          <>
            We do <b>NOT</b> have a refund policy for cancellations, thus you will not receive a
            refund upon cancellation.
          </>
        }
      />
    </Section>
  );
}
