import DataCheck from "@components/common/DataCheck";
import Divider from "@components/common/Divider";
import Pill from "@components/common/Pill";
import Button from "@components/form/Button";
import { colors } from "@lib/constants/colors";
import { Currency } from "@lib/enums/generic";
import { UIColor } from "@lib/types/generic";
import { classNames, getCurrencySymbol } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { cloneElement, ReactElement, ReactNode } from "react";
import { IoArrowForward } from "react-icons/io5";

interface Props {
  color: UIColor;
  icon: ReactElement;

  currency?: Currency;
  currencyIsLoading: boolean;
  currencyError?: string;

  price: number;

  title: string;
  list: ReactNode[];
  prevText?: string;

  isMostPopular?: boolean;

  onClick: () => void;
}

const Card = (props: Props) => {
  const {
    color,
    icon,

    currency,
    currencyIsLoading,
    currencyError,

    price,

    title,
    list,
    prevText,
    isMostPopular,

    onClick,
  } = props;

  const { breakpoints } = useGlobalContext();

  return (
    <div
      className={classNames("card", color)}
      style={
        {
          "--color": colors[color],
        } as any
      }>
      <div className="top">
        {cloneElement(icon, {
          className: classNames("card-icon", icon.props.className),
        })}

        {isMostPopular && (
          <Pill size={breakpoints.sm ? "medium" : "large"} color="purple-6" textColor="black-1">
            Most Popular
          </Pill>
        )}
      </div>

      <div className="head">
        <div className="price">
          <DataCheck
            isLoading={currencyIsLoading}
            error={currencyError}
            loadingIndicator="gloss"
            loadingContent={<>&nbsp;</>}>
            <span className="amount">
              {getCurrencySymbol(currency)}
              {price}
            </span>
            <span className="per">/mo</span>
          </DataCheck>
        </div>
        <h2 className="title">{title}</h2>
      </div>

      <Divider color="black" opacity={0.1} className="divider-1" />

      <div className="content">
        <div className="content-header"></div>

        {prevText && (
          <>
            <p className="prev-text">✓ {prevText}</p>
            <p className={classNames("plus", isMostPopular && "purple")}>PLUS</p>
          </>
        )}

        <ul className="perks-list">
          {list.map((text, i) => (
            <li key={i}>✓ {text}</li>
          ))}
        </ul>
      </div>

      <Divider color="black" opacity={0.1} className="divider-2" />

      <Button
        size={breakpoints.sm ? "large" : "extra-large"}
        color={isMostPopular ? "purple" : "gray-1"}
        textColor="white"
        endIcon={<IoArrowForward />}
        justifyContent="center"
        onClick={onClick}>
        {title}
      </Button>
    </div>
  );
};

export default Card;
