import Button from "@components/form/Button";
import Form from "@components/form/Form";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useUpdateExperiencePackageContext from "./context/useUpdateExperiencePackageContext";
import Availability from "./sections/Availability";
import Games from "./sections/Games";
import Prices from "./sections/Prices";
import { IoArrowBack } from "react-icons/io5";
import DataCheck from "@components/common/DataCheck";
import ExperiencesHeader from "@pages/experiences/ExperiencesHeader";

export default function UpdateExperienceCustomDesktop() {
  const { breakpoints } = useGlobalContext();

  const {
    validations,
    update,
    updateIsLoading,
    resetValidations,
    fullCustomExperienceIsLoading,
    fullCustomExperienceError,
  } = useUpdateExperiencePackageContext();

  const saveButton = (
    <Button
      size="large"
      color="purple"
      textColor="white"
      type="submit"
      isLoading={updateIsLoading}
      justifyContent="center"
      className="save-button">
      Finish
    </Button>
  );

  return (
    <div id="update-experience-custom-page" className="experiences-manage-page desktop">
      <ExperiencesHeader
        title="Custom Setup"
        subTitle="Set up your availability, prices, and games so your fans can request a session with you."
        actions={
          <Button
            color="black-4"
            textColor="white"
            size={breakpoints.md ? "medium" : "large"}
            startIcon={<IoArrowBack />}
            justifyContent="center"
            to="/experiences">
            Back
          </Button>
        }
      />

      <Form
        id="update-custom-experience"
        onCompletion={update}
        resetValidations={resetValidations}
        validation={validations.generic}>
        <DataCheck
          isLoading={fullCustomExperienceIsLoading}
          error={fullCustomExperienceError}
          loadingIndicator="spinner">
          <div className="form-section-outer">
            <div className="form-section-wrapper">
              <Availability />
            </div>
          </div>
          <div className="form-section-outer">
            <div className="form-section-wrapper">
              <Games />
            </div>
          </div>
          <div className="form-section-outer">
            <div className="form-section-wrapper">
              <Prices />
            </div>
            {!breakpoints.md && saveButton}
          </div>
          {breakpoints.md && <div className="form-section-outer">{saveButton}</div>}
        </DataCheck>
      </Form>
    </div>
  );
}
