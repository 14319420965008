import { Config } from "./config";
import awsExports from "./integration/production.aws-exports";

const config: Config = {
  apiURL: "https://prod.yakkr.co.uk/",
  wsUrl: "wss://3df1b5bt8a.execute-api.eu-west-2.amazonaws.com/prod",
  awsExports,
  gaKey: "G-SDN4E4HY0P",
  hjid: 3064173,
  hjsv: 6,
};

export default config;
