import React from "react";
import dataImg from "@images/becomeTalentOld/data.svg";
import DataCheck from "@components/common/DataCheck";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import CtaButton from "../CtaButton";

export default function TopSplash() {
  return (
    <section className="section top-splash">
      <SlideOnIntersect direction="right">
        <div className="cta">
          <h1 className="title">
            <span>Game</span>
            <br />
            with your
            <br />
            community
          </h1>
          <h2 className="sub-title">
            Gaming experiences like no other. Entertain, engage and earn whilst playing your
            favourite games.
          </h2>
          <CtaButton>Sign me up</CtaButton>
        </div>
      </SlideOnIntersect>
      <SlideOnIntersect direction="left">
        <div className="data">
          <div className="data-img-wrapper">
            <DataCheck imgs={[dataImg]} loadingIndicator="gloss">
              <img src={dataImg} alt="Data" className="data-img" />
            </DataCheck>
          </div>
        </div>
      </SlideOnIntersect>
    </section>
  );
}
