import Avatar from "@components/common/Avatar";
import Rating from "@components/common/Rating";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import SplashBox from "../SplashBox";
import SplashText from "../SplashText";
import SplashTitle from "../SplashTitle";

import { ReactNode } from "react";
import { classNames } from "@lib/utils/generic";

const timeouts = [500, 700, 800];

interface FanTestimonial {
  displayName: string;
  text: ReactNode;
  avatarImg: string;
}

interface Props {
  title?: string;

  fanTestimonials: [FanTestimonial, FanTestimonial, FanTestimonial];

  noBackground?: boolean;
}

const FanTestimonialsSplash = (props: Props) => {
  const { title, fanTestimonials, noBackground } = props;

  const { breakpoints } = useGlobalContext();

  return (
    <section className={classNames("section", "fan-testimonials", noBackground && "no-background")}>
      {title && <SplashTitle>{title}</SplashTitle>}
      <div className="boxes">
        {fanTestimonials.map((fanTestimonial, i) => {
          if (i === 2 && breakpoints.lg && !breakpoints.md) return null;
          return <FanTestimonialBox key={i} {...fanTestimonial} timeout={timeouts[i]} />;
        })}
      </div>
    </section>
  );
};

interface FanTestimonialProps {
  avatarImg: string;
  displayName: string;
  text: ReactNode;
  timeout: number;
}

const FanTestimonialBox = (props: FanTestimonialProps) => {
  const { avatarImg, displayName, text, timeout } = props;

  const { breakpoints } = useGlobalContext();

  return (
    <SlideOnIntersect direction="up" timeout={timeout} useParent={!breakpoints.lg}>
      <SplashBox className="fan-testimonial">
        <div className="fan-details">
          <Avatar
            src={avatarImg}
            size={breakpoints.sm ? 50 : breakpoints.sl ? 60 : breakpoints.ul ? 80 : 120}
          />
          <SplashText className="display-name">{displayName}</SplashText>
        </div>
        <SplashText className="fan-testimonial-text">{text}</SplashText>

        <Rating
          value={5}
          color="yellow"
          size={breakpoints.sl ? "small" : breakpoints.ul ? "medium" : "large"}
        />
      </SplashBox>
    </SlideOnIntersect>
  );
};

export default FanTestimonialsSplash;
