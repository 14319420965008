import { api } from "@api/api";

export interface RegisterFlankInterestRes {}

const registerFlankInterest = async (email: string, twitchUsername: string) => {
  const { data } = await api.get<RegisterFlankInterestRes>("/registerFlankInterest", {
    params: {
      email,
      twitchUsername,
    },
  });
  return data;
};

export default registerFlankInterest;
