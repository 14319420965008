import Section from "@components/section/Section";
import React from "react";
import BookingItem from "./BookingItem";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useParams } from "react-router-dom";
import { IoPeople, IoTicket } from "react-icons/io5";
import { Booking } from "./Bookings";
import useQuery from "@hooks/useQuery";
import usePaginate from "@hooks/usePaginate";
import { IoIosLock } from "react-icons/io";

interface Props {
  title?: string;
  bookings?: Booking[];
  minBars?: number;
}

export default function BookingList(props: Props) {
  const { title, bookings = [], minBars = 3 } = props;
  const { id: selectedId } = useParams<Params>();
  const selectedType = useQuery<"booking" | "session">("type");

  const { breakpoints } = useGlobalContext();

  const {
    paginatedData: paginatedBookings,
    canGoNext,
    canGoPrev,
    goToNextPage,
    goToPrevPage,
    goToFirstPage,
  } = usePaginate(bookings, minBars, minBars, true);

  return (
    <Section
      title={title}
      useMoreButtons
      noBackground
      noDividers
      color={breakpoints.md ? "black" : "white"}
      remainingBars={bookings.length - paginatedBookings.length}
      canShowMore={canGoNext}
      canShowLess={canGoPrev}
      onShowMore={goToNextPage}
      onShowLess={goToPrevPage}
      onReset={goToFirstPage}
      dataCheckProps={{
        isEmpty: bookings.length === 0,
        emptyMessage: `No ${title} Bookings`,
      }}>
      {paginatedBookings.map(
        (
          {
            type,
            sessionId,
            bookingId,
            cigName,
            cigProfileImg,
            gameName,
            from,
            price,
            free,
            currency,
            slotsAvailable,
            maxSlots,
            cancellation,
            private: privateSession,
          },
          i,
        ) => {
          const isSession = type === "session";

          const topText = isSession ? gameName : cigName;
          const bottomText = isSession ? (
            <>
              <IoTicket />{" "}
              {privateSession
                ? `${maxSlots} sold`
                : `${maxSlots - slotsAvailable} / ${maxSlots} sold`}
            </>
          ) : (
            gameName
          );

          const group = (
            <div className="avatar-group">{privateSession ? <IoIosLock /> : <IoPeople />}</div>
          );

          const id = isSession ? sessionId : bookingId;

          return (
            <BookingItem
              key={i}
              type={type}
              avatarImg={isSession ? undefined : cigProfileImg}
              avatarIcon={isSession ? group : undefined}
              topText={topText}
              bottomText={bottomText}
              date={from}
              price={price}
              free={free}
              currency={currency}
              cigName={cigName}
              private={privateSession}
              cancellation={cancellation}
              id={id}
              selected={selectedId === id?.toString() && type === selectedType}
            />
          );
        },
      )}
    </Section>
  );
}

interface Params {
  id: string;
}
