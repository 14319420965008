import { Children, cloneElement, isValidElement, ReactElement, ReactNode } from "react";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import { classNames } from "@lib/utils/generic";
import SplashTitle from "../SplashTitle";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { CSSProps } from "@lib/types/generic";

interface Props extends CSSProps {
  views: SplitViewProps[];
}

const SplitViewsSplash = (props: Props) => {
  const { views, className, ...cssProps } = props;

  return (
    <section className={classNames("section split-views", className)} {...cssProps}>
      {views.map((view, i) => (
        <SplitView {...view} key={i} />
      ))}
    </section>
  );
};

interface SplitViewProps {
  title: string;
  content: ReactNode;
  buttons?: ReactElement;
  img?: string;
  imgAlt?: string;
  altContent?: ReactNode;
  centeredTitle?: boolean;
  reverse?: boolean;
}

const SplitView = (props: SplitViewProps) => {
  const { title, content, buttons, img, imgAlt, altContent, centeredTitle, reverse } = props;

  const { breakpoints } = useGlobalContext();

  const buttonSize = breakpoints.md ? "medium" : !breakpoints.sl ? "extra-large" : "large";

  return (
    <SlideOnIntersect direction={reverse ? "left" : "right"} timeout={500} useParent={false}>
      <div className="split-view-wrapper">
        {title && centeredTitle && <SplashTitle noTransition>{title}</SplashTitle>}
        <div className={classNames("split-view", reverse && "reverse")}>
          {img ? (
            <div className="img-wrapper">
              <img src={img} alt={imgAlt} />
            </div>
          ) : (
            <div className=" content-wrapper alt">{altContent}</div>
          )}
          <div className="content-wrapper">
            {title && !centeredTitle && <SplashTitle noTransition>{title}</SplashTitle>}
            {content && <>{content}</>}
            {buttons && (
              <div className="button-row">
                {Children.map(
                  buttons.props.children,
                  child =>
                    isValidElement(child) &&
                    cloneElement(child, { size: buttonSize, justifyContent: "center" } as any),
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </SlideOnIntersect>
  );
};

export default SplitViewsSplash;
