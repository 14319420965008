import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createGuestHolding, { CreateGuestHoldingRes } from "../createGuestHolding";

const useCreateGuestHolding = (availabilityID?: string, slotsRequested?: number) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CreateGuestHoldingRes | null>(null);

  const send = useCallback(
    async (email?: string) => {
      if (availabilityID == null || slotsRequested == null) return reqState;
      setLoading();
      try {
        const data = await createGuestHolding(availabilityID, slotsRequested, email);
        return setSuccess(data);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [availabilityID, slotsRequested, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useCreateGuestHolding;
