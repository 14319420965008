import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const cigProfileValidators = {
  tagLine: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(75),
  shortDescription: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(380),
};

export default cigProfileValidators;
