import React from "react";
import CIGProfileContextProvider from "./context/CIGProfileContext";
import Footer from "@components/layout/Footer";
import Banner from "./sections/Banner";
import ClipsAndReviews from "./sections/ClipsAndReviews";
import Experiences from "./sections/Experiences";
import BuildBar from "./sections/BuildBar";
import HowItWorks from "./sections/HowItWorks";
import Events from "./sections/Events";
import Ready from "./sections/Ready";
import Manage from "./calendar/Manage";
import Book from "./calendar/Book";

export default function CIGProfile() {
  return (
    <CIGProfileContextProvider>
      <div id="cig-profile-page">
        <Banner />
        <ClipsAndReviews />
        <Experiences />
        <BuildBar />
        <HowItWorks />
        <Events />
        <Ready />

        <Footer />
      </div>

      <Manage />
      <Book />
    </CIGProfileContextProvider>
  );
}
