import { CSSProps } from "@lib/types/generic";

export default function FluentRedQuestionMark(cssProps: CSSProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.9258 55.2148C31.4715 55.2148 33.5352 53.1512 33.5352 50.6055C33.5352 48.0598 31.4715 45.9961 28.9258 45.9961C26.3801 45.9961 24.3164 48.0598 24.3164 50.6055C24.3164 53.1512 26.3801 55.2148 28.9258 55.2148Z"
        fill="url(#paint0_linear_8184_83426)"
      />
      <path
        d="M28.9258 55.2148C31.4715 55.2148 33.5352 53.1512 33.5352 50.6055C33.5352 48.0598 31.4715 45.9961 28.9258 45.9961C26.3801 45.9961 24.3164 48.0598 24.3164 50.6055C24.3164 53.1512 26.3801 55.2148 28.9258 55.2148Z"
        fill="url(#paint1_radial_8184_83426)"
      />
      <path
        d="M28.9258 55.2148C31.4715 55.2148 33.5352 53.1512 33.5352 50.6055C33.5352 48.0598 31.4715 45.9961 28.9258 45.9961C26.3801 45.9961 24.3164 48.0598 24.3164 50.6055C24.3164 53.1512 26.3801 55.2148 28.9258 55.2148Z"
        fill="url(#paint2_radial_8184_83426)"
      />
      <g filter="url(#filter0_d_8184_83426)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.4163 13.1387C26.6721 13.5536 25.7428 14.3464 25.2155 15.0747C24.6255 15.8859 24.3711 16.8539 24.3711 17.6688C24.3711 18.8913 23.8855 20.0637 23.021 20.9282C22.1566 21.7926 20.9842 22.2782 19.7617 22.2782C18.5392 22.2782 17.3668 21.7926 16.5024 20.9282C15.638 20.0637 15.1523 18.8913 15.1523 17.6688C15.1523 12.5192 18.3162 5.97947 26.4619 4.12834L26.6426 4.09147C31.9673 3.10506 36.6173 4.56162 39.8807 7.74947C43.0206 10.8119 44.5085 15.1042 44.5085 19.2821C44.5085 23.4361 43.3156 26.8286 40.6053 29.7067C38.7394 31.6869 36.2633 33.2872 33.5032 34.7991V38.4682C33.5032 39.6907 33.0176 40.8631 32.1531 41.7275C31.2887 42.5919 30.1163 43.0776 28.8938 43.0776C27.6713 43.0776 26.4989 42.5919 25.6345 41.7275C24.7701 40.8631 24.2844 39.6907 24.2844 38.4682V32.0151C24.2848 31.1664 24.5194 30.3342 24.9625 29.6104C25.4056 28.8865 26.0399 28.299 26.7956 27.9127C30.6454 25.9436 32.7085 24.6437 33.8941 23.3863C34.7846 22.4405 35.2898 21.4098 35.2898 19.2821C35.2898 17.1802 34.5338 15.4102 33.4423 14.3464C32.491 13.4172 30.9588 12.6907 28.4163 13.1387Z"
          fill="url(#paint3_linear_8184_83426)"
        />
      </g>
      <g filter="url(#filter1_f_8184_83426)">
        <path
          d="M20.8008 17.2655C20.8008 13.8951 22.8178 9.51619 28.5206 8.22004C36.4432 6.75057 40.9382 12.5989 40.9382 18.8788C40.9382 25.1586 37.5383 27.7232 29.9329 31.6117V36.5972"
          stroke="url(#paint4_linear_8184_83426)"
          stroke-width="3.6875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.8008 17.2655C20.8008 13.8951 22.8178 9.51619 28.5206 8.22004C36.4432 6.75057 40.9382 12.5989 40.9382 18.8788C40.9382 25.1586 37.5383 27.7232 29.9329 31.6117V36.5972"
          stroke="url(#paint5_linear_8184_83426)"
          stroke-width="3.6875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8184_83426"
          x="13.7695"
          y="2.40381"
          width="34.8867"
          height="44.8223"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.38281" dy="1.38281" />
          <feGaussianBlur stdDeviation="1.38281" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.8 0 0 0 0 0.180392 0 0 0 0 0.258824 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8184_83426" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8184_83426"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_8184_83426"
          x="16.1914"
          y="3.38232"
          width="29.3555"
          height="37.8242"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.38281" result="effect1_foregroundBlur_8184_83426" />
        </filter>
        <linearGradient
          id="paint0_linear_8184_83426"
          x1="28.9258"
          y1="48.2344"
          x2="28.9258"
          y2="55.2148"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF0B38" />
          <stop offset="1" stop-color="#F62093" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_8184_83426"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.7124 49.9638) rotate(-48.8141) scale(4.28735 3.76944)">
          <stop offset="0.234" stop-color="#FF5878" />
          <stop offset="1" stop-color="#FF5878" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_8184_83426"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.3164 47.0821) rotate(25.9892) scale(5.12793 13.8845)">
          <stop stop-color="#C8404B" />
          <stop offset="1" stop-color="#C8404B" stop-opacity="0" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_8184_83426"
          x1="29.8304"
          y1="4.67778"
          x2="29.8304"
          y2="43.0794"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#ED1F28" />
          <stop offset="1" stop-color="#F41585" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8184_83426"
          x1="41.5706"
          y1="13.3198"
          x2="32.929"
          y2="20.4644"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.103" stop-color="#FF5867" />
          <stop offset="1" stop-color="#FF5867" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8184_83426"
          x1="34.7727"
          y1="39.5933"
          x2="40.9382"
          y2="25.3042"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF3C80" />
          <stop offset="1" stop-color="#FF3C80" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
