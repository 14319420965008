import { CigPackageExperience } from "@api/public/get/getCigPackageExperiences";
import { Compatibility } from "@api/public/get/getCompatibility";
import useFormManager from "@components/form/hooks/useFormManager";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import useCreateExperienceRequestHandle from "./useCreateExperienceRequestHandle";

const initialValues = {
  gameId: "",
  platformId: "",
  startTime: undefined as Dayjs | undefined,
  endTime: undefined as Dayjs | undefined,
  cigId: "",
  customerRequestDetails: "",

  sessionLength: 15,
  tickets: 1,
  streamOption: false,

  packageId: undefined as string | undefined,

  selectedDate: dayjs().startOf("day"),
};

export type CreateExperienceRequestValues = typeof initialValues;

const useExperienceRequestCreate = (
  compatibility: Compatibility[],
  platformId: string | null,
  gameId: string | null,
  packageId?: string,
  cigId?: string,
  packageExperience?: CigPackageExperience,
) => {
  if (platformId) initialValues.platformId = platformId;
  if (gameId) initialValues.gameId = gameId;
  if (packageId) initialValues.packageId = packageId;
  if (cigId) initialValues.cigId = cigId;

  const [submitted, setSubmitted] = useState(false);

  const formManager = useFormManager(initialValues, "createExperienceRequest");

  const { values, validations, onChange, onBlur, resetValidations, onChangeMultiple } = formManager;

  const [handle, createIsLoading] = useCreateExperienceRequestHandle(formManager, () =>
    setSubmitted(true),
  );

  useEffect(() => {
    if (compatibility.length === 0) return;
    const selectedGame = compatibility.find(({ game: { id } }) => id === values.gameId);
    const selectedPlatform = selectedGame?.compatiblePlatforms.items.find(
      ({ platform: { id } }) => id === values.platformId,
    );

    if (!selectedGame || !selectedPlatform)
      return onChange({ name: "platformId", value: undefined });

    // eslint-disable-next-line
  }, [values.gameId, values.platformId]);
  // must omit onChange to prevent loop

  useEffect(() => {
    if (!packageExperience) return;
    const { sessionLength, tickets } = packageExperience;

    onChangeMultiple([
      {
        name: "sessionLength",
        value: sessionLength,
      },
      {
        name: "tickets",
        value: tickets,
      },
    ]);

    // eslint-disable-next-line
  }, [packageExperience]);
  // must omit onChangeMultiple to prevent loop

  return {
    values,
    validations,
    onChange,
    onBlur,
    onChangeMultiple,

    createIsLoading,

    createRequest: handle,
    resetValidations,

    submitted,
    setSubmitted,
  };
};

export default useExperienceRequestCreate;
