import DataCheck from "@components/common/DataCheck";
import useUserIsOAuth from "@hooks/session/useUserIsOAuth";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs from "dayjs";
import React from "react";
import About from "./sections/About";
import DangerZone from "./sections/DangerZone";
import Password from "./sections/Password";

export default function PersonalTab() {
  const { userExtension, initialLoading } = useGlobalContext();

  const userIsOAuth = useUserIsOAuth();

  return (
    <div className="sections-wrapper personal">
      <div className="sections full-width">
        <DataCheck isLoading={initialLoading}>
          <About
            initialValues={{
              firstName: userExtension?.firstName,
              surname: userExtension?.lastName,
              displayName: userExtension?.displayName,
              dob: dayjs(userExtension?.birthdate ?? undefined),
            }}
          />
        </DataCheck>
      </div>
      <div className="sections full-width">
        {!userIsOAuth && <Password />}
        <DangerZone />
      </div>
    </div>
  );
}
