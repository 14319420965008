import IconButton from "@components/form/IconButton";
import Fade from "@components/transitions/Fade";
import Slide from "@components/transitions/Slide";
import usePrevious from "@hooks/usePrevious";
import { classNames } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, {
  cloneElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { IoClose, IoRemove } from "react-icons/io5";
import { useLocation } from "react-router-dom";

interface Props {
  closed?: boolean;
  icon?: ReactElement;
  actions?: ReactNode;
  noCloseButton?: boolean;
  position?: "top" | "bottom";
  pagePersistent?: boolean;

  onClose?: () => void;
}

export default function StickyBar(props: PropsWithChildren<Props>) {
  const {
    closed,
    icon,
    actions,
    noCloseButton,
    position = "bottom",
    pagePersistent,

    onClose,

    children,
  } = props;

  const { breakpoints } = useGlobalContext();

  const location = useLocation();

  const [active, setActive] = useState(true);
  const [minimized, setMinimized] = useState(false);

  const prevLocation = usePrevious(location);

  useLayoutEffect(() => {
    if (!prevLocation?.pathname || location.pathname === prevLocation?.pathname) return;
    if (!pagePersistent) setActive(false);
  }, [location.pathname, prevLocation?.pathname, pagePersistent]);

  const closeButton = (
    <IconButton
      size={
        breakpoints.sm
          ? "small"
          : breakpoints.md
          ? "medium"
          : !breakpoints.sl
          ? "extra-large"
          : "large"
      }
      color="black-4"
      iconColor="white"
      icon={<IoClose />}
      onClick={() => setActive(false)}
      className="close-button"
    />
  );

  const iconNode = icon
    ? cloneElement(icon, {
        className: "sticky-bar-icon",
      })
    : breakpoints.sm && <div />;

  const contentNode = <div className="content">{children}</div>;

  const actionsNode = (
    <div className="actions">
      {actions}
      {!breakpoints.sm && <>{!noCloseButton && closeButton}</>}
    </div>
  );

  useEffect(() => setActive(!closed), [closed]);

  return (
    <Slide
      in={active && !minimized}
      onExited={!minimized ? onClose : undefined}
      direction={position === "top" ? "down" : "up"}
      appear
      properties="transform, opacity, width"
      mountOnEnter={false}
      unmountOnExit={false}>
      <div
        className={classNames("sticky-bar", position, minimized && "minimized")}
        onClick={() => {
          if (minimized) setMinimized(false);
        }}>
        <Fade in={!minimized}>
          <IconButton
            variant="flat"
            size={breakpoints.md ? "extra-small" : "small"}
            color="white"
            iconColor="white"
            rounded
            icon={<IoRemove />}
            className="minimize-button"
            onClick={() => setMinimized(minimized => !minimized)}
          />
        </Fade>

        {breakpoints.sm ? (
          <>
            {iconNode}
            {closeButton}

            {contentNode}
            {actionsNode}
          </>
        ) : (
          <>
            {iconNode}

            {contentNode}

            {actionsNode}
          </>
        )}
      </div>
    </Slide>
  );
}
