import Divider from "@components/common/Divider";
import Button from "@components/form/Button";
import CurrencyInput from "@components/form/CurrencyInput";
import Form from "@components/form/Form";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import useFormManager from "@components/form/hooks/useFormManager";
import Input from "@components/form/Input";
import dayjs from "dayjs";
import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import ActionPanel from "../../ActionPanel";
import { IoArrowForward, IoPencil } from "react-icons/io5";
import useUpdateSessionHandles from "./hooks/useUpdateSessionHandles";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createSuccessSnackbar, getCurrencySymbol } from "@lib/utils/generic";
import Checkbox from "@components/form/Checkbox";
import DialogueModal from "@components/modals/DialogueModal";
import useCancelCigAvailability from "@api/private/cancel/hooks/useCancelCigAvailability";
import { Currency } from "@lib/enums/generic";
import useGetCompatibility from "@api/public/get/hooks/useGetCompatibility";
import DataCheck from "@components/common/DataCheck";
import useScrollToBottom from "@hooks/useScrollToBottom";
import { Session } from "@lib/types/session";

interface Props {
  initialValues?: Session;
  active?: boolean;
  cancel?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  onCompletion?: () => void;
  onCancel?: (sessionId: string) => void;
  onNotCancelling?: () => void;
}

const initialValues = {
  currency: Currency.NotSet,
  sessionId: "",
  price: 0,
  free: false,
  title: "",
  message: "",
};

export type UpdateSessionValues = typeof initialValues;

const UpdateSession = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    initialValues: _initialValues,
    active,
    cancel = false,
    isLoading,
    onClose = () => {},
    onCompletion = () => {},
    onCancel = () => {},
    onNotCancelling = () => {},
  } = props;

  const {
    id,
    from,
    to,
    free,
    game,
    platform,
    slotsAvailable,
    maxSlots,
    originalPrice,
    originalCurrency,
    title,
    extraInfo,
    // exclusive,
    charity,
    charityName,
    private: privateSession,
  } = _initialValues ?? {};

  const hasStarted = dayjs(from).isBefore(dayjs());
  const isOver = dayjs(to).isBefore(dayjs());

  const [cancelModalActive, setCancelModalActive] = useState(cancel);

  const { pushSnackbar } = useGlobalContext();

  const { send: cancelAvailability, isLoading: cancelIsLoading } = useCancelCigAvailability(id);

  if (originalCurrency) initialValues.currency = originalCurrency;

  const formManager = useFormManager(
    {
      ...initialValues,
      sessionId: id ?? "",
      price: originalPrice ?? 0,
      free: free ?? false,
      title: title ?? "",
      message: extraInfo ?? "",
    },
    "updateSession",
  );

  const { values, validations, onChange, onBlur, validateCustom, resetValidations, setValues } =
    formManager;

  const [handle, updateIsLoading] = useUpdateSessionHandles(formManager);

  const {
    data: compatibility,
    error: compatibilityError,
    isLoading: compatibilityIsLoading,
  } = useGetCompatibility(platform?.id, game?.id, true);

  const compatiblePlatforms = compatibility?.compatiblePlatforms.items ?? [];

  useEffect(() => {
    setValues(values => ({
      ...values,
      sessionId: id ?? values.sessionId,
      title: title ?? "",
      price: originalPrice ?? values.price,
      message: extraInfo ?? values.message,
    }));
  }, [id, title, originalPrice, extraInfo, setValues]);

  useEffect(() => {
    if (cancel) setCancelModalActive(true);
  }, [cancel, setCancelModalActive]);

  const handleClose = useCallback(() => {
    onClose();
    resetValidations();
  }, [onClose, resetValidations]);

  const formRef = useRef<HTMLFormElement>(null);
  useScrollToBottom(formRef, !cancel);

  const children = (
    <>
      {hasStarted && (
        <>
          <FormRow>
            <FormEntry label="No longer editable">
              <p>
                {isOver ? "This session is from the past" : "This session is currently ongoing"}
              </p>
            </FormEntry>
          </FormRow>

          <Divider color="gray-3" />
        </>
      )}

      <FormRow className="dates-row">
        <FormEntry label="From" className="start-entry">
          <p>{from && dayjs(from).format("DD MMM YY HH:mm")}</p>
        </FormEntry>
        <IoArrowForward />
        <FormEntry label="To" className="end-entry">
          <p>{to && dayjs(to).format("DD MMM YY HH:mm")}</p>
        </FormEntry>
      </FormRow>

      <Divider color="gray-3" />

      <FormRow>
        <FormEntry label="Game">
          <p>{game?.longName}</p>
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry label="Gaming System">
          <p>{platform?.name}</p>
        </FormEntry>
      </FormRow>

      <FormRow className="platforms-row">
        <FormEntry label="Compatible Platforms">
          <ul
            className="platforms"
            style={{
              gridTemplateColumns: new Array(compatiblePlatforms.length).fill("1fr").join(" "),
            }}>
            <DataCheck
              isLoading={compatibilityIsLoading}
              error={compatibilityError}
              loadingIndicator="spinner">
              {compatiblePlatforms.map(({ platform: { shortName, icon } }, i) => (
                <li className="platform" key={i}>
                  <div
                    className="icon"
                    style={{ maskImage: `url(${icon})`, WebkitMaskImage: `url(${icon})` }}
                  />
                  <p className="name">{shortName}</p>
                </li>
              ))}
            </DataCheck>
          </ul>
        </FormEntry>
      </FormRow>

      <Divider color="gray-3" />

      <FormRow>
        <FormEntry
          label="Price / ticket"
          className="price-entry"
          validation={values.free ? undefined : validations.price}
          showValidationBeneath>
          {values.free || privateSession ? (
            <Input
              size="medium"
              variant="contained"
              color="black-4"
              textColor="white"
              value={privateSession ? "Private Session" : "Free Session"}
              disabled={true}
              style={{ textAlign: "center" }}
            />
          ) : (
            <CurrencyInput
              size="medium"
              variant="contained"
              color="black-4"
              textColor="white"
              name="price"
              placeholder="0.00"
              currency={getCurrencySymbol(originalCurrency)}
              value={values.price}
              onChange={onChange}
              onBlur={onBlur}
              disabled={updateIsLoading || cancelIsLoading || hasStarted}
            />
          )}
          {!values.free && <p className="footnote">(ex. sales tax)</p>}
        </FormEntry>
        <FormEntry label={privateSession ? "Tickets Sold" : "Tickets Left"} className="slots-entry">
          <p>{privateSession ? maxSlots : slotsAvailable}</p>
        </FormEntry>
      </FormRow>

      <Divider color="gray-3" />

      {/* <FormRow>
        <FormEntry>
          <Checkbox value={exclusive} label="Yakkr+ exclusive session" disabled />
        </FormEntry>
      </FormRow> */}

      <FormRow>
        <FormEntry>
          <Checkbox
            size="medium"
            variant="contained"
            color="black-4"
            textColor="white"
            checkmarkColor="gray-3"
            value={charity}
            label="Charity session"
            disabled
          />
        </FormEntry>
      </FormRow>

      {charity && charityName != null && (
        <FormRow>
          <FormEntry label="Charity name">
            <p>{charityName}</p>
          </FormEntry>
        </FormRow>
      )}

      <Divider color="gray-3" />

      <FormRow>
        <FormEntry label="Message" validation={validations.message} showValidationBeneath>
          <Input
            name="message"
            size="medium"
            variant="contained"
            color="black-4"
            textColor="white"
            multiline
            placeholder="Any extra details..."
            value={values.message}
            onChange={onChange}
            onBlur={onBlur}
            disabled={updateIsLoading || cancelIsLoading || hasStarted}
          />
        </FormEntry>
      </FormRow>

      <FormRow className="flex-grow">
        <FormEntry className="justify-end">
          <Button
            color="red"
            textColor="white"
            onClick={() => setCancelModalActive(true)}
            isLoading={cancelIsLoading}
            disabled={isOver}>
            Cancel Session
          </Button>
        </FormEntry>
      </FormRow>
    </>
  );

  return (
    <>
      <ActionPanel
        ref={ref}
        active={active}
        onClose={handleClose}
        isLoading={isLoading}
        className="update-session-panel"
        titleOverride={
          <FormRow>
            <FormEntry validation={validations.title} showValidationBeneath required>
              <Input
                variant="flat"
                color="white"
                textColor="gray-3"
                name="title"
                size="small"
                placeholder="Session Title"
                value={values.title}
                onChange={onChange}
                onBlur={onBlur}
                disabled={updateIsLoading}
                endIcon={<IoPencil />}
              />
            </FormEntry>
          </FormRow>
        }
        prompt="Click and drag to create a session"
        buttons={
          <>
            <Button
              color="white"
              onClick={handleClose}
              disabled={updateIsLoading || cancelIsLoading}>
              {hasStarted ? "Close" : "Cancel"}
            </Button>
            <Button
              color="purple"
              textColor="white"
              type="submit"
              form="update-session-form"
              isLoading={updateIsLoading}
              disabled={hasStarted}>
              Save
            </Button>
          </>
        }>
        <Form
          id="update-session-form"
          ref={formRef}
          steps={[
            {
              children,
              handle,
            },
          ]}
          resetValidations={resetValidations}
          validation={validations.generic}
          onCompletion={() => {
            onCompletion();
            onClose();
            pushSnackbar(createSuccessSnackbar("Session updated", 2500));
          }}
        />
      </ActionPanel>

      <DialogueModal
        subTitle="Are you sure you want to cancel this session? This cannot be undone."
        active={cancelModalActive}
        onConfirm={async () => {
          const { error } = await cancelAvailability();
          if (error) {
            validateCustom("generic", validator => {
              validator.list.push({
                text: error,
                type: "error",
              });
            });
          } else {
            setCancelModalActive(false);
            onClose();
            onCancel(values.sessionId);
            pushSnackbar(createSuccessSnackbar("Session cancelled", 2500));
          }
        }}
        onClose={() => {
          setCancelModalActive(false);
          onNotCancelling();
        }}
      />
    </>
  );
});

export default UpdateSession;
