import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const changePasswordValidators = {
  oldPassword: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(100);
  },
};

export default changePasswordValidators;
