import useForceRerender from "@hooks/useForceRerender";
import { getVideoInputDevices } from "@lib/utils/generic";
import { useEffect, useState } from "react";

const useVideoInputDevices = (): [MediaDeviceInfo[], () => void] => {
  const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([]);

  const [number, rerender] = useForceRerender();

  const get = async () => setVideoInputDevices(await getVideoInputDevices());

  useEffect(() => {
    get();
  }, [number]);

  return [videoInputDevices, rerender];
};

export default useVideoInputDevices;
