import {
  FormValidations,
  OnFormBlur,
  OnFormChange,
  OnFormChangeMultiple,
  ResetValidations,
} from "@components/form/hooks/useFormManager";
import { FormHandle } from "@lib/types/form";
import { createContext, PropsWithChildren, useState } from "react";
import useFlankSignUp, { FlankSignUpValues } from "../hooks/useFlankSignUp";

interface FlankSignUpContextType {
  values: FlankSignUpValues;
  validations: FormValidations<FlankSignUpValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;
  onChangeMultiple: OnFormChangeMultiple;

  handles: FormHandle[];
  resetValidations: ResetValidations;

  signUpIsLoading: boolean;

  step: number;
  setStep: (step: number) => void;

  onClose: () => void;
}

export const FlankSignUpContext = createContext<FlankSignUpContextType>(
  {} as FlankSignUpContextType,
);

interface Props {
  onClose: () => void;
}

export default function FlankSignUpContextProvider(props: PropsWithChildren<Props>) {
  const { onClose, children } = props;

  const [step, setStep] = useState(0);

  const {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles,
    handlesIsLoading,
    resetValidations,
  } = useFlankSignUp();

  const [signUpIsLoading] = handlesIsLoading;

  return (
    <FlankSignUpContext.Provider
      value={{
        values,
        validations,

        onChange,
        onBlur,
        onChangeMultiple,

        handles,
        resetValidations,

        signUpIsLoading,

        step,
        setStep,

        onClose,
      }}>
      {children}
    </FlankSignUpContext.Provider>
  );
}
