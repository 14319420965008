import { ButtonProps } from "@components/form/Button";
import { CheckboxProps } from "@components/form/Checkbox";
import {
  FormValidations,
  OnFormBlur,
  OnFormChange,
  OnFormChangeMultiple,
  ResetValidations,
} from "@components/form/hooks/useFormManager";
import { InputProps } from "@components/form/Input";
import { SelectProps } from "@components/form/Select";
import { SimpleDatePickerProps } from "@components/form/SimpleDatePicker";
import { RelativePosition } from "@hooks/useRelativeCoordsAndSize";
import { AuthState, CIG } from "@lib/enums/generic";
import { FormHandle } from "@lib/types/form";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import useCigRegister, { CigRegisterValues } from "../hooks/useCigRegister";
import useInitialStep from "../hooks/useInitialStep";

interface CigRegisterContextType {
  category: CIG;

  values: CigRegisterValues;
  validations: FormValidations<CigRegisterValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;
  onChangeMultiple: OnFormChangeMultiple;

  handles: FormHandle[];
  resetValidations: ResetValidations;

  registerIsLoading: boolean;
  emailConfirmIsLoading: boolean;
  userExtensionIsLoading: boolean;
  cigCreateIsLoading: boolean;

  initialStep: number;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;

  inputSize: InputProps["size"];
  checkboxSize: CheckboxProps["size"];
  buttonSize: ButtonProps["size"];
  datePickerSize: SimpleDatePickerProps["size"];
  selectSize: SelectProps["size"];
  validationPosition: RelativePosition;
}

export const CigRegisterContext = createContext<CigRegisterContextType>(
  {} as CigRegisterContextType,
);

export default function CigRegisterContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const { breakpoints, setAuthState } = useGlobalContext();

  // const category = useQuery<CIG>("cig") ?? CIG.Gamer; Legacy
  const category = CIG.Gamer;

  const [step, setStep] = useState(0);

  const {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles,
    handlesIsLoading,
    resetValidations,
  } = useCigRegister();

  const [registerIsLoading, emailConfirmIsLoading, userExtensionIsLoading, cigCreateIsLoading] =
    handlesIsLoading;

  const initialStep = useInitialStep();

  const inputSize = breakpoints.sm ? "medium" : "large";
  const checkboxSize = breakpoints.xs ? "small" : "medium";
  const buttonSize = breakpoints.sm ? "medium" : "large";
  const datePickerSize = breakpoints.sm ? "medium" : "large";
  const selectSize = breakpoints.sm ? "medium" : "large";

  const validationPosition = breakpoints.md ? "top" : "right";

  useEffect(() => {
    if (initialStep) setAuthState(AuthState.CigRegistering);
  }, [initialStep, setAuthState]);

  return (
    <CigRegisterContext.Provider
      value={{
        category,

        values,
        validations,

        onChange,
        onBlur,
        onChangeMultiple,

        handles,
        resetValidations,

        registerIsLoading,
        emailConfirmIsLoading,
        userExtensionIsLoading,
        cigCreateIsLoading,

        initialStep,
        step,
        setStep,

        inputSize,
        checkboxSize,
        buttonSize,
        selectSize,
        datePickerSize,

        validationPosition,
      }}>
      {children}
    </CigRegisterContext.Provider>
  );
}
