import { CIGProfile } from "@api/public/get/getCigProfile";
import { CurrentReqState } from "@hooks/useRequestState";
import { Session } from "@lib/types/session";
import { Dayjs } from "dayjs";
import { createContext, PropsWithChildren } from "react";
import useCalendarPeriod from "../hooks/useCalendarPeriod";
import useFullAvailability from "../hooks/useFullAvailability";

interface CalendarContextType {
  cigProfile?: CIGProfile;
  active?: boolean;

  availability: Session[];
  fullAvailability: Session[];
  startDate?: Dayjs;
  endDate?: Dayjs;
  error?: string;
  isLoading: boolean;

  periodDate: Dayjs;
  sessionId: string | null;
  session?: Session;

  setSessionId: (sessionId: string | null) => void;
  setPeriodDate: (periodDate: Dayjs | null) => void;

  goToNextPeriod: () => void;
  goToPrevPeriod: () => void;

  onClose: () => void;
  onUpdate: () => void;

  reSend: () => Promise<CurrentReqState<Session[]> | undefined>;
  handleSessionDelete: (sessionId: string) => void;
}

export const CalendarContext = createContext<CalendarContextType>({} as CalendarContextType);

interface Props {
  sessionId: string | null;
  periodDate: Dayjs;

  onSessionIdChange: (sessionId: string | null) => void;
  onPeriodDateChange: (periodDate: Dayjs | null) => void;

  cigProfile?: CIGProfile;
  active?: boolean;

  onClose?: () => void;
  onUpdate?: () => void;
}

export default function CalendarContextProvider(props: PropsWithChildren<Props>) {
  const {
    sessionId,
    periodDate,

    onSessionIdChange,
    onPeriodDateChange,

    cigProfile,
    active,
    children,

    onClose = () => {},
    onUpdate = () => {},
  } = props;

  const { goToNextPeriod, goToPrevPeriod } = useCalendarPeriod(
    periodDate,

    onSessionIdChange,
    onPeriodDateChange,
  );
  const {
    startDate,
    endDate,
    availability,
    fullAvailability,
    error,
    isLoading,
    reSend,
    handleSessionDelete,
  } = useFullAvailability(cigProfile?.id, periodDate);

  const session = fullAvailability.find(({ id }) => id === sessionId);

  return (
    <CalendarContext.Provider
      value={{
        cigProfile,
        active,

        periodDate,
        session,
        sessionId,

        setSessionId: onSessionIdChange,
        setPeriodDate: onPeriodDateChange,

        startDate,
        endDate,
        availability,
        fullAvailability,
        error,
        isLoading,

        goToNextPeriod,
        goToPrevPeriod,

        onClose,
        onUpdate,

        reSend,
        handleSessionDelete,
      }}>
      {children}
    </CalendarContext.Provider>
  );
}
