import Avatar from "@components/common/Avatar";
import Button from "@components/form/Button";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { pluralise } from "@lib/utils/generic";
import dayjs from "dayjs";
import React from "react";
import { IoReload } from "react-icons/io5";
import useRoomContext from "../roomContext/hooks/useRoomContext";
import useTimeLeft from "../hooks/useTimeLeft";
import RoomOverlay from "./RoomOverlay";

export default function PostRoomOverlay() {
  const { breakpoints } = useGlobalContext();
  const { session, type, bookingId } = useRoomContext();

  const { cig, from, to } = session ?? {};
  const isCig = type === "cig";

  const { hours, minutes, seconds, timeLeft } = useTimeLeft(dayjs(to));
  const { hoursPadded, minutesPadded, secondsPadded } = useTimeLeft(dayjs(to), dayjs(from));

  const buttonSize = breakpoints.sm ? "medium" : "large";

  return (
    <RoomOverlay
      title={timeLeft > 0 ? "Leaving so soon?" : "Thanks for streaming with Yakkr"}
      buttons={
        <>
          {timeLeft > 0 ? (
            <Button
              size={buttonSize}
              variant="contained"
              color="white"
              endIcon={<IoReload />}
              onClick={() => window.location.reload()}>
              Rejoin
            </Button>
          ) : (
            <Button size={buttonSize} color="white" to={`/@${cig?.displayName}`}>
              {isCig ? "View Profile" : `${cig?.displayName}'s Profile`}
            </Button>
          )}
          <Button
            size={buttonSize}
            variant="outlined"
            color="white"
            textColor="white"
            to={isCig ? "/" : `/bookings/room/${session?.id}/post?bookingId=${bookingId}`}>
            Leave
          </Button>
        </>
      }>
      <div className="post-room-overlay">
        {/* <section className="rating-section">
          <h2 className="sub-title">
            How did you enjoy your session with <span className="text-purple">##Username##?</span>
          </h2>
          <Input color="black-4" variant="flat" multiline placeholder="Message" />
        </section>
        <Divider orientation="vertical" color="white" />
        <section className="tip-section">
          <h2 className="sub-title">Leave a tip?</h2>
        </section> */}

        <Avatar
          src={cig?.profilePictureURL}
          alt={cig?.displayName}
          size={breakpoints.sm ? 192 : 256}
        />
        <p className="text">
          {timeLeft > 0 ? (
            <>
              The session isn't due to end for another
              <br />
              {hours} {pluralise("hr", hours)} {minutes} {pluralise("min", minutes)} {seconds}{" "}
              {pluralise("sec", seconds)}
            </>
          ) : (
            <>
              Session Duration
              <br />
              {hoursPadded}:{minutesPadded}:{secondsPadded}
            </>
          )}
        </p>
      </div>
    </RoomOverlay>
  );
}
