import { api } from "@api/api";

export interface CustomerJoinSessionRes {
  videoRoom1Authorisation: string;
  videoRoom2Authorisation: string;
  videoRoom3Authorisation: string;
  videoRoom4Authorisation: string;
  videoRoom5Authorisation: string;
  chatSID: string;
}

const customerJoinSession = async (ticketID: string) => {
  const { data } = await api.post<CustomerJoinSessionRes>("/customerJoinSession", {
    ticketID,
  });
  return data;
};

export default customerJoinSession;
