import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createReport, { CreateReportRes } from "../createReport";

const useCreateReport = (targetUserID?: string, reason?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CreateReportRes | null>(
    null,
  );

  const send = useCallback(async () => {
    if (targetUserID == null || reason == null) return reqState;
    setLoading();
    try {
      const data = await createReport(targetUserID, reason);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [targetUserID, reason, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateReport;
