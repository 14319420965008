import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import setExperienceRequestState from "../setExperienceRequestState";

const useSetExperienceRequestState = (requestId?: string, accept?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState(null);

  const send = useCallback(
    async (requestIdOverride?: string) => {
      const _requestId = requestIdOverride ?? requestId;
      if (_requestId == null || accept == null) return reqState;
      setLoading();
      try {
        await setExperienceRequestState(_requestId, accept);
        return setSuccess(null);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [requestId, accept, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useSetExperienceRequestState;
