import { api } from "@api/api";
import { CigFlowState } from "@lib/enums/generic";

export interface UpdateCigFlowStateRes {}

const updateCigFlowState = async (state: CigFlowState) => {
  const { data } = await api.post<UpdateCigFlowStateRes>("/setCIGFlowState", {
    state,
  });
  return data;
};

export default updateCigFlowState;
