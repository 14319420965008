import experienceImg from "@images/becomeTalent/splitViews/experience.jpg";
import earnImg from "@images/becomeTalent/splitViews/earn.jpg";
import communityImg from "@images/becomeTalent/splitViews/community.jpg";
import incomeImg from "@images/becomeTalent/splitViews/income.jpg";
import CtaButton from "@pages/becomeTalentOld/CtaButton";
import SplitViewsSplash from "@components/splash/sections/SplitViewsSplash";
import SplashText from "@components/splash/SplashText";

const SplitViews = () => {
  return (
    <SplitViewsSplash
      views={[
        {
          title: "Give them the best experience they could ask for!",
          content: (
            <SplashText>
              It's any fans dream to meet their favourite streamer. Half the reason they donate is
              to be noticed by you. So why not give them the experience of a lifetime and game with
              them?
              <br />
              <br />
              It really is an easy choice. Your fans want to play. You both love gaming and you can
              earn a consistent income. Everyone's a winner.
            </SplashText>
          ),
          img: experienceImg,
          imgAlt: "Experience",
          buttons: <CtaButton>Make your fan's year!</CtaButton>,
        },
        {
          title: "How much can I earn?",
          content: (
            <SplashText>
              That's the best bit, you have full control over your rates. You can set your prices
              and change them at any time.
              <br />
              <br />
              Plus, there's plenty of opportunity to make more through tips, time extensions, and
              shoutouts.
            </SplashText>
          ),
          img: earnImg,
          imgAlt: "Earn",
          reverse: true,
        },
        {
          title: "Supercharge your income!",
          content: (
            <SplashText>
              Yakkr allows you to play games with multiple fans at the same time - substantially
              increasing your potential earnings.
              <br />
              <br />
              Imagine getting paid for every fan that joins your League of Legends match, Warzone
              tournament, Apex lobby etc.
              <br />
              <br />
              With our service, you can make a lot of money in just one hour of gaming. Don't miss
              this opportunity to boost your income whilst engaging with your community.
            </SplashText>
          ),
          img: incomeImg,
          imgAlt: "Income",
          buttons: <CtaButton>Join now</CtaButton>,
          centeredTitle: true,
        },
        {
          title: "Grow your community",
          content: (
            <SplashText>
              Being a streamer who cares about your community and even games with them is bound to
              attract new audiences.
              <br />
              <br />
              On Yakkr you can host events with your fans, collab with other streamers, and create
              truly engaging content.
              <br />
              <br />
              It's a sure-fire way to grow quickly online with a loyal and loving fanbase!
            </SplashText>
          ),
          img: communityImg,
          imgAlt: "Community",
          reverse: true,
        },
      ]}
    />
  );
};

export default SplitViews;
