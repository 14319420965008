import { ReactNode } from "react";

interface Props {
  text?: ReactNode;
  buttons?: ReactNode;
}

export default function FormBar(props: Props) {
  const { text, buttons } = props;

  return (
    <div className="form-bar">
      <h5 className="form-bar-title">
        <b>{text}</b>
      </h5>
      <div className="buttons">{buttons}</div>
    </div>
  );
}
