import { getMsgFromApiErr } from "@api/api";
import getCompatibility, { Compatibility } from "@api/public/get/getCompatibility";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";

const useGetCompatibility = (hostPlatformID?: string, gameID?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<Compatibility | null>(
    null,
  );

  const send = useCallback(async () => {
    if (hostPlatformID == null || gameID == null) return;
    setLoading();
    try {
      const compatibility = await getCompatibility(hostPlatformID, gameID);
      setSuccess(compatibility);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [hostPlatformID, gameID, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [hostPlatformID, gameID, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetCompatibility;
