import Accordion from "@components/common/Accordion";
import RelativePortal from "@components/common/RelativePortal";
import useStorageItem from "@hooks/sessionStore/useStorageItem";
import useFocussedWithin from "@hooks/useFocussedWithin";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs from "dayjs";
import { cloneElement, Fragment, isValidElement, PropsWithChildren, useRef, useState } from "react";

interface Props {}

const Changelog = (props: PropsWithChildren<Props>) => {
  const { children } = props;

  const { descendingChangelog, breakpoints } = useGlobalContext();

  const ref = useRef<HTMLDivElement>(null);
  const accordionsRef = useRef<HTMLDivElement>(null);

  const lastVersionItem = useStorageItem<string>(
    "last-version-seen",
    descendingChangelog[0].version,
  );
  const [focussed, setFocussed] = useFocussedWithin(ref);
  const [versionToOpen, setVersionToOpen] = useState<string | null>(null);

  const handleClick = () => {
    if (!focussed) {
      const lastVersionIndex = descendingChangelog.findIndex(
        ({ version }) => version === lastVersionItem.item.data,
      );
      const nextVersion = descendingChangelog[lastVersionIndex - 1] ?? descendingChangelog[0];
      setVersionToOpen(nextVersion.version);
      lastVersionItem.set(descendingChangelog[0].version);
    }

    setFocussed(focussed => !focussed);
  };

  return (
    <RelativePortal
      relativePosition="right"
      active={focussed}
      fullWidth={breakpoints.sm}
      className="changelog-relative-portal"
      onEntered={() => {
        const versionToOpenIndex = descendingChangelog.findIndex(
          ({ version }) => version === versionToOpen,
        );

        const accordions = accordionsRef.current?.childNodes;

        const accordion = accordions?.item(versionToOpenIndex) as HTMLDivElement | undefined;
        const accordionButton = accordion?.firstElementChild as HTMLButtonElement | undefined;

        accordionButton?.click();
      }}
      content={
        <div ref={ref} className="changelog">
          <div className="changelog-inner" tabIndex={0}>
            <div className="changelog-header">
              <h6 className="title">Changelog</h6>
            </div>

            <div className="changelog-list-wrapper">
              <div className="changelog-list" ref={accordionsRef}>
                {descendingChangelog.map(({ version, date, changes }, i) => {
                  const isNew =
                    versionToOpen &&
                    parseInt(versionToOpen.replace(/\./g, "")) <=
                      parseInt(version.replace(/\./g, ""));

                  return (
                    <Accordion
                      key={version}
                      title={`${version} - ${dayjs(date).format("DD MMM YYYY")}${
                        isNew ? (i === 0 ? " ( Latest )" : " ( New )") : ""
                      }`}
                      size="small"
                      color={isNew ? "gray-1" : "black-4"}>
                      <ol>
                        {changes.map((change, i) => (
                          <Fragment key={i}>
                            <li key={i}>{change}</li>
                            {i !== changes.length - 1 && <br />}
                          </Fragment>
                        ))}
                      </ol>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      }>
      {isValidElement(children) &&
        cloneElement(children as any, {
          onClick: handleClick,
        })}
    </RelativePortal>
  );
};

export default Changelog;
