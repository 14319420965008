import IoCalendarGradient from "@components/icons/IoCalendarGradient";
import IoCardGradient from "@components/icons/IoCardGradient";
import IoSparklesGradient from "@components/icons/IoSparklesGradient";
import CigProfileSubTitle from "../CigProfileSubTitle";
import CigProfileTitle from "../CigProfileTitle";

const HowItWorks = () => {
  return (
    <section className="section how-it-works" id="how-it-works">
      <div className="box">
        <CigProfileTitle small>
          How <span className="gradient">Yakkr</span> works
        </CigProfileTitle>
        <CigProfileSubTitle>Learn how to play with your favorites</CigProfileSubTitle>

        <div className="bars">
          <div className="bar">
            <IoCardGradient gradient="gradient-faded-horizontal" />

            <div className="content">
              <h3 className="bar-title">Book a session or bundle with your favourite streamer</h3>
              <p className="bar-text">Book a session or bundle with your favourite streamer</p>
            </div>
          </div>

          <div className="bar">
            <IoCalendarGradient gradient="gradient-faded-horizontal" />

            <div className="content">
              <h3 className="bar-title">Pick your time or date</h3>
              <p className="bar-text">Book a session or bundle with your favourite streamer</p>
            </div>
          </div>

          <div className="bar">
            <IoSparklesGradient gradient="gradient-faded-horizontal" />

            <div className="content">
              <h3 className="bar-title">Join the room and play</h3>
              <p className="bar-text">Book a session or bundle with your favourite streamer</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
