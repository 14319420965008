import { RequestAvailableTime } from "@api/private/update/updateExperienceCustom";
import { GamePlatformSelection } from "@api/private/update/updateExperiencePackage";
import useFormManager from "@components/form/hooks/useFormManager";
import { getClientTimezone } from "@lib/utils/generic";
import useUpdateExperienceCustomHandle from "./useUpdateExperienceCustomHandle";

const initialValues = {
  timezoneName: getClientTimezone(),
  customerOffersPrice: false,
  pricePerPeriod: 0,
  streamOptionEnabled: false,
  streamPricePerPeriod: 0,
  gamePlatformSelections: [] as GamePlatformSelection[],
  requestAvailableTimes: [] as RequestAvailableTime[],
  anyGame: false,
  anyTime: false,
};

export type UpdateExperienceCustomValues = typeof initialValues;

const useExperienceCustomUpdate = () => {
  const formManager = useFormManager(initialValues, "updateExperienceCustom");

  const { values, validations, onChange, onBlur, resetValidations, clearValidation, setValues } =
    formManager;

  const [handle, updateIsLoading] = useUpdateExperienceCustomHandle(formManager);

  return {
    values,
    validations,
    onChange,
    onBlur,

    updateIsLoading,

    update: handle,
    resetValidations,
    clearValidation,
    setValues,
  };
};

export default useExperienceCustomUpdate;
