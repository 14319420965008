import AuthWrapper from "../AuthWrapper";
import useForgotPasswordContext from "./context/useForgotPasswordContext";
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPasswordInner = () => {
  const { forgotPasswordSubmitIsLoading, forgotPasswordConfirmIsloading } =
    useForgotPasswordContext();

  return (
    <AuthWrapper
      title="Password Recovery"
      isLoading={forgotPasswordSubmitIsLoading || forgotPasswordConfirmIsloading}
      noAltAuth>
      <ForgotPasswordForm />
    </AuthWrapper>
  );
};

export default ForgotPasswordInner;
