import { useEffect } from "react";

const useOnPageClose = (callback = () => {}) => {
  useEffect(() => {
    window.addEventListener("beforeunload", callback);
    return () => window.removeEventListener("beforeunload", callback);
  }, [callback]);
};

export default useOnPageClose;
