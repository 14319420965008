import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { ConsultancySignUpValues } from "./useConsultancySignUp";
import useRegisterConsultancyInterest from "@api/public/misc/hooks/useRegisterConsultancyInterest";

const useConsultancySignUpHandles: UseFormHandles<ConsultancySignUpValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { isLoading: signUpIsLoading, send: registerConsultancyInterest } =
    useRegisterConsultancyInterest(values.email, values.twitchUsername, values.package);

  const signUpHandle = async () => {
    let failed = validateFields(["email", "twitchUsername", "package", "terms"]);

    if (!failed) {
      const { error } = await registerConsultancyInterest();

      if (error) {
        failed = true;

        validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    return failed;
  };

  return [signUpHandle, signUpIsLoading];
};

export default useConsultancySignUpHandles;
