import useUpdateAvailability from "@api/private/update/hooks/useUpdateAvailability";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { UpdateSessionValues } from "../UpdateSession";

const useUpdateSessionHandles: UseFormHandles<UpdateSessionValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { send: updateAvailability, isLoading } = useUpdateAvailability(
    values.sessionId,
    values.title,
    values.message,
    values.price,
  );

  const handle = async () => {
    let failed = validateFields(["title", "message", ...(values.free ? [] : ["price" as "price"])]);

    if (!failed) {
      const { error } = await updateAvailability();

      if (error) {
        failed = validateCustom("generic", validator => {
          validator.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    return failed;
  };

  return [handle, isLoading];
};

export default useUpdateSessionHandles;
