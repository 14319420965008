import Checkbox from "@components/form/Checkbox";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import { Link } from "react-router-dom";
import Button from "@components/form/Button";
import useCigLoginContext from "../context/useLoginContext";
import { IoArrowForward } from "react-icons/io5";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import { LoginModalData } from "../context/LoginContextProvider";

export default function LoginStep() {
  const { setAuthModal } = useGlobalContext();

  const { values, validations, onChange, onBlur, loginIsLoading } = useCigLoginContext();

  const { redirect, email } = useAuthModalData<LoginModalData>();

  return (
    <>
      <FormRow>
        <FormEntry label="EMAIL" validation={validations.email} showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            placeholder="example@email.com"
            type="email"
            name="email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
            disabled={loginIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label={
            <div className="flex-row justify-between">
              PASSWORD
              <Link to="#" onClick={() => setAuthModal("forgot-password", { email, redirect })}>
                Forgot password?
              </Link>
            </div>
          }
          validation={validations.password}
          showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            placeholder="Password"
            type="password"
            name="password"
            value={values.password}
            onChange={onChange}
            onBlur={onBlur}
            disabled={loginIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry validation={validations.staySignedIn} showValidationBeneath>
          <Checkbox
            size="medium"
            color="black-4"
            textColor="purple-5"
            checkmarkColor="gray-3"
            name="staySignedIn"
            label={<span>Stay signed in</span>}
            value={values.staySignedIn}
            onChange={onChange}
            onBlur={onBlur}
            disabled={loginIsLoading}
          />
        </FormEntry>
      </FormRow>

      <div className="auth-button-row">
        <Button
          color="purple"
          textColor="white"
          size="large"
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={loginIsLoading}>
          Sign in
        </Button>
      </div>

      <p className="bottom-text">
        Don't have an account?{" "}
        <span onClick={() => setAuthModal("register", { redirect, email })}>Sign up</span>
      </p>
    </>
  );
}
