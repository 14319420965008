import { api } from "@api/api";

const checkTwitchUsername = async (twitchUsername: boolean) => {
  const { data } = await api.get<boolean>("/checkTwitchAccountExists", {
    params: { twitchUsername },
  });
  return data;
};

export default checkTwitchUsername;
