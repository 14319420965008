import Collapse from "@components/transitions/Collapse";
import useElementSize from "@hooks/useElementSize";
import { CSSProps, UIColor } from "@lib/types/generic";
import { useRef } from "react";

interface Props extends CSSProps {
  active?: boolean;
  color?: UIColor;
  backColor?: UIColor;
  variant?: "linear" | "percent";
  percent?: number;
}

export type BarProgressProps = Props;

export default function BarProgress(props: Props) {
  const {
    active = true,
    color = "black",
    backColor = "white",
    variant = "linear",
    percent = 0,
    className = "",
    id,
    style,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const size = useElementSize(ref);
  const width = (size.width / 100) * percent;

  return (
    <Collapse in={active} appear unmountOnExit={false} mountOnEnter={true} ref={ref}>
      <div
        className={`bar-progress ${variant} ${color} back-${backColor} ${className}`}
        id={id}
        style={style}>
        <div className="inner" style={variant === "percent" ? { width } : undefined} />
      </div>
    </Collapse>
  );
}
