import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useEffect, useState } from "react";

const useUserReFetch = () => {
  const { getUser } = useGlobalContext();

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (fetched) return;
    setFetched(true);
    getUser(true);
  }, [fetched, getUser]);
};

export default useUserReFetch;
