import Checkbox from "@components/form/Checkbox";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import { privacyUrl, termsUrl } from "@lib/constants/generic";
import Button from "@components/form/Button";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useRegisterContext from "../context/useRegisterContext";
import { IoArrowForward } from "react-icons/io5";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import { RegisterModalData } from "../context/RegisterContextProvider";

export default function RegisterStep() {
  const { setAuthModal } = useGlobalContext();

  const { redirect, email } = useAuthModalData<RegisterModalData>();

  const {
    values,
    validations,
    onChange,
    onBlur,

    registerIsLoading,
  } = useRegisterContext();

  return (
    <>
      <FormRow>
        <FormEntry label="EMAIL" validation={validations.email} showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            name="email"
            placeholder="example@email.com"
            type="email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
            disabled={registerIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry label="PASSWORD" validation={validations.password} showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            name="password"
            placeholder="Password"
            type="password"
            value={values.password}
            onChange={onChange}
            onBlur={onBlur}
            disabled={registerIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label="CONFIRM PASSWORD"
          validation={validations.confirmPassword}
          showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            name="confirmPassword"
            placeholder="Password"
            type="password"
            value={values.confirmPassword}
            onChange={onChange}
            onBlur={onBlur}
            disabled={registerIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry validation={validations.terms} showValidationBeneath>
          <Checkbox
            size="medium"
            color="black-4"
            textColor="purple-5"
            checkmarkColor="gray-3"
            name="terms"
            label={
              <span>
                I agree to the Yakkr{" "}
                <a target="_blank" rel="noreferrer" href={privacyUrl} className="text-underline">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a target="_blank" rel="noreferrer" href={termsUrl} className="text-underline">
                  Terms of Service
                </a>
                .
              </span>
            }
            value={values.terms}
            onChange={onChange}
            onBlur={onBlur}
            disabled={registerIsLoading}
          />
        </FormEntry>
      </FormRow>

      <div className="auth-button-row">
        <Button
          color="purple"
          textColor="white"
          size="large"
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={registerIsLoading}>
          Sign up
        </Button>
      </div>

      <p className="bottom-text">
        Already have an account?{" "}
        <span onClick={() => setAuthModal("login", { redirect, email })}>Sign in</span>
      </p>
    </>
  );
}
