import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const createInteractiveQuestionAnswerValidators = {
  question: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").exists();
  },
  answer: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").exists();
  },
};

export default createInteractiveQuestionAnswerValidators;
