import {
  ChangeEvent,
  cloneElement,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CSSProps } from "../../lib/types/generic";
import { FormElement } from "./Form";
import mime from "mime-types";
import { IoDocument } from "react-icons/io5";
import { classNames } from "@lib/utils/generic";
import DataCheck from "@components/common/DataCheck";

interface Props extends CSSProps {
  name?: string;
  placeholder?: string;
  whitelistedFileTypes?: string[];
  maxBytes?: number;
  ctaIcon?: ReactElement;
  ctaText?: string;
  initialSrc?: string;
  value?: File;
  file: File | null;
  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
  onFocus?: (target: FormElement) => void;
  onFileChange?: (file: File | null) => void;
  disabled?: boolean;
  readOnly?: boolean;
  isLoading?: boolean;
}

export type FilePickerProps = Props;

const FilePicker = forwardRef<HTMLInputElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    placeholder = "Click to upload",
    whitelistedFileTypes = [],
    maxBytes,
    name = "",
    ctaIcon = <IoDocument />,
    ctaText = "Click to change",
    initialSrc,
    value,
    file,
    onChange = () => {},
    onBlur = () => {},
    onFileChange = () => {},
    disabled,
    readOnly,
    isLoading,
    children,

    style,
    className = "",
    id,
  } = props;

  const mimeTypes = whitelistedFileTypes.reduce((acc, fileType) => {
    const mimeType = mime.lookup(fileType);
    return mimeType ? [...acc, mimeType] : acc;
  }, [] as string[]);
  const fileTypes = mimeTypes.map(mimeType => mime.extension(mimeType));

  const [error, setError] = useState<string | null>(null);

  const handleBoth =
    (onFunc: (target: FormElement) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      setError(null);
      const [file] = event.target.files ?? [];
      if (file == null) return;
      onFileChange(null);
      const lookedUpMime = mime.lookup(file.type);
      if (lookedUpMime && !mimeTypes.includes(lookedUpMime))
        return setError("File type not allowed");
      if (maxBytes && file.size > maxBytes) return setError("File size too large");

      onFileChange(file);
      onFunc({ type: "file", value: URL.createObjectURL(file), name });
    };

  useEffect(() => {
    if (value != null) onFileChange(value);
  }, [value, onFileChange]);

  const previewSrc = useMemo(
    () => (file != null ? URL.createObjectURL(file) : initialSrc),
    [file, initialSrc],
  );

  const fileTypesString = fileTypes.join(", ");
  const mimeTypesString = mimeTypes.join(", ");
  const accept = [fileTypesString, mimeTypesString].join(", ");

  if (children != null && disabled) {
    return <>{children}</>;
  }

  const ctaNodes = (
    <div className="file-picker-cta">
      {cloneElement(ctaIcon, {
        ...ctaIcon.props,
        className: classNames(ctaIcon.props.className, "file-picker-cta-icon"),
      })}
      {!isLoading && <p className="file-picker-cta-text">{ctaText}</p>}
    </div>
  );

  const documentIconNodes = (
    <>{file != null && !file.type.includes("image") && <IoDocument className="document-icon" />}</>
  );

  const placeholderChildren = (
    <>{previewSrc == null && error == null && <p className="placeholder">{placeholder}</p>}</>
  );

  const errorChildren = <>{error != null && <p className="placeholder error">{error}</p>}</>;

  if (children != null) {
    return (
      <label className={classNames("file-picker", "hidden", isLoading && "is-loading", className)}>
        {children}

        <DataCheck isLoading={isLoading} loadingIndicator="gloss">
          <input
            className="file-picker-input"
            ref={ref}
            type="file"
            disabled={disabled}
            readOnly={readOnly}
            onChange={handleBoth(onChange)}
            onBlur={handleBoth(onBlur)}
            accept={accept}
          />

          {errorChildren}

          <div className="cover" />

          {documentIconNodes}
          {ctaNodes}
        </DataCheck>
      </label>
    );
  }

  return (
    <div
      className={classNames("file-picker", "standard", isLoading && "is-loading", className)}
      id={id}
      style={style}>
      <DataCheck isLoading={isLoading} loadingIndicator="gloss">
        <label className="preview-wrapper" style={{ backgroundImage: `url(${previewSrc})` }}>
          {previewSrc && (
            <div className="top-bar">
              <p className="file-name">{file != null ? file.name : placeholder}</p>
            </div>
          )}

          {placeholderChildren}
          {errorChildren}

          <div className="cover" />

          {documentIconNodes}
          {ctaNodes}

          <input
            ref={ref}
            type="file"
            disabled={disabled}
            readOnly={readOnly}
            onChange={handleBoth(onChange)}
            onBlur={handleBoth(onBlur)}
            accept={accept}
            className="file-picker-input"
          />
        </label>
      </DataCheck>
    </div>
  );
});

export default FilePicker;
