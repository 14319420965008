import { ClaimReason } from "@lib/enums/generic";
import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const supportTicketValidators = {
  reasonClass: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string").enum.within(ClaimReason),
  bookingID: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string"),
  reason: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(512),
};

export default supportTicketValidators;
