import {
  FormValidations,
  OnFormBlur,
  OnFormChange,
  OnFormChangeMultiple,
  ResetValidations,
} from "@components/form/hooks/useFormManager";
import { AuthState } from "@lib/enums/generic";
import { FormHandle } from "@lib/types/form";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, { createContext, PropsWithChildren, useEffect, useState } from "react";
import useInitialStep from "../hooks/useInitialStep";
import useRegister, { RegisterValues } from "../hooks/useRegister";

export interface RegisterModalData {
  redirect?: string;
  email?: string;
}

interface RegisterContextType {
  values: RegisterValues;
  validations: FormValidations<RegisterValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;
  onChangeMultiple: OnFormChangeMultiple;

  handles: FormHandle[];
  resetValidations: ResetValidations;

  registerIsLoading: boolean;
  emailConfirmIsLoading: boolean;
  userExtensionIsLoading: boolean;

  initialStep: number;
  step: number;
  setStep: (step: number) => void;
}

export const RegisterContext = createContext<RegisterContextType>({} as RegisterContextType);

interface Props {
  onStepChange: (step: number) => void;
}

export default function RegisterContextProvider(props: PropsWithChildren<Props>) {
  const { onStepChange, children } = props;

  const { setAuthState } = useGlobalContext();

  const [step, setStep] = useState(0);

  const {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles,
    handlesIsLoading,
    resetValidations,
  } = useRegister();

  const [registerIsLoading, emailConfirmIsLoading, userExtensionIsLoading] = handlesIsLoading;

  const initialStep = useInitialStep();

  useEffect(() => {
    if (initialStep) setAuthState(AuthState.CigRegistering);
  }, [initialStep, setAuthState]);

  return (
    <RegisterContext.Provider
      value={{
        values,
        validations,

        onChange,
        onBlur,
        onChangeMultiple,

        handles,
        resetValidations,

        registerIsLoading,
        emailConfirmIsLoading,
        userExtensionIsLoading,

        initialStep,
        step,
        setStep: step => {
          setStep(step);
          onStepChange(step);
        },
      }}>
      {children}
    </RegisterContext.Provider>
  );
}
