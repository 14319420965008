import useUserIsCig from "@hooks/session/useUserIsCig";
import useUserIsRegistered from "@hooks/session/useUserIsRegistered";
import useUserIsLoggedIn from "@hooks/session/useUserIsLoggedIn";
import useQuery from "@hooks/useQuery";

const useInitialStep = () => {
  const isCig = useUserIsCig();
  const isRegistered = useUserIsRegistered();
  const isLoggedIn = useUserIsLoggedIn();

  const email = useQuery("email");

  const initialStep = isCig ? 5 : isRegistered ? 3 : isLoggedIn ? 2 : email ? 1 : 0;

  return initialStep;
};

export default useInitialStep;
