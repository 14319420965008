import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import customerJoinSession, { CustomerJoinSessionRes } from "../customerJoinSession";

const useCustomerJoinSession = (ticketID?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CustomerJoinSessionRes | null>(null);

  const send = useCallback(async () => {
    if (ticketID == null) return reqState;
    setLoading();
    try {
      const data = await customerJoinSession(ticketID);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [ticketID, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [ticketID, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useCustomerJoinSession;
