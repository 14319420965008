import useLogin, { LoginError } from "@api/auth/hooks/useLogin";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { AuthState } from "@lib/enums/generic";
import { createWarnSnackbar } from "@lib/utils/generic";
import { UseFormHandles } from "../../../../form/hooks/useFormManager";
import { LoginValues } from "./useLogin";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import { RegisterModalData } from "../../register/context/RegisterContextProvider";

const useLoginHandles: UseFormHandles<LoginValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { pushSnackbar, setAuthState, setAuthModal } = useGlobalContext();

  const { email, redirect } = useAuthModalData<RegisterModalData>();

  const { send: login, isLoading: loginIsLoading } = useLogin(
    values.email,
    values.password,
    values.staySignedIn,
  );

  const handle = async () => {
    setAuthState(AuthState.CigLoggingIn);

    let failed = false;

    failed = validateFields(["email", "password", "staySignedIn"]);

    if (!failed) {
      const { error } = await login();

      if (error) {
        failed = true;

        switch (error.toString()) {
          case LoginError.EmailNotConfirmed.toString():
            setAuthModal("register", { email, redirect });
            pushSnackbar(createWarnSnackbar("You must confirm your email to continue", 3000));
            break;
          default:
            validateCustom("generic", validator => {
              validator.list.push({
                text: error,
                type: "error",
              });
            });
            break;
        }
      }
    }

    return failed;
  };

  return [handle, loginIsLoading];
};

export default useLoginHandles;
