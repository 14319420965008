import { Currency } from "@lib/enums/generic";
import { api } from "@api/api";
import { Compatibility } from "./getCompatibility";

export interface CigCustomExperience {
  anyGame: boolean;
  anyTime: boolean;

  currency: Currency;
  pricePerPeriodIncTax: number;
  pricePerPeriod: number;
  streamOptionEnabled: boolean;
  streamOptionPricePerPeriod: number;
  streamOptionPricePerPeriodIncTax: number;

  games: Compatibility[];
}

const getCigCustomExperience = async (cigID: string) => {
  const { data } = await api.get<CigCustomExperience | null>("/getCIGRequestSessionBasicDetails", {
    params: { cigID },
  });
  return data;
};

export default getCigCustomExperience;
