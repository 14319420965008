import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { Session } from "@lib/types/session";
import { useCallback, useEffect } from "react";
import getAvailabilityWithID from "../getAvailabilityWithID";

const useGetAvailabilityWithID = (availabilityID?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<Session | undefined>(
    undefined,
  );

  const send = useCallback(async () => {
    if (availabilityID == null) return reqState;
    setLoading();
    try {
      const booking = await getAvailabilityWithID(availabilityID);
      return setSuccess(booking);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [availabilityID, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [availabilityID, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetAvailabilityWithID;
