import { CigCustomExperience } from "@api/public/get/getCigCustomExperience";
import { CigPackageExperience } from "@api/public/get/getCigPackageExperiences";
import useGetCigCustomExperience from "@api/public/get/hooks/useGetCigCustomExperience";
import useGetCigPackageExperiences from "@api/public/get/hooks/useGetCigPackageExperiences";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, { createContext, PropsWithChildren } from "react";

interface SelectExperienceContextType {
  customExperience: CigCustomExperience | null;
  customExperienceIsLoading: boolean;
  customExperienceError?: string;

  packageExperiences: CigPackageExperience[];
  packageExperiencesIsLoading: boolean;
  packageExperiencesError?: string;
}

export const SelectExperienceContext = createContext<SelectExperienceContextType>(
  {} as SelectExperienceContextType,
);

export default function SelectExperienceContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const { cigProfile } = useGlobalContext();

  const {
    data: customExperience,
    isLoading: customExperienceIsLoading,
    error: customExperienceError,
  } = useGetCigCustomExperience(cigProfile?.id, true);
  const {
    data: packageExperiences,
    isLoading: packageExperiencesIsLoading,
    error: packageExperiencesError,
  } = useGetCigPackageExperiences(cigProfile?.id, true);

  return (
    <SelectExperienceContext.Provider
      value={{
        customExperience,
        customExperienceIsLoading,
        customExperienceError,

        packageExperiences,
        packageExperiencesIsLoading,
        packageExperiencesError,
      }}>
      {children}
    </SelectExperienceContext.Provider>
  );
}
