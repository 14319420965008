import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import UpdateExperiencePackageContext from "./context/UpdateExperiencePackageContext";
import UpdateExperiencePackageDesktop from "./UpdateExperiencePackageDesktop";
import UpdateExperiencePackageMobile from "./UpdateExperiencePackageMobile";

export default function UpdateExperiencePackage() {
  const { breakpoints } = useGlobalContext();

  return (
    <UpdateExperiencePackageContext>
      {breakpoints.sm ? <UpdateExperiencePackageMobile /> : <UpdateExperiencePackageDesktop />}
    </UpdateExperiencePackageContext>
  );
}
