import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { FormEventHandler, useCallback, useEffect, useState } from "react";
import { FormStage, FormStep } from "../../../lib/types/form";

const useFormState = (
  onStepChange: (step: number) => void = () => {},
  onCompletion: () => void = () => {},
  resetValidations: () => void = () => {},
  steps: FormStep[],
  timeout: number,
  initialStepIndex?: number,
  stepsLengthOverride?: number,
  resetOnCompletion?: boolean,
  triggerGlobalLoading?: boolean,
) => {
  const { setLoading } = useGlobalContext();

  const [stepIndex, setStepIndex] = useState(initialStepIndex ?? 0);
  const [stage, setStage] = useState(FormStage.Idle);
  const step = steps[stepIndex];
  const nextStep = steps[stepIndex + 1];

  const reset = () => {
    setStepIndex(0);
    setStage(FormStage.Idle);
  };

  const parseAndSubmit = useCallback<FormEventHandler>(
    async event => {
      event.preventDefault();
      event.stopPropagation();

      const submitter = (event.nativeEvent as SubmitEvent).submitter;
      const shouldSkip = !!submitter?.classList.contains("skip");

      resetValidations();

      if (stage === FormStage.PreHandle) return;

      setStage(FormStage.PreHandle);

      const failed = step.handle && !shouldSkip && (await step.handle());

      setStage(FormStage.PostHandle);

      if (failed) return;

      setStage(FormStage.Finished);

      const isFinalStep = stepIndex + 1 === steps.length;
      if (!isFinalStep) {
        setTimeout(() => {
          setStage(FormStage.Idle);

          setStepIndex(stepIndex + 1);
        }, timeout);
      }
      if (isFinalStep || stepIndex + 1 === stepsLengthOverride) {
        if (triggerGlobalLoading) setLoading(false);
        onCompletion();
        if (resetOnCompletion) reset();
      }
    },
    [
      setStage,
      setStepIndex,
      onCompletion,
      resetValidations,
      setLoading,
      triggerGlobalLoading,
      stepsLengthOverride,
      resetOnCompletion,
      steps.length,
      stage,
      stepIndex,
      step,
      timeout,
    ],
  );

  useEffect(() => {
    if (triggerGlobalLoading) setLoading(stage === FormStage.PreHandle);
  }, [setLoading, triggerGlobalLoading, stage]);

  useEffect(() => {
    if (stepIndex < steps.length) onStepChange(stepIndex);
    // Prevents infinite loop
    // eslint-disable-next-line
  }, [onStepChange, stepIndex, steps.length]);

  const children = step?.children;
  const nextChildren = nextStep?.children;

  return {
    stepIndex,
    stage,
    children,
    nextChildren,
    parseAndSubmit,
    reset,
  };
};

export default useFormState;
