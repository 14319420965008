import useLogin, { LoginError } from "@api/auth/hooks/useLogin";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { AuthState, CIG } from "@lib/enums/generic";
import { createWarnSnackbar } from "@lib/utils/generic";
import { useHistory } from "react-router-dom";
import { UseFormHandles } from "../../../../form/hooks/useFormManager";
import { CigLoginValues } from "./useCigLogin";

const useLoginHandles: UseFormHandles<CigLoginValues> = (
  { values, validateFields, validateCustom },
  category: CIG,
) => {
  const { pushSnackbar, setAuthState } = useGlobalContext();

  const history = useHistory();

  const { send: login, isLoading: loginIsLoading } = useLogin(
    values.email,
    values.password,
    values.staySignedIn,
  );

  const handle = async () => {
    setAuthState(AuthState.CigLoggingIn);

    let failed = false;

    failed = validateFields(["email", "password", "staySignedIn"]);

    if (!failed) {
      const { error } = await login();

      if (error) {
        failed = true;

        switch (error.toString()) {
          case LoginError.EmailNotConfirmed.toString():
            history.push(`/cig/register?email=${encodeURIComponent(values.email)}&cig=${category}`);
            pushSnackbar(createWarnSnackbar("You must confirm your email to continue", 3000));
            break;
          default:
            validateCustom("generic", validator => {
              validator.list.push({
                text: error,
                type: "error",
              });
            });
            break;
        }
      }
    }

    return failed;
  };

  return [handle, loginIsLoading];
};

export default useLoginHandles;
