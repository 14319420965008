import BackgroundImage from "@components/common/BackgroundImage";
import YakkrBanner from "@components/common/YakkrBanner";
import FeedbackForm from "./FeedbackForm";

import giftsSplash from "@images/misc/gifts_splash.png";
import useFeedbackContext from "./context/useFeedbackContext";
import DataCheck from "@components/common/DataCheck";
import { IoArrowForward, IoGift } from "react-icons/io5";
import Button from "@components/form/Button";

const FeedbackInner = () => {
  const { session, sessionIsLoading, step, state } = useFeedbackContext();

  const { cig } = session ?? {};

  const title = [
    <>
      Did you enjoy your <br />
      session with
      <br />
      <span>{cig?.displayName}</span>?
    </>,
    <>
      How much would you like to pay <span>{cig?.displayName}</span> for the session?
    </>,
    <>Would you like to leave a personalised message?</>,
  ][step];

  return (
    <div id="feedback-page">
      <BackgroundImage src={giftsSplash} opacity={1} className="main-background-image" />

      <div className="wrapper">
        <YakkrBanner color="white" size="medium" />

        <div className="box">
          <DataCheck
            error={state === "error" ? "Something went wrong" : undefined}
            errorContent={
              <Button
                size="small"
                color="gray-1"
                textColor="white"
                endIcon={<IoArrowForward />}
                to="/">
                Leave
              </Button>
            }>
            <div className="feedback-header">
              <DataCheck isLoading={sessionIsLoading} loadingIndicator="gloss">
                <BackgroundImage src={cig?.profilePictureURL} opacity={1} />

                <IoGift />

                <h1 className="title">{title}</h1>
              </DataCheck>
            </div>

            <FeedbackForm />
          </DataCheck>
        </div>
      </div>
    </div>
  );
};

export default FeedbackInner;
