import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createPortal } from "react-dom";
import Snackbar from "./Snackbar";

interface Props {
  horizontal?: "left" | "right";
  vertical?: "top" | "bottom";
}

export default function Snackbars(props: Props) {
  const { horizontal = "right", vertical = "bottom" } = props;

  const { snackbars, spliceSnackbar } = useGlobalContext();

  const portalRoot = document.getElementById("portal-root");
  if (portalRoot == null) return null;

  return createPortal(
    <div className={`snackbars ${horizontal} ${vertical}`}>
      {snackbars.map(snackbar => {
        const { key, type, timeout, content, icon } = snackbar;
        return (
          <Snackbar
            key={key}
            type={type}
            timeout={timeout}
            icon={icon}
            onClose={() => spliceSnackbar(snackbar)}>
            {content}
          </Snackbar>
        );
      })}
    </div>,
    portalRoot,
  );
}
