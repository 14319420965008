import Form from "@components/form/Form";
import useFlankSignUpContext from "./context/useFlankSignUpContext";
import CompletionStep from "./steps/CompletionStep";
import SignUpStep from "./steps/SignupStep";

export default function FlankSignUpForm() {
  const { handles, resetValidations, validations, setStep } = useFlankSignUpContext();

  const [signUpHandle] = handles;

  return (
    <Form
      id="flank-sign-up"
      onStepChange={setStep}
      resetValidations={resetValidations}
      stepsLengthOverride={1}
      onCompletion={() => rdt("track", "SignUp")}
      validation={validations.generic}
      triggerGlobalLoading
      steps={[
        {
          children: <SignUpStep />,
          handle: signUpHandle,
        },
        {
          children: <CompletionStep />,
        },
      ]}
    />
  );
}
