import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { CigFlowState } from "@lib/enums/generic";
import { useCallback } from "react";
import updateCigFlowState, { UpdateCigFlowStateRes } from "../updateCigFlowState";

const useUpdateCigFlowState = (state?: CigFlowState) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<UpdateCigFlowStateRes | null>(null);

  const send = useCallback(
    async (stateOverride?: CigFlowState) => {
      const _state = stateOverride ?? state;

      if (_state == null) return reqState;

      setLoading();
      try {
        const newCigFlowState = await updateCigFlowState(_state);
        return setSuccess(newCigFlowState);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [state, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useUpdateCigFlowState;
