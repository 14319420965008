import React from "react";
import CIGCancel from "./sections/CIGCancel";
import BookingDetails from "./sections/BookingDetails";
import BookingOther from "./sections/BookingOther";
import { IoBook } from "react-icons/io5";
import BookingViewerBanner from "./BookingViewerBanner";
import CustomerCancel from "./sections/CustomerCancel";
import { Booking } from "./Bookings";
import useQuery from "@hooks/useQuery";
import dayjs from "dayjs";

interface Props {
  error?: string | null;
  isLoading?: boolean;
  booking?: Booking;
  onBookingCancel?: () => void;
  onSessionCancel?: () => void;
}

export default function BookingViewer(props: Props) {
  const { error, isLoading, booking, onBookingCancel, onSessionCancel } = props;

  const type = useQuery<"booking" | "session">("type");

  const {
    sessionId,
    bookingId,
    gameName,
    platformName,
    from,
    to,
    slotsAvailable = 0,
    maxSlots = 0,
    price,
    free,
    private: privateSession,
    currency,
    extraInfo,
    charity,
    charityName,
    participants,
  } = booking ?? {};

  const isSession = type === "session";

  const hasStarted = dayjs(from).isBefore(dayjs());

  return (
    <div className="booking-viewer">
      <BookingViewerBanner error={error} isLoading={isLoading} booking={booking} />
      <div className="sections-wrapper">
        {booking || isLoading || error ? (
          <>
            <div className="sections">
              <BookingDetails
                error={error}
                isLoading={isLoading}
                startDate={from}
                endDate={to}
                slotsAvailable={slotsAvailable}
                maxSlots={maxSlots}
                amountPaid={price}
                participants={participants}
                free={free}
                private={privateSession}
                currency={currency}
                isSession={isSession}
              />
            </div>
            <div className="sections">
              <BookingOther
                bookingId={bookingId}
                isSession={isSession}
                error={error}
                isLoading={isLoading}
                extraInfo={extraInfo}
                gameName={gameName}
                platformName={platformName}
                charity={charity}
                charityName={charityName}
              />
              {!hasStarted &&
                (isSession ? (
                  <CIGCancel
                    availabilityId={sessionId}
                    error={error}
                    isLoading={isLoading}
                    onCancel={onSessionCancel}
                  />
                ) : (
                  <CustomerCancel
                    bookingId={bookingId}
                    error={error}
                    isLoading={isLoading}
                    onCancel={onBookingCancel}
                  />
                ))}
            </div>
          </>
        ) : (
          <div className="none-selected-cover">
            <IoBook />
            <h3>No Booking Selected</h3>
          </div>
        )}
      </div>
    </div>
  );
}
