import { api } from "@api/api";

interface CancelCustomerBookingRes {}

const cancelCustomerBooking = async (bookingID: string) => {
  const { data } = await api.post<CancelCustomerBookingRes>("/customerCancelBooking", {
    bookingID,
  });
  return data;
};

export default cancelCustomerBooking;
