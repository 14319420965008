import DataCheck, { DataCheckProps } from "@components/common/DataCheck";
import Button from "@components/form/Button";
import IconButton from "@components/form/IconButton";
import { CSSProps, UIColor } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { Children, cloneElement, isValidElement, PropsWithChildren, ReactNode } from "react";
import { IoArrowBack, IoClose, IoRemove } from "react-icons/io5";

interface Props extends CSSProps {
  display?: "table" | "flex";
  title?: ReactNode;
  headerActions?: ReactNode;
  footerActions?: ReactNode;
  useMoreButtons?: boolean;
  noDividers?: boolean;
  noBackground?: boolean;
  head?: ReactNode;
  color?: UIColor;
  remainingBars?: number;
  canShowMore?: boolean;
  canShowLess?: boolean;
  dataCheckProps?: DataCheckProps;
  onShowMore?: () => void;
  onShowLess?: () => void;
  onReset?: () => void;
  onBack?: () => void;
}

export default function Section(props: PropsWithChildren<Props>) {
  const {
    display = "flex",
    title,
    headerActions,
    footerActions,
    useMoreButtons,
    noDividers,
    noBackground,
    color = "white",
    remainingBars,
    canShowMore,
    canShowLess,
    dataCheckProps,
    onShowMore,
    onShowLess,
    onReset,
    onBack,
    className,
    id,
    style,
    head,
    children,
  } = props;

  const mappedChildren = Children.map(
    children,
    child => isValidElement(child) && cloneElement(child as any, { dataCheckProps }),
  );

  return (
    <section
      className={classNames(
        "section",
        !noDividers && "dividers",
        !noBackground && "background",
        className,
      )}
      id={id}
      style={style}>
      {onBack && (
        <div className="section-back">
          <Button
            size="small"
            color={color}
            textColor="white"
            variant="outlined"
            startIcon={<IoArrowBack />}
            onClick={onBack}>
            Back
          </Button>
        </div>
      )}
      <div className="section-header">
        <h3 className="title">{title}</h3>
        {headerActions && <div className="actions">{headerActions}</div>}
      </div>
      {display === "flex" ? (
        <div className="section-flex">
          <DataCheck {...dataCheckProps}>{mappedChildren}</DataCheck>
        </div>
      ) : (
        <table className="section-table">
          <tbody>
            {head}
            <DataCheck {...dataCheckProps}>{mappedChildren}</DataCheck>
          </tbody>
        </table>
      )}

      {footerActions ? (
        <div className="section-actions">{footerActions}</div>
      ) : useMoreButtons ? (
        <div className="section-more">
          <div>
            {canShowLess && (
              <IconButton
                size="small"
                variant="flat"
                color={color}
                iconColor="white"
                icon={<IoRemove />}
                onClick={onShowLess}
              />
            )}
          </div>
          <div>
            {canShowMore && (
              <Button
                variant="flat"
                size="small"
                color={color}
                textColor="white"
                onClick={onShowMore}>
                Show More ({remainingBars})
              </Button>
            )}
          </div>
          <div>
            {canShowLess && (
              <IconButton
                size="small"
                variant="flat"
                color={color}
                iconColor="white"
                icon={<IoClose />}
                onClick={onReset}
              />
            )}
          </div>
        </div>
      ) : null}
    </section>
  );
}
