import Section from "@components/section/Section";
import SectionBar from "@components/section/SectionBar";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { Currency } from "@lib/enums/generic";
import { numberToCurrency, pluralise } from "@lib/utils/generic";
import dayjs from "dayjs";
import React from "react";
import { IoTicket } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import useTimeLeft from "../room/hooks/useTimeLeft";
import Pill from "@components/common/Pill";

import Button from "@components/form/Button";
import Menu from "@components/form/Menu";

interface Props {
  startDate?: string;
  endDate?: string;
  slotsAvailable?: number;
  maxSlots?: number;
  amountPaid?: number;
  free?: boolean;
  private?: boolean;
  currency?: Currency;
  participants?: string[];

  error?: string | null;
  isLoading?: boolean;

  isSession?: boolean;
}

export default function BookingDetails(props: Props) {
  const {
    startDate,
    endDate,
    slotsAvailable = 0,
    maxSlots = 0,
    amountPaid = 0,
    free,
    private: privateSession,
    currency,
    participants,

    error,
    isLoading,

    isSession,
  } = props;

  const { breakpoints } = useGlobalContext();

  const history = useHistory();

  const { hours, minutes } = useTimeLeft(dayjs(endDate), dayjs(startDate));
  const onBack = breakpoints.md ? () => history.push("/bookings") : undefined;

  return (
    <Section
      title="Booking"
      className="booking-details"
      onBack={onBack}
      dataCheckProps={{ error, isLoading, loadingIndicator: "gloss" }}>
      <SectionBar>
        <label>Session Date</label>
        <p>{dayjs(startDate).format("Do MMM YYYY HH:mm")}</p>
      </SectionBar>
      <SectionBar>
        <label>Session Period</label>
        <p>
          {hours} {pluralise("hr", hours)} {minutes} {pluralise("min", minutes)}
        </p>
      </SectionBar>
      <SectionBar>
        <label>Participants</label>
        <div>
          <p className="flex-row align-center text-purple" style={{ gap: 8 }}>
            <IoTicket />{" "}
            {privateSession
              ? `${maxSlots} sold`
              : `${maxSlots - slotsAvailable} / ${maxSlots} sold`}
          </p>
        </div>
      </SectionBar>

      {participants && participants.length > 0 && (
        <SectionBar className="participants-bar">
          <label>Who's Joining?</label>
          <div>
            <div />
            <div className="participants">
              <Menu
                variant="contained"
                color="black-3"
                relativePosition="left"
                content={participants.map((participant, i) => (
                  <Pill key={i} size="extra-small" textColor="white" variant="flat">
                    {participant}
                  </Pill>
                ))}>
                <Button color="purple" textColor="white" size="small">
                  View All
                </Button>
              </Menu>
            </div>
          </div>
        </SectionBar>
      )}
      {!isSession && (
        <SectionBar>
          <label>Amount Paid</label>
          {free && !privateSession ? (
            <Pill size="extra-small" color="blue" textColor="white" rounded>
              FREE
            </Pill>
          ) : (
            <p>
              {amountPaid != null &&
                amountPaid > 0 &&
                currency &&
                numberToCurrency(amountPaid, currency)}
            </p>
          )}
        </SectionBar>
      )}
    </Section>
  );
}
