import { api } from "@api/api";

export interface CreateTipCheckoutRes {
  url: string;
  bookingID: string;
  cigID: string;
  userID: string;
  checkoutID: string;
  availabilityID: string;
  cigDisplayName: string;
}

const createTipCheckout = async (availabilityID: string, amount: number) => {
  const { data } = await api.post<CreateTipCheckoutRes>("/createTipCheckout ", {
    availabilityID,
    amount,
    showDisplayName: false,
  });
  return data;
};

export default createTipCheckout;
