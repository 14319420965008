import Accordion from "@components/common/Accordion";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import SplashTitle from "@components/splash/SplashTitle";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

const FAQs = () => {
  const { breakpoints } = useGlobalContext();

  const accordionSize = breakpoints.xs
    ? "small"
    : breakpoints.md
    ? "medium"
    : breakpoints.sl
    ? "large"
    : "extra-large";

  return (
    <section className="section faqs">
      <SplashTitle center>FAQs</SplashTitle>

      <div className="accordions">
        <SlideOnIntersect direction="right" timeout={500}>
          <div>
            <Accordion
              title="Who can use this platform?"
              color="black-2"
              contentTextColor="peach-3"
              size={accordionSize}>
              Flank is open to any Twitch streamer who is looking to monetise their content through
              sponsored partnerships and collaborations. Affiliates will be reviewed as they need to
              have over 30 average concurrent viewers per stream. Twitch Partners are automatically
              accepted.
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={700}>
          <div>
            <Accordion
              title="How do I sign up as a streamer on Flank?"
              color="black-2"
              contentTextColor="peach-3"
              size={accordionSize}>
              Streamers can sign up for Flank by creating an account with their email address, and
              Twitch display name. We may also ask for some basic information about themselves and
              their channel in order to suggest appropriate brands.
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={800}>
          <div>
            <Accordion
              title="How much can I earn?"
              color="black-2"
              contentTextColor="peach-3"
              size={accordionSize}>
              What brands are willing to compensate you for will depends on your fan engagement,
              platform activity, the kind of content you create, and what method they're looking for
              you to advertise them with (for example, stream overlays or panels).
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={850}>
          <div>
            <Accordion
              title="What types of sponsorships and partnerships are available on Flank"
              color="black-2"
              contentTextColor="peach-3"
              size={accordionSize}>
              A wide variety of sponsorships and partnerships are available on Flank. We will
              automatically suggest offers that best fit your interests, and allow you to browse
              offerings.
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={875}>
          <div>
            <Accordion
              title="How do I connect with brands and sponsors through this platform?"
              color="black-2"
              contentTextColor="peach-3"
              size={accordionSize}>
              Streamers can browse available sponsorships on the platform and apply for the ones
              that best fit their interests and audience. Brands can also browse streamers on the
              platform and reach out to them directly with sponsorship opportunities.
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={900}>
          <div>
            <Accordion
              title="What are the requirements for being eligible for particular sponsorships on Flank?"
              color="black-2"
              contentTextColor="peach-3"
              size={accordionSize}>
              Eligibility requirements vary depending on the sponsorships, but generally streamers
              are expected to have a certain number of followers, a consistent streaming schedule,
              and a professional and engaging presence in order to attract the largest offers.
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={925}>
          <div>
            <Accordion
              title="How does this platform ensure the legitimacy of sponsors and brands on the platform?"
              color="black-2"
              contentTextColor="peach-3"
              size={accordionSize}>
              We also hold payment from them so you can be sure that you'll be rewarded when you've
              fulfilled your sponsorship obligations.
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={925}>
          <div>
            <Accordion
              title="How does Flank make money?"
              color="black-2"
              contentTextColor="peach-3"
              size={accordionSize}>
              We do not take a cut from Streamers. You receive 100% of the sponsorship. We charge
              the brand a platform fee for using our service to connect them with streamers like
              yourself.
            </Accordion>
          </div>
        </SlideOnIntersect>
      </div>
    </section>
  );
};

export default FAQs;
