import { RefObject, useEffect } from "react";

const useOnTouchMove = (ref: RefObject<HTMLElement>, callback: (event: TouchEvent) => void) => {
  useEffect(() => {
    const current = ref.current;
    if (current == null) return;

    current.addEventListener("touchmove", callback);

    return () => {
      current.removeEventListener("touchmove", callback);
    };
  }, [ref, callback]);
};

export default useOnTouchMove;
