import Button from "@components/form/Button";
import ButtonSelect from "@components/form/ButtonSelect";
import { FormElement } from "@components/form/Form";
import IconButton from "@components/form/IconButton";
import Input from "@components/form/Input";
import { Option } from "@components/form/Options";
import Select from "@components/form/Select";
import Collapse from "@components/transitions/Collapse";
import useStorageItem from "@hooks/sessionStore/useStorageItem";
import { AuthState, CigFlowState } from "@lib/enums/generic";
import { classNames } from "@lib/utils/generic";
import { AuthModalType } from "@src/globalContext/hooks/useAuthModal";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs from "dayjs";
import React, { ReactNode, useEffect, useState } from "react";
import { IoCheckmark } from "react-icons/io5";

export default function DevPanel() {
  const [active, setActive] = useState(false);

  return (
    <div className={classNames("dev-panel-wrapper", active && "open")}>
      <Collapse in={active} mountOnEnter={false} unmountOnExit={false}>
        <div className="dev-panel">
          <div className="dev-panel-inner">
            <DevPanelHeader />

            <SessionDetailsSection />

            <VersionStateSection />

            <AuthModalSection />

            <AuthStateSection />

            <CigFlowStateSection />
          </div>
        </div>
      </Collapse>

      <Button
        size="extra-small"
        color="black-3"
        textColor="white"
        onClick={() => setActive(active => !active)}
        className="toggle-button">
        {active ? "Close Dev Panel" : "Open Dev Panel"}
      </Button>
    </div>
  );
}

const DevPanelHeader = () => {
  const { user, breakpoints, setAuthModal } = useGlobalContext();

  return (
    <div className="dev-panel-header">
      <h1 className="title">Dev Panel</h1>

      <div className="buttons">
        {user == null ? (
          <>
            <Button
              color="white"
              textColor={breakpoints.sm ? "white" : "black"}
              variant={breakpoints.sm ? "flat" : "contained"}
              size={breakpoints.sm ? "small" : "medium"}
              onClick={() => setAuthModal("login")}
              className="login-button">
              Login
            </Button>
            {!breakpoints.sm && (
              <Button
                color="black-3"
                textColor="white"
                variant="contained"
                size="medium"
                onClick={() => setAuthModal("register")}>
                Register
              </Button>
            )}
          </>
        ) : (
          <>
            <Button
              color={breakpoints.sm ? "white" : "black-3"}
              textColor="white"
              variant={breakpoints.sm ? "flat" : "contained"}
              size={breakpoints.sm ? "small" : "medium"}
              to="/logout">
              Logout
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const SessionDetailsSection = () => {
  const { user, userExtension, cigProfile } = useGlobalContext();

  const { attributes, signInUserSession } = user ?? {};
  const { id, displayName } = userExtension ?? {};
  const { id: cigId, accepted, country, defaultCurrency } = cigProfile ?? {};

  return (
    <section className="section session-details">
      <h2 className="section-title">Current Session</h2>

      {user ? (
        <>
          <Row label="Session Token" value={signInUserSession?.idToken.jwtToken} />
          <Row label="Email" value={attributes?.email} />
          {userExtension && (
            <>
              <Row label="User ID" value={id} />
              <Row label="User Display Name" value={displayName} />
            </>
          )}
          {cigProfile && (
            <>
              <Row label="CIG ID" value={cigId} />
              <Row label="CIG Accepted" value={accepted ? "Yes" : "No"} />
              <Row label="Country" value={country} />
              <Row label="Currency" value={defaultCurrency} />
            </>
          )}
        </>
      ) : (
        <p>No Session</p>
      )}
    </section>
  );
};

const VersionStateSection = () => {
  const { descendingChangelog } = useGlobalContext();

  const lastVersionItem = useStorageItem("last-version-seen", descendingChangelog[0].version);

  return (
    <section className="section session-details">
      <h2 className="section-title">Current Session</h2>

      <Row
        label="Last Version Seen"
        value={lastVersionItem.item.data ?? ""}
        onChange={lastVersionItem.set}
        options={descendingChangelog.map(({ version, date }) => ({
          label: `${version} - ${dayjs(date).format("DD MMM YYYY")}`,
          value: version,
        }))}
      />
    </section>
  );
};

const AuthModalSection = () => {
  const { authModal, setAuthModal } = useGlobalContext();

  return (
    <section className="section auth-modal">
      <h2 className="section-title">Auth Modal State</h2>

      <Row
        label="Type"
        options={[
          {
            label: "Login",
            value: "login",
          },
          {
            label: "Register",
            value: "register",
          },
          {
            label: "Forgot Password",
            value: "forgot-password",
          },
        ]}
        value={authModal.type ?? ""}
        onChange={value => setAuthModal((value || null) as AuthModalType)}
      />
      {Object.entries(authModal.data).map(([key, value]) => (
        <Row key={key} label={key} value={value} />
      ))}
    </section>
  );
};

const AuthStateSection = () => {
  const { authState } = useGlobalContext();

  return (
    <section className="section auth-state">
      <h2 className="section-title">Auth State (Read-only)</h2>
      <ButtonSelect value={authState}>
        {Object.values(AuthState).map(state => {
          const selected = state === authState;
          return (
            <Button
              size="small"
              color={selected ? "purple" : "black-4"}
              textColor="white"
              key={state}
              name={state}
              readOnly>
              {state}
            </Button>
          );
        })}
      </ButtonSelect>
    </section>
  );
};

const CigFlowStateSection = () => {
  const { cigFlowState, setCigFlowState, cigFlowStateIsLoading, cigFlowStateError } =
    useGlobalContext();

  return (
    <section className="section cig-flow-state">
      <h2 className="section-title">CIG Flow State</h2>
      {cigFlowStateError && (
        <p className="text-red" style={{ marginBottom: 8 }}>
          {cigFlowStateError}
        </p>
      )}
      <ButtonSelect
        value={cigFlowState}
        onChange={({ value }: FormElement<any>) => setCigFlowState(value)}>
        {Object.values(CigFlowState).map(state => {
          const selected = state === cigFlowState;
          return (
            <Button
              size="small"
              color={selected ? "purple" : "black-4"}
              textColor="white"
              key={state}
              name={state}
              isLoading={selected && cigFlowStateIsLoading}>
              {state}
            </Button>
          );
        })}
      </ButtonSelect>
    </section>
  );
};

interface RowProps {
  label?: ReactNode;
  value?: string;
  onChange?: (value: string) => void;
  options?: Option[];
}

const Row = ({ label, value, onChange, options }: RowProps) => {
  const [innerValue, setInnerValue] = useState(value ?? "");

  useEffect(() => {
    if (value != null) setInnerValue(value);
  }, [value]);

  const props = onChange
    ? {
        value: innerValue,
        ...(options
          ? {
              onChange: ({ value }: FormElement<string>) => {
                setInnerValue(value);
                onChange(value);
              },
            }
          : {
              onChange: ({ value }: FormElement<string>) => setInnerValue(value),
              endIcon: (
                <IconButton
                  variant="flat"
                  iconColor="white"
                  icon={<IoCheckmark />}
                  onClick={() => onChange(innerValue)}
                  style={{ marginRight: 4 }}
                />
              ),
            }),
      }
    : {
        value,
      };

  return (
    <div className="row">
      <p>{label}</p>
      {options ? (
        <Select
          color="black-4"
          textColor="white"
          size="small"
          options={options}
          useEmptyOption
          {...props}
        />
      ) : (
        <Input color="black-4" textColor="white" size="small" {...props} />
      )}
    </div>
  );
};
