import DataCheck from "@components/common/DataCheck";
import Button from "@components/form/Button";
import Form from "@components/form/Form";
import BulbIcon from "@components/icons/BulbIcon";
import ExperiencesHeader from "@pages/experiences/ExperiencesHeader";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import { IoArrowBack } from "react-icons/io5";
import useUpdateExperiencePackageContext from "./context/useUpdateExperiencePackageContext";
import Details from "./sections/Details";
import Extras from "./sections/Extras";
import Games from "./sections/Games";

export default function UpdateExperiencePackageDesktop() {
  const { breakpoints } = useGlobalContext();

  const {
    validations,
    update,
    updateIsLoading,
    resetValidations,
    fullPackageExperienceIsLoading,
    fullPackageExperienceError,
  } = useUpdateExperiencePackageContext();

  const saveButton = (
    <Button
      size="large"
      color="purple"
      textColor="white"
      type="submit"
      isLoading={updateIsLoading}
      justifyContent="center"
      className="save-button">
      Finish
    </Button>
  );

  return (
    <div id="update-experience-package-page" className="experiences-manage-page desktop">
      <ExperiencesHeader
        title="Package Setup"
        subTitle="Create bundles to help your fans request sessions even faster."
        actions={
          <Button
            color="black-4"
            textColor="white"
            size={breakpoints.md ? "medium" : "large"}
            startIcon={<IoArrowBack />}
            justifyContent="center"
            to="/experiences">
            Back
          </Button>
        }
      />

      <div className="pro-tip">
        <BulbIcon className="bulb-icon" />

        <div className="text-wrapper">
          <p className="title">Pro Tip!</p>
          <p className="text">
            Set up your bundles so fans can easily order an experience for them and their teammates.
            <br />
            Make this the cheapest bundle with less benefits - think popcorn at the cinema
            <br />
            Example: shorter session, less people but cheapest price.
          </p>
        </div>
      </div>

      <Form
        id="update-package-experience"
        onCompletion={update}
        resetValidations={resetValidations}
        validation={validations.generic}>
        <DataCheck
          isLoading={fullPackageExperienceIsLoading}
          error={fullPackageExperienceError}
          loadingIndicator="spinner">
          <div className="form-section-outer">
            <div className="form-section-wrapper">
              <Games />
            </div>
          </div>
          <div className="form-section-outer">
            <div className="form-section-wrapper">
              <Details />
            </div>
          </div>
          <div className="form-section-outer">
            <div className="form-section-wrapper">
              <Extras />
            </div>
            {!breakpoints.md && saveButton}
          </div>
          {breakpoints.md && <div className="form-section-outer">{saveButton}</div>}
        </DataCheck>
      </Form>
    </div>
  );
}
