import useGetCigAvailability from "@api/public/get/hooks/useGetCigAvailability";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { SortDirection } from "@lib/enums/generic";
import { sortMap } from "@lib/utils/generic";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Session } from "@lib/types/session";

const privateSessionsFilter = (isOwnProfile: boolean) => (session: Session) =>
  !session.private || isOwnProfile;

const useFullAvailability = (cigId?: string, periodDate?: Dayjs) => {
  const { breakpoints, userExtension, cigProfile } = useGlobalContext();

  const isOwnProfile = cigProfile?.id === cigId;

  const startDate = periodDate;
  const endDate = periodDate && periodDate.add(1, breakpoints.md ? "day" : "week");

  const [fullAvailability, setFullAvailability] = useState<Session[]>([]);

  const {
    data: availability,
    error,
    isLoading,
    send: reSend,
  } = useGetCigAvailability(cigId, startDate?.format(), endDate?.format(), userExtension?.id, true);

  const handleSessionDelete = (sessionId: string) => {
    setFullAvailability(fullAvailability =>
      fullAvailability.filter(session => session.id !== sessionId),
    );
  };

  useEffect(() => {
    setFullAvailability(fullAvailability => {
      const newAvailability: Session[] = JSON.parse(JSON.stringify(fullAvailability));

      for (const session of availability) {
        const index = newAvailability.findIndex(({ id }) => id === session.id);
        if (index >= 0) {
          newAvailability[index] = session;
        } else {
          newAvailability.push(session);
        }
      }

      return newAvailability;
    });
    // eslint-disable-next-line
  }, [availability, setFullAvailability]);
  // startDate and endDate aren't needed

  return {
    startDate,
    endDate,
    availability: availability.filter(privateSessionsFilter(isOwnProfile)),
    fullAvailability: fullAvailability
      .filter(privateSessionsFilter(isOwnProfile))
      .sort(sortMap("from", SortDirection.Ascending)),
    error,
    isLoading,
    reSend,
    handleSessionDelete,
  };
};

export default useFullAvailability;
