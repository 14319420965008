import BookSession from "@components/calendar/view/book/BookSession";
import useCigProfileContext from "../context/hooks/useCigProfileContext";

export default function Book() {
  const { sessionId, upcomingSessions, setSessionId, setPeriodDate, isOwnProfile } =
    useCigProfileContext();

  const sessionIndex = upcomingSessions.findIndex(({ id }) => id === sessionId);
  const session = upcomingSessions[sessionIndex];

  if (isOwnProfile) return null;

  return (
    <BookSession
      sessionIndex={sessionIndex}
      session={session}
      onClose={() => {
        setSessionId(null);
        setPeriodDate(null);
      }}
    />
  );
}
