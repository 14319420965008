import DataCheck from "@components/common/DataCheck";
import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface Props extends CSSProps {
  dynamicHeight?: boolean;
  error?: string | null;
  isLoading?: boolean;
  to?: string;
  canWrap?: boolean;
  onClick?: () => void;
}

export default function SectionBar(props: PropsWithChildren<Props>) {
  const {
    dynamicHeight = false,
    error,
    isLoading,
    className = "",
    to,
    canWrap,
    id,
    style,
    children,
    onClick = () => {},
  } = props;

  const nodes = (
    <DataCheck error={error} isLoading={isLoading} loadingIndicator="gloss">
      {children}
    </DataCheck>
  );

  if (to != null) {
    return (
      <Link
        className={classNames(
          "section-bar",
          dynamicHeight && "dynamic-height",
          canWrap && "can-wrap",
          className,
        )}
        id={id}
        style={style}
        to={to}>
        {nodes}
      </Link>
    );
  } else {
    return (
      <div
        className={classNames(
          "section-bar",
          dynamicHeight && "dynamic-height",
          canWrap && "can-wrap",
          className,
        )}
        id={id}
        style={style}
        onClick={onClick}>
        {nodes}
      </div>
    );
  }
}
