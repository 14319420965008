import { FormValidation } from "../types/form";
import { Validator } from "./Validator";
import bookSessionValidators from "./validators/bookSession";
import changePasswordValidators from "./validators/changePassword";
import cigProfileValidators from "./validators/cigProfile";
import claimTicketsValidators from "./validators/claimTickets";
import createReportValidators from "./validators/createReport";
import createSessionValidators from "./validators/createSession";
import forgotPasswordValidators from "./validators/forgotPassword";
import freeSessionValidators from "./validators/freeSession";
import globalValidators from "./validators/global";
import loginValidators from "./validators/login";
import messageBoxValidators from "./validators/messageBox";
import registerValidators from "./validators/register";
import shareTicketValidators from "./validators/shareTicket";
import subscriptionValidators from "./validators/subscription";
import supportClaimValidators from "./validators/supportClaim";
import updateSessionValidators from "./validators/updateSession";
import updateUserExtensionValidators from "./validators/updateUserExtension";
import feedbackValidators from "./validators/feedback";
import updateExperiencePackageValidators from "./validators/updateExperiencePackage";
import updateExperienceCustomValidators from "./validators/updateExperienceCustom";
import createExperienceRequestValidators from "./validators/createExperienceRequest";
import createInteractiveQuestionAnswerValidators from "./validators/createInteractiveQuestionAnswer";
import cigRegisterValidators from "./validators/cigRegister";
import consultancySignUpValidators from "./validators/consultancySignUp";
import flankSignUpValidators from "./validators/flankSignUp";

export const createEmptyValidation = (name: string): FormValidation => ({
  key: name,
  failed: false,
  list: [],
});

export function customValidate(key: string, validator: (validation: FormValidation) => void) {
  const validation = {
    key,
    list: [],
  } as FormValidation;

  validator(validation);

  validation.failed = validation.list.find(item => item.type === "error") != null;

  return validation;
}

export type ParentValidator = { [key: string]: ChildValidator };
export type ParentValidators = keyof typeof validators;

export type ChildValidator = (
  validation: FormValidation,
  value: any,
  values: any,
) => void | Validator;

export function validate(
  parentValidatorName: ParentValidators,
  childValidatorName: string,
  value: any,
  values: Record<any, any>,
) {
  const validation = {
    key: childValidatorName,
    list: [],
  } as FormValidation;

  let parentValidator = validators[parentValidatorName] as ParentValidator;
  let childValidator = parentValidator[childValidatorName];
  if (childValidator == null) {
    parentValidator = validators.global as ParentValidator;
    childValidator = parentValidator[childValidatorName];
  }
  if (childValidator == null) return validation;

  childValidator(validation, value, values);

  validation.failed = validation.list.find(item => item.type === "error") != null;

  return validation;
}

const validators = {
  register: registerValidators,
  login: loginValidators,
  forgotPassword: forgotPasswordValidators,
  cigProfile: cigProfileValidators,
  global: globalValidators,
  createSession: createSessionValidators,
  updateSession: updateSessionValidators,
  bookSession: bookSessionValidators,
  changePassword: changePasswordValidators,
  updateUserExtension: updateUserExtensionValidators,
  subscription: subscriptionValidators,
  supportClaim: supportClaimValidators,
  messageBox: messageBoxValidators,
  feedback: feedbackValidators,
  createReport: createReportValidators,
  shareTicket: shareTicketValidators,
  claimTickets: claimTicketsValidators,
  freeSesisonModal: freeSessionValidators,
  updateExperiencePackage: updateExperiencePackageValidators,
  updateExperienceCustom: updateExperienceCustomValidators,
  createExperienceRequest: createExperienceRequestValidators,
  createInteractiveQuestionAnswer: createInteractiveQuestionAnswerValidators,
  cigRegister: cigRegisterValidators,
  consultancySignUp: consultancySignUpValidators,
  flankSignup: flankSignUpValidators,
};
