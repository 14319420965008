import Button from "@components/form/Button";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { classNames, pluralise } from "@lib/utils/generic";
import dayjs from "dayjs";
import React from "react";
import useRoomContext from "../roomContext/hooks/useRoomContext";
import useTimeLeft from "../hooks/useTimeLeft";
import RoomOverlay from "./RoomOverlay";

interface Props {
  rerender?: () => void;
}

export default function PreRoomOverlay(props: Props) {
  const { rerender } = props;

  const { breakpoints } = useGlobalContext();
  const { session, type } = useRoomContext();

  const { from } = session ?? {};
  const isCig = type === "cig";

  const { hours, minutes, seconds, timeLeft, isNegative } = useTimeLeft(dayjs(from));

  const joinable = isCig ? timeLeft <= 120 : timeLeft <= 0;

  const buttonSize = breakpoints.sm ? "medium" : "large";

  return (
    <RoomOverlay
      title="You're nice and early!"
      buttons={
        <>
          <Button
            size={buttonSize}
            color="purple"
            textColor="white"
            disabled={!joinable}
            onClick={rerender}>
            {isCig ? "Launch Room" : "Join Room"}
          </Button>
          <Button size={buttonSize} variant="outlined" color="white" textColor="white" href="/">
            Leave
          </Button>
        </>
      }>
      <div className={classNames("pre-room-overlay", joinable && "joinable")}>
        <div className="countdown">
          <div className="time-stack">
            <div className="number">{isNegative ? 0 : hours}</div>
            <p className="text">{pluralise(breakpoints.sm ? "hr" : "hour", hours)}</p>
          </div>
          <div className="time-stack">
            <div className="number">{isNegative ? 0 : minutes}</div>
            <p className="text">{pluralise(breakpoints.sm ? "min" : "minute", minutes)}</p>
          </div>
          <div className="time-stack">
            <div className="number">{isNegative ? 0 : seconds}</div>
            <p className="text">{pluralise(breakpoints.sm ? "sec" : "second", seconds)}</p>
          </div>

          <div className="prompt">
            <p>The room is ready</p>
          </div>
        </div>
      </div>
    </RoomOverlay>
  );
}
