import Section from "@components/section/Section";
import SectionBar from "@components/section/SectionBar";
import useCigIsAccepted from "@hooks/session/useCigIsAccepted";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { cigNames } from "@lib/constants/generic";
import { CIG } from "@lib/enums/generic";
import Status from "./Status";

export default function CIGStatuses() {
  const { cigProfile } = useGlobalContext();

  const isAccepted = useCigIsAccepted();

  return (
    <Section title="Application Status">
      <SectionBar dynamicHeight>
        <div className="pair">
          <label>Acceptance Status</label>
          <p className="no-clamp">
            This indicates whether you have met the requirements to be a{" "}
            {cigNames[cigProfile?.category ?? CIG.Gamer]} on Yakkr.
            <br />
            <br />
            <Status status={isAccepted} name={isAccepted ? "Accepted" : "Pending Acceptance"} />
          </p>
        </div>
      </SectionBar>
    </Section>
  );
}
