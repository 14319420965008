import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import confirmEmail from "../confirmEmail";

const useConfirmEmail = (email?: string, confirmCode?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<null>(null);

  const send = useCallback(async () => {
    if (email == null || confirmCode == null) {
      return setError("Email and code are required");
    }

    setLoading();
    try {
      await confirmEmail(email, confirmCode);
      return setSuccess(null);
    } catch (err: any) {
      return setError(err.message);
    }
  }, [email, confirmCode, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useConfirmEmail;
