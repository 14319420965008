import { Notification as NotificationType } from "@api/private/get/getNotifications";
import useGetNotifications from "@api/private/get/hooks/useGetNotifications";
import useGetUnreadNotifications from "@api/private/get/hooks/useGetUnreadNotifications";
import useSetReadNotifications from "@api/private/update/hooks/useSetReadNotifications";
import DataCheck from "@components/common/DataCheck";
import Divider from "@components/common/Divider";
import Button from "@components/form/Button";
import IconButton from "@components/form/IconButton";
import Fade from "@components/transitions/Fade";
import useFocussedWithin from "@hooks/useFocussedWithin";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { notificationTypeColors, notificationTypeIcons } from "@lib/constants/generic";
import { SortDirection } from "@lib/enums/generic";
import { UIColor } from "@lib/types/generic";
import { sortMap } from "@lib/utils/generic";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import { createPortal } from "react-dom";
import { IoMailOpen, IoNotifications } from "react-icons/io5";
import useRelativeCoordsAndSize, { RelativePosition } from "../../hooks/useRelativeCoordsAndSize";
import Notification from "./Notification";

interface Props {
  relativePosition?: RelativePosition;
}

export default function Notifications(props: Props) {
  const { relativePosition = "bottom" } = props;

  const { breakpoints } = useGlobalContext();

  const ref = useRef<HTMLDivElement | null>(null);
  const rootRef = useRef<(HTMLButtonElement & HTMLAnchorElement) | null>(null);

  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [showAll, setShowAll] = useState(false);

  const {
    isLoading: unreadIsLoading,
    error: unreadError,
    send: getUnreadNotifications,
  } = useGetUnreadNotifications();

  const {
    isLoading: allIsLoading,
    error: allError,
    send: getAllNotifications,
  } = useGetNotifications();

  const {
    isLoading: setIsLoading,
    error: setError,
    send: setReadNotifications,
  } = useSetReadNotifications();

  const [focussed, setFocussed] = useFocussedWithin(ref);

  const portalRoot = document.getElementById("portal-root");

  const { coords, reCalculate } = useRelativeCoordsAndSize(
    rootRef,
    relativePosition,
    "portal-fixed",
    !focussed,
  );

  const getAndSetUnread = useCallback(async () => {
    setShowAll(false);
    const { data } = await getUnreadNotifications();
    setNotifications(data);
  }, [setShowAll, getUnreadNotifications, setNotifications]);

  const getAndSetAll = useCallback(async () => {
    setShowAll(true);
    const { data } = await getAllNotifications();
    setNotifications(data);
  }, [setShowAll, getAllNotifications, setNotifications]);

  useEffect(() => {
    if (focussed) reCalculate();
  }, [focussed, reCalculate]);

  useEffect(() => {
    if (showAll) return;
    getAndSetUnread();
    const interval = setInterval(getAndSetUnread, 1000 * 60);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [showAll]);
  // must omit getAndSetUnread to prevent loop

  if (portalRoot == null) return null;

  const error = allError ?? unreadError ?? setError;
  const isLoading = unreadIsLoading || allIsLoading;

  const unread = notifications.filter(({ read }) => !read).length;

  const nodes = (
    <Fade in={focussed && !!coords} appear timeout={300}>
      <div
        className={`notifications ${breakpoints.sm ? "full-width" : relativePosition}`}
        style={
          coords &&
          (breakpoints.sm
            ? {
                top: coords.y,
              }
            : {
                left: coords.x,
                top: coords.y,
              })
        }>
        <div ref={ref} className="notifications-inner" tabIndex={0}>
          <div className="notifications-header">
            <h6 className="title">{showAll ? "All" : "Unread"} Notifications</h6>
            <Button
              variant="flat"
              color="gray-3"
              textColor="gray-3"
              size="small"
              isLoading={setIsLoading}
              onClick={async () => {
                await setReadNotifications();
                getAndSetUnread();
              }}
              disabled={unread === 0}>
              Mark read
            </Button>
          </div>
          <div className="notifications-list">
            <DataCheck error={error} isLoading={isLoading} loadingIndicator="spinner">
              {notifications.length > 0 ? (
                notifications
                  .sort(sortMap("date", SortDirection.Descending))
                  .map(({ type, message, date, read }, i) => (
                    <Fragment key={i}>
                      <Notification
                        icon={notificationTypeIcons[type]}
                        color={notificationTypeColors[type] as UIColor}
                        date={date}
                        read={!!read}>
                        {message}
                      </Notification>
                      <Divider color="gray-2" />
                    </Fragment>
                  ))
              ) : (
                <Notification icon={<IoMailOpen className="text-gray-3" />}>
                  <span className="text-gray-2">
                    You have no{showAll ? "" : " unread"} notifications
                  </span>
                </Notification>
              )}
            </DataCheck>
          </div>
          <div className="notifications-show-all">
            {showAll ? (
              <Button
                variant="flat"
                color="gray-3"
                textColor="gray-3"
                size="small"
                onClick={async () => getAndSetUnread()}>
                Hide Read
              </Button>
            ) : (
              <Button
                variant="flat"
                color="gray-3"
                textColor="gray-3"
                size="small"
                onClick={async () => getAndSetAll()}>
                Show Read
              </Button>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );

  return (
    <>
      <IconButton
        size={breakpoints.sm ? "small" : "medium"}
        variant={breakpoints.sm ? "flat" : "contained"}
        color="black-4"
        iconColor="white"
        icon={<IoNotifications />}
        rounded
        onClick={() => setFocussed(!focussed)}
        ref={rootRef}
        count={unread}
      />
      {createPortal(nodes, portalRoot)}
    </>
  );
}
