import useCIGJoinSession from "@api/private/twilio/hooks/useCIGJoinSession";
import useCustomerJoinOpenSession from "@api/private/twilio/hooks/useCustomerJoinOpenSession";
import useCustomerJoinSession from "@api/private/twilio/hooks/useCustomerJoinSession";

const useJoinRoom = (
  sessionId?: string,
  ticketId?: string,
  accessCode?: string,
  type?: "cig" | "customer",
  autoSend?: boolean,
) => {
  const isCig = type === "cig";
  const isCustomer = type === "customer" && !accessCode;
  const isOpenCustomer = type === "customer" && !!accessCode;

  const cigRes = useCIGJoinSession(sessionId, isCig && autoSend);
  const customerRes = useCustomerJoinSession(ticketId, isCustomer && autoSend);
  const customerOpenRes = useCustomerJoinOpenSession(
    sessionId,
    accessCode,
    isOpenCustomer && autoSend,
  );

  const res = isCig ? cigRes : isCustomer ? customerRes : customerOpenRes;

  const { data: joinDetails, error, isLoading, send } = res;

  return { join: send, joinDetails, error, isLoading };
};

export default useJoinRoom;
