import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useImagesLoaded from "@hooks/useImagesLoaded";
import React, { PropsWithChildren, ReactNode, useEffect } from "react";
import Progress, { ProgressProps } from "./Progress";

interface Props extends ProgressProps {
  isLoading?: boolean;
  error?: string | null;
  errorContent?: ReactNode;
  emptyMessage?: string;
  emptyContent?: ReactNode;
  loadingContent?: ReactNode;
  isEmpty?: boolean;
  loadingIndicator?: "spinner" | "global" | "gloss" | "none";
  imgs?: Array<string | undefined | null>;
  fullPage?: boolean;
}

export type DataCheckProps = Props;

export default function DataCheck(props: PropsWithChildren<Props>) {
  const {
    error,
    errorContent,
    isEmpty,
    emptyMessage = "No data",
    emptyContent,
    loadingContent,
    loadingIndicator = "none",
    imgs = [],
    fullPage,
    children,
    ...progressProps
  } = props;

  const { loaded } = useImagesLoaded(imgs.filter(img => img != null) as string[]);

  const isLoading = props.isLoading || !loaded;

  const { setLoading: setGlobalLoading } = useGlobalContext();

  const className = `data-check ${fullPage ? "full-page" : ""}`;

  useEffect(() => {
    if (loadingIndicator === "global") setGlobalLoading(isLoading ?? false);
  }, [isLoading, loadingIndicator, setGlobalLoading]);

  if (isLoading) {
    if (loadingIndicator === "spinner") {
      return (
        <>
          {loadingContent}
          <div className={className}>
            <Progress {...progressProps} />
          </div>
        </>
      );
    } else if (loadingIndicator === "gloss") {
      return (
        <>
          {loadingContent}
          <div className={`${className} gloss`} />
        </>
      );
    } else {
      return (
        <>
          {loadingContent} <div className="data-check" />
        </>
      );
    }
  }

  if (error)
    return (
      <div className={className}>
        <p className="error">{error}</p>
        {errorContent}
      </div>
    );

  if (isEmpty)
    return (
      <div className={className}>
        <p className="no-data">{emptyMessage}</p>
        {emptyContent}
      </div>
    );

  return <>{children}</>;
}
