import Button from "@components/form/Button";
import { IoArrowForward } from "react-icons/io5";
import useCigRegisterContext from "../context/useCigRegisterContext";

const JoinDiscordStep = () => {
  const { buttonSize, userExtensionIsLoading } = useCigRegisterContext();

  return (
    <div className="join-discord">
      <iframe
        src="https://discord.com/widget?id=928594807335563285&theme=dark"
        allowTransparency={true}
        frameBorder={0}
        title="Yakkr Discord Widget"
        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
      />

      <div className="button-row">
        <Button
          color="gray-1"
          textColor="white"
          size={buttonSize}
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={userExtensionIsLoading}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default JoinDiscordStep;
