import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";

import {
  IoInformationCircleOutline,
  IoCheckmarkCircleOutline,
  IoWarningOutline,
  IoAlertCircleOutline,
  IoCloseOutline,
} from "react-icons/io5";
import IconButton from "../form/IconButton";
import { SnackbarType } from "@lib/enums/generic";

interface Props {
  type?: SnackbarType;
  timeout?: number;
  icon?: ReactElement;
  onClose?: () => void;
}

const Icons = {
  [SnackbarType.Info]: IoInformationCircleOutline,
  [SnackbarType.Success]: IoCheckmarkCircleOutline,
  [SnackbarType.Warn]: IoWarningOutline,
  [SnackbarType.Error]: IoAlertCircleOutline,
};

export interface SnackbarChildProps {
  onClose?: () => void;
}

export default function Snackbar(props: PropsWithChildren<Props>) {
  const { type = SnackbarType.Info, icon, children, onClose = () => {}, timeout } = props;

  const Icon = Icons[type];

  const [state, setState] = useState(true);

  const triggerClose = () => {
    setState(false);
    setTimeout(onClose, 300);
  };

  useEffect(() => {
    if (timeout != null) setTimeout(triggerClose, timeout - 300);

    // Needs to only run once
    // eslint-disable-next-line
  }, []);

  return (
    <CSSTransition in={state} timeout={300} appear>
      <div className={`snackbar ${type.toLowerCase()}`}>
        {icon ? cloneElement(icon, { className: "type-icon" }) : <Icon className="type-icon" />}
        <div>
          {Children.map(children, child =>
            isValidElement(child) ? cloneElement(child, { onClose }) : child,
          )}
        </div>
        <IconButton
          variant="flat"
          color="white"
          size="small"
          icon={<IoCloseOutline className="close" />}
          onClick={triggerClose}
          className="close-icon"
        />
      </div>
    </CSSTransition>
  );
}
