import { ConsultancyPackageType } from "@api/public/misc/registerConsultancyInterest";
import { Currency } from "@lib/enums/generic";
import Card from "./Card";
import { BsBarChartFill } from "react-icons/bs";
import { IoStar } from "react-icons/io5";
import { RiVipCrown2Fill } from "react-icons/ri";
import { BsShieldFillCheck } from "react-icons/bs";
import Stripe from "@components/brands/Stripe";
import { FaCcAmex, FaCcMastercard, FaCcVisa } from "react-icons/fa";

export const consultancyPackages = {
  [ConsultancyPackageType.Audit]: {
    name: "Audit",
    price: 14.99,
  },
  [ConsultancyPackageType.Basic]: {
    name: "Basic",
    price: 9,
  },
  [ConsultancyPackageType.Standard]: {
    name: "Standard",
    price: 49,
  },
  [ConsultancyPackageType.Premium]: {
    name: "Premium",
    price: 149,
  },
};

interface Props {
  currency?: Currency;
  currencyIsLoading: boolean;
  currencyError?: string;

  onPackageSelect: (pack: ConsultancyPackageType) => void;
}

const Cards = (props: Props) => {
  const { currency, currencyIsLoading, currencyError, onPackageSelect } = props;

  return (
    <section className="pane cards" id="sign-up">
      <div className="inner">
        <h2 className="flow-sub-title text-gradient-faded-horizontal">
          Choose your growth service
        </h2>
        <p className="flow-text sub-text">Each tier includes the benefits of the previous one</p>

        <div className="wrapper">
          <Card
            color="gray-1"
            icon={<BsBarChartFill />}
            currency={currency}
            currencyIsLoading={currencyIsLoading}
            currencyError={currencyError}
            price={consultancyPackages[ConsultancyPackageType.Basic].price}
            title={consultancyPackages[ConsultancyPackageType.Basic].name}
            list={[
              "Access to the Yakkr platform",
              "Educational videos",
              <span>Discord channel access</span>,
              "Collaboration opportunities",
              "Monthly live event featuring a Twitch expert",
            ]}
            onClick={() => onPackageSelect(ConsultancyPackageType.Basic)}
          />
          <Card
            color="purple"
            icon={<IoStar />}
            currency={currency}
            currencyIsLoading={currencyIsLoading}
            currencyError={currencyError}
            price={consultancyPackages[ConsultancyPackageType.Standard].price}
            title={consultancyPackages[ConsultancyPackageType.Standard].name}
            prevText={"Includes 'Basic' perks"}
            list={[
              "Private monthly consultations",
              "Personalised content strategy assistance",
              "Branding and SEO optimisation",
              "Advanced growth strategies",
              "Partnered content",
            ]}
            isMostPopular
            onClick={() => onPackageSelect(ConsultancyPackageType.Standard)}
          />
          <Card
            color="gold"
            icon={<RiVipCrown2Fill />}
            currency={currency}
            currencyIsLoading={currencyIsLoading}
            currencyError={currencyError}
            price={consultancyPackages[ConsultancyPackageType.Premium].price}
            title={consultancyPackages[ConsultancyPackageType.Premium].name}
            prevText={"Includes 'Standard' and 'Basic' perks"}
            list={[
              <span>Mentorship from already established Twitch Partners</span>,
              "'Brand Ready' - helping you to prepare for working with brands and identifying potential deals",
              "Personal Branding - reviewing and improving your personal branding",
            ]}
            onClick={() => onPackageSelect(ConsultancyPackageType.Premium)}
          />
        </div>

        <div className="payment-details">
          <div className="row">
            <p>
              <BsShieldFillCheck className="shield-icon" /> Secure Payment
            </p>
            <p>
              Processed with <Stripe />
            </p>
          </div>

          <p>Risk free - cancel anytime, no questions asked</p>
          <p>We accept all major payment methods:</p>

          <div className="card-row">
            <FaCcVisa />
            <FaCcMastercard />
            <FaCcAmex />
          </div>
        </div>

        <p className="flow-text bottom-text">
          <span>Every dollar spent</span> on growing your Twitch stream has the potential to{" "}
          <span>6.4x within just 18 months</span>. Join our community of Yakkr Growth members
          kickstarting their Twitch growth today.
        </p>
      </div>
    </section>
  );
};

export default Cards;
