import useCombinedRefs from "@hooks/useCombinedRefs";
import useOnIntersection from "@hooks/useOnIntersection";
import { log } from "@lib/utils/generic";
import React, { forwardRef, useCallback, useLayoutEffect, useRef } from "react";
import RoomParticipant from "../objects/RoomParticipant";

interface Props {
  participant?: RoomParticipant;
}

const ParticipantVideo = forwardRef<HTMLVideoElement, Props>((props, forwardedRef) => {
  const { participant } = props;

  const { videoTrack } = participant ?? {};

  const videoRef = useRef<HTMLVideoElement>(null);
  const ref = useCombinedRefs(videoRef, forwardedRef);
  const attachedRef = useRef(false);

  const attach = useCallback(() => {
    const current = videoRef.current;
    if (!current || !videoTrack || attachedRef.current) return;

    attachedRef.current = true;

    current.muted = true;
    videoTrack.attach(current);
    log("Video attached", videoTrack);
  }, [videoTrack]);

  const detach = useCallback(() => {
    const current = videoRef.current;
    if (!current || !videoTrack || !attachedRef.current) return;

    attachedRef.current = false;

    videoTrack.detach(current);
    current.srcObject = null;
    log("Video detached", videoTrack);
  }, [videoTrack]);

  useLayoutEffect(() => {
    attach();
    return () => detach();
  }, [attach, detach]);

  const handleIntersect = useCallback(() => attach(), [attach]);

  useOnIntersection(videoRef, handleIntersect);

  return <video ref={ref} />;
});

export default ParticipantVideo;
