import { ConsultancyPackageType } from "@api/public/misc/registerConsultancyInterest";
import useFormManager from "@components/form/hooks/useFormManager";
import useConsultancySignUpHandles from "./useConsultancySignUpHandles";

const initialValues = {
  email: "",
  twitchUsername: "",
  package: ConsultancyPackageType.Standard,
  terms: false,
};

export type ConsultancySignUpValues = typeof initialValues;

const useConsultancySignUp = () => {
  const formManager = useFormManager(initialValues, "consultancySignUp");

  const { values, validations, onChange, onBlur, onChangeMultiple, resetValidations } = formManager;

  const [signUpHandle, signUpIsLoading] = useConsultancySignUpHandles(formManager);

  return {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles: [signUpHandle],
    handlesIsLoading: [signUpIsLoading],
    resetValidations,
  };
};

export default useConsultancySignUp;
