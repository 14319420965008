import { BrowserRouter } from "react-router-dom";

import Routes from "./Routes";

import "./styles/index.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import GlobalContextProvider from "./globalContext/GlobalContextProvider";

import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import toArray from "dayjs/plugin/toArray";
import customParseFormat from "dayjs/plugin/customParseFormat";

import dayjs from "dayjs";
import { configureAmplify, configureAuth } from "@lib/utils/aws";
import useGoogleAnalytics from "@hooks/useGoogleAnalytics";

import { useState } from "react";
import Input from "@components/form/Input";
import YakkrBanner from "@components/common/YakkrBanner";
import Button from "@components/form/Button";
import useStorageItem from "@hooks/sessionStore/useStorageItem";
import config, { isPreview } from "@config/config";
import { hotjar } from "react-hotjar";
import Layout from "./Layout";

dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(toArray);
dayjs.extend(customParseFormat);

configureAuth();
configureAmplify();

export default function Root() {
  useGoogleAnalytics();
  hotjar.initialize(config.hjid, config.hjsv);

  const { item: authed, set } = useStorageItem("prod-auth", false);

  if (!authed.data && isPreview) return <AuthBox setAuthed={set} />;

  return (
    <>
      <BrowserRouter basename="/">
        <GlobalContextProvider>
          <Layout>
            <Routes />
          </Layout>
        </GlobalContextProvider>
      </BrowserRouter>
    </>
  );
}

const password = "!6745Wobbly/bean@.943";

const AuthBox = ({ setAuthed }: { setAuthed: (authed: boolean) => void }) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState<string | null>(null);

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        inset: 0,
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 32,
          margin: "0 16px",
        }}>
        <YakkrBanner color="black" size="large" />
        <h2 style={{ textAlign: "center" }}>Site Access is Temporarily Locked</h2>
        <form
          id="auth-form"
          style={{ display: "flex", alignItems: "center", gap: 16 }}
          onSubmit={event => {
            event.preventDefault();
            setError(null);
            if (password === value) setAuthed(true);
            else setError("Password incorrect");
          }}>
          <Input
            name="password"
            type="password"
            placeholder="Access Password"
            value={value}
            onChange={({ value }) => setValue(value)}
          />
          <Button type="submit" form="auth-form">
            Unlock
          </Button>
        </form>
        {<p className="text-red text-bold">{error}</p>}
      </div>
    </div>
  );
};
