import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import resendBookingEmail, { ResendBookingEmailRes } from "../resendBookingEmail";

const useResendBookingEmail = (linkingID?: string) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<ResendBookingEmailRes | null>(null);

  const send = useCallback(async () => {
    if (linkingID == null) return reqState;
    setLoading();
    try {
      const data = await resendBookingEmail(linkingID);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [linkingID, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useResendBookingEmail;
