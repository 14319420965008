import { CSSProps } from "@lib/types/generic";

export default function FluentMagicWand(cssProps: CSSProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...cssProps}>
      <path
        d="M37.1228 235.383C35.0064 237.51 32.1317 238.709 29.1311 238.716C26.1305 238.724 23.2498 237.539 21.1228 235.423C18.9958 233.306 17.7966 230.432 17.7891 227.431C17.7816 224.43 18.9664 221.55 21.0828 219.423L159.379 80.4626C161.495 78.3356 164.37 77.1364 167.371 77.1289C170.371 77.1214 173.252 78.3062 175.379 80.4227C177.506 82.5391 178.705 85.4138 178.713 88.4144C178.72 91.4149 177.535 94.2956 175.419 96.4227L37.1228 235.383Z"
        fill="url(#paint0_linear_8219_82466)"
      />
      <path
        d="M37.1228 235.383C35.0064 237.51 32.1317 238.709 29.1311 238.716C26.1305 238.724 23.2498 237.539 21.1228 235.423C18.9958 233.306 17.7966 230.432 17.7891 227.431C17.7816 224.43 18.9664 221.55 21.0828 219.423L159.379 80.4626C161.495 78.3356 164.37 77.1364 167.371 77.1289C170.371 77.1214 173.252 78.3062 175.379 80.4227C177.506 82.5391 178.705 85.4138 178.713 88.4144C178.72 91.4149 177.535 94.2956 175.419 96.4227L37.1228 235.383Z"
        fill="url(#paint1_linear_8219_82466)"
      />
      <g filter="url(#filter0_dd_8219_82466)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M204.16 61.9197C203.845 60.471 203.131 59.1393 202.099 58.0748C201.067 57.0104 199.758 56.2556 198.32 55.8957C194.599 54.8788 190.987 53.4988 187.536 51.7757C186.797 51.4317 186.172 50.8837 185.733 50.1964C185.295 49.509 185.063 48.7108 185.062 47.8957C185.063 47.0806 185.295 46.2824 185.733 45.5951C186.172 44.9077 186.797 44.3598 187.536 44.0157C190.987 42.2954 194.599 40.9181 198.32 39.9037C199.758 39.5438 201.067 38.7891 202.099 37.7246C203.131 36.6602 203.845 35.3284 204.16 33.8797C205.056 29.4237 206.512 23.9197 208.72 18.5437C208.982 17.8001 209.468 17.156 210.111 16.7003C210.755 16.2447 211.523 16 212.312 16C213.1 16 213.869 16.2447 214.513 16.7003C215.156 17.156 215.642 17.8001 215.904 18.5437C218.112 23.9197 219.568 29.4237 220.456 33.8797C220.772 35.328 221.486 36.6593 222.518 37.7236C223.55 38.7879 224.858 39.543 226.296 39.9037C229.998 40.9184 233.592 42.2958 237.024 44.0157C240.32 45.6157 240.32 50.1757 237.024 51.7757C233.594 53.4964 230.003 54.8763 226.304 55.8957C224.866 56.2545 223.556 57.0078 222.523 58.0707C221.49 59.1336 220.774 60.4639 220.456 61.9117C219.421 67.1582 217.894 72.2956 215.896 77.2557C215.632 77.9966 215.146 78.6378 214.504 79.0913C213.861 79.5447 213.094 79.7881 212.308 79.7881C211.522 79.7881 210.754 79.5447 210.112 79.0913C209.47 78.6378 208.983 77.9966 208.72 77.2557C206.722 72.2955 205.195 67.1661 204.16 61.9197Z"
          fill="url(#paint2_linear_8219_82466)"
        />
      </g>
      <g filter="url(#filter1_dd_8219_82466)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.092 58.3761C113.856 57.2927 113.322 56.2968 112.55 55.5009C111.778 54.705 110.799 54.1408 109.724 53.8721C106.944 53.1141 104.246 52.0852 101.668 50.8001C101.116 50.5427 100.649 50.133 100.321 49.6193C99.9941 49.1056 99.8203 48.5092 99.8203 47.9001C99.8203 47.2911 99.9941 46.6947 100.321 46.181C100.649 45.6673 101.116 45.2576 101.668 45.0001C104.636 43.5601 107.284 42.5601 109.724 41.9201C110.798 41.6521 111.777 41.0891 112.548 40.2947C113.32 39.5003 113.855 38.5061 114.092 37.4241C114.764 34.0881 115.852 29.9841 117.5 25.9521C117.696 25.3976 118.06 24.9177 118.541 24.5783C119.021 24.2389 119.595 24.0566 120.184 24.0566C120.772 24.0566 121.346 24.2389 121.826 24.5783C122.307 24.9177 122.671 25.3976 122.868 25.9521C124.516 29.9761 125.604 34.0881 126.268 37.4241C126.505 38.5057 127.04 39.4995 127.812 40.2938C128.583 41.088 129.561 41.6513 130.636 41.9201C133.076 42.5601 135.7 43.5521 138.66 45.0001C139.212 45.2576 139.679 45.6673 140.006 46.181C140.333 46.6947 140.507 47.2911 140.507 47.9001C140.507 48.5092 140.333 49.1056 140.006 49.6193C139.679 50.133 139.212 50.5427 138.66 50.8001C136.092 52.0849 133.405 53.1138 130.636 53.8721C129.56 54.1417 128.582 54.7061 127.81 55.5019C127.038 56.2976 126.504 57.2931 126.268 58.3761C125.604 61.7121 124.516 65.8161 122.868 69.8401C122.671 70.3946 122.307 70.8746 121.826 71.214C121.346 71.5534 120.772 71.7356 120.184 71.7356C119.595 71.7356 119.021 71.5534 118.541 71.214C118.06 70.8746 117.696 70.3946 117.5 69.8401C116.009 66.134 114.871 62.2957 114.1 58.3761H114.092Z"
          fill="url(#paint3_linear_8219_82466)"
        />
      </g>
      <g filter="url(#filter2_dd_8219_82466)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M190.591 146.127C190.355 145.043 189.822 144.047 189.05 143.251C188.278 142.456 187.299 141.891 186.223 141.623C183.44 140.863 180.739 139.831 178.159 138.543C177.609 138.284 177.144 137.875 176.819 137.362C176.493 136.849 176.32 136.254 176.32 135.647C176.32 135.039 176.493 134.444 176.819 133.932C177.144 133.419 177.609 133.009 178.159 132.751C181.135 131.311 183.775 130.311 186.223 129.671C187.299 129.402 188.278 128.838 189.05 128.042C189.822 127.246 190.355 126.25 190.591 125.167C191.255 121.839 192.351 117.727 193.991 113.703C194.186 113.145 194.55 112.663 195.031 112.321C195.513 111.979 196.089 111.796 196.679 111.796C197.269 111.796 197.845 111.979 198.327 112.321C198.808 112.663 199.172 113.145 199.367 113.703C201.015 117.727 202.103 121.839 202.767 125.167C203.003 126.25 203.538 127.245 204.309 128.041C205.081 128.837 206.06 129.401 207.135 129.671C209.567 130.311 212.191 131.303 215.151 132.743C215.705 132.999 216.174 133.409 216.503 133.924C216.832 134.438 217.007 135.036 217.007 135.647C217.007 136.257 216.832 136.855 216.503 137.37C216.174 137.884 215.705 138.294 215.151 138.551C212.586 139.834 209.901 140.863 207.135 141.623C206.06 141.892 205.081 142.457 204.309 143.252C203.538 144.048 203.003 145.044 202.767 146.127C201.992 150.045 200.854 153.883 199.367 157.591C199.172 158.148 198.808 158.631 198.327 158.972C197.845 159.314 197.269 159.498 196.679 159.498C196.089 159.498 195.513 159.314 195.031 158.972C194.55 158.631 194.186 158.148 193.991 157.591C192.5 153.885 191.362 150.046 190.591 146.127Z"
          fill="url(#paint4_linear_8219_82466)"
        />
      </g>
      <path
        d="M230.073 113.543C235.56 113.543 240.009 109.094 240.009 103.607C240.009 98.1194 235.56 93.6709 230.073 93.6709C224.585 93.6709 220.137 98.1194 220.137 103.607C220.137 109.094 224.585 113.543 230.073 113.543Z"
        fill="url(#paint5_radial_8219_82466)"
      />
      <path
        d="M230.073 113.543C235.56 113.543 240.009 109.094 240.009 103.607C240.009 98.1194 235.56 93.6709 230.073 93.6709C224.585 93.6709 220.137 98.1194 220.137 103.607C220.137 109.094 224.585 113.543 230.073 113.543Z"
        fill="url(#paint6_radial_8219_82466)"
      />
      <path
        d="M152.178 178.921C157.666 178.921 162.114 174.472 162.114 168.985C162.114 163.497 157.666 159.049 152.178 159.049C146.691 159.049 142.242 163.497 142.242 168.985C142.242 174.472 146.691 178.921 152.178 178.921Z"
        fill="url(#paint7_radial_8219_82466)"
      />
      <path
        d="M152.178 178.921C157.666 178.921 162.114 174.472 162.114 168.985C162.114 163.497 157.666 159.049 152.178 159.049C146.691 159.049 142.242 163.497 142.242 168.985C142.242 174.472 146.691 178.921 152.178 178.921Z"
        fill="url(#paint8_radial_8219_82466)"
      />
      <path
        d="M96.4229 114.838C101.694 114.838 105.967 110.565 105.967 105.294C105.967 100.023 101.694 95.75 96.4229 95.75C91.1519 95.75 86.8789 100.023 86.8789 105.294C86.8789 110.565 91.1519 114.838 96.4229 114.838Z"
        fill="url(#paint9_radial_8219_82466)"
      />
      <path
        d="M96.4229 114.838C101.694 114.838 105.967 110.565 105.967 105.294C105.967 100.023 101.694 95.75 96.4229 95.75C91.1519 95.75 86.8789 100.023 86.8789 105.294C86.8789 110.565 91.1519 114.838 96.4229 114.838Z"
        fill="url(#paint10_radial_8219_82466)"
      />
      <path
        d="M152.347 32.0229C156.747 32.0229 160.315 28.4555 160.315 24.0549C160.315 19.6543 156.747 16.0869 152.347 16.0869C147.946 16.0869 144.379 19.6543 144.379 24.0549C144.379 28.4555 147.946 32.0229 152.347 32.0229Z"
        fill="url(#paint11_radial_8219_82466)"
      />
      <path
        d="M152.347 32.0229C156.747 32.0229 160.315 28.4555 160.315 24.0549C160.315 19.6543 156.747 16.0869 152.347 16.0869C147.946 16.0869 144.379 19.6543 144.379 24.0549C144.379 28.4555 147.946 32.0229 152.347 32.0229Z"
        fill="url(#paint12_radial_8219_82466)"
      />
      <g filter="url(#filter3_f_8219_82466)">
        <path
          d="M154.627 97.9209L33.3789 219.681"
          stroke="url(#paint13_linear_8219_82466)"
          stroke-width="1.03125"
        />
      </g>
      <path
        d="M145.602 94.3106L159.378 80.4626C161.494 78.3356 164.369 77.1364 167.369 77.1289C170.37 77.1214 173.251 78.3062 175.378 80.4227C177.505 82.5391 178.704 85.4138 178.711 88.4144C178.719 91.4149 177.534 94.2956 175.418 96.4227L161.706 110.199L145.594 94.3106H145.602Z"
        fill="#CFC9D8"
      />
      <path
        d="M145.602 94.3106L159.378 80.4626C161.494 78.3356 164.369 77.1364 167.369 77.1289C170.37 77.1214 173.251 78.3062 175.378 80.4227C177.505 82.5391 178.704 85.4138 178.711 88.4144C178.719 91.4149 177.534 94.2956 175.418 96.4227L161.706 110.199L145.594 94.3106H145.602Z"
        fill="url(#paint14_linear_8219_82466)"
      />
      <path
        d="M145.602 94.3106L159.378 80.4626C161.494 78.3356 164.369 77.1364 167.369 77.1289C170.37 77.1214 173.251 78.3062 175.378 80.4227C177.505 82.5391 178.704 85.4138 178.711 88.4144C178.719 91.4149 177.534 94.2956 175.418 96.4227L161.706 110.199L145.594 94.3106H145.602Z"
        fill="url(#paint15_linear_8219_82466)"
      />
      <path
        d="M145.602 94.3106L159.378 80.4626C161.494 78.3356 164.369 77.1364 167.369 77.1289C170.37 77.1214 173.251 78.3062 175.378 80.4227C177.505 82.5391 178.704 85.4138 178.711 88.4144C178.719 91.4149 177.534 94.2956 175.418 96.4227L161.706 110.199L145.594 94.3106H145.602Z"
        fill="url(#paint16_radial_8219_82466)"
      />
      <g filter="url(#filter4_d_8219_82466)">
        <path
          d="M21.0995 235.401C25.5235 239.825 32.6995 239.817 37.1235 235.385L50.6435 221.801L34.5875 205.849L21.0835 219.425C18.9708 221.547 17.7861 224.421 17.7891 227.416C17.7921 230.411 18.9826 233.282 21.0995 235.401Z"
          fill="#CFC6DD"
        />
        <path
          d="M21.0995 235.401C25.5235 239.825 32.6995 239.817 37.1235 235.385L50.6435 221.801L34.5875 205.849L21.0835 219.425C18.9708 221.547 17.7861 224.421 17.7891 227.416C17.7921 230.411 18.9826 233.282 21.0995 235.401Z"
          fill="url(#paint17_linear_8219_82466)"
        />
      </g>
      <path
        d="M21.0995 235.401C25.5235 239.825 32.6995 239.817 37.1235 235.385L50.6435 221.801L34.5875 205.849L21.0835 219.425C18.9708 221.547 17.7861 224.421 17.7891 227.416C17.7921 230.411 18.9826 233.282 21.0995 235.401Z"
        fill="url(#paint18_linear_8219_82466)"
      />
      <path
        d="M21.0995 235.401C25.5235 239.825 32.6995 239.817 37.1235 235.385L50.6435 221.801L34.5875 205.849L21.0835 219.425C18.9708 221.547 17.7861 224.421 17.7891 227.416C17.7921 230.411 18.9826 233.282 21.0995 235.401Z"
        fill="url(#paint19_radial_8219_82466)"
      />
      <defs>
        <filter
          id="filter0_dd_8219_82466"
          x="183.516"
          y="14.1437"
          width="57.8367"
          height="67.1912"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.309375" dy="-0.309375" />
          <feGaussianBlur stdDeviation="0.773438" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.94902 0 0 0 0 0.513726 0 0 0 0 0.392157 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8219_82466" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.309375" dy="0.309375" />
          <feGaussianBlur stdDeviation="0.61875" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.823529 0 0 0 0 0.407843 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8219_82466"
            result="effect2_dropShadow_8219_82466"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8219_82466"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_8219_82466"
          x="98.2734"
          y="22.2004"
          width="44.0906"
          height="51.0818"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.309375" dy="-0.309375" />
          <feGaussianBlur stdDeviation="0.773438" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.94902 0 0 0 0 0.513726 0 0 0 0 0.392157 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8219_82466" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.309375" dy="0.309375" />
          <feGaussianBlur stdDeviation="0.61875" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.823529 0 0 0 0 0.407843 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8219_82466"
            result="effect2_dropShadow_8219_82466"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8219_82466"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_8219_82466"
          x="174.773"
          y="109.94"
          width="44.0906"
          height="51.1053"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.309375" dy="-0.309375" />
          <feGaussianBlur stdDeviation="0.773438" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.94902 0 0 0 0 0.513726 0 0 0 0 0.392157 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8219_82466" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.309375" dy="0.309375" />
          <feGaussianBlur stdDeviation="0.61875" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.823529 0 0 0 0 0.407843 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8219_82466"
            result="effect2_dropShadow_8219_82466"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8219_82466"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_f_8219_82466"
          x="31.9805"
          y="96.5254"
          width="124.043"
          height="124.551"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.515625" result="effect1_foregroundBlur_8219_82466" />
        </filter>
        <filter
          id="filter4_d_8219_82466"
          x="15.5203"
          y="202.755"
          width="36.9805"
          height="36.9902"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.20625" dy="-1.03125" />
          <feGaussianBlur stdDeviation="1.03125" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.698039 0 0 0 0 0.619608 0 0 0 0 0.792157 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8219_82466" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8219_82466"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8219_82466"
          x1="153.875"
          y1="104.167"
          x2="41.3788"
          y2="219.167"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#60105F" />
          <stop offset="1" stopColor="#54155E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8219_82466"
          x1="44.0028"
          y1="192.543"
          x2="55.7548"
          y2="204.167"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#523253" />
          <stop offset="1" stopColor="#523253" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8219_82466"
          x1="231.848"
          y1="32.8957"
          x2="200.136"
          y2="64.6317"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD459" />
          <stop offset="1" stopColor="#FFA271" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8219_82466"
          x1="134.788"
          y1="36.6801"
          x2="111.084"
          y2="60.4081"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD465" />
          <stop offset="1" stopColor="#FFAF36" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8219_82466"
          x1="211.279"
          y1="124.431"
          x2="187.575"
          y2="148.159"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD465" />
          <stop offset="1" stopColor="#FFAF36" />
        </linearGradient>
        <radialGradient
          id="paint5_radial_8219_82466"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(234.473 99.7029) rotate(127.823) scale(14.9979 14.0275)">
          <stop stopColor="#FFD463" />
          <stop offset="1" stopColor="#E68F2B" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_8219_82466"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(230.073 97.0469) rotate(90) scale(16.4962 14.3802)">
          <stop offset="0.797" stopColor="#E37A42" stopOpacity="0" />
          <stop offset="1" stopColor="#E37A42" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_8219_82466"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(156.586 165.073) rotate(127.823) scale(14.9979 14.0275)">
          <stop stopColor="#FFD463" />
          <stop offset="1" stopColor="#E68F2B" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_8219_82466"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(152.178 162.425) rotate(90) scale(16.4962 14.3802)">
          <stop offset="0.797" stopColor="#E37A42" stopOpacity="0" />
          <stop offset="1" stopColor="#E37A42" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_8219_82466"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(100.65 101.547) rotate(127.823) scale(14.4084 13.4761)">
          <stop stopColor="#FFD463" />
          <stop offset="1" stopColor="#E68F2B" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_8219_82466"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(96.4229 98.99) rotate(90) scale(15.8477 13.8149)">
          <stop offset="0.797" stopColor="#E37A42" stopOpacity="0" />
          <stop offset="1" stopColor="#E37A42" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_8219_82466"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(155.875 20.9189) rotate(127.823) scale(12.0267 11.2485)">
          <stop stopColor="#FFD463" />
          <stop offset="1" stopColor="#E68F2B" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_8219_82466"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(152.347 18.7989) rotate(90) scale(13.228 11.5313)">
          <stop offset="0.869" stopColor="#E37A42" stopOpacity="0" />
          <stop offset="1" stopColor="#E37A42" />
        </radialGradient>
        <linearGradient
          id="paint13_linear_8219_82466"
          x1="148.379"
          y1="106.673"
          x2="33.3789"
          y2="219.673"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#692167" />
          <stop offset="1" stopColor="#5B2460" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_8219_82466"
          x1="150.778"
          y1="88.1666"
          x2="158.794"
          y2="95.7506"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C1BFC3" />
          <stop offset="1" stopColor="#C1BFC3" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_8219_82466"
          x1="171.378"
          y1="103.047"
          x2="166.378"
          y2="97.7986"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0A1BF" />
          <stop offset="1" stopColor="#B0A1BF" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint16_radial_8219_82466"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(174.378 80.9187) rotate(133.939) scale(9.54763 11.5349)">
          <stop offset="0.21" stopColor="#ECE8F2" />
          <stop offset="1" stopColor="#ECE8F2" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint17_linear_8219_82466"
          x1="26.2595"
          y1="212.681"
          x2="34.2115"
          y2="220.625"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#A8A6AD" />
          <stop offset="1" stopColor="#A8A6AD" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_8219_82466"
          x1="20.8675"
          y1="237.921"
          x2="27.1235"
          y2="230.169"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#968FA1" />
          <stop offset="1" stopColor="#968FA1" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint19_radial_8219_82466"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.2115 207.169) rotate(98.4726) scale(38.7984 39.6079)">
          <stop offset="0.661" stopColor="#8F6EAF" stopOpacity="0" />
          <stop offset="0.918" stopColor="#8F6EAF" />
        </radialGradient>
      </defs>
    </svg>
  );
}
