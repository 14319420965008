import DataCheck from "@components/common/DataCheck";
import Divider from "@components/common/Divider";
import Shake from "@components/transitions/Shake";
import { classNames, dateIsToday, getCoordsFromGestureEvent } from "@lib/utils/generic";
import dayjs, { Dayjs } from "dayjs";
import { Fragment, RefObject, useLayoutEffect, useState } from "react";
import CalendarSession from "./CalendarSession";
import { IoChevronForward, IoChevronBack, IoChevronUp, IoChevronDown } from "react-icons/io5";
import useStripeIsSetup from "@hooks/session/useStripeIsSetup";
import { useHistory } from "react-router-dom";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { Session } from "@lib/types/session";
import NoAccessCover from "@components/common/NoAccessCover";

interface SelectionDetails {
  gameName?: string;
  totalSlots?: number;
  exclusive?: boolean;
  charity?: boolean;
  error?: string | null;
  isLoading?: boolean;
}

interface Props {
  periodDate: Dayjs;
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  selecting?: boolean;
  blocksRef?: RefObject<HTMLDivElement>;
  selectError?: string | null;
  selectionDetails?: SelectionDetails;
  overlappingSessionId?: string | null;
  xDirection?: number;
  yDirection?: number;

  fullAvailability?: Session[];
  error?: string | null;
  isLoading?: boolean;

  startSelecting?: (date: dayjs.Dayjs) => void;
  setDates?: (date: dayjs.Dayjs) => void;
  stopSelecting?: (date: dayjs.Dayjs) => void;

  sessionId?: string | null;
  onSessionSelect?: (id: string) => void;
  onSessionCancel?: (id: string) => void;
}

const CalendarManageBody = (props: Props) => {
  const {
    periodDate,
    startDate,
    endDate,
    selecting,
    blocksRef,
    selectError,
    selectionDetails,
    overlappingSessionId,
    xDirection = 0,
    yDirection = 0,

    fullAvailability = [],
    error,
    isLoading,

    startSelecting = () => {},
    setDates = () => {},
    stopSelecting = () => {},

    sessionId,
    onSessionSelect = () => {},
    onSessionCancel = () => {},
  } = props;

  const [initialScrollDone, setInitialScrollDone] = useState(false);

  const history = useHistory();

  const { breakpoints } = useGlobalContext();

  const xSide = xDirection > 0 ? "right" : xDirection < 0 ? "left" : "";
  const ySide = yDirection > 0 ? "bottom" : yDirection < 0 ? "top" : "";

  const stripeIsSetup = useStripeIsSetup();

  const passDate =
    (callback: (date: Dayjs) => void) =>
    (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
      const coords = getCoordsFromGestureEvent(event);
      if (coords == null) return null;

      const { scrollX, scrollY } = window;

      const elements = document.elementsFromPoint(coords.x - scrollX, coords.y - scrollY);
      const session = elements.find(({ classList }) => classList.contains("session"));
      if (session != null && !session.classList.contains("selecting")) return null;
      const element = elements.find(
        ({ classList }) =>
          classList.contains("cell") &&
          !classList.contains("spacer") &&
          !classList.contains("in-past"),
      );
      if (element == null) return null;

      const date = dayjs(element.id);
      if (!date.isValid()) return null;

      callback(date);
    };

  useLayoutEffect(() => {
    if (initialScrollDone) return;
    const current = blocksRef?.current;
    if (isLoading || error != null || current == null) return;

    const session = fullAvailability.find(({ id }) => id === sessionId);

    if (sessionId != null && session == null) return;

    const date = dayjs(session?.from ?? undefined);

    const scrollY = getScrollY(date);
    if (scrollY === 0) return;

    current.scrollTo({
      top: scrollY,
    });
    setInitialScrollDone(true);
  }, [
    sessionId,
    fullAvailability,
    isLoading,
    error,
    blocksRef,
    initialScrollDone,
    setInitialScrollDone,
  ]);

  return (
    <Shake in={selectError != null}>
      <div className="calendar-body calendar-manage-body">
        <div className="dates">
          <div className="row">
            <div className="cell spacer" />
            {Array(breakpoints.md ? 1 : 7)
              .fill(null)
              .map((_, i) => {
                const date = periodDate.add(i, "day");
                return (
                  <div key={i} className={`cell ${dateIsToday(date) ? "today" : ""}`}>
                    <p className="date">{date.format("DD")}</p>
                    <p className="day">{periodDate.add(i, "day").format("ddd")}</p>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="blocks-wrapper">
          <div className={`indicator x ${xSide}`}>
            {xSide === "right" && <IoChevronForward />}
            {xSide === "left" && <IoChevronBack />}
          </div>
          <div className={`indicator y ${ySide}`}>
            {ySide === "top" && <IoChevronUp />}
            {ySide === "bottom" && <IoChevronDown />}
          </div>

          <div
            ref={blocksRef}
            className={classNames(
              "blocks",
              selecting && "selecting",
              isLoading && "loading",
              !stripeIsSetup && "no-access",
            )}
            onMouseDown={passDate(startSelecting)}
            onMouseMove={passDate(setDates)}
            onMouseUp={passDate(stopSelecting)}
            onTouchStart={passDate(startSelecting)}
            onTouchMove={passDate(setDates)}
            onTouchEnd={passDate(stopSelecting)}>
            <DataCheck error={error}>
              {fullAvailability.map(
                ({
                  from,
                  to,
                  id,
                  game,
                  slotsAvailable,
                  maxSlots,
                  exclusive,
                  charity,
                  private: privateSession,
                }) => (
                  <CalendarSession
                    key={id}
                    id={id}
                    startDate={dayjs(from)}
                    endDate={dayjs(to)}
                    gameName={game.shortName}
                    remainingSlots={slotsAvailable}
                    totalSlots={maxSlots}
                    exclusive={exclusive}
                    charity={charity}
                    private={privateSession}
                    periodDate={periodDate}
                    overlapping={id === overlappingSessionId}
                    selected={id === sessionId}
                    onEdit={() => onSessionSelect(id)}
                    onJoin={() => history.push(`/bookings/room/${id}?type=cig`)}
                    onCancel={() => onSessionCancel(id)}
                  />
                ),
              )}
              {startDate && endDate && (
                <CalendarSession
                  startDate={startDate}
                  endDate={endDate}
                  periodDate={periodDate}
                  selecting={selecting}
                  creating={!selecting}
                  {...selectionDetails}
                  error={selectError ?? selectionDetails?.error}
                />
              )}
              {Array(97)
                .fill(null)
                .map((_, i) => {
                  const time = periodDate.add(15 * i, "minutes");

                  return (
                    <Fragment key={i}>
                      <div className="row divider">
                        <p className="time">{time.format("HH:mm A")}</p>
                      </div>

                      <div className="row">
                        <div className="cell spacer" />
                        {Array(breakpoints.md ? 1 : 7)
                          .fill(null)
                          .map((_, ii) => {
                            if (i === 96) return null;

                            const date = time.add(ii, "day");
                            const isInPast = date.isBefore(dayjs().add(15, "minutes"));

                            return (
                              <div
                                key={ii}
                                className={`cell ${isInPast ? " in-past" : ""} ${
                                  i === 0 ? "first" : ""
                                }`}
                                id={date.format()}
                              />
                            );
                          })}
                      </div>
                    </Fragment>
                  );
                })}
            </DataCheck>
          </div>

          {!stripeIsSetup && (
            <NoAccessCover
              body="You must setup your Stripe account to create sessions"
              buttonProps={{ to: "/settings/statuses" }}
              buttonText="Check Statuses"
            />
          )}
        </div>

        <div className="footer-prompt">
          <Divider color="gray-3" />
          <p>Click and drag to create a session</p>
        </div>
      </div>
    </Shake>
  );
};

export default CalendarManageBody;

const getScrollY = (date: Dayjs = dayjs()) => {
  const cell = document.querySelector(".blocks .cell:not(.spacer)");
  const cellHeight = cell?.clientHeight ?? 0;

  const minutes = date.diff(date.startOf("day"), "minutes");

  const scrollY = Math.floor(minutes / 15) * cellHeight;

  return scrollY;
};
