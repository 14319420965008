import { classNames } from "@lib/utils/generic";
import React, { PropsWithChildren } from "react";

interface Props {
  orientation?: "horizontal" | "vertical";
}

export default function FormGroup(props: PropsWithChildren<Props>) {
  const { orientation = "vertical", children } = props;

  return <div className={classNames("form-group", orientation)}>{children}</div>;
}
