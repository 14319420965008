import Tabs from "@components/common/Tabs";
import Button from "@components/form/Button";
import Form from "@components/form/Form";
import GradientButton from "@components/form/GradientButton";
import React from "react";
import { IoArrowForward } from "react-icons/io5";
import useRequestExperienceContext from "./context/useRequestExperienceContext";

export default function ExperiencesRequestMobile() {
  const {
    validations,
    createRequest,
    resetValidations,
    tabs,
    selectedTab,
    setSelectedTab,
    nextTab,
    goToNextTab,
    createIsLoading,
  } = useRequestExperienceContext();

  return (
    <div id="experiences-request-page" className="mobile">
      <Tabs
        size="small"
        variant="contained"
        color="black-1"
        textColor="gray-2"
        indicatorColor="black-4"
        indicatorTextColor="white"
        rounded
        tabs={tabs}
        selectedTab={selectedTab}
        onChange={setSelectedTab}
      />
      <Form
        id="request-experience"
        onCompletion={createRequest}
        resetValidations={resetValidations}
        validation={validations.generic}>
        {tabs.find(tab => tab?.value === selectedTab)?.component}
      </Form>

      <div className="flow-button-wrapper">
        {nextTab ? (
          <Button
            variant="contained"
            size="medium"
            color="purple"
            textColor="white"
            endIcon={<IoArrowForward />}
            justifyContent="center"
            onClick={goToNextTab}>
            Next
          </Button>
        ) : (
          <GradientButton
            gradient="gradient-blend-horizontal"
            size="medium"
            textColor="white"
            endIcon={<IoArrowForward />}
            justifyContent="center"
            type="submit"
            form="request-experience"
            animated
            glow
            animationOrientation="horizontal"
            isLoading={createIsLoading}>
            Checkout
          </GradientButton>
        )}
      </div>
    </div>
  );
}
