import { api, APIResponsePaginated } from "@api/api";
import { Session } from "@lib/types/session";

const getCigNextAvailability = async (cigID: string, userID?: string, nextToken?: string) => {
  const { data } = await api.get<APIResponsePaginated<Session>>("/nextAvailability", {
    params: { cigID, userID, nextToken },
  });
  return data;
};

export default getCigNextAvailability;
