import BackgroundImage from "@components/common/BackgroundImage";
import Button from "@components/form/Button";
import { IoArrowForward } from "react-icons/io5";
import CigProfileTitle from "../CigProfileTitle";
import controllerSplashImg from "@images/misc/controllers_splash.png";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import DataCheck from "@components/common/DataCheck";
import useCigProfileContext from "../context/hooks/useCigProfileContext";

const Ready = () => {
  const { breakpoints } = useGlobalContext();

  const { getIsLoading, cigProfile } = useCigProfileContext();

  return (
    <section className="section ready">
      <div className="box">
        <CigProfileTitle>
          <DataCheck isLoading={getIsLoading} loadingContent={<>&nbsp;</>} loadingIndicator="gloss">
            Ready to game with {cigProfile?.displayName}?
          </DataCheck>
        </CigProfileTitle>

        <Button
          size={breakpoints.md ? "large" : "extra-large"}
          color="purple"
          textColor="white"
          endIcon={<IoArrowForward />}
          href="#experiences">
          Book now
        </Button>

        <BackgroundImage src={controllerSplashImg} opacity={1} />
      </div>
    </section>
  );
};

export default Ready;
