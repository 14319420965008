import { UseFormHandles } from "@components/form/hooks/useFormManager";
import useRoomContext from "@pages/bookings/room/roomContext/hooks/useRoomContext";
import { MessageBoxValues } from "../MessageBox";
import useSendMessage from "./useSendChatRoomMessage";

const useMessageBoxHandles: UseFormHandles<MessageBoxValues> = ({
  values,
  validateField,
  validateCustom,
}) => {
  const { chatRoom } = useRoomContext();

  const { isLoading, send: sendMessage } = useSendMessage(chatRoom, values.message);

  const handle = async () => {
    let failed = validateField("message");

    if (!failed) {
      const { error } = await sendMessage();

      if (error) {
        failed = validateCustom("message", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }
    }

    return failed;
  };

  return [handle, isLoading];
};

export default useMessageBoxHandles;
