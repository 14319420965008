import { Dayjs } from "dayjs";
import React from "react";
import useRoomContext from "../../roomContext/hooks/useRoomContext";

interface Props {
  body?: string | null;
  authorIdentity?: string | null;
  dateCreated?: Dayjs;
}

export default function Message(props: Props) {
  const { body, authorIdentity, dateCreated } = props;

  const { localIdentity, participants, viewParticipant } = useRoomContext();

  const author = participants.find(({ identity }) => identity === authorIdentity);

  const attributes = author?.chatParticipant?.attributes;

  const isOwn = localIdentity === authorIdentity;

  return (
    <div className={`message ${isOwn ? "is-own" : "not-own"}`}>
      <div className="message-top">
        <p
          className="message-author"
          onClick={() => {
            if (authorIdentity) viewParticipant(authorIdentity);
          }}>
          {attributes?.displayName ?? "Unknown"}
        </p>
        <p className="message-time">{dateCreated?.format("HH:mm")}</p>
      </div>
      <p className="message-body">{body}</p>
    </div>
  );
}
