import { CigFullCustomExperience } from "@api/private/get/getCigFullCustomExperience";
import useGetCigFullCustomExperience from "@api/private/get/hooks/useGetCigFullCustomExperience";
import { Game } from "@api/public/get/getGames";
import useGetGames from "@api/public/get/hooks/useGetGames";
import {
  ClearValidation,
  FormValidations,
  OnFormBlur,
  OnFormChange,
  ResetValidations,
} from "@components/form/hooks/useFormManager";
import React, { createContext, PropsWithChildren, useEffect } from "react";
import { useParams } from "react-router-dom";
import useExperiencePackageUpdate, {
  UpdateExperienceCustomValues,
} from "../hooks/useExperienceCustomUpdate";

interface UpdateExperienceCustomContextType {
  cigId: string;

  values: UpdateExperienceCustomValues;
  validations: FormValidations<UpdateExperienceCustomValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;

  updateIsLoading: boolean;

  update: () => Promise<void>;
  resetValidations: ResetValidations;
  clearValidation: ClearValidation<keyof UpdateExperienceCustomValues>;

  fullCustomExperience: CigFullCustomExperience | null;
  fullCustomExperienceIsLoading: boolean;
  fullCustomExperienceError?: string;

  games: Game[];
  gamesIsLoading: boolean;
  gamesError?: string;
}

export const UpdateExperienceCustomContext = createContext<UpdateExperienceCustomContextType>(
  {} as UpdateExperienceCustomContextType,
);

export default function CIGProfileContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const { cigId } = useParams<Params>();

  const {
    data: fullCustomExperience,
    isLoading: fullCustomExperienceIsLoading,
    error: fullCustomExperienceError,
  } = useGetCigFullCustomExperience(true);

  const { data: games, isLoading: gamesIsLoading, error: gamesError } = useGetGames(true);

  const {
    values,
    validations,

    onChange,
    onBlur,

    updateIsLoading,

    update,
    resetValidations,
    clearValidation,
    setValues,
  } = useExperiencePackageUpdate();

  useEffect(() => {
    if (!fullCustomExperience) return;

    setValues(values => ({ ...values, ...fullCustomExperience }));
  }, [fullCustomExperience, setValues]);

  if (cigId === "my-profile") return null;

  return (
    <UpdateExperienceCustomContext.Provider
      value={{
        cigId,

        values,
        validations,

        onChange,
        onBlur,

        updateIsLoading,

        update,
        resetValidations,
        clearValidation,

        fullCustomExperience,
        fullCustomExperienceIsLoading,
        fullCustomExperienceError,

        games,
        gamesIsLoading,
        gamesError,
      }}>
      {children}
    </UpdateExperienceCustomContext.Provider>
  );
}

interface Params {
  cigId: string;
}
