import HowItWorksSplash from "@components/splash/sections/HowItWorksSplash";
import { FaBrain, FaFileContract } from "react-icons/fa";
import { IoBag, IoSparkles } from "react-icons/io5";

const HowItWorks = () => {
  return (
    <HowItWorksSplash
      steps={[
        {
          icon: <FaFileContract />,
          title: "Sign up with your Twitch handle",
        },
        {
          icon: <FaBrain />,
          title: "Our AI system will automatically create your profile & set your rates",
        },
        {
          icon: <IoBag />,
          title: "Choose the types of goods & services you are interested in promoting",
        },
        {
          icon: <IoSparkles />,
          title: "Brand deals will be sent through individual offers or part of a campaign",
        },
      ]}
    />
  );
};

export default HowItWorks;
