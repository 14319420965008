import AltAuthBar from "@components/common/AltAuthBar";
import Divider from "@components/common/Divider";
import { classNames } from "@lib/utils/generic";
import React, { PropsWithChildren, ReactNode } from "react";

interface Props {
  title: ReactNode;
  isLoading: boolean;
  noAltAuth?: boolean;
}

export default function AuthWrapper(props: PropsWithChildren<Props>) {
  const { title, isLoading, noAltAuth, children } = props;

  return (
    <div className="auth-wrapper">
      <div className={classNames("header-wrapper", noAltAuth && "no-alt-auth")}>
        <h1 className="title">{title}</h1>
        {!noAltAuth && <AltAuthBar disabled={isLoading} />}
      </div>

      <div className="or-divider">
        <Divider color="black-3" />
        {!noAltAuth && <p>OR</p>}
      </div>

      <div className="content-wrapper">{children}</div>
    </div>
  );
}
