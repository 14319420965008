import FormModal from "@components/modals/FormModal";
import Form from "@components/form/Form";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import useFormManager from "@components/form/hooks/useFormManager";
import Input from "@components/form/Input";
import { privacyUrl } from "@lib/constants/generic";
import React, { useCallback } from "react";
import useFreeSessionHandles from "./hooks/useFreeSessionHandles";

interface Props {
  active?: boolean;

  onClose?: () => void;
  onCompletion?: (email: string) => void;
}

const initialValues = {
  email: "",
};

export type FreeSessionValues = typeof initialValues;

export default function FreeSessionModal(props: Props) {
  const { active, onClose = () => {}, onCompletion = () => {} } = props;

  const formManager = useFormManager(initialValues, "shareTicket");

  const { values, validations, onBlur, onChange, resetValidations, setValues } = formManager;

  const [handle] = useFreeSessionHandles(formManager);

  const handleCompletion = useCallback(() => {
    onCompletion(values.email);
    setValues({ ...initialValues });
    onClose();
  }, [values.email, onCompletion, onClose, setValues]);

  return (
    <FormModal
      active={active}
      form="free-session"
      onClose={onClose}
      title="We need your email"
      subTitle="In order to purchase a free session as a guest, you need to provide an email address."
      confirmButtonText="Submit">
      <Form
        id="free-session"
        handle={handle}
        validation={validations.generic}
        resetValidations={resetValidations}
        onCompletion={handleCompletion}
        resetOnCompletion>
        <FormRow>
          <FormEntry label="Email" validation={validations.email} showValidationBeneath>
            <Input
              type="email"
              name="email"
              placeholder="example@email.com"
              value={values.email}
              size="medium"
              onBlur={onBlur}
              onChange={onChange}
            />
          </FormEntry>
        </FormRow>

        <FormRow>
          <p style={{ fontSize: 13 }}>
            Email usage subject to our{" "}
            <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
          </p>
        </FormRow>
      </Form>
    </FormModal>
  );
}
