import useConfirmEmail from "@api/auth/hooks/useConfirmEmail";
import useLogin from "@api/auth/hooks/useLogin";
import useRegister, { RegisterError } from "@api/auth/hooks/useRegister";
import useCreateUserExtension from "@api/private/create/hooks/useCreateUserExtension";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/utils/generic";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { AuthState } from "@lib/enums/generic";
import { RegisterValues } from "./useRegister";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import { RegisterModalData } from "../context/RegisterContextProvider";

const useRegisterHandles: UseFormHandles<RegisterValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { pushSnackbar, getUser, setAuthState, setAuthModal } = useGlobalContext();

  const { email, redirect } = useAuthModalData<RegisterModalData>();

  const { send: register, isLoading: registerIsLoading } = useRegister(
    values.email,
    values.password,
  );
  const { send: confirmEmail, isLoading: emailConfirmIsLoading } = useConfirmEmail(
    values.email,
    values.emailConfirmCode,
  );
  const { send: login, isLoading: loginIsLoading } = useLogin(values.email, values.password, true);
  const { send: createUserExtension, isLoading: userExtensionIsLoading } = useCreateUserExtension(
    values.firstName,
    values.surname,
    values.displayName,
    values.dob.format("YYYY-MM-DD"),
  );

  const registerHandle = async () => {
    setAuthState(AuthState.CigRegistering);

    let failed = validateFields(["email", "password", "confirmPassword", "terms"]);

    if (!failed) {
      failed = validateCustom("password", validation => {
        if (values.password !== values.confirmPassword) {
          validation.list.push({
            text: "Passwords do not match",
            type: "error",
          });
        }
      });
    }

    if (!failed) {
      const { error } = await register();

      if (error) {
        failed = true;

        switch (error.toString()) {
          case RegisterError.EmailTaken.toString():
            validateCustom("email", validation => {
              validation.list.push({
                text: "Email is already taken",
                type: "error",
              });
            });
            break;
          default:
            validateCustom("generic", validation => {
              validation.list.push({
                text: error,
                type: "error",
              });
            });
            break;
        }
      }
    }

    return failed;
  };

  const emailConfirmHandle = async () => {
    setAuthState(AuthState.CigRegistering);

    let failed = validateFields(["emailConfirmCode"]);

    if (!failed) {
      const { error } = await confirmEmail();

      if (error) {
        failed = true;

        validateCustom("emailConfirmCode", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    if (!failed) {
      if (values.email.length === 0 || values.password.length === 0) {
        setAuthModal("login", { email, redirect });
        pushSnackbar(createSuccessSnackbar("Email confirmed, please log in again", 3000));
      }

      const { error } = await login();
      if (error) {
        failed = true;

        validateCustom("emailConfirmCode", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    return failed;
  };

  const userExtensionHandle = async () => {
    setAuthState(AuthState.CigRegistering);

    let failed = validateFields(["firstName", "surname", "displayName", "dob"]);

    if (!failed) {
      const { error } = await createUserExtension();

      if (error) {
        failed = true;

        validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }

      if (!error) await getUser();
    }

    return failed;
  };

  return [
    registerHandle,
    emailConfirmHandle,
    userExtensionHandle,

    registerIsLoading,
    emailConfirmIsLoading || loginIsLoading,
    userExtensionIsLoading,
  ];
};

export default useRegisterHandles;
