import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import bindUserToTickets, { BindUserToTicketsRes } from "../bindUserToTickets";

const useBindUserToTickets = (linkingID?: string) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<BindUserToTicketsRes | null>(null);

  const send = useCallback(async () => {
    if (linkingID == null) return reqState;
    setLoading();
    try {
      const data = await bindUserToTickets(linkingID);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [linkingID, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useBindUserToTickets;
