import { api } from "@api/api";

export interface OnboardingLinkObject {
  link: {
    object: string;
    created: number;
    expires_at: number;
    url: string;
  };
}

const createOnboardingLink = async () => {
  const { data } = await api.post<OnboardingLinkObject>("/createOnboardingLink");
  return data;
};

export default createOnboardingLink;
