import { ConsultancyPackageType } from "@api/public/misc/registerConsultancyInterest";
import {
  FormValidations,
  OnFormBlur,
  OnFormChange,
  OnFormChangeMultiple,
  ResetValidations,
} from "@components/form/hooks/useFormManager";
import { FormHandle } from "@lib/types/form";
import { createContext, PropsWithChildren, useEffect, useState } from "react";
import useConsultancySignUp, { ConsultancySignUpValues } from "../hooks/useConsultancySignUp";

interface ConsultancySignUpContextType {
  values: ConsultancySignUpValues;
  validations: FormValidations<ConsultancySignUpValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;
  onChangeMultiple: OnFormChangeMultiple;

  handles: FormHandle[];
  resetValidations: ResetValidations;

  signUpIsLoading: boolean;

  step: number;
  setStep: (step: number) => void;

  onClose: () => void;
}

export const ConsultancySignUpContext = createContext<ConsultancySignUpContextType>(
  {} as ConsultancySignUpContextType,
);

interface Props {
  selectedPackage?: ConsultancyPackageType;
  onClose: () => void;
}

export default function ConsultancySignUpContextProvider(props: PropsWithChildren<Props>) {
  const { selectedPackage, onClose, children } = props;

  const [step, setStep] = useState(0);

  const {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles,
    handlesIsLoading,
    resetValidations,
  } = useConsultancySignUp();

  const [signUpIsLoading] = handlesIsLoading;

  useEffect(() => {
    onChange({
      name: "package",
      value: selectedPackage,
    });
    // eslint-disable-next-line
  }, [selectedPackage]);
  // must omit onChange to prevent loop

  return (
    <ConsultancySignUpContext.Provider
      value={{
        values,
        validations,

        onChange,
        onBlur,
        onChangeMultiple,

        handles,
        resetValidations,

        signUpIsLoading,

        step,
        setStep,

        onClose,
      }}>
      {children}
    </ConsultancySignUpContext.Provider>
  );
}
