import { useEffect, useState } from "react";
import { arraysEqual, log } from "../lib/utils/generic";
import usePrevious from "./usePrevious";

export default function useImagesLoaded(srcs: string[]) {
  const [loaded, setLoaded] = useState(false);

  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  const [failedImages, setFailedImages] = useState<string[]>([]);

  const prevSrcs = usePrevious(srcs);

  useEffect(() => {
    if (prevSrcs != null && arraysEqual(prevSrcs, srcs)) return;

    setLoaded(false);
    if (srcs.length === 0) return setLoaded(true);

    if (loadedImages.length > 0) setLoadedImages([]);
    if (failedImages.length > 0) setFailedImages([]);

    srcs.forEach(src => {
      const image = new Image();
      image.onload = () => {
        setLoadedImages(loadedImages => [...loadedImages, src]);
      };
      image.onerror = () => {
        setFailedImages(failedImages => [...failedImages, src]);
        log("Failed to load image ", src);
      };
      image.src = src;
    });
  }, [srcs, prevSrcs, loadedImages, failedImages]);

  useEffect(() => {
    if (srcs.length === 0) return;
    log(`Loaded images ${loadedImages.length} / ${srcs.length} (${failedImages.length} failed)`);
    const processed = loadedImages.length + failedImages.length;
    if (processed >= srcs.length) setLoaded(true);
    // eslint-disable-next-line
  }, [loadedImages.length, failedImages.length]);
  // don't want scrs in here as it causes the check to occurr too soon

  return { loaded, loadedImages, failedImages };
}
