import Button, { ButtonProps } from "@components/form/Button";
import { JustifyContent, UIColor } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Pill from "./Pill";

export type Tab = {
  value: string | number;
  label: string;
  component?: ReactNode;
  count?: number;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
};

interface Props {
  color?: UIColor;
  textColor?: UIColor;
  indicatorColor?: UIColor;
  indicatorTextColor?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent" | "underlined";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";

  justifyContent?: JustifyContent;
  rounded?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  disableHover?: boolean;

  selectedTab?: string | number;
  tabs?: Array<Tab | undefined>;

  tabWidth?: number;

  onChange?: (id: string | number) => void;
}

export type TabsProps = Props;

export default function Tabs(props: Props) {
  const {
    color = "white",
    textColor = "black",
    indicatorColor = "black",
    indicatorTextColor = "white",
    variant = "contained",
    size = "medium",

    justifyContent = "space-between",
    rounded,
    disabled,
    readOnly,
    disableHover,

    selectedTab,

    onChange = () => {},
  } = props;

  const tabs = (props.tabs ?? []).filter(tab => !!tab) as Tab[];

  const ref = useRef<HTMLTableElement>(null);

  const [indicatorLeft, setIndicatorLeft] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);

  const selectedIndex = tabs.findIndex(tab => tab.value === selectedTab);

  const tabWidth = props.tabWidth ?? indicatorWidth;

  const tabWidthStyle = tabWidth != null ? `${tabWidth}px` : "unset";
  let indicatorTransformStyle = `translateX(${indicatorLeft}px)`;

  useEffect(() => {
    const current = ref.current;
    if (current == null) return;
    current.scroll({ left: indicatorLeft, behavior: "smooth" });
  }, [indicatorLeft, ref]);

  useLayoutEffect(() => {
    const { current } = ref;
    if (!current) return;

    const tabElement = current.children
      .item(0)
      ?.children.item(0)
      ?.children.item(selectedIndex) as HTMLButtonElement | null;

    tabElement?.click();
  }, [selectedIndex]);

  const buttonVariant = {
    contained: "contained",
    outlined: "outlined",
    flat: "flat",
    transparent: "flat",
    underlined: "flat",
  }[variant] as ButtonProps["variant"];

  const pillVariant = {
    contained: "contained",
    outlined: "outlined",
    flat: "flat",
    transparent: "transparent",
    underlined: "flat",
  }[variant] as ButtonProps["variant"];

  return (
    <div className={classNames("tabs", size, variant, color, rounded && "rounded")} ref={ref}>
      <div className="tabs-wrapper">
        <div className="tabs-inner">
          {tabs.map(({ value, label, startIcon, endIcon, count }) => {
            const selected = selectedTab === value;

            return (
              <Button
                key={value}
                color={color}
                textColor={selected ? indicatorTextColor : textColor}
                variant={buttonVariant}
                size={size}
                startIcon={startIcon}
                endIcon={endIcon}
                justifyContent={justifyContent}
                rounded={rounded}
                disabled={disabled}
                readOnly={readOnly}
                disableHover={disableHover}
                className={classNames("tab", selected && "selected")}
                style={{
                  width: props.tabWidth,
                  minWidth: props.tabWidth,
                }}
                onClick={({ currentTarget }) => {
                  onChange(value);

                  const { offsetLeft, clientWidth } = currentTarget;

                  setIndicatorLeft(offsetLeft - 1);
                  setIndicatorWidth(clientWidth);
                }}>
                {label}
              </Button>
            );
          })}
          <Pill
            color={indicatorColor}
            textColor={indicatorTextColor}
            variant={pillVariant}
            size={size}
            justifyContent={justifyContent}
            rounded={rounded}
            className={classNames("indicator", indicatorColor)}
            style={{
              width: tabWidthStyle,
              transform: indicatorTransformStyle,
            }}>
            {variant === "flat" && <div className="indicator-circle" />}
            {variant === "underlined" && <div className="indicator-underline" />}
          </Pill>
        </div>
      </div>
    </div>
  );
}
