import Divider from "@components/common/Divider";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import React, { ReactNode } from "react";
import BecomeTalentOldHeader from "../BecomeTalentOldHeader";

import documentIcon from "@images/becomeTalentOld/icons/document.svg";
import personIcon from "@images/becomeTalentOld/icons/person.svg";
import starIcon from "@images/becomeTalentOld/icons/star.svg";
import { Link } from "react-router-dom";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

export default function How() {
  const { breakpoints } = useGlobalContext();

  return (
    <section className="section how no-padding">
      <BecomeTalentOldHeader
        title="How to get started on Yakkr"
        subTitle="Follow these 3 simple steps and start offering exciting gaming experiences to your community and loyal supporters."
        center
      />

      <div className="steps-wrapper">
        {!breakpoints.md && <Divider color="gray-1" className="number-line" />}

        <Step
          timeout={500}
          number={1}
          icon={documentIcon}
          title="Submit your details"
          content={
            <>
              Get started by <Link to="/cig/register">signing up</Link> with your email
            </>
          }
        />

        <Step
          timeout={700}
          number={2}
          icon={personIcon}
          title="Fill out your profile"
          content={
            <>
              Whilst we verify your information, set up your profile and create your experience
              bundles.
            </>
          }
        />

        <Step
          timeout={800}
          number={3}
          icon={starIcon}
          title="Accepted - let's go!"
          content={
            <>
              Now verified, you’re ready to set up some sessions and get playing with your
              community!
            </>
          }
        />
      </div>
    </section>
  );
}

interface StepProps {
  timeout: number;
  number: number;
  icon: string;
  title: string;
  content: ReactNode;
}

const Step = ({ timeout, number, icon, title, content }: StepProps) => (
  <SlideOnIntersect direction="left" timeout={timeout}>
    <div className="step">
      <div className="diamond">
        <p className="number">{number}</p>
      </div>

      <img src={icon} alt="Icon" className="icon" />

      <div className="content-wrapper">
        <h3 className="title">{title}</h3>
        <p className="text">{content}</p>
      </div>
    </div>
  </SlideOnIntersect>
);
