import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import cigKickParticipant, { CIGKickParticipantRes } from "../cigKickParticipant";

const useCIGKickParticipant = (availabilityID?: string, chatParticipantID?: string) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CIGKickParticipantRes | null>(null);

  const send = useCallback(async () => {
    if (availabilityID == null || chatParticipantID == null) return reqState;
    setLoading();
    try {
      const data = await cigKickParticipant(availabilityID, chatParticipantID);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [availabilityID, chatParticipantID, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCIGKickParticipant;
