import React from "react";
import { OverlayAlertProps } from "../../hooks/useCigOverlayAlerts";
import TicketIcon from "../icons/TicketIcon";
import OverlayAlert from "../OverlayAlert";

interface Props extends OverlayAlertProps {
  displayName: string;
}

export default function PurchasedSessionAlert(props: Props) {
  const { displayName, ...alertProps } = props;

  return (
    <OverlayAlert
      {...alertProps}
      icon={<TicketIcon />}
      title={displayName}
      subTitle="just secured their ticket!"
    />
  );
}
