import { Option } from "@components/form/Options";
import Search, { SearchProps } from "@components/form/Search";
import { JustifyContent, UIColor } from "@lib/types/generic";
import { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { Group, Showcase } from "./StyleGuide";

const Searches = () => {
  const sizes = ["extra-small", "small", "medium", "large", "extra-large"] as SearchProps["size"][];

  const [value, setValue] = useState("");
  const [color, setColor] = useState<UIColor>("purple");
  const [textColor, setTextColor] = useState<UIColor>("white");
  const [resultColor, setResultColor] = useState<UIColor>("purple");
  const [resultTextColor, setResultTextColor] = useState<UIColor>("white");
  const [variant, setVariant] = useState<SearchProps["variant"]>("contained");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [justifyContent, setJustifyContent] = useState<JustifyContent>("space-between");
  const [rounded, setRounded] = useState<boolean>(false);

  const results: Option[] = new Array(10).fill(null).map((_, i) => ({
    value: `result-${i + 1}`,
    label: `Result ${i + 1}`,
    startIcon: <IoArrowBack />,
  }));

  const props = {
    value,
    onChange: ({ value }: { value: string }) => setValue(value),
    color,
    textColor,
    resultColor,
    resultTextColor,
    variant,
    placeholder: "Search",
    disabled,
    isLoading,
    justifyContent,
    rounded,
    results,
    autoFilter: true,
  };

  return (
    <Showcase
      title="Searches"
      className="searches"
      colors={[
        {
          color: color,
          label: "Color",
          onChange: setColor,
        },
        {
          color: textColor,
          label: "Text Color",
          onChange: setTextColor,
        },
        {
          color: resultColor,
          label: "Result Color",
          onChange: setResultColor,
        },
        {
          color: resultTextColor,
          label: "Result Text Color",
          onChange: setResultTextColor,
        },
      ]}
      selects={[
        {
          value: variant ?? "contained",
          optionsArray: ["contained", "outlined", "flat", "transparent"],
          label: "Variant",
          onChange: setVariant,
        },
        {
          value: justifyContent,
          optionsArray: ["space-between", "flex-start", "center", "flex-end"],
          label: "Justify",
          onChange: setJustifyContent,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
        {
          value: isLoading,
          label: "Is loading",
          onChange: setIsLoading,
        },
        {
          value: rounded,
          label: "Rounded",
          onChange: setRounded,
        },
      ]}>
      {sizes.map(size => (
        <Group key={size}>
          <Search size={size} {...props} />
        </Group>
      ))}
    </Showcase>
  );
};

export default Searches;
