import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createReviewMessage, { CreateReviewMessageRes } from "../createReviewMessage";

const useCreateReviewMessage = (
  availabilityID?: string,
  showDisplayName?: boolean,
  review?: string,
) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CreateReviewMessageRes | null>(null);

  const send = useCallback(async () => {
    if (availabilityID == null || showDisplayName == null) return reqState;
    setLoading();
    try {
      const data = await createReviewMessage(availabilityID, showDisplayName, review);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [availabilityID, showDisplayName, review, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateReviewMessage;
