import { api } from "@api/api";

export interface CreatePaidHoldingRes {
  url: string;
  holdingID: string;
  checkoutID: string;
  paymentIntentID: string;
}

export interface CreateFreeHoldingRes {
  bookingID: string;
}

export type CreateHoldingRes = CreatePaidHoldingRes | CreateFreeHoldingRes;

const createHolding = async (availabilityID: string, slotsRequested: number) => {
  const { data } = await api.post<CreateHoldingRes>("/createHolding", {
    availabilityID,
    slotsRequested,
  });
  return data;
};

export default createHolding;
