import useSetSessionToOpen from "@api/private/update/hooks/useSetSessionToOpen";
import HoverTooltip from "@components/common/HoverTooltip";
import Button from "@components/form/Button";
import useCopyToClipboard from "@hooks/useCopyToClipboard";
import { classNames, createErrorSnackbar, createOpenRoomPath } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import { IoCopy, IoLockOpen } from "react-icons/io5";
import { ChannelNumber } from "../../roomContext/hooks/useRoom";
import useRoomContext from "../../roomContext/hooks/useRoomContext";
import Channel from "./Channel";

interface Props {
  visible?: boolean;
}

export default function ChannelsTab(props: Props) {
  const { visible } = props;

  const { pushSnackbar, cigProfile } = useGlobalContext();

  const {
    participants,
    session,
    channelNumber,
    changeChannel,

    genericIsLoading,
    genericError,
  } = useRoomContext();

  const {
    data: openSessionData,
    isLoading: openSessionIsLoading,
    send: openSession,
  } = useSetSessionToOpen(session?.id);

  const accessCode = openSessionData?.accessCode ?? session?.accessCode;

  const copyToClipboard = useCopyToClipboard();

  const copyOpenSessionLink = (accessCode: string) =>
    session?.id &&
    copyToClipboard(`${window.location.origin}${createOpenRoomPath(session.id, accessCode)}`);

  const openSessionHandle = async () => {
    const { error, data } = await openSession();
    if (error) return pushSnackbar(createErrorSnackbar(error, 5000));
    if (data) copyOpenSessionLink(data.accessCode);
  };

  const isCig = session?.cigID === cigProfile?.id;

  return (
    <div className={classNames("tab-content", "channels-tab", visible && "visible")}>
      {([1, 2, 3, 4, 5] as ChannelNumber[]).map(currChannelNumber => (
        <Channel
          key={currChannelNumber}
          name={`#${currChannelNumber}`}
          active={currChannelNumber === channelNumber}
          participants={participants.filter(
            participant => participant.channelNumber === currChannelNumber,
          )}
          disable={genericIsLoading || !!genericError}
          onClick={() => {
            if (channelNumber === currChannelNumber) return;
            changeChannel(currChannelNumber);
          }}
        />
      ))}
      {isCig && (
        <HoverTooltip
          color="black-1"
          content={
            accessCode ? (
              <p className="text-center">
                Anyone with this link will be able to join this session.
              </p>
            ) : (
              <p className="text-center">
                Generate an open link anyone can use to join the session.
              </p>
            )
          }>
          {accessCode ? (
            <Button
              className="open-session-button"
              variant="contained"
              color="black-3"
              textColor="white"
              endIcon={<IoCopy />}
              onClick={() => copyOpenSessionLink(accessCode)}>
              Copy Access Link
            </Button>
          ) : (
            <Button
              className="open-session-button"
              color="black-3"
              textColor="white"
              endIcon={<IoLockOpen />}
              onClick={openSessionHandle}
              isLoading={openSessionIsLoading}>
              Open Session
            </Button>
          )}
        </HoverTooltip>
      )}
    </div>
  );
}
