import { countryNames } from "@lib/constants/generic";
import { forwardRef, useState } from "react";
import flags from "country-flag-icons/react/3x2";
import { Option } from "./Options";
import Search, { SearchProps } from "./Search";

interface Props extends Omit<SearchProps, "results" | "searchValue" | "onChange"> {}

export type CountrySearchProps = Props;

const CountrySearch = forwardRef<HTMLInputElement & HTMLTextAreaElement, Props>((props, ref) => {
  const [searchValue, setSearchValue] = useState("");

  const options: Option[] = Object.entries(countryNames).map(([code, name]) => {
    const Flag = flags[code];
    return {
      label: name,
      startIcon: <Flag style={{ width: 20 }} />,
      justifyContent: "flex-start",
      value: code,
    };
  });

  const Flag = props.value ? flags[props.value] : null;

  return (
    <Search
      startIcon={Flag && <Flag />}
      searchValue={searchValue}
      onChange={({ value }) => setSearchValue(value)}
      results={options}
      ref={ref}
      {...props}
    />
  );
});

export default CountrySearch;
