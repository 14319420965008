import Avatar from "@components/common/Avatar";
import DataCheck from "@components/common/DataCheck";
import { numberToCurrency, parseTime, pluralise } from "@lib/utils/generic";
import useRequestExperienceContext from "../request/context/useRequestExperienceContext";
import useRequestExperiencesGames from "../../../../hooks/useRequestExperiencesGames";

export default function ExperiencesRequestSummary() {
  const {
    cigProfile,
    values,
    currency,
    priceIncTax,
    sessionCostIsLoading,
    sessionCostError,
    cigProfileIsLoading,
    cigProfileError,

    customExperience,
    customExperienceIsLoading,
    customExperienceError,
    packageExperience,
    packageExperienceIsLoading,
    packageExperienceError,
  } = useRequestExperienceContext();

  const { games, platforms, gamesIsLoading, gamesError } = useRequestExperiencesGames(
    customExperience,
    customExperienceIsLoading,
    customExperienceError,
    packageExperience,
    packageExperienceIsLoading,
    packageExperienceError,
    values.gameId,
  );

  const game = games.find(({ id }) => values.gameId === id);
  const platform = platforms.find(({ id }) => values.platformId === id);

  const { hours, minutes } = parseTime(values.sessionLength * 60);

  return (
    <div className="experiences-request-summary">
      <h1 className="summary-title">let's get it</h1>

      <div className="containers">
        <div className="container cig-summary">
          <DataCheck
            isLoading={cigProfileIsLoading}
            error={cigProfileError}
            loadingIndicator="gloss">
            <Avatar src={cigProfile?.profilePictureURL} alt={cigProfile?.displayName} size={48} />

            <div className="cig-details">
              <p className="display-name">{cigProfile?.displayName}</p>
              <p className="tag-line">{cigProfile?.tagLine}</p>
            </div>
          </DataCheck>
        </div>

        <div className="container details">
          <div className="details-row">
            <DataCheck isLoading={gamesIsLoading} error={gamesError} loadingIndicator="gloss">
              <p className="label">Game</p>
              <p className="value">{game?.longName}</p>
            </DataCheck>
          </div>

          <div className="details-row">
            <DataCheck isLoading={gamesIsLoading} error={gamesError} loadingIndicator="gloss">
              <p className="label">Platform</p>
              <p className="value">{platform?.name}</p>
            </DataCheck>
          </div>

          <div className="details-row">
            <p className="label">Livestreamed</p>
            <p className="value">{values.streamOption ? "Yes" : "No"}</p>
          </div>

          <div className="details-row">
            <p className="label">Date</p>
            <p className="value">
              {values.startTime && values.endTime && (
                <>
                  {values.startTime?.format("DD MMMM YYYY")}
                  <br />
                  {values.startTime?.format("HH:mmA")} - {values.endTime?.format("HH:mmA")}
                </>
              )}
            </p>
          </div>

          <div className="details-row">
            <p className="label">Duration</p>
            <p className="value">
              {values.sessionLength > 0 && (
                <>
                  {hours > 0 && `${hours}H`} {minutes > 0 && `${minutes}M`}
                </>
              )}
            </p>
          </div>

          <div className="details-row">
            <p className="label">Quantity</p>
            <p className="value">
              {values.tickets > 0 && values.tickets} {pluralise("ticket", values.tickets)}
            </p>
          </div>
        </div>

        <div className="container details">
          <DataCheck
            isLoading={sessionCostIsLoading || packageExperienceIsLoading}
            error={sessionCostError ?? packageExperienceError}
            loadingIndicator="gloss">
            <div className="details-row">
              <p className="label">Total</p>
              <p className="value">
                {priceIncTax != null && currency != null
                  ? numberToCurrency(priceIncTax, currency)
                  : "N/A"}
              </p>
            </div>
          </DataCheck>
        </div>
      </div>
    </div>
  );
}
