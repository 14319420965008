import { ButtonProps } from "@components/form/Button";
import { CheckboxProps } from "@components/form/Checkbox";
import {
  FormValidations,
  OnFormBlur,
  OnFormChange,
  OnFormChangeMultiple,
  ResetValidations,
} from "@components/form/hooks/useFormManager";
import { InputProps } from "@components/form/Input";
import useQuery from "@hooks/useQuery";
import { CIG } from "@lib/enums/generic";
import { FormHandle } from "@lib/types/form";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from "react";
import useCigLogin, { CigLoginValues } from "../hooks/useCigLogin";

interface CigLoginContextType {
  category: CIG;

  values: CigLoginValues;
  validations: FormValidations<CigLoginValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;
  onChangeMultiple: OnFormChangeMultiple;

  handles: FormHandle[];
  resetValidations: ResetValidations;

  loginIsLoading: boolean;

  step: number;
  setStep: Dispatch<SetStateAction<number>>;

  inputSize: InputProps["size"];
  checkboxSize: CheckboxProps["size"];
  buttonSize: ButtonProps["size"];
}

export const CigLoginContext = createContext<CigLoginContextType>({} as CigLoginContextType);

export default function CigLoginContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const { breakpoints } = useGlobalContext();

  const category = useQuery<CIG>("category") ?? CIG.Gamer;

  const [step, setStep] = useState(0);

  const {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles,
    resetValidations,

    loginIsLoading,
  } = useCigLogin(category);

  const inputSize = breakpoints.sm ? "medium" : "large";
  const checkboxSize = breakpoints.xs ? "small" : "medium";
  const buttonSize = breakpoints.sm ? "medium" : "large";

  return (
    <CigLoginContext.Provider
      value={{
        category,

        values,
        validations,

        onChange,
        onBlur,
        onChangeMultiple,

        handles,
        resetValidations,

        loginIsLoading,

        step,
        setStep,

        buttonSize,
        inputSize,
        checkboxSize,
      }}>
      {children}
    </CigLoginContext.Provider>
  );
}
