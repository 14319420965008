import Shake from "@components/transitions/Shake";
import useCombinedRefs from "@hooks/useCombinedRefs";
import { CSSProps, StepEvents } from "@lib/types/generic";
import { forwardRef, PropsWithChildren, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { FormHandle, FormStage, FormStep, FormValidation } from "../../lib/types/form";
import FormErrors from "./FormErrors";
import useFormState from "./hooks/useFormState";

export type FormElement<V = any, N = string | number> = { value: V; name: N; type?: string };

interface Props extends StepEvents, CSSProps {
  steps?: FormStep[];
  initialStep?: number;
  stepsLengthOverride?: number;
  resetOnCompletion?: boolean;
  validation?: FormValidation;
  triggerGlobalLoading?: boolean;

  handle?: FormHandle;
  resetValidations?: () => void;
}

const Form = forwardRef<HTMLFormElement, PropsWithChildren<Props>>((props, forwardedRef) => {
  const {
    onStepChange = () => {},
    onCompletion = () => {},
    resetValidations = () => {},
    initialStep = 0,
    stepsLengthOverride,
    resetOnCompletion,
    validation,
    triggerGlobalLoading,
    className = "",
    id,
    style,

    steps = [{ children: props.children, handle: props.handle }],
  } = props;

  const timeout = 300; // milliseconds

  const { stage, stepIndex, children, nextChildren, parseAndSubmit } = useFormState(
    onStepChange,
    onCompletion,
    resetValidations,
    steps,
    timeout,
    initialStep,
    stepsLengthOverride,
    resetOnCompletion,
    triggerGlobalLoading,
  );

  const formRef = useRef<HTMLFormElement>(null);
  const nextFormRef = useRef<HTMLFormElement>(null);

  const ref = useCombinedRefs(formRef, forwardedRef);

  const isFinalStep = stepIndex + 1 === steps.length;

  return (
    <Shake in={!!validation?.failed}>
      <div className="form-wrapper">
        {steps.length > 1 && (
          <CSSTransition
            in={stage === FormStage.Finished}
            timeout={timeout}
            nodeRef={nextFormRef}
            appear>
            <form
              ref={nextFormRef}
              className={`form next ${stage === FormStage.Idle ? "no-transition" : ""}`}>
              <div className="inner">{nextChildren}</div>
            </form>
          </CSSTransition>
        )}

        <CSSTransition
          in={stage !== FormStage.Finished || isFinalStep}
          timeout={timeout}
          nodeRef={formRef}
          appear>
          <form
            ref={ref}
            onSubmit={parseAndSubmit}
            className={`form ${className}`}
            id={id}
            style={style}>
            <div className="inner">{children}</div>
          </form>
        </CSSTransition>
        <FormErrors validation={validation} />
      </div>
    </Shake>
  );
});

export default Form;
