import { CSSProps, UIColor } from "@lib/types/generic";

interface Props extends CSSProps {
  color?: UIColor;
  variant?: "circular" | "ring";
}

export type ProgressProps = Props;

export default function Progress(props: Props) {
  const { color = "white", variant = "circular", className = "", id, style } = props;

  return (
    <div className={`progress ${variant} ${color} ${className}`} id={id} style={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
