import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import { forwardRef, PropsWithChildren } from "react";

interface Props extends CSSProps {}

const SplashBox = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const { className, children, ...cssProps } = props;

  return (
    <div className={classNames("splash-box", className)} ref={ref} {...cssProps}>
      {children}
    </div>
  );
});

export default SplashBox;
