import React from "react";
import CigRegisterInner from "./CigRegisterInner";
import CigRegisterContextProvider from "./context/CigRegisterContextProvider";

export default function CigRegister() {
  return (
    <CigRegisterContextProvider>
      <CigRegisterInner />
    </CigRegisterContextProvider>
  );
}
