import { FormValidation } from "@lib/types/form";
import { CreateExperienceRequestValues } from "@pages/experiences/request/hooks/useExperienceRequestCreate";
import dayjs from "dayjs";
import { Validator } from "../Validator";

const createExperienceRequestValidators = {
  gameId: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string"),
  platformId: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string"),
  cigId: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string"),
  customerRequestDetails: (validation: FormValidation, value: any) =>
    new Validator(validation, value).is("string"),

  tickets: (validation: FormValidation, value: any, { packageId }: CreateExperienceRequestValues) =>
    new Validator(validation, value)
      .skipIf(packageId != null)
      .exists()
      .is("number")
      .number.greaterThan(0)
      .number.lessThanOrEqualTo(10),
  streamOption: (
    validation: FormValidation,
    value: any,
    { packageId }: CreateExperienceRequestValues,
  ) =>
    new Validator(validation, value)
      .skipIf(packageId != null)
      .exists()
      .is("boolean"),
  sessionLength: (
    validation: FormValidation,
    value: any,
    { packageId }: CreateExperienceRequestValues,
  ) =>
    new Validator(validation, value)
      .skipIf(packageId != null)
      .exists()
      .is("number")
      .number.greaterThan(0),

  startTime: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().instanceOf(dayjs),
  endTime: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().instanceOf(dayjs),

  packageID: (validation: FormValidation, value: any) =>
    new Validator(validation, value)
      .skipIf(value == null)
      .exists()
      .is("string"),
};

export default createExperienceRequestValidators;
