import { RefObject, useCallback, useEffect } from "react";

const useOnMouseLeave = (
  ref?: RefObject<HTMLElement>,
  callback = (event: MouseEvent) => {},
  button?: number,
) => {
  const handleMouseLeave = useCallback(
    (event: MouseEvent) => {
      if (button == null) return callback(event);
      if (event.button === button) return callback(event);
    },
    [button, callback],
  );

  useEffect(() => {
    const current = ref?.current ?? document.body;
    if (current == null) return;
    current.addEventListener("mouseleave", handleMouseLeave);
    return () => current.removeEventListener("mouseleave", handleMouseLeave);
  }, [handleMouseLeave, ref]);
};

export default useOnMouseLeave;
