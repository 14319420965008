import { useEffect, useState } from "react";
import { VideoParticipant } from "../../objects/RoomParticipant";

const useNetworkQualityLevel = (videoParticipant?: VideoParticipant) => {
  const [networkQualityLevel, setNetworkQualityLevel] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setNetworkQualityLevel(videoParticipant?.networkQualityLevel ?? 0);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [networkQualityLevel, videoParticipant?.networkQualityLevel]);

  return networkQualityLevel;
};

export default useNetworkQualityLevel;
