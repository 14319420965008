import { api } from "@api/api";

export interface CreateReviewRatingRes {}

const createReviewRating = async (availabilityID: string, score?: number) => {
  const { data } = await api.post<CreateReviewRatingRes>("/createReview", {
    availabilityID,
    score,
  });
  return data;
};

export default createReviewRating;
