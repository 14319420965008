import { api } from "@api/api";

export interface CIGKickParticipantRes {}

const cigKickParticipant = async (availabilityID: string, chatParticipantID: string) => {
  const { data } = await api.post<CIGKickParticipantRes>("/cigKickParticipant", {
    availabilityID,
    chatParticipantID,
  });
  return data;
};

export default cigKickParticipant;
