import { classNames } from "@lib/utils/generic";
import { PropsWithChildren } from "react";

interface Props {
  small?: boolean;
  center?: boolean;
}

const CigProfileTitle = (props: PropsWithChildren<Props>) => {
  const { small, center, children } = props;

  return (
    <h2 className={classNames("section-title", small && "small", center && "center")}>
      {children}
    </h2>
  );
};

export default CigProfileTitle;
