import Button from "@components/form/Button";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import CigProfileTitle from "../CigProfileTitle";
import useCigProfileContext from "../context/hooks/useCigProfileContext";

const BuildBar = () => {
  const { breakpoints } = useGlobalContext();

  const { isOwnProfile, cigProfile } = useCigProfileContext();

  return (
    <section className="section build-bar">
      <div className="box">
        <CigProfileTitle small>Don't see what you're after?</CigProfileTitle>

        <Button
          variant="outlined"
          color="gray-3"
          textColor="white"
          size={breakpoints.sm ? "medium" : "large"}
          wrap
          justifyContent="center"
          to={
            isOwnProfile ? "/experiences/update/custom" : `/experiences/request/${cigProfile?.id}`
          }>
          Request a custom experience
        </Button>
      </div>
    </section>
  );
};

export default BuildBar;
