import Section from "@components/section/Section";
import FormEntry from "@components/form/FormEntry";
import Input from "@components/form/Input";
import FormRow from "@components/form/FormRow";
import Button from "@components/form/Button";
import Form from "@components/form/Form";
import useFormManager from "@components/form/hooks/useFormManager";
import { useCallback } from "react";
import dayjs, { Dayjs } from "dayjs";
import useUpdateUserExtensionHandles from "./hooks/useUpdateUserExtensionHandles";
import equal from "fast-deep-equal";
import SectionBar from "@components/section/SectionBar";
import { CountryCode } from "@lib/enums/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import HoverTooltip from "@components/common/HoverTooltip";
import SimpleDatePicker from "@components/form/SimpleDatePicker";

interface Props {
  initialValues?: {
    firstName?: string;
    surname?: string;
    displayName?: string;
    dob?: Dayjs;
    country?: CountryCode;
  };
}

const initialValues = {
  firstName: "",
  surname: "",
  displayName: "",
  dob: dayjs(),
};

export type UpdateUserExtension = typeof initialValues;

export default function About(props: Props) {
  const { initialValues } = props;

  const { user, breakpoints } = useGlobalContext();

  const { firstName = "", surname = "", displayName = "", dob = dayjs() } = initialValues ?? {};

  const formManager = useFormManager(
    {
      ...initialValues,
      firstName,
      surname,
      displayName,
      dob,
    },
    "updateUserExtension",
  );
  const { values, validations, setValues, onChange, onBlur, resetValidations } = formManager;

  const [handle, isLoading] = useUpdateUserExtensionHandles(formManager);

  const discard = useCallback(() => {
    setValues({
      firstName,
      surname,
      displayName,
      dob,
    });
    resetValidations();
  }, [firstName, surname, displayName, dob, setValues, resetValidations]);

  return (
    <Section
      title="About"
      footerActions={
        (!equal(values, initialValues) || isLoading) && (
          <>
            <Button
              color="purple"
              textColor="white"
              size="small"
              type="submit"
              form="update-user-extension-form"
              isLoading={isLoading}>
              Save Changes
            </Button>
            <Button
              color="red"
              textColor="red"
              size="small"
              variant="outlined"
              onClick={discard}
              disabled={isLoading}>
              Discard
            </Button>
          </>
        )
      }>
      <Form id="update-user-extension-form" handle={handle} validation={validations.generic}>
        <SectionBar>
          <FormRow>
            <label>
              <p>Email</p>
            </label>
            <FormEntry className="flex-shrink">
              <HoverTooltip content={<p>You cannot edit your email</p>}>
                <div>
                  <Input
                    variant="contained"
                    color="black-4"
                    textColor="white"
                    size="medium"
                    value={user.attributes.email}
                    disabled
                  />
                </div>
              </HoverTooltip>
            </FormEntry>
          </FormRow>
        </SectionBar>
        <SectionBar>
          <FormRow>
            <label>
              <p>First Name</p>
            </label>
            <FormEntry validation={validations.firstName} showValidationBeneath>
              <Input
                variant="contained"
                color="black-4"
                textColor="white"
                size="medium"
                name="firstName"
                value={values.firstName}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FormEntry>
          </FormRow>
        </SectionBar>
        <SectionBar>
          <FormRow>
            <label>
              <p>Surname</p>
            </label>
            <FormEntry validation={validations.surname} showValidationBeneath>
              <Input
                variant="contained"
                color="black-4"
                textColor="white"
                size="medium"
                name="surname"
                value={values.surname}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FormEntry>
          </FormRow>
        </SectionBar>
        <SectionBar>
          <FormRow>
            <label>
              <p>Display Name</p>
            </label>
            <FormEntry validation={validations.displayName} showValidationBeneath>
              <Input
                variant="contained"
                color="black-4"
                textColor="white"
                size="medium"
                name="displayName"
                value={values.displayName}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FormEntry>
          </FormRow>
        </SectionBar>
        <SectionBar dynamicHeight>
          <FormRow>
            <label>
              <p>Date of Birth</p>
            </label>
            <FormEntry validation={validations.dob} showValidationBeneath>
              <SimpleDatePicker
                position="portal-absolute"
                variant="contained"
                color="black-4"
                textColor="white"
                size="medium"
                name="dob"
                orientation={breakpoints.sm ? "vertical" : "horizontal"}
                value={values.dob}
                onChange={onChange}
              />
            </FormEntry>
          </FormRow>
        </SectionBar>
      </Form>
    </Section>
  );
}
