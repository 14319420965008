import { MyBooking } from "@api/private/get/getBooking";
import DialogueModal from "@components/modals/DialogueModal";
import Divider from "@components/common/Divider";
import Modal from "@components/modals/Modal";
import TicketRegular, { TicketData } from "@components/tickets/TicketRegular";
import Button from "@components/form/Button";
import useCopyToClipboard from "@hooks/useCopyToClipboard";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createRoomPath } from "@lib/utils/generic";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { IoCopy, IoEnter, IoSend, IoReload } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import ShareTicketModal from "./ShareTicketModal";

interface Props {
  myBooking?: MyBooking | null;
  ticket?: TicketData;
  ticketChromaColor?: chroma.Color;
  ticketIndex?: number;
  shared?: boolean;
  isFirstTicket?: boolean;
  onClose?: () => void;
  onShare?: (ticketId: string) => void;
  onShareReset?: (ticketId: string) => void;
}

export default function TicketActionsModal(props: Props) {
  const {
    myBooking,
    ticket,
    ticketChromaColor,
    ticketIndex,
    shared,
    isFirstTicket,
    onClose,
    onShare = () => {},
    onShareReset = () => {},
  } = props;

  const { breakpoints } = useGlobalContext();

  const history = useHistory();

  const copy = useCopyToClipboard();

  const [dialogueModalActive, setDialogueModalActive] = useState(isFirstTicket);
  const [shareModalActive, setShareModalActive] = useState(false);

  const { session, bookingID } = myBooking ?? {};
  const { ticketId } = ticket ?? {};
  const { to, id: sessionId } = session ?? {};

  const isOver = dayjs(to).isBefore(dayjs());

  const sharePath = createRoomPath(sessionId ?? "", ticketId, bookingID);
  const shareUrl = `${window.location.protocol}//${window.location.host}${sharePath}`;

  useEffect(() => setDialogueModalActive(isFirstTicket), [isFirstTicket]);

  const handleShareReset = () => {
    if (ticketId == null) return;

    onShareReset(ticketId);
  };

  const handleCopy = () => {
    if (ticketId == null) return;

    onShare(ticketId);
    copy(shareUrl);
  };

  const handleUse = () => {
    if (ticketId == null) return;

    onShare(ticketId);
    history.push(sharePath);
  };

  return (
    <Modal
      title="Ticket Options"
      subTitle="Gift or use this Ticket"
      titleDesign="condensed"
      className="ticket-actions-modal"
      active={!!ticket}
      onClose={onClose}
      stackButtons={breakpoints.sm}
      buttons={
        <>
          {shared ? (
            <Button
              endIcon={<IoReload />}
              color="purple"
              textColor="white"
              disabled={isOver}
              onClick={handleShareReset}>
              Mark Not Gifted
            </Button>
          ) : (
            <>
              <Button
                endIcon={<IoEnter />}
                variant="contained"
                color="black-4"
                textColor="white"
                onClick={handleUse}
                disabled={isOver}>
                Join
              </Button>
              <div className="inner-button-row">
                <Button
                  variant="flat"
                  endIcon={<IoCopy />}
                  color="purple"
                  textColor="white"
                  disabled={isOver}
                  onClick={handleCopy}>
                  Copy
                </Button>
                <Button
                  endIcon={<IoSend />}
                  variant="contained"
                  color="purple"
                  textColor="white"
                  disabled={isOver}
                  onClick={() => setShareModalActive(true)}>
                  Gift
                </Button>
              </div>
            </>
          )}
        </>
      }>
      <Divider color="gray-3" />
      <TicketRegular
        {...ticket}
        size={breakpoints.sm ? "small" : "medium"}
        torn={shared}
        chromaColor={ticketChromaColor}
        index={ticketIndex}
      />
      {/* {error && <p className="text-red text-bold">{error}</p>} */}
      {isOver && <p>This session has already occurred</p>}

      <DialogueModal
        title="Please note"
        subTitle="It is advised to not share the first ticket if you intend to join the session yourself, as it is the default ticket used to join."
        titleDesign="condensed"
        color="purple"
        noIcon
        active={dialogueModalActive}
        confirmButtonText="I Understand"
        onConfirm={() => setDialogueModalActive(false)}
        onClose={() => setDialogueModalActive(false)}
        onCancel={onClose}
      />

      <ShareTicketModal
        bookingId={bookingID}
        ticketId={ticketId}
        active={shareModalActive}
        onClose={() => setShareModalActive(false)}
        onCompletion={() => ticketId && onShare(ticketId)}
      />
    </Modal>
  );
}
