import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const claimTicketsValidators = {
  holdingId: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").exists();
  },
};

export default claimTicketsValidators;
