import { api } from "@api/api";

export interface CustomerJoinOpenSessionRes {
  videoRoom1Authorisation: string;
  videoRoom2Authorisation: string;
  videoRoom3Authorisation: string;
  videoRoom4Authorisation: string;
  videoRoom5Authorisation: string;
  chatSID: string;
}

const customerJoinOpenSession = async (availabilityID: string, accessCode: string) => {
  const { data } = await api.post<CustomerJoinOpenSessionRes>("/getTicketPublicSessionJoin", {
    availabilityID,
    accessCode,
  });
  return data;
};

export default customerJoinOpenSession;
