import { api } from "@api/api";

export enum ConsultancyPackageType {
  Basic = "BASIC",
  Standard = "STANDARD",
  Premium = "PREMIUM",
  Audit = "AUDIT",
}

export interface RegisterConsultancyInterestRes {}

const registerConsultancyInterest = async (
  email: string,
  twitchUsername: string,
  packageChosen: ConsultancyPackageType,
) => {
  const { data } = await api.get<RegisterConsultancyInterestRes>("/registerConsultancyInterest", {
    params: {
      email,
      twitchUsername,
      packageChosen,
    },
  });
  return data;
};

export default registerConsultancyInterest;
