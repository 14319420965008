import Button from "@components/form/Button";
import SplashText from "@components/splash/SplashText";
import SplashTitle from "@components/splash/SplashTitle";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { IoArrowForward } from "react-icons/io5";

interface Props {
  onSignUpClick: () => void;
}

const Cta = (props: Props) => {
  const { onSignUpClick } = props;

  const { breakpoints } = useGlobalContext();

  return (
    <section className="section cta">
      <SplashTitle center>READY TO GET STARTED?</SplashTitle>
      <SplashText>Sign up (for free) and we'll get brand deals for your Twitch stream</SplashText>
      <Button
        size={breakpoints.md ? "medium" : "large"}
        color="peach-2"
        textColor="black-1"
        endIcon={<IoArrowForward />}
        onClick={onSignUpClick}>
        Sign up
      </Button>
    </section>
  );
};

export default Cta;
