import React from "react";
import GradientIcon, { GradientIconInnerProps, GradientIconProps } from "./GradientIcon";

const IoFlashGradient = (props: GradientIconProps) => (
  <GradientIcon {...props}>
    {({ gradientDefinition, label, ...cssProps }: GradientIconInnerProps) => (
      <svg
        stroke="currentColor"
        fill="currentColor"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        {...cssProps}>
        <path
          d="M432 208H288L320 16L80 304H224L192 496L432 208Z"
          fill={label ? `url(#${label})` : undefined}
        />
        {gradientDefinition && (
          <defs>
            <linearGradient
              id={label}
              x1="92.5928"
              y1="211.254"
              x2="453.519"
              y2="214.269"
              gradientUnits="userSpaceOnUse"
              gradientTransform={`rotate(${gradientDefinition.rotation})`}>
              {gradientDefinition.stopColors.map(({ stopColor, offset }, i) => (
                <stop key={i} offset={offset} stopColor={stopColor} />
              ))}
            </linearGradient>
          </defs>
        )}
      </svg>
    )}
  </GradientIcon>
);

export default IoFlashGradient;
