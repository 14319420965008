import { RefObject, useEffect } from "react";

import * as THREE from "three";
import BIRDS from "vanta/dist/vanta.birds.min";

const useVantaBirds = (ref: RefObject<any>, disabled?: boolean) => {
  useEffect(() => {
    const current = ref.current;
    if (current == null || disabled) return;
    const effect = BIRDS({
      el: current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      backgroundColor: 0x18181b,
      color1: 0x6d00e1,
      birdSize: 0.5,
      wingSpan: 40.0,
      separation: 90.0,
      alignment: 34.0,
      cohesion: 37.0,
    });
    return () => effect.destroy();
  }, [ref, disabled]);
};

export default useVantaBirds;
