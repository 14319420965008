import useUpdateCigProfile from "@api/private/update/hooks/useUpdateCigProfile";
import { CIGProfile } from "@api/public/get/getCigProfile";
import useGetCigProfile from "@api/public/get/hooks/useGetCigProfile";
import useFormManager from "@components/form/hooks/useFormManager";
import { CigFlowState } from "@lib/enums/generic";
import { createErrorSnackbar, createSuccessSnackbar } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import equal from "fast-deep-equal";
import { useState, useCallback, useEffect } from "react";
import useUploadBannerPicture from "./useUploadBannerPicture";
import useUploadProfiePicture from "./useUploadProfilePicture";

const initialValues = {
  cigID: "",
  tagLine: "",
  shortDescription: "",
  subCategory: "test",
  profilePictureURL: "",
  bannerPictureURL: "",
  instagramHandle: "",
  tiktokHandle: "",
  twitchHandle: "",
  twitterHandle: "",
  youtubeHandle: "",
};

export type CIGProfileValues = typeof initialValues;

const useCigProfileEdit = (cigId?: string, cigDisplayName?: string) => {
  const { pushSnackbar, setCigProfile, userExtension, progressCigFlowState } = useGlobalContext();

  const [editMode, setEditMode] = useState(false);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [unchangedValues, setUnchangedValues] = useState<CIGProfileValues>();

  const { values, validations, setValues, onChange, onBlur, resetValidations, validateFields } =
    useFormManager(initialValues, "cigProfile");

  const { profilePictureFile, uploadProfilePicture, setProfilePictureFile } =
    useUploadProfiePicture();
  const { bannerPictureFile, uploadBannerPicture, setBannerPictureFile } = useUploadBannerPicture();

  const {
    data: initialCigProfile,
    isLoading: getIsLoading,
    error: getError,
  } = useGetCigProfile(cigId, cigDisplayName, userExtension?.id, cigId !== "my-profile");

  const {
    data: updatedCigProfile,
    isLoading: updateIsLoading,
    error: saveError,
    send: updateCigProfile,
  } = useUpdateCigProfile(values);

  const cigProfile = { ...initialCigProfile, ...updatedCigProfile } as CIGProfile | null;
  const changesMade = !equal(values, unchangedValues);

  const saveIsLoading = updateIsLoading || uploadIsLoading;

  const handleSave = async () => {
    try {
      resetValidations();

      const failed = validateFields(["tagLine", "shortDescription"]);
      if (failed) return;

      setUploadIsLoading(true);

      const profilePictureUrl = await uploadProfilePicture();
      const bannerPictureUrl = await uploadBannerPicture();

      setUploadIsLoading(false);

      const newValues = { ...values };
      if (profilePictureUrl) newValues.profilePictureURL = profilePictureUrl;
      if (bannerPictureUrl) newValues.bannerPictureURL = bannerPictureUrl;

      const { error, data } = await updateCigProfile(newValues);
      if (error) throw error;

      setEditMode(false);
      setUnchangedValues(values);
      pushSnackbar(createSuccessSnackbar("Profile updated", 2500));
      progressCigFlowState(CigFlowState.ProfileSetup);
      if (!error && data) setCigProfile(data);
    } catch (error: any) {
      pushSnackbar(createErrorSnackbar(`Update failed, something went wrong.\n${error}`, 5000));
    }
  };

  const resetValues = useCallback(() => {
    resetValidations();
    setBannerPictureFile(null);
    setProfilePictureFile(null);

    if (!cigProfile) return;
    const {
      id,
      tagLine,
      shortDescription,
      subCategory,
      profilePictureURL,
      bannerPictureURL,
      instagramHandle,
      tiktokHandle,
      twitchHandle,
      twitterHandle,
      youtubeHandle,
    } = cigProfile;

    const values = {
      cigID: id,
      tagLine,
      shortDescription: shortDescription ?? "",
      subCategory,
      profilePictureURL,
      bannerPictureURL,
      instagramHandle,
      tiktokHandle,
      twitchHandle,
      twitterHandle,
      youtubeHandle,
    };
    setValues(values);
    setUnchangedValues(values);
    // eslint-disable-next-line
  }, [cigProfile, setBannerPictureFile, setProfilePictureFile, setValues, setUnchangedValues]);
  // must omit resetValidations to prevent loop

  useEffect(() => {
    if (!initialCigProfile) return;
    resetValues();
    // eslint-disable-next-line
  }, [initialCigProfile]);
  // must omit initialCigProfile to unwanted updates

  return {
    values,
    validations,
    onChange,
    onBlur,

    profilePictureFile,
    setProfilePictureFile,

    bannerPictureFile,
    setBannerPictureFile,

    cigProfile,
    getIsLoading,
    getError,

    handleSave,
    resetValues,
    saveIsLoading,
    saveError,

    editMode,
    setEditMode,
    changesMade,
  };
};

export default useCigProfileEdit;
