import { CSSProps } from "@lib/types/generic";
import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { useDrag } from "react-use-gesture";

interface Props extends CSSProps {
  onClose?: () => void;
  variant?: "light" | "dark" | "transparent";
}

export default function PageCover(props: PropsWithChildren<Props>) {
  const { children, style, variant = "dark", className = "", id, onClose = () => {} } = props;

  const bind = useDrag(({ down, movement: [mx] }) => {
    if (down && mx < -5) onClose();
  });

  const portalRoot = document.getElementById("portal-root");
  if (portalRoot == null) return null;

  return createPortal(
    <div
      className={`page-cover ${variant} ${className}`}
      style={style}
      id={id}
      onClick={event => {
        event.stopPropagation();
        onClose();
      }}
      {...bind()}>
      {children}
    </div>,
    portalRoot,
  );
}
