import FormModal from "@components/modals/FormModal";
import Form from "@components/form/Form";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import useFormManager from "@components/form/hooks/useFormManager";
import Input from "@components/form/Input";
import React, { useEffect } from "react";
import useClaimTicketsHandles from "./payment-completion/hooks/useClaimTicketsHandles";

interface Props {
  active?: boolean;
  linkingID?: string;

  onClose?: () => void;
}

const initialValues = {
  linkingID: "",
};

export type ClaimTicketsValues = typeof initialValues;

export default function ClaimTicketsModal(props: Props) {
  const { active, linkingID, onClose = () => {} } = props;

  const formManager = useFormManager(initialValues, "claimTickets");

  const { values, validations, onChange, onBlur, resetValidations } = formManager;

  const [handle, isLoading] = useClaimTicketsHandles(formManager);

  useEffect(() => {
    onChange({ name: "linkingID", value: linkingID, type: "text" });
    // eslint-disable-next-line
  }, [linkingID, active]);
  // must omit onChange to prevent loop

  return (
    <FormModal
      form="claim-tickets-modal"
      title="Claim Tickets"
      subTitle="Input your ticket claim code."
      active={active}
      confirmButtonProps={{
        isLoading,
      }}
      onClose={() => {
        onChange({ name: "linkingID", value: "", type: "text" });
        resetValidations();
        onClose();
      }}>
      <Form
        id="claim-tickets-modal"
        handle={handle}
        resetValidations={resetValidations}
        onCompletion={onClose}
        validation={validations.generic}>
        <FormRow>
          <FormEntry
            label="Ticket Claim Code"
            validation={validations.linkingID}
            showValidationBeneath>
            <Input
              variant="contained"
              color="black-4"
              textColor="white"
              size="medium"
              name="linkingID"
              value={values.linkingID}
              placeholder="********"
              onChange={onChange}
              onBlur={onBlur}
            />
          </FormEntry>
        </FormRow>
      </Form>
    </FormModal>
  );
}
