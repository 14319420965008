import { ButtonProps } from "@components/form/Button";
import GradientButton from "@components/form/GradientButton";
import useMouseIsOver from "@hooks/useMouseIsOver";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, { PropsWithChildren, useRef } from "react";
import { IoArrowForward } from "react-icons/io5";

export default function CtaButton(props: PropsWithChildren<ButtonProps>) {
  const { children, ...buttonProps } = props;

  const { breakpoints } = useGlobalContext();

  const buttonRef = useRef<HTMLButtonElement & HTMLAnchorElement>(null);

  const [buttonIsHovered] = useMouseIsOver(buttonRef);

  return (
    <GradientButton
      textColor="white"
      size={breakpoints.md ? "medium" : !breakpoints.sl ? "extra-large" : "large"}
      endIcon={<IoArrowForward />}
      justifyContent="center"
      glow
      animated
      isCtaing={buttonIsHovered}
      ref={buttonRef}
      wrapperClassName="cta-button"
      {...buttonProps}>
      {children}
    </GradientButton>
  );
}
