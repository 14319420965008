import { CreatePaidHoldingRes } from "@api/private/create/createHolding";
import useCreateHolding from "@api/private/create/hooks/useCreateHolding";
import { CreatePaidGuestHoldingRes } from "@api/public/create/createGuestHolding";
import useCreateGuestHolding from "@api/public/create/hooks/useCreateGuestHolding";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { Session } from "@lib/types/session";
import { GAConverters } from "@lib/utils/GAConverters";
import GAEvent from "@lib/utils/GAEvent";
import { BookSessionValues } from "../BookSession";

const isPaidSession = (res: any): res is CreatePaidHoldingRes | CreatePaidGuestHoldingRes =>
  res.url != null;

const useBookSessionHandles: UseFormHandles<BookSessionValues> = (
  { validateFields, validateCustom, values },
  session: Session,
  cigName: string,
  index: number,
) => {
  const { isLoading: createIsLoading, send: createHolding } = useCreateHolding(
    session?.id,
    values.slots,
  );
  const { isLoading: createGuestIsLoading, send: createGuestHolding } = useCreateGuestHolding(
    session?.id,
    values.slots,
  );

  const handle = (isGuest?: boolean) => async (email?: string) => {
    let failed = validateFields(["slots"]);

    const create = isGuest ? createGuestHolding : createHolding;

    const { data: createHoldingRes, error } = await create(email);

    if (error) {
      failed = validateCustom("generic", validation => {
        validation.list.push({
          type: "error",
          text: error,
        });
      });
    }

    if (!failed) {
      GAEvent.calendarListingCheckout(session.currency, session.price, [
        GAConverters.listing.calendar(session.cigID, cigName, values.slots)(session, index),
      ]);

      if (createHoldingRes) {
        if (isPaidSession(createHoldingRes)) {
          const url = createHoldingRes.url;
          return window.location.replace(url);
        } else {
          const bookingId = createHoldingRes.bookingID;
          return window.location.replace(
            `/bookings/payment-completion/${bookingId}?guest=${isGuest}&type=booking&state=success`,
          );
        }
      }
    }

    return failed;
  };

  return [handle(false), handle(true), createIsLoading, createGuestIsLoading];
};

export default useBookSessionHandles;
