import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import resendConfirmCode from "../resendConfirmCode";

const useResendConfirmCode = (email?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<null>(null);

  const send = useCallback(async () => {
    if (email == null) {
      return setError("Email is required");
    }

    setLoading();
    try {
      await resendConfirmCode(email);
      return setSuccess(null);
    } catch (err: any) {
      return setError(err.message);
    }
  }, [email, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useResendConfirmCode;
