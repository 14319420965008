import { AvailabilityTimeBlock } from "@api/public/get/getRequestAvailability";
import useGetRequestAvailability from "@api/public/get/hooks/useGetRequestAvailability";
import DataCheck from "@components/common/DataCheck";
import Divider from "@components/common/Divider";
import Pill from "@components/common/Pill";
import Button from "@components/form/Button";
import FormEntry from "@components/form/FormEntry";
import FormGroup from "@components/form/FormGroup";
import FormRow from "@components/form/FormRow";
import { mergeValidations } from "@components/form/hooks/useFormManager";
import IconButton from "@components/form/IconButton";
import { Option } from "@components/form/Options";
import Select from "@components/form/Select";
import SimpleCalendar, { SimpleCalendarCount } from "@components/form/SimpleCalendar";
import { dateIsToday, isEmpty, parseTime, pluralise } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import {
  IoArrowForward,
  IoChevronBack,
  IoChevronForward,
  IoHourglassOutline,
} from "react-icons/io5";
import useRequestExperienceContext from "../context/useRequestExperienceContext";
import ExperiencesRequestLabel from "../ExperiencesRequestLabel";

const availabilityBlocksToCounts = (blocks: AvailabilityTimeBlock[]) => {
  const counts: SimpleCalendarCount[] = [];

  for (const { start } of blocks) {
    const index = counts.findIndex(({ date }) => date.isSame(start, "day"));
    if (index >= 0) counts[index].count += 1;
    else
      counts.push({
        date: dayjs(start).startOf("day"),
        count: 1,
      });
  }

  return counts;
};

export default function Time() {
  const { breakpoints } = useGlobalContext();
  const { values, validations, onChange, onChangeMultiple } = useRequestExperienceContext();

  const [selectedMonth, setSelectedMonth] = useState(dayjs().startOf("month"));

  const goToPrevMonth = () => setSelectedMonth(selectedMonth.subtract(1, "month"));
  const goToNextMonth = () => setSelectedMonth(selectedMonth.add(1, "month"));
  const gotoCurrentMonth = () => setSelectedMonth(dayjs().startOf("month"));

  const month = values.selectedDate.format("MMM").toUpperCase();
  const year = parseInt(values.selectedDate.format("YYYY"));

  const {
    data: requestAvailability,
    isLoading: requestAvailabilityIsLoading,
    error: requestAvailabilityError,
  } = useGetRequestAvailability(
    values.cigId,
    month,
    year,
    values.sessionLength,
    values.packageId,
    true,
  );

  const filteredTimeBlocks =
    requestAvailability?.availableBlocksOfTime.filter(({ start }) =>
      dateIsToday(dayjs(start), values.selectedDate),
    ) ?? [];

  const buttonSize = breakpoints.sm ? "small" : "medium";

  useEffect(() => {
    const { availableBlocksOfTime } = requestAvailability ?? {};
    if (!availableBlocksOfTime) return;
    const date = availableBlocksOfTime[0].start;
    if (!date) return;
    onChange({ name: "selectedDate", value: dayjs(date) });
    // eslint-disable-next-line
  }, [requestAvailability]);
  // must omit onChange to prevent loop

  const { hours, minutes } = parseTime(values.sessionLength * 60);

  return (
    <section className="form-section tickets">
      <FormRow>
        <FormEntry
          label={
            <>
              <ExperiencesRequestLabel label={selectedMonth.format("MMMM YYYY")} />

              <div className="controls">
                <Button
                  size={buttonSize}
                  color="black-2"
                  textColor="white"
                  onClick={gotoCurrentMonth}>
                  Today
                </Button>
                <IconButton
                  size={buttonSize}
                  color="black-2"
                  iconColor="white"
                  icon={<IoChevronBack />}
                  onClick={goToPrevMonth}
                />
                <IconButton
                  size={buttonSize}
                  color="black-2"
                  iconColor="white"
                  icon={<IoChevronForward />}
                  onClick={goToNextMonth}
                />
              </div>
            </>
          }
          className="time-entry">
          <FormEntry validation={mergeValidations(validations.endTime, validations.startTime)}>
            <SimpleCalendar
              size="small"
              color="black-2"
              headTextColor="gray-2"
              bodyTextColor="white"
              rounded
              month={selectedMonth}
              value={values.selectedDate}
              onChange={({ value }) => onChange({ name: "selectedDate", value })}
              dayChars={3}
              minDate={dayjs()}
              height={breakpoints.sm ? 250 : 320}
              counts={availabilityBlocksToCounts(requestAvailability?.availableBlocksOfTime ?? [])}
              countText="{count} slots left"
            />
          </FormEntry>

          <div className="time-selector">
            <DataCheck
              isLoading={requestAvailabilityIsLoading}
              error={requestAvailabilityError}
              isEmpty={isEmpty(filteredTimeBlocks)}
              loadingIndicator="spinner"
              emptyMessage="No availability found - try choosing a different day">
              <TimeSelector
                startTime={values.startTime}
                endTime={values.endTime}
                selectedDate={values.selectedDate}
                sessionLength={values.sessionLength}
                timeBlocks={filteredTimeBlocks}
                onChange={(startTime, endTime) =>
                  onChangeMultiple([
                    {
                      name: "startTime",
                      value: startTime,
                    },
                    { name: "endTime", value: endTime },
                  ])
                }
              />
            </DataCheck>
          </div>

          <div className="duration-splash">
            <IoHourglassOutline />{" "}
            <p>
              {hours > 0 && (
                <>
                  {hours} {pluralise("hour", hours)}
                </>
              )}{" "}
              {minutes} {pluralise("minute", minutes)}
            </p>
          </div>
        </FormEntry>
      </FormRow>
    </section>
  );
}

interface TimeSelectorProps {
  startTime?: Dayjs;
  endTime?: Dayjs;
  selectedDate: Dayjs;
  sessionLength: number;

  timeBlocks: AvailabilityTimeBlock[];

  onChange: (startTime: Dayjs, endTime: Dayjs) => void;
}

const TimeSelector = (props: TimeSelectorProps) => {
  const { startTime, endTime, selectedDate, sessionLength, timeBlocks, onChange } = props;

  const { breakpoints } = useGlobalContext();

  const hourOptions = timeBlocks.reduce((acc, { start, end }) => {
    const hour = dayjs(start).hour();
    const hourExists = !!acc.find(({ value }) => hour === value);

    if (hourExists) return acc;

    return [...acc, { value: hour, label: hour.toString().padStart(2, "0") }];
  }, [] as Option[]);

  const minuteOptions = timeBlocks.reduce((acc, { start, end }) => {
    const minute = dayjs(start).minute();
    const hourMatches = dayjs(start).hour() === startTime?.hour();

    if (!hourMatches) return acc;

    return [...acc, { value: minute, label: minute.toString().padStart(2, "0") }];
  }, [] as Option[]);

  const hourValue = startTime?.hour();
  const minuteValue = startTime?.minute() ?? 0;

  const handleChange = (hour: number, minute: number) => {
    const newStartTime = dayjs(selectedDate).hour(hour).minute(minute);
    const newEndTime = newStartTime.add(sessionLength, "minutes");

    onChange(newStartTime, newEndTime);
  };

  return (
    <>
      <FormEntry label="Start Time">
        <FormGroup orientation="horizontal">
          <Select
            value={hourValue}
            size="large"
            color="black-2"
            textColor="white"
            options={hourOptions}
            onChange={({ value }) => {
              handleChange(value, minuteValue);
            }}
            justifyContent="center"
          />
          <Divider color="gray-1" orientation="vertical" />
          <Select
            value={minuteValue}
            size="large"
            color="black-2"
            textColor="white"
            options={minuteOptions}
            onChange={({ value }) => {
              if (hourValue != null) handleChange(hourValue, value);
            }}
            justifyContent="center"
          />
        </FormGroup>
      </FormEntry>

      {!breakpoints.md && <IoArrowForward className="divider-icon" />}

      <FormEntry label="End Time">
        <FormGroup orientation="horizontal">
          <Pill size="large" color="black-2" textColor="white">
            {endTime?.hour().toString().padStart(2, "0") ?? "None"}
          </Pill>
          <Divider color="gray-1" orientation="vertical" />
          <Pill size="large" color="black-2" textColor="white">
            {endTime?.minute().toString().padStart(2, "0") ?? "None"}
          </Pill>
        </FormGroup>
      </FormEntry>
    </>
  );
};
