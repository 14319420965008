import { CigCustomExperience } from "@api/public/get/getCigCustomExperience";
import { CigPackageExperience } from "@api/public/get/getCigPackageExperiences";
import { CIGProfile } from "@api/public/get/getCigProfile";
import { CustomSessionCost } from "@api/public/get/getCustomSessionCost";
import useGetCigCustomExperience from "@api/public/get/hooks/useGetCigCustomExperience";
import useGetCigPackageExperiences from "@api/public/get/hooks/useGetCigPackageExperiences";
import useGetCigProfile from "@api/public/get/hooks/useGetCigProfile";
import useGetCustomSessionCost from "@api/public/get/hooks/useGetCustomSessionCost";
import { Tab } from "@components/common/Tabs";
import {
  FormValidations,
  OnFormBlur,
  OnFormChange,
  OnFormChangeMultiple,
  ResetValidations,
} from "@components/form/hooks/useFormManager";
import useQuery from "@hooks/useQuery";
import { Currency } from "@lib/enums/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import useExperienceRequestCreate, {
  CreateExperienceRequestValues,
} from "../hooks/useExperienceRequestCreate";
import GameSection from "../sections/Game";
import Summary from "../sections/Summary";
import Tickets from "../sections/Tickets";
import Time from "../sections/Time";

interface RequestExperienceContextType {
  cigId: string;
  packageId?: string;

  cigProfile: CIGProfile | null;
  cigProfileIsLoading: boolean;
  cigProfileError?: string;

  packageExperience?: CigPackageExperience;
  packageExperienceIsLoading: boolean;
  packageExperienceError?: string;

  customExperience?: CigCustomExperience;
  customExperienceIsLoading: boolean;
  customExperienceError?: string;

  values: CreateExperienceRequestValues;
  validations: FormValidations<CreateExperienceRequestValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;
  onChangeMultiple: OnFormChangeMultiple;

  createIsLoading: boolean;

  createRequest: () => Promise<void>;
  resetValidations: ResetValidations;

  tabs: Array<Tab | undefined>;
  selectedTab: string | number;
  setSelectedTab: Dispatch<SetStateAction<string | number>>;

  priceIncTax?: number;
  priceExcTax?: number;
  currency?: Currency;

  sessionCost?: CustomSessionCost;
  sessionCostIsLoading: boolean;
  sessionCostError?: string;

  nextTab?: Tab;
  goToNextTab: () => void;
}

export const RequestExperienceContext = createContext<RequestExperienceContextType>(
  {} as RequestExperienceContextType,
);

export default function RequestExperienceContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const { userExtension, breakpoints } = useGlobalContext();

  const { cigId, packageId } = useParams<Params>();
  const platformId = useQuery<string>("platformId");
  const gameId = useQuery<string>("gameId");

  const [selectedTab, setSelectedTab] = useState<string | number>("game");
  const [sessionCost, setSessionCost] = useState<CustomSessionCost>();

  const tabs = [
    {
      value: "game",
      label: "Game",
      component: <GameSection />,
    },
    !packageId
      ? {
          value: "tickets",
          label: "Tickets",
          component: <Tickets />,
        }
      : undefined,
    {
      value: "time",
      label: "Time",
      component: <Time />,
    },
    breakpoints.sm
      ? {
          value: "summary",
          label: "Summary",
          component: <Summary />,
        }
      : undefined,
  ].filter(tab => !!tab) as Array<Tab>;

  const {
    data: cigProfile,
    isLoading: cigProfileIsLoading,
    error: cigProfileError,
  } = useGetCigProfile(cigId, undefined, userExtension?.id, true);

  const {
    data: customExperience,
    isLoading: customExperienceIsLoading,
    error: customExperienceError,
  } = useGetCigCustomExperience(cigId, packageId == null);

  const {
    data: packageExperiences,
    isLoading: packageExperienceIsLoading,
    error: packageExperienceError,
  } = useGetCigPackageExperiences(cigId, packageId != null);

  const packageExperience = packageExperiences.find(
    packageExperience => packageId === packageExperience.packageID,
  );

  const compatibility = (customExperience ?? packageExperience)?.games ?? [];

  const {
    values,
    validations,
    onChange,
    onBlur,
    onChangeMultiple,

    createIsLoading,

    createRequest: handle,
    resetValidations,

    submitted,
    setSubmitted,
  } = useExperienceRequestCreate(
    compatibility,
    platformId,
    gameId,
    packageId,
    cigId,
    packageExperience,
  );

  const {
    data: sessionCostData,
    isLoading: sessionCostIsLoading,
    error: sessionCostError,
  } = useGetCustomSessionCost(
    cigId,
    values.sessionLength,
    values.tickets,
    values.streamOption,
    packageId == null,
  );

  useEffect(() => {
    if (!sessionCostData) return;
    setSessionCost(sessionCostData);
  }, [sessionCostData]);

  const { streamPriceIncTax, streamPriceExcTax } = sessionCost ?? {};
  let priceIncTax = sessionCost?.priceIncTax ?? packageExperience?.priceIncTax;
  if (priceIncTax != null && streamPriceIncTax != null && values.streamOption)
    priceIncTax += streamPriceIncTax;
  let priceExcTax = sessionCost?.priceExcTax ?? packageExperience?.priceExTax;
  if (priceExcTax != null && streamPriceExcTax != null && values.streamOption)
    priceExcTax += streamPriceExcTax;
  const currency = sessionCost?.currency ?? packageExperience?.currency;

  useEffect(() => {
    if (!submitted) return;
    setSubmitted(false);
    const {
      gameId,
      platformId,
      customerRequestDetails,
      sessionLength,
      tickets,
      startTime,
      endTime,
    } = validations;
    const gameFailed = gameId?.failed || platformId?.failed || customerRequestDetails?.failed;
    if (gameFailed) return setSelectedTab("game");
    const ticketsFailed = sessionLength?.failed || tickets?.failed;
    if (ticketsFailed && packageId) return setSelectedTab("tickets");
    const timeFailed = startTime?.failed || endTime?.failed;
    if (timeFailed) return setSelectedTab("time");
  }, [validations, packageId, submitted, setSubmitted]);

  const tabIndex = tabs.findIndex(tab => tab?.value === selectedTab);
  const nextTab = tabs[tabIndex + 1];
  const goToNextTab = () => nextTab && setSelectedTab(nextTab.value);

  return (
    <RequestExperienceContext.Provider
      value={{
        cigId,
        packageId,

        cigProfile,
        cigProfileIsLoading,
        cigProfileError,

        packageExperience,
        packageExperienceIsLoading,
        packageExperienceError,

        customExperience: customExperience ?? undefined,
        customExperienceIsLoading,
        customExperienceError,

        values,
        validations,
        onChange,
        onBlur,
        onChangeMultiple,

        createIsLoading,

        createRequest: handle,
        resetValidations,

        tabs,
        selectedTab,
        setSelectedTab,

        priceIncTax,
        priceExcTax,
        currency,

        sessionCost,
        sessionCostIsLoading,
        sessionCostError,

        nextTab,
        goToNextTab,
      }}>
      {children}
    </RequestExperienceContext.Provider>
  );
}

interface Params {
  cigId: string;
  packageId?: string;
}
