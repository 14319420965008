import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createHolding, { CreateHoldingRes } from "../createHolding";

const useCreateHolding = (availabilityID?: string, slotsRequested?: number) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CreateHoldingRes | null>(
    null,
  );

  const send = useCallback(async () => {
    if (availabilityID == null || slotsRequested == null) return reqState;
    setLoading();
    try {
      const data = await createHolding(availabilityID, slotsRequested);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [availabilityID, slotsRequested, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateHolding;
