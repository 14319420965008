import React from "react";

export default function CashIcon() {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M95.585 98.0376C98.0154 100.609 101.974 100.609 104.439 98.0376L142.249 58.3616C153.221 46.8271 152.596 27.7187 140.305 17.0874C129.611 7.81117 113.674 9.47881 103.883 19.7626L99.9945 23.8275L96.1405 19.7974C86.3494 9.47881 70.4128 7.81117 59.7189 17.0874C47.4627 27.7187 46.803 46.8271 57.7746 58.3616L95.585 98.0376ZM196.274 124.998C192.177 121.28 185.788 121.524 181.483 124.998L149.401 150.673C145.478 153.834 140.583 155.537 135.513 155.537H94.4392C91.3838 155.537 88.884 153.035 88.884 149.978C88.884 146.92 91.3838 144.419 94.4392 144.419H121.625C127.146 144.419 132.284 140.632 133.187 135.177C134.333 128.229 128.986 122.184 122.215 122.184H66.663C57.2885 122.184 48.2265 125.415 40.9352 131.321L24.7903 144.419H5.55525C2.49986 144.419 0 146.92 0 149.978V183.331C0 186.388 2.49986 188.889 5.55525 188.889H129.437C134.472 188.889 139.367 187.187 143.325 184.025L195.823 141.987C201.1 137.783 201.517 129.723 196.274 124.998Z"
        fill="white"
      />
    </svg>
  );
}
