import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Button from "@components/form/Button";
import useFeedbackContext from "../context/useFeedbackContext";
import RatingSelector from "@components/form/RatingSelector";
import { IoArrowForward } from "react-icons/io5";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

export default function RatingStep() {
  const {
    values,
    validations,
    onChange,
    onBlur,

    ratingIsLoading,

    buttonSize,
  } = useFeedbackContext();

  const { breakpoints } = useGlobalContext();

  return (
    <>
      <div className="form-rows">
        <FormRow>
          <FormEntry
            validation={validations.rating}
            relativePosition="left"
            className="rating-entry">
            <RatingSelector
              color="white"
              size={breakpoints.sm ? "large" : "extra-large"}
              name="rating"
              value={values.rating}
              onChange={onChange}
              onBlur={onBlur}
            />
          </FormEntry>
        </FormRow>

        <FormRow>
          <p className="rating">
            <span>{values.rating}</span> out of 5
          </p>
        </FormRow>
      </div>

      <div className="button-wrapper">
        <Button
          size={buttonSize}
          color="purple"
          textColor="white"
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={ratingIsLoading}>
          Rate
        </Button>
        <Button
          size={buttonSize}
          color="black-3"
          textColor="white"
          justifyContent="center"
          type="submit"
          disabled={ratingIsLoading}
          className="skip">
          Not Now
        </Button>
      </div>
    </>
  );
}
