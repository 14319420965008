import React from "react";

import gamingAmico from "@images/misc/gaming_amico.png";
import Button from "@components/form/Button";
import { IoArrowForward } from "react-icons/io5";

export default function NavCta() {
  return (
    <div className="nav-cta">
      <img className="cta-img" src={gamingAmico} alt="Gaming Amico" />

      <h4 className="cta-title">Become Talent</h4>
      <p className="cta-text">Find out more</p>

      <Button
        variant="transparent"
        color="gray-3"
        textColor="white"
        endIcon={<IoArrowForward />}
        to="/become-talent">
        Learn more
      </Button>
    </div>
  );
}
