import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import updateUserExtension, {
  UpdateUserExtension,
  UpdateUserExtensionRes,
} from "../updateUserExtension";

const useUpdateUserExtension = (userExtension?: UpdateUserExtension) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<UpdateUserExtensionRes | null>(null);

  const send = useCallback(async () => {
    if (userExtension == null) return reqState;

    setLoading();
    try {
      const newUserExtension = await updateUserExtension(userExtension);
      return setSuccess(newUserExtension);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [userExtension, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useUpdateUserExtension;
