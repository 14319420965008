import YakkrBanner from "@components/common/YakkrBanner";
import React, { PropsWithChildren, ReactNode } from "react";

interface Props {
  title?: string;
  buttons?: ReactNode;
}

export default function RoomOverlay(props: PropsWithChildren<Props>) {
  const { title, buttons, children } = props;

  return (
    <div className="room-overlay">
      <div className="room-overlay-inner">
        <YakkrBanner to="/bookings" />
        <h1 className="title">{title}</h1>
        <div className="content">{children}</div>
        <div className="button-row">{buttons}</div>
      </div>
    </div>
  );
}
