import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import checkTwitchUsername from "../checkTwitchUsername";

const useCheckTwitchUsername = (twitchUsername?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<boolean | undefined>(
    undefined,
  );

  const send = useCallback(async () => {
    if (twitchUsername == null) return reqState;

    setLoading();
    try {
      const isValid = await checkTwitchUsername(twitchUsername);
      return setSuccess(isValid);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [twitchUsername, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCheckTwitchUsername;
