import { UserExtension } from "@api/private/get/getUserExtension";
import useGetUserExtension from "@api/private/get/hooks/useGetUserExtension";
import { CIGProfile } from "@api/public/get/getCigProfile";
import useGetCigProfile from "@api/public/get/hooks/useGetCigProfile";
import { log } from "@lib/utils/generic";
import { Auth, Hub } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import { FederatedSignInOptions } from "@aws-amplify/auth/lib/types";
import { useHistory, useLocation } from "react-router-dom";
import { AuthState } from "@lib/enums/generic";
import { AuthModalDetails, SetAuthModal } from "./useAuthModal";

const useAuth = (authModal: AuthModalDetails, setAuthModal: SetAuthModal) => {
  const [authState, setAuthState] = useState(AuthState.None);

  const [initialLoading, setInitialLoading] = useState(true);
  const [initialError, setInitialError] = useState<string | null>(null);

  const [user, setUser] = useState(null);
  const [userExtension, setUserExtension] = useState<UserExtension | null>(null);
  const [cigProfile, setCigProfile] = useState<CIGProfile | null>(null);

  const { send: getUserExtension } = useGetUserExtension();
  const { send: getCigProfile } = useGetCigProfile();

  const fetchingUserRef = useRef(false);

  const history = useHistory();
  const location = useLocation();

  const redirect = authModal.data.redirect as string | undefined;

  const getUser = useCallback(
    async (disableLoading?: boolean) => {
      try {
        if (fetchingUserRef.current) return;
        if (!disableLoading) setInitialLoading(true);
        fetchingUserRef.current = true;
        setInitialError(null);

        const user = await Auth.currentAuthenticatedUser();
        setUser(user);

        const { data: _userExtension } = await getUserExtension();
        if (_userExtension) setUserExtension(_userExtension);

        if (
          !_userExtension &&
          !["/cig/register", "/cig/login"].includes(location.pathname) &&
          !["register"].includes(authModal.type ?? "")
        )
          setAuthModal("register", redirect ? { redirect } : undefined);

        const { data: _cigProfile } = await getCigProfile(_userExtension?.cigID ?? undefined);
        if (_cigProfile) setCigProfile(_cigProfile);

        log("User auth successful", user);
        if (_userExtension) log("User extension", _userExtension);
        if (_cigProfile) log("CIG profile", _cigProfile);
      } catch (err: any) {
        log("User auth failed", err);
        setInitialError(err);
        // pushSnackbar(createErrorSnackbar(err, 5000));
      }
      setInitialLoading(false);
      fetchingUserRef.current = false;
    },
    [
      location.pathname,
      authModal.type,
      redirect,
      setInitialLoading,
      getUserExtension,
      setUserExtension,
      getCigProfile,
      setCigProfile,
      setAuthModal,
    ],
  );

  const signIn = async (options: FederatedSignInOptions) => {
    Auth.federatedSignIn(options);
  };

  const signOut = async () => {
    Auth.signOut();
  };

  const handleAuth = useCallback(
    ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser();
          break;
        case "signOut":
          setUser(null);
          window.location.replace("/");
          break;
        case "customOAuthState":
          try {
            const { redirect } = JSON.parse(data);
            if (redirect) history.push(redirect);
          } catch (err) {
            log(err);
          }
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          log("Couldn't login - panic");
          break;
      }
    },
    [history, getUser, setUser],
  );

  useEffect(() => {
    Hub.listen("auth", handleAuth);
    return () => Hub.remove("auth", handleAuth);
  }, [handleAuth]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);
  // must omit getUser to prevent loop

  useEffect(() => {
    // Return if user is not in auth flow
    if (authState === AuthState.None) return;
    // Return if user is already on auth page
    if (["/cig/register", "/cig/login"].includes(location.pathname)) return;
    if (["register", "login"].includes(authModal.type ?? "")) return;
    if (user && !userExtension && !initialLoading) signOut();
    setAuthState(AuthState.None);
  }, [user, userExtension, authState, location.pathname, initialLoading, authModal.type]);

  useEffect(() => {
    if (["/cig/register", "/cig/login"].includes(location.pathname)) return;
    if (["register", "login"].includes(authModal.type ?? "")) return;
    setAuthState(AuthState.None);
  }, [location.pathname, setAuthState, authModal.type]);

  return {
    authState,
    setAuthState,
    user,
    setUser,
    getUser,
    userExtension,
    setUserExtension,
    cigProfile,
    setCigProfile,
    signIn,
    signOut,
    initialLoading,
    initialError,
  };
};

export default useAuth;
