import { api } from "@api/api";

export type UpdateUserExtension = {
  firstName: string;
  lastName: string;
  birthdate: string;
  displayName: string;
};

export type UpdateUserExtensionRes = {
  id: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  displayName: string;
};

const updateUserExtension = async (userExtension: UpdateUserExtension) => {
  const { data } = await api.post<UpdateUserExtensionRes>("/updateUserExtension", userExtension);
  return data;
};

export default updateUserExtension;
