import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getCigReviews, { CIGReview } from "../getCigReviews";

const useGetCigReviews = (cigID?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CIGReview[]>([]);

  const send = useCallback(async () => {
    if (cigID == null) return reqState;
    setLoading();
    try {
      const reviews = await getCigReviews(cigID);
      setSuccess(reviews);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [cigID, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [cigID, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetCigReviews;
