import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import setReadNotifications from "../setReadNotifications";

const useSetReadNotifications = () => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState(null);

  const send = useCallback(async () => {
    setLoading();
    try {
      await setReadNotifications();
      return setSuccess(null);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useSetReadNotifications;
