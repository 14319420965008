import { useState } from "react";

const usePaginate = <T>(data: T[], pageSize = 3, initialPageSize = 3, concatPages = false) => {
  const [numToShow, setNumToShow] = useState(initialPageSize);
  const page = Math.ceil((numToShow - initialPageSize) / pageSize);
  const lastPage = (data.length - initialPageSize) / pageSize;

  const canGoNext = page < lastPage;
  const canGoPrev = page > 0;

  const goToNextPage = () => canGoNext && setNumToShow(numToShow => numToShow + pageSize);
  const goToPrevPage = () => canGoPrev && setNumToShow(numToShow => numToShow - pageSize);

  const goToLastPage = () => canGoNext && setNumToShow(lastPage * pageSize + initialPageSize);
  const goToFirstPage = () => canGoPrev && setNumToShow(initialPageSize);

  const paginatedData: T[] = concatPages
    ? data.slice(0, numToShow)
    : data.slice(page === 0 ? 0 : (page - 1) * pageSize + initialPageSize, numToShow);

  return {
    paginatedData,
    page: page + 1,
    canGoNext,
    canGoPrev,
    goToNextPage,
    goToPrevPage,
    goToLastPage,
    goToFirstPage,
  };
};

export default usePaginate;
