import useRegisterFlankInterest from "@api/public/misc/hooks/useRegisterFlankInterest";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { FlankSignUpValues } from "./useFlankSignUp";

const useFlankSignUpHandles: UseFormHandles<FlankSignUpValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { send: registerFlankInterest, isLoading: signUpIsLoading } = useRegisterFlankInterest(
    values.email,
    values.twitchUsername,
  );

  const signUpHandle = async () => {
    let failed = validateFields(["email", "twitchUsername", "terms"]);

    if (!failed) {
      const { error } = await registerFlankInterest();

      if (error) {
        failed = true;

        validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    return failed;
  };

  return [signUpHandle, signUpIsLoading];
};

export default useFlankSignUpHandles;
