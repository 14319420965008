import Slide, { SlideProps } from "@components/transitions/Slide";
import React, { PropsWithChildren, useState } from "react";
import Intersection from "./Intersection";

interface Props extends Omit<SlideProps, "in" | "mountOnEnter" | "unmountOnExit"> {
  useParent?: boolean;
}

export default function SlideOnIntersect(props: PropsWithChildren<Props>) {
  const { useParent = true, ...slideProps } = props;

  const [intersected, setIntersected] = useState(false);

  return (
    <Intersection
      onIntersect={entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.intersectionRatio > 0) setIntersected(true);
        });
      }}
      useParent={useParent}>
      <Slide {...slideProps} in={intersected} mountOnEnter={false} unmountOnExit={false} />
    </Intersection>
  );
}
