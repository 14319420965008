import { pathHasNav } from "@lib/utils/generic";
import StorageItem from "@lib/utils/StorageItem";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useGlobalContext from "../globalContext/hooks/useGlobalContext";
import usePrevious from "./usePrevious";

const storageItem = new StorageItem("drawerExpanded");

const useDrawerState = () => {
  const { breakpoints, drawerExpanded, drawerActive, setDrawerExpanded, setDrawerActive } =
    useGlobalContext();

  const location = useLocation();

  const hasNav = pathHasNav(location.pathname);

  const type = breakpoints.lg || !hasNav ? "temporary" : "permanent";
  const prevType = usePrevious(type);

  useEffect(() => {
    const storeDrawerExpanded = storageItem.get().data === "true";
    if (type === "permanent" && prevType === "temporary") {
      if (storeDrawerExpanded && !drawerExpanded) setDrawerExpanded(true);
      else if (!storeDrawerExpanded && drawerExpanded) setDrawerExpanded(false);
    }

    if (type === "temporary" && !drawerExpanded) setDrawerExpanded(true);
    if (type === "permanent") storageItem.set(drawerExpanded + "");
  }, [type, prevType, drawerExpanded, setDrawerExpanded]);

  useEffect(() => {
    if (!hasNav && drawerActive) setDrawerActive(false);
    if (type === "permanent" && !drawerActive) setDrawerActive(true);
    if (prevType === "permanent" && type === "temporary") setDrawerActive(false);
  }, [type, prevType, hasNav, drawerActive, setDrawerActive]);
};

export default useDrawerState;
