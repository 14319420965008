import { CSSProps, UIColor } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React from "react";

interface Props extends CSSProps {
  progressColor?: UIColor;
  barColor?: UIColor;
  width?: number;
  height?: number;
  percent?: number;
}

export default function ProgressBar(props: Props) {
  const {
    progressColor = "gray-3",
    barColor = "black-3",
    width = 300,
    height = 10,
    percent = 0,

    className,
    style,
    id,
  } = props;

  return (
    <div
      className={classNames("progress-bar", barColor, className)}
      style={{ maxWidth: width, height, ...style }}
      id={id}>
      <div
        className={classNames("progress-bar-inner", progressColor)}
        style={{ width: `${percent}%` }}
      />
    </div>
  );
}
