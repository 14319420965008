import Avatar from "@components/common/Avatar";
import Divider from "@components/common/Divider";
import Button from "@components/form/Button";
import CircularBlur from "@components/shapes/CircularBlur";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

import avatar1 from "@images/landing/consultancy/avatars/avatar1.png";
import avatar2 from "@images/landing/consultancy/avatars/avatar2.png";
import avatar3 from "@images/landing/consultancy/avatars/avatar3.png";
import avatar4 from "@images/landing/consultancy/avatars/avatar4.png";
import avatar5 from "@images/landing/consultancy/avatars/avatar5.png";
import avatar6 from "@images/landing/consultancy/avatars/avatar6.png";
import avatar7 from "@images/landing/consultancy/avatars/avatar7.png";
import avatar8 from "@images/landing/consultancy/avatars/avatar8.png";

import topSplashImg from "@images/landing/consultancy/top_splash.png";

const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8];

const TopSplash = () => {
  const { breakpoints } = useGlobalContext();

  const avatarSize = breakpoints.sm ? 35 : breakpoints.md ? 30 : 35;

  return (
    <section className="pane top-splash">
      <div className="inner">
        <h1 className="title">
          Trying to grow your Twitch stream but things are going painfully slow?
        </h1>
        <h2 className="sub-title">
          Want the most effective way to organically grow on Twitch? Get expert help from our team
          of Twitch Partners, marketers, and growth specialists that will help{" "}
          <span>turn your stream into a thriving career.</span>
        </h2>

        <Button
          size={breakpoints.sm ? "medium" : breakpoints.lg ? "large" : "extra-large"}
          color="purple"
          textColor="white"
          href="#sign-up">
          Sign up now
        </Button>

        <div className="affiliates-bar-wrapper">
          <div className="dividers">
            <Divider color="white" />
            <Divider color="white" />
          </div>

          <div className="affiliates-bar">
            <div className="background"></div>
            <p className="text">
              Join Twitch affiliates already growing their streams with our services
            </p>
            <div className="avatars">
              {avatars.map((avatar, i) => (
                <Avatar key={i} src={avatar} alt="Avatar" size={avatarSize} />
              ))}
              <Avatar alt="Avatar" size={avatarSize}>
                <p className="plus-text">+more</p>
              </Avatar>
            </div>
          </div>
        </div>

        <img className="top-splash-img" src={topSplashImg} alt="Laptop with dashboard on screen" />
      </div>

      <div className="circular-blur-wrapper">
        <CircularBlur color="purpleBlue" />
      </div>
    </section>
  );
};

export default TopSplash;
