import Progress from "@components/common/Progress";
import { classNames } from "@lib/utils/generic";
import {
  cloneElement,
  FocusEventHandler,
  forwardRef,
  MouseEventHandler,
  ReactElement,
} from "react";
import { Link } from "react-router-dom";
import { CSSProps, UIColor } from "../../lib/types/generic";

interface Props extends CSSProps {
  color?: UIColor;
  iconColor?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent";
  size?: "no-padding" | "extra-small" | "small" | "medium" | "large" | "extra-large";
  icon?: ReactElement;
  type?: "button" | "submit" | "reset";
  name?: string;
  count?: number;
  disabled?: boolean;
  readOnly?: boolean;
  disableHover?: boolean;
  isLoading?: boolean;
  rounded?: boolean;
  active?: boolean;
  form?: string;
  to?: string;
  href?: string;
  openInNewTab?: boolean;
  tabIndex?: number;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseUp?: MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
  onDoubleClick?: MouseEventHandler<HTMLButtonElement>;
  onBlur?: FocusEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onFocus?: FocusEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

export type IconButtonProps = Props;

const IconButton = forwardRef<HTMLButtonElement & HTMLAnchorElement, Props>((props, ref) => {
  const {
    color = "black",
    iconColor = "white",
    variant = "contained",
    size = "medium",

    icon: propsIcon,
    type = "button",
    name,
    count,
    disabled = false,
    readOnly = false,
    disableHover = false,
    isLoading = false,
    rounded = false,
    active = false,
    form,
    to,
    href,
    openInNewTab,
    tabIndex,

    onClick,
    onMouseUp,
    onMouseDown,
    onDoubleClick,

    className = "",
    style,
    id,
    testId,

    children,

    ...events
  } = props;

  const icon = isLoading ? (
    <div className="progress-wrapper">
      <Progress variant="circular" color={iconColor} />
    </div>
  ) : (
    propsIcon
  );
  const nodes = (
    <>
      {count != null && count > 0 && (
        <div className="count-circle">
          <span>{count > 99 ? "99" : count}</span>
        </div>
      )}
      {icon &&
        cloneElement(icon, {
          className: `button-icon icon-button-icon ${icon.props.className ?? ""}`,
        })}
    </>
  );

  const handleClick =
    (onFunc: (event: React.MouseEvent<HTMLButtonElement>) => void) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      onFunc(event);
    };

  const fullClassName = classNames(
    "icon",
    readOnly && "read-only",
    disableHover && "hover-disabled",
    isLoading && "is-loading",
    rounded && "rounded",
    active && "active",
    color,
    `${iconColor}-icon`,
    size,
    variant,
    className,
  );

  if ((to == null && href == null) || disabled) {
    return (
      <button
        ref={ref}
        type={type}
        name={name}
        disabled={disabled}
        form={form}
        onClick={onClick ? handleClick(onClick) : undefined}
        onMouseUp={onMouseUp ? handleClick(onMouseUp) : undefined}
        onMouseDown={onMouseDown ? handleClick(onMouseDown) : undefined}
        onDoubleClick={onDoubleClick ? handleClick(onDoubleClick) : undefined}
        style={style}
        className={fullClassName}
        id={id}
        tabIndex={tabIndex}
        data-testid={testId}
        {...events}>
        {nodes}
      </button>
    );
  } else if (to != null) {
    return (
      <Link
        ref={ref}
        type={type}
        style={style}
        className={fullClassName}
        id={id}
        to={to}
        tabIndex={tabIndex}
        data-testid={testId}
        {...events}>
        {nodes}
      </Link>
    );
  } else {
    return (
      <a
        ref={ref}
        type={type}
        style={style}
        className={fullClassName}
        id={id}
        href={href}
        {...(openInNewTab
          ? {
              target: "_blank",
              rel: "noopener noreferrer",
            }
          : {})}
        tabIndex={tabIndex}
        data-testid={testId}
        {...events}>
        {nodes}
      </a>
    );
  }
});

export default IconButton;
