import CigAuthWrapper from "@pages/cigAuth/CigAuthWrapper";
import React from "react";
import CigLoginForm from "./CigLoginForm";
import useCigLoginContext from "./context/useCigLoginContext";

export default function CigLoginInner() {
  const { step } = useCigLoginContext();

  return (
    <div id="cig-login-page">
      <CigAuthWrapper
        title="Time to login."
        subTitle="Create your account here."
        progressPercent={12.5 / (step + 1)}>
        <CigLoginForm />
      </CigAuthWrapper>
    </div>
  );
}
