import { UIColor } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import { HTMLProps } from "react";
import { Link } from "react-router-dom";

interface Props {
  color?: UIColor;
  size?: "extra-small" | "small" | "medium" | "large";
  svgProps?: HTMLProps<SVGElement>;
  to?: string;
  disabled?: boolean;
  animated?: boolean;
  onClick?: () => void;
}

export default function YakkrGrowthBanner(props: Props) {
  const {
    color = "white",
    size = "medium",
    svgProps = {},
    to,
    disabled,
    animated,
    onClick,
  } = props;

  const width = {
    "extra-small": 80,
    small: 100,
    medium: 125,
    large: 200,
  }[size];

  const nodes = (
    <svg
      fill="currentColor"
      width={width}
      viewBox="0 0 133 55"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={classNames(
        "yakkr-banner",
        color,
        disabled && "disabled",
        animated && "animated",
        (onClick || to) && "clickable",
      )}
      {...(svgProps as any)}>
      <path d="M27.0155 9.12671L14.0127 44.818C13.9477 44.995 13.8334 45.1472 13.6847 45.2547C13.5359 45.3622 13.3598 45.4199 13.1793 45.4203H7.6701C7.52525 45.4203 7.38258 45.3832 7.2544 45.3123C7.12622 45.2414 7.01638 45.1387 6.93435 45.0132C6.85232 44.8877 6.80056 44.7431 6.78354 44.5919C6.76652 44.4407 6.78475 44.2874 6.83665 44.1452L10.1407 35.0731C9.40704 35.0726 8.69071 34.8387 8.08593 34.4021C7.48115 33.9655 7.01623 33.3467 6.75232 32.6271L0.340229 15.14C0.043146 14.3288 -0.0616768 13.4538 0.0348096 12.5905C0.131296 11.7272 0.426192 10.9016 0.894041 10.1849C1.36189 9.46818 1.98863 8.88195 2.72016 8.47679C3.45169 8.07164 4.26602 7.85974 5.09286 7.85938V7.85938L12.5989 28.3322L18.8001 11.2989C19.1691 10.2865 19.8218 9.41564 20.6717 8.80151C21.5216 8.18737 22.5288 7.85885 23.5602 7.85938H26.1796C26.3239 7.85887 26.4662 7.89525 26.5942 7.96541C26.7223 8.03556 26.8321 8.13738 26.9144 8.26209C26.9966 8.38679 27.0488 8.53066 27.0664 8.68128C27.0841 8.8319 27.0666 8.98477 27.0155 9.12671Z" />
      <path d="M48.1159 11.8088C48.1192 10.7522 48.5202 9.73988 49.2314 8.99323C49.9426 8.24659 50.906 7.82629 51.9111 7.82423H53.8955C54.2244 7.82423 54.5399 7.9616 54.7725 8.20611C55.005 8.45063 55.1357 8.78227 55.1357 9.12807V34.1148C55.1357 34.3638 55.0416 34.6026 54.8742 34.7786C54.7067 34.9547 54.4796 35.0536 54.2427 35.0536H49.0014C48.7646 35.0536 48.5375 34.9547 48.37 34.7786C48.2025 34.6026 48.1085 34.3638 48.1085 34.1148V31.2464H47.8877C46.1365 33.6142 43.1748 35.0458 38.3179 35.0458C27.4534 35.0458 26.2305 25.0975 26.2305 21.4311C26.2305 17.4622 27.3864 7.81641 38.3378 7.81641C43.1251 7.81641 46.0571 9.40187 47.8133 11.814L48.1159 11.8088ZM47.6694 21.4259C47.6694 17.7412 46.4292 14.1583 40.3668 14.1583C33.6422 14.1583 32.9253 19.3084 32.9253 21.4259C32.9253 23.3816 33.6695 28.6935 40.347 28.6935C46.4292 28.6856 47.6694 25.5564 47.6694 21.4259Z" />
      <path d="M74.8292 19.1951L85.726 35.0394H77.5403C77.3707 35.0399 77.2034 34.9979 77.0524 34.9167C76.9014 34.8355 76.771 34.7175 76.6722 34.5726L69.7268 24.4626L64.6864 29.678V34.025C64.6864 34.2739 64.5923 34.5127 64.4249 34.6888C64.2574 34.8648 64.0303 34.9637 63.7934 34.9637H58.5844C58.3476 34.9637 58.1204 34.8648 57.953 34.6888C57.7855 34.5127 57.6914 34.2739 57.6914 34.025V5.35357C57.6917 4.64974 57.824 3.95289 58.0807 3.30284C58.3373 2.65279 58.7133 2.06229 59.1872 1.5651C59.6611 1.06791 60.2235 0.673772 60.8423 0.405223C61.4612 0.136674 62.1243 -0.00102235 62.7938 5.71446e-06H63.786C63.9033 5.71446e-06 64.0194 0.0242875 64.1277 0.0714648C64.2361 0.118642 64.3345 0.187791 64.4174 0.274963C64.5003 0.362135 64.5661 0.465624 64.611 0.57952C64.6559 0.693416 64.679 0.815489 64.679 0.938769V19.6619L74.601 9.36939C75.5557 8.37673 76.8449 7.82092 78.1878 7.82303H85.8227L74.8292 19.1951Z" />
      <path d="M100.861 19.1951L110.733 33.5504C110.83 33.6904 110.887 33.8556 110.9 34.0279C110.913 34.2002 110.88 34.3729 110.805 34.5269C110.731 34.6808 110.617 34.8101 110.477 34.9006C110.337 34.991 110.176 35.039 110.011 35.0394H103.622C103.44 35.0394 103.26 34.994 103.098 34.907C102.936 34.82 102.796 34.6937 102.689 34.5387L95.7609 24.4626L90.7205 29.678V34.111C90.7205 34.2343 90.6974 34.3564 90.6525 34.4703C90.6077 34.5842 90.5419 34.6877 90.459 34.7748C90.376 34.862 90.2776 34.9311 90.1693 34.9783C90.0609 35.0255 89.9448 35.0498 89.8275 35.0498H84.8839C84.5784 35.0498 84.2854 34.9224 84.0692 34.6956C83.8529 34.4687 83.7311 34.161 83.7305 33.8398V5.35356C83.7311 3.93347 84.2682 2.57178 85.2236 1.56786C86.179 0.563951 87.4746 -1.68301e-07 88.8254 0L89.5696 0C89.8748 0 90.1676 0.127478 90.3834 0.35439C90.5992 0.581302 90.7205 0.88906 90.7205 1.20996V19.6619L100.643 9.36938C101.595 8.37927 102.88 7.82366 104.219 7.82303H110.86C110.94 7.82299 111.019 7.84824 111.087 7.89555C111.154 7.94287 111.206 8.01011 111.236 8.08871C111.267 8.16731 111.275 8.2537 111.258 8.33689C111.242 8.42007 111.203 8.49628 111.145 8.55579L100.861 19.1951Z" />
      <path d="M131.897 9.38882L131.076 14.492C131.056 14.6156 131.014 14.7337 130.95 14.8395C130.886 14.9453 130.803 15.0366 130.705 15.1079C130.607 15.1791 130.497 15.229 130.38 15.2545C130.264 15.2799 130.143 15.2805 130.026 15.2561C128.79 14.9977 127.532 14.8666 126.271 14.8649C121.062 14.8649 119.509 19.1363 119.509 22.8236V34.094C119.509 34.3429 119.415 34.5817 119.248 34.7578C119.08 34.9338 118.853 35.0327 118.616 35.0327H113.377C113.26 35.0327 113.144 35.0084 113.036 34.9613C112.927 34.9141 112.829 34.8449 112.746 34.7578C112.663 34.6706 112.597 34.5671 112.552 34.4532C112.507 34.3393 112.484 34.2172 112.484 34.094V13.1804C112.484 11.7612 113.02 10.4 113.974 9.39577C114.928 8.39152 116.222 7.82629 117.572 7.82422H118.611C118.728 7.82422 118.845 7.8485 118.953 7.89568C119.061 7.94286 119.16 8.012 119.243 8.09918C119.326 8.18635 119.391 8.28984 119.436 8.40373C119.481 8.51763 119.504 8.6397 119.504 8.76298V11.8244H119.943C121.384 9.21672 122.982 7.82422 127.432 7.82422C128.707 7.83891 129.975 8.00512 131.215 8.31968C131.437 8.37236 131.631 8.51255 131.758 8.71142C131.885 8.91029 131.935 9.15271 131.897 9.38882V9.38882Z" />
      <path
        d="M52.54 43.92L52.1 46.48H54.84C54.1 47.2 53.04 47.56 51.6 47.56C49.06 47.56 47.96 46.76 48.38 44.42C48.78 42.1 50.16 41.3 52.7 41.3C55.68 41.3 56.66 42.46 56.66 42.46L59.02 40.76C59.02 40.76 57.46 38.66 53.18 38.66C49.26 38.66 46.02 40.9 45.4 44.42C44.78 47.96 47.22 50.18 51.14 50.18C52.58 50.18 53.96 49.84 55.18 49.18L55.02 50H57.94L59 43.92H52.54ZM72.5866 42.86C72.8266 41.46 72.4066 38.84 69.0466 38.84H62.0666L60.1066 50H63.0066L63.5266 47.14H66.1866L67.7466 50H71.1666L69.4666 46.84C71.3666 46.16 72.3266 44.38 72.5866 42.86ZM69.8066 43.1C69.8066 43.16 69.5266 44.58 68.2466 44.58H63.9666L64.5266 41.38H68.5866C69.9666 41.38 69.9066 42.58 69.8066 43.1ZM81.6195 38.66C77.6995 38.66 74.4595 40.9 73.8395 44.42C73.2195 47.96 75.6595 50.18 79.5795 50.18C83.4995 50.18 86.7395 47.96 87.3595 44.42C87.9795 40.9 85.5395 38.66 81.6195 38.66ZM84.3795 44.42C83.9795 46.76 82.5995 47.56 80.0395 47.56C77.4995 47.56 76.3995 46.76 76.8195 44.42C77.2195 42.1 78.5995 41.3 81.1595 41.3C83.6995 41.3 84.7995 42.1 84.3795 44.42ZM101.239 47.4L100.279 38.84H97.0588L93.0988 47.4L92.1388 38.84H89.1788L90.4188 50.02H94.8388L97.8588 43.52L98.5588 50.02H102.979L108.179 38.84H105.219L101.239 47.4ZM108.574 41.38H112.394L110.874 50H113.774L115.294 41.38H119.094L119.554 38.84H109.034L108.574 41.38ZM128.751 43.14H123.371L124.131 38.84H121.211L119.251 50H122.151L122.911 45.7H128.311L127.551 50H130.451L132.431 38.84H129.511L128.751 43.14Z"
        fill="url(#paint0_linear_8277_82431)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8277_82431"
          x1="48.1482"
          y1="43.1356"
          x2="138.16"
          y2="47.6471"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.01365" stop-color="#32E6FF" />
          <stop offset="0.505208" stop-color="#5781FE" />
          <stop offset="1" stop-color="#9F6FFD" />
        </linearGradient>
      </defs>
    </svg>
  );

  if (to == null) return nodes;

  return (
    <Link to={to} onClick={onClick} className={classNames(disabled && "disabled no-visual")}>
      {nodes}
    </Link>
  );
}
