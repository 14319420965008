import { useState } from "react";

const useImagesLoading = () => {
  const [imagesLoading, setImagesLoading] = useState<string[]>([]);

  const spliceImageLoading = (image: string) => {
    setImagesLoading(images => {
      const index = images.indexOf(image);
      if (index < 0) return images;
      const imagesCopy = [...images];
      imagesCopy.splice(index, 1);
      return imagesCopy;
    });
  };

  const pushImageLoading = (image: string) => {
    setImagesLoading(images => [...images, image]);
  };

  return { imagesLoading, setImagesLoading, spliceImageLoading, pushImageLoading };
};

export default useImagesLoading;
