import { Auth } from "aws-amplify";

const login = async (email: string, password: string, staySignedIn: boolean = false) => {
  const storage = staySignedIn ? localStorage : sessionStorage;
  Auth.configure({ storage });

  const attemptLogin = () =>
    Auth.signIn({
      username: email,
      password: password,
    });

  try {
    const { user } = await attemptLogin();
    return user;
  } catch (err: any) {
    if (err?.name === "QuotaExceededError") {
      storage.clear();
      const { user } = await attemptLogin();
      return user;
    }
    throw err;
  }
};

export default login;
