import Slide from "@components/transitions/Slide";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createWarnSnackbar } from "@lib/utils/generic";
import dayjs from "dayjs";
import { useEffect } from "react";
import useRoomContext from "../roomContext/hooks/useRoomContext";
import useTimeLeft from "../hooks/useTimeLeft";

interface Props {
  visible?: boolean;
  fixed?: boolean;
}

export default function Title(props: Props) {
  const { visible = true, fixed = false } = props;

  const { session, overtimeSnackbarSent, setOvertimeSnackbarSent } = useRoomContext();
  const { pushSnackbar } = useGlobalContext();

  const { cig, game, to } = session ?? {};

  let { timeLeft } = useTimeLeft(dayjs(to));

  const overtime = timeLeft < 0;

  const { hoursPadded, minutesPadded, secondsPadded } = useTimeLeft(dayjs(to));

  useEffect(() => {
    if (overtime && !overtimeSnackbarSent) {
      pushSnackbar(createWarnSnackbar("Session will end in 5 minutes."));
      setOvertimeSnackbarSent(true);
    }
  }, [overtime, overtimeSnackbarSent, pushSnackbar, setOvertimeSnackbarSent]);

  return (
    <Slide in={visible || overtime} appear direction="down">
      <section className={`title ${fixed ? "fixed" : ""}`}>
        <p className={overtime ? "overtime" : ""}>
          {cig?.displayName?.toLocaleLowerCase()} | {game?.shortName} | {hoursPadded}:
          {minutesPadded}:{secondsPadded}
        </p>
      </section>
    </Slide>
  );
}
