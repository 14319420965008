import SlideOnIntersect from "@components/common/SlideOnIntersect";
import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import { ReactElement } from "react";
import { HiOutlineArrowRight } from "react-icons/hi";
import SplashBox from "../SplashBox";
import SplashTitle from "../SplashTitle";

interface Step {
  icon: ReactElement;
  title: string;
}

interface Props {
  steps: [Step, Step, Step, Step];
}

const HowItWorksSplash = (props: Props) => {
  const { steps } = props;

  return (
    <section className="section how-it-works" id="how-it-works">
      <SplashTitle center>How does it work?</SplashTitle>

      <div className="boxes">
        <div className="group">
          <Box icon={steps[0].icon} text={steps[0].title} timeout={500} direction="right" />
          <DividerIcon timeout={800} />
          <Box icon={steps[1].icon} text={steps[1].title} timeout={700} direction="right" />
        </div>
        <DividerIcon timeout={800} className="middle" />
        <div className="group">
          <Box icon={steps[2].icon} text={steps[2].title} timeout={500} direction="left" />
          <DividerIcon timeout={800} />
          <Box icon={steps[3].icon} text={steps[3].title} timeout={700} direction="left" />
        </div>
      </div>
    </section>
  );
};

interface BoxProps {
  icon: ReactElement;
  text: string;
  timeout: number;
  direction: "left" | "down" | "right" | "up";
}

const Box = (props: BoxProps) => {
  const { icon, text, timeout, direction } = props;

  return (
    <SlideOnIntersect direction={direction} timeout={timeout} useParent={false}>
      <SplashBox className="box">
        {icon}
        <p className="text">{text}</p>
      </SplashBox>
    </SlideOnIntersect>
  );
};

interface DividerIconProps extends CSSProps {
  timeout: number;
}

const DividerIcon = (props: DividerIconProps) => {
  const { timeout, className, ...cssProps } = props;

  return (
    <SlideOnIntersect direction="up" distance="long" timeout={timeout} useParent={false}>
      <div className={classNames("divider-arrow-wrapper", className)} {...cssProps}>
        <HiOutlineArrowRight />
      </div>
    </SlideOnIntersect>
  );
};

export default HowItWorksSplash;
