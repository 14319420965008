import useGetAllBookings from "@api/private/get/hooks/useGetAllBookings";
import useGetUpcomingSessions from "@api/private/get/hooks/useGetUpcomingSessions";
import useUserIsCig from "@hooks/session/useUserIsCig";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { CancellationStatus, Currency } from "@lib/enums/generic";
import { useParams } from "react-router-dom";
import BookingSelector from "./BookingSelector";
import BookingViewer from "./BookingViewer";
import useQuery from "@hooks/useQuery";
import { MyBookingPartial } from "@api/private/get/getAllBookings";
import { CigUpcomingSession } from "@api/private/get/getCigUpcomingSessions";

export interface Booking {
  type: "booking" | "session";
  sessionId: string;
  bookingId?: string;
  ticketId?: string;
  cigID?: string;
  cigName?: string;
  cigProfileImg?: string;
  bannerImg?: string;
  gameName: string;
  platformName: string;
  from: string;
  to: string;
  price: number;
  free: boolean;
  currency: Currency;
  slotsAvailable: number;
  maxSlots: number;
  extraInfo: string;
  charity: boolean;
  charityName: string | null;
  private: boolean;
  cancellation?: CancellationStatus | null;
  ticketsSold?: number;
  participants?: string[];
  accessCode?: string | null;
}

export default function Bookings() {
  const { id: selectedId } = useParams<Params>();
  const selectedType = useQuery<"booking" | "session">("type");
  const claimLinkingID = useQuery("linkingID");

  const isCig = useUserIsCig();

  const { breakpoints, cigProfile } = useGlobalContext();

  const {
    data: myBookings,
    error: myBookingsError,
    isLoading: myBookingsIsLoading,
    send: getAllBookings,
  } = useGetAllBookings(true);

  const {
    data: sessions,
    error: sessionsError,
    isLoading: sessionsIsLoading,
    send: getUpcomingSessions,
  } = useGetUpcomingSessions(isCig);

  const error = myBookingsError || sessionsError;
  const isLoading = myBookingsIsLoading || sessionsIsLoading;

  const bookings = [
    ...myBookings.map(myBookingToBooking),
    ...sessions.filter(session => session).map(sessionToBooking(cigProfile?.id)),
  ];

  const booking = bookings.find(({ sessionId, bookingId, type }) => {
    const isSession = type === "session";
    const id = isSession ? sessionId : bookingId;

    return selectedType === type && selectedId === id?.toString();
  });

  return (
    <div id="bookings-page">
      {(!breakpoints.md || selectedId == null) && (
        <BookingSelector
          error={error}
          isLoading={isLoading}
          bookings={bookings}
          claimLinkingID={claimLinkingID ?? undefined}
        />
      )}
      {(!breakpoints.md || selectedId != null) && (
        <BookingViewer
          error={error}
          isLoading={isLoading}
          booking={booking}
          onBookingCancel={getAllBookings}
          onSessionCancel={getUpcomingSessions}
        />
      )}
    </div>
  );
}

interface Params {
  id: string;
}

const myBookingToBooking = (myBooking: MyBookingPartial): Booking => {
  const { availabilityID, session, bookingID, tickets, currency, cancellation } = myBooking;
  const {
    game,
    platform,
    from,
    to,
    slotsAvailable,
    maxSlots,
    extraInfo,
    cig,
    charity,
    charityName,
    free,
    private: privateSession,
  } = session;
  const price = parseFloat(myBooking.price);

  return {
    type: "booking",
    sessionId: availabilityID,
    bookingId: bookingID,
    ticketId: tickets[0].ticketID,
    cigID: cig?.id,
    cigName: cig?.displayName,
    cigProfileImg: cig?.profilePictureURL ?? undefined,
    bannerImg: cig?.bannerPictureURL ?? undefined,
    gameName: game.longName,
    platformName: platform.name,
    from,
    to,
    price,
    free,
    currency,
    slotsAvailable,
    maxSlots,
    extraInfo,
    charity,
    charityName,
    private: privateSession,
    cancellation,
  };
};

const sessionToBooking =
  (cigID?: string) =>
  (session: CigUpcomingSession): Booking => {
    const {
      id,
      game,
      platform,
      from,
      to,
      price,
      free,
      currency,
      slotsAvailable,
      maxSlots,
      extraInfo,
      charity,
      charityName,
      displayNames,
      private: privateSession,
      accessCode,
    } = session;

    return {
      type: "session",
      sessionId: id,
      bannerImg: game.secondaryImageURL,
      gameName: game.longName,
      platformName: platform.name,
      cigID,
      from,
      to,
      price,
      free,
      currency,
      slotsAvailable,
      maxSlots,
      extraInfo,
      charity,
      charityName,
      private: privateSession,
      participants: displayNames,
      accessCode,
    };
  };
