import React from "react";
import CigLoginInner from "./CigLoginInner";
import CigLoginContextProvider from "./context/CigLoginContextProvider";

export default function CigLogin() {
  return (
    <CigLoginContextProvider>
      <CigLoginInner />
    </CigLoginContextProvider>
  );
}
