import { Conversation, Participant } from "@twilio/conversations";
import { useCallback, useEffect, useState } from "react";
import {
  LocalParticipant,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
} from "twilio-video";
import RoomParticipant, {
  ChatParticipant,
  LocalRoomParticipant,
  VideoParticipant,
} from "../../objects/RoomParticipant";
import { ChannelNumber } from "./useRoom";
import {
  findParticipant,
  getChatParticipants,
  getVideoParticipants,
  ParticipantResolvable,
  updateParticipant,
} from "../../hooks/participantUtils";
import { log } from "@lib/utils/generic";
import { ChannelChangeEvent } from "./useRoomSocket";

const useParticipants = (videoRoom?: Room, chatRoom?: Conversation, roomSocket?: WebSocket) => {
  const [participants, setParticipants] = useState<Array<RoomParticipant>>([]);

  const localParticipant = participants.find(({ isLocal }) => isLocal) as
    | LocalRoomParticipant
    | undefined;

  const handleParticipantUpdate = useCallback((participant: Participant | VideoParticipant) => {
    setParticipants(participants =>
      updateParticipant(participants, participant as ChatParticipant | VideoParticipant),
    );
  }, []);

  const handleChatParticipantUpdate = useCallback(
    ({ participant }: { participant: Participant }) => {
      handleParticipantUpdate(participant);
    },
    [handleParticipantUpdate],
  );

  const toggleParticipantAudio = useCallback((participant: ParticipantResolvable) => {
    setParticipants(participants => {
      const { index, roomParticipant } = findParticipant(participants, participant);
      if (index < 0) return participants;
      roomParticipant.toggleAudio();
      participants[index] = roomParticipant.clone();
      return [...participants];
    });
  }, []);

  const toggleParticipantVideo = useCallback((participant: ParticipantResolvable) => {
    setParticipants(participants => {
      const { index, roomParticipant } = findParticipant(participants, participant);
      if (index < 0) return participants;
      roomParticipant.toggleVideo();
      participants[index] = roomParticipant.clone();
      return [...participants];
    });
  }, []);

  const setParticipantVolume = useCallback((participant: ParticipantResolvable, volume: number) => {
    setParticipants(participants => {
      const { index, roomParticipant } = findParticipant(participants, participant);
      if (index < 0) return participants;
      roomParticipant.setVolume(volume);
      participants[index] = roomParticipant.clone();
      return [...participants];
    });
  }, []);

  const updateTrack = useCallback(
    (participant: RemoteParticipant | LocalParticipant) => {
      setParticipants(participants => {
        const { index, roomParticipant } = findParticipant(participants, participant);
        if (index < 0) return participants;
        roomParticipant.updateTracks();
        participants[index] = roomParticipant.clone();
        return [...participants];
      });
    },
    [setParticipants],
  );

  const handleTrackUpdate = useCallback(
    (_publication: RemoteTrackPublication, participant: RemoteParticipant) =>
      updateTrack(participant),
    [updateTrack],
  );

  const handleTrackSubscriptionUpdate = useCallback(
    (_track: RemoteTrack, _publication: RemoteTrackPublication, participant: RemoteParticipant) =>
      updateTrack(participant),
    [updateTrack],
  );

  const handleLocalTrackUpdate = useCallback(() => {
    const { videoParticipant } = localParticipant ?? {};
    if (videoParticipant) updateTrack(videoParticipant);
  }, [localParticipant, updateTrack]);

  useEffect(() => {
    const { videoParticipant } = localParticipant ?? {};

    videoRoom?.on("participantConnected", handleParticipantUpdate);
    videoRoom?.on("participantReconnected", handleParticipantUpdate);
    videoRoom?.on("participantReconnecting", handleParticipantUpdate);

    videoRoom?.on("trackPublished", handleTrackUpdate);
    videoRoom?.on("trackUnpublished", handleTrackUpdate);
    videoRoom?.on("trackEnabled", handleTrackUpdate);
    videoRoom?.on("trackDisabled", handleTrackUpdate);
    videoRoom?.on("trackSubscribed", handleTrackSubscriptionUpdate);

    videoParticipant?.on("trackPublished", handleLocalTrackUpdate);
    videoParticipant?.on("trackEnabled", handleLocalTrackUpdate);
    videoParticipant?.on("trackDisabled", handleLocalTrackUpdate);

    return () => {
      videoRoom?.off("participantConnected", handleParticipantUpdate);
      videoRoom?.off("participantReconnected", handleParticipantUpdate);
      videoRoom?.off("participantReconnecting", handleParticipantUpdate);

      videoRoom?.off("trackPublished", handleTrackUpdate);
      videoRoom?.off("trackUnpublished", handleTrackUpdate);
      videoRoom?.off("trackEnabled", handleTrackUpdate);
      videoRoom?.off("trackDisabled", handleTrackUpdate);
      videoRoom?.off("trackSubscribed", handleTrackSubscriptionUpdate);

      videoParticipant?.off("trackPublished", handleLocalTrackUpdate);
      videoParticipant?.off("trackEnabled", handleLocalTrackUpdate);
      videoParticipant?.off("trackDisabled", handleLocalTrackUpdate);
    };
  }, [
    videoRoom,
    localParticipant,
    handleParticipantUpdate,
    handleTrackUpdate,
    handleTrackSubscriptionUpdate,
    handleLocalTrackUpdate,
  ]);

  useEffect(() => {
    chatRoom?.on("participantJoined", handleParticipantUpdate);
    chatRoom?.on("participantUpdated", handleChatParticipantUpdate);
    return () => {
      chatRoom?.off("participantJoined", handleParticipantUpdate);
      chatRoom?.off("participantUpdated", handleChatParticipantUpdate);
    };
  }, [chatRoom, handleParticipantUpdate, handleChatParticipantUpdate]);

  const handleSocketMessage = useCallback(
    async (event: MessageEvent<string>) => {
      const data = JSON.parse(event.data);
      if (data.message || data.state) return;

      try {
        const channels = data as ChannelChangeEvent;
        const newParticipants = participants.map(participant =>
          participant.clone().setChannelNumber(undefined).removeVideoParticipant(),
        );

        for (const [i, channel] of Object.entries(channels)) {
          const channelNumber = (parseInt(i) + 1) as ChannelNumber;

          for (const identity of Object.values(channel)[0]) {
            const prevParticipantIndex = newParticipants.findIndex(
              participant => participant.identity === identity,
            );
            const prevParticipant = newParticipants[prevParticipantIndex];

            const videoParticipants = videoRoom ? getVideoParticipants(videoRoom) : [];
            const chatParticipants = chatRoom ? await getChatParticipants(chatRoom) : [];

            const videoParticipant = videoParticipants.find(
              participant => participant.identity === identity,
            ) as VideoParticipant | undefined;
            const chatParticipant = chatParticipants.find(
              participant => participant.identity === identity,
            ) as ChatParticipant | undefined;

            const participant = prevParticipant ?? new RoomParticipant(identity, channelNumber);
            participant.setChannelNumber(channelNumber);
            participant.setVideoParticipant(videoParticipant);
            participant.setChatParticipant(chatParticipant);
            participant.updateTracks();

            if (prevParticipant) newParticipants[prevParticipantIndex] = participant;
            else newParticipants.push(participant);
          }
        }

        setParticipants(newParticipants);
      } catch (err) {
        log(err);
      }
    },
    [videoRoom, chatRoom, participants],
  );

  useEffect(() => {
    if (!roomSocket) return;
    roomSocket.addEventListener("message", handleSocketMessage);

    return () => roomSocket.removeEventListener("message", handleSocketMessage);
  }, [roomSocket, handleSocketMessage]);

  return {
    participants,
    localParticipant,
    toggleParticipantAudio,
    toggleParticipantVideo,
    setParticipantVolume,
  };
};

export default useParticipants;
