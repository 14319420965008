import { colors } from "@lib/constants/colors";
import { CSSProps, UIColor } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { CSSProperties } from "react";
import DataCheck from "./DataCheck";

interface Props extends CSSProps {
  src?: string | null;
  color?: UIColor;
  opacity?: number;
  isLoading?: boolean;
}

export default function BackgroundImage(props: Props) {
  const { src, color = "black-3", opacity = 0.3, isLoading, className, id, style } = props;

  return (
    <div
      className={classNames("background-image", className)}
      style={
        {
          "--src": `url(${src})`,
          "--color": colors[color],
          "--opacity": opacity,
          ...style,
        } as CSSProperties
      }
      id={id}>
      <DataCheck imgs={[src]} isLoading={isLoading} loadingIndicator="gloss" />
      <div className="background-image-inner" />
    </div>
  );
}
