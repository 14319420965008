import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import deleteUser from "../deleteUser";

const useDeleteUser = () => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<null>(null);

  const send = useCallback(async () => {
    setLoading();
    try {
      await deleteUser();
      return setSuccess(null);
    } catch (err: any) {
      return setError(err.message);
    }
  }, [setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useDeleteUser;
