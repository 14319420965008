import Avatar from "@components/common/Avatar";
import HoverTooltip from "@components/common/HoverTooltip";
import IconButton from "@components/form/IconButton";
import { classNames } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import { IoMic, IoMicOff, IoVideocam, IoVideocamOff, IoWarning } from "react-icons/io5";
import { RiVipCrown2Fill } from "react-icons/ri";
import RoomParticipant from "../../objects/RoomParticipant";
import useRoomContext from "../../roomContext/hooks/useRoomContext";
import useHandleReport from "./hooks/useHandleReport";
import ParticipantMenu from "./ParticipantMenu";

interface Props {
  participant: RoomParticipant;
  highlight?: boolean;
}

export default function Participant(props: Props) {
  const { participant, highlight } = props;

  const { localIdentity, session, toggleParticipantAudio, toggleParticipantVideo } =
    useRoomContext();
  const { breakpoints } = useGlobalContext();

  const {
    chatParticipant,
    identity,
    videoTrack,
    audioTrack,
    videoDisabled,
    audioDisabled,
    isLocal,
  } = participant;

  const videoIsToggling = videoDisabled === !!videoTrack?.isEnabled && isLocal;
  const audioIsToggling = audioDisabled === !!audioTrack?.isEnabled && isLocal;

  const { cig } = session ?? {};

  const isSelf = localIdentity === identity;
  const isCig = chatParticipant?.attributes.isCIG;

  const displayName = chatParticipant?.attributes.displayName;

  const { handleReport } = useHandleReport(identity);

  return (
    <div className={classNames("participant", highlight && "highlight")}>
      <div className="details">
        <Avatar src={isCig ? cig?.profilePictureURL : undefined} alt={displayName} size={24} />
        <p className={classNames("display-name", isCig && "is-cig")}>{displayName}</p>

        {!videoTrack?.isEnabled && (
          <HoverTooltip content={<p>Video Disabled</p>} noWrap color="black-1">
            <span>
              <IoVideocamOff />
            </span>
          </HoverTooltip>
        )}
        {(!audioTrack?.isEnabled || audioDisabled) && (
          <HoverTooltip
            content={<p>{audioDisabled && !isLocal ? "Muted by You" : "Muted"}</p>}
            noWrap
            color="black-1">
            <span>
              <IoMicOff
                className={classNames(audioDisabled && !isLocal ? "stroke-red" : "stroke-white")}
              />
            </span>
          </HoverTooltip>
        )}

        {isCig && (
          <HoverTooltip content={<p>Room Owner</p>} noWrap color="black-1">
            <span>
              <RiVipCrown2Fill />
            </span>
          </HoverTooltip>
        )}
      </div>

      <div className="actions">
        <HoverTooltip
          content={<p>{!audioDisabled ? "Mute" : "Unmute"}</p>}
          killTooltip={breakpoints.md || audioIsToggling}
          color="black-1">
          <IconButton
            size="small"
            color="black"
            rounded
            icon={!audioDisabled ? <IoMic /> : <IoMicOff />}
            onClick={() => toggleParticipantAudio(participant)}
            disabled={audioIsToggling}
          />
        </HoverTooltip>

        {isSelf && (
          <HoverTooltip
            content={<p>{!videoDisabled ? "Disable Video" : "Enable Video"}</p>}
            killTooltip={breakpoints.md || videoIsToggling}
            noWrap
            color="black-1">
            <IconButton
              size="small"
              color="black"
              rounded
              icon={!videoDisabled ? <IoVideocam /> : <IoVideocamOff />}
              onClick={() => participant && toggleParticipantVideo(participant)}
              disabled={videoIsToggling}
            />
          </HoverTooltip>
        )}

        {!isSelf && (
          <HoverTooltip content={<p>Report</p>} killTooltip={breakpoints.md} color="black-1">
            <IconButton
              size="small"
              color="red"
              rounded
              icon={<IoWarning />}
              onClick={handleReport}
            />
          </HoverTooltip>
        )}

        <ParticipantMenu participant={participant} />
      </div>
    </div>
  );
}
