import React from "react";
import Payment from "./sections/Payment";

export default function PaymentTab() {
  return (
    <div className="sections-wrapper payment">
      <div className="sections">
        <Payment />
      </div>
    </div>
  );
}
