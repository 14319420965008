import React from "react";
import useRoomContext from "../roomContext/hooks/useRoomContext";
import ViewPort from "./ViewPort";

export default function MobileViewPort() {
  const { localParticipant } = useRoomContext();

  return (
    <ViewPort
      participant={localParticipant}
      showControls={false}
      size="small"
      className="collapsible"
    />
  );
}
