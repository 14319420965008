import useForgotPassword from "@api/auth/hooks/useForgotPassword";
import { UseFormHandles } from "../../../../form/hooks/useFormManager";
import { ForgotPasswordValues } from "./useForgotPassword";
import useForgotPasswordSubmit from "@api/auth/hooks/useForgotPasswordSubmit";

const useForgotPasswordHandles: UseFormHandles<ForgotPasswordValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { send: forgotPasswordConfirm, isLoading: forgotPasswordConfirmIsloading } =
    useForgotPassword(values.email);
  const { send: forgotPasswordSubmit, isLoading: forgotPasswordSubmitIsLoading } =
    useForgotPasswordSubmit(values.email, values.forgotPasswordCode, values.password);

  const forgotPasswordConfirmHandle = async () => {
    let failed = validateFields(["email"]);

    if (!failed) {
      const { error } = await forgotPasswordConfirm();

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    return failed;
  };

  const forgotPasswordSubmitHandle = async () => {
    let failed = validateFields(["forgotPasswordCode", "password", "confirmPassword"]);

    if (!failed) {
      failed = validateCustom("password", validation => {
        if (values.password !== values.confirmPassword) {
          validation.list.push({
            text: "Passwords do not match",
            type: "error",
          });
        }
      });
    }

    if (!failed) {
      const { error } = await forgotPasswordSubmit();

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    return failed;
  };

  return [
    forgotPasswordConfirmHandle,
    forgotPasswordSubmitHandle,

    forgotPasswordConfirmIsloading,
    forgotPasswordSubmitIsLoading,
  ];
};

export default useForgotPasswordHandles;
