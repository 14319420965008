import React, { cloneElement } from "react";
import { useParams } from "react-router-dom";
import useAlertsUpdater from "./hooks/useAlertsUpdater";
import useCigOverlayAlerts from "./hooks/useCigOverlayAlerts";

export default function CIGOverlay() {
  const { cigId } = useParams<Params>();

  const { alerts, spliceAlert, pushAlert } = useCigOverlayAlerts();

  useAlertsUpdater(pushAlert, cigId);

  const alert = alerts[0];

  return (
    <div id="cig-overlay-page">
      {alert &&
        cloneElement(alert.component, {
          key: alert.key,
          onFinish: () => setTimeout(() => spliceAlert(alert), 8000),
        })}
    </div>
  );
}

interface Params {
  cigId?: string;
}
