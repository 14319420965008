import Section from "@components/section/Section";
import SectionBar from "@components/section/SectionBar";
import Button from "@components/form/Button";
import useStripeIsSetup from "@hooks/session/useStripeIsSetup";
import useOnboarding from "@hooks/stripe/useOnboarding";
import { IoArrowForward } from "react-icons/io5";
import Status from "./Status";

export default function StripeStatuses() {
  const stripeIsSetup = useStripeIsSetup();

  const {
    isLoading: onboardIsLoading,
    isRedirecting: onboardingIsRedirecting,
    onboard,
  } = useOnboarding();

  return (
    <Section title="Stripe Status">
      <SectionBar dynamicHeight>
        <div className="pair">
          <label>Stripe Setup Status</label>
          <p className="no-clamp">
            This indicates whether your stripe account has been fully setup.
            <br />
            <br />
            <Status status={stripeIsSetup} name={stripeIsSetup ? "Setup" : "Not Setup"} />
          </p>
        </div>
      </SectionBar>
      {!stripeIsSetup && (
        <SectionBar dynamicHeight>
          <div className="pair">
            <p className="no-clamp">
              Not completed the stripe setup process? You can do so below.
              <br />
              <br />
              <Button
                size="small"
                color="purple"
                textColor="white"
                isLoading={onboardIsLoading}
                isCtaing={onboardingIsRedirecting}
                onClick={onboard}
                endIcon={<IoArrowForward />}
                className="display-block"
                style={{ width: "fit-content" }}>
                Go to Setup
              </Button>
            </p>
          </div>
        </SectionBar>
      )}
    </Section>
  );
}
