import { CSSProps } from "@lib/types/generic";
import React from "react";

export default function GiftIcon(props: CSSProps) {
  return (
    <svg
      {...props}
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 192.271C12 196.546 15.575 200 20 200H91.5V109.179H12V192.271ZM108.5 200H180C184.425 200 188 196.546 188 192.271V109.179H108.5V200ZM192 51.2077H155.1C158.5 46.0386 160.5 39.9034 160.5 33.3333C160.5 14.9517 145.025 0 126 0C115.65 0 106.325 4.44444 100 11.4493C93.675 4.44444 84.35 0 74 0C54.975 0 39.5 14.9517 39.5 33.3333C39.5 39.9034 41.475 46.0386 44.9 51.2077H8C3.575 51.2077 0 54.6618 0 58.9372V92.7536H91.5V51.2077H108.5V92.7536H200V58.9372C200 54.6618 196.425 51.2077 192 51.2077ZM91.5 50.2415H74C64.35 50.2415 56.5 42.657 56.5 33.3333C56.5 24.0097 64.35 16.4251 74 16.4251C83.65 16.4251 91.5 24.0097 91.5 33.3333V50.2415ZM126 50.2415H108.5V33.3333C108.5 24.0097 116.35 16.4251 126 16.4251C135.65 16.4251 143.5 24.0097 143.5 33.3333C143.5 42.657 135.65 50.2415 126 50.2415Z"
        fill="white"
      />
    </svg>
  );
}
