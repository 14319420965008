import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import updateExperiencePackage, {
  GamePlatformSelection,
  UpdateExperiencePackageRes,
} from "../updateExperiencePackage";

const useUpdateExperiencePackage = (
  packageID?: string,
  name?: string,
  tagLine?: string,
  extraDetails?: string,
  sessionLength?: number,
  tickets?: number,
  streamOption?: boolean,
  price?: number,
  gamePlatformSelections?: GamePlatformSelection[],
  anyGame?: boolean,
) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<
    UpdateExperiencePackageRes | undefined
  >(undefined);

  const send = useCallback(async () => {
    if (
      name == null ||
      extraDetails == null ||
      tagLine == null ||
      sessionLength == null ||
      tickets == null ||
      streamOption == null ||
      price == null ||
      gamePlatformSelections == null ||
      anyGame == null
    )
      return reqState;

    setLoading();
    try {
      const data = await updateExperiencePackage(
        packageID,
        name,
        tagLine,
        extraDetails,
        sessionLength,
        tickets,
        streamOption,
        price,
        gamePlatformSelections,
        anyGame,
      );
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [
    packageID,
    name,
    tagLine,
    extraDetails,
    sessionLength,
    tickets,
    streamOption,
    price,
    gamePlatformSelections,
    anyGame,
    reqState,
    setError,
    setLoading,
    setSuccess,
  ]);

  return { ...reqState, data: reqState.data, send };
};

export default useUpdateExperiencePackage;
