import Modal from "@components/modals/Modal";
import TicketRegular, { TicketData } from "@components/tickets/TicketRegular";
import Button from "@components/form/Button";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import { IoInformationCircleOutline, IoWarningOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import useRoomContext from "./roomContext/hooks/useRoomContext";

const rules: string[] = [
  "No discrimination of any kind in any form.",
  "No spamming or flooding over chat or VoIP. (This includes soundboards)",
  "Do not share pornography or content of illegal nature.",
  "No nudity",
  "Do not record others without their consent.",
];

export default function RoomRulesModal() {
  const { rulesAccepted, setRulesAccepted, session } = useRoomContext();
  const { userExtension, breakpoints } = useGlobalContext();

  const history = useHistory();

  const { cig, game, from, to, exclusive, extraInfo } = session ?? {};

  const ticketData: TicketData = {
    img: cig?.profilePictureURL ?? undefined,
    cigName: cig?.displayName,
    customerName: userExtension?.displayName,
    gameName: game?.longName,
    startDate: from,
    endDate: to,
    exclusive: exclusive,
  };

  return (
    <Modal active={!rulesAccepted} className="room-rules-modal" bare>
      <div className="box extra-info-wrapper">
        <IoInformationCircleOutline className="info-icon" />
        <h3 className="title">Details from {cig?.displayName}</h3>
        <p>{extraInfo || `${cig?.displayName} hasn't left any details.`}</p>
      </div>

      {!breakpoints.md && (
        <TicketRegular
          {...ticketData}
          size={breakpoints.sm ? "small" : breakpoints.lg ? "medium" : "large"}
        />
      )}

      <div className="box rules-wrapper">
        <h3 className="title">Rules</h3>
        <div className="rules">
          {rules.map((rule, i) => (
            <div className="rule" key={i}>
              <div className="rule-icon">
                <IoWarningOutline />
              </div>
              <p>{rule}</p>
            </div>
          ))}
        </div>

        <p className="foot-note">
          If these rules are violated, you may be kicked instantly without refund.
        </p>

        <div className="button-row">
          <Button
            color="red"
            textColor="white"
            size={breakpoints.sm ? "small" : "medium"}
            onClick={() => history.push("/")}>
            Cancel
          </Button>
          <Button
            color="green"
            textColor="white"
            size={breakpoints.sm ? "small" : "medium"}
            onClick={() => setRulesAccepted(true)}>
            I agree
          </Button>
        </div>
      </div>
    </Modal>
  );
}
