import useGetAllBookings from "@api/private/get/hooks/useGetAllBookings";
import Divider from "@components/common/Divider";
import FormModal from "@components/modals/FormModal";
import Form from "@components/form/Form";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import useFormManager from "@components/form/hooks/useFormManager";
import Input from "@components/form/Input";
import Select from "@components/form/Select";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { claimReasonNames } from "@lib/constants/generic";
import { ClaimReason, SortDirection } from "@lib/enums/generic";
import { createSuccessSnackbar, objectToOptions, sortMap } from "@lib/utils/generic";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import useCreateClaimHandles from "./hooks/useCreateClaimHandles";
import Search from "@components/form/Search";

interface Props {
  active?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  redirect?: boolean;
  initialBookingId?: string;
}

const reasonClassOptions = objectToOptions(claimReasonNames);

const initialValues = {
  reasonClass: ClaimReason.TechnicalDifficulties,
  bookingID: "",
  reason: "",
};

export type CreateClaimValues = typeof initialValues;

export default function CreateClaimModal(props: Props) {
  const {
    active,
    onClose = () => {},
    onSubmit = () => {},
    redirect = false,
    initialBookingId,
  } = props;

  if (initialBookingId) initialValues.bookingID = initialBookingId;

  const { pushSnackbar } = useGlobalContext();

  const [searchValue, setSearchValue] = useState("");

  const formManager = useFormManager(initialValues, "supportClaim");
  const { values, validations, onChange, onBlur, resetValidations } = formManager;

  const [handle, createIsloading] = useCreateClaimHandles(formManager, redirect);

  const {
    data: myBookings,
    isLoading: bookingsIsLoading,
    error: bookingsError,
  } = useGetAllBookings(true);

  const myBookingsOptions = myBookings
    .sort(sortMap("session.from", SortDirection.Descending))
    .map(({ bookingID, session }) => {
      const { cig, from, game } = session;
      return {
        value: bookingID,
        label: `${cig?.displayName} - ${dayjs(from).format("DD MMM YY HH:mm")} - ${game.shortName}`,
      };
    });

  const handleClose = useCallback(() => {
    resetValidations();
    onClose();
  }, [resetValidations, onClose]);

  const handleCompletion = useCallback(() => {
    handleClose();
    onSubmit();
    pushSnackbar(createSuccessSnackbar("Support ticket created", 2500));
  }, [handleClose, pushSnackbar, onSubmit]);

  return (
    <FormModal
      form="create-support-ticket-form"
      title="Submit a Claim"
      active={active}
      onClose={handleClose}
      confirmButtonProps={{ isLoading: createIsloading }}>
      <Divider color="gray-3" />
      <Form
        id="create-support-ticket-form"
        validation={validations.generic}
        handle={handle}
        onCompletion={handleCompletion}
        resetValidations={resetValidations}
        resetOnCompletion>
        <FormRow>
          <FormEntry
            validation={validations.reasonClass}
            label="Reason for Claim"
            showValidationBeneath>
            <Select
              variant="contained"
              color="black-4"
              textColor="white"
              size="small"
              position="absolute"
              name="reasonClass"
              value={values.reasonClass}
              options={reasonClassOptions}
              onChange={onChange}
              onBlur={onBlur}
            />
          </FormEntry>
        </FormRow>

        <Divider color="gray-3" />

        <FormRow>
          <FormEntry validation={validations.bookingID} label="Booking" showValidationBeneath>
            <Search
              variant="contained"
              color="black-4"
              textColor="white"
              size="small"
              position="absolute"
              name="bookingID"
              value={values.bookingID}
              searchValue={searchValue}
              results={myBookingsOptions}
              onChange={({ value }) => setSearchValue(value)}
              onSelect={onChange}
              autoFilter
              isLoading={bookingsIsLoading}
              error={bookingsError}
            />
          </FormEntry>
        </FormRow>

        <Divider color="gray-3" />

        <FormRow>
          <FormEntry validation={validations.reason} label="Claim Details" showValidationBeneath>
            <Input
              variant="contained"
              color="black-4"
              textColor="white"
              size="small"
              name="reason"
              value={values.reason}
              onChange={onChange}
              onBlur={onBlur}
              multiline
              placeholder="Give as much information as possible to help process your claim"
            />
          </FormEntry>
        </FormRow>
      </Form>
    </FormModal>
  );
}
