import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getCigFullCustomExperience, { CigFullCustomExperience } from "../getCigFullCustomExperience";

const useGetCigFullCustomExperience = (autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CigFullCustomExperience | null>(null);

  const send = useCallback(async () => {
    setLoading();
    try {
      const booking = await getCigFullCustomExperience();
      return setSuccess(booking);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetCigFullCustomExperience;
