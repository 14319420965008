import useCreateAvailability from "@api/private/create/hooks/useCreateAvailability";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { CreateSessionValues } from "../CreateSession";

const useCreateSessionHandles: UseFormHandles<CreateSessionValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { send: createAvailability, isLoading } = useCreateAvailability(
    values.gameId,
    values.platformId,
    values.startDate.format(),
    values.endDate.format(),
    values.isExclusive,
    values.isCharity,
    values.charityName,
    values.totalSlots,
    values.title,
    values.message,
    values.price,
    values.free,
    values.repeat ? values.repeatCount : undefined,
    values.repeat ? values.repeatDays : undefined,
  );

  const handle = async () => {
    let failed = validateFields([
      "platformId",
      "isCharity",
      "charityName",
      "gameId",
      "startDate",
      "endDate",
      "totalSlots",
      "repeatDays",
      "repeat",
      "repeatCount",
      "title",
      "message",
      ...(values.free ? [] : ["price" as "price"]),
    ]);

    if (!failed) {
      failed = validateCustom("startDate", validator => {
        if (values.startDate.isSameOrAfter(values.endDate)) {
          validator.list.push({
            text: "Start date must be before end date",
            type: "error",
          });
        }
      });
    }

    if (!failed) {
      failed = validateCustom("endDate", validator => {
        if (values.endDate.isSameOrBefore(values.startDate)) {
          validator.list.push({
            text: "End date must be after start date",
            type: "error",
          });
        }
      });
    }

    if (!failed) {
      const { error } = await createAvailability();

      if (error) {
        failed = validateCustom("generic", validator => {
          validator.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    return failed;
  };

  return [handle, isLoading];
};

export default useCreateSessionHandles;
