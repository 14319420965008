import useGetGames from "@api/public/get/hooks/useGetGames";
import { ButtonProps } from "@components/form/Button";
import ExperienceCard, { defaultExperienceCardDetails } from "@components/sections/ExperienceCard";
import { SortDirection } from "@lib/enums/generic";
import { getLongestGame, sortMap } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useCigProfileContext from "../context/hooks/useCigProfileContext";
import CigProfileTitle from "../CigProfileTitle";
import DataCheck from "@components/common/DataCheck";

const Experiences = () => {
  const { breakpoints } = useGlobalContext();

  const {
    isOwnProfile,
    cigProfile,
    packageExperiences,
    packageExperiencesIsLoading,
    getIsLoading,
    packageExperiencesError,
  } = useCigProfileContext();

  const { data: games, isLoading: gamesIsLoading } = useGetGames(true);

  const buttonSize = breakpoints.sm ? "medium" : breakpoints.md ? "medium" : "large";

  const cardProps = {
    width: breakpoints.xs ? "calc(100vw - 32px)" : breakpoints.sm ? 320 : 350,
    isMobile: breakpoints.sm,
    buttonSize: buttonSize as ButtonProps["size"],
  };

  if (packageExperiences.length === 0 && !packageExperiencesIsLoading && !getIsLoading) return null;

  return (
    <section className="section experiences" id="experiences">
      <CigProfileTitle center>
        <DataCheck isLoading={getIsLoading} loadingIndicator="gloss" loadingContent={<>&nbsp;</>}>
          Book a gaming session with {cigProfile?.displayName}
        </DataCheck>
      </CigProfileTitle>

      {packageExperiencesIsLoading || getIsLoading ? (
        <div className="cards">
          {new Array(3).fill(null).map((_, i) => {
            return <ExperienceCard key={i} isLoading {...cardProps} />;
          })}
        </div>
      ) : (
        <div className="cards">
          <DataCheck error={packageExperiencesError}>
            {packageExperiences
              .sort(sortMap("priceIncTax", SortDirection.Ascending))
              .map(
                (
                  {
                    packageID,
                    name,
                    tagLine,
                    sessionLength,
                    tickets,
                    streamOption,
                    priceIncTax,
                    currency,
                    games: selectedGames,
                    anyGame,
                  },
                  i,
                ) => {
                  const longestGame = anyGame ? undefined : getLongestGame(games, selectedGames);
                  const img = anyGame
                    ? undefined
                    : longestGame?.primaryImageURL ?? selectedGames[0]?.game.primaryImageURL;

                  return (
                    <ExperienceCard
                      key={i}
                      highlight={i === 1}
                      highlightText="Most Popular"
                      name={name}
                      price={priceIncTax}
                      currency={currency}
                      tagLine={tagLine}
                      longestGame={longestGame}
                      img={img}
                      tickets={tickets}
                      livestreamed={streamOption}
                      duration={sessionLength * 60}
                      color={defaultExperienceCardDetails[i].color ?? "purple"}
                      to={
                        isOwnProfile
                          ? `/experiences/update/package/${packageID}`
                          : `/experiences/request/${cigProfile?.id}/${packageID}`
                      }
                      isLoading={gamesIsLoading}
                      {...cardProps}
                    />
                  );
                },
              )}
          </DataCheck>
        </div>
      )}
    </section>
  );
};

export default Experiences;
