import { classNames } from "@lib/utils/generic";
import { forwardRef } from "react";
import NumberInput, { NumberInputProps } from "./NumberInput";

interface Props extends NumberInputProps {
  currency?: string;
}

export type CurrenctInputProps = Props;

const CurrencyInput = forwardRef<(HTMLInputElement & HTMLTextAreaElement) | null, Props>(
  (props, ref) => {
    const { currency = "$", ...numberInputProps } = props;

    return (
      <NumberInput
        ref={ref}
        startIcon={
          <p>
            <span className="small">{currency.slice(0, -1)}</span>
            <span>{currency.slice(-1)}</span>
          </p>
        }
        wrapperClassName={classNames("currency-input", props.wrapperClassName)}
        numberType="float"
        {...numberInputProps}
      />
    );
  },
);

export default CurrencyInput;
