import { api } from "@api/api";

export interface GamePlatformSelection {
  gameID: string;
  platformID: string;
}

export interface UpdateExperiencePackageRes {}

const updateExperiencePackage = async (
  packageID: string | undefined = undefined,
  name: string,
  tagLine: string,
  extraDetails: string,
  sessionLength: number,
  tickets: number,
  streamOption: boolean,
  price: number,
  gamePlatformSelections: GamePlatformSelection[],
  anyGame: boolean,
) => {
  const { data } = await api.post<UpdateExperiencePackageRes>("/saveOrReplacePackageConfig", {
    packageID,
    name,
    tagLine,
    extraDetails,
    sessionLength,
    tickets,
    streamOption,
    price,
    gamePlatformSelections,
    anyGame,
  });
  return data;
};

export default updateExperiencePackage;
