import TicketRegular, { TicketData } from "@components/tickets/TicketRegular";
import Button from "@components/form/Button";
import Fade from "@components/transitions/Fade";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useMouseIsOver from "@hooks/useMouseIsOver";
import React, { useRef, useState } from "react";
import { IoArrowForward } from "react-icons/io5";
import circularBlurBlack from "@images/misc/circular_blur_black.png";
import DataCheck from "@components/common/DataCheck";
import useVantaBirds from "@hooks/useVantaBirds";
import { classNames } from "@lib/utils/generic";

interface Props {
  in: boolean;
  tickets?: TicketData[];
  isLoading?: boolean;
  error?: string | null;
  onClose?: () => void;
}

export default function TicketsOverlay(props: Props) {
  const { in: _in, tickets = [], isLoading, error, onClose = () => {} } = props;

  const [entering, setEntering] = useState(false);
  const [exited, setExited] = useState(false);

  const { breakpoints } = useGlobalContext();

  const ticketsRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const [mouseIsOver] = useMouseIsOver(ticketsRef);

  const ticketCount = tickets.length;
  const rotationMultiplier = mouseIsOver ? 1.5 : 1;

  useVantaBirds(innerRef, isLoading || error != null);

  if (exited) return null;

  return (
    <div
      className={classNames(
        "tickets-overlay-background",
        (entering || isLoading || error != null) && "entering",
      )}>
      <Fade
        in={_in}
        appear
        timeout={_in ? 3000 : 500}
        onEnter={() => {
          setEntering(true);
          setExited(false);
        }}
        onEntered={() => setEntering(false)}
        onExited={() => setExited(true)}>
        <div className="tickets-overlay">
          <DataCheck isLoading={isLoading} loadingIndicator="spinner" color="white">
            <div className="tickets-overlay-inner" ref={innerRef}>
              {/* <CircularBlur color="black" /> */}
              <div
                className="tickets-overlay-wrapper"
                style={{ backgroundImage: `url(${circularBlurBlack})` }}>
                <div className="tickets-overlay-tickets" ref={ticketsRef}>
                  {tickets.map((ticket, i) => {
                    if (i >= 4) return null;
                    return (
                      <TicketRegular
                        key={i}
                        {...ticket}
                        style={{
                          transform: `rotate(${i * 5 * rotationMultiplier}deg)`,
                          zIndex: (ticketCount - i) * 100,
                        }}
                        size={breakpoints.sm ? "small" : breakpoints.md ? "medium" : "large"}
                      />
                    );
                  })}
                </div>

                <div className="tickets-overlay-content">
                  <h1 className="tickets-overlay-title">
                    YOUR
                    <br />
                    EXCLUSIVE
                    <br />
                    ACCESS
                  </h1>
                  <div className="tickets-overlay-buttons">
                    {/* <Button color="purple" disabled={entering}>
                    Share
                  </Button> */}
                    <Button
                      variant="outlined"
                      color="white"
                      textColor="white"
                      endIcon={<IoArrowForward />}
                      onClick={onClose}
                      disabled={entering}>
                      Go to Tickets
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </DataCheck>
        </div>
      </Fade>
    </div>
  );
}
