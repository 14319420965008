import FeedbackContextProvider from "./context/FeedbackContextProvider";
import FeedbackInner from "./FeedbackInner";

export default function Feedback() {
  return (
    <FeedbackContextProvider>
      <FeedbackInner />
    </FeedbackContextProvider>
  );
}
