import BackgroundImage from "@components/common/BackgroundImage";
import Rating from "@components/common/Rating";
import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import controllersSplashImg from "@images/misc/controllers_splash.png";
import DataCheck from "@components/common/DataCheck";

interface Props extends CSSProps {
  rating?: number;
  displayName?: string;
  review?: string;

  bgColor?: string;

  isLoading?: boolean;
  error?: string;
}

const ReviewClip = (props: Props) => {
  const {
    rating = 0,
    displayName,
    review,
    bgColor = "black-2",
    isLoading,
    error,
    className,
    ...cssProps
  } = props;

  return (
    <div className={classNames("review-card", `${bgColor}-bg`, className)} {...cssProps}>
      <DataCheck isLoading={isLoading} error={error} loadingIndicator="gloss">
        <div className="overlay">
          <BackgroundImage src={controllersSplashImg} opacity={1} />
          <div className="content">
            <Rating value={rating} color="yellow" size="ultra-small" />
            <p className="review-text">
              {displayName && (
                <>
                  <b>{displayName}</b>
                  <br />
                </>
              )}
              {review}
            </p>
          </div>
        </div>
      </DataCheck>
    </div>
  );
};

export default ReviewClip;
