import { api } from "@api/api";

export interface CreatePaidGuestHoldingRes {
  url: string;
  holdingID: string;
  checkoutID: string;
  paymentIntentID: string;
}

export interface CreateFreeGuestHoldingRes {
  bookingID: string;
}

export type CreateGuestHoldingRes = CreatePaidGuestHoldingRes | CreateFreeGuestHoldingRes;

const createGuestHolding = async (
  availabilityID: string,
  slotsRequested: number,
  email?: string,
) => {
  const { data } = await api.get<CreateGuestHoldingRes>("/createGuestHolding", {
    params: {
      availabilityID,
      slotsRequested,
      email,
    },
  });
  return data;
};

export default createGuestHolding;
