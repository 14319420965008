import YakkrBanner from "@components/common/YakkrBanner";
import ForgotPasswordModal from "@components/layout/auth/forgotPassword/ForgotPasswordModal";
import LoginModal from "@components/layout/auth/login/LoginModal";
import RegisterModal from "@components/layout/auth/register/RegisterModal";
import DevPanel from "@components/layout/DevPanel";
import Header from "@components/layout/Header";
import Nav from "@components/layout/Nav";
import Snackbars from "@components/layout/Snackbars";
import TopProgress from "@components/layout/TopProgress";
import CookieConsentBanner from "@components/prompts/CookieConsentBanner";
import { isDev } from "@config/config";
import useCigFlow from "@hooks/session/useCigFlow";
import useBodyClassManager from "@hooks/useBodyClassManager";
import useDrawerState from "@hooks/useDrawerState";
import useHasHeader from "@hooks/useHasHeader";
import useHasNav from "@hooks/useHasNav";
import useHeaderState from "@hooks/useHeaderState";
import { AuthState } from "@lib/enums/generic";
import { classNames } from "@lib/utils/generic";
import { cloneElement, isValidElement, PropsWithChildren } from "react";
import { IoArrowForward, IoHammer } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import useGlobalContext from "./globalContext/hooks/useGlobalContext";
import Button from "@components/form/Button";
import DialogueModal from "@components/modals/DialogueModal";

interface Props {}

const Layout = (props: PropsWithChildren<Props>) => {
  const { children } = props;

  const {
    maintenanceMode,
    breakpoints,
    initialLoading,
    drawerActive,
    drawerExpanded,

    authState,

    stickyBar,
    setStickyBar,
  } = useGlobalContext();

  const location = useLocation();

  useBodyClassManager();

  useDrawerState();
  useHeaderState();
  useCigFlow();

  const hasHeader = useHasHeader();
  const hasNav = useHasNav();
  const type = breakpoints.lg || !hasNav ? "temporary" : "permanent";

  if (maintenanceMode)
    return (
      <div className="data-check progress-overlay text-center">
        <YakkrBanner color="black-3" />

        <div style={{ padding: 8 }}>
          <h2 className="display-flex align-center justify-center" style={{ gap: 12 }}>
            <IoHammer /> Maintenance Mode
          </h2>
          <br />
          <p>
            We're currently making some changes to the site. {!breakpoints.sm && <br />}We should be
            back shortly.
          </p>
        </div>
      </div>
    );

  const initialDataCheckIsLoading = initialLoading && authState === AuthState.None;

  return (
    <>
      <DialogueModal
        active
        color="yellow"
        title="This is our old site"
        subTitle="Click the button below to be taken to our new website."
        titleDesign="condensed"
        buttonsDesign="flat"
        noCloseButton
        buttons={
          <Button
            variant="flat"
            color="white"
            textColor="yellow"
            endIcon={<IoArrowForward />}
            href="https://yakkrgrowth.com/">
            Take me there
          </Button>
        }
      />

      <TopProgress />

      {initialDataCheckIsLoading && (
        <div className="data-check progress-overlay">
          <YakkrBanner color="white" animated />
        </div>
      )}

      <Header />

      {isDev && <DevPanel />}

      <div id="portal-root" />
      <Nav type={type} />
      <Snackbars horizontal="left" />
      <CookieConsentBanner />

      <LoginModal />
      <RegisterModal />
      <ForgotPasswordModal />

      <div
        id="content-root"
        className={classNames(
          !drawerExpanded && "drawer-collapsed",
          drawerActive && "drawer-active",
          hasNav && "nav-active",
          initialDataCheckIsLoading && "initial-loading",
          location.pathname === "/register/choose" && "overflow-hidden", // Legacy
        )}>
        {isValidElement(stickyBar) &&
          cloneElement(stickyBar as any, {
            onClose: stickyBar.props.onClose ?? (() => setStickyBar(null)),
          })}

        <div
          className={classNames("content-wrapper", hasHeader && "header-active")}
          style={{
            minHeight: window.innerHeight - (breakpoints.sm && hasHeader ? 60 : 0),
          }}>
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
