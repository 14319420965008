import useGetGames from "@api/public/get/hooks/useGetGames";
import DataCheck from "@components/common/DataCheck";
import ScrollCarousel from "@components/common/ScrollCarousel";
import Button from "@components/form/Button";
import IconButton from "@components/form/IconButton";
import { Option } from "@components/form/Options";
import Select from "@components/form/Select";
import SortArrowIcon from "@components/icons/SortArrowIcon";
import ExperienceRequestCard from "@components/sections/ExperienceRequestCard";
import { sortDirectionInvert } from "@lib/constants/generic";
import { ExperienceRequestState, SortDirection } from "@lib/enums/generic";
import { isEmpty, padArray, sortMap } from "@lib/utils/generic";
import ExperiencesHeader from "@pages/experiences/ExperiencesHeader";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { IoCalendar } from "react-icons/io5";
import ExperienceRequestCancelModal from "./modals/ExperienceRequestCancelModal";

const sortOptions: Option[] = [
  {
    value: "createdAt",
    label: "Created at",
  },
  {
    value: "start",
    label: "Start Date",
  },
  {
    value: "state",
    label: "State",
  },
  {
    value: "customerPrice",
    label: "Price",
  },
  {
    value: "packageName",
    label: "Package Name",
  },
];

export default function Experiences() {
  const {
    breakpoints,
    cigProfile,
    customerExperienceRequests,
    customerExperienceRequestsIsLoading,
    customerExperienceRequestsError,
    getAllExperienceRequests,

    cigPendingExperienceRequests,
  } = useGlobalContext();

  const { data: games, isLoading: gamesIsLoading, error: gamesError } = useGetGames(true);

  const [cancelRequestId, setCancelRequestId] = useState<string>();
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState(SortDirection.Descending);

  const isLoading = customerExperienceRequestsIsLoading || gamesIsLoading;
  const error = customerExperienceRequestsError ?? gamesError;

  const cardProps = {
    width: 250,
  };

  const bookingExistsStates = [ExperienceRequestState.Accepted, ExperienceRequestState.Completed];

  useEffect(() => {
    getAllExperienceRequests();
    // eslint-disable-next-line
  }, []);
  // must omit getAllExperienceRequests to execute on mount only

  return (
    <div id="experiences-page">
      <div className="pending-requests">
        <ExperiencesHeader
          title="My Requested Experiences"
          subTitle="Here’s a list of your requested sessions."
          actions={
            <>
              {cigProfile && (
                <Button
                  color="gray-3"
                  textColor="black"
                  size={breakpoints.md ? "medium" : "large"}
                  justifyContent="center"
                  startIcon={<IoCalendar />}
                  to="/experiences/requests">
                  Pending Requests
                  {cigPendingExperienceRequests.length > 0 && (
                    <> ({cigPendingExperienceRequests.length})</>
                  )}
                </Button>
              )}
              <Select
                size={breakpoints.md ? "medium" : "large"}
                color="black-2"
                textColor="white"
                rounded
                startIcon={
                  <IconButton
                    variant="flat"
                    color="white"
                    iconColor="white"
                    rounded
                    icon={<SortArrowIcon sortDirection={sortDirection} />}
                    onClick={() => setSortDirection(sortDirectionInvert[sortDirection])}
                  />
                }
                value={sortKey}
                options={sortOptions}
                onChange={({ value }) => setSortKey(value)}
              />
            </>
          }
        />

        <div className="scroll-carousel-wrapper">
          <DataCheck
            error={error}
            isEmpty={isEmpty(customerExperienceRequests) && !isLoading}
            emptyMessage="No requests outstanding">
            <ScrollCarousel gap={16}>
              {padArray(customerExperienceRequests, null, 7)
                .sort(sortMap(sortKey, sortDirection))
                .map((pendingExperienceRequest, i) => {
                  if (isLoading) return <ExperienceRequestCard key={i} {...cardProps} isLoading />;

                  if (!pendingExperienceRequest) return null;

                  const {
                    packageName,
                    extraCustomerRequestDetails,

                    createdAt,
                    start,
                    end,
                    tickets,

                    customerPrice,
                    customerCurrency,

                    requestID,
                    gameID,
                    platformID,
                    bookingID,

                    state,
                  } = pendingExperienceRequest;

                  const game = games.find(({ id }) => id === gameID);
                  const platform = game?.platforms.items.find(
                    ({ platform: { id } }) => id === platformID,
                  );

                  return (
                    <ExperienceRequestCard
                      key={i}
                      {...cardProps}
                      packageName={packageName ?? "Custom"}
                      extraCustomerRequestDetails={extraCustomerRequestDetails}
                      createdAt={dayjs(createdAt)}
                      start={dayjs(start)}
                      end={dayjs(end)}
                      tickets={tickets}
                      price={customerPrice}
                      currency={customerCurrency}
                      gameName={game?.shortName}
                      gameImg={game?.tertiaryImageURL}
                      platformName={platform?.platform.shortName}
                      state={state}
                      actions={
                        <>
                          {state === ExperienceRequestState.Pending && (
                            <Button
                              size="medium"
                              color="red"
                              textColor="black"
                              onClick={() => setCancelRequestId(requestID)}>
                              Cancel
                            </Button>
                          )}
                          {bookingExistsStates.includes(state) && (
                            <Button
                              size="medium"
                              color="green"
                              textColor="black"
                              to={`/bookings/${bookingID}?type=booking`}>
                              Go to Booking
                            </Button>
                          )}
                        </>
                      }
                    />
                  );
                })}
            </ScrollCarousel>
          </DataCheck>
        </div>
      </div>
      <ExperienceRequestCancelModal
        requestId={cancelRequestId}
        onRequestCancel={getAllExperienceRequests}
        onClose={() => setCancelRequestId(undefined)}
      />
    </div>
  );
}
