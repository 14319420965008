import IconButton from "@components/form/IconButton";
import { IoClose } from "react-icons/io5";
import { IoCalendar } from "react-icons/io5";
import useCalendarContext from "./context/useCalendarContext";
import IncrementPicker from "@components/form/IncrementPicker";

interface Props {
  title: string;
}

const CalendarHeader = (props: Props) => {
  const { title } = props;

  const { periodDate, goToNextPeriod, goToPrevPeriod, onClose } = useCalendarContext();

  return (
    <div className="calendar-header">
      <div className="left">
        <IoCalendar />
        <h3 className="title">{title}</h3>
      </div>

      <div className="middle">
        <IncrementPicker
          variant="flat"
          color="gray-3"
          textColor="gray-3"
          iconColor="gray-3"
          value={periodDate.format("DD MMM YYYY")}
          onBack={goToPrevPeriod}
          onForward={goToNextPeriod}
        />
      </div>

      <div className="right">
        <IconButton
          variant="flat"
          color="gray-3"
          iconColor="gray-3"
          icon={<IoClose />}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default CalendarHeader;
