import Accordion from "@components/common/Accordion";
import Button from "@components/form/Button";
import FluentRedQuestionMark from "@components/icons/FluentRedQuestionMark";
import FluentSparkles from "@components/icons/FluentSparkles";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { IoArrowForward } from "react-icons/io5";
import Avatar from "@components/common/Avatar";

import avatar1 from "@images/landing/consultancy/avatars/avatar1.png";
import avatar2 from "@images/landing/consultancy/avatars/avatar2.png";
import avatar3 from "@images/landing/consultancy/avatars/avatar3.png";
import avatar4 from "@images/landing/consultancy/avatars/avatar4.png";
import avatar5 from "@images/landing/consultancy/avatars/avatar5.png";
import avatar6 from "@images/landing/consultancy/avatars/avatar6.png";
import avatar7 from "@images/landing/consultancy/avatars/avatar7.png";
import avatar8 from "@images/landing/consultancy/avatars/avatar8.png";

const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8];

const LastQuestions = () => {
  const { breakpoints } = useGlobalContext();

  const accordionSize = breakpoints.xs
    ? "small"
    : breakpoints.md
    ? "medium"
    : breakpoints.sl
    ? "large"
    : "extra-large";

  const avatarSize = breakpoints.sm ? 35 : breakpoints.md ? 30 : 35;

  return (
    <section className="pane last-questions" id="last-questions">
      <div className="inner">
        <h2 className="flow-sub-title text-gradient-faded-horizontal">
          Got some remaining questions <FluentRedQuestionMark className="flow-icon" />
        </h2>

        <p className="flow-text sub-text">
          That's completely normal, don't worry.
          <br />
          <br />
          Here's some answers to commonly asked questions we get.
        </p>

        <div className="accordions">
          <div>
            <Accordion
              title="How does it work?"
              color="white"
              contentTextColor="gray-2"
              buttonTextColor="gray-1"
              size={accordionSize}>
              Depending on the service level you choose, you will be invited onto Yakkr and into our
              private network. Once on Yakkr, you will have full access to a range of education
              material and tools.
              <br />
              <br />
              For Standard and Premium clients, we will organise our onboarding call and begin
              working together to build out your strategy. Consultancy calls are key for
              understanding the wider options in the gaming and streaming industry that you might
              not have considered.
            </Accordion>
          </div>
          <div>
            <Accordion
              title="What can I expect from the service?"
              color="white"
              contentTextColor="gray-2"
              buttonTextColor="gray-1"
              size={accordionSize}>
              When you join Yakkr Growth, you will first learn the key areas you need to focus on
              depending on the current state of your streaming career. Factors including your size,
              time commitment and niche will depend on the strategy we recommend and the tools you
              need to use. For new/early-stage streamers, most of these strategies are quick and
              easy to implement. For those looking to accelerate their growth then a hands on
              approach with our consultants and mentors is vital.
            </Accordion>
          </div>
          <div>
            <Accordion
              title="Can I upgrade my package?"
              color="white"
              contentTextColor="gray-2"
              buttonTextColor="gray-1"
              size={accordionSize}>
              Yes you can upgrade your package at anytime. Send us an email at
              info+upgrades@yakkr.co.uk to change your package. If you would like to build out a
              fully custom package including more consulting, mentoring and hands on involvement
              from our team then email info+custom@yakkr.co.uk
            </Accordion>
          </div>
          <div>
            <Accordion
              title="Are there any minimum requirements to join?"
              color="white"
              contentTextColor="gray-2"
              buttonTextColor="gray-1"
              size={accordionSize}>
              The only requirement is that you must be over 18.
            </Accordion>
          </div>
        </div>

        <h2 className="flow-sub-title text-gradient-faded-horizontal">
          Alright - let's do it! <FluentSparkles className="flow-icon" />
        </h2>

        <div className="cta">
          <div className="title">Kickstart my streaming career</div>
          <Button
            size={breakpoints.md ? "large" : "extra-large"}
            color="purple"
            textColor="white"
            endIcon={<IoArrowForward />}
            href="#sign-up">
            Sign up now
          </Button>

          <div className="affiliates-bar">
            <p className="text">
              Join Twitch affiliates already growing their streams with our services
            </p>
            <div className="avatars">
              {avatars.map((avatar, i) => (
                <Avatar key={i} src={avatar} alt="Avatar" size={avatarSize} />
              ))}
              <Avatar alt="Avatar" size={avatarSize}>
                <p className="plus-text">+more</p>
              </Avatar>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LastQuestions;
