import { MyBooking } from "@api/private/get/getBooking";
import TicketRegular, { TicketData } from "@components/tickets/TicketRegular";
import useTicketShareStatusStore from "@hooks/sessionStore/useTicketShareStatusStore";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, { useState } from "react";
import TicketActionsModal from "./TicketActionsModal";
import distinctColors from "distinct-colors";
import Button from "@components/form/Button";
import { IoSend } from "react-icons/io5";
import DataCheck from "@components/common/DataCheck";
import { classNames } from "@lib/utils/generic";

interface Props {
  myBooking?: MyBooking | null;
  tickets?: TicketData[];
  isLoading?: boolean;
}

export default function Tickets(props: Props) {
  const { myBooking, tickets = [], isLoading } = props;

  const { session } = myBooking ?? {};

  const { breakpoints } = useGlobalContext();

  const [selectedTicket, setSelectedTicket] = useState<TicketData>();
  const selectedTicketIndex = tickets.findIndex(
    ({ ticketId }) => selectedTicket?.ticketId === ticketId,
  );

  const { data, error, addTicketId, removeTicketId } = useTicketShareStatusStore(session?.id);

  const palette = distinctColors({
    count: tickets.length,
    chromaMin: 50,
    chromaMax: 100,
    lightMin: 25,
    lightMax: 75,
  });

  return (
    <div className="tickets">
      <DataCheck isLoading={isLoading} loadingIndicator="spinner" error={error}>
        {tickets.map((ticket, i) => {
          const shared =
            ticket?.ticketId != null && data?.sharedTicketIds.includes(ticket.ticketId);

          return (
            <div key={i} className={classNames("ticket-wrapper", shared && "shared")}>
              <TicketRegular
                key={i}
                {...ticket}
                size={breakpoints.md ? "small" : "medium"}
                torn={shared}
                chromaColor={palette[i]}
                index={i}
                onClick={() => setSelectedTicket(ticket)}
              />
              {breakpoints.sm && !shared && (
                <div className="button-row">
                  <Button
                    endIcon={<IoSend />}
                    color="black-3"
                    textColor="white"
                    onClick={() => setSelectedTicket(ticket)}>
                    Share
                  </Button>
                </div>
              )}
            </div>
          );
        })}

        <TicketActionsModal
          myBooking={myBooking}
          ticket={selectedTicket}
          ticketChromaColor={palette[selectedTicketIndex]}
          ticketIndex={selectedTicketIndex}
          shared={
            selectedTicket?.ticketId != null &&
            data?.sharedTicketIds.includes(selectedTicket.ticketId)
          }
          isFirstTicket={selectedTicketIndex === 0}
          onClose={() => setSelectedTicket(undefined)}
          onShare={addTicketId}
          onShareReset={removeTicketId}
        />
      </DataCheck>
    </div>
  );
}
