import { CreateExperienceRequestValues } from "./useExperienceRequestCreate";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import useCreateExperienceRequest from "@api/private/create/hooks/useCreateExperienceRequest";

const useCreateExperienceRequestHandle: UseFormHandles<CreateExperienceRequestValues> = (
  { values, validateFields, validateCustom },
  onSubmit: () => void,
) => {
  const { send: updateExperiencePackage, isLoading: createIsLoading } = useCreateExperienceRequest(
    values.gameId,
    values.platformId,
    values.cigId,
    values.customerRequestDetails,

    values.tickets,
    values.streamOption,

    values.startTime?.format(),
    values.endTime?.format(),

    values.packageId,
  );

  const handle = async () => {
    let failed = validateFields([
      "gameId",
      "platformId",
      "startTime",
      "endTime",
      "cigId",
      "customerRequestDetails",

      "tickets",
      "sessionLength",
      "streamOption",

      "packageId",
    ]);

    if (!failed) {
      const { data, error } = await updateExperiencePackage();

      if (error) {
        failed = true;

        validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
      if (data) {
        window.location.replace(data.url);
      }
    }

    onSubmit();

    return failed;
  };

  return [handle, createIsLoading];
};

export default useCreateExperienceRequestHandle;
