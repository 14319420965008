import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

const useUserIsAdmin = () => {
  const { user } = useGlobalContext();

  const groups = user?.signInUserSession.idToken.payload["cognito:groups"] ?? [];
  return groups.includes("ADMIN");
};

export default useUserIsAdmin;
