import { SupportedPresentmentCurrency } from "@api/private/get/getSupportedPresentmentCurrency";
import useGetAvailabilityWithID from "@api/private/get/hooks/useGetAvailabilityWithID";
import useGetSupportedPresentmentCurrency from "@api/private/get/hooks/useGetSupportedPresentmentCurrency";
import { ButtonProps } from "@components/form/Button";
import {
  FormValidations,
  OnFormBlur,
  OnFormChange,
  OnFormChangeMultiple,
  ResetValidations,
} from "@components/form/hooks/useFormManager";
import useQuery from "@hooks/useQuery";
import { FormHandle } from "@lib/types/form";
import { Session } from "@lib/types/session";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import useFeedback, { FeedbackValues } from "../hooks/useFeedback";

interface FeedbackContextType {
  values: FeedbackValues;
  validations: FormValidations<FeedbackValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;
  onChangeMultiple: OnFormChangeMultiple;

  handles: FormHandle[];
  resetValidations: ResetValidations;

  ratingIsLoading: boolean;
  tipIsLoading: boolean;
  messageIsLoading: boolean;

  initialStep: number;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;

  session?: Session;
  sessionIsLoading?: boolean;
  sessionError?: string;

  supportedPresentmentCurrency: SupportedPresentmentCurrency | null;
  currencyIsLoading: boolean;
  currencyError?: string;

  buttonSize: ButtonProps["size"];

  state: "success" | "error" | null;
}

export const FeedbackContext = createContext<FeedbackContextType>({} as FeedbackContextType);

export default function FeedbackContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const { breakpoints } = useGlobalContext();

  const { sessionId } = useParams<Params>();
  const bookingId = useQuery("bookingId");
  const initialStepType = useQuery<"message">("step");
  const state = useQuery<"success" | "error">("state");

  const initialStep = initialStepType === "message" ? 2 : 0;

  const [step, setStep] = useState(initialStep);

  const {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles,
    resetValidations,

    ratingIsLoading,
    tipIsLoading,
    messageIsLoading,
  } = useFeedback();

  const {
    data: session,
    isLoading: sessionIsLoading,
    error: sessionError,
  } = useGetAvailabilityWithID(sessionId, true);

  const {
    data: supportedPresentmentCurrency,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useGetSupportedPresentmentCurrency(true);

  useEffect(() => {
    onChangeMultiple([
      {
        name: "sessionId",
        value: sessionId,
      },
      {
        name: "bookingId",
        value: bookingId,
      },
    ]);
    // eslint-disable-next-line
  }, [sessionId, bookingId]);
  // must omit onChangeMultiple to prevent loop

  const buttonSize = breakpoints.sm ? "medium" : "large";

  return (
    <FeedbackContext.Provider
      value={{
        values,
        validations,

        onChange,
        onBlur,
        onChangeMultiple,

        handles,
        resetValidations,

        ratingIsLoading,
        tipIsLoading,
        messageIsLoading,

        initialStep,
        step,
        setStep,

        session,
        sessionIsLoading,
        sessionError,

        supportedPresentmentCurrency,
        currencyIsLoading,
        currencyError,

        buttonSize,

        state,
      }}>
      {children}
    </FeedbackContext.Provider>
  );
}

interface Params {
  sessionId?: string;
}
