import { CIG, CountryCode } from "@lib/enums/generic";
import { api } from "@api/api";

export interface CreateUserExtensionRes {
  cigProfileID: string;
  userExtensionID: string;
  verificationCode: string;
}

const createCIGProfile = async (
  subCategory: string,
  displayName: string,
  category: CIG,
  country: CountryCode,
  // twitterHandle?: string,
  // facebookHandle?: string,
  // instagramHandle?: string,
  twitchHandle?: string,
  // tiktokHandle?: string,
  // youtubeHandle?: string,
) => {
  const { data } = await api.post<CreateUserExtensionRes>("/createCIGProfile", {
    displayName,
    category,
    subCategory,
    country,
    // twitterHandle,
    // facebookHandle,
    // instagramHandle,
    twitchHandle,
    // tiktokHandle,
    // youtubeHandle,
  });
  return data;
};

export default createCIGProfile;
