import IconButton, { IconButtonProps } from "./IconButton";
import { cloneElement, forwardRef, useRef, useState } from "react";

import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { CSSProps, UIColor } from "../../lib/types/generic";
import { ReactElement } from "react";
import { FormElement } from "./Form";
import useCombinedRefs from "@hooks/useCombinedRefs";
import { classNames, elementInstanceOf, preventAndStop } from "@lib/utils/generic";
import Progress from "@components/common/Progress";

interface Props extends CSSProps {
  color?: UIColor;
  textColor?: UIColor;
  variant?: "contained" | "outlined" | "transparent" | "flat";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  type?: "text" | "password" | "email" | "number" | "url";
  name?: string;
  placeholder?: string;
  multiline?: boolean;
  value?: string;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  disabled?: boolean;
  disableHover?: boolean;
  readOnly?: boolean;
  isLoading?: boolean;
  rounded?: boolean;
  focussed?: boolean;
  autocomplete?: boolean;
  oversized?: boolean;
  wrapperClassName?: string;

  noError?: boolean;

  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export type InputProps = Props;

const Input = forwardRef<(HTMLInputElement & HTMLTextAreaElement) | null, Props>(
  (props, forwardedRef) => {
    const {
      color = "white",
      textColor = "black",
      variant = "contained",
      size = "medium",
      type = "text",
      name,
      placeholder,
      multiline = false,
      value,
      startIcon,
      disabled,
      readOnly,
      disableHover = readOnly,
      isLoading,
      rounded,
      focussed,
      autocomplete = true,
      oversized,

      noError,

      style,
      className,
      id,
      wrapperClassName,

      onChange = () => {},
      onBlur = () => {},
      onFocus = () => {},
      onMouseEnter,
      onMouseLeave,
    } = props;

    let { endIcon } = props;

    const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
    const ref = useCombinedRefs(inputRef, forwardedRef);

    const [passwordOverride, setPasswordOverride] = useState(false);

    const iconButtonSize = {
      "extra-small": "no-padding",
      small: "extra-small",
      medium: "small",
      large: "medium",
      "extra-large": "large",
    }[size] as IconButtonProps["size"];

    if (type === "password")
      endIcon = (
        <IconButton
          variant="flat"
          size={iconButtonSize}
          type="button"
          color={color}
          onClick={() => setPasswordOverride(!passwordOverride)}
          icon={passwordOverride ? <IoEyeOffOutline /> : <IoEyeOutline />}
        />
      );

    if (isLoading)
      endIcon = (
        <div className="progress-wrapper">
          <Progress color={textColor} variant="ring" />
        </div>
      );

    return (
      <div
        className={classNames(
          "input-wrapper",
          color,
          `${textColor}-text`,
          variant,
          size,
          startIcon && "has-start-icon",
          endIcon && "has-end-icon",
          multiline && "textarea",
          rounded && "rounded",
          focussed && "focus",
          disableHover && "hover-disabled",
          disabled && "disabled",
          oversized && "oversized",
          noError && "no-error",
          wrapperClassName,
        )}>
        {startIcon &&
          cloneElement(startIcon, {
            className: `${startIcon.props.className ?? ""} input-icon`,
            ...(elementInstanceOf(startIcon, IconButton)
              ? { size: iconButtonSize, color: textColor }
              : {}),
          })}
        <div className="input-overflow-wrapper">
          {multiline ? (
            <textarea
              ref={ref}
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={({ target }) => onChange(target)}
              onBlur={({ target }) => onBlur(target)}
              onFocus={onFocus}
              onClick={preventAndStop}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              {...(passwordOverride ? { type: "text" } : { type })}
              className={classNames("input", color, variant, size, className)}
              id={id}
              style={style}
              disabled={isLoading || disabled}
              readOnly={readOnly}
            />
          ) : (
            <input
              ref={ref}
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={event => onChange(event.target)}
              onBlur={event => onBlur(event.target)}
              onFocus={onFocus}
              onClick={preventAndStop}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              {...(passwordOverride ? { type: "text" } : { type })}
              className={classNames("input", className)}
              id={id}
              style={style}
              disabled={disabled}
              readOnly={readOnly}
              autoComplete={autocomplete ? "on" : "off"}
            />
          )}
        </div>
        {endIcon &&
          cloneElement(endIcon, {
            className: `${endIcon.props.className ?? ""} input-icon`,
            ...(elementInstanceOf(endIcon, IconButton)
              ? { size: iconButtonSize, color: textColor }
              : {}),
          })}
      </div>
    );
  },
);

export default Input;
