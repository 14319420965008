import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import registerFlankInterest, { RegisterFlankInterestRes } from "../registerFlankInterest";

const useRegisterFlankInterest = (email?: string, twitchUsername?: string) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<RegisterFlankInterestRes | null>(null);

  const send = useCallback(async () => {
    if (email == null || twitchUsername == null) return reqState;
    setLoading();
    try {
      const data = await registerFlankInterest(email, twitchUsername);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [email, twitchUsername, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useRegisterFlankInterest;
