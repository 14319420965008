import { RefObject, useEffect } from "react";

const useOnTouchEnd = (ref: RefObject<HTMLElement>, callback: (event: TouchEvent) => void) => {
  useEffect(() => {
    const current = ref.current;
    if (current == null) return;
    current.addEventListener("touchend", callback);
    return () => current.removeEventListener("touchend", callback);
  }, [callback, ref]);
};

export default useOnTouchEnd;
