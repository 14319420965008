import {
  IoGameController,
  IoPersonAdd,
  IoRadio,
  IoArrowDown,
  IoArrowUp,
  IoCheckmarkCircle,
  IoCloseCircle,
  IoCash,
  IoHammer,
  IoBan,
  IoCard,
  IoTime,
  IoReload,
  IoCheckbox,
  IoPerson,
  IoHourglass,
  IoLogoTwitter,
  IoLogoTwitch,
  IoLogoYoutube,
  IoLogoTiktok,
  IoLogoFacebook,
  IoLogoDiscord,
  IoLogoInstagram,
  IoCalendar,
  IoBook,
  IoReceipt,
} from "react-icons/io5";
import { IconType } from "react-icons/lib";

import gamerSplashImage from "@images/misc/splash_gamer.jpg";
import celebritySplashImage from "@images/misc/splash_celebrity.jpg";
import influencerSplashImage from "@images/misc/splash_influencer.jpg";
import {
  CardBrand,
  CIG,
  CIGPlatform,
  ClaimReason,
  ClaimState,
  CountryCode,
  ExperienceRequestState,
  NotificationType,
  Platform,
  SortDirection,
  VerificationPlatform,
  YakkrSocialPlatform,
} from "@lib/enums/generic";
import { UIColor } from "@lib/types/generic";

import avatar1 from "@images/fake/avatar1.jpg";
import avatar2 from "@images/fake/avatar2.jpg";
import avatar3 from "@images/fake/avatar3.jpg";
import avatar4 from "@images/fake/avatar4.jpg";
import avatar5 from "@images/fake/avatar5.jpg";

// import avatar1Blurred from "@images/fake/avatar1_blurred.jpg";
// import avatar2Blurred from "@images/fake/avatar2_blurred.jpg";
// import avatar3Blurred from "@images/fake/avatar3_blurred.jpg";
// import avatar4Blurred from "@images/fake/avatar4_blurred.jpg";
// import avatar5Blurred from "@images/fake/avatar5_blurred.jpg";
// import avatar6Blurred from "@images/fake/avatar6_blurred.jpg";
// import avatar7Blurred from "@images/fake/avatar7_blurred.jpg";
// import avatar8Blurred from "@images/fake/avatar8_blurred.jpg";
// import avatar9Blurred from "@images/fake/avatar9_blurred.jpg";
// import avatar10Blurred from "@images/fake/avatar10_blurred.jpg";

import amexImg from "@images/brands/cards/amex.svg";
import cartesBancairesImg from "@images/brands/cards/cartes_bancaires.svg";
import dinersClubImg from "@images/brands/cards/diners_club.svg";
import discoverImg from "@images/brands/cards/discover.svg";
import jcbImg from "@images/brands/cards/jcb.svg";
import mastercardImg from "@images/brands/cards/mastercard.svg";
import visaImg from "@images/brands/cards/visa.svg";
import unionpayImg from "@images/brands/cards/unionpay.svg";
import Instagram from "@components/brands/Instagram";
import { ReactElement } from "react";
import { CigCardProps } from "@components/sections/CigCard";
import { colors } from "./colors";
import { rawTimeZones } from "@vvo/tzdb";
import { Option } from "@components/form/Options";

export const cigColors: Record<CIG, UIColor> = {
  [CIG.Gamer]: "purple",
  [CIG.Celebrity]: "orange",
  [CIG.Influencer]: "blue",
};

export const cigNames: Record<CIG, string> = {
  [CIG.Gamer]: "Gamer",
  [CIG.Celebrity]: "Celebrity",
  [CIG.Influencer]: "Influencer",
};

export const cigIcons: Record<CIG, IconType> = {
  [CIG.Gamer]: IoGameController,
  [CIG.Celebrity]: IoPersonAdd,
  [CIG.Influencer]: IoRadio,
};

export const cigSplashImages: Record<CIG, string> = {
  [CIG.Gamer]: gamerSplashImage,
  [CIG.Celebrity]: celebritySplashImage,
  [CIG.Influencer]: influencerSplashImage,
};

export const platformColors: Record<Platform, UIColor> = {
  [Platform.Twitter]: "twitter-blue",
  [Platform.Twitch]: "twitch-purple",
  [Platform.YouTube]: "youtube-red",
  [Platform.TikTok]: "tiktok-black",
  [Platform.Facebook]: "facebook-blue",
  [Platform.Instagram]: "instagram-orange",
  [Platform.Discord]: "discord-blue",
};

export const platformNames: Record<Platform, string> = {
  [Platform.Twitter]: "Twitter",
  [Platform.Twitch]: "Twitch",
  [Platform.YouTube]: "YouTube",
  [Platform.TikTok]: "TikTok",
  [Platform.Facebook]: "Facebook",
  [Platform.Instagram]: "Instagram",
  [Platform.Discord]: "Discord",
};

export const platformIcons: Record<Platform, ReactElement> = {
  [Platform.Twitter]: <IoLogoTwitter />,
  [Platform.Twitch]: <IoLogoTwitch />,
  [Platform.YouTube]: <IoLogoYoutube />,
  [Platform.TikTok]: <IoLogoTiktok />,
  [Platform.Facebook]: <IoLogoFacebook />,
  [Platform.Instagram]: <IoLogoInstagram />,
  [Platform.Discord]: <IoLogoDiscord />,
};

export const platformIconsColored: Record<Platform, ReactElement> = {
  [Platform.Twitter]: <IoLogoTwitter color={colors[platformColors[Platform.Twitter]]} />,
  [Platform.Twitch]: <IoLogoTwitch color={colors[platformColors[Platform.Twitch]]} />,
  [Platform.YouTube]: <IoLogoYoutube color={colors[platformColors[Platform.YouTube]]} />,
  [Platform.TikTok]: <IoLogoTiktok color={colors[platformColors[Platform.TikTok]]} />,
  [Platform.Facebook]: <IoLogoFacebook color={colors[platformColors[Platform.Facebook]]} />,
  [Platform.Instagram]: <Instagram />,
  [Platform.Discord]: <IoLogoDiscord color={colors[platformColors[Platform.Discord]]} />,
};

export const cigPlatformUrls: Record<CIGPlatform, string> = {
  [CIGPlatform.Twitter]: "https://twitter.com/",
  [CIGPlatform.Instagram]: "https://www.instagram.com/",
  [CIGPlatform.TikTok]: "https://www.tiktok.com/@",
  [CIGPlatform.Facebook]: "https://www.facebook.com/",
  [CIGPlatform.Twitch]: "https://www.twitch.tv/",
  [CIGPlatform.YouTube]: "https://www.youtube.com/c/",
};

export const yakkrPlatformUrls: Record<YakkrSocialPlatform, string> = {
  [YakkrSocialPlatform.Twitter]: "https://twitter.com/Yakkr_",
  [YakkrSocialPlatform.Instagram]: "https://www.instagram.com/yakkr_/",
  [YakkrSocialPlatform.TikTok]: "https://www.tiktok.com/@yakkr_",
  [YakkrSocialPlatform.Facebook]: "https://www.facebook.com/Yakkr.co.uk",
  [YakkrSocialPlatform.Discord]: "https://discord.gg/34zyGF4KGN",
};

export const platforms: Platform[] = [
  Platform.Twitter,
  Platform.Twitch,
  Platform.YouTube,
  Platform.TikTok,
  Platform.Facebook,
  Platform.Instagram,
  Platform.Discord,
];

export const cigPlatforms: CIGPlatform[] = [
  CIGPlatform.Twitter,
  CIGPlatform.Instagram,
  CIGPlatform.TikTok,
  CIGPlatform.Facebook,
  CIGPlatform.Twitch,
  CIGPlatform.YouTube,
];

export const yakkrSocialPlatforms: YakkrSocialPlatform[] = [
  YakkrSocialPlatform.Twitter,
  YakkrSocialPlatform.Instagram,
  YakkrSocialPlatform.Facebook,
  YakkrSocialPlatform.TikTok,
  YakkrSocialPlatform.Discord,
];

export const verificationPlatforms: VerificationPlatform[] = [
  VerificationPlatform.Twitter,
  VerificationPlatform.Twitch,
  VerificationPlatform.YouTube,
  VerificationPlatform.TikTok,
  VerificationPlatform.Facebook,
  VerificationPlatform.Instagram,
];

export const fakeCigCardData: CigCardProps[] = [
  {
    displayName: "Ekisteret",
    tagLine: "Professional League Player",
    profilePictureURL: avatar1,
  },
  {
    displayName: "Lovenix",
    tagLine: "Selfcare Youtuber",
    profilePictureURL: avatar2,
  },
  {
    displayName: "BiggDiddy",
    tagLine: "I make electronic music",
    profilePictureURL: avatar3,
  },
  {
    displayName: "Snowfingo",
    tagLine: "Fabulous Twitch Streamer",
    profilePictureURL: avatar4,
  },
  {
    displayName: "Ironify",
    tagLine: "Youtube and Twitch Streamer",
    profilePictureURL: avatar5,
  },
];

export const isoDayNames: { [key: number]: string } = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

export const dayNames: { [key: number]: string } = {
  1: "Sunday",
  2: "Monday",
  3: "Tuesday",
  4: "Wednesday",
  5: "Thursday",
  6: "Friday",
  7: "Saturday",
};

export const isoToDay: { [key: number]: number } = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
  4: 5,
  5: 6,
  6: 0,
};

export const monthNames: { [key: number]: string } = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const countryNames = {
  [CountryCode.Australia]: "Australia",
  [CountryCode.Austria]: "Austria",
  [CountryCode.Belgium]: "Belgium",
  [CountryCode.Bulgaria]: "Bulgaria",
  [CountryCode.Canada]: "Canada",
  [CountryCode.Croatia]: "Croatia",
  [CountryCode.Cyprus]: "Cyprus",
  [CountryCode.CzechRepublic]: "Czech Republic",
  [CountryCode.Denmark]: "Denmark",
  [CountryCode.Estonia]: "Estonia",
  [CountryCode.Finland]: "Finland",
  [CountryCode.France]: "France",
  [CountryCode.Germany]: "Germany",
  [CountryCode.Gibraltar]: "Gibraltar",
  [CountryCode.Greece]: "Greece",
  [CountryCode.HongKongSARChina]: "Hong Kong SAR China",
  [CountryCode.Hungary]: "Hungary",
  [CountryCode.Ireland]: "Ireland",
  [CountryCode.Italy]: "Italy",
  [CountryCode.Japan]: "Japan",
  [CountryCode.Latvia]: "Latvia",
  [CountryCode.Liechtenstein]: "Liechtenstein",
  [CountryCode.Lithuania]: "Lithuania",
  [CountryCode.Luxembourg]: "Luxembourg",
  [CountryCode.Malta]: "Malta",
  [CountryCode.Mexico]: "Mexico ",
  [CountryCode.Netherlands]: "Netherlands",
  [CountryCode.NewZealand]: "New Zealand",
  [CountryCode.Norway]: "Norway",
  [CountryCode.Poland]: "Poland",
  [CountryCode.Portugal]: "Portugal",
  [CountryCode.Romania]: "Romania",
  [CountryCode.Singapore]: "Singapore",
  [CountryCode.Slovakia]: "Slovakia",
  [CountryCode.Slovenia]: "Slovenia",
  [CountryCode.Spain]: "Spain",
  [CountryCode.Sweden]: "Sweden",
  [CountryCode.Switzerland]: "Switzerland",
  [CountryCode.UnitedArabEmirates]: "United Arab Emirates",
  [CountryCode.UnitedKingdom]: "United Kingdom",
  [CountryCode.UnitedStates]: "United States",
};

export const cardBrandImages = {
  [CardBrand.AmericanExpress]: amexImg,
  [CardBrand.CartesBancaires]: cartesBancairesImg,
  [CardBrand.DinersClub]: dinersClubImg,
  [CardBrand.Discover]: discoverImg,
  [CardBrand.JCB]: jcbImg,
  [CardBrand.Mastercard]: mastercardImg,
  [CardBrand.Visa]: visaImg,
  [CardBrand.UnionPay]: unionpayImg,
};

export const sortDirectionIcons = {
  [SortDirection.Ascending]: <IoArrowDown />,
  [SortDirection.Descending]: <IoArrowUp />,
};

export const sortDirectionInvert = {
  [SortDirection.Ascending]: SortDirection.Descending,
  [SortDirection.Descending]: SortDirection.Ascending,
};

export const claimStateNames = {
  [ClaimState.Resolved]: "Resolved",
  [ClaimState.Pending]: "Pending",
  [ClaimState.Rejected]: "Rejected",
  [ClaimState.Created]: "Created",
};

export const claimStateColors = {
  [ClaimState.Resolved]: "green",
  [ClaimState.Pending]: "gray-2",
  [ClaimState.Rejected]: "red",
  [ClaimState.Created]: "purple",
};

export const claimReasonNames = {
  [ClaimReason.TechnicalDifficulties]: "Technical difficulties",
  [ClaimReason.CigNoShow]: "They didn't turn up",
  [ClaimReason.Other]: "Other",
};

export const notificationTypeIcons: Record<NotificationType, ReactElement> = {
  [NotificationType.BookingConfirmed]: <IoBook />,
  [NotificationType.BookingCancelled]: <IoBook />,
  [NotificationType.BookingCancelledAndRefunded]: <IoCash />,

  [NotificationType.DisputeResolved]: <IoHammer />,
  [NotificationType.DisputeRejected]: <IoBan />,

  [NotificationType.SubscriptionCreated]: <IoCard />,
  [NotificationType.SubscriptionCancelled]: <IoCard />,

  [NotificationType.SessionStarting]: <IoTime />,
  [NotificationType.VerificationRequested]: <IoReload />,
  [NotificationType.VerificationRejected]: <IoBan />,
  [NotificationType.VerificationAccepted]: <IoCheckbox />,

  [NotificationType.CigAccepted]: <IoCheckbox />,
  [NotificationType.CigRejected]: <IoBan />,

  [NotificationType.CigSessionCreate]: <IoCalendar />,
  [NotificationType.GuestBookingConfirmed]: <IoCheckmarkCircle />,
  [NotificationType.BookingConfirmedNotifyCig]: <IoPerson />,
  [NotificationType.CigSessionStartingSoon]: <IoHourglass />,
  [NotificationType.DayReminder]: <IoHourglass />,
  [NotificationType.HourReminder]: <IoHourglass />,

  [NotificationType.NewRequestRecieved]: <IoReceipt />,
  [NotificationType.CigAcceptedRequest]: <IoReceipt />,
  [NotificationType.CigDeclinedRequest]: <IoReceipt />,
  [NotificationType.RequestExpired]: <IoHourglass />,
  [NotificationType.RequestWithdrawn]: <IoCloseCircle />,
  [NotificationType.RequestAcknowledged]: <IoCheckmarkCircle />,
  [NotificationType.CustomerCancelledAcceptedRequest]: <IoReceipt />,
  [NotificationType.CigAcceptedRequestReminder]: <IoHourglass />,
};
export const notificationTypeColors: Record<NotificationType, UIColor> = {
  [NotificationType.BookingConfirmed]: "green",
  [NotificationType.BookingCancelled]: "red",
  [NotificationType.BookingCancelledAndRefunded]: "red",

  [NotificationType.DisputeResolved]: "green",
  [NotificationType.DisputeRejected]: "red",

  [NotificationType.SubscriptionCreated]: "green",
  [NotificationType.SubscriptionCancelled]: "red",

  [NotificationType.SessionStarting]: "yellow",
  [NotificationType.VerificationRequested]: "yellow",
  [NotificationType.VerificationRejected]: "red",
  [NotificationType.VerificationAccepted]: "green",

  [NotificationType.CigAccepted]: "green",
  [NotificationType.CigRejected]: "red",

  [NotificationType.CigSessionCreate]: "green",
  [NotificationType.GuestBookingConfirmed]: "green",
  [NotificationType.BookingConfirmedNotifyCig]: "green",
  [NotificationType.CigSessionStartingSoon]: "yellow",
  [NotificationType.DayReminder]: "yellow",
  [NotificationType.HourReminder]: "yellow",

  [NotificationType.NewRequestRecieved]: "green",
  [NotificationType.CigAcceptedRequest]: "green",
  [NotificationType.CigDeclinedRequest]: "red",
  [NotificationType.RequestExpired]: "red",
  [NotificationType.RequestWithdrawn]: "red",
  [NotificationType.RequestAcknowledged]: "yellow",
  [NotificationType.CustomerCancelledAcceptedRequest]: "red",
  [NotificationType.CigAcceptedRequestReminder]: "green",
};

export const experienceRequestStateNames = {
  [ExperienceRequestState.PendingCardAuth]: "Card Pending",
  [ExperienceRequestState.Pending]: "Pending",
  [ExperienceRequestState.Accepted]: "Accepted",
  [ExperienceRequestState.Rejected]: "Rejected",
  [ExperienceRequestState.CigCancelled]: "Cancelled by Talent",
  [ExperienceRequestState.CustomerCancelled]: "Cancelled by Customer",
  [ExperienceRequestState.Expired]: "Expired",
  [ExperienceRequestState.Completed]: "Completed",
  [ExperienceRequestState.ExpiredCardAuth]: "Card Authentication Expired",
};

export const experienceRequestStateColors = {
  [ExperienceRequestState.PendingCardAuth]: "yellow",
  [ExperienceRequestState.Pending]: "yellow",
  [ExperienceRequestState.Accepted]: "green",
  [ExperienceRequestState.Rejected]: "red",
  [ExperienceRequestState.CigCancelled]: "red",
  [ExperienceRequestState.CustomerCancelled]: "red",
  [ExperienceRequestState.Expired]: "gray-2",
  [ExperienceRequestState.Completed]: "gray-3",
  [ExperienceRequestState.ExpiredCardAuth]: "red",
};

export const timeZoneOptions: Option[] = rawTimeZones.map(({ name }) => ({
  value: name,
  label: name,
}));

export const aboutSiteUrl = "https://exclusive.yakkr.co.uk/";
export const helpSiteUrl = "https://community.yakkr.co.uk/";

export const faqUrl = `${helpSiteUrl}faqs`;
export const blogUrl = `${helpSiteUrl}blog`;

export const privacyUrl = `${helpSiteUrl}privacypolicy`;
export const termsUrl = `${helpSiteUrl}terms-of-service`;
export const acceptableUseUrl = `${helpSiteUrl}acceptable-use`;

export const contactEmail = "info@yakkr.co.uk";
export const bookingEmail = "booking@yakkr.co.uk";
export const legalEmail = "marcus@yakkr.co.uk";

export const assetPackUrl =
  "https://drive.google.com/drive/u/0/folders/1xucOXW5fo5oQc9zG4MDR5_d_p0B5F-xm";

export const flankDiscordUrl = "https://discord.gg/z4yxC58M";
export const flankPrivacyUrl =
  "https://docs.google.com/document/d/1rJA1LW0FHWYWiaJ5-ursRCkjykASPL3EwE07a8tIkcA/edit?usp=sharing";
export const growthDiscordUrl = "https://discord.gg/jWAb9MYAGt";
