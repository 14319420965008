import BackgroundImage from "@components/common/BackgroundImage";
import { Currency } from "@lib/enums/generic";
import { UIColor } from "@lib/types/generic";
import React from "react";
import background from "@images/misc/experience_card_background.jpg";
import {
  classNames,
  getCurrencySymbol,
  numberToPrice,
  parseTime,
  pluralise,
} from "@lib/utils/generic";
import Button, { ButtonProps } from "@components/form/Button";
import { IoArrowForward } from "react-icons/io5";
import Divider from "@components/common/Divider";
import { Link, useHistory } from "react-router-dom";
import DataCheck from "@components/common/DataCheck";
import { Game } from "@api/public/get/getGames";
import Pill from "@components/common/Pill";

export const defaultExperienceCardDetails: {
  color: UIColor;
  name: string;
}[] = [
  {
    color: "purple-5",
    name: "Bronze",
  },
  {
    color: "white",
    name: "Silver",
  },
  {
    color: "gold",
    name: "Gold",
  },
];

interface Props {
  isCustom?: boolean;

  highlight?: boolean;
  highlightText?: string;

  color?: UIColor;
  name?: string;
  price?: number;
  currency?: Currency;
  tagLine?: string;

  tickets?: number;
  duration?: number;
  longestGame?: Game | null;
  livestreamed?: boolean;

  img?: string | null;

  to?: string;

  width?: number | string;

  isMobile?: boolean;
  buttonSize?: ButtonProps["size"];
  buttonText?: string;
  isCig?: boolean;

  isLoading?: boolean;
}

export type ExperienceCardProps = Props;

export default function ExperienceCard(props: Props) {
  const {
    isCustom,

    highlight,
    highlightText,

    color = "blue",
    name,
    price,
    currency,
    tagLine,

    tickets,
    duration,
    longestGame,
    livestreamed,

    img,

    to = "#",

    width = 350,

    isMobile,
    buttonSize,
    buttonText = "Book Now",
    isCig,

    isLoading,
  } = props;

  const history = useHistory();

  const { hours, minutes } = parseTime(duration ?? 0);

  const pronoun = isCig ? "They" : "You";

  const numberOfGames =
    longestGame?.estimatedMaxLengthMins && !!duration
      ? Math.floor(duration / 60 / longestGame.estimatedMaxLengthMins)
      : 0;

  return (
    <div
      className={classNames(
        "experience-card",
        color,
        isMobile && "is-mobile",
        highlight && "highlight",
        isLoading && "is-loading",
      )}
      style={{
        fontSize: width,
      }}>
      <Link to={to} className="experience-card-inner">
        <DataCheck isLoading={isLoading} loadingIndicator="gloss">
          <BackgroundImage src={img ?? background} opacity={1} />

          <BackgroundImage className="offset-background" color="black-2" opacity={1} />

          <div className="pills">
            {highlight && (
              <Pill size="small" color="purple-5" textColor="black-1">
                {highlightText}
              </Pill>
            )}
            {numberOfGames > 0 && (
              <Pill size="small" color="black-4" textColor="white">
                {numberOfGames} {pluralise("Game", numberOfGames)}
              </Pill>
            )}
          </div>

          <div className="details">
            <div className="bar">
              <p className="name">{isCustom ? "Custom" : name}</p>
              {price != null && currency && (
                <p className="price">
                  <span className="currency">{getCurrencySymbol(currency)}</span>
                  {numberToPrice(price)}
                  <br />
                </p>
              )}
            </div>

            <p className="tag-line">
              {isCustom ? "Build your own fully customised gaming experience!" : tagLine}
            </p>

            <div className="bars">
              <div className="bar">
                <p className="label">Tickets</p>
                <p className="value">
                  {tickets != null ? (
                    <>
                      {tickets} {pluralise("Ticket", tickets)}
                    </>
                  ) : (
                    isCustom && `${pronoun} choose`
                  )}
                </p>
              </div>

              <div className="bar">
                <p className="label">Length</p>
                <p className="value">
                  {duration != null ? (
                    <>
                      <span>
                        {hours > 0 && `${hours} ${pluralise("Hour", hours)}`}{" "}
                        {minutes > 0 && `${minutes} ${pluralise("Minute", minutes)}`}
                      </span>
                    </>
                  ) : (
                    isCustom && `${pronoun} choose`
                  )}
                </p>
              </div>

              <div className="bar">
                <p className="label">Livestreamed</p>
                {livestreamed != null ? (
                  <p className="value">{livestreamed ? "Yes" : "No"}</p>
                ) : (
                  isCustom && <p className="value">{pronoun} choose</p>
                )}
              </div>

              <div className="bar">
                <p className="label">Extras</p>
                <div className="value gradient">
                  {!isCustom ? "Included" : `${pronoun} choose`}
                  <Divider />
                </div>
              </div>

              <Button
                size={buttonSize}
                color={highlight ? "purple" : "gray-1"}
                textColor="white"
                endIcon={<IoArrowForward />}
                justifyContent="center"
                onClick={() => history.push(to)}>
                {buttonText}
              </Button>
            </div>
          </div>
        </DataCheck>
      </Link>
    </div>
  );
}
