import Button from "@components/form/Button";
import GradientButton from "@components/form/GradientButton";
import React from "react";
import { IoArrowForward } from "react-icons/io5";
import useRequestExperienceContext from "./context/useRequestExperienceContext";
import ExperiencesRequestSummary from "../requests/ExperiencesRequestSummary";

export default function ExperiencesRequestSummaryPanel() {
  const {
    createIsLoading,

    nextTab,
    goToNextTab,
  } = useRequestExperienceContext();

  return (
    <div className="experiences-request-summary-panel">
      <ExperiencesRequestSummary />

      {nextTab ? (
        <Button
          variant="contained"
          size="large"
          color="purple"
          textColor="white"
          endIcon={<IoArrowForward />}
          justifyContent="center"
          onClick={goToNextTab}>
          Next
        </Button>
      ) : (
        <GradientButton
          gradient="gradient-blend-horizontal"
          size="large"
          textColor="white"
          endIcon={<IoArrowForward />}
          justifyContent="center"
          type="submit"
          form="request-experience"
          animated
          glow
          animationOrientation="horizontal"
          isLoading={createIsLoading}>
          Checkout
        </GradientButton>
      )}
    </div>
  );
}
