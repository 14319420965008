import Modal from "@components/modals/Modal";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import LoginContextProvider from "./context/LoginContextProvider";
import LoginInner from "./LoginInner";

const LoginModal = () => {
  const { authModal, setAuthModal } = useGlobalContext();

  const active = authModal.type === "login";
  const close = () => setAuthModal(null);

  return (
    <Modal active={active} onClose={close} onlyCloseButton id="login-modal" className="auth-modal">
      <LoginContextProvider>
        <LoginInner />
      </LoginContextProvider>
    </Modal>
  );
};

export default LoginModal;
