import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const shareTicketValidators = {
  bookingID: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").exists();
  },
  ticketID: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").exists();
  },
};

export default shareTicketValidators;
