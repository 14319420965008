import useCreateCIGPaymentPortalLink from "@api/private/create/hooks/useCreateCIGPaymentPortalLink";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createErrorSnackbar } from "@lib/utils/generic";
import { useCallback } from "react";

const useCigPaymentPortal = () => {
  const { pushSnackbar } = useGlobalContext();

  const { isLoading, send } = useCreateCIGPaymentPortalLink();

  const viewCigPaymentPortal = useCallback(async () => {
    const { data, error } = await send();
    if (error) return pushSnackbar(createErrorSnackbar(error, 5000));
    const url = data?.url;
    if (url) window.open(url);
  }, [send, pushSnackbar]);

  return { isLoading, viewCigPaymentPortal };
};

export default useCigPaymentPortal;
