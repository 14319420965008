import { Dispatch, RefObject, useEffect, useState } from "react";
import useOnMouseUp from "./useOnMouseUp";

const useFocussedWithin = (
  ref: RefObject<HTMLElement>,
): [boolean, Dispatch<React.SetStateAction<boolean>>] => {
  const [focussed, setFocussed] = useState(false);

  useEffect(() => {
    const current = ref.current;
    if (current != null && current !== document.activeElement) current.focus();
  }, [ref]);

  useOnMouseUp(undefined, () => {
    const activeElement = document.activeElement;
    const element = ref.current;

    const datePickerFocussed = element?.contains(activeElement) || element === activeElement;

    if (datePickerFocussed) return;
    setFocussed(false);
  });

  return [focussed, setFocussed];
};

export default useFocussedWithin;
