import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { CIG, CountryCode } from "@lib/enums/generic";
import { useCallback } from "react";
import createCIGProfile, { CreateUserExtensionRes } from "../createCIGProfile";

const useCreateCIGProfile = (
  subCategory?: string,
  displayName?: string,
  category?: CIG,
  country?: CountryCode,
  // twitterHandle?: string,
  // facebookHandle?: string,
  // instagramHandle?: string,
  twitchHandle?: string,
  // tiktokHandle?: string,
  // youtubeHandle?: string,
) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CreateUserExtensionRes | null>(null);

  const send = useCallback(async () => {
    if (category == null || subCategory == null || displayName == null || country == null)
      return reqState;
    setLoading();
    try {
      const data = await createCIGProfile(
        subCategory,
        displayName,
        category,
        country,
        // twitterHandle,
        // facebookHandle,
        // instagramHandle,
        twitchHandle,
        // tiktokHandle,
        // youtubeHandle,
      );
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [
    subCategory,
    displayName,
    category,
    country,
    // twitterHandle,
    // facebookHandle,
    // instagramHandle,
    twitchHandle,
    // tiktokHandle,
    // youtubeHandle,
    reqState,
    setError,
    setLoading,
    setSuccess,
  ]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateCIGProfile;
