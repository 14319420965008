import Form from "@components/form/Form";
import { createSuccessSnackbar } from "@lib/utils/generic";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { ForgotPasswordModalData } from "./context/ForgotPasswordContextProvider";
import useForgotPasswordContext from "./context/useForgotPasswordContext";
import ForgotPasswordStep from "./steps/ForgotPasswordConfirmStep";
import ForgotPasswordSubmitStep from "./steps/ForgotPasswordSubmitStep";

export default function ForgotPasswordForm() {
  const { setAuthModal, pushSnackbar } = useGlobalContext();

  const { handles, resetValidations, validations, setStep } = useForgotPasswordContext();

  const { email, redirect } = useAuthModalData<ForgotPasswordModalData>();

  const [forgotPasswordConfirmHandle, forgotPasswordSubmitHandle] = handles;

  return (
    <Form
      id="forgot-password-form"
      onStepChange={setStep}
      resetValidations={resetValidations}
      onCompletion={() => {
        setAuthModal("login", { email, redirect });
        pushSnackbar(
          createSuccessSnackbar(
            "Password changed! You can now login using your new password.",
            5000,
          ),
        );
      }}
      validation={validations.generic}
      triggerGlobalLoading
      steps={[
        {
          children: <ForgotPasswordStep />,
          handle: forgotPasswordConfirmHandle,
        },
        {
          children: <ForgotPasswordSubmitStep />,
          handle: forgotPasswordSubmitHandle,
        },
      ]}
    />
  );
}
