import React from "react";
import { OverlayAlertProps } from "../../hooks/useCigOverlayAlerts";
import GiftIcon from "../icons/GiftIcon";
import OverlayAlert from "../OverlayAlert";

interface Props extends OverlayAlertProps {
  displayName: string;
}

export default function GiftedSessionAlert(props: Props) {
  const { displayName, ...alertProps } = props;

  return (
    <OverlayAlert
      {...alertProps}
      icon={<GiftIcon />}
      title={displayName}
      subTitle="Gifted a session"
    />
  );
}
