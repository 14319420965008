import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import uploadToS3 from "../uploadToS3";

const useUploadToS3 = (url?: string, file?: File) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<null>(null);

  const send = useCallback(
    async (urlOverride?: string, fileOverride?: File) => {
      const _url = urlOverride ?? url;
      const _file = fileOverride ?? file;

      if (_url == null || _file == null) return reqState;

      setLoading();
      try {
        const { data } = await uploadToS3(_url, _file);
        return setSuccess(data);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [url, file, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useUploadToS3;
