import useCIGKickParticipant from "@api/private/twilio/hooks/useCIGKickParticipant";
import { createErrorSnackbar, createSuccessSnackbar } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useCallback, useState } from "react";
import useRoomContext from "../../../roomContext/hooks/useRoomContext";

const useHandleKick = (chatParticipantSid?: string) => {
  const { pushSnackbar } = useGlobalContext();
  const { session } = useRoomContext();

  const { send: cigKickParticipant, isLoading: kickIsLoading } = useCIGKickParticipant(
    session?.id,
    chatParticipantSid,
  );

  const [kickModalActive, setKickModalActive] = useState(false);

  const handleKick = useCallback(async () => {
    const { error } = await cigKickParticipant();
    setKickModalActive(false);
    if (error) pushSnackbar(createErrorSnackbar(error, 5000));
    else pushSnackbar(createSuccessSnackbar("Participant kicked", 3000));
  }, [cigKickParticipant, pushSnackbar, setKickModalActive]);

  const toggleKickModal = useCallback(() => {
    setKickModalActive(kickModalActive => !kickModalActive);
  }, [setKickModalActive]);

  return { kickModalActive, kickIsLoading, toggleKickModal, handleKick };
};

export default useHandleKick;
