import { UIGradient } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import { forwardRef, PropsWithChildren } from "react";
import Button, { ButtonProps } from "./Button";

interface Props extends Omit<ButtonProps, "color" | "variant"> {
  gradient?: UIGradient;
  animated?: boolean;
  glow?: boolean;
  animationOrientation?: "horizontal" | "vertical";
  wrapperClassName?: string;
}

export type GradientButtonProps = Props;

const GradientButton = forwardRef<HTMLButtonElement & HTMLAnchorElement, PropsWithChildren<Props>>(
  (props, ref) => {
    const {
      gradient = "gradient-blend-horizontal",
      animated,
      glow,
      animationOrientation = "horizontal",

      wrapperClassName,

      ...buttonProps
    } = props;

    return (
      <div
        className={classNames(
          "gradient-button",
          gradient,
          animationOrientation,
          buttonProps.size,
          buttonProps.disabled && "disabled",
          buttonProps.disableHover && "hover-disabled",
          buttonProps.isLoading && "loading",
          animated && "animated",
          glow && "glow",
          wrapperClassName,
        )}>
        <Button {...buttonProps} ref={ref} variant="flat" />
      </div>
    );
  },
);

export default GradientButton;
