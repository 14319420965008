import { api } from "@api/api";
import { Currency } from "@lib/enums/generic";

export interface CIGReview {
  score: number; // 1 - 10
  review: string | null;
  createdAt: string;
  displayName?: string;
  amount?: string;
  currency?: Currency;
}

const getCigReviews = async (cigID?: string) => {
  const { data } = await api.get<CIGReview[]>("/getReviewsForCIG", {
    params: { cigID },
  });
  return data;
};

export default getCigReviews;
