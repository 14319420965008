import {
  FormValidations,
  OnFormBlur,
  OnFormChange,
  OnFormChangeMultiple,
  ResetValidations,
} from "@components/form/hooks/useFormManager";
import { FormHandle } from "@lib/types/form";
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from "react";
import useCigLogin, { LoginValues } from "../hooks/useLogin";

export interface LoginModalData {
  redirect?: string;
  email?: string;
}

interface LoginContextType {
  values: LoginValues;
  validations: FormValidations<LoginValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;
  onChangeMultiple: OnFormChangeMultiple;

  handles: FormHandle[];
  resetValidations: ResetValidations;

  loginIsLoading: boolean;

  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}

export const LoginContext = createContext<LoginContextType>({} as LoginContextType);

export default function LoginContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [step, setStep] = useState(0);

  const {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles,
    resetValidations,

    loginIsLoading,
  } = useCigLogin();

  return (
    <LoginContext.Provider
      value={{
        values,
        validations,

        onChange,
        onBlur,
        onChangeMultiple,

        handles,
        resetValidations,

        loginIsLoading,

        step,
        setStep,
      }}>
      {children}
    </LoginContext.Provider>
  );
}
