import YakkrBanner from "@components/common/YakkrBanner";
import IconButton from "@components/form/IconButton";
import useUserIsCig from "@hooks/session/useUserIsCig";
import {
  acceptableUseUrl,
  blogUrl,
  contactEmail,
  faqUrl,
  helpSiteUrl,
  platformColors,
  platformIcons,
  privacyUrl,
  termsUrl,
  yakkrPlatformUrls,
  yakkrSocialPlatforms,
} from "@lib/constants/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { Link } from "react-router-dom";

export default function Footer() {
  const { breakpoints } = useGlobalContext();

  const isCig = useUserIsCig();

  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="wrapper">
          <div className="top">
            <div className="branding">
              <YakkrBanner size={breakpoints.sm ? "extra-small" : "small"} />
            </div>

            <div className="socials">
              {yakkrSocialPlatforms.map(platform => (
                <IconButton
                  key={platform}
                  icon={platformIcons[platform]}
                  color={platformColors[platform]}
                  href={yakkrPlatformUrls[platform]}
                  openInNewTab
                  variant="flat"
                  iconColor="white"
                  size="medium"
                />
              ))}
            </div>
          </div>

          <div className="bottom">
            <div className="link-column-group">
              <div className="link-column">
                <p className="link-header">Company</p>
                <a href={faqUrl} className="link">
                  FAQs
                </a>
                <a href={blogUrl} className="link">
                  Blog
                </a>
              </div>

              <div className="link-column">
                <p className="link-header">Product</p>
                {!isCig && (
                  <Link to="/become-talent" className="link">
                    Become Talent
                  </Link>
                )}
                <Link to="/report-bug" className="link">
                  Report a bug
                </Link>
                <a href={helpSiteUrl} className="link">
                  Help
                </a>
              </div>
            </div>

            <div className="link-column-group">
              <div className="link-column">
                <p className="link-header">Legal</p>
                <a href={termsUrl} className="link">
                  Terms
                </a>
                <a href={privacyUrl} className="link">
                  Privacy
                </a>
                <a href={acceptableUseUrl} className="link">
                  Acceptance
                </a>
              </div>

              <div className="link-column">
                <p className="link-header">Contact</p>
                <a href={`mailto:${contactEmail}`} className="link">
                  Investors
                </a>
                <a href={`mailto:${contactEmail}`} className="link">
                  Privacy
                </a>
                <a href={`mailto:${contactEmail}`} className="link">
                  Acceptance
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
