import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const bookSessionValidators = {
  slots: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("number");
  },
};

export default bookSessionValidators;
