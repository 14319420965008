import { api } from "@api/api";

export interface ShareTicketRes {}

const shareTicket = async (bookingID: string, ticketID: string, emailToGift: string) => {
  const { data } = await api.post<ShareTicketRes>("/inviteToSession", {
    bookingID,
    ticketID,
    emailToGift,
  });
  return data;
};

export default shareTicket;
