import useGetExperienceRequests from "@api/private/get/hooks/useGetExperienceRequests";
import { ExperienceRequestState } from "@lib/enums/generic";

const useCustomerExpereinceRequests = () => {
  const { data, isLoading, error, send } = useGetExperienceRequests(true);

  return {
    data,
    pendingData: data.filter(({ state }) => state === ExperienceRequestState.Pending),
    isLoading,
    error,
    send,
  };
};

export default useCustomerExpereinceRequests;
