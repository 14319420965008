import useGetGames from "@api/public/get/hooks/useGetGames";
import BarProgress from "@components/common/BarProgress";
import dayjs from "dayjs";
import React, { useState } from "react";
import Calendar from "../Calendar";
import useCalendarContext from "../context/useCalendarContext";
import CalendarManageBody from "./CalendarManageBody";
import CreateSession, { CreateSessionValues } from "./create/CreateSession";
import useBlockSelector from "./hooks/useBlockSelector";
import UpdateSession from "./update/UpdateSession";

export default function CalendarManage() {
  const {
    periodDate,
    isLoading,
    error,
    fullAvailability,
    sessionId,
    setSessionId,
    goToNextPeriod,
    goToPrevPeriod,
    onUpdate,
    reSend,
    handleSessionDelete,
  } = useCalendarContext();

  const [createValues, setCreateValues] = useState<CreateSessionValues | undefined>();
  const [cancelSession, setCancelSession] = useState(false);

  const { data: games, error: gamesError, isLoading: gamesIsLoading } = useGetGames(true);

  const blockSelector = useBlockSelector(fullAvailability, goToNextPeriod, goToPrevPeriod);

  const { startDate, endDate, startSelecting, stopSelecting, resetSelection } = blockSelector;

  return (
    <Calendar
      className="calendar-manage"
      title="Manage Schedule"
      actionPanel={
        sessionId != null ? (
          <UpdateSession
            active={sessionId != null}
            cancel={cancelSession}
            isLoading={isLoading}
            initialValues={fullAvailability.find(({ id }) => id === sessionId)}
            onClose={() => setSessionId(null)}
            onCompletion={() => {
              reSend();
              onUpdate();
            }}
            onCancel={sessionId => {
              handleSessionDelete(sessionId);
              reSend();
              onUpdate();
            }}
            onNotCancelling={() => setCancelSession(false)}
          />
        ) : (
          <CreateSession
            active={endDate != null && !blockSelector.selecting && !blockSelector.selectError}
            onClose={resetSelection}
            startDate={startDate ?? undefined}
            endDate={endDate ?? undefined}
            onStartDateChange={newStartDate => {
              if (endDate == null) return;

              if (!startSelecting(newStartDate, true)) return;
              stopSelecting(endDate.subtract(15, "minutes"), true);
            }}
            onEndDateChange={newEndDate => {
              if (startDate == null) return;

              if (!startSelecting(startDate, true)) return;
              stopSelecting(newEndDate.subtract(15, "minutes"), true);
            }}
            onChange={values => setCreateValues(values)}
            onCompletion={() => {
              reSend();
              onUpdate();
            }}
          />
        )
      }>
      <div className="bar-progress-wrapper">
        <BarProgress active={isLoading} color="purple" />
      </div>
      <CalendarManageBody
        periodDate={dayjs(periodDate)}
        fullAvailability={fullAvailability}
        selectionDetails={{
          gameName: games.find(game => game.id === createValues?.gameId)?.shortName,
          totalSlots: createValues?.totalSlots,
          exclusive: createValues?.isExclusive,
          charity: createValues?.isCharity,
          error: gamesError,
          isLoading: gamesIsLoading,
        }}
        error={error}
        isLoading={isLoading}
        sessionId={sessionId}
        onSessionSelect={id => {
          setSessionId(id);
          resetSelection();
        }}
        onSessionCancel={id => {
          setCancelSession(true);
          setSessionId(id);
          resetSelection();
        }}
        {...blockSelector}
        stopSelecting={date => {
          setSessionId(null);
          stopSelecting(date);
        }}
      />
    </Calendar>
  );
}
