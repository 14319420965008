import HowItWorksSplash from "@components/splash/sections/HowItWorksSplash";
import { FaFileContract } from "react-icons/fa";
import { IoCalendar, IoGameController, IoSparkles } from "react-icons/io5";

const HowItWorks = () => {
  return (
    <HowItWorksSplash
      steps={[
        {
          title: "Choose your streamer, celebrity, or influencer",
          icon: <FaFileContract />,
        },
        {
          title: "Pick what game you want to play and for how long",
          icon: <IoGameController />,
        },
        {
          title: "Book a time that suits you both",
          icon: <IoCalendar />,
        },
        {
          title: "Have a truly unforgettable experience",
          icon: <IoSparkles />,
        },
      ]}
    />
  );
};

export default HowItWorks;
