import DataCheck from "@components/common/DataCheck";
import ScrollCarousel from "@components/common/ScrollCarousel";
import ReviewCard from "@components/sections/ReviewCard";
import CigProfileTitle from "../CigProfileTitle";
import useCigProfileContext from "../context/hooks/useCigProfileContext";

const ClipsAndReviews = () => {
  const { reviews, reviewsIsLoading, reviewsError } = useCigProfileContext();

  // const [playingIndex, setPlayingIndex] = useState(0);

  return (
    <section className="section clips">
      <CigProfileTitle>Unforgettable moments together</CigProfileTitle>

      <ScrollCarousel>
        {/* <ReviewClip
          src="https://cdn.discordapp.com/attachments/1067481840849526795/1069889219553677322/vllo.mov"
          rating={randomNum(1, 5)}
          review="Omg! That was so fun. I've always wanted to meet you."
          autoPlay={i === 0}
          doReset={playingIndex !== i}
          onPlay={() => setPlayingIndex(i)}
        /> */}

        {reviewsIsLoading ? (
          <>
            {new Array(6).fill(null).map((_, i) => (
              <ReviewCard isLoading key={i} />
            ))}
          </>
        ) : (
          <DataCheck error={reviewsError}>
            {reviews.map(
              ({ review, score, displayName }, i) =>
                review && (
                  <ReviewCard
                    key={i}
                    rating={Math.ceil(score / 2)}
                    displayName={displayName}
                    review={review ?? undefined}
                  />
                ),
            )}
          </DataCheck>
        )}
      </ScrollCarousel>
    </section>
  );
};

export default ClipsAndReviews;
