import useSetExperienceRequestState from "@api/private/update/hooks/useSetExperienceRequestState";
import DialogueModal from "@components/modals/DialogueModal";
import { createErrorSnackbar, createSuccessSnackbar } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";

interface Props {
  requestId?: string;
  onReject: () => void;
  onClose: () => void;
}

export default function ExperiencesRequestsRejectModal(props: Props) {
  const { requestId, onReject, onClose } = props;

  const { pushSnackbar } = useGlobalContext();

  const { send, isLoading } = useSetExperienceRequestState(requestId, false);

  const rejectRequest = async () => {
    const { error } = await send();
    if (error) pushSnackbar(createErrorSnackbar(error, 5000));
    else {
      pushSnackbar(createSuccessSnackbar("Request rejected", 3000));
      onReject();
    }
    onClose();
  };

  return (
    <DialogueModal
      color="red"
      subTitle="Once rejected, it cannot be undone."
      titleDesign="condensed"
      buttonsDesign="flat"
      active={!!requestId}
      confirmButtonText="Accept"
      confirmButtonProps={{
        textColor: "black",
        isLoading,
      }}
      onConfirm={rejectRequest}
      onCancel={onClose}
    />
  );
}
