import React from "react";
import GradientIcon, { GradientIconInnerProps, GradientIconProps } from "./GradientIcon";

const IoSparklesGradient = (props: GradientIconProps) => (
  <GradientIcon {...props}>
    {({ gradientDefinition, label, ...cssProps }: GradientIconInnerProps) => (
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        {...cssProps}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={label ? `url(#${label})` : undefined}
          d="M208 512a24.84 24.84 0 01-23.34-16l-39.84-103.6a16.06 16.06 0 00-9.19-9.19L32 343.34a25 25 0 010-46.68l103.6-39.84a16.06 16.06 0 009.19-9.19L184.66 144a25 25 0 0146.68 0l39.84 103.6a16.06 16.06 0 009.19 9.19l103 39.63a25.49 25.49 0 0116.63 24.1 24.82 24.82 0 01-16 22.82l-103.6 39.84a16.06 16.06 0 00-9.19 9.19L231.34 496A24.84 24.84 0 01208 512zm66.85-254.84zM88 176a14.67 14.67 0 01-13.69-9.4l-16.86-43.84a7.28 7.28 0 00-4.21-4.21L9.4 101.69a14.67 14.67 0 010-27.38l43.84-16.86a7.31 7.31 0 004.21-4.21L74.16 9.79A15 15 0 0186.23.11a14.67 14.67 0 0115.46 9.29l16.86 43.84a7.31 7.31 0 004.21 4.21l43.84 16.86a14.67 14.67 0 010 27.38l-43.84 16.86a7.28 7.28 0 00-4.21 4.21l-16.86 43.84A14.67 14.67 0 0188 176zm312 80a16 16 0 01-14.93-10.26l-22.84-59.37a8 8 0 00-4.6-4.6l-59.37-22.84a16 16 0 010-29.86l59.37-22.84a8 8 0 004.6-4.6l22.67-58.95a16.45 16.45 0 0113.17-10.57 16 16 0 0116.86 10.15l22.84 59.37a8 8 0 004.6 4.6l59.37 22.84a16 16 0 010 29.86l-59.37 22.84a8 8 0 00-4.6 4.6l-22.84 59.37A16 16 0 01400 256z"
        />
        {gradientDefinition && (
          <defs>
            <linearGradient
              id={label}
              gradientUnits="userSpaceOnUse"
              gradientTransform={`rotate(${gradientDefinition.rotation})`}>
              {gradientDefinition.stopColors.map(({ stopColor, offset }, i) => (
                <stop key={i} offset={offset} stopColor={stopColor} />
              ))}
            </linearGradient>
          </defs>
        )}
      </svg>
    )}
  </GradientIcon>
);

export default IoSparklesGradient;
