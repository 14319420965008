import { GamePlatformSelection } from "@api/private/update/updateExperiencePackage";
import useFormManager from "@components/form/hooks/useFormManager";
import { useEffect } from "react";
import useUpdateExperiencePackageHandle from "./useUpdateExperiencePackageHandle";

const initialValues = {
  packageId: undefined as string | undefined,
  name: "",
  tagLine: "",
  extraDetails: "",
  duration: 30,
  tickets: 1,
  streamOption: false,
  price: 0,
  gamePlatformSelections: [] as GamePlatformSelection[],
  anyGame: false,
};

export type UpdateExperiencePackageValues = typeof initialValues;

const useExperiencePackageUpdate = (packageId?: string) => {
  const formManager = useFormManager(initialValues, "updateExperiencePackage");

  const { values, validations, onChange, onBlur, resetValidations, setValues } = formManager;

  const [handle, updateIsLoading] = useUpdateExperiencePackageHandle(formManager);

  useEffect(() => setValues(values => ({ ...values, packageId })), [packageId, setValues]);

  return {
    values,
    validations,
    onChange,
    onBlur,

    updateIsLoading,

    update: handle,
    resetValidations,
    setValues,
  };
};

export default useExperiencePackageUpdate;
