import { RefObject, useCallback, useEffect, useState } from "react";
import screenfull from "screenfull";

const useFullscreenElement = (ref?: RefObject<Element>) => {
  const [fullscreenElement, setFullscreenElement] = useState<Element>();

  const handleFullscreenChange = useCallback(() => {
    setFullscreenElement(screenfull.element);
  }, []);

  useEffect(() => {
    if (!screenfull.on) return;
    screenfull.on("change", handleFullscreenChange);
    return () => screenfull.off("change", handleFullscreenChange);
  }, [handleFullscreenChange]);

  const isFullscreen = ref?.current ? ref?.current === fullscreenElement : !!fullscreenElement;

  return { fullscreenElement, isFullscreen };
};

export default useFullscreenElement;
