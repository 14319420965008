import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createUserExtension from "../createUserExtension";

const useCreateUserExtension = (
  firstName: string,
  lastName: string,
  displayName: string,
  birthdate: string,
) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState(null);

  const send = useCallback(async () => {
    setLoading();
    try {
      await createUserExtension(firstName, lastName, displayName, birthdate);
      return setSuccess(null);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [firstName, lastName, displayName, birthdate, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateUserExtension;
