import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getCustomSessionCost, { CustomSessionCost } from "../getCustomSessionCost";

const useGetCustomSessionCost = (
  cigID?: string,
  duration?: number,
  tickets?: number,
  streamOption?: boolean,
  autoSend?: boolean,
) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<
    CustomSessionCost | undefined
  >(undefined);

  const send = useCallback(async () => {
    if (cigID == null || duration == null || tickets == null || streamOption == null)
      return reqState;
    setLoading();
    try {
      const customSessionCost = await getCustomSessionCost(cigID, duration, tickets, streamOption);
      setSuccess(customSessionCost);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [cigID, duration, tickets, streamOption, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [cigID, duration, tickets, streamOption, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetCustomSessionCost;
