import useGetGames from "@api/public/get/hooks/useGetGames";
import DataCheck from "@components/common/DataCheck";
import ScrollCarousel from "@components/common/ScrollCarousel";
import SlideOnIntersect from "@components/common/SlideOnIntersect";

import GameCard from "@components/sections/GameCard";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import BecomeTalentOldHeader from "../BecomeTalentOldHeader";

export default function Games() {
  const { breakpoints } = useGlobalContext();

  const { data: games, isLoading, error } = useGetGames(true);

  const cardWidth = breakpoints.sm ? 175 : 215;

  return (
    <section className="section games">
      <BecomeTalentOldHeader
        title={
          <>
            <span>Games</span> that we support
          </>
        }
        center
      />

      <SlideOnIntersect direction="right" timeout={500}>
        <div className="scroll-carousel-wrapper">
          <DataCheck error={error}>
            <ScrollCarousel>
              {isLoading
                ? new Array(20)
                    .fill(null)
                    .map((_, i) => <GameCard key={i} scaffold width={cardWidth} />)
                : games.map(({ id, tertiaryImageURL, statisticName, statisticValue }) => {
                    return (
                      <GameCard
                        key={id}
                        gameImg={tertiaryImageURL}
                        statisticName={statisticName}
                        statisticValue={statisticValue}
                        width={cardWidth}
                      />
                    );
                  })}
            </ScrollCarousel>
          </DataCheck>
        </div>
      </SlideOnIntersect>
    </section>
  );
}
