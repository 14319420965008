import React, { useCallback, useRef } from "react";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import ViewPort from "./view/ViewPort";
import MobileViewPort from "./view/MobileViewPort";
import useRoomContext from "./roomContext/hooks/useRoomContext";
import { canFullscreen, createErrorSnackbar, log } from "@lib/utils/generic";
import useIsLandscape from "@hooks/useIsLandscale";
import RoomLoadingModal from "./RoomLoadingModal";
import RoomRulesModal from "./RoomRulesModal";
import CircularBlur from "@components/shapes/CircularBlur";
import DataCheck from "@components/common/DataCheck";
import Button from "@components/form/Button";
import { IoReload } from "react-icons/io5";
import useIsJoinable from "./hooks/useIsJoinable";
import screenfull from "screenfull";
import useFullscreenElement from "@hooks/useFullscreenElement";
import Controls from "./view/Controls";
import Title from "./view/Title";
import PreRoomOverlay from "./overlays/PreRoomOverlay";
import PostRoomOverlay from "./overlays/PostRoomOverlay";
import ReportModal from "./ReportModal";
import RoomDrawer from "./usersDrawer/RoomDrawer";

export default function RoomInner() {
  const { breakpoints, pushSnackbar } = useGlobalContext();
  const {
    mainParticipant,
    activeParticipants,
    disconnected,
    sessionIsLoading,
    sessionError,
    setDrawerTab,
    retrySession,
    rerender,
    viewParticipant,
  } = useRoomContext();

  const ref = useRef<HTMLDivElement>(null);

  const { isFullscreen } = useFullscreenElement(ref);
  const isLandscape = useIsLandscape();
  const isMobilePortrait = breakpoints.md && !isLandscape;

  const isJoinable = useIsJoinable();

  let windowHeight = window.innerHeight;

  const goFullscreen = useCallback(async () => {
    const current = ref.current;
    if (!current || !canFullscreen()) return;

    try {
      if (!screenfull.isFullscreen) await screenfull.request(current);
      setDrawerTab(undefined);
    } catch (err: any) {
      log(err);
      pushSnackbar(createErrorSnackbar(err.message, 3000));
    }
  }, [pushSnackbar, setDrawerTab]);

  return (
    <div id="room-page">
      {(!isJoinable || disconnected) && (
        <div className="circular-blurs">
          <CircularBlur />
          <CircularBlur />
        </div>
      )}

      <DataCheck
        isLoading={sessionIsLoading}
        error={sessionError}
        errorContent={
          <Button
            variant="flat"
            color="white"
            size="small"
            endIcon={<IoReload />}
            onClick={retrySession}>
            Retry
          </Button>
        }
        loadingIndicator="spinner"
        color="white">
        {!isJoinable ? (
          <PreRoomOverlay rerender={rerender} />
        ) : disconnected ? (
          <PostRoomOverlay />
        ) : (
          <>
            <RoomLoadingModal />
            <RoomRulesModal />
            <div className="room-wrapper" style={{ height: windowHeight }}>
              {isMobilePortrait && !isFullscreen && <Title fixed />}
              <ViewPort
                showBranding
                participant={mainParticipant}
                noParticipants={activeParticipants.length <= 1}
                showControls={!breakpoints.md || isLandscape || isFullscreen}
                showSwitch={breakpoints.md && !isLandscape}
                mountAudio
                size={breakpoints.md && !isLandscape ? "small" : "large"}
                ref={ref}
                isFullscreen={isFullscreen}
                goFullscreen={goFullscreen}
                onClick={
                  breakpoints.md && !isFullscreen && !isLandscape
                    ? () => viewParticipant(mainParticipant?.identity)
                    : undefined
                }
              />
              {isMobilePortrait && !isFullscreen && <MobileViewPort />}
              {isMobilePortrait && !isFullscreen && (
                <Controls fixed isFullscreen={isFullscreen} goFullscreen={goFullscreen} />
              )}
            </div>
          </>
        )}

        <ReportModal />

        <RoomDrawer />
      </DataCheck>
    </div>
  );
}
