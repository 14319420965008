import Button, { ButtonProps } from "@components/form/Button";
import ExperienceCard, { defaultExperienceCardDetails } from "@components/sections/ExperienceCard";
import { SortDirection } from "@lib/enums/generic";
import { getLongestGame, padArray, sortMap } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useSelectExperienceContext from "./context/useSelectExperienceContext";
import ExperienceUpdateCard from "./ExperienceUpdateCard";
import { IoArrowBack, IoCalendar } from "react-icons/io5";
import useStripeIsSetup from "@hooks/session/useStripeIsSetup";
import NoAccessCover from "@components/common/NoAccessCover";
import useGetGames from "@api/public/get/hooks/useGetGames";
import DataCheck from "@components/common/DataCheck";
import ExperiencesHeader from "@pages/experiences/ExperiencesHeader";

export default function ExperiencesManageSelectDesktop() {
  const {
    customExperience,
    customExperienceError,
    customExperienceIsLoading,
    packageExperiences,
    packageExperiencesError,
    packageExperiencesIsLoading,
  } = useSelectExperienceContext();

  const { breakpoints, cigProfile } = useGlobalContext();

  const cardWidth = breakpoints.md ? 300 : 400;
  const buttonSize = breakpoints.md ? "medium" : "large";

  const cardProps = {
    width: cardWidth,
    isMobile: breakpoints.md,
    buttonSize: buttonSize as ButtonProps["size"],
    buttonText: "Edit",
    isCig: true,
  };

  const stripeIsSetup = useStripeIsSetup();
  const customIsSetup = !!customExperience;

  const { data: games, isLoading: gamesIsLoading } = useGetGames(true);

  return (
    <div id="experiences-manage-select-page" className="experiences-manage-page desktop">
      <ExperiencesHeader
        title="Experience bundles"
        subTitle="Set up your experience bundles for your fans to purchase."
        actions={
          <>
            <Button
              color="gray-3"
              textColor="black-1"
              size={breakpoints.md ? "medium" : "large"}
              justifyContent="center"
              startIcon={<IoArrowBack />}
              to={`/@${cigProfile?.displayName}`}>
              Profile
            </Button>
            <Button
              color="purple"
              textColor="white"
              size={breakpoints.md ? "medium" : "large"}
              justifyContent="center"
              startIcon={<IoCalendar />}
              to="/experiences/requests">
              Pending Requests
            </Button>
          </>
        }
      />

      <div className="cards">
        <DataCheck error={customExperienceError ?? packageExperiencesError}>
          {customExperienceIsLoading ? (
            <ExperienceCard isCustom {...cardProps} isLoading />
          ) : customExperience ? (
            <ExperienceCard
              isCustom
              price={customExperience.pricePerPeriodIncTax}
              currency={customExperience.currency}
              to="/experiences/update/custom"
              {...cardProps}
            />
          ) : (
            <ExperienceUpdateCard
              title="Custom"
              color="blue"
              to="/experiences/update/custom"
              width={cardWidth}
              buttonSize={buttonSize}
            />
          )}

          {padArray(packageExperiences, null, 3)
            .sort(sortMap("priceIncTax", SortDirection.Ascending))
            .map((packageExperience, i) => {
              const defaultCard = defaultExperienceCardDetails[i];

              if (packageExperiencesIsLoading)
                return (
                  <ExperienceCard
                    key={i}
                    color={defaultCard.color ?? "purple"}
                    {...cardProps}
                    isLoading
                  />
                );

              if (!packageExperience)
                return (
                  <ExperienceUpdateCard
                    key={i}
                    title={defaultCard.name}
                    color={defaultCard.color}
                    to="/experiences/update/package"
                    width={cardWidth}
                    buttonSize={buttonSize}
                    disabled={!customIsSetup}
                    disabledReason={
                      !customIsSetup &&
                      "You must setup your custom experience, before you can setup bundle experiences."
                    }
                  />
                );

              const {
                packageID,
                name,
                tagLine,
                sessionLength,
                tickets,
                streamOption,
                priceIncTax,
                currency,
                games: selectedGames,
                anyGame,
              } = packageExperience;

              const longestGame = anyGame ? undefined : getLongestGame(games, selectedGames);
              const img = anyGame
                ? undefined
                : longestGame?.primaryImageURL ?? selectedGames[0]?.game.primaryImageURL;

              return (
                <ExperienceCard
                  key={i}
                  name={name}
                  price={priceIncTax}
                  currency={currency}
                  tagLine={tagLine}
                  longestGame={longestGame}
                  img={img}
                  tickets={tickets}
                  livestreamed={streamOption}
                  duration={sessionLength * 60}
                  color={defaultCard.color ?? "purple"}
                  to={`/experiences/update/package/${packageID}`}
                  isLoading={gamesIsLoading}
                  {...cardProps}
                />
              );
            })}
          {!stripeIsSetup && (
            <NoAccessCover
              body="You must setup your Stripe account to manage your experiences"
              buttonProps={{ to: "/settings/statuses" }}
              buttonText="Check Statuses"
            />
          )}
        </DataCheck>
      </div>
    </div>
  );
}
