import Slide from "@components/transitions/Slide";
import useCombinedRefs from "@hooks/useCombinedRefs";
import useRelativeCoordsAndSize, { RelativePosition } from "@hooks/useRelativeCoordsAndSize";
import { CSSProps, UIColor } from "@lib/types/generic";
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  ReactNode,
} from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { createPortal } from "react-dom";

interface Props extends CSSProps {
  relativePosition?: RelativePosition;
  content?: ReactNode;
  timeout?: number;
  color?: UIColor;
  textColor?: UIColor;
  killTooltip?: boolean;
  noWrap?: boolean;
}

const HoverTooltip = forwardRef<HTMLElement, PropsWithChildren<Props>>((props, forwardedRef) => {
  const {
    children,
    relativePosition = "top",
    content = <></>,
    timeout = 300,
    color = "black-3",
    textColor = "white",
    killTooltip,
    noWrap,
    style,
    className = "",
    id,
  } = props;

  const rootRef = useRef<HTMLElement | null>(null);
  const ref = useCombinedRefs(rootRef, forwardedRef);

  const [active, setActive] = useState(false);

  const portalRoot = document.getElementById("portal-root");

  const { coords, reCalculate } = useRelativeCoordsAndSize(
    rootRef,
    relativePosition,
    "portal-fixed",
    !active,
  );

  useEffect(() => {
    if (active) reCalculate();
  }, [active, reCalculate]);

  useEffect(() => {
    if (killTooltip) setActive(false);
  }, [killTooltip, setActive]);

  if (killTooltip) return <>{children}</>;

  if (portalRoot == null) return null;

  const nodes = (
    <Slide in={active && !!coords} appear timeout={timeout} distance="short">
      <div
        className={`tooltip-base hover ${className}`}
        style={{
          ...style,
          ...(coords && { left: coords.x, top: coords.y }),
        }}
        id={id}>
        <div
          className={`tooltip ${
            noWrap ? "no-wrap" : ""
          } ${color} text-${textColor} ${relativePosition}`}>
          {content}
        </div>
      </div>
    </Slide>
  );

  return (
    <>
      {Children.map(
        children,
        child =>
          isValidElement(child) &&
          cloneElement(child as any, {
            ref,
            onMouseEnter: () => setActive(true),
            onMouseLeave: () => setActive(false),
          }),
      )}
      {createPortal(nodes, portalRoot)}
    </>
  );
});

export default HoverTooltip;
