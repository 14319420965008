import { api } from "@api/api";

export interface CreateClaimRes {
  message: string;
  claimID: string;
}

const createClaim = async (bookingID: string, reason: string, reasonClass: string) => {
  const { data } = await api.post<CreateClaimRes>("/createClaim", {
    bookingID,
    reason,
    reasonClass,
  });
  return data;
};

export default createClaim;
