import React from "react";
import GradientIcon, { GradientIconInnerProps, GradientIconProps } from "./GradientIcon";

const IoPersonGradient = (props: GradientIconProps) => (
  <GradientIcon {...props}>
    {({ gradientDefinition, label, ...cssProps }: GradientIconInnerProps) => (
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        {...cssProps}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={label ? `url(#${label})` : undefined}
          d="M256 256a112 112 0 10-112-112 112 112 0 00112 112zm0 32c-69.42 0-208 42.88-208 128v64h416v-64c0-85.12-138.58-128-208-128z"
        />
        {gradientDefinition && (
          <defs>
            <linearGradient
              id={label}
              gradientUnits="userSpaceOnUse"
              gradientTransform={`rotate(${gradientDefinition.rotation})`}>
              {gradientDefinition.stopColors.map(({ stopColor, offset }, i) => (
                <stop key={i} offset={offset} stopColor={stopColor} />
              ))}
            </linearGradient>
          </defs>
        )}
      </svg>
    )}
  </GradientIcon>
);

export default IoPersonGradient;
