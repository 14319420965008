import Avatar from "@components/common/Avatar";
import Pill from "@components/common/Pill";
import SectionBar from "@components/section/SectionBar";
import { CancellationStatus, Currency } from "@lib/enums/generic";
import { classNames, numberToCurrency } from "@lib/utils/generic";
import dayjs from "dayjs";
import React, { ReactNode } from "react";
import { IoClose } from "react-icons/io5";

interface Props {
  type?: "session" | "booking";
  avatarImg?: string;
  avatarIcon?: ReactNode;
  topText?: ReactNode;
  bottomText?: ReactNode;
  date?: string;
  price?: number;
  free?: boolean;
  currency?: Currency;
  cigName?: string;
  selected?: boolean;
  private?: boolean;

  id?: string | number;
  cancellation?: CancellationStatus | null;
}

export default function BookingItem(props: Props) {
  const {
    type,
    avatarImg,
    avatarIcon,
    topText,
    bottomText,
    date,
    price,
    free,
    currency,
    cigName,
    selected,
    private: privateSession,
    id,
    cancellation,
  } = props;

  return (
    <SectionBar
      className={classNames(
        selected && "selected",
        cancellation && "cancelled",
        privateSession && "private",
      )}
      to={cancellation ? undefined : `/bookings/${id}?type=${type}`}
      dynamicHeight>
      <Avatar
        size={56}
        src={avatarImg}
        fallbackOverride={avatarIcon}
        alt={topText?.toString()}
        variant="rounded"
      />

      <div className="text-wrapper">
        <div className="left-text">
          <p>{topText}</p>
          <p>{bottomText}</p>
        </div>
        <div className="right-text">
          <p>{date && dayjs(date).format("DD MMM YY HH:mm")}</p>
          <div className="pills">
            {privateSession && (
              <Pill size="extra-small" color="gray-3" textColor="black" rounded>
                PRIVATE
              </Pill>
            )}
            {free && !privateSession ? (
              <Pill size="extra-small" color="blue" textColor="white" rounded>
                FREE
              </Pill>
            ) : (
              <p>{price != null && price > 0 && currency && numberToCurrency(price, currency)}</p>
            )}
          </div>
        </div>
      </div>

      {cancellation && (
        <div className="cancelled-cover">
          <p className="cancelled-text">
            <IoClose />
            Cancelled by {cancellation === CancellationStatus.CigCancel && cigName}
            {cancellation === CancellationStatus.CustomerCancel && "You"}
          </p>
        </div>
      )}
    </SectionBar>
  );
}
