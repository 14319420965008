import { PropsWithChildren, useEffect } from "react";
import { IoArrowBackOutline, IoClose } from "react-icons/io5";
import { CSSProps } from "@lib/types/generic";
import PageCover from "./PageCover";
import { classNames } from "@lib/utils/generic";
import IconButton from "@components/form/IconButton";

interface Props extends CSSProps {
  position?: "fixed" | "absolute";
  side?: "right" | "left";
  type?: "temporary" | "permanent";
  width?: number | string;
  noPageCover?: boolean;
  noCloseButton?: boolean;

  drawerActive?: boolean;
  onClose?: () => void;
  drawerExpanded?: boolean;
  setDrawerExpanded?: (expanded: boolean) => void;

  onTransitionStart?: () => void;
  onTransitionFinish?: () => void;
}

export default function Drawer(props: PropsWithChildren<Props>) {
  const {
    position = "absolute",
    side = "left",
    type = "permanent",

    width,
    noPageCover,
    noCloseButton,

    drawerActive,
    drawerExpanded,

    className = "",
    id,
    style,
    children,

    onClose = () => {},
    setDrawerExpanded = () => {},

    onTransitionStart = () => {},
    onTransitionFinish = () => {},
  } = props;

  const isPermanent = type === "permanent";
  const isVisible = drawerActive || isPermanent;

  useEffect(() => {
    if (!drawerActive) return;

    onTransitionStart();
    const interval = setTimeout(onTransitionFinish, 300);
    return () => clearTimeout(interval);
    // eslint-disable-next-line
  }, [drawerActive]);
  // must omit onTransitionStart and onTransitionFinish to prevent loop

  useEffect(() => {
    if (!drawerExpanded) return;

    onTransitionStart();
    const interval = setTimeout(onTransitionFinish, 300);
    return () => clearTimeout(interval);
    // eslint-disable-next-line
  }, [drawerExpanded]);
  // must omit onTransitionStart and onTransitionFinish to prevent loop

  return (
    <div
      className={classNames(
        "drawer",
        position,
        side,
        isVisible ? "active" : "inactive",
        (!isVisible || !drawerExpanded) && "collapsed",
        className,
      )}
      id={id}
      style={{ ...style, width }}
      data-testid="drawer">
      {children}
      {!noCloseButton && !isPermanent && (
        <IconButton
          icon={<IoClose />}
          color="white"
          iconColor="white"
          variant="flat"
          size="medium"
          className="close-icon"
          onClick={() => onClose()}
        />
      )}
      {isPermanent && (
        <IconButton
          icon={<IoArrowBackOutline />}
          color="white"
          iconColor="black"
          rounded
          size="small"
          className={classNames("expand-icon", !drawerExpanded && "collapsed")}
          onClick={() => setDrawerExpanded(!drawerExpanded)}
        />
      )}
      {!noPageCover && !isPermanent && drawerActive && (
        <PageCover variant="light" onClose={() => onClose()} />
      )}
    </div>
  );
}
