import Checkbox from "@components/form/Checkbox";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Search from "@components/form/Search";
import { timeZoneOptions } from "@lib/constants/generic";
import React, { useState } from "react";
import { IoGlobe } from "react-icons/io5";
import ExperiencesManageAvailabilitySelector from "../../../ExperiencesManageAvailabilitySelector";
import ExperiencesManageLabel from "../../../ExperiencesManageLabel";
import useUpdateExperiencePackageContext from "../context/useUpdateExperiencePackageContext";

export default function Availability() {
  const { values, validations, onChange, onBlur } = useUpdateExperiencePackageContext();

  const [timezoneSearchValue, setTimezoneSearchValue] = useState("");

  return (
    <div className="form-section">
      <FormRow>
        <FormEntry
          label={
            <ExperiencesManageLabel
              label="1. Pick your times"
              subLabel="Choose what days to receive request for."
            />
          }
          validation={validations.timezoneName}
          relativePosition="top">
          <Checkbox
            size="medium"
            color="black-4"
            textColor="purple-5"
            checkmarkColor="gray-3"
            name="anyTime"
            label="Enable all times"
            value={values.anyTime}
            onChange={onChange}
            onBlur={onBlur}
            wrapperClassName="any-checkbox"
          />
          <Search
            size="large"
            color="black-3"
            textColor="gray-3"
            startIcon={<IoGlobe />}
            name="timezoneName"
            placeholder="Timezone"
            position="portal-absolute"
            rounded
            results={timeZoneOptions}
            value={values.timezoneName}
            disabled={values.anyTime}
            searchValue={timezoneSearchValue}
            onChange={({ value }) => setTimezoneSearchValue(value)}
            onSelect={onChange}
            autoFilter
            sortResults
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry validation={validations.requestAvailableTimes} relativePosition="top">
          <ExperiencesManageAvailabilitySelector
            name="requestAvailableTimes"
            value={values.requestAvailableTimes}
            disabled={values.anyTime}
            onChange={onChange}
          />
        </FormEntry>
      </FormRow>
    </div>
  );
}
