import Collapse from "@components/transitions/Collapse";
import { CSSProps, UIColor } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { forwardRef, PropsWithChildren, useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import DataCheck from "./DataCheck";
import Divider from "./Divider";

interface Props extends CSSProps {
  color?: UIColor;
  buttonTextColor?: UIColor;
  contentTextColor?: UIColor;
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  title?: string;

  isLoading?: boolean;
}

export type AccordionProps = Props;

const Accordion = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    color = "black",
    buttonTextColor = "white",
    contentTextColor = "gray-3",
    size = "medium",
    title,

    isLoading,

    children,

    className,

    ...cssProps
  } = props;

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(open => !open);

  return (
    <div
      className={classNames(
        "accordion",
        color,
        `${contentTextColor}-content-text`,
        `${buttonTextColor}-button-text`,
        size,
        className,
      )}
      ref={ref}
      {...cssProps}>
      <DataCheck isLoading={isLoading} loadingIndicator="gloss">
        <button className="accordion-button" onClick={toggle}>
          {title} <IoChevronDown />
        </button>
        <Collapse in={open}>
          <div className={classNames("content-wrapper")}>
            <div className="content-inner">
              <Divider orientation="vertical" color="gray-3" className="accordion-divider" />
              <div className={classNames("content")}>{children}</div>
            </div>
          </div>
        </Collapse>
      </DataCheck>
    </div>
  );
});

export default Accordion;
