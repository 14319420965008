import Avatar from "@components/common/Avatar";
import Rating from "@components/common/Rating";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { PropsWithChildren } from "react";

interface Props {
  avatarImg: string;
  displayName: string;
  tagLine: string;
}

const LandingTestimonial = (props: PropsWithChildren<Props>) => {
  const { avatarImg, displayName, tagLine, children } = props;

  const { breakpoints } = useGlobalContext();

  return (
    <div className="landing-testimonial">
      <div className="testimonial-inner">
        <div className="user">
          <Avatar
            src={avatarImg}
            alt={displayName}
            size={breakpoints.sm ? 70 : breakpoints.md ? 75 : 83}
          />
          <div className="user-details">
            <p className="display-name">{displayName}</p>
            <p className="tag-line">{tagLine}</p>
            <Rating size="extra-small" color="gold" value={5} />
          </div>
        </div>

        <p className="flow-text full-width">{children}</p>
      </div>
    </div>
  );
};

export default LandingTestimonial;
