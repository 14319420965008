import { classNames } from "@lib/utils/generic";
import DataCheck from "@components/common/DataCheck";
import BackgroundImage from "@components/common/BackgroundImage";

interface Props extends SharedProps {
  scaffold?: false;
  gameImg: string | null;
  statisticName: string | null;
  statisticValue: string | null;
}

interface ScaffoldProps extends SharedProps {
  scaffold: true;
}

interface SharedProps {
  width?: string | number;
}

export type CigCardProps = (Props | ScaffoldProps) & SharedProps;

const isScaffold = (props: any): props is ScaffoldProps => props.scaffold === true;

export default function GameCard(props: CigCardProps | ScaffoldProps) {
  const { width = 215 } = props;

  if (isScaffold(props)) {
    return (
      <div
        className={classNames("cig-card", "scaffold")}
        style={{
          minWidth: width,
          width: width,
          fontSize: width,
        }}>
        <DataCheck isLoading loadingIndicator="gloss" />
      </div>
    );
  } else {
    const { gameImg, statisticName, statisticValue } = props;

    return (
      <div
        className="game-card"
        style={{
          minWidth: width,
          width: width,
          fontSize: width,
        }}>
        <DataCheck imgs={[gameImg]} loadingIndicator="gloss">
          <BackgroundImage src={gameImg} opacity={0} />
          <div className="background-gradient" />

          <div className="stats-wrapper">
            <p className="stats-value">{statisticValue}</p>
            <p className="stats-name">{statisticName}</p>
          </div>
        </DataCheck>
      </div>
    );
  }
}
