import useCreateOnboardingLink from "@api/private/create/hooks/useCreateOnboardingLink";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createErrorSnackbar } from "@lib/utils/generic";
import { useCallback } from "react";

const useOnboarding = () => {
  const { pushSnackbar } = useGlobalContext();

  const { isLoading, error, send, data } = useCreateOnboardingLink();

  const onboard = useCallback(async () => {
    const { data, error } = await send();
    if (error) return pushSnackbar(createErrorSnackbar(error, 5000));
    const url = data?.link?.url;
    if (url) window.location.replace(url);
  }, [send, pushSnackbar]);

  return { isLoading, isRedirecting: !!data, error, onboard };
};

export default useOnboarding;
