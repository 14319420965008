import { classNames } from "@lib/utils/generic";
import { forwardRef, ReactNode } from "react";
import { CSSProps, UIColor } from "../../lib/types/generic";
import { FormElement } from "./Form";

interface Props extends CSSProps {
  color?: UIColor;
  textColor?: UIColor;
  thumbColor?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent";

  selectedColor?: UIColor;
  selectedTextColor?: UIColor;
  selectedThumbColor?: UIColor;
  selectedVariant?: "contained" | "outlined" | "flat" | "transparent";

  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  label?: ReactNode;
  name?: string;
  value?: boolean;
  disabled?: boolean;
  disableHover?: boolean;
  onChange?: (target: FormElement<boolean>) => void;
  onBlur?: (target: FormElement<boolean>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export type ToggleSwitchProps = Props;

const ToggleSwitch = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    color: defaultColor = "white",
    textColor: defaultTextColor = "white",
    thumbColor: defaultThumbColor = "black",
    variant: defaultVariant = "contained",

    selectedColor = defaultColor,
    selectedTextColor = defaultTextColor,
    selectedThumbColor = defaultThumbColor,
    selectedVariant = defaultVariant,

    size = "medium",
    label,
    name,
    value,
    disabled,
    disableHover,
    onChange = () => {},
    onBlur = () => {},
    style,
    id,
    className = "",

    ...events
  } = props;

  const color = value ? selectedColor : defaultColor;
  const textColor = value ? selectedTextColor : defaultTextColor;
  const thumbColor = value ? selectedThumbColor : defaultThumbColor;
  const variant = value ? selectedVariant : defaultVariant;

  return (
    <label
      className={classNames(
        "toggle-switch-wrapper",
        size,
        color,
        `${thumbColor}-thumb`,
        variant,
        label && "has-label",
      )}>
      {label && <div className={classNames("label", `${textColor}-text`)}>{label}</div>}
      <div
        ref={ref}
        className={classNames("toggle-switch", className)}
        id={id}
        style={style}
        {...events}>
        <input
          type="checkbox"
          name={name}
          checked={value}
          onChange={({ target }) =>
            onChange({ ...target, name: name ?? "", value: target.checked })
          }
          onBlur={({ target }) => onBlur({ ...target, name: name ?? "", value: target.checked })}
          disabled={disabled}
        />
        <span className={classNames("slider", disableHover && "hover-disabled")} />
      </div>
    </label>
  );
});

export default ToggleSwitch;
