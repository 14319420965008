import { api } from "@api/api";

export interface RequestAvailability {
  duration: number;
  availableBlocksOfTime: AvailabilityTimeBlock[];
}

export interface AvailabilityTimeBlock {
  start: string;
  end: string;
}

const getRequestAvailability = async (
  cigID: string,
  month: string,
  year: number,
  duration: number,
  packageID?: string,
) => {
  const { data } = await api.get<RequestAvailability>("/getCIGRequestSessionAvailability", {
    params: { cigID, month, year, duration, packageID },
  });
  return data;
};

export default getRequestAvailability;
