import Checkbox from "@components/form/Checkbox";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import { createInfoSnackbar } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import ExperiencesManageGameSelector from "../../../ExperiencesManageGameSelector";
import ExperiencesManageLabel from "../../../ExperiencesManageLabel";
import useUpdateExperiencePackageContext from "../context/useUpdateExperiencePackageContext";
import { getLongestGame } from "../hooks/useLongestGame";

export default function Games() {
  const { values, validations, onChange, onBlur, games, gamesIsLoading, gamesError } =
    useUpdateExperiencePackageContext();

  const { pushSnackbar } = useGlobalContext();

  return (
    <section className="form-section">
      <FormRow>
        <FormEntry
          label={
            <ExperiencesManageLabel
              label="Pick your games"
              subLabel="Select what games can be played with this bundle."
            />
          }
          validation={validations.gamePlatformSelections}
          relativePosition="top"
          className="game-platform-selections-entry">
          <Checkbox
            size="medium"
            color="black-4"
            textColor="purple-5"
            checkmarkColor="gray-3"
            name="anyGame"
            label="Enable all games"
            value={values.anyGame}
            onChange={onChange}
            onBlur={onBlur}
            wrapperClassName="any-checkbox"
          />
          <div className="game-selector-wrapper">
            <ExperiencesManageGameSelector
              name="gamePlatformSelections"
              value={values.gamePlatformSelections}
              games={games}
              disabled={values.anyGame}
              gamesIsLoading={gamesIsLoading}
              gamesError={gamesError}
              onChange={target => {
                onChange(target);

                const longestGame = getLongestGame(games, target.value);
                if (!longestGame?.estimatedMaxLengthMins) return;
                if (values.duration >= longestGame.estimatedMaxLengthMins) return;

                onChange({ name: "duration", value: longestGame.estimatedMaxLengthMins });
                pushSnackbar(
                  createInfoSnackbar("Updated session length to match game selection", 5000),
                );
              }}
            />
          </div>
        </FormEntry>
      </FormRow>
    </section>
  );
}
