import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useUserReFetch from "./useGetUserReFetch";

const useStripeIsSetup = () => {
  const { userExtension } = useGlobalContext();

  useUserReFetch();

  return (
    !!userExtension &&
    !userExtension?.stripeOnboardingPending &&
    !userExtension?.stripeRequiresInformationUpdate
  );
};

export default useStripeIsSetup;
