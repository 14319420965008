import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import { FederatedSignInOptions } from "@aws-amplify/auth/lib/types";
import { Snackbar } from "../lib/types/generic";
import { UserExtension } from "@api/private/get/getUserExtension";
import { CIGProfile } from "@api/public/get/getCigProfile";
import StorageItem from "@lib/utils/StorageItem";
import useSnackbars from "./hooks/useSnackbars";
import useImagesLoading from "./hooks/useImagesLoading";
import useBreakpoints, { Breakpoints } from "./hooks/useBreakpoints";
import useAuth from "./hooks/useAuth";
import useCustomerExpereinceRequests from "@src/globalContext/hooks/useCustomerExpereinceRequests";
import useCigExperienceRequests from "@src/globalContext/hooks/useCigExperienceRequests";
import { ExperienceRequest } from "@api/private/get/getExperienceRequests";
import { CigPendingExperienceRequest } from "@api/private/get/getCigPendingExperienceRequests";
import { CurrentReqState } from "@hooks/useRequestState";
import { AuthState, CigFlowState, SortDirection } from "@lib/enums/generic";
import useCigFlowState from "./hooks/useCigFlowState";
import { sortMap } from "@lib/utils/generic";
import changelog from "@lib/constants/changelog.json";
import useAuthModal, { AuthModalDetails, SetAuthModal } from "./hooks/useAuthModal";

interface GlobalContextType {
  maintenanceMode: boolean;
  setMaintenanceMode: Dispatch<SetStateAction<boolean>>;

  loading: boolean;
  setLoading: (loading: boolean) => void;
  breakpoints: Breakpoints;
  setBreakpoints: (breakpoints: Breakpoints) => void;
  drawerActive: boolean;
  setDrawerActive: (drawerActive: boolean) => void;
  drawerExpanded: boolean;
  setDrawerExpanded: (drawerExpanded: boolean) => void;
  headerActive: boolean;
  setHeaderActive: (headerActive: boolean) => void;
  user: any;
  setUser: (user: any) => void;
  getUser: (disableLoading?: boolean) => Promise<void>;
  userExtension: UserExtension | null;
  setUserExtension: (userExtension: UserExtension) => void;
  cigProfile: CIGProfile | null;
  setCigProfile: (cigProfile: CIGProfile) => void;
  initialLoading: boolean;
  initialError: string | null;

  authState: AuthState;
  setAuthState: Dispatch<SetStateAction<AuthState>>;

  cigFlowState: CigFlowState;
  cigFlowStateIsLoading: boolean;
  cigFlowStateError?: string;
  setCigFlowState: (cigFlowState: CigFlowState) => void;
  progressCigFlowState: (expectedCurrentState: CigFlowState) => void;

  signIn: (options: FederatedSignInOptions) => void;
  signOut: () => void;

  snackbars: Snackbar[];
  setSnackbars: (snackbars: Snackbar[]) => void;
  pushSnackbar: (snackbar: Snackbar) => void;
  spliceSnackbar: (snackbar: Snackbar) => void;

  stickyBar: ReactNode;
  setStickyBar: Dispatch<SetStateAction<ReactNode>>;

  imagesLoading: string[];
  setImagesLoading: (imagesLoading: string[]) => void;
  pushImageLoading: (image: string) => void;
  spliceImageLoading: (image: string) => void;

  customerExperienceRequests: ExperienceRequest[];
  customerPendingExperienceRequests: ExperienceRequest[];
  customerExperienceRequestsIsLoading: boolean;
  customerExperienceRequestsError?: string;
  getCustomerExperienceRequests: () => Promise<CurrentReqState<ExperienceRequest[]>>;

  cigExperienceRequests: CigPendingExperienceRequest[];
  cigPendingExperienceRequests: CigPendingExperienceRequest[];
  cigExperienceRequestsIsLoading: boolean;
  cigExperienceRequestsError?: string;
  getCigExperienceRequests: () => Promise<
    CurrentReqState<CigPendingExperienceRequest[]> | undefined
  >;

  getAllExperienceRequests: () => Promise<void>;

  descendingChangelog: { version: string; date: string; changes: string[] }[];

  authModal: AuthModalDetails;
  setAuthModal: SetAuthModal;
}

export const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType);

export default function GlobalContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const storeDrawerExpandedItem = new StorageItem("drawerExpanded").get() ?? "false";
  const storeDrawerExpanded = (storeDrawerExpandedItem.data ?? "false") === "true";

  const descendingChangelog = Object.entries(changelog)
    .map(([version, { date, changes }]) => ({
      version,
      date,
      changes,
    }))
    .sort(sortMap("version", SortDirection.Descending));

  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [drawerActive, setDrawerActive] = useState(true);
  const [drawerExpanded, setDrawerExpanded] = useState(storeDrawerExpanded);
  const [headerActive, setHeaderActive] = useState(false);
  const [stickyBar, setStickyBar] = useState<ReactNode>(null);

  const { snackbars, setSnackbars, spliceSnackbar, pushSnackbar } = useSnackbars();
  const { imagesLoading, setImagesLoading, spliceImageLoading, pushImageLoading } =
    useImagesLoading();
  const { breakpoints, setBreakpoints } = useBreakpoints();

  const { authModal, setAuthModal } = useAuthModal();

  const {
    authState,
    setAuthState,
    user,
    setUser,
    getUser,
    userExtension,
    setUserExtension,
    cigProfile,
    setCigProfile,
    signIn,
    signOut,
    initialLoading,
    initialError,
  } = useAuth(authModal, setAuthModal);

  const {
    data: cigFlowState,
    isLoading: cigFlowStateIsLoading,
    error: cigFlowStateError,
    set: setCigFlowState,
    progressCigFlowState,
  } = useCigFlowState(cigProfile);

  const {
    data: customerExperienceRequests,
    pendingData: customerPendingExperienceRequests,
    isLoading: customerExperienceRequestsIsLoading,
    error: customerExperienceRequestsError,
    send: getCustomerExperienceRequests,
  } = useCustomerExpereinceRequests();

  const {
    data: cigExperienceRequests,
    pendingData: cigPendingExperienceRequests,
    isLoading: cigExperienceRequestsIsLoading,
    error: cigExperienceRequestsError,
    send: getCigExperienceRequests,
  } = useCigExperienceRequests(!!cigProfile);

  return (
    <GlobalContext.Provider
      value={{
        maintenanceMode,
        setMaintenanceMode,

        loading,
        setLoading,
        breakpoints,
        setBreakpoints,
        drawerActive,
        setDrawerActive,
        drawerExpanded,
        setDrawerExpanded,
        headerActive,
        setHeaderActive,
        user,
        setUser,
        getUser,
        userExtension,
        setUserExtension,
        cigProfile,
        setCigProfile,
        signIn,
        signOut,
        initialLoading,
        initialError,

        authState,
        setAuthState,

        cigFlowState,
        cigFlowStateIsLoading,
        cigFlowStateError,
        setCigFlowState,
        progressCigFlowState,

        snackbars,
        setSnackbars,
        pushSnackbar,
        spliceSnackbar,

        stickyBar,
        setStickyBar,

        imagesLoading,
        setImagesLoading,
        pushImageLoading,
        spliceImageLoading,

        customerExperienceRequests,
        customerPendingExperienceRequests,
        customerExperienceRequestsIsLoading,
        customerExperienceRequestsError,
        getCustomerExperienceRequests,

        cigExperienceRequests,
        cigPendingExperienceRequests,
        cigExperienceRequestsIsLoading,
        cigExperienceRequestsError,
        getCigExperienceRequests,

        getAllExperienceRequests: async () => {
          await getCustomerExperienceRequests();
          await getCigExperienceRequests();
        },

        descendingChangelog,

        authModal,
        setAuthModal,
      }}>
      {children}
    </GlobalContext.Provider>
  );
}
