import { CigCustomExperience } from "@api/public/get/getCigCustomExperience";
import { CigPackageExperience } from "@api/public/get/getCigPackageExperiences";
import { CIGProfile } from "@api/public/get/getCigProfile";
import { CIGReview } from "@api/public/get/getCigReviews";
import useGetCigCustomExperience from "@api/public/get/hooks/useGetCigCustomExperience";
import useGetCigNextAvailability from "@api/public/get/hooks/useGetCigNextAvailability";
import useGetCigPackageExperiences from "@api/public/get/hooks/useGetCigPackageExperiences";
import useGetCigReviews from "@api/public/get/hooks/useGetCigReviews";
import DataCheck from "@components/common/DataCheck";
import { FormValidations, OnFormBlur, OnFormChange } from "@components/form/hooks/useFormManager";
import useUserReFetch from "@hooks/session/useGetUserReFetch";
import useQuery, { getQuery } from "@hooks/useQuery";
import { CurrentReqState } from "@hooks/useRequestState";
import useUserIsAdmin from "@hooks/useUserIsAdmin";
import { Session } from "@lib/types/session";
import { createParams } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs, { Dayjs } from "dayjs";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import useCigProfileEdit, { CIGProfileValues } from "./hooks/useCigProfileEdit";

interface CIGProfileContextType {
  values: CIGProfileValues;
  validations: FormValidations<CIGProfileValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;

  profilePictureFile: File | null;
  setProfilePictureFile: Dispatch<SetStateAction<File | null>>;

  bannerPictureFile: File | null;
  setBannerPictureFile: Dispatch<SetStateAction<File | null>>;

  cigProfile: CIGProfile | null;
  getIsLoading: boolean;
  getError?: string;

  customExperience: CigCustomExperience | null;
  customExperienceIsLoading: boolean;
  customExperienceError?: string;

  packageExperiences: CigPackageExperience[];
  packageExperiencesIsLoading: boolean;
  packageExperiencesError?: string;

  handleSave: () => Promise<void>;
  resetValues: () => void;
  saveIsLoading: boolean;
  saveError?: string;

  changesMade: boolean;

  upcomingSessions: Session[];
  upcomingSessionsIsLoading: boolean;
  upcomingSessionsError?: string;
  upcomingSessionsCanGoNext: boolean;
  getUpcomingSessions: (currNextToken?: string | undefined) => Promise<CurrentReqState<Session[]>>;
  getNextUpcomingSessions: () => Promise<CurrentReqState<Session[]> | undefined>;

  reviews: CIGReview[];
  reviewsIsLoading: boolean;
  reviewsError?: string;

  sessionId: string | null;
  periodDate: Dayjs;

  setSessionId: (sessionId: string | null) => void;
  setPeriodDate: (periodDate: Dayjs | null) => void;

  calendarOpen: boolean;
  setCalendarOpen: Dispatch<SetStateAction<boolean>>;
  openFirstSession: () => void;

  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  isOwnProfile: boolean;
}

export const CIGProfileContext = createContext<CIGProfileContextType>({} as CIGProfileContextType);

export default function CIGProfileContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const { userExtension, breakpoints, setHeaderActive } = useGlobalContext();

  const history = useHistory();

  const { cigId, cigDisplayName } = useParams<Params>();
  const action = useQuery<"calendar" | "edit" | "none">("action");

  const periodDateQuery = useQuery<string>("periodDate");
  const sessionId = useQuery<string>("sessionId");

  const periodDate = dayjs(
    periodDateQuery ? decodeURIComponent(periodDateQuery) : undefined,
  ).startOf(breakpoints.md ? "day" : "isoWeek");

  const userIsAdmin = useUserIsAdmin();

  const [calendarOpen, setCalendarOpen] = useState(sessionId != null);

  useUserReFetch();

  const {
    values,
    validations,
    onChange,
    onBlur,

    profilePictureFile,
    setProfilePictureFile,

    bannerPictureFile,
    setBannerPictureFile,

    cigProfile,
    getIsLoading,
    getError,

    handleSave,
    resetValues,
    saveIsLoading,
    saveError,

    editMode,
    setEditMode,
    changesMade,
  } = useCigProfileEdit(cigId, cigDisplayName);

  const setSessionId = (newSessionId: string | null) => {
    if (sessionId === newSessionId) return;
    const url = `/@${cigProfile?.displayName}${createParams(
      { sessionId: newSessionId },
      { periodDate: getQuery("periodDate", window.location) },
    )}`;
    history.push(url);
  };

  const setPeriodDate = (newPeriodDate: Dayjs | null) => {
    if (periodDate.isSame(newPeriodDate)) return;
    const url = `/@${cigProfile?.displayName}${createParams(
      { sessionId: getQuery("sessionId", window.location) },
      { periodDate: newPeriodDate?.toISOString() },
    )}`;
    history.push(url);
  };

  const {
    data: upcomingSessions,
    isLoading: upcomingSessionsIsLoading,
    error: upcomingSessionsError,
    nextToken: upcomingSessionsNextToken,
    send: getUpcomingSessions,
    next: getNextUpcomingSessions,
  } = useGetCigNextAvailability(cigProfile?.id, userExtension?.id, true);

  const {
    data: reviews,
    isLoading: reviewsIsLoading,
    error: reviewsError,
  } = useGetCigReviews(cigProfile?.id, true);

  const isOwnProfile = cigProfile?.id != null && userExtension?.cigID === cigProfile?.id;

  const openFirstSession = () => {
    const { id } = upcomingSessions[0] ?? {};
    if (id) setSessionId(id);
    setCalendarOpen(true);
  };

  const {
    data: customExperience,
    isLoading: customExperienceIsLoading,
    error: customExperienceError,
  } = useGetCigCustomExperience(cigProfile?.id, true);
  const {
    data: packageExperiences,
    isLoading: packageExperiencesIsLoading,
    error: packageExperiencesError,
  } = useGetCigPackageExperiences(cigProfile?.id, true);

  useEffect(() => {
    switch (action) {
      case "calendar": {
        setCalendarOpen(true);
        break;
      }
      case "edit": {
        setEditMode(true);
        break;
      }
      case "none":
        setCalendarOpen(false);
        setEditMode(false);
        break;
    }
    // eslint-disable-next-line
  }, [action]);
  // Must omit setEditMode to prevent unwanted execution

  useEffect(
    () => setHeaderActive(!editMode || breakpoints.sm),
    [editMode, setHeaderActive, breakpoints.sm],
  );

  if (!userIsAdmin && !isOwnProfile && !cigProfile?.accepted && !getIsLoading)
    return <DataCheck error={`${cigProfile?.displayName}'s profile is not publicly visable`} />;

  return (
    <CIGProfileContext.Provider
      value={{
        values,
        validations,
        onChange,
        onBlur,

        profilePictureFile,
        setProfilePictureFile,

        bannerPictureFile,
        setBannerPictureFile,

        cigProfile,
        getIsLoading,
        getError,

        customExperience,
        customExperienceIsLoading,
        customExperienceError,

        packageExperiences,
        packageExperiencesIsLoading,
        packageExperiencesError,

        handleSave,
        resetValues,
        saveIsLoading,
        saveError,

        editMode,
        setEditMode,
        changesMade,

        upcomingSessions,
        upcomingSessionsIsLoading,
        upcomingSessionsError,
        upcomingSessionsCanGoNext: upcomingSessionsNextToken != null,
        getUpcomingSessions,
        getNextUpcomingSessions,

        reviews,
        reviewsIsLoading,
        reviewsError,

        sessionId,
        periodDate,

        setSessionId,
        setPeriodDate,

        calendarOpen,
        setCalendarOpen,
        openFirstSession,

        isOwnProfile,
      }}>
      {children}
    </CIGProfileContext.Provider>
  );
}

interface Params {
  cigId?: string;
  cigDisplayName?: string;
}
