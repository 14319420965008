import Modal from "@components/modals/Modal";
import FlankSignUpInner from "./FlankSignUpInner";
import FlankSignUpContextProvider from "./context/FlankSignUpContextProvider";

interface Props {
  active: boolean;
  onClose: () => void;
}

const FlankSignUpModal = (props: Props) => {
  const { active, onClose } = props;

  return (
    <Modal
      active={active}
      onClose={onClose}
      onlyCloseButton
      id="flank-sign-up-modal"
      className="auth-modal">
      <FlankSignUpContextProvider onClose={onClose}>
        <FlankSignUpInner />
      </FlankSignUpContextProvider>
    </Modal>
  );
};

export default FlankSignUpModal;
