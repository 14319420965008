import SlideOnIntersect from "@components/common/SlideOnIntersect";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useOnMountTimeout from "@hooks/useOnMountTimeout";

import Clip from "@components/common/Clip";
import useBannerImages from "./hooks/useBannerImages";
import { Children, cloneElement, isValidElement, ReactElement, ReactNode } from "react";
import BackgroundImage from "@components/common/BackgroundImage";
import { classNames } from "@lib/utils/generic";

interface Props {
  title: ReactNode;
  subTitle?: ReactNode;

  buttons: ReactElement;

  clipSrc?: string;
  imgSrc?: string;
}

export default function TopSplash(props: Props) {
  const { title, subTitle, buttons, clipSrc, imgSrc } = props;

  const { imageColumns, imageWidth, imageHeight, imageGap } = useBannerImages();

  const { breakpoints } = useGlobalContext();

  const buttonSize = breakpoints.md ? "medium" : !breakpoints.sl ? "extra-large" : "large";

  const videoCanLoad = useOnMountTimeout(800);

  return (
    <section className="section top-splash">
      <div className="layer columns" style={{ gap: imageGap, top: `-${imageHeight / 2}px` }}>
        {imageColumns.map((column, i) => (
          <div key={i} className="column" style={{ gap: imageGap }}>
            {column.map((src, ii) => (
              <img key={ii} src={src} alt="Banner" style={{ width: imageWidth }} />
            ))}
          </div>
        ))}
      </div>
      <div className="layer opacity" />
      <div className="layer gradient" />

      <SlideOnIntersect direction="right">
        <div className="cta">
          <h1 className="title">{title}</h1>
          {subTitle && <h2 className="sub-title">{subTitle}</h2>}
          <div className="button-row">
            {Children.map(
              buttons.props.children,
              child =>
                isValidElement(child) &&
                cloneElement(child, { size: buttonSize, justifyContent: "center" } as any),
            )}
          </div>
        </div>
      </SlideOnIntersect>
      <SlideOnIntersect direction="left">
        <div className={classNames("media", imgSrc && "is-img", clipSrc && "is-video")}>
          {imgSrc ? (
            <div className="image-wrapper">
              <BackgroundImage src={imgSrc} opacity={0} />
            </div>
          ) : (
            <div className="video-wrapper">
              {videoCanLoad && (
                <Clip src={clipSrc} autoPlay={false}>
                  <div></div>
                </Clip>
              )}
            </div>
          )}
        </div>
      </SlideOnIntersect>
    </section>
  );
}
