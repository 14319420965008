import React, { ReactElement, ReactNode } from "react";

interface Props {
  icon?: ReactElement;
  topText?: ReactNode;
  bottomText?: ReactNode;
}

export default function EventMessage(props: Props) {
  const { topText, bottomText, icon } = props;

  return (
    <div className="message event">
      {icon}
      <div className="text">
        <p>{topText}</p>
        <p>{bottomText}</p>
      </div>
    </div>
  );
}
