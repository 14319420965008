import Pill from "@components/common/Pill";

import { UIColor } from "@lib/types/generic";
import Section from "@components/section/Section";
import dayjs from "dayjs";
import React from "react";
import SectionBar from "@components/section/SectionBar";
import { claimReasonNames, claimStateColors, claimStateNames } from "@lib/constants/generic";
import { Claim } from "@api/private/get/getCustomerClaims";
import useGetBooking from "@api/private/get/hooks/useGetBooking";
import { useHistory } from "react-router-dom";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

interface Props {
  claim?: Claim;
}

export default function ClaimDetails(props: Props) {
  const { claim } = props;

  const { breakpoints } = useGlobalContext();

  const history = useHistory();

  const { bookingID, reason, state, reasonClass, createdAt } = claim ?? {};

  const { data: myBooking, isLoading, error } = useGetBooking({ bookingID }, true);

  const { session } = myBooking ?? {};
  const { game, cig, from } = session ?? {};

  const statusColor = (state ? claimStateColors[state] : "black") as UIColor;
  const statusName = state ? claimStateNames[state] : "";
  const reasonName = reasonClass ? claimReasonNames[reasonClass] : "";

  return (
    <Section
      display="flex"
      dataCheckProps={{ isLoading, error, loadingIndicator: "spinner" }}
      title={
        !isLoading && cig?.displayName && from ? (
          <>
            Claim - {cig?.displayName} - {dayjs(from).format("DD MMM YY | HH:mm")}{" "}
            {state && (
              <Pill color={statusColor} textColor="white" variant="contained" size="small" rounded>
                {statusName}
              </Pill>
            )}
          </>
        ) : (
          "Claim"
        )
      }
      onBack={() => history.push("/settings/support")}>
      <SectionBar canWrap={breakpoints.sm}>
        <div className="pair">
          <label>Reason</label>
          <p>{reasonName}</p>
        </div>
        <div className="pair">
          <label>Session</label>
          {cig?.displayName && from && game?.shortName && (
            <p>{`${cig?.displayName} - ${dayjs(from).format("DD MMM YY HH:mm")} - ${
              game?.shortName
            }`}</p>
          )}
        </div>
        <div className="pair">
          <label>Time &amp; Date</label>
          {from && <p>{dayjs(createdAt).format("DD MMM YY | HH:mm")}</p>}
        </div>
      </SectionBar>
      <SectionBar dynamicHeight>
        <div className="pair">
          <label>Claim Details</label>
          <p className="no-clamp">{reason}</p>
        </div>
      </SectionBar>
    </Section>
  );
}
