import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useGetHome from "@api/public/get/hooks/useGetHome";
import Footer from "@components/layout/Footer";
import TopSplash from "@components/splash/sections/TopSplash";
import Cta1 from "./sections/Cta1";
import Cta2 from "./sections/Cta2";
import Button from "@components/form/Button";
import { IoArrowForward } from "react-icons/io5";
import HomeSection from "./HomeSection";
import clip from "@videos/home/clip.mp4";
import FAQs from "./sections/FAQs";
import HowItWorks from "./sections/HowItWorks";
import FanTestimonials from "./sections/FanTestimonials";

export default function Home() {
  const { userExtension } = useGlobalContext();

  const { data: sections, error, isLoading } = useGetHome(userExtension?.id, true);

  return (
    <div id="home-page" className="splash">
      <TopSplash
        title={<>Create unforgettable moments, together</>}
        subTitle={
          <>Book a gaming session with your favourite streamers, celebrities, and influencers!</>
        }
        buttons={
          <>
            <Button color="purple" textColor="white" href="#streamers" endIcon={<IoArrowForward />}>
              View streamers
            </Button>
            <Button color="gray-1" textColor="white" href="#how-it-works">
              How does it work?
            </Button>
          </>
        }
        clipSrc={`${clip}#t=0.001`}
      />
      <FanTestimonials />
      <HomeSection section={sections[0]} isLoading={isLoading} error={error} />

      <Cta1 />

      <HowItWorks />

      <HomeSection section={sections[1]} isLoading={isLoading} error={error} />

      <HomeSection section={sections[2]} isLoading={isLoading} error={error} />

      <Cta2 />

      {sections.length > 3 &&
        sections
          .slice(3)
          .map(section => <HomeSection section={section} isLoading={isLoading} error={error} />)}

      <FAQs />

      <Footer />
    </div>
  );
}
