import { cigPlatformUrls, fakeCigCardData } from "@lib/constants/generic";
import { Currency, SnackbarType, SortDirection, CIGPlatform } from "@lib/enums/generic";
import { GestureEvents, Snackbar, UIColor } from "@lib/types/generic";
import dayjs, { Dayjs } from "dayjs";
import React, { ComponentProps, JSXElementConstructor, ReactElement, ReactNode } from "react";
import { isDev } from "../../config/config";
import { settings } from "../../settings";
import screenfull from "screenfull";
import { darkColors, lightColors } from "@lib/constants/colors";
import { SubCategories } from "@api/public/get/getSubCategories";
import { Option } from "@components/form/Options";
import { Session } from "@lib/types/session";
import { CigCardProps } from "@components/sections/CigCard";
import tzToCountry from "@lib/constants/tzToCountry";
import { Compatibility, GamePartial } from "@api/public/get/getCompatibility";
import { Game } from "@api/public/get/getGames";

export function getRootFromPath(pathname: string) {
  return `/${pathname.split("?")[0].split("/")[1]}`;
}

export function pathMatch(path: string, pathPattern: string, exact?: boolean) {
  const pathSegments = path.split("/");
  const pathPatternSegments = pathPattern.replace(/(:[\d\w-]*)/g, "*").split("/");

  const pathSegmentsLength = pathSegments.length;
  const pathPatternSegmentsLength = pathPatternSegments.length;
  const length = Math.max(pathSegmentsLength, pathPatternSegmentsLength);

  if (exact && pathSegmentsLength !== pathPatternSegmentsLength) return false;

  for (let i = 0; i < length; i++) {
    const pathSegment = pathSegments[i];
    const pathPatternSegment = pathPatternSegments[i];

    const regex = new RegExp(pathPatternSegment.replace(/\*/g, ".*"));

    if (!regex.test(pathSegment)) return false;
  }

  return true;
}

export const pathMatchSome = (path: string, pathPatterns: string[], exact?: boolean) =>
  pathPatterns.some(pathPattern => pathMatch(path, pathPattern, exact));

function findPathIn(pathname: string, paths: string[]) {
  return paths.find(path => pathMatch(pathname, path));
}

export function pathHasLayout(pathname: string) {
  return findPathIn(pathname, settings.pathsWithoutLayout) == null;
}

export function pathHasNav(pathname: string) {
  return findPathIn(pathname, settings.pathsWithoutNav) == null && pathHasLayout(pathname);
}

export function pathHasHeader(pathname: string) {
  return findPathIn(pathname, settings.pathsWithoutHeader) == null && pathHasLayout(pathname);
}

export function pathHasSearch(pathname: string) {
  return findPathIn(pathname, settings.pathsWithoutSearch) == null && pathHasLayout(pathname);
}

export function rangeAsArray(start: number, end: number) {
  const array = [] as number[];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
}

export function daysInMonth(date: Dayjs, minDate: Dayjs, maxDate: Dayjs) {
  let days = date.daysInMonth();

  if (date.month() === minDate.month() && date.year() === minDate.year()) {
    days = minDate.daysInMonth() - minDate.date();
  }

  if (date.month() === maxDate.month() && date.year() === maxDate.year()) {
    days = maxDate.date();
  }

  return days;
}

export function monthsInYear(date: Dayjs, minDate: Dayjs, maxDate: Dayjs) {
  let months = 12;

  if (date.year() === minDate.year()) {
    months = 11 - minDate.month() + 1;
  }

  if (date.year() === maxDate.year()) {
    months = maxDate.month() + 1;
  }

  return months;
}

export function nodeIsElement(node: any): node is ReactElement {
  if (node == null) return false;
  if (typeof node !== "object") return false;
  return node.type != null;
}

export function elementInstanceOf<E extends JSXElementConstructor<any>>(
  element: ReactElement,
  ofElement: E,
): element is ReactElement<ComponentProps<E>> {
  return element.type === ofElement;
}

export function elementIsTag<E extends HTMLElement>(
  element: HTMLElement,
  tag: string,
): element is E {
  if (element == null) return false;
  return element.tagName === tag.toUpperCase();
}

export function enumContains<T>(enumType: any, value: T): value is T {
  return Object.values(enumType).includes(value);
}

export const parseTime = (timeElapsed: number, includeDays?: boolean) => {
  const isNegative = timeElapsed < 0;
  if (isNegative) timeElapsed = Math.abs(timeElapsed);
  if (timeElapsed <= 0) timeElapsed = 0;
  let days = 0;
  if (includeDays) {
    days = Math.floor(timeElapsed / 86400);
    timeElapsed -= days * 86400;
  }
  const hours = Math.floor(timeElapsed / 3600);
  timeElapsed -= hours * 3600;
  const minutes = Math.floor(timeElapsed / 60);
  timeElapsed -= minutes * 60;
  const seconds = timeElapsed;

  const padNumber = (number: number) => {
    return number.toString().padStart(2, "0");
  };

  return {
    days,
    hours,
    minutes,
    seconds,
    daysPadded: padNumber(days),
    hoursPadded: padNumber(hours),
    minutesPadded: padNumber(minutes),
    secondsPadded: padNumber(seconds),
    isNegative,
  };
};

export const log = (...text: any[]) => {
  if (isDev) console.log("[Development]", ...text);
};

export const sleep = (duration: number) => new Promise(resolve => setTimeout(resolve, duration));

export const takeRandom = <V>(array: V[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const takeRandomReduce = <V>(array: V[]) => {
  const index = Math.floor(Math.random() * array.length);
  const item = array[index];
  array.splice(index, 1);
  return item;
};

export const randomise = <V>(array: V[]) => {
  const copiedArray = [...array];
  const newArray = [];

  // eslint-disable-next-line
  for (const _ of array) {
    newArray.push(takeRandomReduce(copiedArray));
  }
  return newArray;
};

export const generateCards = (amount: number) => {
  const cards: CigCardProps[] = [];
  for (let i = 0; i < amount; i++) {
    cards.push(takeRandom(fakeCigCardData));
  }
  return cards;
};

export const isEmpty = (value: any) => {
  if (value == null) return true;
  if (value.length != null) return value.length === 0;
  if (typeof value === "object") return Object.values(value).length === 0;

  return true;
};

export const isArray = <V>(value: any): value is Array<V> => {
  return Array.isArray(value);
};

export const arraysEqual = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) return false;
  for (const i in arr1) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const buildCigSocialUrl = (platform: CIGPlatform, handle: string) =>
  `${cigPlatformUrls[platform]}${handle}`;

export const preventAndStop = (event: React.MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
  return event;
};

export const isTouchEvent = (event: any): event is TouchEvent | React.TouchEvent =>
  event.targetTouches != null;

export const getCoordsFromGestureEvent = (event: GestureEvents, relativeToWindow?: boolean) => {
  const coords = { x: 0, y: 0 };

  if (isTouchEvent(event)) {
    const touch = event.targetTouches.item(0);
    if (touch == null) return null;

    const { pageX, pageY } = touch;
    coords.x = pageX;
    coords.y = pageY;
  } else {
    const { pageX, pageY } = event;
    coords.x = pageX;
    coords.y = pageY;
  }

  if (relativeToWindow) {
    coords.x -= window.scrollX;
    coords.y -= window.scrollY;
  }

  return coords;
};

export const createSnackbar = (
  type: SnackbarType,
  content: ReactNode,
  timeout?: number,
  icon?: ReactElement,
): Snackbar => ({
  type,
  content,
  timeout,
  icon,
});

export const createErrorSnackbar = (content: ReactNode, timeout?: number, icon?: ReactElement) =>
  createSnackbar(SnackbarType.Error, content, timeout, icon);

export const createSuccessSnackbar = (content: ReactNode, timeout?: number, icon?: ReactElement) =>
  createSnackbar(SnackbarType.Success, content, timeout, icon);

export const createInfoSnackbar = (content: ReactNode, timeout?: number, icon?: ReactElement) =>
  createSnackbar(SnackbarType.Info, content, timeout, icon);

export const createWarnSnackbar = (content: ReactNode, timeout?: number, icon?: ReactElement) =>
  createSnackbar(SnackbarType.Warn, content, timeout, icon);

export const isHTMLElement = (element: any): element is HTMLElement =>
  element instanceof HTMLElement;

export const cumulativeOffset = function (element: HTMLElement) {
  let top = 0;
  let left = 0;
  while (true) {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    if (isHTMLElement(element.offsetParent)) element = element.offsetParent;
    else break;
  }

  return {
    top,
    left,
  };
};

export const getNestedValue = (object: Record<any, any>, key: string): any => {
  return key.split(".").reduce((acc, k) => (acc && acc[k]) ?? null, object);
};

export const isNumber = (value: any): value is number => typeof value === "number" && !isNaN(value);
export const isString = (value: any): value is string => typeof value === "string";
export const isBoolean = (value: any): value is boolean => typeof value === "boolean";

export const sortMap = (key: string | number, direction: SortDirection) => (a: any, b: any) => {
  const keyISNum = typeof key === "number";

  let aVal = keyISNum ? a[key] : getNestedValue(a, key);
  let bVal = keyISNum ? b[key] : getNestedValue(b, key);

  if (aVal == null) return 1;
  if (bVal == null) return -1;

  const validFormats = [
    "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
    "YYYY-MM-DDTHH:mm:ss.SSSZ",
    "YYYY-MM-DDTHH:mm:ss.SSSZZ",
    "YYYY-MM-DDTHH:mm:ss[Z]",
    "YYYY-MM-DDTHH:mm:ssZ",
    "YYYY-MM-DDTHH:mm:ssZZ",
  ];

  const isDate = validFormats.some(format => dayjs(aVal, format, true).isValid());

  if (isDate) {
    const aDate = dayjs(aVal);
    const bDate = dayjs(bVal);

    return direction === SortDirection.Ascending ? aDate.diff(bDate) : bDate.diff(aDate);
  }

  if (isNumber(aVal) && isNumber(bVal)) {
    return direction === SortDirection.Ascending ? aVal - bVal : bVal - aVal;
  }

  if (isString(aVal) && isString(bVal)) {
    return direction === SortDirection.Ascending
      ? aVal.localeCompare(bVal)
      : bVal.localeCompare(aVal);
  }

  if (isBoolean(aVal) && isBoolean(bVal)) {
    return direction === SortDirection.Ascending
      ? Number(aVal) - Number(bVal)
      : Number(bVal) - Number(aVal);
  }

  return 0;
};

export const numberToPrice = (number: number, compact?: boolean) => {
  const formatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: compact && Math.round(number).toString().length >= 4 ? 0 : 2,
    maximumFractionDigits: 2,
    currencyDisplay: "symbol",
    notation: compact ? "compact" : "standard",
  });

  return formatter.format(number);
};

export const numberToCurrency = (number: number, currency?: Currency | null, compact?: boolean) => {
  if (currency == null || currency === Currency.NotSet) return "N/A";
  const formatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: compact && Math.round(number).toString().length >= 4 ? 0 : 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency,
    currencyDisplay: "symbol",
    notation: compact ? "compact" : "standard",
  });

  return formatter.format(number);
};

export const getCurrencySymbol = (currency?: string | null) => {
  if (currency == null || currency === Currency.NotSet) return "N/A";
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currency.toUpperCase(),
    currencyDisplay: "symbol",
  });

  return formatter.format(0).replace(/[0-9.,]+/, "");
};

export const getMediaDevices = async () => (await navigator.mediaDevices?.enumerateDevices()) ?? [];

export const getAudioInputDevices = async () => {
  return (await getMediaDevices()).filter(({ kind }) => kind === "audioinput");
};

export const getAudioOutputDevices = async () => {
  return (await getMediaDevices()).filter(({ kind }) => kind === "audiooutput");
};

export const getVideoInputDevices = async () => {
  return (await getMediaDevices()).filter(({ kind }) => kind === "videoinput");
};

export const mediaDevicesToOptions = (devices: MediaDeviceInfo[]): Option[] =>
  devices.map(({ deviceId, label }) => ({
    value: deviceId,
    label: label || "Unknown",
  }));

export const mapToArray = <T>(map?: Map<string, T>) => Array.from(map?.values() ?? []);

export const classNames = (...classNames: any[]) =>
  classNames.filter(className => !!className).join(" ");

export const copyToClipboard = async (text: string): Promise<string | null> => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err: any) {
    return err.message;
  }
  return null;
};

export const createReviewPath = (sessionId: string, ticketId?: string, bookingId?: string) => {
  return `/bookings/room/${sessionId}/post?type=customer&ticketId=${ticketId}&bookingId=${bookingId}`;
};

export const createRoomPath = (sessionId: string, ticketId?: string, bookingId?: string) => {
  if (ticketId != null) {
    return `/bookings/room/${sessionId}?type=customer&ticketId=${ticketId}&bookingId=${bookingId}`;
  } else {
    return `/bookings/room/${sessionId}?type=cig`;
  }
};

export const createOpenRoomPath = (sessionId: string, accessCode: string) =>
  `/bookings/room/${sessionId}?type=customer&accessCode=${accessCode}`;

export const generateSessionCalendarLink = (
  cigDisplayName: string,
  sessionId: string,
  sessionDate: string,
) =>
  `${window.location.origin}/@${cigDisplayName}${createParams(
    { sessionId },
    { periodDate: sessionDate },
  )}`;

export const canFullscreen = () => !!screenfull.on && screenfull.isEnabled;

export const pluralise = (word: string, number: number) => `${word}${number !== 1 ? "s" : ""}`;

export const dateIsToday = (date: Dayjs, today: Dayjs = dayjs()) => {
  return date.startOf("day").isSame(today.startOf("day"));
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const last = parts.pop();
    if (last == null) return;
    return last.split(";").shift();
  }
};

export const getCheapestSession = (_items: Session[]): Session | undefined => {
  const items = [..._items];
  const sorted = items.sort(sortMap("price", SortDirection.Ascending));
  const filtered = sorted.filter(({ slotsAvailable }) => slotsAvailable > 0);
  return filtered[0];
};

export const stopPropagation = (event: any) => {
  event.stopPropagation();
};

export const compareVersions = (version: string, requiredVersion: string) => {
  const majorMatch = version[0] === requiredVersion[0];
  const minorMatch = version[2] === requiredVersion[2];
  const patchMatch = version[4] === requiredVersion[4];

  return {
    major: majorMatch,
    minor: majorMatch && minorMatch,
    patch: majorMatch && minorMatch && patchMatch,
  };
};

export const padArray = <A, W>(array: Array<A | W>, padWith: W, padTo: number): Array<A | W> => {
  const newArray = [...array];
  for (let i = 0; i < padTo - array.length; i++) {
    newArray.push(padWith);
  }

  return newArray;
};

export const toTitleCase = (string: string, joinChar = "") =>
  string
    .split(/(?=[A-Z])|[-_]/g)
    .map(subString => capitalize(subString))
    .join(joinChar);

export const capitalize = (string: string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const checkIsLightColor = (color: UIColor) => lightColors.includes(color);
export const checkIsDarkColor = (color: UIColor) => darkColors.includes(color);

export const objectToOptions = (array: { [key: string | number]: string }): Option[] =>
  Object.entries(array).map(([value, label]) => ({
    value,
    label,
  }));

export const subCategoriesToOptions = (subCategories: SubCategories): Option[] =>
  Object.entries(subCategories)
    .map(([value, label]) => ({
      value,
      label,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const gamesToOptions = (games: GamePartial[]): Option[] =>
  games
    .reduce(
      (acc, game) => (acc.find(({ id }) => game.id === id) ? acc : [...acc, game]),
      [] as GamePartial[],
    )
    .map(({ id, longName }) => ({ value: id, label: longName }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const rgbTofeColorMatrix = (rgbString: string) => {
  const RGB = rgbString
    .replace(/(rgba\(|rgb\()(.*)(\))/g, "$2")
    .split(",")
    .map(string => parseFloat(string) / 255);

  const red = RGB[0];
  const green = RGB[1];
  const blue = RGB[2];
  const alpha = RGB[3] ?? 1;

  const numberList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  numberList[4] = red;
  numberList[9] = green;
  numberList[14] = blue;
  numberList[18] = alpha;

  return numberList.join(" ");
};

export const indexOverflow = (length: number, index: number) => {
  if (index < 0) index += length;
  if (index >= length) index -= length;
  return index;
};

export const randomNum = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const getClientTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getClientCountry = () => tzToCountry[getClientTimezone()];

export const getLongestGame = (games: Game[], selectedGames: Compatibility[]) => {
  let longestGame: Game | null = null;

  const filteredGames = games.filter(({ id }) => selectedGames.find(({ game }) => id === game.id));

  for (const game of filteredGames) {
    if (game.estimatedMaxLengthMins == null) continue;
    if (
      !longestGame ||
      (longestGame.estimatedMaxLengthMins != null &&
        game.estimatedMaxLengthMins > longestGame.estimatedMaxLengthMins)
    ) {
      longestGame = game;
    }
  }

  return longestGame;
};

export const createParams = (...params: Record<string, string | null | undefined>[]) =>
  `?${params
    .reduce((acc, object) => {
      const [key, value] = Object.entries(object)[0];
      if (value) return [...acc, `${key}=${encodeURIComponent(value)}`];
      return acc;
    }, [] as string[])
    .join("&")}`;
