import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getSupportedPresentmentCurrency, {
  SupportedPresentmentCurrency,
} from "../getSupportedPresentmentCurrency";

const useGetSupportedPresentmentCurrency = (autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<SupportedPresentmentCurrency | null>(null);

  const send = useCallback(async () => {
    setLoading();
    try {
      const supportedPresentmentCurrency = await getSupportedPresentmentCurrency();
      return setSuccess(supportedPresentmentCurrency);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetSupportedPresentmentCurrency;
