import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import useCigRegisterContext from "../context/useCigRegisterContext";
import CountrySearch from "@components/form/CountrySearch";
import Button from "@components/form/Button";
import { IoArrowForward } from "react-icons/io5";

export default function CigCreateStep() {
  const {
    values,
    validations,
    onChange,
    onBlur,
    inputSize,
    selectSize,
    buttonSize,
    validationPosition,

    cigCreateIsLoading,
  } = useCigRegisterContext();

  // const prevValues = usePrevious(values);

  // useEffect(() => {
  //   const changes = [];

  //   if (prevValues != null) {
  //     const key = `${prevValues.platform.toLowerCase()}Handle`;
  //     changes.push({ name: key, value: undefined });
  //   }

  //   const key = `${values.platform.toLowerCase()}Handle`;
  //   changes.push({ name: key, value: values.socialMediaHandle });

  //   onChangeMultiple(changes);

  //   // eslint-disable-next-line
  // }, [values.platform, values.socialMediaHandle]);
  // // must omit onChange to avoid infinite loop

  return (
    <>
      <FormRow className="break">
        <FormEntry
          label="TWITCH HANDLE"
          validation={validations.twitchHandle}
          relativePosition={validationPosition}>
          <Input
            color="black-2"
            textColor="white"
            size={inputSize}
            name="twitchHandle"
            value={values.twitchHandle}
            placeholder="harry_barry"
            onChange={onChange}
            onBlur={onBlur}
            disabled={cigCreateIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label="COUNTRY"
          validation={validations.country}
          relativePosition={validationPosition}>
          <CountrySearch
            position="portal-absolute"
            color="black-2"
            textColor="white"
            size={selectSize}
            name="country"
            value={values.country}
            onSelect={onBlur}
            className="flex-2"
            placeholder="Search for your country..."
            disabled={cigCreateIsLoading}
            autoFilter
            sortResults
          />
        </FormEntry>
      </FormRow>

      <div className="form-box">
        <p className="text gradient">Don't see your country?</p>
        <p className="text">
          Yakkr is unfortunately not available in your country.
          <br />
          <b className="text-purple-5">Do not input a subsitute country.</b>
        </p>
      </div>

      <div className="button-row">
        <Button
          color="gray-1"
          textColor="white"
          size={buttonSize}
          type="submit"
          to="/"
          disabled={cigCreateIsLoading}>
          Cancel
        </Button>
        <Button
          color="purple"
          textColor="white"
          size={buttonSize}
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={cigCreateIsLoading}>
          Create
        </Button>
      </div>
    </>
  );
}
