import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const subscriptionValidators = {
  price: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("number").number.greaterThan(0),
  discount: (validation: FormValidation, value: any) =>
    new Validator(validation, value)
      .exists()
      .is("number")
      .number.greaterThan(0)
      .number.lessThanOrEqualTo(100),
};

export default subscriptionValidators;
