import { api } from "@api/api";

export interface SetSessionToOpenRes {
  accessCode: string;
}

const setSessionToOpen = async (availabilityID: string) => {
  const { data } = await api.post<SetSessionToOpenRes>("/setAvailabilityIsOpenToPublic", {
    availabilityID,
  });
  return data;
};

export default setSessionToOpen;
