import { PropsWithChildren } from "react";

import ticketPlaceholder from "@images/misc/profile_placeholder.jpg";

interface Props {
  img?: string;
}

const TicketTop = ({ children, img }: PropsWithChildren<Props>) => {
  const id = Math.random().toString();

  return (
    <>
      <svg
        viewBox="0 0 434 448"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        className="ticket-svg top-svg">
        <mask id={`mask_${id}`} maskUnits="userSpaceOnUse" x="0" y="0" width="434" height="448">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.00209995 20.002C0.0021038 8.95628 8.95641 0.00195312 20.0021 0.00195312H413.002C424.048 0.00195312 433.002 8.95626 433.002 20.002L433.002 441.003C429.684 441.067 427.012 443.772 427.002 447.103H6.24328C6.23246 443.732 3.49625 441.002 0.122522 441.002C0.0822401 441.002 0.0420494 441.002 0.00195312 441.003L0.00209995 20.002Z"
            fill="white"
            width="434"
            height="448"
          />
        </mask>
        <g mask={`url(#mask_${id})`}>
          <path d="M0 -1H433V447H0V-1Z" fill={`url(#pattern_${id})`} />
          <path d="M0 -1H433V447H0V-1Z" fill={`url(#paint_linear_${id})`} />
        </g>
        <defs>
          <pattern
            id={`pattern_${id}`}
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1">
            <use xlinkHref={`#image_${id}`} transform="translate(-0.017321) scale(0.00073903)" />
          </pattern>
          <linearGradient
            id={`paint_linear_${id}`}
            x1="221.653"
            y1="469.238"
            x2="221.653"
            y2="286.271"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#131217" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <image
            id={`image_${id}`}
            width="1400"
            height="1400"
            xlinkHref={img ?? ticketPlaceholder}
            preserveAspectRatio="xMidYMid slice"
          />
        </defs>
      </svg>
      {children}
    </>
  );
};

export default TicketTop;
