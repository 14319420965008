import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import UpdateExperienceCustomContext from "./context/UpdateExperienceCustomContext";
import UpdateExperienceCustomDesktop from "./UpdateExperienceCustomDesktop";
import UpdateExperienceCustomMobile from "./UpdateExperienceCustomMobile";

export default function UpdateExperienceCustom() {
  const { breakpoints } = useGlobalContext();

  return (
    <UpdateExperienceCustomContext>
      {breakpoints.sm ? <UpdateExperienceCustomMobile /> : <UpdateExperienceCustomDesktop />}
    </UpdateExperienceCustomContext>
  );
}
