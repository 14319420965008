import { useLayoutEffect, useState } from "react";

const useOnMountTimeout = (timeout: number) => {
  const [done, setDone] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => setDone(true), timeout);
    // eslint-disable-next-line
  }, []);
  // must omit timeout to prevent unwanted updates

  return done;
};

export default useOnMountTimeout;
