import { IoCheckmark } from "react-icons/io5";

interface Props {
  status: boolean;
  name: string;
}

const Status = ({ status, name }: Props) => (
  <span
    className={`flex-row align-center ${status ? "text-green" : "text-red"}`}
    style={{ gap: 4 }}>
    <IoCheckmark />
    {name}
  </span>
);

export default Status;
