import Form from "@components/form/Form";
import GAEvent from "@lib/utils/GAEvent";
import React from "react";
import { useHistory } from "react-router-dom";
import useCigLoginContext from "./context/useCigLoginContext";
import LoginStep from "./steps/LoginStep";

export default function CigLoginForm() {
  const { handles, resetValidations, validations, setStep, category } = useCigLoginContext();

  const [handle] = handles;

  const history = useHistory();

  return (
    <Form
      id="login-form"
      onStepChange={setStep}
      handle={handle}
      resetValidations={resetValidations}
      validation={validations.generic}
      triggerGlobalLoading
      onCompletion={() => {
        GAEvent.login("Email");
        history.push(`/cig/register?cig=${category}`);
      }}>
      <LoginStep />
    </Form>
  );
}
