import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import { mediaDevicesToOptions } from "@lib/utils/generic";
import Select from "../../../../../form/Select";
import useRoomContext from "../../roomContext/hooks/useRoomContext";
import ParticipantView from "../../view/ParticipantView";

export default function SettingsTab() {
  const {
    activeParticipants,
    localIdentity,

    audioInputDevices,
    videoInputDevices,

    audioInputDeviceId,
    videoInputDeviceId,

    setAudioInputDeviceId,
    setVideoInputDeviceId,
  } = useRoomContext();

  const localParticipant = activeParticipants.find(({ identity }) => identity === localIdentity);

  // const participantOptions = activeParticipants
  //   .filter(({ identity }) => identity !== localIdentity)
  //   .map(({ chatParticipant, identity }) => ({
  //     name: identity,
  //     displayName: chatParticipant?.attributes?.displayName ?? "Unknown",
  //   }));

  return (
    <div className="tab-content settings-tab visible">
      {/* <FormRow>
     <FormEntry label="Main Participant">
       <Select
         variant="outlined"
         color="purple"
         size="medium"
         name="mainParticipant"
         value={mainParticipant?.identity}
         options={participantOptions}
         onChange={({ value }) => setMainParticipantId(value)}
       />
     </FormEntry>
   </FormRow>
      <div className="field">
       <label htmlFor="output">Output</label>
       <Select
         id="output"
         variant="contained"
         color="purple"
         name="output"
         value={audioOutputDeviceId}
         options={mediaDevicesToOptions(audioOutputDevices)}
         size={breakpoints.sm ? "medium" : "large"}
         onChange={async ({ value }) => setAudioOutputDeviceId(value)}
       />
     </div> */}
      <FormRow>
        <FormEntry label="Microphone">
          <Select
            variant="contained"
            color="black-3"
            textColor="white"
            size="large"
            name="microphone"
            value={audioInputDeviceId}
            options={mediaDevicesToOptions(audioInputDevices)}
            onChange={({ value }) => setAudioInputDeviceId(value)}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry label="Camera">
          <Select
            variant="contained"
            color="black-3"
            textColor="white"
            size="large"
            name="camera"
            value={videoInputDeviceId}
            options={mediaDevicesToOptions(videoInputDevices)}
            onChange={({ value }) => setVideoInputDeviceId(value)}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry label="Preview">
          <ParticipantView participant={localParticipant} size="small" />
        </FormEntry>
      </FormRow>
    </div>
  );
}
