import Button from "@components/form/Button";
import SplitViewsSplash from "@components/splash/sections/SplitViewsSplash";
import SplashText from "@components/splash/SplashText";
import SplashTitle from "@components/splash/SplashTitle";
import { IoArrowForward, IoSparkles } from "react-icons/io5";

interface Props {
  onSignUpClick: () => void;
}

const Perks = (props: Props) => {
  const { onSignUpClick } = props;

  return (
    <SplitViewsSplash
      views={[
        {
          title: "GIVING YOUR EXPOSURE & CONTROL",
          content: (
            <SplashText className="perks-text">
              It's <span className="gradient">free to sign up</span>. No catches or hidden costs. We
              use a standardised contract which is fair to Streamers and businesses.
            </SplashText>
          ),
          altContent: (
            <div className="perks">
              <div className="head">
                <SplashTitle>PERKS</SplashTitle>
                <div className="bar" />
              </div>

              <ul>
                <li>
                  <IoSparkles /> Keep 100% of your earnings
                </li>
                <li>
                  <IoSparkles /> No Exclusivity - you're not locked in with Flank
                </li>
                <li>
                  <IoSparkles /> Exposure to more and varied brands
                </li>
                <li>
                  <IoSparkles /> Standardised and fair contracts for simple agreement
                </li>
              </ul>
            </div>
          ),
          reverse: true,
          buttons: (
            <>
              <Button
                color="peach-2"
                textColor="black-1"
                endIcon={<IoArrowForward />}
                onClick={onSignUpClick}>
                Sign Up
              </Button>
            </>
          ),
        },
      ]}
    />
  );
};

export default Perks;
