import { api } from "@api/api";

export interface Compatibility {
  id: string;
  game: GamePartial;
  hostPlatform: PlatformPartial;
  compatiblePlatforms: CompatiblePlatforms;
}

export interface CompatiblePlatforms {
  items: {
    id: string;
    platform: PlatformPartial;
  }[];
}

export interface PlatformPartial {
  name: string;
  id: string;
  shortName: string;
  icon: string;
}

export interface GamePartial {
  id: string;

  shortName: string;
  longName: string;

  category: string;
  description: string;

  primaryImageURL: string | null;
  secondaryImageURL: string | null;
  tertiaryImageURL: string | null;

  statisticName: string | null;
  statisticValue: string | null;
}

const getCompatibility = async (hostPlatformID: string, gameID: string) => {
  const { data } = await api.get<Compatibility>("/compatibility", {
    params: {
      hostPlatformID,
      gameID,
    },
  });
  return data;
};

export default getCompatibility;
