import useChangePassword from "@api/auth/hooks/useChangePassword";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { ChangePasswordValues } from "../Password";

const useChangePasswordHandles: UseFormHandles<ChangePasswordValues> = ({
  values,
  validateFields,
  validateCustom,
  resetValidations,
}) => {
  const { isLoading, send: changePassword } = useChangePassword(
    values.oldPassword,
    values.password,
  );

  const handle = async () => {
    resetValidations();
    let failed = validateFields(["oldPassword", "password", "confirmPassword"]);

    if (!failed) {
      failed = validateCustom("password", validation => {
        if (values.password !== values.confirmPassword) {
          validation.list.push({
            text: "Passwords do not match",
            type: "error",
          });
        }
      });
    }

    if (!failed) {
      const { error } = await changePassword();

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    return failed;
  };

  return [handle, isLoading];
};

export default useChangePasswordHandles;
