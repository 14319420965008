import AuthWrapper from "@components/layout/auth/AuthWrapper";
import ConsultancySignUpForm from "./FlankSignUpForm";
import useFlankSignUpContext from "./context/useFlankSignUpContext";
import FlankBanner from "@components/common/FlankBanner";

const FlankSignUpInner = () => {
  const { step } = useFlankSignUpContext();

  return (
    <>
      <FlankBanner />
      <AuthWrapper
        title={["Register", "Thanks for registering your interest. "][step]}
        isLoading={false}
        noAltAuth>
        <ConsultancySignUpForm />
      </AuthWrapper>
    </>
  );
};

export default FlankSignUpInner;
