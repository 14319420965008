import { compareVersions, getClientCountry, log } from "@lib/utils/generic";
import { Auth } from "aws-amplify";
import axios, { AxiosError, AxiosResponse } from "axios";
import dayjs from "dayjs";
import config, { appVersion, requiredApiVersion } from "../config/config";

export interface APIResponsePaginated<T> {
  items: T[];
  nextToken: string | null;
}
export interface APIResponseArray<T> {
  items: T[];
}

export interface APIResponseData<T> {
  data: T[];
}

export const api = axios.create({
  baseURL: config.apiURL,
});

api.interceptors.request.use(async config => {
  try {
    let userIdToken = null;

    if (await checkSessionHasExpired()) {
      log("Session expiring soon, fetching new token");
      userIdToken = await getNewToken();
    } else {
      const session = await Auth.currentSession();
      userIdToken = session.getIdToken().getJwtToken();
    }

    if (userIdToken != null) {
      config.headers.common["Authorization"] = userIdToken;
    }
  } catch (err) {
    log(err);
  }

  const country = getClientCountry();
  if (country != null) {
    config.headers.common["Country"] = country;
  }

  return config;
});

let failedAttempts = 0;

api.interceptors.response.use(
  async (res: AxiosResponse<{ yakkrAPIVersion: string; response: any }>) => {
    failedAttempts = 0;

    const { yakkrAPIVersion, response } = res.data;
    res.data = response;

    if (requiredApiVersion) {
      const versionMatches = compareVersions(yakkrAPIVersion, requiredApiVersion);

      if (!versionMatches.major || !versionMatches.minor) {
        log(
          `Version ${appVersion} requires API version ${requiredApiVersion}. Current API version: ${yakkrAPIVersion}`,
        );
        throw new Error("VERSION_MISMATCH");
      }
    }

    return res;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config;

    const isUnauthorised = error.response?.data?.message === "Unauthorized";
    const isTokenExpired = error.response?.data?.code === "TOKEN_EXPIRED";

    if (isUnauthorised || isTokenExpired) {
      if (failedAttempts > 5) {
        log("Exceeded 5 re-auth attempts, halting.");
        Auth.signOut();
        return;
      }
      failedAttempts += 1;

      const userIdToken = await getNewToken();
      if (userIdToken != null) {
        originalRequest.headers["Authorization"] = userIdToken;
        return api(originalRequest);
      }
    }

    throw error;
  },
);

export const isAxiosError = (err: any): err is AxiosError => err.isAxiosError;

export const getMsgFromApiErr = (err: any): string => {
  log(err);
  if (isAxiosError(err)) {
    const message = err.response?.data?.message;
    if (message) return message;
  }
  return err.message ?? err;
};

export const getCodeFromApiErr = (err: any): string | undefined => {
  log(err);
  if (isAxiosError(err)) {
    const code = err.response?.data?.code;
    if (code) return code;
  }
};

const checkSessionHasExpired = async () => {
  const user = await Auth.currentAuthenticatedUser();
  if (user == null) return true;

  const expMs = user.signInUserSession.idToken.payload.exp as number;

  const exp = dayjs.unix(expMs);

  const diff = exp.diff();
  const minutesLeft = diff / 1000 / 60;

  const hasExpired = minutesLeft <= 1;

  log(`Current session:\nMinutes left: ${minutesLeft}\nHas Expired: ${hasExpired}`);

  return hasExpired;
};

const getNewToken = () => {
  return new Promise<string>(async (resolve, reject) => {
    const session = await Auth.currentSession();
    const refreshToken = session.getRefreshToken();
    const user = await Auth.currentAuthenticatedUser();
    user.refreshSession(refreshToken, (err: any, session: any) => {
      if (err) reject(err);
      const userIdToken = session.getIdToken().getJwtToken();
      resolve(userIdToken);
    });
  });
};
