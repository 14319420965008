import { getCurrencySymbol, numberToPrice, parseTime, pluralise } from "@lib/utils/generic";
import DataCheck from "@components/common/DataCheck";
import dayjs, { Dayjs } from "dayjs";
import { Currency, ExperienceRequestState } from "@lib/enums/generic";
import Pill from "@components/common/Pill";
import IconButton from "@components/form/IconButton";
import { IoInformation, IoTime } from "react-icons/io5";
import BackgroundImage from "@components/common/BackgroundImage";
import { ReactNode, useState } from "react";
import Slide from "@components/transitions/Slide";
import Input from "@components/form/Input";
import { experienceRequestStateColors, experienceRequestStateNames } from "@lib/constants/generic";

interface Props {
  state?: ExperienceRequestState;

  packageName?: string;
  extraCustomerRequestDetails?: string;

  createdAt?: Dayjs;
  start?: Dayjs;
  end?: Dayjs;
  tickets?: number;

  price?: number;
  currency?: Currency;

  gameName?: string;
  gameImg?: string | null;
  platformName?: string;

  actions?: ReactNode;

  width?: number | string;

  isLoading?: boolean;
}

export type ExperienceRequestCardProps = Props;

export default function ExperienceRequestCard(props: Props) {
  const {
    state,

    packageName,
    extraCustomerRequestDetails,

    tickets,

    price = 0,
    currency,

    createdAt,
    start,
    end,

    gameName,
    gameImg,
    platformName,

    actions,

    width = 250,

    isLoading,
  } = props;

  const [showingInfo, setShowingInfo] = useState(false);

  const sessionLength = end?.diff(start, "minutes") ?? 0;

  const { hours, minutes } = parseTime(sessionLength * 60);

  const hardExpiry = dayjs(createdAt).add(6, "days");
  const expiresOn = hardExpiry.isBefore(start) ? hardExpiry : start;

  const expiresIn = expiresOn?.subtract(1, "hour").diff(dayjs(), "seconds") ?? 0;

  const {
    daysPadded: expiryDays,
    hoursPadded: expiryHours,
    minutesPadded: expiryMinutes,
  } = parseTime(expiresIn ?? 0, true);

  const stateName = state && experienceRequestStateNames[state];
  const stateColor = state && experienceRequestStateColors[state];

  const moreInfo = (
    <div className="more-info-wrapper">
      <BackgroundImage src={gameImg} opacity={0.85} />

      <div className="more-info-inner">
        <p className="tickets-number">{tickets}</p>
        <p className="tickets-text">{tickets != null && pluralise("ticket", tickets)}</p>

        <p className="title">Customer Request</p>
        <Input
          variant="flat"
          textColor="white"
          readOnly
          multiline
          value={extraCustomerRequestDetails}
          placeholder="No requests made by customer"
        />
      </div>
    </div>
  );

  return (
    <div
      className={"experience-request-card"}
      style={{
        minWidth: width,
        width: width,
        fontSize: width,
      }}>
      <DataCheck isLoading={isLoading} imgs={[gameImg]} loadingIndicator="gloss">
        <BackgroundImage src={gameImg} opacity={0.4} />
        <div className="background-gradient" />

        <div className="top-wrapper">
          <Pill size="small" color="yellow" textColor="black" rounded>
            {packageName}
          </Pill>
          <Pill size="small" color="yellow" textColor="black" rounded>
            {hours > 0 && `${hours}H`} {minutes > 0 && `${minutes}M`}
          </Pill>
        </div>

        <div className="bottom-wrapper">
          <p className="price">
            <span className="currency">{getCurrencySymbol(currency)}</span> {numberToPrice(price)}
          </p>
          <div className="date-time">
            <p className="date">{start?.format("dddd DD MMM")}</p>
            <p className="time">
              {start?.format("HH:mm")} - {end?.format("HH:mm")}
            </p>
          </div>
          <p className="platform-game">
            {gameName} | {platformName}
          </p>
          <p className={`state text-${stateColor}`}>
            {state === ExperienceRequestState.Pending ? (
              <>
                <IoTime /> {expiryDays}:{expiryHours}:{expiryMinutes} to accept
              </>
            ) : (
              stateName
            )}
          </p>

          <div className="actions">{actions}</div>

          <p className="created-at">Created on: {createdAt?.format("HH:mm DD MMM YY")}</p>
        </div>

        <Slide in={showingInfo} appear>
          {moreInfo}
        </Slide>

        <div className="more-info-button-wrapper">
          <IconButton
            size="small"
            color="white"
            iconColor="black"
            rounded
            icon={<IoInformation />}
            onClick={() => setShowingInfo(showingInfo => !showingInfo)}
          />
        </div>
      </DataCheck>
    </div>
  );
}
