import { api } from "@api/api";

interface UpdateAvailabilityRes {}

const updateAvailability = async (
  availabilityID: string,
  title: string,
  extraInfo: string,
  price: number,
) => {
  const { data } = await api.post<UpdateAvailabilityRes>("/updateAvailability", {
    availabilityID,
    title,
    extraInfo,
    price,
  });
  return data;
};

export default updateAvailability;
