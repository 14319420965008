import {
  cloneElement,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { CSSProps, UIColor } from "../../lib/types/generic";
import IconButton from "../form/IconButton";
import { IoCloseOutline } from "react-icons/io5";
import Fade from "@components/transitions/Fade";
import Button, { ButtonProps } from "@components/form/Button";
import Shake from "@components/transitions/Shake";
import Slide from "@components/transitions/Slide";
import Divider from "@components/common/Divider";
import DataCheck from "@components/common/DataCheck";
import DialogueModal, { DialogModalProps } from "./DialogueModal";
import { classNames } from "@lib/utils/generic";

interface Props extends CSSProps {
  killModal?: boolean;
  disablePageCover?: boolean;
  active?: boolean;
  bare?: boolean;
  onlyCloseButton?: boolean;

  noConfirmButton?: boolean;
  noCancelButton?: boolean;
  noCloseButton?: boolean;

  title?: ReactNode;
  subTitle?: ReactNode;

  icon?: ReactElement;
  color?: UIColor;

  confirmButtonText?: ReactNode;
  cancelButtonText?: ReactNode;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;

  buttons?: ReactNode;
  stackButtons?: boolean;
  titleDesign?: "large" | "condensed";
  buttonsDesign?: "flat" | "divided";

  shake?: boolean;
  periodicShake?: boolean;

  isLoading?: boolean;

  askBeforeClosing?: boolean;
  askModalProps?: Omit<
    DialogModalProps,
    "askBeforeClosing" | "askModalProps" | "in" | "onConfirm" | "onCancel"
  >;

  onClose?: () => void;
}

export type ModalProps = Props;

const Modal = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    children,
    style,
    className = "",
    id,
    killModal = false,
    disablePageCover = false,
    active = false,
    bare,
    onlyCloseButton,

    noConfirmButton,
    noCancelButton,
    noCloseButton,

    title,
    subTitle,

    icon,
    color = "purple",

    confirmButtonText = "Confirm",
    confirmButtonProps,
    cancelButtonText = "Cancel",
    cancelButtonProps,

    buttons,
    stackButtons,
    buttonsDesign = "divided",
    titleDesign = "large",

    shake = false,
    periodicShake = false,

    isLoading,

    askBeforeClosing,
    askModalProps,

    onClose = () => {},
  } = props;

  const [askModalActive, setAskModalActive] = useState(false);

  const handleClose = useCallback(() => {
    if (!askBeforeClosing) return onClose();

    setAskModalActive(true);
  }, [askBeforeClosing, onClose, setAskModalActive]);

  if (killModal) return <>{children}</>;

  const portalRoot = document.getElementById("portal-root");
  if (portalRoot == null) return null;

  return (
    <>
      {askBeforeClosing && (
        <DialogueModal
          active={askModalActive}
          onCancel={() => setAskModalActive(false)}
          onConfirm={() => {
            setAskModalActive(false);
            onClose();
          }}
          title="Before you exit!"
          subTitle="Leaving now will result in lost changes. Are you sure you want to exit now?"
          {...askModalProps}
        />
      )}
      {createPortal(
        <Fade in={active} appear mountOnEnter unmountOnExit>
          <div ref={ref} className={classNames("modal-wrapper", className)} style={style} id={id}>
            {!disablePageCover && <div className="page-cover" onClick={handleClose} />}
            <Shake in={shake} periodic={periodicShake}>
              <div className={classNames("modal", bare && "bare", isLoading && "loading")}>
                {(onlyCloseButton || !bare) && !noCloseButton && (
                  <IconButton
                    variant="flat"
                    color="white"
                    icon={<IoCloseOutline />}
                    className="modal-close-button"
                    onClick={handleClose}
                  />
                )}

                <Slide in={active} appear mountOnEnter unmountOnExit distance="tiny">
                  <div className="modal-content">
                    {!bare && !onlyCloseButton ? (
                      <>
                        <div className={classNames("wrapper", "text", titleDesign)}>
                          {icon &&
                            cloneElement(icon, {
                              className: classNames("modal-icon", `text-${color}`),
                            })}
                          {(title || subTitle) && (
                            <div className="titles-wrapper">
                              {title && <h2 className="title">{title}</h2>}
                              {subTitle && <h5 className="sub-title">{subTitle}</h5>}
                            </div>
                          )}
                        </div>

                        {children && <div className="wrapper children">{children}</div>}

                        {buttonsDesign === "divided" && <Divider color="gray-3" />}

                        <div
                          className={classNames(
                            "button-row",
                            stackButtons && "stack-buttons",
                            buttonsDesign,
                          )}>
                          {buttons ? (
                            buttons
                          ) : (
                            <>
                              {!noCancelButton && (
                                <Button
                                  variant={buttonsDesign === "divided" ? "flat" : "contained"}
                                  color={buttonsDesign === "divided" ? "black" : "white"}
                                  textColor={buttonsDesign === "divided" ? "white" : "black"}
                                  {...cancelButtonProps}>
                                  {cancelButtonText}
                                </Button>
                              )}
                              {!noConfirmButton && (
                                <Button color={color} textColor="white" {...confirmButtonProps}>
                                  {confirmButtonText}
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      children
                    )}
                  </div>
                </Slide>
                <DataCheck isLoading={isLoading} loadingIndicator="gloss" />
              </div>
            </Shake>
          </div>
        </Fade>,
        portalRoot,
      )}
    </>
  );
});

export default Modal;
