import config from "@config/config";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";

const useRoomSocket = (sessionId?: string, autoSend?: boolean) => {
  const { reqState, setError, setLoading, setSuccess } = useRequestState<WebSocket | undefined>(
    undefined,
  );

  const connect = useCallback(() => {
    setLoading();
    const socket = new WebSocket(config.wsUrl);

    socket.addEventListener("open", () => {
      socket.send(
        JSON.stringify({
          availabilityID: sessionId,
          websocketEvent: "SESSION_SUB_REQUEST",
        }),
      );
      setSuccess(socket);
    });

    socket.addEventListener("error", () => setError("Websocket error"));
  }, [sessionId, setLoading, setError, setSuccess]);

  useEffect(() => {
    if (autoSend) connect();
    // eslint-disable-next-line
  }, [sessionId, autoSend]);
  // must omit connect to avoid loop

  return {
    connect,
    error: reqState.error,
    isLoading: reqState.isLoading,
    roomSocket: reqState.data,
  };
};

export default useRoomSocket;

export type ChannelChangeEvent = Array<Record<string, string[]>>;
