import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createAvailability from "../createAvailability";

const useCreateAvailability = (
  gameID?: string,
  platformID?: string,
  fromDate?: string,
  toDate?: string,
  exclusive?: boolean,
  charity?: boolean,
  charityName?: string,
  slotsAvailable?: number,
  title?: string,
  extraInfo?: string,
  price?: number,
  free?: boolean,
  repeatingWeeks?: number,
  repeatingDays?: number[],
) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState(null);

  const send = useCallback(async () => {
    if (
      gameID == null ||
      platformID == null ||
      fromDate == null ||
      toDate == null ||
      exclusive == null ||
      charity == null ||
      slotsAvailable == null ||
      title == null ||
      extraInfo == null ||
      price == null ||
      free == null
    )
      return reqState;

    setLoading();
    try {
      await createAvailability(
        gameID,
        platformID,
        fromDate,
        toDate,
        exclusive,
        charity,
        slotsAvailable,
        title,
        price,
        free,
        extraInfo,
        repeatingWeeks,
        repeatingDays,
        charityName,
      );
      return setSuccess(null);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [
    gameID,
    platformID,
    fromDate,
    toDate,
    exclusive,
    charity,
    charityName,
    slotsAvailable,
    title,
    extraInfo,
    price,
    free,
    repeatingWeeks,
    repeatingDays,
    reqState,
    setError,
    setLoading,
    setSuccess,
  ]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateAvailability;
