import { AudioTrack } from "twilio-video";

const _AudioContext = window.AudioContext || (window as any).webkitAudioContext;

const audioContext = _AudioContext ? new _AudioContext() : null;

const createVolumeMeter = async (track: AudioTrack) => {
  if (!audioContext) {
    return;
  }

  await audioContext.resume();

  // Create an analyser to access the raw audio samples from the microphone.
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 1024;
  analyser.smoothingTimeConstant = 0.5;

  // Connect the LocalAudioTrack's media source to the analyser.
  const stream = new MediaStream([track.mediaStreamTrack]);
  const source = audioContext.createMediaStreamSource(stream);
  source.connect(analyser);

  const sampleArray = new Uint8Array(analyser.frequencyBinCount);

  const shutdown = () => {
    source.disconnect(analyser);
  };

  const samples = () => {
    analyser.getByteFrequencyData(sampleArray);
    return sampleArray;
  };

  return { shutdown, analyser, samples };
};

const getVolume = async (
  track: AudioTrack,
  callback: (volume: number, samples?: Uint8Array) => void,
) => {
  const volumeMeter = await createVolumeMeter(track);
  if (!volumeMeter) return null;
  const { shutdown, analyser, samples } = volumeMeter;
  requestAnimationFrame(function checkVolume() {
    callback(analyser.frequencyBinCount, samples());
    if (track.mediaStreamTrack.readyState === "live") {
      requestAnimationFrame(checkVolume);
    } else {
      requestAnimationFrame(() => {
        shutdown();
        callback(0);
      });
    }
  });
  return shutdown;
};

export const getVolumeLevel = async (track: AudioTrack, callback: (level: number) => void) => {
  return await getVolume(track, (bufferLength, samples) => {
    if (!samples) return;

    var level = 0;
    for (var i = 0; i < bufferLength; i++) level += samples[i];

    return callback(level);
  });
};
