import { GradientDefinition, UIColor } from "@lib/types/generic";

export const colors = {
  "discord-blue": "rgb(88, 101, 242)",
  "twitter-blue": "rgb(73, 190, 253)",
  "youtube-red": "rgb(255, 0, 32)",
  "tiktok-black": "rgb(28, 28, 31)",
  "facebook-blue": "rgb(66, 103, 178)",
  "instagram-orange": "rgb(253, 89, 73)",
  "google-red": "rgb(219, 68, 55)",
  "twitch-purple": "rgb(130, 66, 253)",

  // Generic
  white: "rgb(255, 255, 255)", // #FFF
  "off-white": "rgb(250, 250, 250)", // #FAFAFA
  "stained-white": "rgb(234, 234, 234)", // #EAEAEA
  "old-white": "rgb(216, 216, 216)", // #D8D8D8
  black: "rgb(0, 0, 0)", // #000

  // Primary
  purple: "rgb(140, 81, 255)", // #8C51FF
  red: "rgb(255, 82, 82)", // #FF5252
  green: "rgb(30, 221, 118)", // #1EDD76
  blue: "rgb(73, 190, 253)", // #49BEFD
  "dark-blue": "rgb(87, 129, 254)", // #5781FE
  orange: "rgb(254, 146, 51)", // #FE9233
  yellow: "rgb(254, 183, 47)", // #FEB72F
  gold: "rgb(230,205,141)", // #E6CD8D

  // Black
  "black-1": "rgb(13, 11, 19)", // #0D0B13
  "black-2": "rgb(21, 18, 28)", // #15121C
  "black-3": "rgb(33, 28, 43)", // #211C2B
  "black-4": "rgb(42, 36, 56)", //#2A2438

  // Gray
  "gray-1": "rgb(71, 66, 102)", // #474266
  "gray-2": "rgb(127, 124, 169)", // #7F7CA9
  "gray-3": "rgb(165, 162, 195)", // #A5A2C3

  // Peach
  "peach-1": "rgb(234, 110, 89)", // #EA6E59
  "peach-2": "rgb(255, 156, 139)", // #FF9C8B
  "peach-3": "rgb(255, 185, 185)", // #FFB9B9

  // Purple
  "purple-1": "rgb(68, 40, 148)", // #442894
  "purple-2": "rgb(79, 46, 173)", // #4F2EAD
  "purple-3": "rgb(139, 93, 255)", // #8B5DFF
  "purple-4": "rgb(174, 137, 255)", // #AE89FF
  "purple-5": "rgb(197, 171, 255)", // #C5ABFF
  "purple-6": "rgb(241, 233, 255)", // #F1E9FF
};

export const lightColors: UIColor[] = ["white", "purple-6", "gray-3"];

export const darkColors: UIColor[] = [
  "black",
  "black-1",
  "black-2",
  "black-3",
  "black-4",
  "tiktok-black",
];

export const gradients = {
  "gradient-blend-horizontal": {
    rotation: "0",
    stopColors: [
      {
        stopColor: "#32e6ff",
        offset: "5.38%",
      },
      {
        stopColor: "#9AB3FE",
        offset: "31.12%",
      },
      {
        stopColor: "#8140ff",
        offset: "76.17%",
      },
    ],
  } as GradientDefinition,
  "gradient-blend-vertical": {
    rotation: "90",
    stopColors: [
      {
        stopColor: "#49befd",
        offset: "76.34%",
      },
      {
        stopColor: "#6188fe",
        offset: "81.55%",
      },
      {
        stopColor: "#8140ff",
        offset: "90.66%",
      },
    ],
  } as GradientDefinition,
  "gradient-vibrant-blend-horizontal": {
    rotation: "0",
    stopColors: [
      {
        stopColor: "#32e6ff",
        offset: "5.38%",
      },
      {
        stopColor: "#5781fe",
        offset: "55.22%",
      },
      {
        stopColor: "#9f6ffd",
        offset: "105.38%",
      },
    ],
  } as GradientDefinition,
  "gradient-faded-horizontal": {
    rotation: "0",
    stopColors: [
      {
        stopColor: "#99f3ff",
        offset: "5.38%",
      },
      {
        stopColor: "#9ab3fe",
        offset: "55.22%",
      },
      {
        stopColor: "#bc9afe",
        offset: "105.38%",
      },
    ],
  } as GradientDefinition,
  "gradient-light-vertical": {
    rotation: "90",
    stopColors: [
      {
        stopColor: "#e0d0ff",
        offset: "180%",
      },
      {
        stopColor: "#8242fd",
        offset: "0%",
      },
    ],
  } as GradientDefinition,
  "gradient-orange-horizontal": {
    rotation: "90",
    stopColors: [
      {
        stopColor: "#ea6a5b",
        offset: "180%",
      },
      {
        stopColor: "#e39640",
        offset: "0%",
      },
    ],
  } as GradientDefinition,
  "gradient-dark-orange-horizontal": {
    rotation: "90",
    stopColors: [
      {
        stopColor: "#df2201",
        offset: "180%",
      },
      {
        stopColor: "#ff8301",
        offset: "0%",
      },
    ],
  } as GradientDefinition,
  "gradient-gold-vertical": {
    rotation: "180",
    stopColors: [
      {
        stopColor: "#ffe7a8",
        offset: "180%",
      },
      {
        stopColor: "#d3aa3f",
        offset: "90%",
      },
      {
        stopColor: "#ffe7a8",
        offset: "0%",
      },
    ],
  } as GradientDefinition,
};
