import { api } from "@api/api";
import { Currency, StreamNotificationType } from "@lib/enums/generic";

export type StreamNotification =
  | BookedStreamNotification
  | SessionGiftedStreamNotification
  | StartingSoonStreamNotification
  | TippedStreamNotification;

const getStreamNotifications = async (cigID: string) => {
  const { data } = await api.get<StreamNotification[]>("/getStreamNotifications", {
    params: { cigID },
  });
  return data;
};

export default getStreamNotifications;

export interface StreamNotificationShared {
  streamNotificationID: string;
  createdAt: string;
}

export interface BookedStreamNotification extends StreamNotificationShared {
  type: StreamNotificationType.Booked;
  userDisplayName: string;
}

export interface SessionGiftedStreamNotification extends StreamNotificationShared {
  type: StreamNotificationType.SessionGifted;
  userDisplayName: string;
}

export interface StartingSoonStreamNotification extends StreamNotificationShared {
  type: StreamNotificationType.StartingSoon;
  sessionStartTime: string;
}

export interface TippedStreamNotification extends StreamNotificationShared {
  type: StreamNotificationType.Tipped;
  userDisplayName: string | null;
  tipAmount: number;
  tipCurrency: Currency;
}
