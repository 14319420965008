import useQuery from "@hooks/useQuery";
import { Client, Conversation } from "@twilio/conversations";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Room } from "twilio-video";
import useMediaDeviceManager from "../hooks/useMediaDeviceManager";
import useMessages, { RoomMessage } from "../hooks/useMessages";
import useParticipantManager from "./hooks/useParticipantManager";
import useRoomManager from "./hooks/useRoomManager";
import RoomParticipant, { LocalRoomParticipant } from "../objects/RoomParticipant";
import { ParticipantResolvable } from "../hooks/participantUtils";
import { Option } from "@components/form/Options";
import { Session } from "@lib/types/session";
import useRoom, { ChannelNumber } from "./hooks/useRoom";
import { CustomerJoinSessionRes } from "@api/private/twilio/customerJoinSession";
import { CIGJoinSessionRes } from "@api/private/twilio/cigJoinSession";

export type RoomParticipantType = "customer" | "cig";

interface RoomContextType {
  type?: RoomParticipantType;
  bookingId?: string;

  session?: Session;
  sessionError?: string;
  sessionIsLoading: boolean;

  joinDetails?: CustomerJoinSessionRes | CustomerJoinSessionRes | CIGJoinSessionRes | null;
  joinIsLoading: boolean;
  joinError?: string;

  videoRoom?: Room;
  videoRoomIsLoading: boolean;
  videoRoomError?: string;

  chatRoom?: Conversation;
  chatClient?: Client;
  chatRoomIsLoading: boolean;
  chatRoomError?: string;

  roomSocket?: WebSocket;
  roomSocketIsLoading: boolean;
  roomSocketError?: string;

  isReconnecting: boolean;
  reconnectError?: string;
  disconnectError?: string;

  genericIsLoading: boolean;
  genericError?: string;

  rulesAccepted: boolean;
  setRulesAccepted: Dispatch<SetStateAction<boolean>>;

  channelNumber: ChannelNumber;

  changeChannel: (roomNumber: ChannelNumber) => void;
  retryRoom: () => void;
  retrySession: () => void;

  rerender: () => void;

  drawerTab?: string | number;
  setDrawerTab: Dispatch<SetStateAction<string | number | undefined>>;
  drawerParticipantId?: string;
  setDrawerParticipantId: Dispatch<SetStateAction<string | undefined>>;
  viewParticipant: (identity?: string) => void;

  disconnect: () => void;
  disconnected?: boolean;
  setDisconnected: Dispatch<SetStateAction<boolean>>;

  localIdentity?: string;
  cigIdentity?: string;
  mainParticipant?: RoomParticipant;
  localParticipant?: LocalRoomParticipant;
  setMainParticipantId: Dispatch<SetStateAction<string | undefined>>;
  participants: Array<RoomParticipant>;
  activeParticipants: Array<RoomParticipant>;
  gridParticipants: Array<RoomParticipant>;
  dominnantParticipant?: RoomParticipant;

  toggleParticipantAudio: (participant: ParticipantResolvable) => void;
  toggleParticipantVideo: (participant: ParticipantResolvable) => void;
  setParticipantVolume: (participant: ParticipantResolvable, volume: number) => void;

  participantOptions: Option[];

  reportModalActive: boolean;
  initialReportParticipantId?: string;
  openReportModal: (identity?: string) => void;
  closeReportModal: () => void;

  overtimeSnackbarSent: boolean;
  setOvertimeSnackbarSent: Dispatch<SetStateAction<boolean>>;

  messages: RoomMessage[];
  unreadMessages: RoomMessage[];
  setReadAllMessages: () => void;

  audioInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  videoInputDevices: MediaDeviceInfo[];
  audioInputDeviceId: string;
  audioOutputDeviceId: string;
  videoInputDeviceId: string;
  setAudioInputDeviceId: Dispatch<SetStateAction<string>>;
  setAudioOutputDeviceId: Dispatch<SetStateAction<string>>;
  setVideoInputDeviceId: Dispatch<SetStateAction<string>>;

  gridStreamsVisible: boolean;
  setGridStreamsVisible: Dispatch<SetStateAction<boolean>>;

  muted: boolean;
  setMuted: Dispatch<SetStateAction<boolean>>;
  videoDisabled: boolean;
  setVideoDisabled: Dispatch<SetStateAction<boolean>>;
}

export const RoomContext = createContext<RoomContextType>({} as RoomContextType);

interface Props {
  rerender: () => void;
}

export default function RoomContextProvider(props: PropsWithChildren<Props>) {
  const { rerender, children } = props;

  const bookingId = useQuery<RoomParticipantType>("bookingId") ?? undefined;

  const [gridStreamsVisible, setGridStreamsVisible] = useState(true);

  const [muted, setMuted] = useState(false);
  const [videoDisabled, setVideoDisabled] = useState(false);

  const [overtimeSnackbarSent, setOvertimeSnackbarSent] = useState(true);

  const [drawerTab, setDrawerTab] = useState<string | number>();
  const [drawerParticipantIdIntent, setDrawerParticipantIdIntent] = useState<string>();
  const [drawerParticipantId, setDrawerParticipantId] = useState<string>();

  const viewParticipant = useCallback(
    (identity?: string) => {
      setDrawerTab(identity == null ? undefined : "channels");
      setDrawerParticipantId(undefined);
      setDrawerParticipantIdIntent(identity);
    },
    [setDrawerTab, setDrawerParticipantId, setDrawerParticipantIdIntent],
  );

  useEffect(() => {
    if (!drawerParticipantIdIntent) return;
    setDrawerParticipantId(drawerParticipantIdIntent);
    setDrawerParticipantIdIntent(undefined);
  }, [drawerParticipantIdIntent, setDrawerParticipantId, setDrawerParticipantIdIntent]);

  const {
    type,

    session,
    sessionError,
    sessionIsLoading,

    joinDetails,
    joinIsLoading,
    joinError,

    videoRoom,
    videoRoomIsLoading,
    videoRoomError,

    chatRoom,
    chatClient,
    chatRoomIsLoading,
    chatRoomError,

    roomSocket,
    roomSocketIsLoading,
    roomSocketError,

    rulesAccepted,
    setRulesAccepted,

    channelNumber,

    changeChannel,
    retryRoom,
    retrySession,
  } = useRoom();

  const {
    participants,
    activeParticipants,
    gridParticipants,
    mainParticipant,
    dominnantParticipant,
    localParticipant,
    cigIdentity,
    participantOptions,

    reportModalActive,
    initialReportParticipantId,

    setMainParticipantId,
    setDominnantParticipantId,

    openReportModal,
    closeReportModal,

    toggleParticipantAudio,
    toggleParticipantVideo,
    setParticipantVolume,
  } = useParticipantManager(
    videoRoom,
    chatRoom,
    roomSocket,
    videoRoomIsLoading || chatRoomIsLoading,
  );

  const {
    isReconnecting,
    reconnectError,
    disconnectError,
    disconnected,
    disconnect,
    setDisconnected,
  } = useRoomManager(videoRoom, chatClient, roomSocket, setDominnantParticipantId, () =>
    setDrawerTab(undefined),
  );

  const { messages, unreadMessages, setReadAllMessages } = useMessages(
    participants,
    chatRoom,
    videoRoom,
    chatClient,
    viewParticipant,
  );

  const {
    audioInputDevices,
    audioOutputDevices,
    videoInputDevices,
    audioInputDeviceId,
    audioOutputDeviceId,
    videoInputDeviceId,
    setAudioInputDeviceId,
    setAudioOutputDeviceId,
    setVideoInputDeviceId,
  } = useMediaDeviceManager(videoRoom ?? null, localParticipant);

  const genericIsLoading =
    sessionIsLoading ||
    joinIsLoading ||
    videoRoomIsLoading ||
    chatRoomIsLoading ||
    roomSocketIsLoading;

  const genericError =
    sessionError ??
    joinError ??
    videoRoomError ??
    chatRoomError ??
    roomSocketError ??
    reconnectError ??
    disconnectError;

  return (
    <RoomContext.Provider
      value={{
        type,
        bookingId,

        session,
        sessionError,
        sessionIsLoading,

        joinDetails,
        joinIsLoading,
        joinError,

        videoRoom,
        videoRoomIsLoading,
        videoRoomError,

        chatRoom,
        chatClient,
        chatRoomIsLoading,
        chatRoomError,

        roomSocket,
        roomSocketIsLoading,
        roomSocketError,

        isReconnecting,
        reconnectError,
        disconnectError,

        genericIsLoading,
        genericError,

        rulesAccepted,
        setRulesAccepted,

        channelNumber,

        changeChannel,
        retryRoom,
        retrySession,

        rerender,

        toggleParticipantAudio,
        toggleParticipantVideo,
        setParticipantVolume,

        drawerTab,
        setDrawerTab,
        drawerParticipantId,
        setDrawerParticipantId,
        viewParticipant,

        disconnect,
        disconnected,
        setDisconnected,

        cigIdentity,
        localIdentity: localParticipant?.identity,
        localParticipant,
        mainParticipant,
        setMainParticipantId,
        participants,
        activeParticipants,
        gridParticipants,
        dominnantParticipant,

        reportModalActive,
        initialReportParticipantId,
        openReportModal,
        closeReportModal,

        overtimeSnackbarSent,
        setOvertimeSnackbarSent,

        participantOptions,

        messages,
        unreadMessages,
        setReadAllMessages,

        audioInputDevices,
        audioOutputDevices,
        videoInputDevices,
        audioInputDeviceId,
        audioOutputDeviceId,
        videoInputDeviceId,
        setAudioInputDeviceId,
        setAudioOutputDeviceId,
        setVideoInputDeviceId,

        gridStreamsVisible,
        setGridStreamsVisible,

        muted,
        setMuted,
        videoDisabled,
        setVideoDisabled,
      }}>
      {children}
    </RoomContext.Provider>
  );
}
