import Slide from "@components/transitions/Slide";
import { CSSProps, UIColor } from "@lib/types/generic";
import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";

interface Props extends CSSProps {
  active?: boolean;
  timeout?: number;
  color?: UIColor;
}

export default function Ribbon(props: PropsWithChildren<Props>) {
  const {
    active = false,
    timeout = 300,
    color = "purple",
    style,
    children,
    className = "",
    id,
  } = props;

  const portalRoot = document.getElementById("portal-root");

  if (portalRoot == null) return null;

  const nodes = (
    <Slide in={active} appear timeout={timeout} direction="up">
      <div className={`ribbon cookie-consent-ribbon ${color} ${className}`} id={id} style={style}>
        {children}
      </div>
    </Slide>
  );

  return createPortal(nodes, portalRoot);
}
