import { api } from "@api/api";
import { Currency } from "@lib/enums/generic";

export interface MyBooking {
  bookingID: string;
  availabilityID: string;

  displayName: string;

  price: string;
  currency: Currency;

  ticketsOwned: number;
  tickets: Ticket[];

  session: Session;
}

interface Session {
  id: string;
  cigID: string;

  createdAt: string;
  to: string;
  from: string;

  title: string;
  extraInfo: string;

  cig: Cig;
  platform: Platform;
  game: Game;

  maxSlots: number;
  slotsAvailable: number;

  charity: boolean;
  charityName: string;

  free: boolean;
  price: number;
  currency: Currency;

  exclusive: boolean;
}

interface Cig {
  id: string;

  displayName: string;
  tagLine: string;
  category: string;
  subCategory: string;

  profilePictureURL: string;
  bannerPictureURL: string;
}

interface Game {
  id: string;

  longName: string;
  shortName: string;
  description: string;
  category: string;

  primaryImageURL: string;
  secondaryImageURL: string;
}

interface Platform {
  id: string;

  name: string;
  shortName: string;

  icon: string;
}

interface Ticket {
  ticketID: string;
}

export interface GetBookingBody {
  holdingID?: string;
  bookingID?: string;
}

const getBooking = async (body: GetBookingBody) => {
  const { data } = await api.post<MyBooking>("/getBooking", body);
  return data;
};

export default getBooking;
