import DataCheck from "@components/common/DataCheck";
import Tabs, { Tab } from "@components/common/Tabs";
import Button from "@components/form/Button";
import Form from "@components/form/Form";
import ExperiencesHeader from "@pages/experiences/ExperiencesHeader";
import { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import useUpdateExperiencePackageContext from "./context/useUpdateExperiencePackageContext";
import Availability from "./sections/Availability";
import Games from "./sections/Games";
import Prices from "./sections/Prices";

const tabs: Tab[] = [
  {
    value: "availability",
    label: "Availability",
    component: <Availability />,
  },
  {
    value: "games",
    label: "Games",
    component: <Games />,
  },
  {
    value: "prices",
    label: "Prices",
    component: <Prices />,
  },
];

export default function UpdateExperienceCustomMobile() {
  const {
    update,
    resetValidations,
    validations,
    updateIsLoading,
    fullCustomExperienceIsLoading,
    fullCustomExperienceError,
  } = useUpdateExperiencePackageContext();

  const [selectedTab, setSelectedTab] = useState<string | number>("availability");

  return (
    <div id="update-experience-custom-page" className="experiences-manage-page mobile">
      <ExperiencesHeader
        title="Custom Setup"
        subTitle="Set up your availability, prices, and games so your fans can request a session with you."
        actions={
          <Button
            color="black-4"
            textColor="white"
            size="medium"
            startIcon={<IoArrowBack />}
            justifyContent="center"
            to="/experiences">
            Back
          </Button>
        }
      />

      <Tabs
        size="small"
        variant="contained"
        color="black-1"
        textColor="gray-2"
        indicatorColor="black-4"
        indicatorTextColor="white"
        rounded
        tabs={tabs}
        selectedTab={selectedTab}
        onChange={setSelectedTab}
      />

      <Form
        id="update-custom-experience"
        onCompletion={update}
        resetValidations={resetValidations}
        validation={validations.generic}>
        <DataCheck
          isLoading={fullCustomExperienceIsLoading}
          error={fullCustomExperienceError}
          loadingIndicator="spinner">
          {tabs.find(({ value }) => value === selectedTab)?.component}

          {selectedTab === "prices" && (
            <Button
              size="large"
              color="purple"
              textColor="white"
              onClick={update}
              isLoading={updateIsLoading}
              justifyContent="center"
              className="save-button">
              Finish
            </Button>
          )}
        </DataCheck>
      </Form>
    </div>
  );
}
