import { colors } from "@lib/constants/colors";
import { classNames } from "@lib/utils/generic";
import { forwardRef, MouseEvent, useCallback } from "react";
import { CSSProps, UIColor } from "../../lib/types/generic";
import { FormElement } from "./Form";

interface Props extends CSSProps {
  activeTrackColor?: UIColor;
  inactiveTrackColor?: UIColor;
  thumbColor?: UIColor;
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  orientation?: "horizontal" | "vertical";

  name?: string;
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  disableHover?: boolean;
  stopPropgation?: boolean;
  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
}

export type SliderProps = Props;

const Slider = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    activeTrackColor = "gray-3",
    inactiveTrackColor = "white",
    thumbColor = "black-4",
    size = "medium",
    orientation = "horizontal",
    name,
    value = 50,
    min = 0,
    max = 100,
    step = 1,
    disabled,
    disableHover,
    stopPropgation,
    onChange = () => {},
    onBlur = () => {},
    style,
    id,
    className = "",
  } = props;

  const handleClick = useCallback(
    (event: MouseEvent<HTMLInputElement>) => {
      if (stopPropgation) event.stopPropagation();
    },
    [stopPropgation],
  );

  const activeTrackRgb = colors[activeTrackColor];
  const inactiveTrackRgb = colors[inactiveTrackColor];
  const percent = (max / 100) * value;
  const direction = {
    horizontal: "right",
    vertical: "down",
  }[orientation];
  const background = `linear-gradient(to ${direction}, ${activeTrackRgb} 0%, ${activeTrackRgb} ${percent}%, ${inactiveTrackRgb} ${percent}%, ${inactiveTrackRgb} 100%)`;

  return (
    <input
      ref={ref}
      type="range"
      name={name}
      onChange={({ target }) => onChange(target)}
      onBlur={({ target }) => onBlur(target)}
      onClick={handleClick}
      value={value}
      min={min}
      max={max}
      step={step}
      disabled={disabled}
      style={{ background, ...style }}
      id={id}
      className={classNames(
        "slider",
        `${activeTrackColor}-active`,
        `${inactiveTrackColor}-inactive`,
        `${thumbColor}-thumb`,
        size,
        orientation,
        disableHover && "hover-disabled",
        className,
      )}
    />
  );
});

export default Slider;
