import Section from "@components/section/Section";
import Button from "@components/form/Button";
import { useCallback, useState } from "react";
import DialogueModal from "@components/modals/DialogueModal";
import Menu from "@components/form/Menu";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import SectionBar from "@components/section/SectionBar";
import useDeleteUser from "@api/auth/hooks/useDeleteUser";
import { createErrorSnackbar, sleep } from "@lib/utils/generic";
import { useHistory } from "react-router-dom";
import usePageDestruction from "@hooks/usePageDestruction";

interface Props {}

export default function DangerZone(props: Props) {
  const { breakpoints, pushSnackbar } = useGlobalContext();

  const history = useHistory();

  const [confirmModalActive, setConfirmModalActive] = useState(false);
  const [doubleConfirmModalActive, setDoubleConfirmModalActive] = useState(false);

  const [destructing, setDestructing] = useState(false);

  const { send: deleteUser, isLoading } = useDeleteUser();

  const { destruct, reset } = usePageDestruction();

  const cancelDelete = useCallback(() => {
    reset();
    setDestructing(false);
  }, [setDestructing, reset]);

  const handleDelete = useCallback(async () => {
    const { error } = await deleteUser();
    if (error) {
      cancelDelete();
      pushSnackbar(createErrorSnackbar(error, 5000));
    } else {
      history.push("/logout");
    }
  }, [history, pushSnackbar, deleteUser, cancelDelete]);

  const startDelete = useCallback(async () => {
    setDestructing(true);
    await destruct();
    await sleep(1000);
    setDestructing(false);

    handleDelete();
  }, [handleDelete, destruct]);

  return (
    <Section title="Danger Zone">
      <SectionBar className="bg-red">
        <p className="text-white flex-grow text-left">Delete my account</p>
        <Menu
          relativePosition="left"
          variant="flat"
          color="white"
          size="small"
          content={
            <Button
              variant="contained"
              size={breakpoints.sm ? "small" : "medium"}
              color="white"
              textColor="black"
              onClick={() => setConfirmModalActive(true)}>
              Click to continue
            </Button>
          }>
          <Button color="white">Delete</Button>
        </Menu>
      </SectionBar>

      <DialogueModal
        active={confirmModalActive}
        onClose={() => setConfirmModalActive(false)}
        onConfirm={() => setDoubleConfirmModalActive(true)}
        subTitle="Are you sure you want to delete your account"
        requiredConfirmText="DELETE"
      />

      <DialogueModal
        active={doubleConfirmModalActive}
        onClose={() => setDoubleConfirmModalActive(false)}
        onConfirm={startDelete}
        confirmButtonText="I am 100% sure"
        title="BE WARNED"
        subTitle="Once your account has been deleted, there is no going back."
        periodicShake
        buttons={destructing ? <Button color="red">Cancel</Button> : undefined}
      />

      <DialogueModal
        active={destructing || isLoading}
        onClose={cancelDelete}
        noConfirmButton={destructing}
        noCancelButton={isLoading}
        cancelButtonText="Cancel"
        cancelButtonProps={{
          variant: "contained",
          color: "red",
        }}
        confirmButtonText="Deleting"
        confirmButtonProps={{
          isLoading,
        }}
        title="WARNING"
        subTitle="Your account is about to be deleted, this is your last chance to cancel."
      />
    </Section>
  );
}
