import DatePicker, { DatePickerProps } from "@components/form/DatePicker";
import { Position } from "@hooks/useRelativeCoordsAndSize";
import { JustifyContent, UIColor } from "@lib/types/generic";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { Group, Showcase } from "./StyleGuide";

const DatePickers = () => {
  const sizes = [
    "extra-small",
    "small",
    "medium",
    "large",
    "extra-large",
  ] as DatePickerProps["size"][];

  const [value, setValue] = useState(dayjs());
  const [color, setColor] = useState<UIColor>("purple");
  const [textColor, setTextColor] = useState<UIColor>("white");
  const [pickerColor, setPickercolor] = useState<UIColor>("purple");
  const [pickerTextColor, setPickerTextColor] = useState<UIColor>("white");
  const [variant, setVariant] = useState<DatePickerProps["variant"]>("contained");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [justifyContent, setJustifyContent] = useState<JustifyContent>("space-between");
  const [rounded, setRounded] = useState<boolean>(false);

  const props = {
    position: "portal-absolute" as Position,
    value,
    onChange: ({ value }: { value: Dayjs }) => setValue(value),
    color,
    textColor,
    pickerColor,
    pickerTextColor,
    variant,
    disabled,
    isLoading,
    justifyContent,
    rounded,
  };

  return (
    <Showcase
      title="Date Pickers"
      className="date-pickers"
      colors={[
        {
          color: color,
          label: "Color",
          onChange: setColor,
        },
        {
          color: textColor,
          label: "Text Color",
          onChange: setTextColor,
        },
        {
          color: pickerColor,
          label: "Picker Color",
          onChange: setPickercolor,
        },
        {
          color: pickerTextColor,
          label: "Picker Text Color",
          onChange: setPickerTextColor,
        },
      ]}
      selects={[
        {
          value: variant ?? "contained",
          optionsArray: ["contained", "outlined", "flat", "transparent"],
          label: "Variant",
          onChange: setVariant,
        },
        {
          value: justifyContent,
          optionsArray: ["space-between", "flex-start", "center", "flex-end"],
          label: "Justify",
          onChange: setJustifyContent,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
        {
          value: isLoading,
          label: "Is loading",
          onChange: setIsLoading,
        },
        {
          value: rounded,
          label: "Rounded",
          onChange: setRounded,
        },
      ]}>
      {sizes.map(size => (
        <Group key={size}>
          <DatePicker size={size} {...props} />
          <DatePicker size={size} {...props} maxDate={dayjs().add(1, "day")} />
          <DatePicker size={size} {...props} minDate={dayjs().subtract(1, "day")} />
          <DatePicker
            size={size}
            {...props}
            maxDate={dayjs().add(1, "week")}
            minDate={dayjs().subtract(1, "week")}
          />
        </Group>
      ))}
    </Showcase>
  );
};

export default DatePickers;
