import { api } from "@api/api";
import { NotificationType } from "@lib/enums/generic";

export interface UnreadNotification {
  id: string;
  type: NotificationType;
  message: string;
  date: string;
  read: number;
}

const getUnreadNotifications = async () => {
  const { data } = await api.post<UnreadNotification[]>("/getUnreadNotifications");
  return data;
};

export default getUnreadNotifications;
