import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { Dayjs } from "dayjs";
import { useCallback, useEffect } from "react";

const useCalendarPeriod = (
  periodDate: Dayjs,

  setSessionId: (periodDate: string | null) => void,
  setPeriodDate: (periodDate: Dayjs) => void,
) => {
  const { breakpoints } = useGlobalContext();

  const goToNextPeriod = useCallback(() => {
    setSessionId(null);
    setPeriodDate(periodDate.add(1, breakpoints.md ? "day" : "week"));
  }, [periodDate, breakpoints, setSessionId, setPeriodDate]);

  const goToPrevPeriod = useCallback(() => {
    setSessionId(null);
    setPeriodDate(periodDate.subtract(1, breakpoints.md ? "day" : "week"));
  }, [periodDate, breakpoints, setSessionId, setPeriodDate]);

  useEffect(() => {
    setPeriodDate(periodDate.startOf(breakpoints.md ? "day" : "isoWeek"));
  }, [periodDate, breakpoints.md, setPeriodDate]);

  return { periodDate, goToNextPeriod, goToPrevPeriod };
};

export default useCalendarPeriod;
