import { mapToArray } from "@lib/utils/generic";
import { Conversation } from "@twilio/conversations";
import { LocalParticipant, RemoteParticipant, Room } from "twilio-video";
import RoomParticipant, { ChatParticipant, VideoParticipant } from "../objects/RoomParticipant";
import { isString } from "@lib/utils/generic";

export type ParticipantResolvable = ChatParticipant | VideoParticipant | RoomParticipant | string;

export const findParticipant = (
  participants: RoomParticipant[],
  participant: ParticipantResolvable,
) => {
  const identity = isString(participant) ? participant : participant.identity;
  const index = participants.findIndex(currParticipant => currParticipant.identity === identity);
  const roomParticipant = participants[index];
  return { index, roomParticipant };
};

export const getVideoParticipants = (videoRoom: Room) => {
  const array: Array<RemoteParticipant | LocalParticipant> = [
    ...mapToArray(videoRoom?.participants),
  ];
  if (videoRoom?.localParticipant) array.push(videoRoom.localParticipant);
  return array;
};

export const getChatParticipants = async (chatRoom: Conversation) => {
  return (await chatRoom?.getParticipants()) ?? [];
};

export const updateParticipant = (
  participants: RoomParticipant[],
  participant: ChatParticipant | VideoParticipant,
) => {
  const { index } = findParticipant(participants, participant);
  if (index >= 0) {
    participants[index].setParticipant(participant);
  }
  return [...participants];
};
