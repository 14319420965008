import Modal, { ModalProps } from "./Modal";

import { PropsWithChildren } from "react";

interface Props extends ModalProps {
  form?: string;
}

export default function FormModal(props: PropsWithChildren<Props>) {
  const {
    form,

    onClose = () => {},
    className = "",
    children,
    ...modalProps
  } = props;

  return (
    <Modal
      onClose={onClose}
      confirmButtonText="Submit"
      titleDesign="condensed"
      buttonsDesign="flat"
      {...modalProps}
      className={`form-modal ${className}`}
      cancelButtonProps={{
        onClick: onClose,
        ...modalProps.cancelButtonProps,
      }}
      confirmButtonProps={{
        type: "submit",
        form,
        ...modalProps.confirmButtonProps,
      }}>
      {children}
    </Modal>
  );
}
