import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";

const useRedirectToRegister = () => {
  const { setAuthModal } = useGlobalContext();

  const location = useLocation();

  const redirect = useCallback(
    (redirectUrl?: string) => {
      const { pathname, search, hash } = location;
      const redirect = redirectUrl != null ? redirectUrl : `${pathname}${search}${hash}`;

      setAuthModal("login", { redirect });
    },
    [location, setAuthModal],
  );

  return redirect;
};

export default useRedirectToRegister;
