import { api } from "@api/api";
import { Currency } from "@lib/enums/generic";

export interface CustomSessionCost {
  priceIncTax: number;
  priceExcTax: number;
  singleTicketPriceIncTax: number;
  singleTicketPriceExcTax: number;
  streamPriceIncTax: number;
  streamPriceExcTax: number;
  currency: Currency;
  cigOriginalCurrency: Currency;
  cigOriginalPrice: number;

  discountThresholds: {
    tierOne: DiscountThreshold;
    tierTwo: DiscountThreshold;
  };
}

export interface DiscountThreshold {
  inclusiveLowerBound: number;
  inclusiveUpperBound: number;
  discountProportion: number;
  priceIncTax: number;
  priceExcTax: number;
}

const getCustomSessionCost = async (
  cigID: string,
  duration: number,
  tickets: number,
  streamOption: boolean,
) => {
  const { data } = await api.get<CustomSessionCost>("/calculateExpectedCustomSessionCost", {
    params: {
      cigID,
      duration,
      tickets,
      streamOption,
    },
  });
  return data;
};

export default getCustomSessionCost;
