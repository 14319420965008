import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import React from "react";
import useCigRegisterContext from "../context/useCigRegisterContext";
import Button from "@components/form/Button";
import { IoArrowForward } from "react-icons/io5";
import HoverTooltip from "@components/common/HoverTooltip";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import SimpleDatePicker from "@components/form/SimpleDatePicker";
import dayjs from "dayjs";

export default function UserExtensionStep() {
  const { breakpoints } = useGlobalContext();
  const {
    values,
    validations,
    onChange,
    onBlur,
    inputSize,
    buttonSize,
    datePickerSize,
    userExtensionIsLoading,
  } = useCigRegisterContext();

  return (
    <>
      <FormRow className="break">
        <FormEntry label="FIRST NAME" validation={validations.firstName}>
          <Input
            color="black-2"
            textColor="white"
            size={inputSize}
            name="firstName"
            placeholder="Harry"
            value={values.firstName}
            onChange={onChange}
            onBlur={onBlur}
            disabled={userExtensionIsLoading}
          />
        </FormEntry>
        <FormEntry label="SURNAME" validation={validations.surname}>
          <Input
            color="black-2"
            textColor="white"
            size={inputSize}
            name="surname"
            placeholder="Barry"
            value={values.surname}
            onChange={onChange}
            onBlur={onBlur}
            disabled={userExtensionIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry label="DISPLAY NAME" validation={validations.displayName}>
          <Input
            color="black-2"
            textColor="white"
            size={inputSize}
            name="displayName"
            placeholder="HarryBarry"
            value={values.displayName}
            onChange={onChange}
            onBlur={onBlur}
            disabled={userExtensionIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label={
            <div className="flex-row justify-between">
              DOB
              <HoverTooltip
                relativePosition={breakpoints.sm ? "left" : "top"}
                content={
                  <p style={{ width: 200 }} className="text-center">
                    We collect this information to ensure you are old enough to join Yakkr
                  </p>
                }>
                <span className="text-purple-4">Why?</span>
              </HoverTooltip>
            </div>
          }
          validation={validations.dob}>
          <SimpleDatePicker
            color="black-2"
            textColor="white"
            dividerColor="gray-1"
            size={datePickerSize}
            position="portal-absolute"
            name="dob"
            value={values.dob}
            onChange={onChange}
            onBlur={onBlur}
            defaultDate={dayjs("2000-01-01")}
            orientation={breakpoints.xs ? "vertical" : "horizontal"}
            disabled={userExtensionIsLoading}
          />
        </FormEntry>
      </FormRow>

      <div className="button-row">
        <Button
          color="gray-1"
          textColor="white"
          size={buttonSize}
          type="submit"
          to="/"
          disabled={userExtensionIsLoading}>
          Cancel
        </Button>
        <Button
          color="purple"
          textColor="white"
          size={buttonSize}
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={userExtensionIsLoading}>
          Next
        </Button>
      </div>
    </>
  );
}
