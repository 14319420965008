import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getCigCustomExperience, { CigCustomExperience } from "../getCigCustomExperience";

const useGetCigCustomExperience = (cigId?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CigCustomExperience | null>(null);

  const send = useCallback(async () => {
    if (cigId == null) return reqState;
    setLoading();
    try {
      const customExperience = await getCigCustomExperience(cigId);
      setSuccess(customExperience);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [cigId, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [cigId, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetCigCustomExperience;
