import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import customerJoinOpenSession, { CustomerJoinOpenSessionRes } from "../customerJoinOpenSession";

const useCustomerJoinOpenSession = (
  availabilityId?: string,
  accessCode?: string,
  autoSend?: boolean,
) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CustomerJoinOpenSessionRes | null>(null);

  const send = useCallback(async () => {
    if (availabilityId == null || accessCode == null) return reqState;
    setLoading();
    try {
      const data = await customerJoinOpenSession(availabilityId, accessCode);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [availabilityId, accessCode, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [availabilityId, accessCode, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useCustomerJoinOpenSession;
