import Button from "@components/form/Button";
import { CSSProps } from "@lib/types/generic";
import dayjs, { Dayjs } from "dayjs";
import {
  IoTicket,
  IoPencil,
  IoResize,
  IoRocket,
  IoTrash,
  IoEye,
  IoClipboard,
} from "react-icons/io5";
import { FaHandsHelping } from "react-icons/fa";
import diamondIcon from "@images/icons/diamond.svg";
import HoverTooltip from "@components/common/HoverTooltip";
import Menu from "@components/form/Menu";
import Slide from "@components/transitions/Slide";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { Breakpoints } from "@src/globalContext/hooks/useBreakpoints";
import useCopyToClipboard from "@hooks/useCopyToClipboard";
import useCalendarContext from "../context/useCalendarContext";
import { generateSessionCalendarLink } from "@lib/utils/generic";
import DataCheck from "@components/common/DataCheck";

interface Props extends CSSProps {
  id?: string;
  gameName?: string;
  totalSlots?: number;
  remainingSlots?: number;
  exclusive?: boolean;
  charity?: boolean;
  private?: boolean;

  startDate: Dayjs;
  endDate: Dayjs;
  periodDate: Dayjs;
  selecting?: boolean;
  creating?: boolean;
  error?: string | null;
  isLoading?: boolean;
  originalStartDate?: Dayjs;
  nestedCount?: number;
  overlapping?: boolean;
  selected?: boolean;

  onEdit?: () => void;
  onJoin?: () => void;
  onCancel?: () => void;
}

const CalendarSession = (props: Props) => {
  const {
    id,
    gameName,
    totalSlots = 0,
    remainingSlots = totalSlots,
    exclusive,
    charity,
    private: privateSession,

    startDate,
    periodDate,
    selecting,
    creating,
    error,
    isLoading,
    originalStartDate = startDate,
    nestedCount = 0,
    overlapping,
    selected,

    onEdit = () => {},
    onJoin = () => {},
    onCancel = () => {},
  } = props;

  const { cigProfile } = useCalendarContext();
  const { breakpoints } = useGlobalContext();

  const copyToClipboard = useCopyToClipboard();

  const endOfPeriodDate = periodDate.endOf(breakpoints.md ? "day" : "isoWeek");
  const endDate = props.endDate.isAfter(endOfPeriodDate) ? endOfPeriodDate : props.endDate;

  const { left, top, width, height, rowsToRender } = getSessionPosition(
    startDate,
    endDate,
    breakpoints,
  );

  const newStartDate = startDate.startOf("day").add(1, "day");

  let remainingNests = endDate.date() - startDate.date();
  if (endDate.format("HH:mm") === "00:00") remainingNests -= 1;

  let blockType = "standard";
  if (remainingNests > 0 && nestedCount === 0) {
    blockType = "header";
  } else if (remainingNests > 0 && nestedCount > 0) {
    blockType = "body";
  } else if (remainingNests === 0 && nestedCount > 0) {
    blockType = "footer";
  }

  const isBooked = remainingSlots === 0;
  const hasStarted = startDate.isBefore(dayjs());
  const isOver = endDate.isBefore(dayjs());

  let type = "available";
  if (privateSession) type = "private";
  if (isBooked) type = "full";
  if (hasStarted) type = "ongoing";
  if (isOver) type = "ended";
  if (creating) type = "creating";
  if (selecting) type = "selecting";

  const errorPrompt = <p className="prompt-text">{error}</p>;

  const detailsWrapper = (
    <div className="details-wrapper">
      <div className="details">
        <p className="status">{type.toUpperCase()}</p>
        <p className="game-name">{gameName ?? "No game selected"}</p>
        <div className="slots-wrapper">
          <IoTicket />
          <p className="slots">
            {privateSession ? (
              <>{totalSlots} sold</>
            ) : (
              <>
                {totalSlots - remainingSlots} / {totalSlots} sold
              </>
            )}
          </p>
        </div>
      </div>
      <div className="icons">
        {exclusive && (
          <HoverTooltip content={<p>Yakkr+</p>}>
            <img alt="Diamond" src={diamondIcon} />
          </HoverTooltip>
        )}
        {charity && (
          <HoverTooltip content={<p>Charity</p>}>
            <div>
              <FaHandsHelping />
            </div>
          </HoverTooltip>
        )}
      </div>
    </div>
  );

  return (
    <>
      {newStartDate.isBefore(endDate) && (
        <CalendarSession
          {...props}
          nestedCount={nestedCount + 1}
          startDate={newStartDate}
          originalStartDate={originalStartDate}
        />
      )}
      {startDate?.isSameOrAfter(periodDate) && startDate?.isSameOrBefore(endOfPeriodDate) && (
        <Menu
          position="mouse"
          relativePosition="bottom right"
          content={
            <>
              {!isOver && (
                <Button
                  variant="flat"
                  size="small"
                  color="green"
                  textColor="green"
                  endIcon={<IoRocket />}
                  onClick={onJoin}>
                  Launch
                </Button>
              )}
              {!isOver && (
                <Button
                  variant="flat"
                  size="small"
                  color="purple"
                  textColor="white"
                  endIcon={<IoClipboard />}
                  onClick={() =>
                    cigProfile &&
                    id &&
                    copyToClipboard(
                      generateSessionCalendarLink(cigProfile?.displayName, id, startDate.format()),
                    )
                  }>
                  Copy Link
                </Button>
              )}
              <Button
                variant="flat"
                size="small"
                color="purple"
                textColor="white"
                endIcon={hasStarted ? <IoEye /> : <IoPencil />}
                onClick={onEdit}>
                {hasStarted ? "View" : "Edit"}
              </Button>
              {!isOver && (
                <Button
                  variant="flat"
                  size="small"
                  color="red"
                  textColor="red"
                  endIcon={<IoTrash />}
                  onClick={onCancel}>
                  Cancel
                </Button>
              )}
            </>
          }>
          <div
            className={`session ${rowsToRender <= 1 ? "small" : "regular"} ${
              error ? "error" : ""
            } session-${blockType} ${overlapping ? "overlapping" : ""} ${type}`}
            style={{
              top: 0,
              left: 0,
              transform: `translate(${left}px, ${top}px)`,
              width,
              height,
            }}>
            <Slide in={!!selected} appear direction={selected ? "right" : "left"}>
              <div className="selection-indicator" />
            </Slide>
            <DataCheck isLoading={isLoading}>
              {error ? (
                errorPrompt
              ) : selecting ? (
                rowsToRender > 1 ? (
                  <>
                    <p className="prompt-text">{originalStartDate.format("DD MMM - HH:mm")}</p>
                    <IoResize className="prompt-icon" size={32} transform="rotate(-45)" />
                    <p className="prompt-text">{endDate.format("DD MMM - HH:mm")}</p>
                  </>
                ) : (
                  <>
                    <p className="prompt-text">
                      {originalStartDate.format("DD MMM - HH:mm")}
                      <br />
                      -
                      <br />
                      {endDate.format("DD MMM - HH:mm")}
                    </p>
                  </>
                )
              ) : (
                <>
                  {detailsWrapper}
                  {rowsToRender > 4 && detailsWrapper}
                </>
              )}
            </DataCheck>
          </div>
        </Menu>
      )}
    </>
  );
};

const getSessionPosition = (startDate: Dayjs, endDate: Dayjs, breakpoints: Breakpoints) => {
  const cell = document.querySelector<HTMLDivElement>(".blocks .cell:not(.spacer)");

  const style = cell == null ? null : getComputedStyle(cell);

  const cellHeight = parseFloat(style?.height ?? "0px");
  const cellWidth = parseFloat(style?.width ?? "0px");

  const borderWidth = parseFloat(style?.borderBottomWidth ?? "0px");

  const paddingOffset = breakpoints.sm ? 8 : 16;

  const spacerCell = document.querySelector(".blocks .cell.spacer");
  const maxRows = 24 * 4;
  const offsetX = paddingOffset + (spacerCell?.clientWidth ?? 0) - 2;
  const offsetY = paddingOffset - 2;

  const timePeriod = breakpoints.md ? "day" : "isoWeek";

  const startUtcOffset =
    startDate.startOf(timePeriod).utcOffset() - startDate.subtract(15, "minutes").utcOffset();

  const startX = startDate?.diff(startDate.startOf(timePeriod), "days") ?? 0;
  const startY =
    ((startDate?.diff(startDate.startOf("day"), "minutes") ?? 0) - startUtcOffset) / 15;

  const endUtcOffset =
    endDate.startOf(timePeriod).utcOffset() - endDate.subtract(15, "minutes").utcOffset();
  const endX = endDate?.diff(endDate.startOf(timePeriod), "days") ?? 0;
  let endY = ((endDate?.diff(endDate.startOf("day"), "minutes") ?? 0) - endUtcOffset) / 15;
  if (endDate.format("HH:mm") === "00:00") endY = 1;

  const rowsAvailableInColumn = maxRows - startY;

  const columns = endX - startX + 1;
  let rows = -1;

  if (columns === 1) {
    rows += endY - startY + 1;
  } else if (columns === 2) {
    rows += maxRows - startY + endY;
  } else {
    rows += endY - startY + 1;
    rows += maxRows * columns;
    rows += maxRows - startY + endY;
  }

  const rowsToRender = rows > rowsAvailableInColumn ? rowsAvailableInColumn : rows;

  const left = startX * cellWidth + offsetX;
  const top = startY * cellHeight + offsetY + startY * borderWidth;

  const totalCellHeight = rowsToRender * cellHeight;
  const totalBorderWidth = rowsToRender * borderWidth + borderWidth;

  const height = totalCellHeight + totalBorderWidth - 4;
  const width = cellWidth - 4;

  return { left, top, width, height, rowsToRender };
};

export default CalendarSession;
