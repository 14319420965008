import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const messageBoxValidators = {
  message: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string");
  },
};

export default messageBoxValidators;
