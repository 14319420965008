import { isString, mapToArray } from "@lib/utils/generic";
import { Participant } from "@twilio/conversations";
import {
  AudioTrack,
  AudioTrackPublication,
  LocalParticipant,
  RemoteParticipant,
  Track,
  VideoTrack,
  VideoTrackPublication,
} from "twilio-video";
import { ChannelNumber } from "../roomContext/hooks/useRoom";

export type LocalRoomParticipant = Omit<RoomParticipant, "videoParticipant"> & {
  videoParticipant: LocalParticipant;
};

export type VideoParticipant = RemoteParticipant | LocalParticipant;

export type ChatParticipant = Participant & { attributes: { displayName: string; isCIG: boolean } };

export const participantIsVideo = (participant: any): participant is VideoParticipant =>
  !!participant.tracks;
export const participantIsChat = (participant: any): participant is ChatParticipant =>
  !!participant.conversation;
export const participantIsIdentity = (participant: any): participant is string =>
  isString(participant);

const isVideoTrack = (track: Track): track is VideoTrack => track.kind === "video";
const isAudioTrack = (track: Track): track is AudioTrack => track.kind === "audio";

export default class RoomParticipant {
  public isLocal!: boolean;

  public identity!: string;
  public videoParticipant?: VideoParticipant;
  public chatParticipant?: ChatParticipant;

  public audioDisabled: boolean = false;
  public videoDisabled: boolean = false;

  public videoTrack?: VideoTrack;
  public audioTrack?: AudioTrack;

  public volume: number = 100;

  public channelNumber?: ChannelNumber;

  constructor(
    participant: ChatParticipant | VideoParticipant | string,
    channelNumber?: ChannelNumber,
  ) {
    this.setParticipant(participant);
    this.setChannelNumber(channelNumber);
  }

  public setVideoParticipant(videoParticipant?: VideoParticipant) {
    this.isLocal = (videoParticipant as any)?.publishTrack != null;
    this.videoParticipant = videoParticipant;
    return this;
  }

  public removeVideoParticipant() {
    this.videoParticipant = undefined;
    return this;
  }

  public setChatParticipant(chatParticipant?: ChatParticipant) {
    this.chatParticipant = chatParticipant;
    return this;
  }

  public removeChatParticipant() {
    this.chatParticipant = undefined;
    return this;
  }

  public setParticipant(participant: ChatParticipant | VideoParticipant | string) {
    if (participantIsVideo(participant)) {
      this.setVideoParticipant(participant);
      this.identity = participant.identity ?? "";
    } else if (participantIsChat(participant)) {
      this.setChatParticipant(participant);
      this.identity = participant.identity ?? "";
    } else if (participantIsIdentity(participant)) {
      this.identity = participant ?? "";
    }
    return this;
  }

  public removeParticipant(participant: ChatParticipant | VideoParticipant) {
    if (participantIsVideo(participant)) {
      this.setVideoParticipant(undefined);
    } else if (participantIsChat(participant)) {
      this.setChatParticipant(undefined);
    }
    return this;
  }

  public setChannelNumber(channelNumber?: ChannelNumber) {
    this.channelNumber = channelNumber;
    return this;
  }

  public toggleAudio() {
    this.audioDisabled = !this.audioDisabled;
    this.updateTracks();
    return this;
  }

  public toggleVideo() {
    this.videoDisabled = !this.videoDisabled;
    this.updateTracks();
    return this;
  }

  public setVolume(volume: number) {
    this.volume = volume;
    return this;
  }

  public updateTrack(track: Track) {
    if (!isVideoTrack(track) && !isAudioTrack(track)) return;

    if (isVideoTrack(track)) {
      this.videoTrack = undefined;
      if (track.isEnabled && !this.videoDisabled) this.videoTrack = track;
    } else if (isAudioTrack(track)) {
      this.audioTrack = undefined;
      if (track.isEnabled && !this.audioDisabled) this.audioTrack = track;
    }
    return this;
  }

  public updateTracks() {
    const { videoParticipant } = this;
    if (videoParticipant) {
      const videoTracks = videoParticipant.videoTracks;
      const videoTrack = mapToArray<VideoTrackPublication>(videoTracks)[0]?.track;
      if (videoTrack) this.updateTrack(videoTrack);

      const audioTracks = videoParticipant.audioTracks;
      const audioTrack = mapToArray<AudioTrackPublication>(audioTracks)[0]?.track;
      if (audioTrack) this.updateTrack(audioTrack);
    }
    return this;
  }

  public clone() {
    const {
      identity,
      channelNumber,
      videoParticipant,
      chatParticipant,
      audioDisabled,
      videoDisabled,
      videoTrack,
      audioTrack,
      volume,
    } = this;
    const participant = videoParticipant ?? chatParticipant ?? identity;
    if (!participant) return this;

    const instance = new RoomParticipant(participant, channelNumber);
    instance.setVideoParticipant(videoParticipant);
    instance.setChatParticipant(chatParticipant);
    instance.audioDisabled = audioDisabled;
    instance.videoDisabled = videoDisabled;
    instance.videoTrack = videoTrack;
    instance.audioTrack = audioTrack;
    instance.volume = volume;
    instance.channelNumber = channelNumber;

    return instance;
  }
}
