import useUpdateUserExtension from "@api/private/update/hooks/useUpdateUserExtension";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/utils/generic";
import { UpdateUserExtension } from "../About";

const useUpdateUserExtensionHandles: UseFormHandles<UpdateUserExtension> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { userExtension, setUserExtension, pushSnackbar } = useGlobalContext();

  const { isLoading, send: updateUserExtension } = useUpdateUserExtension({
    firstName: values.firstName,
    lastName: values.surname,
    displayName: values.displayName,
    birthdate: values.dob.format("YYYY-MM-DD"),
  });

  const handle = async () => {
    let failed = validateFields(["firstName", "surname", "displayName", "dob"]);

    if (!failed) {
      const { error, data } = await updateUserExtension();

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }

      if (!error && data && userExtension) {
        setUserExtension({ ...userExtension, ...data });
        pushSnackbar(createSuccessSnackbar("Profile updated", 2500));
      }
    }

    return failed;
  };

  return [handle, isLoading];
};

export default useUpdateUserExtensionHandles;
