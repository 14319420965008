import useFormManager from "@components/form/hooks/useFormManager";
import useFlankSignUpHandles from "./useFlankSignUpHandles";

const initialValues = {
  email: "",
  twitchUsername: "",
  terms: false,
};

export type FlankSignUpValues = typeof initialValues;

const useFlankSignUp = () => {
  const formManager = useFormManager(initialValues, "flankSignup");

  const { values, validations, onChange, onBlur, onChangeMultiple, resetValidations } = formManager;

  const [signUpHandle, signUpIsLoading] = useFlankSignUpHandles(formManager);

  return {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles: [signUpHandle],
    handlesIsLoading: [signUpIsLoading],
    resetValidations,
  };
};

export default useFlankSignUp;
