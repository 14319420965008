import { copyToClipboard, createErrorSnackbar, createSuccessSnackbar } from "@lib/utils/generic";
import useGlobalContext from "../globalContext/hooks/useGlobalContext";

const useCopyToClipboard = () => {
  const { pushSnackbar } = useGlobalContext();

  const copy = async (text: string) => {
    const error = await copyToClipboard(text);

    if (error) pushSnackbar(createErrorSnackbar(error, 3000));
    else pushSnackbar(createSuccessSnackbar("Copied to clipboard", 2500));
  };

  return copy;
};

export default useCopyToClipboard;
