import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const updateExperienceCustomValidators = {
  timezoneName: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string"),
  customerOffersPrice: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("boolean"),
  pricePerPeriod: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("number").number.greaterThanOrEqualTo(2.5),
  streamOptionEnabled: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("boolean"),
  requestAvailableTimes: (validation: FormValidation, value: any, values: any) =>
    new Validator(validation, value)
      .skipIf(values.anyTime)
      .exists()
      .is("array")
      .length.greaterThan(0),
};

export default updateExperienceCustomValidators;
