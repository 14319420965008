import usePrevious from "@hooks/usePrevious";
import { Client, ConnectionState, Conversation, Message } from "@twilio/conversations";
import { useCallback, useEffect, useState } from "react";
import { IoFlash, IoFlashOff } from "react-icons/io5";
import { Room } from "twilio-video";
import RoomParticipant from "../objects/RoomParticipant";
import { ChannelNumber } from "../roomContext/hooks/useRoom";
import EventMessage from "../usersDrawer/chatTab/objects/EventMessage";

export type RoomMessage = Message | EventMessage;

const useMessages = (
  participants: RoomParticipant[],
  chatRoom?: Conversation,
  videoRoom?: Room,
  chatClient?: Client,
  viewParticipant?: (identity: string) => void,
) => {
  const [unreadMessages, setUnreadMessages] = useState<RoomMessage[]>([]);
  const [messages, setMessages] = useState<RoomMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const prevParticipants = usePrevious(participants);

  const prevMessages = usePrevious(messages);

  const loadMessages = useCallback(async () => {
    setIsLoading(true);
    const messages = await chatRoom?.getMessages();
    setMessages(messages?.items ?? []);
    setIsLoading(false);
  }, [chatRoom, setIsLoading]);

  const setReadAllMessages = useCallback(() => {
    setUnreadMessages([]);
  }, [setUnreadMessages]);

  useEffect(() => {
    if (!prevMessages || isLoading) return;
    const newMessages = messages.filter(
      ({ sid }) => !prevMessages.find(({ sid: prevSid }) => sid === prevSid),
    );
    setUnreadMessages(unreadMessages => [...unreadMessages, ...newMessages]);
  }, [messages, prevMessages, isLoading]);

  const handleMessageAdded = useCallback(
    (message: Message) => {
      setMessages([...messages, message]);
    },
    [messages],
  );

  const handleMessageRemoved = useCallback(
    (message: Message) => {
      setMessages([...messages].filter(({ sid }) => sid !== message.sid));
    },
    [messages],
  );

  const handleMessageUpdated = useCallback(({ message }: { message: Message }) => {
    setMessages(messages =>
      [...messages].map((currMessage: RoomMessage) =>
        currMessage.sid === message.sid ? message : currMessage,
      ),
    );
  }, []);

  const handleParticipantConnected = useCallback(
    (identity: string, displayName?: string, channelNumber?: ChannelNumber) => {
      setMessages(messages => [
        ...messages,
        new EventMessage(
          (
            <>
              <b
                className="cursor-pointer"
                onClick={() => viewParticipant && viewParticipant(identity)}>
                {displayName ?? "Unknown"}
              </b>{" "}
              joined channel #{channelNumber}
            </>
          ),
          <IoFlash />,
        ),
      ]);
    },
    [viewParticipant],
  );

  const handleParticipantDisconnected = useCallback(
    (identity: string, displayName?: string, channelNumber?: ChannelNumber) => {
      setMessages(messages => [
        ...messages,
        new EventMessage(
          (
            <>
              <b
                className="cursor-pointer"
                onClick={() => viewParticipant && viewParticipant(identity)}>
                {displayName ?? "Unknown"}
              </b>{" "}
              left channel #{channelNumber}
            </>
          ),
          <IoFlashOff />,
        ),
      ]);
    },
    [viewParticipant],
  );

  const handleChatClientConnect = useCallback(
    (state: ConnectionState) => {
      if (state === "connected") loadMessages();
    },
    [loadMessages],
  );

  useEffect(() => {
    for (const newParticipant of participants) {
      const prevParticipant = prevParticipants?.find(
        prevParticipant => prevParticipant.identity === newParticipant.identity,
      );

      if (!prevParticipant) {
        handleParticipantConnected(
          newParticipant.identity,
          newParticipant.chatParticipant?.attributes.displayName,
          newParticipant.channelNumber,
        );
      }
    }

    for (const prevParticipant of prevParticipants ?? []) {
      const newParticipant = participants?.find(
        newParticipant => newParticipant.identity === prevParticipant.identity,
      );

      if (!newParticipant) {
        handleParticipantDisconnected(
          prevParticipant.identity,
          prevParticipant.chatParticipant?.attributes.displayName,
          prevParticipant.channelNumber,
        );
      }
    }
  }, [participants, prevParticipants, handleParticipantConnected, handleParticipantDisconnected]);

  useEffect(() => {
    chatRoom?.on("messageAdded", handleMessageAdded);
    chatRoom?.on("messageRemoved", handleMessageRemoved);
    chatRoom?.on("messageUpdated", handleMessageUpdated);

    chatClient?.on("connectionStateChanged", handleChatClientConnect);

    return () => {
      chatRoom?.off("messageAdded", handleMessageAdded);
      chatRoom?.off("messageRemoved", handleMessageRemoved);
      chatRoom?.off("messageUpdated", handleMessageUpdated);

      chatClient?.off("connectionStateChanged", handleChatClientConnect);
    };
  }, [
    chatRoom,
    chatClient,
    videoRoom,
    handleMessageAdded,
    handleMessageRemoved,
    handleMessageUpdated,
    handleChatClientConnect,
    handleParticipantConnected,
    handleParticipantDisconnected,
  ]);

  useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  return { messages, unreadMessages, setReadAllMessages };
};

export default useMessages;
