import { CSSProps } from "@lib/types/generic";

const Arrow = (cssProps: CSSProps) => {
  return (
    <svg
      width="61"
      height="369"
      viewBox="0 0 61 369"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...cssProps}>
      <g clip-path="url(#clip0_8219_82888)">
        <path
          d="M30.5 0V369M30.5 369L16 352.663M30.5 369L45 352.663"
          stroke="#EAEAEA"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8219_82888">
          <rect width="61" height="369" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Arrow;
