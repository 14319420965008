import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import Button from "@components/form/Button";
import useForgotPasswordContext from "../context/useForgotPasswordContext";
import { IoArrowForward } from "react-icons/io5";
import ForgotPasswordResendCodeBar from "../ForgotPasswordResendCodeBar";

export default function ForgotPasswordSubmitStep() {
  const { values, validations, onChange, onBlur, forgotPasswordSubmitIsLoading } =
    useForgotPasswordContext();

  return (
    <>
      <div>
        <div className="form-box">
          <p className="text">
            We have sent a confirmation code to{" "}
            <a href={`mailto:${values.email}`}>{values.email}</a>.
          </p>
          <p className="text">Use the code from the email, in box below to change your password.</p>
        </div>

        <ForgotPasswordResendCodeBar type="forgot-password" email={values.email} />
      </div>
      <FormRow>
        <FormEntry
          label="CONFIRMATION CODE"
          validation={validations.forgotPasswordCode}
          showValidationBeneath>
          <Input
            variant="contained"
            color="black-4"
            textColor="white"
            size="medium"
            name="forgotPasswordCode"
            placeholder="123456"
            value={values.forgotPasswordCode}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry label="PASSWORD" validation={validations.password} showValidationBeneath>
          <Input
            variant="contained"
            color="black-4"
            textColor="white"
            size="medium"
            name="password"
            placeholder="Password"
            type="password"
            value={values.password}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label="CONFIRM PASSWORD"
          validation={validations.confirmPassword}
          showValidationBeneath>
          <Input
            variant="contained"
            color="black-4"
            textColor="white"
            size="medium"
            name="confirmPassword"
            placeholder="Password"
            type="password"
            value={values.confirmPassword}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormEntry>
      </FormRow>

      <div className="auth-button-row">
        <Button
          color="purple"
          textColor="white"
          size="large"
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={forgotPasswordSubmitIsLoading}>
          Change Password
        </Button>
      </div>
    </>
  );
}
