import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { Conversation } from "@twilio/conversations";
import { useCallback, useEffect, useState } from "react";
import { Room } from "twilio-video";
import useParticipants from "./useParticipants";

const useParticipantManager = (
  videoRoom?: Room,
  chatRoom?: Conversation,
  roomSocket?: WebSocket,
  isConnecting?: boolean,
) => {
  const { breakpoints } = useGlobalContext();

  const [mainParticipantId, setMainParticipantId] = useState<string>();
  const [dominantParticipantId, setDominnantParticipantId] = useState<string>();
  const [reportModalActive, setReportModalActive] = useState(false);
  const [initialReportParticipantId, setInitialReportParticipantId] = useState<string>();

  const {
    participants,
    localParticipant,
    toggleParticipantAudio,
    toggleParticipantVideo,
    setParticipantVolume,
  } = useParticipants(videoRoom, chatRoom, roomSocket);

  const activeParticipants = participants.filter(({ videoParticipant }) => videoParticipant);
  const gridParticipants = activeParticipants.filter(
    ({ identity }) => identity !== mainParticipantId,
  );
  const mainParticipant = participants.find(({ identity }) => identity === mainParticipantId);
  const dominnantParticipant = participants.find(
    ({ identity }) => identity === dominantParticipantId,
  );

  const openReportModal = useCallback(
    (identity?: string) => {
      setInitialReportParticipantId(identity);
      setReportModalActive(true);
    },
    [setInitialReportParticipantId, setReportModalActive],
  );

  const closeReportModal = useCallback(() => {
    setReportModalActive(false);
  }, [setReportModalActive]);

  const cigIdentity = activeParticipants.find(
    ({ chatParticipant }) => chatParticipant?.attributes?.isCIG,
  )?.identity;

  useEffect(() => {
    if (!breakpoints.md) return setMainParticipantId(cigIdentity);

    const mainIsLocal = localParticipant?.identity === mainParticipantId;
    const mainIsActive = activeParticipants.find(({ identity }) => mainParticipantId === identity);

    if (!mainIsLocal && mainIsActive) return;
    if (!isConnecting && localParticipant) {
      const identity = activeParticipants.filter(({ identity }) => {
        return identity !== localParticipant?.identity;
      })[0]?.identity;
      if (identity == null) return;
      setMainParticipantId(identity);
    }
  }, [
    isConnecting,
    activeParticipants,
    mainParticipantId,
    localParticipant,
    breakpoints,
    cigIdentity,
  ]);

  const participantOptions = activeParticipants
    .filter(({ identity }) => identity !== videoRoom?.localParticipant?.identity)
    .map(({ chatParticipant, identity }) => ({
      value: identity,
      label: chatParticipant?.attributes?.displayName ?? "Unknown",
    }));

  return {
    participants,
    activeParticipants,
    gridParticipants,
    mainParticipantId,
    mainParticipant,
    dominnantParticipant,
    localParticipant,
    cigIdentity,
    participantOptions,

    reportModalActive,
    initialReportParticipantId,

    setMainParticipantId,
    setDominnantParticipantId,

    openReportModal,
    closeReportModal,

    toggleParticipantAudio,
    toggleParticipantVideo,
    setParticipantVolume,
  };
};

export default useParticipantManager;
