import { forwardRef, ReactElement, useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { CSSProps, JustifyContent, UIColor } from "../../lib/types/generic";
import { FormElement } from "./Form";
import useCombinedRefs from "@hooks/useCombinedRefs";
import { classNames } from "@lib/utils/generic";
import { IoSearch } from "react-icons/io5";
import Input from "./Input";
import Options, { Option } from "./Options";
import RelativePortal from "@components/common/RelativePortal";
import { Position } from "@hooks/useRelativeCoordsAndSize";

interface Props extends CSSProps {
  position?: Position;
  color?: UIColor;
  textColor?: UIColor;
  resultColor?: UIColor;
  resultTextColor?: UIColor;
  variant?: "contained" | "outlined" | "transparent" | "flat";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  justifyContent?: JustifyContent;
  name?: string;
  results?: Option[];
  disabled?: boolean;
  disableHover?: boolean;
  readOnly?: boolean;
  isLoading?: boolean;
  rounded?: boolean;
  error?: string;
  placeholder?: string;

  autoFilter?: boolean;
  sortResults?: boolean;

  searchValue?: string;
  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;

  value?: string;
  onSelect?: (target: FormElement) => void;

  onFocus?: () => void;
  onStateChange?: (open: boolean) => void;
}

export type SearchProps = Props;

const Search = forwardRef<HTMLInputElement & HTMLTextAreaElement, Props>((props, forwardedRef) => {
  const {
    position = "absolute",
    color = "white",
    textColor = "black",
    resultColor = color,
    resultTextColor = textColor,
    variant = "contained",
    size = "medium",
    startIcon = <IoSearch />,
    endIcon,
    justifyContent = "space-between",
    name,
    results = [],
    disabled,
    disableHover,
    readOnly,
    isLoading,
    rounded,
    error,
    placeholder = "Type to start searching...",

    autoFilter,
    sortResults,

    searchValue,
    onChange = () => {},
    onBlur = () => {},

    value,
    onSelect = () => {},

    onFocus = () => {},
    onStateChange = () => {},

    className = "",
    id,
    style,
  } = props;

  const [open, setOpen] = useState(false);
  const [preserveValue, setPreserveValue] = useState(true);

  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const ref = useCombinedRefs<HTMLInputElement & HTMLTextAreaElement>(inputRef, forwardedRef);

  useEffect(() => {
    onStateChange(open);
  }, [open, onStateChange]);

  useEffect(() => {
    if (preserveValue) return setPreserveValue(false);
    onSelect({ name: name ?? "", value: "", type: "select" });
    // eslint-disable-next-line
  }, [searchValue, name]);
  // must omit onSelect to prevent loop

  useEffect(() => {
    const result = results.find(result => result.value === value);
    if (!result) return;
    setPreserveValue(true);
    onChange({ name: name ?? "", value: result.label, type: "select" });
    // eslint-disable-next-line
  }, [isLoading, name, value]);
  // must omit onChange to prevent loop

  const filteredResults = useMemo(() => {
    let filteredResults = [...results];

    if (autoFilter)
      filteredResults = filteredResults.filter(({ label }) =>
        label.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase() ?? ""),
      );

    if (sortResults)
      filteredResults = filteredResults.sort((a, b) => a.label.localeCompare(b.label));

    if (filteredResults.length === 0)
      filteredResults = [
        { value: "", label: "No results found", disabled: true },
        ...filteredResults,
      ];

    return filteredResults;
  }, [results, searchValue, autoFilter, sortResults]);

  const nodes = (
    <Options
      color={color}
      textColor={textColor}
      optionColor={resultColor}
      optionTextColor={resultTextColor}
      variant={variant}
      size={size}
      name={name}
      disabled={disabled}
      justifyContent={justifyContent}
      value={searchValue}
      options={filteredResults}
      onSelect={({ value, ...target }) => {
        onSelect({ ...target, value: value.value });
        setOpen(false);
        inputRef.current?.blur();
      }}
    />
  );

  const portalRoot = document.getElementById("portal-root");
  if (portalRoot == null) return null;

  return (
    <div
      className={classNames("search-wrapper", open ? "open" : "closed", position, className)}
      id={id}
      style={style}>
      <RelativePortal
        active={open}
        content={nodes}
        relativePosition="bottom"
        position={position}
        matchWidth
        spacing="0"
        className="z-select">
        <div>
          <Input
            color={color}
            textColor={textColor}
            variant={variant}
            size={size}
            startIcon={startIcon}
            endIcon={endIcon}
            isLoading={isLoading}
            rounded={rounded && !open}
            focussed={open}
            ref={ref}
            name={name}
            value={searchValue}
            placeholder={placeholder}
            autocomplete={false}
            onChange={onChange}
            onFocus={() => {
              if (readOnly) return;
              setOpen(!open);
              onFocus();
            }}
            onBlur={() => {
              if (readOnly) return;
              setOpen(false);
              onBlur({ name: name ?? "", value, type: "search" });
            }}
            disabled={error != null || disabled}
            disableHover={disableHover}
            readOnly={readOnly}
          />
        </div>
      </RelativePortal>
    </div>
  );
});

export default Search;
