import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getCigFullPackageExperience, {
  CigFullPackageExperience,
} from "../getCigFullPackageExperience";

const useGetCigFullPackageExperience = (packageId?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CigFullPackageExperience | null>(null);

  const send = useCallback(async () => {
    if (packageId == null) return reqState;
    setLoading();
    try {
      const booking = await getCigFullPackageExperience(packageId);
      return setSuccess(booking);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [packageId, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [packageId, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetCigFullPackageExperience;
