import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const updateExperiencePackageValidators = {
  packageId: (validation: FormValidation, value: any) =>
    new Validator(validation, value)
      .skipIf(value == null)
      .exists()
      .is("string"),
  name: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string"),
  tagLine: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string"),
  extraDetails: (validation: FormValidation, value: any) =>
    new Validator(validation, value).is("string"),
  duration: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("number").number.greaterThan(0),
  tickets: (validation: FormValidation, value: any) =>
    new Validator(validation, value)
      .exists()
      .is("number")
      .number.greaterThan(0)
      .number.lessThanOrEqualTo(10),
  streamOption: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("boolean"),
  price: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("number").number.greaterThanOrEqualTo(10),
};

export default updateExperiencePackageValidators;
