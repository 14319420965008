import { api } from "@api/api";

export interface BannerPictureUploadUrl {
  key: string;
  url: string;
  processedImageURL: string;
}

const getBannerPictureUploadUrl = async () => {
  const { data } = await api.post<BannerPictureUploadUrl>("/getProfileBannerPictureUploadUrl");
  return data;
};

export default getBannerPictureUploadUrl;
