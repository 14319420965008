import useMouseIsOver from "@hooks/useMouseIsOver";
import useOnMouseLeave from "@hooks/useOnMouseLeave";
import usePrevious from "@hooks/usePrevious";
import { CSSProps, UIColor } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { IoStar, IoStarOutline } from "react-icons/io5";
import { FormElement } from "./Form";

interface Props extends CSSProps {
  color?: UIColor;
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  name?: string;
  value: number;
  maxStars?: number;
  disabled?: boolean;

  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
}

export type RatingSelectorProps = Props;

const RatingSelector = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    color = "purple",
    size = "medium",
    name,
    value,
    maxStars = 5,
    disabled,

    onChange = () => {},
    onBlur = () => {},

    className = "",
    style,
    id,
  } = props;

  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  return (
    <div
      tabIndex={0}
      className={classNames("rating-selector", color, size, className)}
      style={style}
      id={id}
      ref={ref}>
      {new Array(maxStars).fill(null).map((_, _i) => {
        const i = _i + 1;

        const selected = value >= i;
        const hover = hoverIndex != null ? hoverIndex >= i : null;

        return (
          <StarButton
            key={i}
            selected={hover == null ? selected : hover}
            disabled={disabled}
            onClick={() => {
              const eventData = {
                name: name ?? "",
                value: value === i ? 0 : i,
                type: "rating-select",
              };
              onChange(eventData);
              onBlur(eventData);
            }}
            onOver={isOver => setHoverIndex(isOver ? i : null)}
          />
        );
      })}
    </div>
  );
});

export default RatingSelector;

interface StarProps {
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onOver?: (isOver: boolean) => void;
}

const StarButton = (props: StarProps) => {
  const { selected, disabled, onClick = () => {}, onOver = () => {} } = props;

  const ref = useRef<HTMLButtonElement>(null);

  const [isOver, reset] = useMouseIsOver(ref);

  const isOverPrev = usePrevious(isOver);

  const [left, setLeft] = useState(true);

  useEffect(() => {
    if (!left) onOver(false);
    if (isOver === isOverPrev) return;
    onOver(isOver);
  }, [isOver, isOverPrev, left, onOver]);

  useOnMouseLeave(ref, () => setLeft(true));

  return (
    <button
      type="button"
      ref={ref}
      className={classNames("star-button", selected && "selected")}
      onTouchEnd={event => {
        event.stopPropagation();
        event.preventDefault();
        reset();
        onClick();
      }}
      onClick={() => {
        setLeft(!selected);
        onClick();
      }}
      disabled={disabled}>
      {selected ? <IoStar /> : <IoStarOutline />}
    </button>
  );
};
