import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import ToggleSwitch from "@components/form/ToggleSwitch";
import React from "react";
import ExperiencesManageLabel from "../../../ExperiencesManageLabel";
import useUpdateExperiencePackageContext from "../context/useUpdateExperiencePackageContext";

const extraDetailsExample = `Extras (optional):

Examples:
• Twitter/Instagram/Twitch follow
• Discord friend request
• Private Discord access
• A tagged tweet announcing the session
• On stream shoutout
• TikTok / Instagram collab and tag
• YouTube feature
• Discount code on merchandise
• Steam signature
• Twitch channel points`;

export default function Extras() {
  const { values, validations, onChange, onBlur } = useUpdateExperiencePackageContext();

  return (
    <section className="form-section">
      <FormRow>
        <FormEntry
          label={
            <ExperiencesManageLabel
              label="Livestreamed"
              subLabel="Does this bundle include livestreaming the session?"
            />
          }
          validation={validations.streamOption}
          relativePosition="top">
          <div className="toggle-switch-outer">
            <ToggleSwitch
              size="small"
              color="black-1"
              thumbColor="black-4"
              textColor="white"
              selectedColor="purple"
              selectedThumbColor="black-2"
              name="streamOption"
              label="Session is livestreamed"
              value={values.streamOption}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label={
            <ExperiencesManageLabel
              label="Other benefits"
              subLabel="List any additional extras you would like to include."
            />
          }
          validation={validations.extraDetails}
          relativePosition="top"
          className="extra-details-entry">
          <Input
            size="large"
            color="black-3"
            textColor="gray-3"
            name="extraDetails"
            placeholder={extraDetailsExample}
            multiline
            value={values.extraDetails}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormEntry>
      </FormRow>
    </section>
  );
}
