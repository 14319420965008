import { AuthModalData } from "./useAuthModal";
import useGlobalContext from "./useGlobalContext";

const useAuthModalData = <D>() => {
  const { authModal } = useGlobalContext();

  return authModal.data as AuthModalData<D>;
};

export default useAuthModalData;
