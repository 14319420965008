import { api } from "@api/api";

interface SetReadNotificationsRes {}

const setReadNotifications = async () => {
  const { data } = await api.post<SetReadNotificationsRes>("/setReadNotifications");
  return data;
};

export default setReadNotifications;
