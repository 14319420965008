import { ConsultancyPackageType } from "@api/public/misc/registerConsultancyInterest";
import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const consultancySignUpValidators = {
  twitchUsername: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string"),
  package: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string").enum.within(ConsultancyPackageType),
};

export default consultancySignUpValidators;
