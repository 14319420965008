import { useCallback, useEffect, useState } from "react";

const useWindowScroll = () => {
  const [scrollY, setScrollTop] = useState(window.scrollY);
  const [scrollX, setScrollLeft] = useState(window.scrollX);

  const onScroll = useCallback(() => {
    setScrollTop(window.scrollY);
    setScrollLeft(window.scrollX);
  }, [setScrollTop, setScrollLeft]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  return { scrollY, scrollX };
};

export default useWindowScroll;
