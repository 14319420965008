import Avatar from "@components/common/Avatar";
import DataCheck from "@components/common/DataCheck";
import Divider from "@components/common/Divider";
import { Currency } from "@lib/enums/generic";
import { numberToCurrency } from "@lib/utils/generic";
import dayjs from "dayjs";
import React from "react";

interface Props {
  img?: string;
  gameName?: string;
  cigName?: string;
  slotsPurchased?: number;
  startDate?: string;
  endDate?: string;
  currency?: Currency;
  slotCost?: number;
  totalCost?: number;
  free?: boolean;
  isLoading?: boolean;
}

export default function Details(props: Props) {
  const {
    img,
    gameName,
    cigName,
    slotsPurchased = 1,
    startDate,
    endDate,
    currency,
    slotCost,
    totalCost,
    free,
    isLoading,
  } = props;

  return (
    <div className="details">
      <DataCheck isLoading={isLoading} loadingIndicator="gloss">
        <div className="group">
          <div className="row">
            <div className="no-grow">
              <Avatar src={img} alt={cigName} size={128} variant="rounded" />
            </div>
            <div className="top-details">
              <div>
                <p>
                  <span className="game-name">{gameName}</span>
                  <br />
                  <span className="text-purple-4">with {cigName}</span>
                </p>
              </div>

              <div>
                <p>
                  <span className="label">SLOTS: </span> {slotsPurchased}
                </p>
                <p>
                  <span className="label">TYPE: </span> {slotsPurchased > 1 ? "Group" : "Single"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <Divider color="white" />

        <div className="group">
          <div className="row">
            <p className="label">START DATE</p>
            <p>{dayjs(startDate).format("MMMM DD YYYY HH:mm")}</p>
          </div>

          <div className="row">
            <p className="label">END DATE</p>
            <p>{dayjs(endDate).format("MMMM DD YYYY HH:mm")}</p>
          </div>
        </div>

        <Divider color="white" />

        <div className="group">
          <div className="row">
            <p className="label">SLOT COST</p>
            <p className="text-purple-5">
              {free ? "FREE" : numberToCurrency(slotCost ?? 0, currency)}
            </p>
          </div>
          <div className="row">
            <p className="label">SUBTOTAL (x{slotsPurchased} slots)</p>
            <p className="text-purple-5">
              {free ? "FREE" : numberToCurrency(totalCost ?? 0, currency)}
            </p>
          </div>
        </div>

        <Divider color="white" />

        <div className="group">
          <div className="row">
            <p className="label large text-gray-3">TOTAL COST</p>
            <p className="text-purple-5 large">
              {free ? "FREE" : numberToCurrency(totalCost ?? 0, currency)}
            </p>
          </div>
        </div>
      </DataCheck>
    </div>
  );
}
