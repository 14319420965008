import Button from "@components/form/Button";
import SplitViewsSplash from "@components/splash/sections/SplitViewsSplash";
import SplashText from "@components/splash/SplashText";
import { IoArrowForward } from "react-icons/io5";

import workWithUsImg from "@images/landing/flank/splitViews/workWithUs.png";
import earnMoreImg from "@images/landing/flank/splitViews/earnMore.png";
import workWithBrandsImg from "@images/landing/flank/splitViews/workWithBrands.png";
import flankBannerImg from "@images/landing/flank/splitViews/flankBanner.png";

interface Props {
  onSignUpClick: () => void;
}

const SplitViews = (props: Props) => {
  const { onSignUpClick } = props;

  return (
    <SplitViewsSplash
      className="no-padding"
      views={[
        {
          title: "WHY WORK WITH US?",
          content: (
            <SplashText>
              Agencies take a big cut of the money you work hard for. We don't think that's right.
              <br />
              <br />
              With Flank, <span className="gradient">you keep 100% of the money!</span>
              <br />
              <br />
              We're different to the normal business model. We charge brands an additional fee for
              using our platform.
              <br />
              <br />
              You built your stream from the ground up so you should receive the income and exposure
              from it.
            </SplashText>
          ),
          img: workWithUsImg,
          imgAlt: "Work with us",
          buttons: (
            <>
              <Button
                color="peach-2"
                textColor="black-1"
                endIcon={<IoArrowForward />}
                onClick={onSignUpClick}>
                Sign Up
              </Button>
            </>
          ),
        },
        {
          title: "EARN MORE BY KEEPING MORE",
          content: (
            <SplashText>
              We throw away the complicated back and forth between brands, agencies, and creators by
              connecting brands directly with Streamers -{" "}
              <span className="gradient">cutting out the middleman</span> and distributing earnings
              directly to you.
            </SplashText>
          ),
          img: earnMoreImg,
          imgAlt: "Earn more",
          reverse: true,
        },
        {
          title: "WORK WITH BRANDS YOU LIKE",
          content: (
            <SplashText>
              We accept <span className="gradient">Twitch Partners & top Affiliates</span> with all
              interests and backgrounds. No matter what your preferred content style is, there's a
              brand out there for you that's a perfect collab.
            </SplashText>
          ),
          img: workWithBrandsImg,
          imgAlt: "Work with brands",
        },
        {
          title: "ALPHA LAUNCH",
          content: (
            <SplashText>
              We are building a platform that is focused on providing the{" "}
              <span className="gradient">best value to Streamers</span> and brands. Sign up now to
              get first access to the Alpha release and have your say in future development.
            </SplashText>
          ),
          img: flankBannerImg,
          imgAlt: "Flank banner",
          reverse: true,
          buttons: (
            <>
              <Button
                color="peach-2"
                textColor="black-1"
                endIcon={<IoArrowForward />}
                onClick={onSignUpClick}>
                Sign Up
              </Button>
            </>
          ),
        },
      ]}
    />
  );
};

export default SplitViews;
