import { forwardRef } from "react";
import NumberInput, { NumberInputProps } from "./NumberInput";

interface Props extends NumberInputProps {}

export type PercentInputProps = Props;

const PercentInput = forwardRef<(HTMLInputElement & HTMLTextAreaElement) | null, Props>(
  (props, ref) => {
    const { min = 0, max = 100, ...numberInputProps } = props;

    return <NumberInput ref={ref} min={min} max={max} endIcon={<p>%</p>} {...numberInputProps} />;
  },
);

export default PercentInput;
