import Button from "@components/form/Button";
import IconButton from "@components/form/IconButton";
import Menu from "@components/form/Menu";
import React from "react";
import { IoPeople } from "react-icons/io5";
import useRoomContext from "../roomContext/hooks/useRoomContext";

export default function ParticipantSwitchButton() {
  const { activeParticipants, mainParticipant, setMainParticipantId, participantOptions } =
    useRoomContext();

  const noParticipants = activeParticipants.length <= 1;

  if (noParticipants) return null;

  return (
    <Menu
      relativePosition="bottom left"
      variant="contained"
      color="black-2"
      content={
        <>
          {participantOptions.map(({ value, label }, i) => {
            const active = mainParticipant?.identity === value;
            return (
              <Button
                key={i}
                size="small"
                variant={active ? "contained" : "flat"}
                color="purple"
                textColor="white"
                active={active}
                onClick={() => typeof value === "string" && setMainParticipantId(value)}>
                {label}
              </Button>
            );
          })}
        </>
      }>
      <IconButton size="small" color="white" iconColor="black" rounded icon={<IoPeople />} />
    </Menu>
  );
}
