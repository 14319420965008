import Button from "@components/form/Button";
import Form from "@components/form/Form";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import useFormManager from "@components/form/hooks/useFormManager";
import dayjs from "dayjs";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import ActionPanel from "../../ActionPanel";
import useBookSessionHandles from "./hooks/useBookSessionHandles";
import DataCheck from "@components/common/DataCheck";
import { numberToCurrency, pluralise } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useRedirectToLogin from "@hooks/useRedirectToLogin";
import { useLocation } from "react-router-dom";
import useGetCompatibility from "@api/public/get/hooks/useGetCompatibility";
import TicketBook from "@components/tickets/TicketBook";
import IncrementPicker from "@components/form/IncrementPicker";
import HoverTooltip from "@components/common/HoverTooltip";
import { IoCart } from "react-icons/io5";
import FreeSessionModal from "./FreeSessionModal";
import useCigProfileContext from "@pages/cigProfile/context/hooks/useCigProfileContext";
import { Session } from "@lib/types/session";

interface Props {
  session?: Session | null;
  sessionIndex?: number;
  onClose: () => void;
}

const initialValues = {
  slots: 1,
};

export type BookSessionValues = typeof initialValues;

const BookSession = forwardRef<HTMLDivElement, Props>((props, _ref) => {
  const { getIsLoading, getError, cigProfile } = useCigProfileContext();

  const { session, sessionIndex, onClose } = props;

  const {
    id,
    currency,
    price,
    discount = 0,
    discountedPrice,

    free,

    platform,
    game,

    from,
    to,
    slotsAvailable,

    title,
    extraInfo,

    charity,
    charityName,
  } = session ?? {};

  const { breakpoints, user } = useGlobalContext();

  const redirect = useRedirectToLogin();
  const location = useLocation();

  const [isGuest, setIsGuest] = useState(false);
  const [freeSessionModalActive, setFreeSessionModelActive] = useState(false);

  const {
    data: compatibility,
    error: compatibilityError,
    isLoading: compatibilityIsLoading,
  } = useGetCompatibility(platform?.id, game?.id, true);

  const compatiblePlatforms = compatibility?.compatiblePlatforms.items ?? [];

  const formManager = useFormManager(initialValues, "bookSession");
  const { values, validations, onChange, onBlur, resetValidations, setValues } = formManager;

  const [handle, guestHandle, bookIsLoading, guestBookIsLoading] = useBookSessionHandles(
    formManager,
    session,
    cigProfile?.displayName,
    sessionIndex,
  );

  const resetValues = useCallback(() => {
    setValues({ slots: 1 });
  }, [setValues]);

  const isInPast = dayjs(from).isBefore(dayjs());

  const notLoggedIn = user == null;

  const isLoading = getIsLoading || compatibilityIsLoading;
  const error = getError ?? compatibilityError;

  const priceString = `(${
    free
      ? "FREE"
      : discountedPrice != null
      ? numberToCurrency(discountedPrice * values.slots, currency)
      : "N/A"
  })`;

  useEffect(() => {
    if (free) onChange({ name: "slots", value: 1 });
    if (id) onChange({ name: "sessionId", value: id });
    // eslint-disable-next-line
  }, [free, id]);
  // must omit onChange to prevent loop

  const children = (
    <>
      <TicketBook
        size={breakpoints.xs ? "small" : "medium"}
        isLoading={isLoading}
        slotsAvailable={slotsAvailable}
        cigName={cigProfile?.displayName}
        img={cigProfile?.profilePictureURL}
        title={title || undefined}
        extraInfo={extraInfo ?? undefined}
        gameName={game?.shortName}
        startDate={from}
        endDate={to}
        charity={charity}
        charityName={charityName}
        free={free}
        compatiblePlatforms={compatiblePlatforms}
        compatibilityIsLoading={compatibilityIsLoading}
        compatibilityError={compatibilityError}
        tabColor="black-3"
      />
    </>
  );

  const bookButton = (
    <HoverTooltip
      killTooltip={!isInPast && slotsAvailable !== 0}
      content={<p>Session is no longer available</p>}>
      <div className="flex-column">
        <Button
          color={notLoggedIn ? "black-3" : "purple"}
          textColor="white"
          type="submit"
          form="book-session-form"
          onClick={notLoggedIn ? () => redirect(`${location.pathname}/${id}`) : undefined}
          isLoading={bookIsLoading}
          disabled={isInPast || slotsAvailable === 0}
          startIcon={!notLoggedIn ? <IoCart /> : undefined}
          onMouseDown={() => setIsGuest(false)}
          justifyContent="center">
          Secure {pluralise("Ticket", values.slots)} {!notLoggedIn && priceString}
        </Button>
      </div>
    </HoverTooltip>
  );

  const guestBookButton = (
    <HoverTooltip
      killTooltip={!isInPast && slotsAvailable !== 0}
      content={<p>Session is no longer available</p>}>
      <div className="flex-column">
        <Button
          color={!notLoggedIn ? "black-3" : "purple"}
          textColor="white"
          type="submit"
          form="book-session-form"
          isLoading={guestBookIsLoading}
          disabled={isInPast || slotsAvailable === 0}
          startIcon={notLoggedIn ? <IoCart /> : undefined}
          onMouseDown={() => setIsGuest(true)}
          justifyContent="center">
          Guest Checkout {notLoggedIn && priceString}
        </Button>
      </div>
    </HoverTooltip>
  );

  return (
    <>
      <ActionPanel
        active={!!session}
        onClose={() => {
          onClose();
          resetValidations();
          resetValues();
        }}
        preventKill
        className="book-session-panel"
        modalClassName="book-session-panel-modal"
        titleOverride={
          breakpoints.xl ? (
            <h3 className="title">{title || "Unnamed"}</h3>
          ) : (
            <h3 className="title">
              <span>Session Details</span>
              <br />
              <span>{title}</span>
            </h3>
          )
        }
        prompt="Click a session to book"
        buttons={
          <>
            <FormRow>
              <HoverTooltip
                killTooltip={!isInPast && slotsAvailable !== 0 && !free}
                content={
                  isInPast || slotsAvailable === 0 ? (
                    <p>Session is no longer available</p>
                  ) : (
                    <p>You can only take one ticket from free sessions</p>
                  )
                }>
                <FormEntry validation={validations.slots} showValidationBeneath required>
                  <IncrementPicker
                    name="slots"
                    size="medium"
                    variant="outlined"
                    color="gray-3"
                    textColor="white"
                    iconColor="white"
                    value={values.slots}
                    valueSuffix={pluralise("Ticket", values.slots)}
                    onBlur={onBlur}
                    backDisabled={!slotsAvailable || values.slots <= 1}
                    forwardDisabled={!slotsAvailable || values.slots >= slotsAvailable}
                    onBack={() => onChange({ name: "slots", value: values.slots - 1 })}
                    onForward={() => onChange({ name: "slots", value: values.slots + 1 })}
                    disabled={isInPast || slotsAvailable === 0 || free}
                  />
                </FormEntry>
              </HoverTooltip>
            </FormRow>

            {notLoggedIn ? (
              <>
                {guestBookButton}
                {bookButton}
              </>
            ) : (
              <>{bookButton}</>
            )}

            {price != null && discount ? (
              <p className="discount">
                <span>
                  <span className="price">
                    was {numberToCurrency(price * values.slots, currency)}
                  </span>{" "}
                  (-
                  {discount}%)
                </span>
              </p>
            ) : null}
          </>
        }>
        <DataCheck error={error}>
          <Form
            id="book-session-form"
            steps={[
              {
                children,
                handle: isGuest
                  ? free
                    ? () => setFreeSessionModelActive(true)
                    : guestHandle
                  : handle,
              },
            ]}
            resetValidations={resetValidations}
            validation={validations.generic}
          />
        </DataCheck>
      </ActionPanel>

      <FreeSessionModal
        active={freeSessionModalActive}
        onClose={() => setFreeSessionModelActive(false)}
        onCompletion={email => {
          guestHandle(email);
        }}
      />
    </>
  );
});

export default BookSession;
