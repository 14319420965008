import React, { forwardRef, ReactNode, useState } from "react";
import { useDrag } from "react-use-gesture";
import { CSSProps, UIColor } from "@lib/types/generic";
import TicketTop from "./TicketTop";
import TicketBottom from "./TicketBottom";
import DataCheck from "@components/common/DataCheck";
import Divider from "@components/common/Divider";

interface Props extends CSSProps {
  isLoading?: boolean;
  size?: "small" | "medium" | "large";
  tearable?: boolean;
  torn?: boolean;

  img?: string;
  tabColor?: UIColor;

  topContent?: ReactNode;
  bottomContent?: ReactNode;

  onClick?: () => void;
}

export type TicketProps = Props;

const Ticket = forwardRef<HTMLDivElement, TicketProps>((props, ref) => {
  const {
    isLoading,
    size = "medium",
    tearable,
    torn = false,

    img,
    tabColor,

    topContent,
    bottomContent,

    className = "",
    id,
    style,

    onClick,
  } = props;

  const [dragging, setDragging] = useState(false);
  const [ripped, setRipped] = useState(false);

  const bind = useDrag(({ down, movement: [_, my] }) => {
    if (!tearable) return;
    setDragging(down);
    if (down && my > 25) setRipped(true);
    else if (down && my < -25) setRipped(false);
  });

  return (
    <div
      className={`ticket ${size} ${ripped || torn ? "torn" : ""} ${
        isLoading ? "loading" : ""
      } ${className}`}
      style={style}
      id={id}
      ref={ref}
      onClick={onClick}>
      <div className="top">
        <TicketTop img={img}>
          <div className="top-content">
            <DataCheck isLoading={isLoading} imgs={[img]} loadingIndicator="gloss">
              {topContent}
            </DataCheck>

            <Divider color="white" variant="dotted" />
          </div>
        </TicketTop>
      </div>
      <div
        className={`bottom ${tearable ? "tearable" : ""} ${dragging ? "dragging" : ""}`}
        {...bind()}>
        <TicketBottom bgColor={tabColor}>
          <div className="bottom-content">
            <Divider color="white" variant="dotted" />

            <DataCheck isLoading={isLoading} loadingIndicator="gloss">
              {bottomContent}
            </DataCheck>
          </div>
        </TicketBottom>
      </div>
    </div>
  );
});

export default Ticket;
