import { SiFacebook, SiGoogle } from "react-icons/si";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import Button from "../form/Button";
import GAEvent from "@lib/utils/GAEvent";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useQuery from "@hooks/useQuery";
import HoverTooltip from "./HoverTooltip";

interface Props {
  disabled?: boolean;
}

export default function AltAuthBar(props: Props) {
  const { disabled } = props;

  const { signIn, breakpoints } = useGlobalContext();

  const redirect = useQuery("redirect");
  const cigCategory = useQuery("cig");

  const redirectUrl = cigCategory ? `/cig/register?cig=${cigCategory}` : redirect;

  const onAuth = (name: string, provider: CognitoHostedUIIdentityProvider) => {
    GAEvent.login(name);
    signIn({
      provider,
      customState: JSON.stringify({ redirect: redirectUrl }),
    });
  };

  const buttonSize = breakpoints.sm ? "small" : "medium";

  return (
    <div className="alt-auth-bar">
      <HoverTooltip content={<p>Log in with Google</p>}>
        <Button
          color="google-red"
          textColor="white"
          size={buttonSize}
          onClick={() => onAuth("Google", CognitoHostedUIIdentityProvider.Google)}
          disabled={disabled}>
          <SiGoogle />
        </Button>
      </HoverTooltip>
      <HoverTooltip content={<p>Log in with Facebook</p>}>
        <Button
          color="facebook-blue"
          textColor="white"
          size={buttonSize}
          onClick={() => onAuth("Facebook", CognitoHostedUIIdentityProvider.Facebook)}
          disabled={disabled}>
          <SiFacebook />
        </Button>
      </HoverTooltip>
    </div>
  );
}
