import Message from "./Message";
import MessageBox from "./MessageBox";
import dayjs from "dayjs";
import { useEffect, useLayoutEffect, useRef } from "react";
import { IoChatbox } from "react-icons/io5";
import IconButton from "@components/form/IconButton";
import { isEventMessage } from "./objects/EventMessage";
import EventMessage from "./EventMessage";
import screenfull from "screenfull";
import useRoomContext from "../../roomContext/hooks/useRoomContext";
import useScrollToBottom from "@hooks/useScrollToBottom";
import { classNames } from "@lib/utils/generic";

interface Props {
  visible?: boolean;
  drawerJustOpened?: boolean;
}

export default function ChatTab(props: Props) {
  const { visible, drawerJustOpened } = props;

  const { messages, unreadMessages, setReadAllMessages } = useRoomContext();

  const ref = useRef<HTMLDivElement>(null);

  const isFullscreen = screenfull.element != null;

  const scrollToBottom = useScrollToBottom(ref);
  useLayoutEffect(() => {
    if (drawerJustOpened) scrollToBottom();
  }, [drawerJustOpened, visible, scrollToBottom]);
  useLayoutEffect(() => scrollToBottom(), [messages.length, scrollToBottom]);

  useEffect(() => {
    if (unreadMessages.length > 0 && visible) setReadAllMessages();
  }, [unreadMessages, visible, setReadAllMessages]);

  return (
    <div className={classNames("tab-content", "chat-tab", visible && "visible")}>
      <div ref={ref} className="messages">
        {messages.map((message, i) => {
          if (isEventMessage(message)) {
            const { body, icon } = message;
            return <EventMessage key={i} bottomText={body} icon={icon} />;
          } else {
            const { body, author, dateCreated } = message;
            return (
              <Message
                key={i}
                body={body}
                authorIdentity={author}
                dateCreated={dayjs(dateCreated)}
              />
            );
          }
        })}
        {messages.length === 0 && (
          <div className="message event">
            <IconButton
              variant="flat"
              color="white"
              size="small"
              icon={<IoChatbox />}
              disableHover
            />
            <div className="text">
              <p>There's no messages yet</p>
              <p>Be the first!</p>
            </div>
          </div>
        )}
      </div>
      {!isFullscreen && <MessageBox />}
    </div>
  );
}
