import { api } from "@api/api";
import { CIGProfile } from "@api/public/get/getCigProfile";

export type UpdateCIGProfile = Partial<Omit<CIGProfile, "id">>;

const updateCIGProfile = async (cigProfile: UpdateCIGProfile) => {
  const { data } = await api.post<CIGProfile>("/updateCIGProfile", cigProfile);
  return data;
};

export default updateCIGProfile;
