import { forwardRef, ReactElement, useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { CSSProps, JustifyContent, UIColor } from "../../lib/types/generic";
import { FormElement } from "./Form";
import useCombinedRefs from "@hooks/useCombinedRefs";
import { classNames } from "@lib/utils/generic";
import Button from "./Button";
import { IoChevronDownOutline } from "react-icons/io5";
import Options, { Option } from "./Options";
import RelativePortal from "@components/common/RelativePortal";
import { Position } from "@hooks/useRelativeCoordsAndSize";

interface Props extends CSSProps {
  position?: Position;
  color?: UIColor;
  textColor?: UIColor;
  optionColor?: UIColor;
  optionTextColor?: UIColor;
  variant?: "contained" | "outlined" | "transparent" | "flat";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  justifyContent?: JustifyContent;
  name?: string;
  options?: Option[];
  useEmptyOption?: boolean;
  emptyOptionProps?: Partial<Option>;
  disabled?: boolean;
  disableHover?: boolean;
  isLoading?: boolean;
  rounded?: boolean;
  error?: string;

  buttonClassName?: string;

  value?: string | number;
  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
  onStateChange?: (open: boolean) => void;
}

export type SelectProps = Props;

const Select = forwardRef<HTMLButtonElement & HTMLAnchorElement, Props>((props, forwardedRef) => {
  const {
    position = "absolute",
    color = "white",
    textColor = "black",
    optionColor = color,
    optionTextColor = textColor,
    variant = "contained",
    size = "medium",
    startIcon,
    endIcon = <IoChevronDownOutline className="chevron" />,
    justifyContent = "space-between",
    name,
    useEmptyOption,
    emptyOptionProps,
    disabled,
    disableHover,
    isLoading,
    rounded,
    error,

    buttonClassName,

    value,
    onChange = () => {},
    onBlur = () => {},
    onStateChange = () => {},

    className = "",
    id,
    style,
  } = props;

  const options = useMemo(() => {
    if (useEmptyOption)
      return [{ value: "", label: "None", ...emptyOptionProps }, ...(props.options ?? [])];
    return props.options ?? [];
  }, [useEmptyOption, emptyOptionProps, props.options]);

  const [open, setOpen] = useState(false);

  const buttonRef = useRef<HTMLButtonElement & HTMLAnchorElement>(null);
  const ref = useCombinedRefs<HTMLButtonElement & HTMLAnchorElement>(buttonRef, forwardedRef);
  const optionsRef = useRef<HTMLDivElement>(null);

  const optionHeight = optionsRef.current?.children.item(0)?.clientHeight ?? 0;
  let selectedIndex = options.findIndex(option => option.value === value) - 1;
  if (selectedIndex < 0) selectedIndex = 0;
  const selectedY = selectedIndex * optionHeight;

  useEffect(() => {
    const { current } = optionsRef;
    if (!current || !open) return;

    current.scrollTo({
      top: selectedY,
    });
  }, [open, selectedY]);

  useEffect(() => {
    onStateChange(open);
  }, [open, onStateChange]);

  useEffect(() => {
    if (options.length === 0 || value == null) return;
    if (options.find(option => option.value === value) == null) {
      onChange({ value: options[0].value, name: name ?? "", type: "select" });
    }
  }, [options, onChange, value, name]);

  const nodes = (
    <Options
      color={color}
      textColor={textColor}
      optionColor={optionColor}
      optionTextColor={optionTextColor}
      variant={variant}
      justifyContent={justifyContent}
      size={size}
      name={name}
      disabled={disabled}
      value={value}
      options={options}
      onSelect={({ value, ...target }) => {
        onChange({ ...target, value: value.value });
        setOpen(false);
      }}
    />
  );

  const label = options.find(option => option.value === value)?.label;

  const handleBlur = () => {
    onBlur({ name: name ?? "", value, type: "select" });
  };

  return (
    <div className={classNames("select-wrapper", className, position)} id={id} style={style}>
      <RelativePortal
        active={open}
        content={nodes}
        relativePosition="bottom"
        position={position}
        matchWidth
        spacing="0"
        className="z-select">
        <div>
          <Button
            color={color}
            textColor={textColor}
            variant={variant}
            size={size}
            startIcon={startIcon}
            endIcon={endIcon}
            isLoading={isLoading}
            justifyContent={justifyContent}
            rounded={rounded && !open}
            active={open}
            tabIndex={0}
            ref={ref}
            className={classNames(
              "select",
              color,
              variant,
              size,
              open ? "open" : "closed",
              buttonClassName,
            )}
            name={name}
            type="button"
            onClick={() => {
              ref.current?.focus();
              setOpen(!open);
            }}
            onBlur={() => {
              setOpen(false);
              handleBlur();
            }}
            disabled={error != null || disabled}
            disableHover={disableHover}>
            {label ?? error ?? "None"}
          </Button>
        </div>
      </RelativePortal>
    </div>
  );
});

export default Select;
