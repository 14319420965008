import Tabs, { Tab, TabsProps } from "@components/common/Tabs";
import { JustifyContent, UIColor } from "@lib/types/generic";
import { useState } from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { Group, Showcase } from "./StyleGuide";

const Tabss = () => {
  const sizes = ["extra-small", "small", "medium", "large", "extra-large"] as TabsProps["size"][];

  const [selectedTab, setSelectedTab] = useState<string | number>("tab-1");
  const [color, setColor] = useState<UIColor>("purple");
  const [textColor, setTextColor] = useState<UIColor>("white");
  const [indicatorColor, setIndicatorColor] = useState<UIColor>("white");
  const [indicatorTextColor, setIndicatorTextColor] = useState<UIColor>("purple");
  const [variant, setVariant] = useState<TabsProps["variant"]>("contained");
  const [justifyContent, setJustifyContent] = useState<JustifyContent>("space-between");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [rounded, setRounded] = useState<boolean>(false);

  const tabs: Tab[] = new Array(3).fill(null).map((_, i) => ({
    value: `tab-${i + 1}`,
    label: `Tab ${i + 1}`,
    startIcon: <IoArrowBack />,
    endIcon: <IoArrowForward />,
  }));

  const props = {
    tabs,
    selectedTab,
    onChange: (tab: string | number) => setSelectedTab(tab),
    color,
    textColor,
    indicatorColor,
    indicatorTextColor,
    variant,
    justifyContent,
    disabled,
    rounded,
  };

  const widths = {
    "extra-small": 100,
    small: 150,
    medium: 200,
    large: 300,
    "extra-large": 400,
  };

  return (
    <Showcase
      title="Tabs"
      className="tabss"
      colors={[
        {
          color: color,
          label: "Color",
          onChange: setColor,
        },
        {
          color: textColor,
          label: "Text Color",
          onChange: setTextColor,
        },
        {
          color: indicatorColor,
          label: "Indicator Color",
          onChange: setIndicatorColor,
        },
        {
          color: indicatorTextColor,
          label: "Indicator Text Color",
          onChange: setIndicatorTextColor,
        },
      ]}
      selects={[
        {
          value: variant ?? "contained",
          optionsArray: ["contained", "outlined", "flat", "transparent", "underlined"],
          label: "Variant",
          onChange: setVariant,
        },
        {
          value: justifyContent,
          optionsArray: ["space-between", "flex-start", "center", "flex-end"],
          label: "Justify",
          onChange: setJustifyContent,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
        {
          value: rounded,
          label: "Rounded",
          onChange: setRounded,
        },
      ]}>
      <div className="flex-column align-center">
        {sizes.map(size => (
          <Group key={size}>
            <Tabs size={size} {...props} tabWidth={size ? widths[size] : undefined} />
          </Group>
        ))}
      </div>
    </Showcase>
  );
};

export default Tabss;
