import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createClaim, { CreateClaimRes } from "../createClaim";

const useCreateClaim = (bookingID?: string, reason?: string, reasonClass?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CreateClaimRes | null>(
    null,
  );

  const send = useCallback(async () => {
    if (bookingID == null || reason == null || reasonClass == null) return reqState;
    setLoading();
    try {
      const data = await createClaim(bookingID, reason, reasonClass);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [bookingID, reason, reasonClass, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateClaim;
