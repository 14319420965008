import Accordion, { AccordionProps } from "@components/common/Accordion";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import { contactEmail } from "@lib/constants/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, { ReactNode } from "react";
import BecomeTalentOldHeader from "../BecomeTalentOldHeader";

export default function FAQ() {
  return (
    <section className="section faq">
      <BecomeTalentOldHeader
        title="Frequently asked questions"
        subTitle="A question you may. An answer we might. For you, my Liege..."
      />

      <div className="accordions">
        <Question
          title="How do I become Talent on Yakkr?"
          content={
            <>
              Click <a href={`mailto:${contactEmail}`}>here</a> and let's get you set up!
            </>
          }
          timeout={500}
        />

        <Question
          title="Do I need to be a Streamer?"
          content="No you don't! One of our big goals is to help Creators and Talent who aren't Streamers
            to get involved in the gaming space. You can use your phone, tablet or computer to use
            Yakkr - no need to download any complex software packages or own a £5K setup!"
          timeout={700}
        />

        <Question
          title="Do I have to charge for my sessions?"
          content="Nope, you don't have to charge for your sessions. You can run them for free if you wish! This is great for smaller Creators who are trying to build up their community and get to know their followers better."
          timeout={800}
        />

        <Question
          title="I'm an agent and have a number of Talent I want to sign up. What next?"
          content={
            <>
              Fire us an <a href={`mailto:${contactEmail}`}>email</a> and we can look at the best
              solution to getting your Talent on board. Depending on the number of Talent you want
              to sign up, there are a number of options we can discuss.
            </>
          }
          timeout={850}
        />

        <Question
          title="I already play with fans on Discord, what's the difference?"
          content="Yakkr allows those who aren't on Discord to still be involved. A lot of console users
            aren't Discord users, and even those who do have Discords don't actively use them. I
            barely keep up with my Whatsapp group chats, never mind all the Discord servers I'm in
            too! So chances are, a lot of people will miss your sessions. Yakkr helps keep all your
            followers in the loop without them having to join a billion different servers."
          timeout={875}
        />

        <Question
          title="Do I have to own a game to play it?"
          content="To play any game you do have to own it."
          timeout={875}
        />
      </div>
    </section>
  );
}

interface QuestionProps {
  title: string;
  content: ReactNode;
  timeout: number;
}

const Question = ({ title, content, timeout }: QuestionProps) => {
  const { breakpoints } = useGlobalContext();

  const accordionProps: AccordionProps = {
    color: "black-2",
    size: !breakpoints.ul
      ? "extra-large"
      : breakpoints.sm
      ? "small"
      : breakpoints.md
      ? "medium"
      : "large",
  };

  return (
    <SlideOnIntersect direction="right" timeout={timeout}>
      <div>
        <Accordion title={title} {...accordionProps}>
          {content}
        </Accordion>
      </div>
    </SlideOnIntersect>
  );
};
