import YakkrBanner from "@components/common/YakkrBanner";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

const SplashFooter = () => {
  const { breakpoints } = useGlobalContext();

  return (
    <footer className="splash-footer">
      <YakkrBanner size={breakpoints.sm ? "small" : breakpoints.sl ? "medium" : "large"} />
      <p className="copyright">@ Yakkr 2023</p>
    </footer>
  );
};

export default SplashFooter;
