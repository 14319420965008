import { CigCustomExperience } from "@api/public/get/getCigCustomExperience";
import { CigPackageExperience } from "@api/public/get/getCigPackageExperiences";
import { GamePartial, PlatformPartial } from "@api/public/get/getCompatibility";
import { Game } from "@api/public/get/getGames";
import { Platform } from "@api/public/get/getPlatforms";
import useGetGames from "@api/public/get/hooks/useGetGames";

const useRequestExperiencesGames = (
  customExperience: CigCustomExperience | undefined,
  customExperienceIsLoading: boolean,
  customExperienceError: string | undefined,
  packageExperience: CigPackageExperience | undefined,
  packageExperienceIsLoading: boolean,
  packageExperienceError: string | undefined,
  selectedGameId: string | undefined,
) => {
  const experience = customExperience ?? packageExperience;

  const anyGame = experience?.anyGame;

  const {
    data: allGames,
    isLoading: allGamesIsLoading,
    error: allGamesError,
  } = useGetGames(anyGame);

  let games = [] as Array<Game | GamePartial>;
  let platforms = [] as Array<{
    id: string;
    platform: Platform | PlatformPartial;
  }>;

  if (anyGame) {
    games = allGames;
    const game = allGames.find(({ id }) => selectedGameId === id);

    platforms = game?.platforms.items ?? [];
  } else {
    const compatibilities = experience?.games ?? [];
    games = compatibilities.map(({ game }) => game);
    const compatibility = compatibilities.find(({ game: { id } }) => selectedGameId === id);

    platforms = compatibility?.compatiblePlatforms.items ?? [];
  }

  const gamesIsLoading =
    customExperienceIsLoading || packageExperienceIsLoading || allGamesIsLoading;
  const gamesError = customExperienceError ?? packageExperienceError ?? allGamesError;

  return {
    games,
    platforms: platforms.map(({ platform }) => platform),
    gamesIsLoading,
    gamesError,
  };
};

export default useRequestExperiencesGames;
