import { UIColor } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { HTMLProps } from "react";
import { Link } from "react-router-dom";

interface Props {
  color?: UIColor;
  size?: "extra-small" | "small" | "medium" | "large";
  svgProps?: HTMLProps<SVGElement>;
  to?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export default function Logo(props: Props) {
  const { color = "white", size = "medium", svgProps = {}, to, disabled, onClick } = props;

  const width = {
    "extra-small": 10,
    small: 14,
    medium: 24,
    large: 32,
    "extra-large": 48,
  }[size];

  const nodes = (
    <svg
      fill="currentColor"
      width={width}
      viewBox="0 0 300 337"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick || to}
      className={classNames(
        "yakkr-logo",
        color,
        disabled && "disabled",
        (onClick || to) && "clickable",
      )}
      {...(svgProps as any)}>
      <path
        d="M35.451 0H109.172C121.754 0 133.39 6.67829 139.736 17.5418L200.939 122.303C213.679 144.11 200.138 171.861 175.11 175.239L106.85 184.452C92.6923 186.363 78.7736 179.576 71.5607 167.244L4.89628 53.2692C-8.90622 29.6714 8.11299 0 35.451 0Z"
        fillOpacity="0.5"
      />
      <path d="M159.97 17.6621C166.299 6.73073 177.973 0 190.604 0H264.549C291.817 0 308.846 29.5347 295.183 53.133L141.483 318.615C135.155 329.546 123.481 336.277 110.849 336.277H36.9039C9.63599 336.277 -7.39228 306.742 6.26992 283.144L159.97 17.6621Z" />
    </svg>
  );

  if (to == null) return nodes;

  return (
    <Link to={to} onClick={onClick} className={classNames(disabled && "disabled no-visual")}>
      {nodes}
    </Link>
  );
}
