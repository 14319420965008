import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import { cloneElement, PropsWithChildren, ReactElement } from "react";

interface Props extends CSSProps {
  icon: ReactElement;
  title: string;
}

const FeatureBox = (props: PropsWithChildren<Props>) => {
  const { icon, title, children, className, ...cssProps } = props;

  return (
    <div className={classNames("feature-box", className)} {...cssProps}>
      <div className="feature-box-inner">
        <div className="feature-box-top">
          {cloneElement(icon, {
            className: "feature-box-icon",
          })}
          <h4 className="feature-box-title">{title}</h4>
        </div>
        <div className="feature-box-content">{children}</div>
      </div>
    </div>
  );
};

export default FeatureBox;
