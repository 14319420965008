import React from "react";
import { CSSProps, UIColor } from "../../lib/types/generic";

interface Props extends CSSProps {
  color?: UIColor;
}

export default function Square(props: Props) {
  const { color = "purple-4", className = "", style, id } = props;

  return (
    <svg
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`square ${className}`}
      style={style}
      id={id}>
      <rect x="0.723633" y="0.644531" width="52" height="52" className={`fill-${color}`} />
    </svg>
  );
}
