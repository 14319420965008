import { CigFullPackageExperience } from "@api/private/get/getCigFullPackageExperience";
import useGetCigFullPackageExperience from "@api/private/get/hooks/useGetCigFullPackageExperience";
import { Game } from "@api/public/get/getGames";
import useGetGames from "@api/public/get/hooks/useGetGames";
import {
  FormValidations,
  OnFormBlur,
  OnFormChange,
  ResetValidations,
} from "@components/form/hooks/useFormManager";
import React, { createContext, PropsWithChildren, useEffect } from "react";
import { useParams } from "react-router-dom";
import useExperiencePackageUpdate, {
  UpdateExperiencePackageValues,
} from "../hooks/useExperiencePackageUpdate";
import useLongestGame from "../hooks/useLongestGame";

interface UpdateExperiencePackageContextType {
  cigId: string;
  packageId?: string;

  values: UpdateExperiencePackageValues;
  validations: FormValidations<UpdateExperiencePackageValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;

  updateIsLoading: boolean;

  update: () => Promise<void>;
  resetValidations: ResetValidations;

  fullPackageExperience: CigFullPackageExperience | null;
  fullPackageExperienceIsLoading: boolean;
  fullPackageExperienceError?: string;

  games: Game[];
  gamesIsLoading: boolean;
  gamesError?: string;

  longestGame: Game | null;
}

export const UpdateExperiencePackageContext = createContext<UpdateExperiencePackageContextType>(
  {} as UpdateExperiencePackageContextType,
);

export default function CIGProfileContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const { cigId, packageId } = useParams<Params>();

  const {
    data: fullPackageExperience,
    isLoading: fullPackageExperienceIsLoading,
    error: fullPackageExperienceError,
  } = useGetCigFullPackageExperience(packageId, true);

  const { data: games, isLoading: gamesIsLoading, error: gamesError } = useGetGames(true);

  const {
    values,
    validations,

    onChange,
    onBlur,

    updateIsLoading,

    update,
    resetValidations,
    setValues,
  } = useExperiencePackageUpdate(packageId);

  const longestGame = useLongestGame(games, values.gamePlatformSelections);

  useEffect(() => {
    if (!fullPackageExperience) return;

    setValues(values => ({ ...values, ...fullPackageExperience }));
  }, [fullPackageExperience, setValues]);

  if (cigId === "my-profile") return null;

  return (
    <UpdateExperiencePackageContext.Provider
      value={{
        cigId,
        packageId,

        values,
        validations,

        onChange,
        onBlur,

        updateIsLoading,

        update,
        resetValidations,

        fullPackageExperience,
        fullPackageExperienceIsLoading,
        fullPackageExperienceError,

        games,
        gamesIsLoading,
        gamesError,

        longestGame,
      }}>
      {children}
    </UpdateExperiencePackageContext.Provider>
  );
}

interface Params {
  cigId: string;
  packageId?: string;
}
