import { api } from "@api/api";

interface CustomerCancelExperienceRequestRes {}

const cancelCustomerExperienceRequest = async (requestSessionID: string) => {
  const { data } = await api.post<CustomerCancelExperienceRequestRes>(
    "/customerCancelPendingRequestSession",
    {
      requestSessionID,
    },
  );
  return data;
};

export default cancelCustomerExperienceRequest;
