import useCombinedRefs from "@hooks/useCombinedRefs";
import { TransitionEvents } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  useRef,
} from "react";
import { CSSTransition } from "react-transition-group";

interface Props extends TransitionEvents {
  in: boolean;
  appear?: boolean;
  properties?: string;
  timeout?: number;
  easing?: string;
  unmountOnExit?: boolean;
  mountOnEnter?: boolean;
}

const Fade = forwardRef<HTMLElement, PropsWithChildren<Props>>((props, forwardedRef) => {
  const {
    in: _in,
    appear,
    properties = "opacity",
    timeout = 300,
    easing = "ease",

    unmountOnExit = true,
    mountOnEnter = true,

    children,

    ...events
  } = props;

  const nodeRef = useRef<HTMLElement>(null);
  const ref = useCombinedRefs(nodeRef, forwardedRef);

  return (
    <CSSTransition
      classNames="fade"
      in={_in}
      appear={appear}
      timeout={timeout}
      unmountOnExit={unmountOnExit}
      mountOnEnter={mountOnEnter}
      nodeRef={ref}
      {...events}>
      {isValidElement(children) &&
        cloneElement(Children.only(children) as any, {
          style: {
            transitionDuration: `${timeout}ms`,
            transitionTimingFunction: easing,
            transitionProperty: properties,
            ...children.props.style,
          },
          className: classNames("fade-transition", children.props.className),
          ref,
        })}
    </CSSTransition>
  );
});

export default Fade;
