import { useCallback, useEffect, useState } from "react";

const useLastMousePosition = (disable?: boolean) => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  const handleWindowClick = useCallback(
    (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    },
    [setMousePosition],
  );

  useEffect(() => {
    if (disable) return;
    window.addEventListener("mousedown", handleWindowClick);
    return () => window.removeEventListener("mousedown", handleWindowClick);
  }, [handleWindowClick, disable]);

  return mousePosition;
};

export default useLastMousePosition;
