import { useEffect } from "react";
import useGlobalContext from "../globalContext/hooks/useGlobalContext";
import useHasHeader from "./useHasHeader";

const useHeaderState = () => {
  const { setHeaderActive } = useGlobalContext();
  const hasHeader = useHasHeader();

  useEffect(() => {
    setHeaderActive(hasHeader);
  }, [hasHeader, setHeaderActive]);
};

export default useHeaderState;
