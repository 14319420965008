import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useState } from "react";

export type CurrentReqState<D> = {
  isLoading: boolean;
  data: D;
  error?: string;
  errorCode?: string;
  nextToken?: string | null;
};

const useRequestState = <D>(defaultValue: D) => {
  const { setMaintenanceMode } = useGlobalContext();

  const createReqState = (
    isLoading: boolean,
    data: D,
    error?: string,
    errorCode?: string,
    nextToken?: string | null,
  ): CurrentReqState<D> => ({
    isLoading,
    data,
    error,
    errorCode,
    nextToken,
  });

  const [reqState, setReqState] = useState<CurrentReqState<D>>({
    isLoading: false,
    data: defaultValue,
  });

  const setIdle = () => {
    const newReqState = createReqState(false, defaultValue);
    setReqState(newReqState);
    return newReqState;
  };
  const setLoading = (preserveData?: boolean) => {
    const newReqState = createReqState(true, preserveData ? reqState.data : defaultValue);
    setReqState(newReqState);
    return newReqState;
  };
  const setSuccess = (data: D, nextToken?: string | null) => {
    const newReqState = createReqState(false, data, undefined, undefined, nextToken);
    setReqState(newReqState);
    return newReqState;
  };
  const setError = (error?: string | null, errorCode?: string) => {
    if (error === "VERSION_MISMATCH") setMaintenanceMode(true);
    const newReqState = createReqState(
      false,
      defaultValue,
      error ?? "Something went wrong",
      errorCode,
    );
    setReqState(newReqState);
    return newReqState;
  };

  return { reqState, setIdle, setLoading, setSuccess, setError };
};

export default useRequestState;
