import Divider from "@components/common/Divider";
import Footer from "@components/layout/Footer";
import Benefits from "./sections/Benefits";
import FAQ from "./sections/FAQ";
import Features from "./sections/Features";
import Games from "./sections/Games";
import How from "./sections/How";
import LastChance from "./sections/LastChance";
import TopSplash from "./sections/TopSplash";
import Why from "./sections/Why";

export default function BecomeTalentOld() {
  return (
    <div id="become-talent-old-page">
      <TopSplash />

      <Why />

      <Divider color="white" />

      <How />

      <Divider color="white" />

      <Benefits />

      <Divider color="white" />

      <Features />

      <Divider color="white" />

      <Games />

      <Divider color="white" />

      <LastChance />

      <FAQ />

      <Footer />
    </div>
  );
}
