import CigAuthWrapper from "@pages/cigAuth/CigAuthWrapper";
import React from "react";
import CigRegisterForm from "./CigRegisterForm";
import useCigRegisterContext from "./context/useCigRegisterContext";

const titles = [
  "Join the club.",
  "Confirm your email.",
  "Just a few more things.",
  "Just a few more things.",
  "Join our Yakkr Discord",
];

const subTitles = [
  "Create your account here.",
  "This ensures only you can sign up with your email.",
  "Extra details.",
  "Extra details.",
  "This grants access to exclusive features, asset packs, and our Twitch Partner network.",
];

export default function CigRegisterInner() {
  const { step } = useCigRegisterContext();

  return (
    <div id="cig-register-page">
      <CigAuthWrapper
        title={titles[step]}
        subTitle={subTitles[step]}
        progressPercent={12.5 * (step + 1)}>
        <CigRegisterForm />
      </CigAuthWrapper>
    </div>
  );
}
