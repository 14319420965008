import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createOnboardingLink, { OnboardingLinkObject } from "../createOnboardingLink";

const useCreateOnboardingLink = () => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<OnboardingLinkObject | null>(null);

  const send = useCallback(async () => {
    setLoading();
    try {
      const data = await createOnboardingLink();
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateOnboardingLink;
