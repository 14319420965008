import { Currency } from "@lib/enums/generic";
import React from "react";
import OverlayAlert from "../OverlayAlert";
import { numberToCurrency } from "@lib/utils/generic";
import { OverlayAlertProps } from "../../hooks/useCigOverlayAlerts";
import TipIcon from "../icons/TipIcon";

interface Props extends OverlayAlertProps {
  displayName: string | null;
  amount: number;
  currency: Currency;
}

export default function TippedAlert(props: Props) {
  const { displayName, amount, currency, ...alertProps } = props;

  return (
    <OverlayAlert
      {...alertProps}
      icon={<TipIcon />}
      title={`${numberToCurrency(amount, currency)}`}
      subTitle={
        <>
          Gifted by <span className="gradient">{displayName ?? "Anonymous"}</span>
        </>
      }
    />
  );
}
