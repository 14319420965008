import { CSSProps, UIColor } from "@lib/types/generic";
import React, { forwardRef } from "react";
import { IoStar, IoStarOutline } from "react-icons/io5";

interface Props extends CSSProps {
  color?: UIColor;
  size?: "ultra-small" | "extra-small" | "small" | "medium" | "large";
  name?: string;
  value: number;
  maxStars?: number;
}

const Rating = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    color = "purple",
    size = "medium",
    value,
    maxStars = 5,
    className = "",
    style,
    id,
  } = props;

  return (
    <div
      className={`rating-selector read-only ${color} ${size} ${className}`}
      style={style}
      id={id}
      ref={ref}>
      {new Array(maxStars).fill(null).map((_, _i) => {
        const i = _i + 1;

        const selected = value >= i;

        return <Star key={i} selected={selected} />;
      })}
    </div>
  );
});

export default Rating;

interface StarProps {
  selected?: boolean;
}

const Star = (props: StarProps) => {
  const { selected } = props;

  return (
    <div className={`star-button ${selected ? "selected" : ""}`}>
      {selected ? <IoStar /> : <IoStarOutline />}
    </div>
  );
};
