import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import Button from "@components/form/Button";
import useForgotPasswordContext from "../context/useForgotPasswordContext";
import { IoArrowForward } from "react-icons/io5";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import { ForgotPasswordModalData } from "../context/ForgotPasswordContextProvider";

export default function ForgotPasswordConfirmStep() {
  const { setAuthModal } = useGlobalContext();

  const { values, validations, onChange, onBlur, forgotPasswordConfirmIsloading } =
    useForgotPasswordContext();

  const { redirect, email } = useAuthModalData<ForgotPasswordModalData>();

  return (
    <>
      <FormRow>
        <FormEntry label="EMAIL" validation={validations.email} showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            placeholder="example@email.com"
            type="email"
            name="email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
            disabled={forgotPasswordConfirmIsloading}
          />
        </FormEntry>
      </FormRow>

      <div className="auth-button-row">
        <Button
          color="purple"
          textColor="white"
          size="large"
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={forgotPasswordConfirmIsloading}>
          Recover Password
        </Button>
      </div>

      <p className="bottom-text">
        Back to <span onClick={() => setAuthModal("login", { redirect, email })}>Sign in</span>
      </p>
    </>
  );
}
