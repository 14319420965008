import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import DataCheck from "./DataCheck";

interface Props extends CSSProps {
  src?: string | null;
  alt?: string;
  size?: number;
  variant?: Variant;
  fallbackOverride?: ReactNode;
  isLoading?: boolean;
}

type Variant = "circle" | "rounded" | "square";

export default function Avatar(props: PropsWithChildren<Props>) {
  const {
    src,
    alt = "",
    size,
    variant = "circle",
    fallbackOverride,
    isLoading,
    style,
    className = "",
    children,
  } = props;

  const [errored, setErrored] = useState(false);

  useEffect(() => {
    if (errored) setErrored(false);
    // eslint-disable-next-line
  }, [src]);
  // errored must be omitted to prevent loop

  const shouldFallback = errored || !src;

  return (
    <div
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        fontSize: size,
        ...style,
      }}
      className={classNames(
        "avatar-wrapper",
        variant,
        shouldFallback && !fallbackOverride ? "avatar-wrapper-fallback" : "",
        className,
      )}>
      <DataCheck imgs={[src]} isLoading={isLoading} loadingIndicator="gloss">
        {children ? (
          Children.map(
            children,
            child =>
              isValidElement(child) &&
              cloneElement(child as any, {
                className: classNames("avatar-icon", child.props.className),
              }),
          )
        ) : !shouldFallback ? (
          <img src={src} alt={alt} onError={() => setErrored(true)} className="avatar-img" />
        ) : (
          fallbackOverride || <p className="avatar-fallback">{alt.charAt(0).toUpperCase()}</p>
        )}
      </DataCheck>
    </div>
  );
}
