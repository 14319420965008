import { RefObject, useCallback, useEffect, useState } from "react";

const useVideoIsPlaying = (ref: RefObject<HTMLVideoElement | null>) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const updateIsPlaying = useCallback(() => {
    const current = ref.current;
    if (!current) return;

    const { paused, currentTime } = current;
    setIsPlaying(!paused && currentTime > 0);
  }, [setIsPlaying, ref]);

  useEffect(() => {
    const current = ref.current;
    if (!current) return;

    current.addEventListener("timeupdate", updateIsPlaying);
    current.addEventListener("play", updateIsPlaying);
    current.addEventListener("pause", updateIsPlaying);
    current.addEventListener("playing", updateIsPlaying);
    current.addEventListener("paused", updateIsPlaying);

    const interval = setInterval(updateIsPlaying, 1000 * 1);

    return () => {
      current.removeEventListener("timeupdate", updateIsPlaying);
      current.removeEventListener("play", updateIsPlaying);
      current.removeEventListener("pause", updateIsPlaying);
      current.removeEventListener("playing", updateIsPlaying);
      current.removeEventListener("paused", updateIsPlaying);

      clearInterval(interval);
    };
  }, [updateIsPlaying, ref]);

  return isPlaying;
};

export default useVideoIsPlaying;
