import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getCigPackageExperiences, { CigPackageExperience } from "../getCigPackageExperiences";

const useGetCigPackageExperiences = (cigId?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CigPackageExperience[]>(
    [],
  );

  const send = useCallback(async () => {
    if (cigId == null) return reqState;
    setLoading();
    try {
      const packageExperiences = await getCigPackageExperiences(cigId);
      setSuccess(packageExperiences);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [cigId, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [cigId, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetCigPackageExperiences;
