import Button from "@components/form/Button";
import { Link } from "react-router-dom";

import DataCheck from "@components/common/DataCheck";
import BackgroundImage from "@components/common/BackgroundImage";
import Divider from "@components/common/Divider";

import ticketPlaceholder from "@images/misc/profile_placeholder.jpg";
import { forwardRef } from "react";

interface Props extends SharedProps {
  scaffold?: false;
  displayName: string;
  tagLine: string;
  profilePictureURL: string | null;
}

interface ScaffoldProps extends SharedProps {
  scaffold: true;
}

interface SharedProps {
  width?: string | number;
}

export type CigCardProps = (Props | ScaffoldProps) & SharedProps;

const isScaffold = (props: any): props is ScaffoldProps => props.scaffold === true;

const CigCard = forwardRef<HTMLDivElement, CigCardProps | ScaffoldProps>((props, ref) => {
  const { width = 215 } = props;

  if (isScaffold(props)) {
    return (
      <div
        ref={ref}
        className="cig-card scaffold"
        style={{
          minWidth: width,
          width: width,
          fontSize: width,
        }}>
        <DataCheck isLoading loadingIndicator="gloss" />
      </div>
    );
  } else {
    const { displayName, tagLine, profilePictureURL } = props;

    return (
      <div
        ref={ref}
        className="cig-card"
        style={{
          minWidth: width,
          width: width,
          fontSize: width,
        }}>
        <DataCheck imgs={[profilePictureURL || ticketPlaceholder]} loadingIndicator="gloss">
          <Link to={`/@${displayName}`} className="image-wrapper">
            <BackgroundImage opacity={1} src={profilePictureURL || ticketPlaceholder} />

            <div className="text-wrapper">
              <p className="display-name">{displayName}</p>
              <p className="tag-line">{tagLine || "Yakkr Talent"}</p>
            </div>
          </Link>

          <Divider color="white" />

          <div className="details-wrapper">
            <Button
              variant="flat"
              color="white"
              textColor="white"
              size="small"
              to={`/@${displayName}`}>
              View Profile
            </Button>
          </div>
        </DataCheck>
      </div>
    );
  }
});

export default CigCard;
