import { api } from "@api/api";
import { Currency } from "@lib/enums/generic";
import { GamePlatformSelection } from "../update/updateExperienceCustom";

export interface CigFullPackageExperience {
  packageID: "2qe5plrlf4j";

  anyGame: boolean;
  anyTime: boolean;

  name: string;
  tagLine: string;
  extraDetails: string;

  price: number;
  currency: Currency;

  duration: number;
  tickets: number;
  streamOption: boolean;

  gamePlatformSelections: GamePlatformSelection[];
}

const getCigFullPackageExperience = async (packageID: string) => {
  const { data } = await api.post<CigFullPackageExperience>("/getCIGFullPackageConfig", {
    packageID,
  });
  return data;
};

export default getCigFullPackageExperience;
