import { Currency } from "@lib/enums/generic";
import { GAItem } from "@lib/types/ga";
import ReactGA from "react-ga4";
import { log } from "./generic";

export default class GAEvent {
  private static send(name: string, options: Record<string, any>) {
    log(`Sending GA event '${name}'`, options);
    ReactGA.event(name, options);
  }

  static listingViewerSelect(category?: string, item?: GAItem) {
    this.send("select_item", {
      type: "home_cig_select",
      item_list_id: category,
      item_list_name: category,
      items: [item],
    });
  }

  static calendarPeriodView(
    cigId?: string,
    cigName?: string,
    periodStartDate?: string,
    periodEndDate?: string,
    items?: GAItem[],
  ) {
    this.send("view_item_list", {
      type: "calendar_period_view",
      item_list_id: cigId,
      item_list_name: cigName,
      period_start_date: periodStartDate,
      period_end_date: periodEndDate,
      items,
    });
  }

  static calendarListingView(currency: Currency, value: number, items?: GAItem[]) {
    this.send("view_item", {
      type: "calendar_listing_view",
      currency,
      value,
      items,
    });
  }

  static calendarListingCheckout(currency: Currency, value: number, items?: GAItem[]) {
    this.send("begin_checkout", {
      type: "calendar_listing_checkout",
      currency,
      value,
      items,
    });
  }

  static login(method: string) {
    this.send("login", {
      method,
    });
  }

  static register(method: string) {
    this.send("sign_up", {
      method,
    });
  }

  static search(searchTerm: string) {
    this.send("search", {
      type: "cig_search",
      search_term: searchTerm,
    });
  }

  static searchItemSelect(searchTerm?: string, item?: GAItem) {
    this.send("select_item", {
      type: "cig_search_select",
      item_list_id: searchTerm,
      item_list_name: searchTerm,
      items: [item],
    });
  }
}
