import AuthWrapper from "../AuthWrapper";
import useLoginContext from "./context/useLoginContext";
import LoginForm from "./LoginForm";

const LoginInner = () => {
  const { loginIsLoading } = useLoginContext();

  return (
    <AuthWrapper title="Jump in" isLoading={loginIsLoading}>
      <LoginForm />
    </AuthWrapper>
  );
};

export default LoginInner;
