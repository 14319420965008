import { getCookie } from "@lib/utils/generic";
import useStorageItem from "./sessionStore/useStorageItem";

const useGAConsent = () => {
  const { item: consentItem, set: setConsent } = useStorageItem<boolean | null>("ga-consent");

  const cookieExists = getCookie("_ga") != null;

  return { consented: cookieExists || consentItem.data, setConsent };
};

export default useGAConsent;
