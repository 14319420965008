import Accordion from "@components/common/Accordion";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import SplashTitle from "@components/splash/SplashTitle";
import { contactEmail } from "@lib/constants/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { Link } from "react-router-dom";

const FAQs = () => {
  const { breakpoints } = useGlobalContext();

  const accordionSize = breakpoints.xs
    ? "small"
    : breakpoints.md
    ? "medium"
    : breakpoints.sl
    ? "large"
    : "extra-large";

  return (
    <section className="section faqs">
      <SplashTitle center>FAQs</SplashTitle>

      <div className="accordions">
        <SlideOnIntersect direction="right" timeout={500}>
          <div>
            <Accordion title="Can anyone play?" color="black-2" size={accordionSize}>
              Absolutely! All you need is the game and device you want to play on to get started
              with Yakkr. Find the streamer you want to game with and get that dub!
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={700}>
          <div>
            <Accordion title="Can I join as a streamer?" color="black-2" size={accordionSize}>
              Yakkr is exclusive to streamers who have an average ccv of 50. Sound like you? If so
              you can join Yakkr now completely free! <Link to="/cig/register">Sign up here</Link>.
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={800}>
          <div>
            <Accordion
              title="Will my sessions be live streamed?"
              color="black-2"
              size={accordionSize}>
              That's up to the streamer. You can usually pay a little extra for the session to be
              streamed so everyone can see the amazing moments you'll create together!
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={850}>
          <div>
            <Accordion
              title="I don't see the streamer I want to play with here"
              color="black-2"
              size={accordionSize}>
              If they're not here then they should be right?! Their fans really want to game with
              them. Let them know about Yakkr or <a href={`mailto:${contactEmail}`}>email us</a> and
              we'll work on bringing them onboard.
            </Accordion>
          </div>
        </SlideOnIntersect>
      </div>
    </section>
  );
};

export default FAQs;
