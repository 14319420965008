import { useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import Button from "../form/Button";
import IconButton from "../form/IconButton";

import { IoArrowForward, IoMenuSharp, IoSearch, IoStar } from "react-icons/io5";
import Search from "@components/form/Search";
import YakkrBanner from "@components/common/YakkrBanner";
import { classNames, pathHasLayout, pathHasNav, pathHasSearch } from "@lib/utils/generic";
import Notifications from "./Notifications";
import useWindowScroll from "@hooks/useWindowScroll";
import useUserIsCig from "@hooks/session/useUserIsCig";
import GAEvent from "@lib/utils/GAEvent";
import { GAConverters } from "@lib/utils/GAConverters";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import Fade from "@components/transitions/Fade";
import useCigsSearch from "@hooks/useCigsSearch";
import Slide from "@components/transitions/Slide";
import GradientButton from "@components/form/GradientButton";
import useMouseIsOver from "@hooks/useMouseIsOver";

export default function Header() {
  const history = useHistory();

  const { isLoading, error, results, cigs, get: getCigs } = useCigsSearch();

  const {
    breakpoints,
    user,
    drawerActive,
    setDrawerActive,
    drawerExpanded,
    headerActive,
    setAuthModal,
  } = useGlobalContext();

  const searchRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const [selectedCigId, setSelectedCigId] = useState("");
  const [searchState, setSearchState] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { scrollY } = useWindowScroll();

  const canScroll = document.body.offsetHeight > window.innerHeight;
  const scrollAtTop = scrollY <= 0 || !canScroll;

  const location = useLocation();

  const isCig = useUserIsCig();

  const hasSearch = pathHasSearch(location.pathname);
  const hasNav = pathHasNav(location.pathname);

  const hasLayout = pathHasLayout(location.pathname);
  if (!hasLayout) return null;

  return (
    <Slide in={headerActive} appear mountOnEnter={false} unmountOnExit={false}>
      <header className="header">
        <div
          className={classNames(
            "header-inner",
            drawerActive && "drawer-active",
            drawerExpanded && "drawer-expanded",
            scrollAtTop && !breakpoints.sm && "transparent",
            searchState && "search-only",
            scrollAtTop && "scroll-top",
          )}>
          <div className="header-left">
            {breakpoints.lg && hasNav && (
              <IconButton
                color={breakpoints.sm ? "white" : "black"}
                variant="flat"
                icon={<IoMenuSharp />}
                onClick={() => setDrawerActive(true)}
                className="nav-button"
                testId="drawer-toggle-button"
              />
            )}

            {searchState || !breakpoints.sm ? (
              hasSearch ? (
                <Fade in appear onEntered={() => breakpoints.sm && searchRef.current?.focus()}>
                  <div className="search-transition-wrapper">
                    <Search
                      ref={searchRef}
                      color="black-4"
                      textColor="white"
                      resultColor="black-4"
                      resultTextColor="white"
                      variant="contained"
                      size="medium"
                      value={selectedCigId}
                      searchValue={searchValue}
                      results={results}
                      onChange={({ value }) => setSearchValue(value)}
                      isLoading={isLoading}
                      error={error}
                      autoFilter
                      sortResults
                      onFocus={() => getCigs()}
                      onBlur={() => setSearchState(false)}
                      onSelect={({ value }) => {
                        const cig = cigs.find(({ id }) => id === value);
                        if (!cig) return;

                        GAEvent.searchItemSelect(
                          searchValue,
                          GAConverters.search.cig(searchValue)(cig),
                        );

                        setSelectedCigId(cig.id);
                        history.push(`/@${cig.displayName}`);
                      }}
                    />
                  </div>
                </Fade>
              ) : (
                <YakkrBanner to="/" />
              )
            ) : (
              <IconButton
                color="white"
                variant="flat"
                icon={<IoSearch />}
                onClick={() => setSearchState(true)}
              />
            )}
          </div>
          {breakpoints.sm && !searchState && (
            <Link to="/" className="header-center">
              <YakkrBanner size="extra-small" />
            </Link>
          )}
          <div className="header-right">
            {user == null ? (
              <>
                <Button
                  size={breakpoints.sm ? "small" : "medium"}
                  variant={breakpoints.sm ? "flat" : "contained"}
                  color="black-4"
                  textColor="white"
                  onClick={() => setAuthModal("login")}
                  className="login-button">
                  Login
                </Button>
                {!breakpoints.sm && (
                  <Button
                    size={breakpoints.sm ? "small" : "medium"}
                    variant={breakpoints.sm ? "flat" : "contained"}
                    color="black-4"
                    textColor="white"
                    onClick={() => setAuthModal("register")}>
                    Register
                  </Button>
                )}
              </>
            ) : (
              <>
                <Notifications />
                <Button
                  size={breakpoints.sm ? "small" : "medium"}
                  variant={breakpoints.sm ? "flat" : "contained"}
                  color="black-4"
                  textColor="white"
                  to="/logout">
                  Logout
                </Button>
              </>
            )}

            {!isCig && !breakpoints.md && <BecomeTalentButton />}
          </div>
        </div>
      </header>
    </Slide>
  );
}

function BecomeTalentButton() {
  const { breakpoints } = useGlobalContext();

  const location = useLocation();

  const buttonRef = useRef<HTMLButtonElement & HTMLAnchorElement>(null);

  const [buttonIsHovered] = useMouseIsOver(buttonRef);

  if (location.pathname === "/become-talent")
    return (
      <GradientButton
        size={breakpoints.sm ? "small" : "medium"}
        textColor="white"
        endIcon={<IoArrowForward />}
        startIcon={<IoStar />}
        justifyContent="center"
        glow
        animated
        isCtaing={buttonIsHovered}
        ref={buttonRef}
        wrapperClassName="cta-button"
        to="/cig/register">
        Apply now
      </GradientButton>
    );

  return (
    <Button
      size={breakpoints.sm ? "small" : "medium"}
      variant={breakpoints.sm ? "flat" : "contained"}
      color="gray-1"
      textColor="white"
      startIcon={<IoStar />}
      to="/become-talent">
      Become Talent
    </Button>
  );
}
