import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Button from "@components/form/Button";
import useFeedbackContext from "../context/useFeedbackContext";
import CurrencyInput from "@components/form/CurrencyInput";
import { getCurrencySymbol } from "@lib/utils/generic";
import { IoArrowForward } from "react-icons/io5";
import HoverTooltip from "@components/common/HoverTooltip";

const suggestedAmounts = [
  {
    amount: 2.0,
    label: "A helping hand",
  },
  {
    amount: 4.99,
    label: "Generous",
  },
  {
    amount: 9.99,
    label: "Very generous",
  },
  {
    amount: 14.99,
    label: "What a legend",
  },
];

export default function TipStep() {
  const {
    values,
    validations,
    onChange,
    onBlur,

    tipIsLoading,

    supportedPresentmentCurrency,
    currencyIsLoading,

    buttonSize,
  } = useFeedbackContext();

  const { supportedCurrency } = supportedPresentmentCurrency ?? {};

  return (
    <>
      <div className="form-rows">
        <FormRow>
          <FormEntry validation={validations.amount} relativePosition="left">
            <CurrencyInput
              variant="contained"
              color="black-4"
              textColor="white"
              size="extra-large"
              name="amount"
              value={values.amount}
              onChange={onChange}
              onBlur={onBlur}
              placeholder="0.00"
              currency={getCurrencySymbol(supportedCurrency)}
              oversized
              isLoading={currencyIsLoading}
            />
          </FormEntry>
        </FormRow>

        <FormRow className="suggested-amounts-row">
          {suggestedAmounts.map(({ amount, label }, i) => (
            <FormEntry key={i}>
              <HoverTooltip content={<p>{label}</p>} noWrap>
                <Button
                  variant="contained"
                  color="black-4"
                  textColor="white"
                  size="medium"
                  onClick={() => onBlur({ name: "amount", value: amount })}
                  rounded>
                  <span className="currency">{getCurrencySymbol(supportedCurrency)}</span>{" "}
                  {amount.toFixed(2)}
                </Button>
              </HoverTooltip>
            </FormEntry>
          ))}
        </FormRow>
      </div>

      <div className="button-wrapper">
        <Button
          size={buttonSize}
          color="purple"
          textColor="white"
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={tipIsLoading}>
          Pay
        </Button>
        <Button
          size={buttonSize}
          color="black-3"
          textColor="white"
          justifyContent="center"
          type="submit"
          disabled={tipIsLoading}
          className="skip">
          Not Now
        </Button>
      </div>
    </>
  );
}
