import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useRequestState from "@hooks/useRequestState";
import { createErrorSnackbar, log } from "@lib/utils/generic";
import { useCallback, useEffect } from "react";
import { Client, Conversation } from "@twilio/conversations";

export interface Bundle {
  chatClient: Client;
  chatRoom: Conversation;
}

const useChatRoom = (token?: string, chatSID?: string, autoSend?: boolean) => {
  const { reqState, setError, setLoading, setSuccess } = useRequestState<Bundle | undefined>(
    undefined,
  );

  const { pushSnackbar } = useGlobalContext();

  const connect = useCallback(async () => {
    if (token && chatSID) {
      try {
        setLoading();
        const chatClient = new Client(token);
        chatClient.on("connectionStateChanged", async state => {
          switch (state) {
            case "connected":
              try {
                const chatRoom = await chatClient.getConversationBySid(chatSID);
                return setSuccess({ chatClient, chatRoom });
              } catch (err: any) {
                log(err);
                setError(err.message);
                pushSnackbar(createErrorSnackbar(err.message, 5000));
              }
              return;
            default:
              return;
          }
        });

        chatClient.on("connectionError", err => {
          log(err);
          setError(err.message);
          pushSnackbar(createErrorSnackbar(err.message, 5000));
        });
      } catch (err: any) {
        log(err);
        setError(err.message);
        pushSnackbar(createErrorSnackbar(err.message, 5000));
      }
    }
  }, [token, chatSID, pushSnackbar, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) connect();
    // eslint-disable-next-line
  }, [token, chatSID, autoSend]);
  // must omit connect to avoid loop

  return {
    connect,
    error: reqState.error,
    isLoading: reqState.isLoading,
    ...reqState.data,
  };
};

export default useChatRoom;
