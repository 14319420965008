import { CSSProps } from "@lib/types/generic";

export default function FluentThinkingFace(cssProps: CSSProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...cssProps}>
      <path
        d="M127.992 239.984C202.664 239.984 239.984 189.84 239.984 127.984C239.984 66.144 202.664 16 127.984 16C53.32 16 16 66.144 16 127.992C16 189.84 53.312 239.984 127.992 239.984Z"
        fill="url(#paint0_radial_8219_82848)"
      />
      <path
        d="M127.992 239.984C202.664 239.984 239.984 189.84 239.984 127.984C239.984 66.144 202.664 16 127.984 16C53.32 16 16 66.144 16 127.992C16 189.84 53.312 239.984 127.992 239.984Z"
        fill="url(#paint1_radial_8219_82848)"
      />
      <path
        d="M127.992 239.984C202.664 239.984 239.984 189.84 239.984 127.984C239.984 66.144 202.664 16 127.984 16C53.32 16 16 66.144 16 127.992C16 189.84 53.312 239.984 127.992 239.984Z"
        fill="url(#paint2_radial_8219_82848)"
      />
      <path
        d="M127.992 239.984C202.664 239.984 239.984 189.84 239.984 127.984C239.984 66.144 202.664 16 127.984 16C53.32 16 16 66.144 16 127.992C16 189.84 53.312 239.984 127.992 239.984Z"
        fill="url(#paint3_radial_8219_82848)"
        fillOpacity="0.6"
      />
      <path
        d="M127.992 239.984C202.664 239.984 239.984 189.84 239.984 127.984C239.984 66.144 202.664 16 127.984 16C53.32 16 16 66.144 16 127.992C16 189.84 53.312 239.984 127.992 239.984Z"
        fill="url(#paint4_radial_8219_82848)"
      />
      <path
        d="M127.992 239.984C202.664 239.984 239.984 189.84 239.984 127.984C239.984 66.144 202.664 16 127.984 16C53.32 16 16 66.144 16 127.992C16 189.84 53.312 239.984 127.992 239.984Z"
        fill="url(#paint5_radial_8219_82848)"
      />
      <path
        d="M127.992 239.984C202.664 239.984 239.984 189.84 239.984 127.984C239.984 66.144 202.664 16 127.984 16C53.32 16 16 66.144 16 127.992C16 189.84 53.312 239.984 127.992 239.984Z"
        fill="url(#paint6_radial_8219_82848)"
      />
      <path
        d="M127.992 239.984C202.664 239.984 239.984 189.84 239.984 127.984C239.984 66.144 202.664 16 127.984 16C53.32 16 16 66.144 16 127.992C16 189.84 53.312 239.984 127.992 239.984Z"
        fill="url(#paint7_radial_8219_82848)"
      />
      <path
        d="M80.0034 172V147.52C80.0034 144.465 78.7897 141.535 76.6293 139.374C74.4689 137.214 71.5387 136 68.4834 136C61.7634 136 56.4434 141.744 56.3474 148.472C56.1874 159.36 54.6834 173.776 48.0034 176C24.0034 184 16.0034 220 36.0034 236C52.0034 248.8 68.0034 248 80.0034 248H102.115C104.344 248.002 106.509 247.251 108.257 245.869C110.006 244.486 111.236 242.554 111.749 240.384C112.261 238.215 112.025 235.936 111.079 233.918C110.134 231.899 108.534 230.259 106.539 229.264L104.003 228H109.763C111.548 228 113.281 227.402 114.687 226.304C116.093 225.205 117.092 223.667 117.523 221.936L118.059 219.76C118.291 218.839 118.309 217.878 118.112 216.95C117.916 216.021 117.511 215.149 116.927 214.401C116.343 213.653 115.596 213.047 114.743 212.631C113.889 212.215 112.953 211.999 112.003 212L114.643 210.68C116.253 209.872 117.606 208.632 118.552 207.1C119.498 205.567 119.999 203.801 119.999 202C119.999 200.199 119.498 198.433 118.552 196.9C117.606 195.368 116.253 194.128 114.643 193.32L112.003 192H126.003C128.656 192 131.199 190.946 133.074 189.071C134.95 187.196 136.003 184.652 136.003 182C136.003 179.348 134.95 176.804 133.074 174.929C131.199 173.054 128.656 172 126.003 172H80.0034Z"
        fill="url(#paint8_radial_8219_82848)"
      />
      <path
        d="M104 151.998C104 151.998 112.992 141.574 130 143.998C147.008 146.422 152 159.998 152 159.998"
        stroke="#402A32"
        strokeWidth="4.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.1418 144.752C92.7884 144.752 109.526 128.015 109.526 107.368C109.526 86.7218 92.7884 69.9844 72.1418 69.9844C51.4952 69.9844 34.7578 86.7218 34.7578 107.368C34.7578 128.015 51.4952 144.752 72.1418 144.752Z"
        fill="url(#paint9_radial_8219_82848)"
      />
      <path
        d="M153.952 145.495C172.703 145.495 187.904 130.294 187.904 111.543C187.904 92.7916 172.703 77.5908 153.952 77.5908C135.201 77.5908 120 92.7916 120 111.543C120 130.294 135.201 145.495 153.952 145.495Z"
        fill="url(#paint10_radial_8219_82848)"
      />
      <path
        d="M83.3557 129.792C87.8176 129.861 92.2486 129.042 96.3907 127.382C100.533 125.722 104.304 123.255 107.483 120.124C110.663 116.993 113.188 113.26 114.911 109.144C116.635 105.028 117.523 100.61 117.523 96.1481C117.523 91.6857 116.635 87.2679 114.911 83.1518C113.188 79.0357 110.663 75.3036 107.483 72.1726C104.304 69.0417 100.533 66.5745 96.3907 64.9146C92.2486 63.2547 87.8176 62.4353 83.3557 62.5041C74.5227 62.6403 66.0974 66.2449 59.8991 72.5395C53.7008 78.8342 50.2266 87.314 50.2266 96.1481C50.2266 104.982 53.7008 113.462 59.8991 119.757C66.0974 126.051 74.5227 129.656 83.3557 129.792ZM172.54 130.408C181.545 130.408 190.182 126.831 196.55 120.463C202.918 114.095 206.496 105.458 206.496 96.4521C206.496 87.4464 202.918 78.8096 196.55 72.4416C190.182 66.0736 181.545 62.4961 172.54 62.4961C163.533 62.4961 154.895 66.074 148.526 72.4428C142.158 78.8115 138.58 87.4493 138.58 96.4561C138.58 105.463 142.158 114.101 148.526 120.469C154.895 126.838 163.533 130.408 172.54 130.408Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104 92C104 98.3652 101.471 104.47 96.9706 108.971C92.4697 113.471 86.3652 116 80 116C73.6348 116 67.5303 113.471 63.0294 108.971C58.5286 104.47 56 98.3652 56 92C56 85.6348 58.5286 79.5303 63.0294 75.0294C67.5303 70.5286 73.6348 68 80 68C86.3652 68 92.4697 70.5286 96.9706 75.0294C101.471 79.5303 104 85.6348 104 92ZM192 92C192 98.3652 189.471 104.47 184.971 108.971C180.47 113.471 174.365 116 168 116C161.635 116 155.53 113.471 151.029 108.971C146.529 104.47 144 98.3652 144 92C144 85.6348 146.529 79.5303 151.029 75.0294C155.53 70.5286 161.635 68 168 68C174.365 68 180.47 70.5286 184.971 75.0294C189.471 79.5303 192 85.6348 192 92Z"
        fill="url(#paint11_linear_8219_82848)"
      />
      <g filter="url(#filter0_f_8219_82848)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M101.622 229.504L102.062 227.76C102.294 226.839 102.312 225.877 102.115 224.948C101.918 224.018 101.512 223.146 100.927 222.398C100.342 221.649 99.5941 221.044 98.7399 220.629C97.8858 220.213 96.948 219.998 95.9982 220L98.6382 218.68C100.248 217.872 101.601 216.632 102.547 215.1C103.493 213.567 103.994 211.801 103.994 210C103.994 208.199 103.493 206.433 102.547 204.9C101.601 203.368 100.248 202.128 98.6382 201.32L95.9982 200H109.998C112.65 200 115.194 198.946 117.069 197.071C118.945 195.196 119.998 192.652 119.998 190C119.998 187.348 118.945 184.804 117.069 182.929C115.194 181.054 112.65 180 109.998 180H63.9982V155.52C63.9982 152.465 62.7845 149.535 60.6241 147.374C58.4636 145.214 55.5335 144 52.4782 144C45.7582 144 40.4382 149.744 40.3422 156.472C40.2222 164.936 39.2862 175.52 35.7422 180.872C48.0382 205.2 69.9982 223.16 101.622 229.504Z"
          fill="#E4694E"
        />
      </g>
      <path
        d="M80.0034 172V147.52C80.0034 144.465 78.7897 141.535 76.6293 139.374C74.4689 137.214 71.5387 136 68.4834 136C61.7634 136 56.4434 141.744 56.3474 148.472C56.1874 159.36 54.6834 173.776 48.0034 176C24.0034 184 16.0034 220 36.0034 236C52.0034 248.8 68.0034 248 80.0034 248H102.115C104.344 248.002 106.509 247.251 108.257 245.869C110.006 244.486 111.236 242.554 111.749 240.384C112.261 238.215 112.025 235.936 111.079 233.918C110.134 231.899 108.534 230.259 106.539 229.264L104.003 228H109.763C111.548 228 113.281 227.402 114.687 226.304C116.093 225.205 117.092 223.667 117.523 221.936L118.059 219.76C118.291 218.839 118.309 217.878 118.112 216.95C117.916 216.021 117.511 215.149 116.927 214.401C116.343 213.653 115.596 213.047 114.743 212.631C113.889 212.215 112.953 211.999 112.003 212L114.643 210.68C116.253 209.872 117.606 208.632 118.552 207.1C119.498 205.567 119.999 203.801 119.999 202C119.999 200.199 119.498 198.433 118.552 196.9C117.606 195.368 116.253 194.128 114.643 193.32L112.003 192H126.003C128.656 192 131.199 190.946 133.074 189.071C134.95 187.196 136.003 184.652 136.003 182C136.003 179.348 134.95 176.804 133.074 174.929C131.199 173.054 128.656 172 126.003 172H80.0034Z"
        fill="url(#paint12_radial_8219_82848)"
      />
      <path
        d="M80.0034 172V147.52C80.0034 144.465 78.7897 141.535 76.6293 139.374C74.4689 137.214 71.5387 136 68.4834 136C61.7634 136 56.4434 141.744 56.3474 148.472C56.1874 159.36 54.6834 173.776 48.0034 176C24.0034 184 16.0034 220 36.0034 236C52.0034 248.8 68.0034 248 80.0034 248H102.115C104.344 248.002 106.509 247.251 108.257 245.869C110.006 244.486 111.236 242.554 111.749 240.384C112.261 238.215 112.025 235.936 111.079 233.918C110.134 231.899 108.534 230.259 106.539 229.264L104.003 228H109.763C111.548 228 113.281 227.402 114.687 226.304C116.093 225.205 117.092 223.667 117.523 221.936L118.059 219.76C118.291 218.839 118.309 217.878 118.112 216.95C117.916 216.021 117.511 215.149 116.927 214.401C116.343 213.653 115.596 213.047 114.743 212.631C113.889 212.215 112.953 211.999 112.003 212L114.643 210.68C116.253 209.872 117.606 208.632 118.552 207.1C119.498 205.567 119.999 203.801 119.999 202C119.999 200.199 119.498 198.433 118.552 196.9C117.606 195.368 116.253 194.128 114.643 193.32L112.003 192H126.003C128.656 192 131.199 190.946 133.074 189.071C134.95 187.196 136.003 184.652 136.003 182C136.003 179.348 134.95 176.804 133.074 174.929C131.199 173.054 128.656 172 126.003 172H80.0034Z"
        fill="url(#paint13_radial_8219_82848)"
      />
      <path
        d="M100 36C97.336 33.336 89.6 28 80 28C70.4 28 62.664 33.336 60 36M148 68C148 68 156 60 172 60C188 60 196 68 196 68"
        stroke="#402A32"
        strokeWidth="2.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.8" filter="url(#filter1_f_8219_82848)">
        <path d="M80 184H136V192H80V184Z" fill="#FFA048" />
      </g>
      <path
        d="M88 192H112C114.122 192 116.157 192.843 117.657 194.343C119.157 195.843 120 197.878 120 200L88 192Z"
        fill="url(#paint14_linear_8219_82848)"
      />
      <path
        d="M88 228H104C106.122 228 108.157 228.843 109.657 230.343C111.157 231.843 112 233.878 112 236L88 228Z"
        fill="url(#paint15_linear_8219_82848)"
      />
      <path
        d="M88 212H112C114.122 212 116.157 212.843 117.657 214.343C119.157 215.843 120 217.878 120 220L88 212Z"
        fill="url(#paint16_linear_8219_82848)"
      />
      <defs>
        <filter
          id="filter0_f_8219_82848"
          x="31.6172"
          y="139.875"
          width="92.5078"
          height="93.7539"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.0625" result="effect1_foregroundBlur_8219_82848" />
        </filter>
        <filter
          id="filter1_f_8219_82848"
          x="76.9062"
          y="180.906"
          width="62.1875"
          height="14.1875"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.54688" result="effect1_foregroundBlur_8219_82848" />
        </filter>
        <radialGradient
          id="paint0_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(204.001 71.9967) rotate(132.839) scale(300.026)">
          <stop stopColor="#FFF478" />
          <stop offset="0.475" stopColor="#FFB02E" />
          <stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(203.991 72.0051) rotate(131.878) scale(311.59)">
          <stop stopColor="#FFF478" />
          <stop offset="0.475" stopColor="#FFB02E" />
          <stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(127.994 99.9978) rotate(101.31) scale(142.773 182.865)">
          <stop offset="0.788" stopColor="#F59639" stopOpacity="0" />
          <stop offset="0.973" stopColor="#FF7DCE" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(144 112) rotate(135) scale(328.098)">
          <stop offset="0.315" stopOpacity="0" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(128 136.006) rotate(77.692) scale(225.175)">
          <stop offset="0.508" stopColor="#7D6133" stopOpacity="0" />
          <stop offset="1" stopColor="#715B32" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(132 132) rotate(55.713) scale(106.508 77.2026)">
          <stop stopColor="#FFB849" />
          <stop offset="1" stopColor="#FFB847" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(164 144) rotate(9.86582) scale(93.3808)">
          <stop stopColor="#FFA64B" />
          <stop offset="0.9" stopColor="#FFAE46" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(140 120.002) rotate(43.971) scale(472.423)">
          <stop offset="0.185" stopOpacity="0" />
          <stop offset="1" stopOpacity="0.4" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(124.003 176) rotate(-178.264) scale(132.061 123.15)">
          <stop offset="0.066" stopColor="#FFEA67" />
          <stop offset="0.593" stopColor="#FFC13F" />
          <stop offset="0.904" stopColor="#DF9030" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(101.23 78.2798) rotate(135) scale(81.6208 42.2441)">
          <stop stopColor="#392108" />
          <stop offset="1" stopColor="#C87928" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(180.368 85.1322) rotate(133.664) scale(75.8418 42.8138)">
          <stop stopColor="#392108" />
          <stop offset="1" stopColor="#C87928" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint11_linear_8219_82848"
          x1="132"
          y1="56"
          x2="124"
          y2="112"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#553B3E" />
          <stop offset="1" stopColor="#3D2432" />
        </linearGradient>
        <radialGradient
          id="paint12_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(124.003 176) rotate(-178.264) scale(132.061 123.15)">
          <stop offset="0.066" stopColor="#FFEA67" />
          <stop offset="0.593" stopColor="#FFC13F" />
          <stop offset="0.904" stopColor="#DF9030" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_8219_82848"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(84.0034 180) rotate(172.235) scale(88.8144 91.0688)">
          <stop offset="0.5" stopColor="#FFEA67" stopOpacity="0" />
          <stop offset="0.851" stopColor="#F9708E" />
        </radialGradient>
        <linearGradient
          id="paint14_linear_8219_82848"
          x1="100"
          y1="192"
          x2="112"
          y2="204"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FA9428" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_8219_82848"
          x1="92"
          y1="228"
          x2="104"
          y2="240"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FA9428" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_8219_82848"
          x1="92"
          y1="212"
          x2="104"
          y2="224"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FA9428" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
