import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const feedbackValidators = {
  sessionId: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").exists();
  },
  amount: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("number").number.greaterThanOrEqualTo(2);
  },
  message: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").length.lessThanOrEqualTo(1000);
  },
  rating: (validation: FormValidation, value: any) => {
    new Validator(validation, value)
      .is("number")
      .number.greaterThan(0, undefined, "You must provide a rating")
      .number.lessThanOrEqualTo(5);
  },
  anonymous: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("boolean").exists();
  },
};

export default feedbackValidators;
