import { pathMatch } from "@lib/utils/generic";
import { Route, RouteProps, useHistory } from "react-router-dom";

interface Props extends RouteProps {
  guarded?: boolean;
  redirect?: string;
  disable?: boolean;
  onGuard?: () => void;
}

export default function GuardedRoute(props: Props) {
  const { guarded = true, redirect = "/", disable, exact, onGuard } = props;
  const history = useHistory();

  if (disable) return null;
  if (guarded) {
    const path = snipPath(props.path?.toString() ?? "/");
    if (pathMatch(history.location.pathname, path, exact)) {
      history.push(redirect);
      if (onGuard != null) onGuard();
    }
    return null;
  }

  return <Route {...props} />;
}

const snipPath = (path: string) => {
  if (path === "/") return path;
  return path.replace(/\/?$/, "");
};
