import useTimeLeft from "@pages/bookings/room/hooks/useTimeLeft";
import { Dayjs } from "dayjs";
import React from "react";
import { OverlayAlertProps } from "../../hooks/useCigOverlayAlerts";
import ClockIcon from "../icons/ClockIcon";
import OverlayAlert from "../OverlayAlert";

interface Props extends OverlayAlertProps {
  startDate: Dayjs;
}

export default function SessionSoonAlert(props: Props) {
  const { startDate, ...alertProps } = props;

  const { minutesPadded, secondsPadded } = useTimeLeft(startDate);

  return (
    <OverlayAlert
      {...alertProps}
      icon={<ClockIcon />}
      title={`${minutesPadded}:${secondsPadded} mins`}
      subTitle="Session starting soon!"
    />
  );
}
