import React from "react";
import ctaImg from "@images/becomeTalentOld/cta.jpg";
import BackgroundImage from "@components/common/BackgroundImage";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import CtaButton from "../CtaButton";

export default function LastChance() {
  return (
    <section className="section last-chance">
      <SlideOnIntersect direction="left" timeout={500}>
        <div className="cta-box">
          <div className="content">
            <h3 className="title">Last chance, meet your fans now</h3>
            <p className="text">
              It really is an easy choice. Your fans want to play. You both love gaming and you can
              earn a consistent income. Everyone's a winner.
            </p>
            <CtaButton>Ok, fine, I'm in</CtaButton>
          </div>

          <div className="cta-img-wrapper">
            <BackgroundImage src={ctaImg} opacity={0} />
          </div>
        </div>
      </SlideOnIntersect>
    </section>
  );
}
