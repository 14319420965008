import { StreamNotification } from "@api/public/get/getStreamNotifications";
import useGetStreamNotifications from "@api/public/get/hooks/useGetStreamNotifications";
import useInterval from "@hooks/useInterval";
import { StreamNotificationType } from "@lib/enums/generic";
import dayjs from "dayjs";
import { OverlayAlertItem } from "./useCigOverlayAlerts";
import PurchasedSessionAlert from "@components/pages/cigProfile/overlay/alerts/variants/PurchasedSessionAlert";
import GiftedSessionAlert from "../alerts/variants/GiftedSessionAlert";
import TippedAlert from "../alerts/variants/TippedAlert";
import SessionSoonAlert from "../alerts/variants/SessionSoonAlert";

const intervalMins = 1;

const useAlertsUpdater = (pushAlert: (alert: OverlayAlertItem) => void, cigId?: string) => {
  const { send: getStreamNotifications } = useGetStreamNotifications(cigId);

  const queryNotifications = async () => {
    const { data: streamNotifications } = await getStreamNotifications();

    for (const streamNotification of streamNotifications) {
      const { createdAt, streamNotificationID } = streamNotification;

      if (dayjs(createdAt).isBefore(dayjs().subtract(intervalMins, "minutes"))) continue;

      const component = generateComponent(streamNotification);
      if (!component) continue;

      pushAlert({ component, key: streamNotificationID });
    }
  };

  useInterval(queryNotifications, 1000 * 60 * intervalMins);
};

export default useAlertsUpdater;

const generateComponent = (streamNotification: StreamNotification) => {
  switch (streamNotification.type) {
    case StreamNotificationType.Booked: {
      const { userDisplayName } = streamNotification;
      return <PurchasedSessionAlert displayName={userDisplayName} />;
    }
    case StreamNotificationType.SessionGifted: {
      const { userDisplayName } = streamNotification;
      return <GiftedSessionAlert displayName={userDisplayName} />;
    }
    case StreamNotificationType.Tipped: {
      const { userDisplayName, tipAmount, tipCurrency } = streamNotification;
      return (
        <TippedAlert displayName={userDisplayName} amount={tipAmount} currency={tipCurrency} />
      );
    }
    case StreamNotificationType.StartingSoon: {
      const { sessionStartTime } = streamNotification;
      return <SessionSoonAlert startDate={dayjs(sessionStartTime)} />;
    }
  }
};
