import React from "react";
import GradientIcon, { GradientIconInnerProps, GradientIconProps } from "./GradientIcon";

const IoRadioGradient = (props: GradientIconProps) => (
  <GradientIcon {...props}>
    {({ gradientDefinition, label, ...cssProps }: GradientIconInnerProps) => (
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        {...cssProps}>
        <path
          d="M256 291.98C275.882 291.98 292 275.867 292 255.99C292 236.113 275.882 220 256 220C236.118 220 220 236.113 220 255.99C220 275.867 236.118 291.98 256 291.98Z"
          fill={label ? `url(#${label}-1)` : undefined}
        />
        <path
          d="M188.421 350.83L173.801 334.39C154.604 312.804 144 284.922 144 256.035C144 227.148 154.604 199.266 173.801 177.68L188.421 161.25L221.291 190.49L206.671 206.92C194.637 220.454 187.989 237.935 187.989 256.045C187.989 274.155 194.637 291.636 206.671 305.17L221.291 321.61L188.421 350.83ZM323.621 350.83L290.731 321.61L305.351 305.17C317.385 291.636 324.032 274.155 324.032 256.045C324.032 237.935 317.385 220.454 305.351 206.92L290.731 190.49L323.601 161.25L338.221 177.68C357.417 199.266 368.021 227.148 368.021 256.035C368.021 284.922 357.417 312.804 338.221 334.39L323.621 350.83Z"
          fill={label ? `url(#${label}-2)` : undefined}
        />
        <path
          d="M138.21 401.78L123.21 385.72C90.3061 350.548 72 304.184 72 256.02C72 207.856 90.3061 161.492 123.21 126.32L138.21 110.25L170.35 140.3L155.35 156.36C130.067 183.386 116.001 219.011 116.001 256.02C116.001 293.029 130.067 328.654 155.35 355.68L170.35 371.74L138.21 401.78ZM373.73 401.78L341.59 371.78L356.59 355.72C381.873 328.694 395.939 293.069 395.939 256.06C395.939 219.051 381.873 183.426 356.59 156.4L341.59 140.34L373.73 110.34L388.73 126.41C421.635 161.582 439.941 207.946 439.941 256.11C439.941 304.274 421.635 350.638 388.73 385.81L373.73 401.78Z"
          fill={label ? `url(#${label}-3)` : undefined}
        />
        <path
          d="M430.735 447L397.945 417.67L412.605 401.28C448.283 361.298 468.001 309.586 468.001 256C468.001 202.414 448.283 150.702 412.605 110.72L397.935 94.34L430.725 65L445.395 81.39C488.29 129.436 511.999 191.591 511.999 256C511.999 320.409 488.29 382.564 445.395 430.61L430.735 447ZM81.2747 447L66.6047 430.61C23.709 382.564 0 320.409 0 256C0 191.591 23.709 129.436 66.6047 81.39L81.2847 65L114.075 94.34L99.3947 110.72C63.7165 150.702 43.9981 202.414 43.9981 256C43.9981 309.586 63.7165 361.298 99.3947 401.28L114.055 417.67L81.2747 447Z"
          fill={label ? `url(#${label}-4)` : undefined}
        />
        {gradientDefinition && (
          <defs>
            <linearGradient
              id={`${label}-1`}
              x1="222.576"
              y1="249.28"
              x2="296.397"
              y2="250.121"
              gradientUnits="userSpaceOnUse"
              gradientTransform={`rotate(${gradientDefinition.rotation})`}>
              {gradientDefinition.stopColors.map(({ stopColor, offset }, i) => (
                <stop key={i} offset={offset} stopColor={stopColor} />
              ))}
            </linearGradient>
            <linearGradient
              id={`${label}-2`}
              x1="152.014"
              y1="238.367"
              x2="381.691"
              y2="241.459"
              gradientUnits="userSpaceOnUse"
              gradientTransform={`rotate(${gradientDefinition.rotation})`}>
              {gradientDefinition.stopColors.map(({ stopColor, offset }, i) => (
                <stop key={i} offset={offset} stopColor={stopColor} />
              ))}
            </linearGradient>
            <linearGradient
              id={`${label}-3`}
              x1="85.1631"
              y1="228.838"
              x2="462.382"
              y2="234.262"
              gradientUnits="userSpaceOnUse"
              gradientTransform={`rotate(${gradientDefinition.rotation})`}>
              {gradientDefinition.stopColors.map(({ stopColor, offset }, i) => (
                <stop key={i} offset={offset} stopColor={stopColor} />
              ))}
            </linearGradient>
            <linearGradient
              id={`${label}-4`}
              x1="18.3168"
              y1="220.39"
              x2="543.213"
              y2="228.404"
              gradientUnits="userSpaceOnUse"
              gradientTransform={`rotate(${gradientDefinition.rotation})`}>
              {gradientDefinition.stopColors.map(({ stopColor, offset }, i) => (
                <stop key={i} offset={offset} stopColor={stopColor} />
              ))}
            </linearGradient>
          </defs>
        )}
      </svg>
    )}
  </GradientIcon>
);

export default IoRadioGradient;
