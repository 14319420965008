import FormModal from "@components/modals/FormModal";
import Form from "@components/form/Form";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import useFormManager from "@components/form/hooks/useFormManager";
import Input from "@components/form/Input";
import Select from "@components/form/Select";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/utils/generic";
import React, { useCallback, useEffect } from "react";
import useCreateReportHandles from "./hooks/useCreateReportHandles";
import useRoomContext from "./roomContext/hooks/useRoomContext";

const initialValues = {
  userId: "",
  reason: "",
};

export type CreateReportValues = typeof initialValues;

export default function ReportModal() {
  const { pushSnackbar } = useGlobalContext();
  const { reportModalActive, initialReportParticipantId, closeReportModal, participantOptions } =
    useRoomContext();

  const formManager = useFormManager(initialValues, "createReport");

  const { values, validations, onBlur, onChange, resetValidations, setValues } = formManager;

  const [handle, isLoading] = useCreateReportHandles(formManager);

  useEffect(() => {
    onChange({ name: "userId", value: initialReportParticipantId });
    // eslint-disable-next-line
  }, [initialReportParticipantId]);
  // must omit onChange to prevent loop

  const handleCompletion = useCallback(() => {
    pushSnackbar(createSuccessSnackbar("Participant reported", 3000));
    setValues({ ...initialValues, userId: initialReportParticipantId ?? "" });
    closeReportModal();
  }, [initialReportParticipantId, closeReportModal, pushSnackbar, setValues]);

  return (
    <FormModal
      form="report-form"
      active={reportModalActive}
      onClose={closeReportModal}
      confirmButtonProps={{ isLoading }}
      title="Report a User"
      subTitle="Please detail exactly why you are reporting this user to help with the investigation process."
      color="red"
      confirmButtonText="Report">
      <Form
        id="report-form"
        handle={handle}
        resetValidations={resetValidations}
        validation={validations.generic}
        resetOnCompletion
        onCompletion={handleCompletion}>
        <FormRow>
          <FormEntry label="Participant" validation={validations.userId} showValidationBeneath>
            <Select
              variant="contained"
              color="black-4"
              textColor="white"
              size="medium"
              name="userId"
              useEmptyOption
              options={participantOptions}
              value={values.userId}
              onChange={onChange}
              onBlur={onBlur}
            />
          </FormEntry>
        </FormRow>

        <FormRow>
          <FormEntry label="Reason" validation={validations.reason} showValidationBeneath>
            <Input
              variant="contained"
              color="black-4"
              textColor="white"
              size="medium"
              name="reason"
              value={values.reason}
              placeholder="Be as clear as possible."
              onChange={onChange}
              onBlur={onBlur}
              multiline
            />
          </FormEntry>
        </FormRow>
      </Form>
    </FormModal>
  );
}
