import useForceRerender from "@hooks/useForceRerender";
import { getAudioOutputDevices } from "@lib/utils/generic";
import { useEffect, useState } from "react";

const useAudioOutputDevices = (): [MediaDeviceInfo[], () => void] => {
  const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([]);

  const [number, rerender] = useForceRerender();

  const get = async () => setAudioOutputDevices(await getAudioOutputDevices());

  useEffect(() => {
    get();
  }, [number]);

  return [audioOutputDevices, rerender];
};

export default useAudioOutputDevices;
