import { api } from "@api/api";
import { Currency } from "@lib/enums/generic";

export interface CigUpcomingSession {
  extraInfo: string;
  from: string;
  maxSlots: number;
  slotsAvailable: number;
  game: Game;
  currency: Currency;
  exclusive: boolean;
  charity: boolean;
  charityName: string | null;
  platform: Platform;
  price: number;
  free: boolean;
  private: boolean;
  to: string;
  id: string;
  displayNames: string[];
  isOpenPublic: boolean;
  accessCode: string | null;
}

interface Game {
  longName: string;
  shortName: string;
  secondaryImageURL: string;
  primaryImageURL: string;
  id: string;
  description: string;
  category: string;
}

interface Platform {
  name: string;
  shortName: string;
  icon: string;
  id: string;
}

const getCigUpcomingSessions = async () => {
  const { data } = await api.post<CigUpcomingSession[]>("/cigGetUpcomingSessions");
  return data;
};

export default getCigUpcomingSessions;
