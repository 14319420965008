import { cloneElement, forwardRef, ReactElement, useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { CSSProps, JustifyContent, UIColor } from "../../lib/types/generic";
import { FormElement } from "./Form";
import { IoAlarm, IoChevronDown } from "react-icons/io5";
import { Position, RelativePosition } from "../../hooks/useRelativeCoordsAndSize";
import { Dayjs } from "dayjs";
import { IoTime } from "react-icons/io5";
import FormRow from "./FormRow";
import FormEntry from "./FormEntry";
import useOnMouseUp from "@hooks/useOnMouseUp";
import Select from "./Select";
import Button from "./Button";
import { classNames } from "@lib/utils/generic";
import { Option } from "./Options";
import RelativePortal from "@components/common/RelativePortal";

interface Props extends CSSProps {
  position?: Position;
  relativePosition?: RelativePosition;
  color?: UIColor;
  textColor?: UIColor;
  pickerColor?: UIColor;
  pickerTextColor?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  pickerIcon?: ReactElement;

  name?: string;
  hourInterval?: number;
  minuteInterval?: number;
  maxTime?: Dayjs;
  minTime?: Dayjs;

  justifyContent?: JustifyContent;
  rounded?: boolean;
  disabled?: boolean;
  disableHover?: boolean;
  isLoading?: boolean;
  error?: string;
  value: Dayjs;

  onChange?: (target: FormElement<Dayjs>) => void;
  onClose?: (value: Dayjs) => void;
}

export type TimePickerProps = Props;

const TimePicker = forwardRef<HTMLButtonElement & HTMLAnchorElement, Props>((props, ref) => {
  const {
    position = "absolute",
    relativePosition = "bottom",
    color = "white",
    textColor = "black",
    pickerColor = color,
    pickerTextColor = textColor,
    variant = "contained",
    size = "medium",
    startIcon = <IoTime />,
    endIcon = <IoChevronDown className="chevron" />,
    pickerIcon = <IoAlarm />,

    name,
    hourInterval = 1,
    minuteInterval = 1,
    maxTime,
    minTime,

    justifyContent = "space-between",
    rounded,
    disabled,
    disableHover,
    isLoading,
    error,

    value,
    onChange = () => {},
    onClose = () => {},

    className = "",
    id,
    style,
  } = props;

  const [active, setActive] = useState(false);
  const [handled, setHandled] = useState(false);

  const nodes = (
    <Inner
      pickerColor={pickerColor}
      pickerTextColor={pickerTextColor}
      variant={variant}
      size={size}
      pickerIcon={pickerIcon}
      name={name}
      hourInterval={hourInterval}
      minuteInterval={minuteInterval}
      maxTime={maxTime}
      minTime={minTime}
      rounded={rounded}
      value={value}
      onChange={onChange}
      onClose={value => {
        if (handled) return;
        setHandled(true);
        onClose(value);
        setActive(false);
      }}
    />
  );

  useEffect(() => {
    if (handled) setTimeout(() => setHandled(false), 50);
  }, [handled]);

  return (
    <div className="time-picker-wrapper">
      <RelativePortal
        active={active}
        content={nodes}
        relativePosition={relativePosition}
        position={position}
        className="z-select">
        <div>
          <Button
            ref={ref}
            color={color}
            textColor={textColor}
            variant={variant}
            size={size}
            startIcon={startIcon}
            endIcon={endIcon}
            name={name}
            type="button"
            justifyContent={justifyContent}
            rounded={rounded}
            disabled={error != null || disabled}
            disableHover={disableHover}
            isLoading={isLoading}
            active={active}
            className={classNames("time-picker", className)}
            id={id}
            style={style}
            onClick={() => {
              if (handled) return;
              setHandled(true);
              setActive(true);
            }}>
            {value.format("HH:mm")}
          </Button>
        </div>
      </RelativePortal>
    </div>
  );
});

export default TimePicker;

interface InnerProps {
  color?: UIColor;
  textColor?: UIColor;
  pickerColor?: UIColor;
  pickerTextColor?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  pickerIcon?: ReactElement;

  name?: string;
  hourInterval?: number;
  minuteInterval?: number;
  maxTime?: Dayjs;
  minTime?: Dayjs;

  rounded?: boolean;
  value: Dayjs;

  onChange?: (target: FormElement<Dayjs>) => void;
  onClose?: (value: Dayjs) => void;
}

const Inner = (props: InnerProps) => {
  const {
    color = "white",
    textColor = "black",
    pickerColor = color,
    pickerTextColor = textColor,
    variant = "contained",
    size = "medium",
    pickerIcon = <IoAlarm />,

    name,
    hourInterval = 1,
    minuteInterval = 1,
    maxTime,
    minTime,

    rounded,

    value,

    onChange = () => {},
    onClose = () => {},
  } = props;

  const timePickerRef = useRef<HTMLDivElement>(null);

  const handleChange = useCallback(
    (newValue: Dayjs) => {
      if (maxTime != null && newValue.isAfter(maxTime))
        return onChange({ name: name ?? "", value: maxTime });
      if (minTime != null && newValue.isBefore(minTime))
        return onChange({ name: name ?? "", value: minTime });
      onChange({ name: name ?? "", value: newValue });
    },
    [maxTime, minTime, name, onChange],
  );

  useOnMouseUp(
    undefined,
    () => {
      const activeElement = document.activeElement;
      const timePicker = timePickerRef.current;

      const timePickerFocussed =
        timePicker?.contains(activeElement) || timePicker === activeElement;

      if (timePickerFocussed) return;
      onClose(value);
    },
    undefined,
  );

  useEffect(() => {
    const { current } = timePickerRef;
    if (current) current.focus();
  }, []);

  return (
    <div
      className={classNames(
        "time-picker-selector",
        pickerColor,
        `${pickerTextColor}-text`,
        variant,
        size,
        rounded && "rounded",
      )}>
      <div className="time-picker-selector-inner" ref={timePickerRef} tabIndex={0}>
        <FormRow>
          {cloneElement(pickerIcon, {
            className: "time-icon",
          })}
          <FormEntry>
            <Select
              size="small"
              color={pickerColor}
              textColor={pickerTextColor}
              variant="outlined"
              position="absolute"
              value={value.hour()}
              options={Array(24 / hourInterval)
                .fill(null)
                .reduce((acc, _, i) => {
                  const date = value.set("hour", i * hourInterval);
                  if (minTime != null && date.isBefore(minTime)) return acc;
                  if (maxTime != null && date.isAfter(maxTime)) return acc;
                  return [
                    ...acc,
                    {
                      value: i * hourInterval,
                      label: `${i * hourInterval}`.padStart(2, "0"),
                    },
                  ];
                }, [] as Option[])}
              onChange={target => handleChange(value.set("hour", target.value))}
              className="omit-from-form-group"
              buttonClassName="omit-from-form-group"
            />
          </FormEntry>
          <p className="colon">:</p>
          <FormEntry>
            <Select
              size="small"
              color={pickerColor}
              textColor={pickerTextColor}
              variant="outlined"
              position="absolute"
              value={value.minute()}
              options={Array(60 / minuteInterval)
                .fill(null)
                .reduce((acc, _, i) => {
                  const date = value.set("minute", i * minuteInterval);
                  if (minTime != null && date.isBefore(minTime)) return acc;
                  if (maxTime != null && date.isAfter(maxTime)) return acc;
                  return [
                    ...acc,
                    {
                      value: i * minuteInterval,
                      label: `${i * minuteInterval}`.padStart(2, "0"),
                    },
                  ];
                }, [] as Option[])}
              onChange={target => handleChange(value.set("minute", target.value))}
              className="omit-from-form-group"
              buttonClassName="omit-from-form-group"
            />
          </FormEntry>
        </FormRow>
      </div>
    </div>
  );
};
