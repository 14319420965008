import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import shareTicket, { ShareTicketRes } from "../shareTicket";

const useShareTicket = (bookingId?: string, ticketId?: string, emailToGift?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<ShareTicketRes | null>(
    null,
  );

  const send = useCallback(async () => {
    if (bookingId == null || ticketId == null || emailToGift == null) return reqState;
    setLoading();
    try {
      const data = await shareTicket(bookingId, ticketId, emailToGift);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [bookingId, ticketId, emailToGift, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useShareTicket;
