import Checkbox from "@components/form/Checkbox";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import AltAuthBar from "@components/common/AltAuthBar";
import React from "react";
import { Link } from "react-router-dom";
import Divider from "@components/common/Divider";
import Button from "@components/form/Button";
import useCigLoginContext from "../context/useCigLoginContext";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

export default function LoginStep() {
  const { setAuthModal } = useGlobalContext();

  const {
    values,
    validations,
    onChange,
    onBlur,
    category,
    loginIsLoading,
    buttonSize,
    inputSize,
    checkboxSize,
  } = useCigLoginContext();

  return (
    <>
      <div>
        <small>
          Don't have an account?{" "}
          <Link to={decodeURI(`/cig/register?cig=${category}`)}>Sign up</Link>
        </small>

        <AltAuthBar disabled={loginIsLoading} />
      </div>

      <div className="or-divider">
        <Divider color="black-3" />
        <p>OR</p>
      </div>

      <FormRow className="break">
        <FormEntry label="EMAIL" validation={validations.email}>
          <Input
            size={inputSize}
            color="black-2"
            textColor="white"
            placeholder="example@email.com"
            type="email"
            name="email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
            disabled={loginIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label={
            <div className="flex-row justify-between">
              PASSWORD
              <Link to="#" onClick={() => setAuthModal("forgot-password")}>
                Forgot password?
              </Link>
            </div>
          }
          validation={validations.password}>
          <Input
            size={inputSize}
            color="black-2"
            textColor="white"
            placeholder="Password"
            type="password"
            name="password"
            value={values.password}
            onChange={onChange}
            onBlur={onBlur}
            disabled={loginIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry validation={validations.staySignedIn}>
          <Checkbox
            size={checkboxSize}
            variant="contained"
            color="black-3"
            textColor="purple-5"
            checkmarkColor="gray-3"
            name="staySignedIn"
            label={<span>Stay signed in</span>}
            value={values.staySignedIn}
            onChange={onChange}
            onBlur={onBlur}
            disabled={loginIsLoading}
          />
        </FormEntry>
      </FormRow>

      <div className="button-row">
        <Button
          size={buttonSize}
          color="purple"
          textColor="white"
          type="submit"
          justifyContent="center"
          isLoading={loginIsLoading}>
          Sign in
        </Button>
      </div>
    </>
  );
}
