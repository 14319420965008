import { Auth } from "aws-amplify";

const register = async (email: string, password: string) => {
  const { user } = await Auth.signUp({
    username: email,
    password: password,
  });

  return user;
};

export default register;
