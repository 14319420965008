import { UIColor } from "@lib/types/generic";
import dayjs from "dayjs";
import React, { PropsWithChildren, ReactElement } from "react";

interface Props {
  color?: UIColor;
  icon: ReactElement;
  date?: string;
  read?: boolean;
  // onClear?: () => void;
}

export default function Notification(props: PropsWithChildren<Props>) {
  const { color, icon, date, read, children } = props;

  return (
    <div className={`notification ${read ? "read" : ""} ${color}`}>
      {/* {onClear && (
        <IconButton
          icon={<IoClose />}
          variant="flat"
          size="no-padding"
          className="close-icon"
          onClick={onClear}
        />
      )} */}
      <div className="icon-wrapper">{icon}</div>

      <div className="content-wrapper">
        <div className="content">{children}</div>
        {date && <p className="date">{dayjs(date).fromNow()}</p>}
      </div>
    </div>
  );
}
