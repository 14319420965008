import DataCheck from "@components/common/DataCheck";
import Tabs, { Tab } from "@components/common/Tabs";
import Button from "@components/form/Button";
import Form from "@components/form/Form";
import ExperiencesHeader from "@pages/experiences/ExperiencesHeader";
import React, { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import useUpdateExperiencePackageContext from "./context/useUpdateExperiencePackageContext";
import Details from "./sections/Details";
import Extras from "./sections/Extras";
import Games from "./sections/Games";

const tabs: Tab[] = [
  {
    value: "games",
    label: "Games",
    component: <Games />,
  },
  {
    value: "details",
    label: "Details",
    component: <Details />,
  },
  {
    value: "extras",
    label: "Extras",
    component: <Extras />,
  },
];

export default function UpdateExperiencePackageMobile() {
  const {
    update,
    resetValidations,
    validations,
    updateIsLoading,
    fullPackageExperienceIsLoading,
    fullPackageExperienceError,
  } = useUpdateExperiencePackageContext();

  const [selectedTab, setSelectedTab] = useState<string | number>("games");

  return (
    <div id="update-experience-package-page" className="experiences-manage-page mobile">
      <ExperiencesHeader
        title="Package Setup"
        subTitle="Create bundles to help your fans request sessions even faster."
        actions={
          <Button
            color="black-4"
            textColor="white"
            size="medium"
            startIcon={<IoArrowBack />}
            justifyContent="center"
            to="/experiences">
            Back
          </Button>
        }
      />

      <Tabs
        size="small"
        variant="contained"
        color="black-1"
        textColor="gray-2"
        indicatorColor="black-4"
        indicatorTextColor="white"
        rounded
        tabs={tabs}
        selectedTab={selectedTab}
        onChange={setSelectedTab}
      />

      <Form
        id="update-package-experience"
        onCompletion={update}
        resetValidations={resetValidations}
        validation={validations.generic}>
        <DataCheck
          isLoading={fullPackageExperienceIsLoading}
          error={fullPackageExperienceError}
          loadingIndicator="spinner">
          {tabs.find(({ value }) => value === selectedTab)?.component}

          {selectedTab === "extras" && (
            <Button
              size="large"
              color="purple"
              textColor="white"
              type="submit"
              isLoading={updateIsLoading}
              justifyContent="center"
              className="save-button">
              Finish
            </Button>
          )}
        </DataCheck>
      </Form>
    </div>
  );
}
