import Accordion from "@components/common/Accordion";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import SplashTitle from "@components/splash/SplashTitle";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { Link } from "react-router-dom";

const FAQs = () => {
  const { breakpoints } = useGlobalContext();

  const accordionSize = breakpoints.xs
    ? "small"
    : breakpoints.md
    ? "medium"
    : breakpoints.sl
    ? "large"
    : "extra-large";

  return (
    <section className="section faqs">
      <SplashTitle center>FAQs</SplashTitle>

      <div className="accordions">
        <SlideOnIntersect direction="right" timeout={500}>
          <div>
            <Accordion title="How do I join Yakkr?" color="black-2" size={accordionSize}>
              <Link to="/cig/register">Click here</Link> and let's get you set up - it's 100% free
              to join!
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={700}>
          <div>
            <Accordion title="How does it work?" color="black-2" size={accordionSize}>
              <ul>
                <li>Set your preferred games, availability, and price rates</li>
                <br />
                <li>Let your audience know they can game with you</li>
                <br />
                <li>We'll take care of the rest and pay you every week</li>
              </ul>
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={800}>
          <div>
            <Accordion
              title="Do I have to charge for my sessions?
"
              color="black-2"
              size={accordionSize}>
              Nope, you don't have to charge for your sessions. You can run them for free if you
              wish! This is great for smaller streamers who are trying to build up their community
              and get to know their followers better.
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={850}>
          <div>
            <Accordion title="Will my viewers like this?" color="black-2" size={accordionSize}>
              Look we get it, you're not sure your fans will want to pay to play with you. But with
              how quickly we're growing we know that they absolutely do!
              <br />
              <br />
              You can give them the best experience they could ask for and they'll be more than
              happy to pay you for that.
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={850}>
          <div>
            <Accordion title="How do I earn more on Yakkr?" color="black-2" size={accordionSize}>
              We'll pay you for gaming sessions your fans book with you. Even if you decide to host
              free gaming events with your community we have in-built tipping features and bonuses.
            </Accordion>
          </div>
        </SlideOnIntersect>

        <SlideOnIntersect direction="right" timeout={875}>
          <div>
            <Accordion title="Will I be successful on Yakkr?" color="black-2" size={accordionSize}>
              Streamers who earn the most of Yakkr are those who post to their socials or let their
              chat know that they can game with them. We're like Cameo but for gaming. Let them know
              and they will come!
              <br />
              <br />
              It's easier than you think, plus we're free to sign up so why not give us a go!
            </Accordion>
          </div>
        </SlideOnIntersect>
      </div>
    </section>
  );
};

export default FAQs;
