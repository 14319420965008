import useElementSize from "./useElementSize";
import useGlobalContext from "../globalContext/hooks/useGlobalContext";
import useIsKeyboardOpen from "./useIsKeyboardOpen";

const useIsLandscape = () => {
  const { breakpoints } = useGlobalContext();
  const { width, height } = useElementSize();

  const isKeyboardOpen = useIsKeyboardOpen();

  const isLandscape = width > height && breakpoints.md && !isKeyboardOpen;

  return isLandscape;
};

export default useIsLandscape;
