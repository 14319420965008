import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createExperienceRequest, { CreateExperienceRequestRes } from "../createExperienceRequest";

const useCreateExperienceRequest = (
  gameID?: string,
  platformID?: string,
  cigID?: string,
  customerRequestDetails?: string,

  tickets?: number,
  streamOption?: boolean,

  startTime?: string,
  endTime?: string,

  packageID?: string,
) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CreateExperienceRequestRes | null>(null);

  const send = useCallback(async () => {
    if (
      gameID == null ||
      platformID == null ||
      cigID == null ||
      customerRequestDetails == null ||
      startTime == null ||
      endTime == null
    )
      return reqState;

    if (packageID == null && (tickets == null || streamOption == null)) return reqState;

    setLoading();
    try {
      const data = await createExperienceRequest(
        gameID,
        platformID,
        cigID,
        customerRequestDetails,

        tickets,
        streamOption,

        startTime,
        endTime,

        packageID,
      );
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [
    gameID,
    platformID,
    cigID,
    customerRequestDetails,

    tickets,
    startTime,

    endTime,
    streamOption,

    packageID,
    reqState,
    setError,
    setLoading,
    setSuccess,
  ]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateExperienceRequest;
