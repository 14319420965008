import React from "react";
import stripeImg from "@images/brands/stripe.svg";
import GradientButton from "@components/form/GradientButton";
import useOnboarding from "@hooks/stripe/useOnboarding";

export default function StripeSetupStep() {
  const { isLoading, isRedirecting, onboard } = useOnboarding();

  return (
    <div className="stripe-setup">
      <img src={stripeImg} alt="Stripe" className="stripe-logo" />

      <h1 className="title">Accept Payments</h1>
      <h2 className="sub-title">To receive payments you need to set up your payment account.</h2>

      <GradientButton
        textColor="white"
        size="large"
        gradient="gradient-vibrant-blend-horizontal"
        animated
        onClick={onboard}
        isLoading={isLoading}
        isCtaing={isRedirecting}
        className="stripe-button">
        Payment set up
      </GradientButton>
    </div>
  );
}
