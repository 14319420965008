import { CIGProfilePartial } from "@api/public/get/getCigs";
import { GAItem } from "@lib/types/ga";
import { Session } from "@lib/types/session";
import dayjs from "dayjs";

export class GAConverters {
  static listing = {
    viewer: (category?: string) => (listing: any, index: number) => {
      // const { id, displayName } = listing;
      // const availability = getLowestPrice(listing.availability.items);
      // return {
      //   index,
      //   item_id: id,
      //   item_name: displayName,
      //   item_list_id: category,
      //   item_list_name: category,
      //   price: availability?.price,
      //   currency: availability?.currency,
      // };
    },
    calendar:
      (cigId?: string, cigName?: string, quantity: number = 1) =>
      (listing: Session, index: number): GAItem => {
        const { id, currency, price, from, to, game, platform, discount } = listing;

        return {
          index,

          item_id: id,
          item_name: `${cigName} - ${game.shortName}/${platform.shortName} | ${dayjs(from).format(
            "DD/MM/YY HH:mm Z",
          )} - ${dayjs(to).format("DD/MM/YY HH:mm Z")}`,

          item_list_id: cigId,
          item_list_name: cigName,

          price,
          currency,
          discount,
          quantity,
        };
      },
  };
  static search = {
    cig:
      (searchTerm?: string) =>
      (cig: CIGProfilePartial): GAItem => {
        const { id, displayName } = cig ?? {};

        return {
          item_id: id,
          item_name: displayName,

          item_list_id: searchTerm,
          item_list_name: searchTerm,
        };
      },
  };
}
