import { api } from "@api/api";

interface CreateUserExtensionRes {
  id: string;
}

const createUserExtension = async (
  firstName: string,
  lastName: string,
  displayName: string,
  birthdate: string,
) => {
  const { data } = await api.post<CreateUserExtensionRes>("/createUserExtension", {
    firstName,
    lastName,
    displayName,
    birthdate,
  });
  return data;
};

export default createUserExtension;
