import React from "react";

interface Props {
  label: string;
  subLabel?: string;
}

export default function ExperiencesManageLabel(props: Props) {
  const { label, subLabel } = props;

  return (
    <>
      <p className="label">{label}</p>
      {subLabel && <p className="sub-label">{subLabel}</p>}
    </>
  );
}
