import React from "react";
import GradientIcon, { GradientIconInnerProps, GradientIconProps } from "./GradientIcon";

const IoCalendarGradient = (props: GradientIconProps) => (
  <GradientIcon {...props}>
    {({ gradientDefinition, label, ...cssProps }: GradientIconInnerProps) => (
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        {...cssProps}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={label ? `url(#${label})` : undefined}
          d="M480 128a64 64 0 00-64-64h-16V48.45c0-8.61-6.62-16-15.23-16.43A16 16 0 00368 48v16H144V48.45c0-8.61-6.62-16-15.23-16.43A16 16 0 00112 48v16H96a64 64 0 00-64 64v12a4 4 0 004 4h440a4 4 0 004-4zM32 416a64 64 0 0064 64h320a64 64 0 0064-64V179a3 3 0 00-3-3H35a3 3 0 00-3 3zm344-208a24 24 0 11-24 24 24 24 0 0124-24zm0 80a24 24 0 11-24 24 24 24 0 0124-24zm-80-80a24 24 0 11-24 24 24 24 0 0124-24zm0 80a24 24 0 11-24 24 24 24 0 0124-24zm0 80a24 24 0 11-24 24 24 24 0 0124-24zm-80-80a24 24 0 11-24 24 24 24 0 0124-24zm0 80a24 24 0 11-24 24 24 24 0 0124-24zm-80-80a24 24 0 11-24 24 24 24 0 0124-24zm0 80a24 24 0 11-24 24 24 24 0 0124-24z"
        />
        {gradientDefinition && (
          <defs>
            <linearGradient
              id={label}
              gradientUnits="userSpaceOnUse"
              gradientTransform={`rotate(${gradientDefinition.rotation})`}>
              {gradientDefinition.stopColors.map(({ stopColor, offset }, i) => (
                <stop key={i} offset={offset} stopColor={stopColor} />
              ))}
            </linearGradient>
          </defs>
        )}
      </svg>
    )}
  </GradientIcon>
);

export default IoCalendarGradient;
