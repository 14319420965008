import {
  FormValidations,
  OnFormBlur,
  OnFormChange,
  OnFormChangeMultiple,
  ResetValidations,
} from "@components/form/hooks/useFormManager";
import { FormHandle } from "@lib/types/form";
import React, { createContext, PropsWithChildren, useState } from "react";
import useCigForgotPassword, { ForgotPasswordValues } from "../hooks/useForgotPassword";

export interface ForgotPasswordModalData {
  redirect?: string;
  email?: string;
}

interface ForgotPasswordContextType {
  values: ForgotPasswordValues;
  validations: FormValidations<ForgotPasswordValues>;

  onChange: OnFormChange;
  onBlur: OnFormBlur;
  onChangeMultiple: OnFormChangeMultiple;

  handles: FormHandle[];
  resetValidations: ResetValidations;

  forgotPasswordConfirmIsloading: boolean;
  forgotPasswordSubmitIsLoading: boolean;

  step: number;
  setStep: (step: number) => void;
}

export const ForgotPasswordContext = createContext<ForgotPasswordContextType>(
  {} as ForgotPasswordContextType,
);

interface Props {
  onStepChange: (step: number) => void;
}

export default function ForgotPasswordContextProvider(props: PropsWithChildren<Props>) {
  const { onStepChange, children } = props;

  const [step, setStep] = useState(0);

  const {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles,
    resetValidations,

    forgotPasswordConfirmIsloading,
    forgotPasswordSubmitIsLoading,
  } = useCigForgotPassword();

  return (
    <ForgotPasswordContext.Provider
      value={{
        values,
        validations,

        onChange,
        onBlur,
        onChangeMultiple,

        handles,
        resetValidations,

        forgotPasswordConfirmIsloading,
        forgotPasswordSubmitIsLoading,

        step,
        setStep: step => {
          setStep(step);
          onStepChange(step);
        },
      }}>
      {children}
    </ForgotPasswordContext.Provider>
  );
}
