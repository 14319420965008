import { CIG, CountryCode, Currency, VerificationPlatform } from "@lib/enums/generic";
import { api } from "@api/api";

export interface CIGProfile {
  id: string;

  bannerPictureURL: string;
  category: CIG;
  country: CountryCode;
  defaultCurrency: Currency;
  displayName: string;
  profilePictureURL: string;
  subCategory: string;
  tagLine: string;
  shortDescription: string | null;

  instagramHandle: string;
  tiktokHandle: string;
  twitchHandle: string;
  twitterHandle: string;
  youtubeHandle: string;

  accepted: boolean;
  verified: boolean;
  featured: boolean;

  verifiedHandle: string;
  verifiedPlatformName: VerificationPlatform;
}

const getCigProfile = async (cigID?: string, cigDisplayName?: string, userID?: string) => {
  const { data } = await api.get<CIGProfile>(cigID ? "/cig" : "/cigBasicInfoFromDisplayName", {
    params: { cigID, cigDisplayName, userID },
  });
  return data;
};

export default getCigProfile;
