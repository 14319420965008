import { Currency } from "@lib/enums/generic";
import { api } from "@api/api";
import { Compatibility } from "./getCompatibility";

export interface CigPackageExperience {
  packageID: string;

  anyGame: boolean;
  anyTime: boolean;

  name: string;
  extraDetails: string;
  tagLine: string;

  sessionLength: number;
  tickets: number;

  streamOption: boolean;

  priceIncTax: number;
  priceExTax: number;
  currency: Currency;

  games: Compatibility[];
}

const getCigPackageExperiences = async (cigID: string) => {
  const { data } = await api.get<CigPackageExperience[]>("/getCIGPackagesBasicDetails", {
    params: { cigID },
  });
  return data;
};

export default getCigPackageExperiences;
