import Section from "@components/section/Section";
import React, { useState } from "react";
import { IoAdd } from "react-icons/io5";
import Button from "@components/form/Button";
import supportImg from "@images/misc/support.png";
import CreateSupportTicketModal from "../CreateClaimModal";
import SectionBar from "@components/section/SectionBar";
import { useHistory } from "react-router-dom";

interface Props {
  initialBookingId?: string;
  onSubmit?: () => void;
}

export default function CreateAClaim(props: Props) {
  const { initialBookingId, onSubmit } = props;

  const history = useHistory();

  const [modalActive, setModalActive] = useState(!!initialBookingId);

  return (
    <Section className="make-a-claim">
      <SectionBar dynamicHeight>
        <div className="wrapper">
          <div className="title-wrapper">
            <h2 className="title">
              Need something?
              <br />
              We're here to help.
            </h2>

            <Button
              color="purple"
              textColor="white"
              startIcon={<IoAdd />}
              onClick={() => setModalActive(true)}>
              Create a claim
            </Button>
          </div>

          <img src={supportImg} alt="Help" />
        </div>
      </SectionBar>

      <CreateSupportTicketModal
        active={modalActive}
        onClose={() => {
          setModalActive(false);
          history.push("/settings/support");
        }}
        onSubmit={onSubmit}
        redirect
        initialBookingId={initialBookingId}
      />
    </Section>
  );
}
