import Avatar from "@components/common/Avatar";
import FluentThinkingFace from "@components/icons/FluentThinkingFace";
import Arrow from "./Arrow";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useState } from "react";
import { classNames } from "@lib/utils/generic";
import Button from "@components/form/Button";
import { IoChevronDown } from "react-icons/io5";

import avatar9 from "@images/landing/consultancy/avatars/avatar9.png";

const HowItWorks = () => {
  const [readingMore, setReadingMore] = useState(false);

  const { breakpoints } = useGlobalContext();

  return (
    <section className="pane how-it-works" id="how-it-works">
      <div className="inner">
        <FluentThinkingFace className="flow-icon" />
        <h2 className="flow-sub-title text-gradient-blend-horizontal">
          Okay, this sounds great - how does it work?
        </h2>

        <div className="steps-wrapper">
          <Arrow className="arrow" />

          <div className="steps">
            <div className="step">
              <p className="number">1</p>
              <p className="text">
                First you pick which growth package is best for you. We have basic, standard, and
                premium options.
              </p>
            </div>
            <div className="step">
              <p className="number">2</p>
              <p className="text">
                You then get exclusive access to our discord where you can collab with other
                streamers, get your own task dashboard, and educational videos.
              </p>
            </div>
            <div className="step">
              <p className="number">3</p>
              <p className="text">
                Depending on your chosen package you'll then get your very own personal growth
                consultations, and mentorship from an established Twitch partner.
              </p>
            </div>
          </div>
        </div>

        <h2 className="flow-sub-title text-gradient-blend-horizontal">
          Do your services really work? - here's what Ar1seDota has to say
        </h2>

        <div className="testimonial">
          <div className="testimonial-inner">
            <div className="user">
              <Avatar
                src={avatar9}
                alt="Ar1seDota"
                size={breakpoints.sm ? 80 : breakpoints.md ? 140 : 160}
              />
              <div className="user-details">
                <p className="display-name">Ar1seDota</p>
                <p className="tag-line">Twitch Partner</p>
              </div>
            </div>

            <p className="flow-text full-width statement">
              Since working with the team at Yakkr, I've grown my stream by 30% and the retention
              rate has skyrocketed!
            </p>

            <div className="sub-title">Here's what we did</div>

            <div
              className={classNames(
                "flow-text full-width what-we-did",
                readingMore && "reading-more",
              )}>
              First, we analysed Ar1se's stream and compared him to other Dota content creators.
              <br />
              <br />
              After consultation with the team and other streamers, we quickly identified that he
              had an amazing untapped opportunity in TikTok & YouTube.
              <br />
              <br />
              So we set him up with some fantastic video editors and content strategists to produce
              viral videos every month which have supported growth on his channel.
              <br />
              <br />
              Then, after optimising his Twitch's about page and providing 'engagement' coaching, it
              was all about collaborations.
              <br />
              <br />
              We helped him organise tournaments with other streamers where they'd battle it out.
              Being able to partner up with other streamers is the secret sauce to success and our
              Discord is perfect for that.
            </div>

            <Button
              size={breakpoints.sm ? "medium" : "large"}
              variant="flat"
              color="purple"
              textColor="purple"
              endIcon={<IoChevronDown />}
              onClick={() => setReadingMore(readingMore => !readingMore)}
              className={classNames(readingMore && "reading-more")}>
              {readingMore ? "SHOW LESS" : "READ MORE"}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
