import useOnPageClose from "@hooks/useOnPageClose";
import { log } from "@lib/utils/generic";
import { Client } from "@twilio/conversations";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { RemoteParticipant, Room, TwilioError } from "twilio-video";

const useRoomManager = (
  videoRoom?: Room,
  chatClient?: Client,
  roomSocket?: WebSocket,
  setDominnantParticipantId?: Dispatch<SetStateAction<string | undefined>>,
  onDisconnect?: () => void,
) => {
  const [disconnected, setDisconnected] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [reconnectError, setReconnectError] = useState<string>();
  const [disconnectError, setDisconnectError] = useState<string>();

  const handleReconnecting = useCallback((error: TwilioError) => {
    setIsReconnecting(true);
    setReconnectError(error.message);
  }, []);

  const handleReconnected = useCallback(() => {
    setIsReconnecting(false);
    setReconnectError(undefined);
  }, []);

  const handleDisconnected = useCallback((_videoRoom: Room, error: TwilioError) => {
    if (error) setDisconnectError(error.message);
  }, []);

  const handleDominantSpeakerChange = useCallback(
    (participant: RemoteParticipant) => {
      if (!setDominnantParticipantId) return;
      setDominnantParticipantId(participant?.identity);
    },
    [setDominnantParticipantId],
  );

  useEffect(() => {
    videoRoom?.on("reconnecting", handleReconnecting);
    videoRoom?.on("reconnected", handleReconnected);
    videoRoom?.on("disconnected", handleDisconnected);
    videoRoom?.on("dominantSpeakerChanged", handleDominantSpeakerChange);

    return () => {
      videoRoom?.off("reconnecting", handleReconnecting);
      videoRoom?.off("reconnected", handleReconnected);
      videoRoom?.off("disconnected", handleDisconnected);
      videoRoom?.off("dominantSpeakerChanged", handleDominantSpeakerChange);
    };
  }, [
    videoRoom,
    handleReconnecting,
    handleReconnected,
    handleDisconnected,
    handleDominantSpeakerChange,
  ]);

  const disconnect = useCallback(() => {
    videoRoom?.disconnect();
    chatClient?.shutdown();
    setDisconnected(true);
    if (onDisconnect) onDisconnect();
  }, [videoRoom, chatClient, onDisconnect]);

  const disconnectRef = useRef(disconnect);
  disconnectRef.current = disconnect;

  // disconnects client when the component unmounts
  useEffect(() => () => disconnectRef.current(), []);

  useOnPageClose(disconnect);

  const handleParticipantKicked = useCallback(
    (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);
        const identity = videoRoom?.localParticipant.identity;
        if (data.state === "KICKED" && data.identity === identity) disconnect();
      } catch (err) {
        log(err);
      }
    },
    [disconnect, videoRoom?.localParticipant.identity],
  );

  useEffect(() => {
    if (!roomSocket) return;
    roomSocket.addEventListener("message", handleParticipantKicked);

    return () => roomSocket.removeEventListener("message", handleParticipantKicked);
  }, [roomSocket, handleParticipantKicked]);

  return {
    isReconnecting,
    reconnectError,
    disconnectError,
    disconnected,
    disconnect,
    setDisconnected,
  };
};

export default useRoomManager;
