import TeamMember from "./TeamMember";

import alfieAvatar from "@images/landing/consultancy/teamAvatars/alfie.png";
import marcusAvatar from "@images/landing/consultancy/teamAvatars/marcus.png";
import cancelAvatar from "@images/landing/consultancy/teamAvatars/cancel.png";
import nealAvatar from "@images/landing/consultancy/teamAvatars/neal.png";
import Divider from "@components/common/Divider";
import LandingTestimonial from "../../LandingTestimonial";
import Button from "@components/form/Button";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

import avatar4 from "@images/landing/consultancy/avatars/avatar4.png";
import { IoArrowForward } from "react-icons/io5";

const WhyUseUs = () => {
  const { breakpoints } = useGlobalContext();

  const buttonSize = breakpoints.sm ? "medium" : "large";

  return (
    <section className="pane why-use-us">
      <div className="inner">
        <h2 className="flow-sub-title text-gradient-blend-horizontal">Why should you use us?</h2>
        <p className="flow-text sub-text">
          Our consultants & mentors have extensive experience in branding, influencer & growth
          marketing, sales, content creation and data analytics.
          <br />
          <br />
          <br />
          Here are some experts that have <span>recently joined our growth team:</span>
        </p>

        <div className="team">
          <TeamMember
            img={alfieAvatar}
            name="Alfie Godfrey"
            role="Growth Marketer"
            about="CIM Certified Marketer. Social Media, Brand, Content, Analytics"
            favouriteGame="Apex Legends"
            funFact="I used to work at a cloud gaming company based in Silicon Valley"
            isNew
          />
          <TeamMember
            img={marcusAvatar}
            name="Marcus Rowley"
            role="Consultant"
            about="Business and Strategy Consultant. Sponsorships & Partnerships"
            favouriteGame="Call of Duty: Modern Warfare 2"
            funFact="I raised over £240K in deals by the age of 24 "
            isNew
          />
          <TeamMember
            img={cancelAvatar}
            name="Canceldota"
            role="Mentor"
            about="Dota 2 Streamer and Twitch Partner with over 180,000 supporters."
            favouriteGame="Dota 2"
            funFact="I became a Dota 2 Esports Pro at 16 playing for FNatic and Romania"
          />
          <TeamMember
            img={nealAvatar}
            name="NealGuides"
            role="Mentor"
            about="FIFA YouTuber and Twitch Partner with over 290,000 supporters."
            favouriteGame="FIFA 23"
            funFact="I made my first YouTube channel when I was 10 years old"
          />
        </div>

        <div className="divider-wrapper">
          <Divider color="old-white" width="2px" />
          <p className="many-more">+ many more</p>
        </div>

        <LandingTestimonial
          avatarImg={avatar4}
          displayName="Tuci Gaming"
          tagLine="Twitch Affiliate">
          Finally a company understands how difficult streaming is. Being able to collab with
          similar streamers is a great feature.
        </LandingTestimonial>

        <div className="button-row">
          <Button
            size={buttonSize}
            color="purple"
            textColor="white"
            endIcon={<IoArrowForward />}
            href="#sign-up"
            justifyContent="center">
            Sign Up Now
          </Button>
          <Button size={buttonSize} color="gray-3" textColor="white" href="#last-questions">
            I need more info
          </Button>
        </div>
      </div>
    </section>
  );
};

export default WhyUseUs;
