import Button from "@components/form/Button";
import TopSplash from "@components/splash/sections/TopSplash";
import { useEffect, useState } from "react";
import { IoArrowForward } from "react-icons/io5";
import FlankSignUpModal from "./modal/FlankSignUpModal";
import topSplash from "@images/landing/flank/top_splash.png";
import Testimonials from "./sections/Testimonials";
import SplitViews from "./sections/SplitViews";
import HowItWorks from "./sections/HowItWorks";
import Perks from "./sections/Perks";
import FAQs from "./sections/FAQs";
import Cta from "./sections/Cta";
import FlankBanner from "@components/common/FlankBanner";
import flankLogo from "@images/landing/flank/flank_logo.png";

const ConsultancyLanding = () => {
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    document.title = "Flank";
    const linkElement = document.querySelector("link[rel='icon']");
    if (!linkElement) return;
    linkElement.setAttribute("href", flankLogo);
  }, []);

  return (
    <div id="flank-landing-page" className="splash">
      <TopSplash
        title="GET BRAND DEALS FOR YOUR TWITCH STREAM"
        subTitle={
          <>
            Through our Streamer marketplace, we connect Twitch Partners & Affiliates like you with
            brands, giving you <span className="gradient">100% of the money</span> and helping take
            your Twitch stream and career to the next level.
          </>
        }
        buttons={
          <>
            <Button
              color="white"
              textColor="black-1"
              endIcon={<IoArrowForward />}
              onClick={() => setModalActive(true)}>
              Sign Up
            </Button>
            <Button color="black-2" textColor="white" href="#how-it-works">
              How does it work?
            </Button>
          </>
        }
        imgSrc={topSplash}
      />

      <FlankBanner />

      <Testimonials />
      <SplitViews onSignUpClick={() => setModalActive(true)} />
      <HowItWorks />
      <Perks onSignUpClick={() => setModalActive(true)} />
      <FAQs />
      <Cta onSignUpClick={() => setModalActive(true)} />

      <FlankSignUpModal active={modalActive} onClose={() => setModalActive(false)} />
    </div>
  );
};

export default ConsultancyLanding;
