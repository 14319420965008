import { CreateSessionValues } from "@components/calendar/manage/create/CreateSession";
import { Currency } from "@lib/enums/generic";
import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const updateSessionValidators = {
  price: (validation: FormValidation, value: any, values: CreateSessionValues) => {
    new Validator(validation, value)
      .exists()
      .is("number")
      .skipNextIf(values.currency === Currency.CZK)
      .number.greaterThanOrEqualTo(10)
      .skipNextIf(values.currency !== Currency.CZK)
      .number.greaterThanOrEqualTo(15);
  },
  message: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").length.lessThanOrEqualTo(500);
  },
};

export default updateSessionValidators;
