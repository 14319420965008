import CtaButton from "@components/splash/CtaButton";
import SplitViews from "@pages/becomeTalent/sections/SplitViews";
import TopSplash from "@components/splash/sections/TopSplash";
import SplashFooter from "@components/splash/SplashFooter";
import clip from "@videos/becomeTalent/clip.mp4";
import FanTestimonials from "./sections/FanTestimonials";
import FAQs from "./sections/FAQs";
import HowItWorks from "./sections/HowItWorks";
import Testimonials from "./sections/Testimonials";
import Button from "@components/form/Button";

const BecomeTalent = () => {
  return (
    <div id="become-talent-page" className="splash">
      <TopSplash
        title={<>Skyrocket your stream earnings</>}
        subTitle={
          <>
            Get paid to have fun playing games with your community! Our platform allows you to
            connect with and entertain your fans while earning money.
          </>
        }
        buttons={
          <>
            <CtaButton to="/cig/register">Join now</CtaButton>
            <Button color="gray-1" textColor="white" href="#how-it-works">
              How does it work?
            </Button>
          </>
        }
        clipSrc={clip}
      />
      <Testimonials />
      <HowItWorks />

      <FanTestimonials />
      <SplitViews />
      <FAQs />

      <SplashFooter />
    </div>
  );
};

export default BecomeTalent;
