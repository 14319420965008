import CurrencyInput from "@components/form/CurrencyInput";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import ToggleSwitch from "@components/form/ToggleSwitch";
import { getCurrencySymbol } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, { useEffect } from "react";
import ExperiencesManageLabel from "../../../ExperiencesManageLabel";
import useUpdateExperiencePackageContext from "../context/useUpdateExperiencePackageContext";

export default function Prices() {
  const { cigProfile } = useGlobalContext();

  const { values, validations, onChange, onBlur, clearValidation } =
    useUpdateExperiencePackageContext();

  useEffect(() => {
    if (!values.streamOptionEnabled) {
      onChange({ name: "streamOptionPricePerPeriod", value: 0 });
      clearValidation("streamPricePerPeriod");
    }

    // eslint-disable-next-line
  }, [values.streamOptionEnabled]);
  // must omit onChange to prevent loop

  return (
    <div className="form-section">
      <FormRow>
        <FormEntry
          label={
            <ExperiencesManageLabel
              label="3. Set your prices"
              subLabel="Choose your price rates."
            />
          }
          validation={validations.pricePerPeriod}
          relativePosition="top">
          <CurrencyInput
            size="extra-large"
            color="black-3"
            textColor="gray-3"
            name="pricePerPeriod"
            placeholder="0.00"
            value={values.pricePerPeriod}
            currency={getCurrencySymbol(cigProfile?.defaultCurrency)}
            onChange={onChange}
            onBlur={onBlur}
            oversized
          />
          <p className="bottom-text">ex. sales tax per 15 minutes</p>
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry validation={validations.streamOptionEnabled} relativePosition="top">
          <div className="toggle-switch-outer">
            <ToggleSwitch
              size="small"
              color="black-1"
              thumbColor="black-4"
              textColor="white"
              selectedColor="purple"
              selectedThumbColor="black-2"
              name="streamOptionEnabled"
              label="Option for session to be livestreamed"
              value={values.streamOptionEnabled}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry validation={validations.streamPricePerPeriod} relativePosition="top">
          <CurrencyInput
            size="extra-large"
            color="black-3"
            textColor="gray-3"
            name="streamPricePerPeriod"
            placeholder="0.00"
            value={values.streamPricePerPeriod}
            currency={getCurrencySymbol(cigProfile?.defaultCurrency)}
            onChange={onChange}
            onBlur={onBlur}
            oversized
            disabled={!values.streamOptionEnabled}
          />
          <p className="bottom-text">ex. sales tax per 15 minutes when streamed</p>
        </FormEntry>
      </FormRow>
    </div>
  );
}
