import React from "react";
import {
  MdSignalCellularNodata,
  MdSignalCellularConnectedNoInternet1Bar,
  MdSignalCellular1Bar,
  MdSignalCellular2Bar,
  MdSignalCellular3Bar,
  MdSignalCellular4Bar,
} from "react-icons/md";
import { VideoParticipant } from "../objects/RoomParticipant";
import useNetworkQualityLevel from "./hooks/useNetworkQualityLevel";

interface Props {
  videoParticipant?: VideoParticipant;
}

export default function NetworkQualityIcon(props: Props) {
  const { videoParticipant } = props;

  const networkQualityLevel = useNetworkQualityLevel(videoParticipant);

  if (networkQualityLevel == null) return null;

  switch (networkQualityLevel) {
    case 0:
      return <MdSignalCellularNodata />;
    case 1:
      return <MdSignalCellularConnectedNoInternet1Bar />;
    case 2:
      return <MdSignalCellular1Bar />;
    case 3:
      return <MdSignalCellular2Bar />;
    case 4:
      return <MdSignalCellular3Bar />;
    case 5:
      return <MdSignalCellular4Bar />;
    default:
      return null;
  }
}
