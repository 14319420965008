import IconButton from "@components/form/IconButton";
import React, { useCallback, useEffect, useState } from "react";
import { IoSwapHorizontal } from "react-icons/io5";
import useRoomContext from "../roomContext/hooks/useRoomContext";

export default function CameraSwitchButton() {
  const { videoInputDeviceId, videoInputDevices, setVideoInputDeviceId } = useRoomContext();

  const [onCooldown, setOnCooldown] = useState(false);

  const handleSwitch = useCallback(() => {
    let index = videoInputDevices.findIndex(({ deviceId }) => deviceId === videoInputDeviceId);
    if (index < 0) index = 0;
    const nextDevice = videoInputDevices[index + 1] ?? videoInputDevices[0];
    if (nextDevice == null) return;
    setVideoInputDeviceId(nextDevice.deviceId);
  }, [videoInputDeviceId, videoInputDevices, setVideoInputDeviceId]);

  useEffect(() => {
    setOnCooldown(true);
    const timeout = setTimeout(() => {
      setOnCooldown(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [videoInputDeviceId, setOnCooldown]);

  return (
    <IconButton
      size="small"
      color="white"
      iconColor="black"
      rounded
      icon={<IoSwapHorizontal />}
      onClick={handleSwitch}
      disabled={onCooldown}
    />
  );
}
