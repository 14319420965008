import BackgroundImage from "@components/common/BackgroundImage";
import DataCheck from "@components/common/DataCheck";
import Divider from "@components/common/Divider";
import IoRadioGradient from "@components/icons/IoRadioGradient";
import { Currency } from "@lib/enums/generic";
import { CSSProps } from "@lib/types/generic";
import {
  classNames,
  getCurrencySymbol,
  numberToPrice,
  parseTime,
  pluralise,
} from "@lib/utils/generic";
import dayjs, { Dayjs } from "dayjs";
import { cloneElement, ReactElement } from "react";

interface Props extends CSSProps {
  eventName?: string;
  eventIcon?: ReactElement;

  title?: string;
  extraInfo?: string | null;

  slotsAvailable?: number;
  price?: number;
  currency?: Currency;
  free?: boolean;

  startDate?: Dayjs;
  endDate?: Dayjs;

  gameImg?: string | null;

  width?: number | string;

  isLoading?: boolean;

  onClick?: () => void;
}

const CigEvent = (props: Props) => {
  const {
    eventName = "COMMUNITY SESSION",
    eventIcon = <IoRadioGradient gradient="gradient-faded-horizontal" />,

    title,
    extraInfo,

    slotsAvailable = 0,
    price = 0,
    currency,
    free,

    startDate,
    endDate,

    gameImg,

    width = 443,

    isLoading,

    onClick,

    className,
    id,
    style,
  } = props;

  const { hours, minutes } = parseTime(dayjs(startDate).diff(endDate) / 1000);

  return (
    <div
      className={classNames("cig-event", isLoading && "is-loading", className)}
      id={id}
      style={{ fontSize: width, ...style }}
      onClick={onClick}>
      <DataCheck isLoading={isLoading} imgs={[gameImg]} loadingIndicator="gloss">
        <div className="details-wrapper">
          <div className="top-wrapper">
            <div className="event-type-wrapper">
              {cloneElement(eventIcon, {
                className: classNames(eventIcon.props.className, "event-icon"),
              })}
              <p className="event-type">{eventName}</p>
            </div>

            <p className="title">{title}</p>
            <p className="extra-details">{extraInfo}</p>
          </div>

          <Divider />

          <div className="bottom-wrapper">
            <p className="detail">
              {slotsAvailable} <span>{pluralise("ticket", slotsAvailable)}</span>
            </p>
            <p className="detail">
              {hours > 0 && (
                <>
                  {hours}
                  <span>h</span>
                </>
              )}
              {minutes > 0 && (
                <>
                  {minutes}
                  <span>m</span>
                </>
              )}
            </p>
            <p className="detail">
              {free ? (
                <>Free</>
              ) : (
                <>
                  <span>{getCurrencySymbol(currency)}</span>
                  {numberToPrice(price)}
                </>
              )}
            </p>
          </div>
        </div>

        <div className="game-wrapper">
          <BackgroundImage src={gameImg} opacity={0.6} color="black" />

          <p className="date">{startDate?.format("DD MMM YY")}</p>
          <p className="time">{startDate?.format("HH:mm")}</p>
        </div>
      </DataCheck>
    </div>
  );
};

export default CigEvent;
