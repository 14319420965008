import { colors } from "@lib/constants/colors";
import { UIColor } from "@lib/types/generic";
import { cloneElement, PropsWithChildren, ReactElement } from "react";

interface Props {
  color: UIColor;
  icon: ReactElement;
  title: string;
}

const FeatureSet = (props: PropsWithChildren<Props>) => {
  const { color, icon, title, children } = props;

  return (
    <div
      className="feature-set"
      style={
        {
          "--color": colors[color],
        } as any
      }>
      <div className="feature-set-top">
        {cloneElement(icon, {
          className: "feature-set-icon",
        })}
        <h3 className="feature-set-title">{title}</h3>
      </div>

      <div className="feature-set-boxes">{children}</div>
    </div>
  );
};

export default FeatureSet;
