import Form from "@components/form/Form";
import GAEvent from "@lib/utils/GAEvent";
import { createSuccessSnackbar } from "@lib/utils/generic";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useHistory } from "react-router-dom";
import { LoginModalData } from "./context/LoginContextProvider";
import useLoginContext from "./context/useLoginContext";
import LoginStep from "./steps/LoginStep";

export default function LoginForm() {
  const history = useHistory();

  const { setAuthModal, pushSnackbar } = useGlobalContext();

  const { handles, resetValidations, validations, setStep } = useLoginContext();

  const { redirect } = useAuthModalData<LoginModalData>();

  const [handle] = handles;

  return (
    <Form
      id="login-form"
      onStepChange={setStep}
      handle={handle}
      resetValidations={resetValidations}
      onCompletion={() => {
        setAuthModal(null);
        pushSnackbar(createSuccessSnackbar("Sign in successful!", 5000));
        GAEvent.login("Email");
        history.push(redirect ?? "/");
      }}
      validation={validations.generic}
      triggerGlobalLoading>
      <LoginStep />
    </Form>
  );
}
