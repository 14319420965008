import { sortDirectionIcons, sortDirectionInvert } from "@lib/constants/generic";
import { SortDirection } from "@lib/enums/generic";
import React, { Children, cloneElement, isValidElement, PropsWithChildren } from "react";

interface Props {
  sortKey: string | null;
  sortDirection: SortDirection;
  onSortKeyChange?: (sortType: string) => void;
  onSortDirectionChange?: (sortDirection: SortDirection) => void;
}

export default function SectionHead(props: PropsWithChildren<Props>) {
  const {
    sortKey,
    sortDirection,
    children,
    onSortKeyChange = () => {},
    onSortDirectionChange = () => {},
  } = props;

  return (
    <tr className="section-head">
      {Children.map(children, child => {
        if (!isValidElement(child)) return child;
        const key = child.props.id;

        if (key == null) return child;

        const onClick =
          sortKey === key
            ? () => {
                onSortDirectionChange(sortDirectionInvert[sortDirection]);
              }
            : () => {
                onSortDirectionChange(SortDirection.Ascending);
                onSortKeyChange(key);
              };

        return cloneElement(
          child as any,
          {
            onClick,
            className: "can-sort",
          },
          <InnerCell isSorting={sortKey === key} sortDirection={sortDirection}>
            {child.props.children}
          </InnerCell>,
        );
      })}
    </tr>
  );
}

const InnerCell = ({
  isSorting,
  sortDirection,
  children,
}: PropsWithChildren<{ isSorting: boolean; sortDirection: SortDirection }>) => (
  <div className="sort-wrapper">
    {children}
    {isSorting && sortDirectionIcons[sortDirection]}
  </div>
);
