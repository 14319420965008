import { useCallback } from "react";
import useStorageItem from "./useStorageItem";

export interface TicketShareStatus {
  sessionId: string;
  sharedTicketIds: string[];
}

const useTicketShareStatusStore = (sessionId: string = "") => {
  const localStore = useStorageItem<TicketShareStatus>(`ticket-share-status-${sessionId}`, {
    sessionId,
    sharedTicketIds: [],
  });

  const { item, set } = localStore;
  const { data } = item;

  const addTicketId = useCallback(
    (ticketId: string) => {
      if (data == null) return;
      set({
        ...data,
        sharedTicketIds: [...data.sharedTicketIds, ticketId],
      });
    },
    [data, set],
  );

  const removeTicketId = useCallback(
    (ticketId: string) => {
      if (data == null) return;
      set({
        ...data,
        sharedTicketIds: data.sharedTicketIds.filter(id => id !== ticketId),
      });
    },
    [data, set],
  );

  return { ...item, addTicketId, removeTicketId };
};

export default useTicketShareStatusStore;
