import { UpdateExperiencePackageValues } from "./useExperiencePackageUpdate";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import useUpdateExperiencePackage from "@api/private/update/hooks/useUpdateExperiencePackage";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useHistory } from "react-router-dom";
import { createSuccessSnackbar } from "@lib/utils/generic";

const useUpdateExperiencePackageHandle: UseFormHandles<UpdateExperiencePackageValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { pushSnackbar } = useGlobalContext();
  const history = useHistory();

  const { send: updateExperiencePackage, isLoading: updateIsLoading } = useUpdateExperiencePackage(
    values.packageId,
    values.name,
    values.tagLine,
    values.extraDetails,
    values.duration,
    values.tickets,
    values.streamOption,
    values.price,
    values.gamePlatformSelections,
    values.anyGame,
  );

  const handle = async () => {
    let failed = validateFields([
      "packageId",
      "name",
      "tagLine",
      "extraDetails",
      "duration",
      "tickets",
      "streamOption",
      "price",
      "gamePlatformSelections",
    ]);

    if (!failed) {
      const { error } = await updateExperiencePackage();

      if (error) {
        failed = true;

        validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    if (!failed) {
      pushSnackbar(createSuccessSnackbar("Package experience updated", 3500));
      history.push("/experiences");
    }

    return failed;
  };

  return [handle, updateIsLoading];
};

export default useUpdateExperiencePackageHandle;
