import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import Button from "@components/form/Button";
import { IoArrowForward } from "react-icons/io5";
import HoverTooltip from "@components/common/HoverTooltip";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import SimpleDatePicker from "@components/form/SimpleDatePicker";
import dayjs from "dayjs";
import useRegisterContext from "../context/useRegisterContext";

export default function UserExtensionStep() {
  const { breakpoints } = useGlobalContext();
  const { values, validations, onChange, onBlur, userExtensionIsLoading } = useRegisterContext();

  return (
    <>
      <FormRow>
        <FormEntry label="FIRST NAME" validation={validations.firstName} showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            name="firstName"
            placeholder="Harry"
            value={values.firstName}
            onChange={onChange}
            onBlur={onBlur}
            disabled={userExtensionIsLoading}
          />
        </FormEntry>
        <FormEntry label="SURNAME" validation={validations.surname} showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            name="surname"
            placeholder="Barry"
            value={values.surname}
            onChange={onChange}
            onBlur={onBlur}
            disabled={userExtensionIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry label="DISPLAY NAME" validation={validations.displayName} showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            name="displayName"
            placeholder="HarryBarry"
            value={values.displayName}
            onChange={onChange}
            onBlur={onBlur}
            disabled={userExtensionIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label={
            <div className="flex-row justify-between">
              DOB
              <HoverTooltip
                relativePosition={breakpoints.sm ? "left" : "top"}
                content={
                  <p style={{ width: 200 }} className="text-center">
                    We collect this information to ensure you are old enough to join Yakkr
                  </p>
                }>
                <span className="text-purple-4">Why?</span>
              </HoverTooltip>
            </div>
          }
          validation={validations.dob}
          showValidationBeneath>
          <SimpleDatePicker
            size="medium"
            color="black-3"
            textColor="white"
            dividerColor="gray-1"
            position="portal-fixed"
            name="dob"
            value={values.dob}
            onChange={onChange}
            onBlur={onBlur}
            defaultDate={dayjs("2000-01-01")}
            orientation={breakpoints.xs ? "vertical" : "horizontal"}
            disabled={userExtensionIsLoading}
          />
        </FormEntry>
      </FormRow>

      <div className="auth-button-row">
        <Button
          size="large"
          color="gray-1"
          textColor="white"
          type="submit"
          to="/"
          disabled={userExtensionIsLoading}>
          Cancel
        </Button>
        <Button
          size="large"
          color="purple"
          textColor="white"
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={userExtensionIsLoading}>
          Next
        </Button>
      </div>
    </>
  );
}
