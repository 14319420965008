import Modal from "@components/modals/Modal";
import Progress from "@components/common/Progress";
import { classNames } from "@lib/utils/generic";
import React, { cloneElement, ReactElement } from "react";
import {
  IoAlert,
  IoCalendar,
  IoChatboxEllipses,
  IoFlash,
  IoHome,
  IoVideocam,
} from "react-icons/io5";
import useRoomContext from "./roomContext/hooks/useRoomContext";

export default function RoomLoadingModal() {
  const {
    sessionIsLoading,
    sessionError,

    joinIsLoading,
    joinError,

    videoRoomIsLoading,
    videoRoomError,

    chatRoomIsLoading,
    chatRoomError,

    roomSocketIsLoading,
    roomSocketError,

    isReconnecting,
    reconnectError,
    disconnectError,

    genericIsLoading,
  } = useRoomContext();

  return (
    <Modal active={genericIsLoading} className="room-loading-modal" disablePageCover bare>
      <div className="main-wrapper">
        <div className="statuses">
          <Status
            text="Session"
            icon={<IoCalendar />}
            isLoading={sessionIsLoading}
            error={sessionError}
          />
          <Status text="Room" icon={<IoHome />} isLoading={joinIsLoading} error={joinError} />
          <Status
            text="Room Socket"
            icon={<IoFlash />}
            isLoading={roomSocketIsLoading}
            error={roomSocketError}
          />
          <Status
            text="Video Room"
            icon={<IoVideocam />}
            isLoading={videoRoomIsLoading || isReconnecting}
            error={videoRoomError ?? reconnectError ?? disconnectError}
          />
          <Status
            text="Chat Room"
            icon={<IoChatboxEllipses />}
            isLoading={chatRoomIsLoading}
            error={chatRoomError}
          />
        </div>

        {/* {genericError && (
          <div className="modal-footer">
            <div className="button-row">
              <Button
                startIcon={<IoArrowBack />}
                size="small"
                variant="flat"
                color="purple"
                textColor="white"
                to="/"
                disabled={!genericError}>
                Leave
              </Button>
              <Button
                endIcon={<IoReload />}
                size="small"
                variant="flat"
                color="purple"
                textColor="white"
                onClick={retryRoom}
                disabled={!genericError}>
                Retry
              </Button>
            </div>
            <FormError textOverride={genericError} />
          </div>
        )} */}
      </div>
    </Modal>
  );
}

interface StatusProps {
  icon: ReactElement;
  text: string;
  isLoading: boolean;
  error?: string;
}

const Status = (props: StatusProps) => {
  const { icon, text, isLoading, error } = props;

  const state = isLoading ? "is-loading" : error ? "error" : "none";

  if (state === "none") return null;

  return (
    <div className="status">
      <div className="left-wrapper">
        {cloneElement(icon, { className: classNames(icon.props.className, "status-icon") })}
        <p className="status-text">{text}</p>
      </div>
      <State state={state} />
    </div>
  );
};

interface StateProps {
  state: "is-loading" | "error" | "none";
}

const State = (props: StateProps) => {
  const { state } = props;

  return (
    <div className={classNames("state", state)}>
      {state === "is-loading" && <Progress variant="ring" color="white" />}
      {state === "error" && <IoAlert className="state-icon" />}
    </div>
  );
};
