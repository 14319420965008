import { api } from "@api/api";
import { Currency } from "@lib/enums/generic";

export interface SupportedPresentmentCurrency {
  supportedCurrency: Currency;
}

const getSupportedPresentmentCurrency = async () => {
  const { data } = await api.post<SupportedPresentmentCurrency>("/getSupportedPresentmentCurrency");
  return data;
};

export default getSupportedPresentmentCurrency;
