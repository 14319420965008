import useFormManager from "@components/form/hooks/useFormManager";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useEffect } from "react";
import { ForgotPasswordModalData } from "../context/ForgotPasswordContextProvider";
import useCigForgotPasswordHandles from "./useForgotPasswordHandles";

const initialValues = {
  email: "",
  forgotPasswordCode: "",
  password: "",
  confirmPassword: "",
};

export type ForgotPasswordValues = typeof initialValues;

const useCigForgotPassword = () => {
  const { setAuthModal } = useGlobalContext();

  const { email, ...authModalData } = useAuthModalData<ForgotPasswordModalData>();

  if (email) initialValues.email = email;

  const formManager = useFormManager(initialValues, "forgotPassword");

  const { values, validations, onChange, onBlur, onChangeMultiple, resetValidations } = formManager;

  const [
    forgotPasswordConfirmHandle,
    forgotPasswordSubmitHandle,
    forgotPasswordConfirmIsloading,
    forgotPasswordSubmitIsLoading,
  ] = useCigForgotPasswordHandles(formManager);

  useEffect(
    () => setAuthModal("forgot-password", { ...authModalData, email: values.email }),
    // eslint-disable-next-line
    [values.email],
  );
  // must omit authModalData and setAuthModal to prevent loop

  return {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles: [forgotPasswordConfirmHandle, forgotPasswordSubmitHandle],
    resetValidations,

    forgotPasswordConfirmIsloading,
    forgotPasswordSubmitIsLoading,
  };
};

export default useCigForgotPassword;
