import { getMsgFromApiErr } from "@api/api";
import getCigAvailability from "@api/public/get/getCigAvailability";
import useRequestState from "@hooks/useRequestState";
import { Session } from "@lib/types/session";
import { useCallback, useEffect } from "react";

const useGetCigAvailability = (
  cigID?: string,
  dateFrom?: string,
  dateTo?: string,
  userID?: string,
  autoSend?: boolean,
) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<Session[]>([]);

  const send = useCallback(async () => {
    if (cigID == null || dateFrom == null || dateTo == null) return;
    setLoading();
    try {
      const availability = await getCigAvailability(cigID, dateFrom, dateTo, userID);
      setSuccess(availability ?? []);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [cigID, dateFrom, dateTo, userID, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [cigID, dateFrom, dateTo, userID, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetCigAvailability;
