import useRelativeCoordsAndSize, {
  Position,
  RelativePosition,
} from "@hooks/useRelativeCoordsAndSize";
import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import {
  Children,
  cloneElement,
  CSSProperties,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useEffect,
} from "react";
import { useRef } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";

interface Props extends CSSProps {
  active?: boolean;
  position?: Position;
  relativePosition?: RelativePosition;
  content?: ReactNode;
  timeout?: number;
  matchWidth?: boolean;
  fullWidth?: boolean;
  spacing?: string;
  noWrap?: boolean;
  onEntered?: () => void;
}

export default function RelativePortal(props: PropsWithChildren<Props>) {
  const {
    children,
    active = false,
    position = "portal-fixed",
    relativePosition = "right",
    content = <></>,
    timeout = 300,
    matchWidth,
    fullWidth,
    spacing = "10px",
    style,
    className = "",
    id,

    onEntered,
  } = props;

  const rootRef = useRef<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  const portalRoot = document.getElementById("portal-root");

  const { coords, size, reCalculate } = useRelativeCoordsAndSize(
    rootRef,
    relativePosition,
    position,
    !active,
  );

  useEffect(() => {
    if (active) reCalculate();
  }, [active, reCalculate]);

  if (portalRoot == null) return null;

  const nodes = (
    <CSSTransition
      in={active && !!coords}
      appear
      timeout={timeout}
      nodeRef={ref}
      unmountOnExit={true}
      mountOnEnter={true}
      onEntered={onEntered}>
      <div
        className={classNames(
          "relative-portal",
          relativePosition,
          position,
          fullWidth && "full-width",
          className,
        )}
        ref={ref}
        id={id}
        style={
          {
            ...(coords &&
              position !== "absolute" && { left: fullWidth ? undefined : coords.x, top: coords.y }),
            ...(matchWidth && { width: size.width }),
            "--spacing": spacing,
            ...style,
          } as CSSProperties
        }>
        {content}
      </div>
    </CSSTransition>
  );

  return (
    <>
      {Children.map(
        children,
        child => isValidElement(child) && cloneElement(child as any, { ref: rootRef }),
      )}
      {position === "absolute" ? nodes : createPortal(nodes, portalRoot)}
    </>
  );
}
