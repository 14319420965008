import React from "react";
import { CSSProps } from "../../lib/types/generic";
import circularBlurPurpleBlue from "@images/misc/circular_blur_purple_blue.png";
import circularBlurBlack from "@images/misc/circular_blur_black.png";

const imgs = {
  purpleBlue: circularBlurPurpleBlue,
  black: circularBlurBlack,
};

interface Props extends CSSProps {
  color?: "purpleBlue" | "black";
}

const CircularBlur = (props: Props) => {
  const { color = "purpleBlue", className = "", style, id } = props;

  return (
    <img
      className={`circular-blur ${className}`}
      style={style}
      id={id}
      alt="circular blur"
      src={imgs[color]}
    />
  );
};

export default CircularBlur;
