import Tooltip from "@components/common/Tooltip";
import CurrencyInput from "@components/form/CurrencyInput";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import IncrementPicker from "@components/form/IncrementPicker";
import Input from "@components/form/Input";
import { getCurrencySymbol, pluralise } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import { IoAdd, IoRemove } from "react-icons/io5";
import ExperiencesManageLabel from "../../../ExperiencesManageLabel";
import useUpdateExperiencePackageContext from "../context/useUpdateExperiencePackageContext";

export default function Details() {
  const { values, validations, onChange, onBlur, longestGame } =
    useUpdateExperiencePackageContext();

  const { cigProfile } = useGlobalContext();

  const numberOfGames = longestGame?.estimatedMaxLengthMins
    ? Math.floor(values.duration / longestGame?.estimatedMaxLengthMins)
    : 0;

  return (
    <section className="form-section">
      <FormRow>
        <FormEntry
          label={<ExperiencesManageLabel label="Bundle Name" />}
          validation={validations.name}
          relativePosition="top">
          <Input
            size="large"
            color="black-3"
            textColor="gray-3"
            name="name"
            placeholder="Classic"
            value={values.name}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label={<ExperiencesManageLabel label="Bundle Tag Line" />}
          validation={validations.tagLine}
          relativePosition="top"
          className="tag-line-entry">
          <Input
            size="large"
            color="black-3"
            textColor="gray-3"
            name="tagLine"
            placeholder="The Classic Experience! The best bundle for a quick game and chat."
            multiline
            value={values.tagLine}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label={<ExperiencesManageLabel label="Bundle Price" />}
          validation={validations.price}
          relativePosition="top">
          <CurrencyInput
            size="extra-large"
            color="black-3"
            textColor="gray-3"
            name="price"
            placeholder="0.00"
            value={values.price}
            currency={getCurrencySymbol(cigProfile?.defaultCurrency)}
            onChange={onChange}
            onBlur={onBlur}
            oversized
          />
          <p className="bottom-text">ex. sales tax</p>
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label={<ExperiencesManageLabel label="Session Length" />}
          validation={validations.duration}
          relativePosition="top"
          className="duration-entry">
          <div>
            <Tooltip
              active={
                !!longestGame?.estimatedMaxLengthMins &&
                values.duration < longestGame.estimatedMaxLengthMins
              }
              content={
                <p>
                  Based on the games you have selected, we recommend a minimum session length of{" "}
                  {longestGame?.estimatedMaxLengthMins} minutes.
                </p>
              }
              relativePosition="bottom">
              <IncrementPicker
                size="large"
                color="black-3"
                textColor="gray-3"
                name="duration"
                value={values.duration}
                onBlur={onBlur}
                backIcon={<IoRemove />}
                forwardIcon={<IoAdd />}
                backDisabled={values.duration <= 0}
                onBack={() => onChange({ name: "duration", value: values.duration - 15 })}
                onForward={() => onChange({ name: "duration", value: values.duration + 15 })}
                valueSuffix="mins"
              />
              {numberOfGames > 0 && (
                <p className="prompt-text">
                  approx. {numberOfGames} {pluralise("game", numberOfGames)}
                </p>
              )}
            </Tooltip>
          </div>
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label={<ExperiencesManageLabel label="Tickets" subLabel="How many people can join in?" />}
          validation={validations.tickets}
          relativePosition="top">
          <IncrementPicker
            size="large"
            color="black-3"
            textColor="gray-3"
            name="tickets"
            value={values.tickets}
            onBlur={onBlur}
            backIcon={<IoRemove />}
            forwardIcon={<IoAdd />}
            backDisabled={values.tickets <= 1}
            forwardDisabled={values.tickets >= 10}
            onBack={() => onChange({ name: "tickets", value: values.tickets - 1 })}
            onForward={() => onChange({ name: "tickets", value: values.tickets + 1 })}
            valueSuffix={pluralise("ticket", values.tickets)}
          />
        </FormEntry>
      </FormRow>
    </section>
  );
}
