import useRequestState from "@hooks/useRequestState";
import dayjs, { Dayjs } from "dayjs";
import { useCallback } from "react";

const useShareSession = (from?: Dayjs, to?: Dayjs, gameName?: string, url?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState(null);

  const send = useCallback(async () => {
    if (from == null || to == null || url == null) return reqState;

    setLoading();
    try {
      const fromString = dayjs(from).format("DD MMMM YYYY HH:mm");
      const toString = dayjs(to).format("DD MMMM YYYY HH:mm");

      const shareOptions = {
        title: `Come join my Yakkr session!`,
        text: `We're playing ${gameName} on Yakkr, from ${fromString} to ${toString}.\nClick the link to book.`,
        url,
      };

      if (!navigator.canShare || !navigator.canShare(shareOptions)) {
        return setError("Your browser does not support sharing");
      }
      navigator.share(shareOptions);

      return setSuccess(null);
    } catch (err: any) {
      return setError(err.message);
    }
  }, [from, to, gameName, url, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useShareSession;
