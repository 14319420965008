import DataCheck from "@components/common/DataCheck";
import Pill from "@components/common/Pill";
import Button from "@components/form/Button";
import IconButton from "@components/form/IconButton";
import CigEvent from "@components/sections/CigEvent";
import Collapse from "@components/transitions/Collapse";
import useElementSize from "@hooks/useElementSize";
import usePrevious from "@hooks/usePrevious";
import { classNames, isEmpty } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { IoArrowForward, IoCalendar, IoChevronDown, IoEye, IoEyeOff } from "react-icons/io5";
import CigProfileSubTitle from "../CigProfileSubTitle";
import CigProfileTitle from "../CigProfileTitle";
import useCigProfileContext from "../context/hooks/useCigProfileContext";

const Events = () => {
  const { breakpoints } = useGlobalContext();

  const {
    cigProfile,
    getIsLoading,

    upcomingSessions,
    upcomingSessionsIsLoading,
    upcomingSessionsError,
    getUpcomingSessions,

    upcomingSessionsCanGoNext,

    getNextUpcomingSessions,

    setCalendarOpen,
    isOwnProfile,
    setSessionId,
    setPeriodDate,
  } = useCigProfileContext();

  const ref = useRef<HTMLDivElement>(null);

  const { width } = useElementSize(ref);

  const [showingMore, setShowingMore] = useState(false);
  const [active, setActive] = useState(false);

  const cardColumns = breakpoints.md ? 1 : breakpoints.xl ? 2 : breakpoints.sl ? 3 : 4;

  const totalGaps = 16 * (cardColumns - 1);
  const eventWidth = Math.floor(width / cardColumns - totalGaps / cardColumns) - 1;

  const prevLength = usePrevious(upcomingSessions.length);

  return (
    <section className="section events">
      <CigProfileTitle small>
        <DataCheck isLoading={getIsLoading} loadingIndicator="gloss" loadingContent={<>&nbsp;</>}>
          Explore {cigProfile?.displayName}'s community events
        </DataCheck>
      </CigProfileTitle>

      <div className={classNames("box", active && "active")}>
        <div className="box-header">
          <div className="box-titles">
            <CigProfileTitle small>
              <DataCheck
                isLoading={getIsLoading}
                loadingIndicator="gloss"
                loadingContent={<>&nbsp;</>}>
                Events with {cigProfile?.displayName}
              </DataCheck>
            </CigProfileTitle>
            <CigProfileSubTitle>Join your fellow fans for a game or two</CigProfileSubTitle>
          </div>

          <div className="box-buttons">
            {isOwnProfile && (
              <Button
                color="purple"
                textColor="white"
                endIcon={<IoCalendar />}
                onClick={() => setCalendarOpen(true)}>
                Calendar
              </Button>
            )}
            <Pill color="purple-4" textColor="black-1">
              {upcomingSessions.length}
              {upcomingSessionsCanGoNext && "+"} Available
            </Pill>
            <IconButton
              color="black-3"
              iconColor="white"
              icon={<IoChevronDown />}
              onClick={() => setActive(active => !active)}
            />
          </div>
        </div>

        <Collapse in={active}>
          <div className="box-content-wrapper">
            <div className="box-content" ref={ref}>
              <DataCheck
                error={upcomingSessionsError}
                isEmpty={isEmpty(upcomingSessions) && !upcomingSessionsIsLoading}
                emptyMessage="No sessions available"
                emptyContent={
                  isOwnProfile && (
                    <Button
                      color="black-4"
                      textColor="white"
                      endIcon={<IoArrowForward />}
                      onClick={() => setCalendarOpen(true)}>
                      Create a Session
                    </Button>
                  )
                }>
                {upcomingSessionsIsLoading
                  ? new Array(prevLength || 6)
                      .fill(null)
                      .map((_, i) => <CigEvent key={i} isLoading width={eventWidth} />)
                  : upcomingSessions.map(
                      (
                        {
                          id,
                          game,
                          title,
                          extraInfo,
                          slotsAvailable,
                          from,
                          to,
                          currency,
                          price,
                          free,
                        },
                        i,
                      ) => (
                        <CigEvent
                          key={i}
                          title={title}
                          extraInfo={extraInfo}
                          slotsAvailable={slotsAvailable}
                          price={price}
                          currency={currency}
                          free={free}
                          startDate={dayjs(from)}
                          endDate={dayjs(to)}
                          gameImg={game.tertiaryImageURL}
                          width={eventWidth}
                          onClick={() => {
                            setSessionId(id);
                            setPeriodDate(dayjs(from));
                            setCalendarOpen(true);
                          }}
                        />
                      ),
                    )}
              </DataCheck>
            </div>
            {!upcomingSessionsIsLoading && (
              <div className="buttons-row">
                {upcomingSessionsCanGoNext && (
                  <Button
                    variant="flat"
                    color="gray-3"
                    textColor="white"
                    onClick={() => {
                      setShowingMore(true);
                      getNextUpcomingSessions();
                    }}
                    endIcon={<IoEye />}>
                    View More
                  </Button>
                )}
                {showingMore && (
                  <Button
                    variant="flat"
                    color="gray-3"
                    textColor="white"
                    onClick={() => {
                      setShowingMore(false);
                      getUpcomingSessions();
                    }}
                    endIcon={<IoEyeOff />}>
                    Hide
                  </Button>
                )}
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </section>
  );
};

export default Events;
