import { UseFormHandles } from "@components/form/hooks/useFormManager";
import useUpdateExperienceCustom from "@api/private/update/hooks/useUpdateExperienceCustom";
import { UpdateExperienceCustomValues } from "./useExperienceCustomUpdate";
import { useHistory } from "react-router-dom";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/utils/generic";

const useUpdateExperienceCustomHandle: UseFormHandles<UpdateExperienceCustomValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { pushSnackbar } = useGlobalContext();
  const history = useHistory();

  const { send: updateExperiencePackage, isLoading: updateIsLoading } = useUpdateExperienceCustom(
    values.timezoneName,
    values.customerOffersPrice,
    values.pricePerPeriod,
    values.streamOptionEnabled,
    values.streamPricePerPeriod,
    values.gamePlatformSelections,
    values.requestAvailableTimes,
    values.anyGame,
    values.anyTime,
  );

  const handle = async () => {
    let failed = validateFields([
      "timezoneName",
      "customerOffersPrice",
      "pricePerPeriod",
      "streamOptionEnabled",
      "streamPricePerPeriod",
      "gamePlatformSelections",
      "requestAvailableTimes",
    ]);

    if (!failed) {
      const { error } = await updateExperiencePackage();

      if (error) {
        failed = true;

        validateCustom("generic", validation => {
          validation.list.push({
            text: error,
            type: "error",
          });
        });
      }
    }

    if (!failed) {
      pushSnackbar(createSuccessSnackbar("Custom experience updated", 3500));
      history.push("/experiences");
    }

    return failed;
  };

  return [handle, updateIsLoading];
};

export default useUpdateExperienceCustomHandle;
