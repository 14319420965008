import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Details from "./Details";
import Tickets from "./Tickets";
import TicketsOverlay from "@components/layout/TicketsOverlay";
import DataCheck from "@components/common/DataCheck";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { TicketData } from "@components/tickets/TicketRegular";
import useGetBooking from "@api/private/get/hooks/useGetBooking";
import useQuery from "@hooks/useQuery";
import Button from "@components/form/Button";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import TicketsUnclaimed from "./TicketsUnclaimed";

export default function PaymentCompletion() {
  const { id } = useParams<Params>();
  const isGuest = useQuery("guest") === "true";
  const noOverlay = useQuery("noOverlay") === "true";
  const type = useQuery<"holding" | "booking">("type") ?? "holding";
  const state = useQuery<"success" | "error" | "cancel">("state") ?? "success";

  const idName = type === "holding" ? "holdingID" : "bookingID";

  const [overlayActive, setOverlayActive] = useState(true);
  const [retried, setRetried] = useState(false);

  const { userExtension, breakpoints } = useGlobalContext();

  const {
    data: myBooking,
    error: bookingError,
    isLoading: bookingIsLoading,
    send: getBooking,
  } = useGetBooking({ [idName]: id }, !isGuest);

  useEffect(() => {
    if (bookingError && !retried && !isGuest) {
      setTimeout(() => {
        setRetried(true);
        getBooking();
      }, 3000);
    }
  }, [bookingError, retried, isGuest, setRetried, getBooking]);

  const { session, currency, tickets: partialTickets = [] } = myBooking ?? {};
  const { game, from, to, exclusive, free } = session ?? {};
  const price = parseFloat(myBooking?.price ?? "0");

  const cigProfile = myBooking?.session?.cig;

  const ticketData: TicketData = {
    img: cigProfile?.profilePictureURL ?? undefined,
    cigName: cigProfile?.displayName,
    customerName: userExtension?.displayName,
    gameName: game?.longName,
    startDate: from,
    endDate: to,
    exclusive: exclusive,
  };

  const tickets: TicketData[] = partialTickets?.map(({ ticketID }) => ({
    ...ticketData,
    ticketId: ticketID,
  }));

  const errorMessage = {
    cancel: "Booking cancelled",
    error: "We were unable to complete your booking",
    success: null,
  };

  const error = errorMessage[state] ?? bookingError;
  const isLoading = bookingIsLoading;

  if (id == null) return null;

  return (
    <div id="payment-completion-page">
      <DataCheck
        error={error}
        fullPage
        errorContent={
          <Button textColor="white" variant="flat" to="/" startIcon={<IoArrowBack />}>
            Leave
          </Button>
        }>
        {(!isGuest || !breakpoints.sm) && (
          <div className="banner">
            {!isGuest && (
              <div className="banner-content">
                <h2 className="title">All booked!</h2>
                {!isGuest && (
                  <Button
                    color="white"
                    endIcon={<IoArrowForward />}
                    to={`/bookings/${myBooking?.bookingID}?type=booking`}>
                    Go to Booking
                  </Button>
                )}
              </div>
            )}
          </div>
        )}

        {!isGuest ? (
          <div className="content">
            <div className="left">
              <h3 className="title">Order Summary</h3>

              <Details
                img={ticketData.img}
                gameName={game?.longName}
                cigName={ticketData.cigName}
                slotsPurchased={tickets.length}
                startDate={from}
                endDate={to}
                currency={currency}
                slotCost={price}
                totalCost={price * tickets.length}
                free={free}
                isLoading={isLoading}
              />
            </div>

            <div className="right">
              <h3 className="title">Your Tickets</h3>

              <Tickets myBooking={myBooking} tickets={tickets} isLoading={isLoading} />
            </div>
          </div>
        ) : (
          <TicketsUnclaimed id={id} />
        )}

        {!noOverlay && state === "success" && !isGuest && (
          <TicketsOverlay
            in={overlayActive}
            tickets={tickets}
            onClose={() => setOverlayActive(false)}
            isLoading={isLoading}
            error={error}
          />
        )}
      </DataCheck>
    </div>
  );
}

interface Params {
  id?: string;
}
