import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import cancelCigAvailability from "../cancelCigAvailability";

const useCancelCigAvailability = (availabilityID?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState(null);

  const send = useCallback(async () => {
    if (availabilityID == null) return reqState;

    setLoading();
    try {
      await cancelCigAvailability(availabilityID);
      return setSuccess(null);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [availabilityID, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCancelCigAvailability;
