import Section from "@components/section/Section";
import SectionBar from "@components/section/SectionBar";
import Button from "@components/form/Button";
import React from "react";

interface Props {
  bookingId?: string;
  isSession?: boolean;
  extraInfo?: string | null;
  gameName?: string;
  platformName?: string;
  charity?: boolean;
  charityName?: string | null;

  error?: string | null;
  isLoading?: boolean;
}

export default function BookingOther(props: Props) {
  const {
    bookingId,
    isSession,
    extraInfo,
    gameName,
    platformName,
    charity,
    charityName,

    error,
    isLoading,
  } = props;

  return (
    <Section
      title="Other"
      className="booking-other"
      dataCheckProps={{ error, isLoading, loadingIndicator: "gloss" }}>
      <SectionBar>
        <label>Game</label>
        <p>{gameName}</p>
      </SectionBar>
      <SectionBar>
        <label>Platform</label>
        <p>{platformName}</p>
      </SectionBar>
      <SectionBar className="extra-info-bar">
        <label>Extra Info</label>
        <p>
          <span className="no-clamp">{extraInfo}</span>
        </p>
      </SectionBar>
      {charity && charityName != null && (
        <SectionBar>
          <label>Charity Name</label>
          <p>{charityName}</p>
        </SectionBar>
      )}
      {!isSession && (
        <SectionBar>
          <label>Issue with this booking?</label>
          <Button
            color="purple"
            textColor="white"
            to={`/settings/support/${bookingId}?action=create`}>
            Support
          </Button>
        </SectionBar>
      )}
    </Section>
  );
}
