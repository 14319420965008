import React from "react";
import CIGStatuses from "./sections/CIGStatuses";
import StripeStatuses from "./sections/StripeStatuses";

export default function StatusesTab() {
  return (
    <div className="sections-wrapper personal">
      <div className="sections">
        <CIGStatuses />
        <StripeStatuses />
      </div>
    </div>
  );
}
