import Form from "@components/form/Form";
import { useHistory } from "react-router-dom";
import useFeedbackContext from "./context/useFeedbackContext";
import MessageStep from "./steps/MessageStep";
import RatingStep from "./steps/RatingStep";
import TipStep from "./steps/TipStep";

export default function FeedbackForm() {
  const { handles, resetValidations, validations, setStep, initialStep, session } =
    useFeedbackContext();

  const [ratingIsLoading, tipIsLoading, messageIsLoading] = handles;

  const history = useHistory();

  return (
    <Form
      id="feedback-form"
      initialStep={initialStep}
      onStepChange={setStep}
      steps={[
        {
          handle: ratingIsLoading,
          children: <RatingStep />,
        },
        {
          handle: tipIsLoading,
          children: <TipStep />,
        },
        {
          handle: messageIsLoading,
          children: <MessageStep />,
        },
      ]}
      resetValidations={resetValidations}
      validation={validations.generic}
      triggerGlobalLoading
      onCompletion={() => history.push(`/@${session?.cig.displayName}`)}
    />
  );
}
