import Form from "@components/form/Form";
import GAEvent from "@lib/utils/GAEvent";
import React from "react";
import { useHistory } from "react-router-dom";
import useCigRegisterContext from "./context/useCigRegisterContext";
import CigCreateStep from "./steps/CigCreateStep";
import EmailConfirmStep from "./steps/EmailConfirmStep";
import JoinDiscordStep from "./steps/JoinDiscordStep";
import RegisterStep from "./steps/RegisterStep";
import StripeSetupStep from "./steps/StripeSetupStep";
import UserExtensionStep from "./steps/UserExtensionStep";

export default function CigRegisterForm() {
  const { handles, resetValidations, validations, initialStep, setStep } = useCigRegisterContext();

  const [registerHandle, emailConfirmHandle, userExtensionHandle, cigCreateHandle] = handles;

  const history = useHistory();

  return (
    <Form
      id="register-form"
      initialStep={initialStep}
      onStepChange={setStep}
      steps={[
        {
          children: <RegisterStep />,
          handle: registerHandle,
        },
        {
          children: <EmailConfirmStep />,
          handle: emailConfirmHandle,
        },
        {
          children: <UserExtensionStep />,
          handle: userExtensionHandle,
        },
        {
          children: <CigCreateStep />,
          handle: cigCreateHandle,
        },
        {
          children: <JoinDiscordStep />,
        },
        {
          children: <StripeSetupStep />,
        },
      ]}
      resetValidations={resetValidations}
      validation={validations.generic}
      triggerGlobalLoading
      onCompletion={() => {
        GAEvent.login("Email");
        history.push("/");
      }}
    />
  );
}
