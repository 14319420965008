import { api } from "@api/api";

export interface CIGPaymentPortalLinkObject {
  url: string;
}

const createCIGPaymentPortalLink = async () => {
  const { data } = await api.post<CIGPaymentPortalLinkObject>("/getCIGPaymentPortal");
  return data;
};

export default createCIGPaymentPortalLink;
