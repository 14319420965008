import useFormManager from "@components/form/hooks/useFormManager";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs from "dayjs";
import { useEffect } from "react";
import { RegisterModalData } from "../context/RegisterContextProvider";
import useRegisterHandles from "./useRegisterHandles";

const initialValues = {
  firstName: "",
  surname: "",
  email: "",
  password: "",
  confirmPassword: "",
  displayName: "",
  dob: dayjs(),
  terms: false,
  emailConfirmCode: "",
};

export type RegisterValues = typeof initialValues;

const useRegister = () => {
  const { setAuthModal } = useGlobalContext();

  const { email, ...authModalData } = useAuthModalData<RegisterModalData>();

  if (email) initialValues.email = email;

  const formManager = useFormManager(initialValues, "register");

  const { values, validations, onChange, onBlur, onChangeMultiple, resetValidations } = formManager;

  const [
    registerHandle,
    emailConfirmHandle,
    userExtensionHandle,

    registerIsLoading,
    emailConfirmIsLoading,
    userExtensionIsLoading,
  ] = useRegisterHandles(formManager);

  useEffect(
    () => setAuthModal("register", { ...authModalData, email: values.email }),
    // eslint-disable-next-line
    [values.email],
  );
  // must omit authModalData and setAuthModal to prevent loop

  return {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles: [registerHandle, emailConfirmHandle, userExtensionHandle],
    handlesIsLoading: [registerIsLoading, emailConfirmIsLoading, userExtensionIsLoading],
    resetValidations,
  };
};

export default useRegister;
