import React from "react";
import ExperiencesRequestSummary from "../../requests/ExperiencesRequestSummary";

export default function Time() {
  return (
    <section className="form-section summary">
      <ExperiencesRequestSummary />
    </section>
  );
}
