import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import { PropsWithChildren } from "react";

interface Props extends CSSProps {
  center?: boolean;
}

const SplashText = (props: PropsWithChildren<Props>) => {
  const { center, className, children, ...cssProps } = props;

  return (
    <p className={classNames("splash-text", center && "center", className)} {...cssProps}>
      {children}
    </p>
  );
};

export default SplashText;
