export default {
  "1.0.0": "1.2.1",
  "1.1.0": "1.3.1",
  "1.2.0": "1.4.1",
  "1.3.0": "1.5.1",
  "1.4.0": "1.5.1",
  "1.5.0": "1.6.1",
  "1.5.1": "1.6.2",
  "1.5.2": "1.6.2",
  "1.5.3": "1.7.0",
  "1.5.4": "1.7.1",
  "1.5.5": "1.7.2",
  "1.5.6": "1.7.4",
  "1.6.0": "1.8.0",
  "1.7.0": "1.9.0",
  "1.7.1": "1.9.0",
  "1.7.3": "1.10.1",
  "1.7.4": "1.10.3",
  "1.7.5": "1.10.3",
} as Record<string, string>;
