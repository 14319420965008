import { CSSProps, UIColor } from "@lib/types/generic";

interface Props extends CSSProps {
  color?: UIColor;
  variant?: "solid" | "dotted" | "dashed";
  orientation?: "horizontal" | "vertical";
  opacity?: number;
  width?: string;
}

export default function Divider(props: Props) {
  const {
    color = "purple",
    variant = "solid",
    orientation = "horizontal",
    opacity = 1,
    width = "1px",
    className = "",
    id,
    style,
  } = props;

  return (
    <div
      className={`divider ${orientation} ${color} ${variant} ${className}`}
      id={id}
      style={{ "--opacity": opacity, "--width": width, ...style } as any}></div>
  );
}
