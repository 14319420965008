import { CIGProfilePartial } from "@api/public/get/getCigs";
import useGetCigs from "@api/public/get/hooks/useGetCigs";
import Avatar from "@components/common/Avatar";
import { Option } from "@components/form/Options";
import { takeRandom } from "@lib/utils/generic";
import { useEffect, useState } from "react";

const useCigsSearch = (onlyWithCustomExperience?: boolean) => {
  const [defaultCig, setDefaultCig] = useState<CIGProfilePartial>();

  const { isLoading, data: cigs, error, send: get } = useGetCigs(true);

  let filteredCigs = cigs;
  if (onlyWithCustomExperience)
    filteredCigs = filteredCigs.filter(({ hasCustomExperience }) => hasCustomExperience);

  const results: Option[] = filteredCigs
    ?.filter(
      ({ id, displayName, category }) => id != null && displayName != null && category != null,
    )
    .map(({ id, displayName, profilePictureURL }) => {
      return {
        value: id,
        startIcon: <Avatar src={profilePictureURL ?? undefined} alt={displayName} />,
        label: displayName,
      };
    });

  useEffect(() => {
    if (defaultCig || filteredCigs.length === 0) return;

    setDefaultCig(takeRandom(filteredCigs));
  }, [filteredCigs, defaultCig]);

  return { isLoading, error, cigs: filteredCigs, results, get, defaultCig };
};

export default useCigsSearch;
