import Avatar from "@components/common/Avatar";
import { CSSProps } from "@lib/types/generic";
import React, { forwardRef, PropsWithChildren, useRef } from "react";
import {
  IoClose,
  IoMegaphone,
  IoMicOff,
  IoRadio,
  IoSadOutline,
  IoVideocamOff,
  IoVolumeHigh,
  IoVolumeOff,
} from "react-icons/io5";
import useParticipantIsSpeaking from "../hooks/useParticipantIsSpeaking";
import useRoomContext from "../roomContext/hooks/useRoomContext";
import ParticipantVideo from "./ParticipantVideo";
import NetworkQualityIcon from "./NetworkQualityIcon";
import useVideoIsPlaying from "./hooks/useVideoIsPlaying";
import DataCheck from "@components/common/DataCheck";
import YakkrBanner from "@components/common/YakkrBanner";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useTimeLeft from "../hooks/useTimeLeft";
import dayjs from "dayjs";
import useIsLandscape from "@hooks/useIsLandscale";
import RoomParticipant from "../objects/RoomParticipant";
import { classNames } from "@lib/utils/generic";

interface Props extends CSSProps {
  isMain?: boolean;
  size?: "small" | "large";
  participant?: RoomParticipant;
  noParticipants?: boolean;
  pauseVideo?: boolean;
  isFullscreen?: boolean;
  showBranding?: boolean;

  onClick?: () => void;
}

const ParticipantView = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    isMain,
    size = "small",
    participant,
    noParticipants,
    pauseVideo,
    showBranding,
    children,
    className = "",
    id,
    style,

    onClick,
  } = props;

  const {
    videoParticipant,
    chatParticipant,
    identity,
    audioTrack,
    videoTrack,
    audioDisabled,
    isLocal,
  } = participant ?? {};

  const displayName = chatParticipant?.attributes?.displayName ?? "";

  const { breakpoints, cigProfile } = useGlobalContext();
  const { dominnantParticipant, session } = useRoomContext();

  const isSpeaking = useParticipantIsSpeaking(audioTrack ?? undefined);

  const { state } = videoParticipant ?? {};

  const isLandscape = useIsLandscape();

  const isDominnant = dominnantParticipant?.identity === identity;
  const speakingIcon = noParticipants ? (
    <IoClose />
  ) : isSpeaking ? (
    isDominnant ? (
      <IoMegaphone />
    ) : (
      <IoVolumeHigh />
    )
  ) : (
    <IoVolumeOff />
  );

  const videoRef = useRef<HTMLVideoElement>(null);

  const isPlaying = useVideoIsPlaying(videoRef);

  const { timeLeft } = useTimeLeft(dayjs(session?.to));
  const overtime = timeLeft < 0;

  const isCig = session?.cigID === cigProfile?.id;

  return (
    <div
      ref={ref}
      className={classNames(
        "participant-view",
        onClick && "clickable",
        isLandscape && "langscape",
        size,
        className,
      )}
      id={id}
      style={style}
      onClick={onClick}>
      {(!videoTrack?.isEnabled || noParticipants) && <div className="video-placeholder" />}

      {!pauseVideo && <ParticipantVideo ref={videoRef} participant={participant} />}

      {showBranding && !overtime && (
        <YakkrBanner color="white" size={breakpoints.sm ? "extra-small" : "medium"} />
      )}

      {noParticipants && isMain && !isCig ? (
        <div className="state-wrapper">
          <IoSadOutline />
          <p>{session?.cig.displayName ?? "The talent"} is not in this room</p>
        </div>
      ) : state === "reconnecting" ? (
        <div className="state-wrapper">
          <IoRadio />
          <p>Reconnecting</p>
        </div>
      ) : videoParticipant == null ? (
        <div className="state-wrapper">
          <IoClose />
          <p>Unavailable</p>
        </div>
      ) : !videoTrack?.isEnabled ? (
        <div className="state-wrapper">
          <Avatar alt={displayName} size={size === "small" ? 40 : 160} />
        </div>
      ) : !isPlaying ? (
        <div className="state-wrapper">
          <DataCheck isLoading loadingIndicator="spinner" color="white" />
        </div>
      ) : null}

      <div className="name-wrapper">
        {participant && (
          <div className="icons-wrapper">
            <div className="left">
              {!videoTrack?.isEnabled && <IoVideocamOff />}
              {(!audioTrack?.isEnabled || audioDisabled) && (
                <IoMicOff className={audioDisabled && !isLocal ? "stroke-red" : "stroke-white"} />
              )}
            </div>

            <div className="right">
              <NetworkQualityIcon videoParticipant={videoParticipant} />
            </div>
          </div>
        )}
        <div className="text-wrapper">
          <div className="group">
            {speakingIcon}
            {isLocal ? (
              <p>
                <b>{displayName}</b>
              </p>
            ) : (
              <p>{displayName}</p>
            )}
          </div>
        </div>
      </div>

      {children}
    </div>
  );
});

export default ParticipantView;
