import DataCheck from "@components/common/DataCheck";
import Button from "@components/form/Button";
import ButtonSelect from "@components/form/ButtonSelect";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import Select from "@components/form/Select";
import { gamesToOptions } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useRequestExperienceContext from "../context/useRequestExperienceContext";
import ExperiencesRequestLabel from "../ExperiencesRequestLabel";
import useRequestExperiencesGames from "../../../../../hooks/useRequestExperiencesGames";

export default function Game() {
  const { breakpoints } = useGlobalContext();
  const {
    values,
    validations,
    onChange,
    onBlur,
    cigProfileIsLoading,
    cigProfile,

    customExperience,
    customExperienceIsLoading,
    customExperienceError,
    packageExperience,
    packageExperienceIsLoading,
    packageExperienceError,
  } = useRequestExperienceContext();

  const customerRequestDetailsPlaceholder = `Let ${
    cigProfile?.displayName ?? "Loading"
  } know if you want to focus or talk about anything specific during the experience.

Examples:
  1. Discuss meta tactics
  2. Chat about general life and gaming
  3. Talk dank memes`;

  const { games, platforms, gamesIsLoading, gamesError } = useRequestExperiencesGames(
    customExperience,
    customExperienceIsLoading,
    customExperienceError,
    packageExperience,
    packageExperienceIsLoading,
    packageExperienceError,
    values.gameId,
  );

  return (
    <section className="form-section game">
      <FormRow>
        <FormEntry
          label={<ExperiencesRequestLabel label="Pick your game" />}
          validation={validations.gameId}>
          <Select
            size="large"
            color="black-2"
            textColor="gray-2"
            rounded
            position="portal-absolute"
            name="gameId"
            value={values.gameId}
            options={gamesToOptions(games)}
            isLoading={gamesIsLoading}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label={
            breakpoints.sm ? undefined : <ExperiencesRequestLabel label="Pick your platform" />
          }
          validation={validations.platformId}
          className="platform-entry">
          <div className="platform-select-wrapper">
            <DataCheck
              isLoading={gamesIsLoading}
              error={gamesError}
              isEmpty={!values.gameId}
              emptyMessage="Select a game to choose your platform"
              loadingIndicator="spinner">
              <ButtonSelect
                type="single"
                name="platformId"
                value={values.platformId}
                onChange={onChange}
                onBlur={onBlur}
                selectedButtonProps={{
                  variant: "contained",
                  textColor: "white",
                }}>
                {platforms.map(({ id, shortName, icon }) => (
                  <Button
                    variant="outlined"
                    color="gray-2"
                    textColor="gray-3"
                    size="small"
                    rounded
                    key={id}
                    name={id}
                    startIcon={
                      <div
                        className="mask"
                        style={{
                          maskImage: `url(${icon})`,
                          WebkitMaskImage: `url(${icon})`,
                        }}
                      />
                    }>
                    {shortName}
                  </Button>
                ))}
              </ButtonSelect>
            </DataCheck>
          </div>
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label={<ExperiencesRequestLabel label="Anything to add?" />}
          validation={validations.customerRequestDetails}
          className="customer-requests-details-entry">
          <Input
            size="large"
            color="black-2"
            textColor="gray-2"
            name="customerRequestDetails"
            multiline
            placeholder={customerRequestDetailsPlaceholder}
            value={values.customerRequestDetails}
            onChange={onChange}
            onBlur={onBlur}
            isLoading={cigProfileIsLoading}
          />
        </FormEntry>
      </FormRow>
    </section>
  );
}
