import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import setSessionToOpen, { SetSessionToOpenRes } from "../setSessionToOpen";

const useSetSessionToOpen = (availabilityId?: string) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<SetSessionToOpenRes | null>(null);

  const send = useCallback(async () => {
    if (availabilityId == null) return reqState;
    setLoading();
    try {
      const openSessionData = await setSessionToOpen(availabilityId);
      return setSuccess(openSessionData);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [availabilityId, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useSetSessionToOpen;
