import { log } from "@lib/utils/generic";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  Room,
} from "twilio-video";
import useOnPageClose from "@hooks/useOnPageClose";
import useAudioInputDevices from "@hooks/mediaDevices/useAudioInputDevices";
import useAudioOutputDevices from "@hooks/mediaDevices/useAudioOutputDevices";
import useVideoInputDevices from "@hooks/mediaDevices/useVideoInputDevices";
import usePrevious from "@hooks/usePrevious";
import { LocalRoomParticipant } from "../objects/RoomParticipant";

const isLocalVideoTrack = (track: LocalTrack): track is LocalVideoTrack => track.kind === "video";
const isLocalAudioTrack = (track: LocalTrack): track is LocalAudioTrack => track.kind === "audio";

const useMediaDeviceManager = (
  videoRoom: Room | null,
  localParticipant: LocalRoomParticipant | undefined,
) => {
  const { videoParticipant, chatParticipant, audioDisabled, videoDisabled } =
    localParticipant ?? {};
  const isHost = !!chatParticipant?.attributes.isCIG;

  const [audioInputDevices, refetchAudioInputDevices] = useAudioInputDevices();
  const [audioOutputDevices, refetchAudioOutputDevices] = useAudioOutputDevices();
  const [videoInputDevices, refetchVideoInputDevices] = useVideoInputDevices();

  const prevAudioInputDevices = usePrevious(audioInputDevices);
  const prevAudioOutputDevices = usePrevious(audioOutputDevices);
  const prevVideoInputDevices = usePrevious(videoInputDevices);

  const [audioInputDeviceId, setAudioInputDeviceId] = useState<string>("default");
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState<string>("default");
  const [videoInputDeviceId, setVideoInputDeviceId] = useState<string>("default");

  useEffect(() => {
    if (prevAudioInputDevices?.length === 0 && audioInputDevices.length > 0)
      setAudioInputDeviceId(audioInputDevices[0].deviceId);

    if (prevAudioOutputDevices?.length === 0 && audioOutputDevices.length > 0)
      setAudioOutputDeviceId(audioOutputDevices[0].deviceId);

    if (prevVideoInputDevices?.length === 0 && videoInputDevices.length > 0)
      setVideoInputDeviceId(videoInputDevices[0].deviceId);
  }, [
    audioInputDevices,
    audioOutputDevices,
    videoInputDevices,
    prevAudioInputDevices,
    prevAudioOutputDevices,
    prevVideoInputDevices,
    setAudioInputDeviceId,
    setAudioOutputDeviceId,
    setVideoInputDeviceId,
  ]);

  const unpublish = useCallback(
    (kind: "video" | "audio") => {
      if (!videoParticipant) return;

      const publications = Array.from(videoParticipant.tracks.values()).filter(
        publication => publication.kind === kind,
      );
      const tracks = publications.map(publication => publication.track);

      tracks.forEach(track => {
        if (isLocalVideoTrack(track)) {
          log(`Stopping video stream:`, track.mediaStreamTrack);
          track.mediaStreamTrack.stop();
          track.stop();
        } else if (isLocalAudioTrack(track)) {
          log(`Stopping audio stream:`, track.mediaStreamTrack);
          track.mediaStreamTrack.stop();
          track.stop();
        }
      });

      log(`Unpublishing ${kind} tracks:`, tracks);

      videoParticipant.unpublishTracks(tracks);
    },
    [videoParticipant],
  );

  const publish = useCallback(
    async (kind: "video" | "audio", deviceId?: string) => {
      if (!videoParticipant) return;
      if (audioDisabled && kind === "audio") return;
      if (videoDisabled && kind === "video") return;

      try {
        unpublish(kind);

        const createTrack = kind === "video" ? createLocalVideoTrack : createLocalAudioTrack;

        const track = await createTrack({ deviceId: deviceId });

        log(`Publishing ${kind} track:`, track);
        await videoParticipant.publishTrack(track, {
          priority: isHost ? "high" : "low",
        });
      } catch (err: any) {
        log(err);
      }
    },
    // eslint-disable-next-line
    [videoParticipant, audioDisabled, videoDisabled, isHost, unpublish],
    // must omit pushSnackbar to prevent loop
  );

  const unpublishAll = useCallback(() => {
    unpublish("video");
    unpublish("audio");
  }, [unpublish]);

  const unpublishAllRef = useRef(unpublishAll);
  unpublishAllRef.current = unpublishAll;

  useOnPageClose(unpublishAll);
  useEffect(() => () => unpublishAllRef.current(), []);

  useEffect(() => {
    publish("audio", audioInputDeviceId);
    // eslint-disable-next-line
  }, [audioInputDeviceId, videoParticipant]);
  // must omit muted and enable to prevnet unwanted publish

  useEffect(() => {
    publish("video", videoInputDeviceId);
    // eslint-disable-next-line
  }, [videoInputDeviceId, videoParticipant]);
  // must omit videoDisabled and enable to prevnet unwanted publish

  const prevAudioDisabled = usePrevious(audioDisabled);
  const prevVideoDisabled = usePrevious(videoDisabled);

  useEffect(() => {
    if (!audioDisabled && prevAudioDisabled) publish("audio", audioInputDeviceId);
    if (!videoDisabled && prevVideoDisabled) publish("video", videoInputDeviceId);
  }, [
    audioDisabled,
    videoDisabled,
    prevAudioDisabled,
    prevVideoDisabled,
    audioInputDeviceId,
    videoInputDeviceId,
    publish,
  ]);

  useEffect(() => {
    if (!videoParticipant) return;
    const { audioTracks } = videoParticipant;

    if (audioDisabled) audioTracks.forEach(({ track }) => track.disable());
    else audioTracks.forEach(({ track }) => track.enable());
  }, [videoParticipant, audioDisabled]);

  useEffect(() => {
    if (!videoParticipant) return;
    const { videoTracks } = videoParticipant;

    if (videoDisabled) videoTracks.forEach(({ track }) => track.disable());
    else videoTracks.forEach(({ track }) => track.enable());
  }, [videoParticipant, videoDisabled]);

  const refetchAllDevices = useCallback(() => {
    refetchAudioInputDevices();
    refetchAudioOutputDevices();
    refetchVideoInputDevices();
  }, [refetchAudioInputDevices, refetchAudioOutputDevices, refetchVideoInputDevices]);

  useEffect(() => {
    navigator.mediaDevices.addEventListener("devicechange", refetchAllDevices);
    videoParticipant?.on("trackStarted", refetchAllDevices);

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", refetchAllDevices);
      videoParticipant?.off("trackStarted", refetchAllDevices);
    };
  }, [videoParticipant, refetchAllDevices]);

  useEffect(() => {
    videoRoom?.on("disconnected", unpublishAll);

    return () => {
      videoRoom?.off("disconnected", unpublishAll);
    };
  }, [videoRoom, unpublishAll]);

  return {
    audioInputDevices,
    audioOutputDevices,
    videoInputDevices,
    audioInputDeviceId,
    audioOutputDeviceId,
    videoInputDeviceId,
    setAudioInputDeviceId,
    setAudioOutputDeviceId,
    setVideoInputDeviceId,
  };
};

export default useMediaDeviceManager;
