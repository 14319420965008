export const settings = {
  pathsWithoutNav: [
    "/register/*",
    "/login/*",
    "/forgot-password/*",
    "/bookings/room/*/*",
    "/become-talent",
    "/cig/register",
    "/cig/login",
    "/landing/*",
  ],
  pathsWithoutHeader: [
    "/register/*",
    "/login/*",
    "/forgot-password/*",
    "/bookings/room/*/*",
    "/cig/register",
    "/cig/login",
    "/landing/*",
  ],
  pathsWithoutLayout: ["/*/overlay"],
  pathsWithoutSearch: ["/become-talent"],
};
