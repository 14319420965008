import Button from "@components/form/Button";
import { SortDirection } from "@lib/enums/generic";
import { sortMap } from "@lib/utils/generic";
import Section from "@components/section/Section";
import dayjs from "dayjs";
import React, { useState } from "react";
import SectionHead from "@components/section/SectionHead";
import SectionRow from "@components/section/SectionRow";
import SectionRowButtons from "@components/section/SectionRowButtons";
import { claimStateColors, claimStateNames } from "@lib/constants/generic";
import { Claim } from "@api/private/get/getCustomerClaims";
import usePaginate from "@hooks/usePaginate";

enum SortKey {
  Date = "createdAt",
  Reason = "reason",
  State = "state",
}

interface Props {
  claims?: Claim[];
  isLoading?: boolean;
  error?: string;
}

export default function Claims(props: Props) {
  const { claims = [], isLoading, error } = props;

  const [sortKey, setSortKey] = useState<string>(SortKey.Date);
  const [sortDirection, setSortDirection] = useState(SortDirection.Ascending);

  const {
    paginatedData: paginatedClaims,
    canGoNext,
    canGoPrev,
    goToNextPage,
    goToPrevPage,
    goToFirstPage,
  } = usePaginate(claims, 2, 2, true);

  return (
    <Section
      display="table"
      dataCheckProps={{
        isLoading,
        error,
        loadingIndicator: "spinner",
        isEmpty: claims.length === 0,
        emptyMessage: "You don't have any claims",
      }}
      useMoreButtons
      title="Claims"
      canShowMore={canGoNext}
      canShowLess={canGoPrev}
      remainingBars={claims.length - paginatedClaims.length}
      onShowMore={goToNextPage}
      onShowLess={goToPrevPage}
      onReset={goToFirstPage}
      head={
        <SectionHead
          sortKey={sortKey}
          sortDirection={sortDirection}
          onSortKeyChange={setSortKey}
          onSortDirectionChange={setSortDirection}>
          <th id={SortKey.Date}>
            <p>Date</p>
          </th>
          <th id={SortKey.Reason}>
            <p>Reason</p>
          </th>
          <th id={SortKey.State}>
            <p>Status</p>
          </th>
          <th>
            <p>Actions</p>
          </th>
        </SectionHead>
      }>
      {paginatedClaims
        .sort(sortMap(sortKey, sortDirection))
        .map(({ id, state, createdAt, reason }, i) => (
          <SectionRow key={i}>
            <td>
              <p>{dayjs(createdAt).format("DD MMM YYYY | HH:mm")}</p>
            </td>
            <td>
              <p>{reason}</p>
            </td>
            <td>
              <p className={`text-${claimStateColors[state]}`}>{claimStateNames[state]}</p>
            </td>
            <td>
              <div className="group actions">
                <SectionRowButtons>
                  <Button
                    color="purple"
                    textColor="white"
                    to={`/settings/support/${id}?action=view`}>
                    Details
                  </Button>
                </SectionRowButtons>
              </div>
            </td>
          </SectionRow>
        ))}
    </Section>
  );
}
