import { pathHasNav } from "@lib/utils/generic";
import { useLocation } from "react-router-dom";

const useHasNav = () => {
  const location = useLocation();

  return pathHasNav(location.pathname);
};

export default useHasNav;
