import useUploadToS3 from "@api/other/put/hooks/useUploadToS3";
import useGetBannerPictureUploadUrl from "@api/private/get/hooks/useGetBannerPictureUploadUrl";
import { useState } from "react";

const useUploadBannerPicture = () => {
  const [bannerPictureFile, setBannerPictureFile] = useState<File | null>(null);

  const { send: getUploadUrl } = useGetBannerPictureUploadUrl();
  const { send: uploadToS3 } = useUploadToS3();

  const uploadBannerPicture = async () => {
    if (bannerPictureFile == null) return;
    const { data: bannerPictureUploadUrl } = await getUploadUrl();
    if (bannerPictureUploadUrl == null) return;

    const { url, processedImageURL } = bannerPictureUploadUrl;

    const { error } = await uploadToS3(url, bannerPictureFile);
    if (error) throw error;

    return processedImageURL;
  };

  return { bannerPictureFile, uploadBannerPicture, setBannerPictureFile };
};

export default useUploadBannerPicture;
