import Tabs, { Tab } from "@components/common/Tabs";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useUserIsCig from "@hooks/session/useUserIsCig";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import PaymentTab from "./tabs/payment/PaymentTab";
import SupportTab from "./tabs/support/SupportTab";
import PersonalTab from "./tabs/personal/PersonalTab";
import StatusesTab from "./tabs/status/StatusesTab";

export default function Settings() {
  const history = useHistory();
  const { tab: selectedTab } = useParams<Params>();

  const { breakpoints } = useGlobalContext();

  const isCig = useUserIsCig();

  const tabs: Array<Tab | undefined> = [
    { value: "personal", label: "Personal", component: <PersonalTab /> },
    isCig ? { value: "payment", label: "Payment", component: <PaymentTab /> } : undefined,
    { value: "support", label: "Support", component: <SupportTab /> },
    isCig ? { value: "statuses", label: "Statuses", component: <StatusesTab /> } : undefined,
  ];

  return (
    <div id="settings-page">
      <h2 className="settings-title">Settings</h2>

      <Tabs
        size={breakpoints.sm ? "small" : "medium"}
        variant="flat"
        color="white"
        textColor="gray-3"
        indicatorColor="white"
        selectedTab={selectedTab}
        tabs={tabs}
        onChange={newTab => {
          if (selectedTab !== newTab) history.push(`/settings/${newTab}`);
        }}
      />

      {tabs.find(tab => tab?.value === selectedTab)?.component}
    </div>
  );
}

interface Params {
  tab?: string;
}
