import Progress from "@components/common/Progress";
import { classNames } from "@lib/utils/generic";
import { cloneElement, FocusEvent, forwardRef, MouseEvent, PropsWithChildren } from "react";
import { IoChevronForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import { CSSProps, JustifyContent, UIColor } from "../../lib/types/generic";

interface Props extends CSSProps {
  color?: UIColor;
  textColor?: UIColor;
  iconColor?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  type?: "button" | "submit" | "reset";
  name?: string;
  justifyContent?: JustifyContent;
  rounded?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  disableHover?: boolean;
  isLoading?: boolean;
  isCtaing?: boolean;
  active?: boolean;
  form?: string;
  to?: string;
  href?: string;
  openInNewTab?: boolean;
  wrap?: boolean;
  as?: "link" | "a" | "button";
  stopPropagation?: boolean;
  tabIndex?: number;

  onBlur?: (event: FocusEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onFocus?: (event: FocusEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onMouseUp?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onMouseDown?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onDoubleClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onMouseEnter?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onMouseLeave?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

export type ButtonProps = Props;

const Button = forwardRef<HTMLButtonElement & HTMLAnchorElement, PropsWithChildren<Props>>(
  (props, ref) => {
    const {
      color = "white",
      textColor = "black",
      iconColor = textColor,
      variant = "contained",
      size = "medium",
      startIcon,
      endIcon: propsEndIcon,

      type = "button",
      name,

      justifyContent = "space-between",
      rounded,
      disabled = false,
      readOnly = false,
      disableHover = false,
      isLoading = false,
      isCtaing = false,
      active = false,

      form,
      to,
      href,
      openInNewTab,
      wrap = false,
      as,
      stopPropagation,
      tabIndex,

      onClick,
      onMouseUp,
      onMouseDown,
      onDoubleClick,

      children,
      style,
      id,
      className,
      testId,

      ...events
    } = props;

    let endIcon = isCtaing ? (
      <IoChevronForward />
    ) : isLoading ? (
      <div className="progress-wrapper">
        <Progress variant="ring" color={textColor} />
      </div>
    ) : (
      propsEndIcon
    );

    const handleClick =
      (onFunc: (event: React.MouseEvent<HTMLButtonElement>) => void) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (stopPropagation) event.stopPropagation();
        event.preventDefault();
        onFunc(event);
      };

    const inner = (
      <div className="button-inner">
        {startIcon &&
          cloneElement(startIcon, {
            ...startIcon.props,
            className: classNames("button-icon", startIcon.props.className),
          })}
        {children && <span>{children}</span>}
        {endIcon &&
          cloneElement(endIcon, {
            ...endIcon.props,
            className: classNames("button-icon", endIcon.props.className),
          })}
      </div>
    );

    const fullClassName = classNames(
      "button",
      readOnly && "read-only",
      disableHover && "hover-disabled",
      wrap && "wrap",
      (startIcon || endIcon || justifyContent !== "space-between") && "do-justify",
      isLoading && "is-loading",
      isCtaing && "is-ctaing",
      active && "active",

      rounded && "rounded",
      color,
      `${textColor}-text`,
      `${iconColor}-icon`,
      variant,
      size,
      className,
    );

    const fullStyle = {
      "--justify-content": justifyContent,
      ...style,
    };

    if (as === "button" || (to == null && href == null) || disabled) {
      return (
        <button
          ref={ref}
          type={type}
          disabled={disabled}
          form={form}
          name={name}
          tabIndex={tabIndex}
          onClick={onClick ? handleClick(onClick) : undefined}
          onMouseUp={onMouseUp ? handleClick(onMouseUp) : undefined}
          onMouseDown={onMouseDown ? handleClick(onMouseDown) : undefined}
          onDoubleClick={onDoubleClick ? handleClick(onDoubleClick) : undefined}
          {...events}
          style={fullStyle}
          className={fullClassName}
          id={id}
          data-testid={testId}>
          {inner}
        </button>
      );
    } else if (as === "link" || to != null) {
      return (
        <Link
          ref={ref}
          style={fullStyle}
          className={fullClassName}
          id={id}
          to={to ?? ""}
          tabIndex={tabIndex}
          onClick={onClick}
          {...events}
          data-testid={testId}>
          {inner}
        </Link>
      );
    } else {
      return (
        <a
          ref={ref}
          style={fullStyle}
          className={fullClassName}
          id={id}
          href={href}
          {...(openInNewTab
            ? {
                target: "_blank",
                rel: "noopener noreferrer",
              }
            : {})}
          tabIndex={tabIndex}
          onClick={onClick}
          {...events}
          data-testid={testId}>
          {inner}
        </a>
      );
    }
  },
);

export default Button;
