import IconButton from "../../../../form/IconButton";
import {
  IoMicOff,
  IoCall,
  IoSettings,
  IoMic,
  IoVideocamOff,
  IoChatbubbleEllipses,
  IoVideocam,
} from "react-icons/io5";
import Slide from "@components/transitions/Slide";
import useRoomContext from "../roomContext/hooks/useRoomContext";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import screenfull from "screenfull";
import { AiOutlineCompress, AiOutlineExpand } from "react-icons/ai";

interface Props {
  visible?: boolean;
  fixed?: boolean;
  isFullscreen?: boolean;
  goFullscreen?: () => void;
}

export default function Controls(props: Props) {
  const { visible = true, fixed = false, isFullscreen, goFullscreen = () => {} } = props;

  const { breakpoints } = useGlobalContext();
  const {
    disconnect,
    unreadMessages,
    drawerTab,
    setDrawerTab,
    localParticipant,
    toggleParticipantAudio,
    toggleParticipantVideo,
  } = useRoomContext();

  const { audioDisabled, videoDisabled, videoTrack, audioTrack } = localParticipant ?? {};

  const videoIsToggling = videoDisabled === !!videoTrack?.isEnabled;
  const audioIsToggling = audioDisabled === !!audioTrack?.isEnabled;

  const buttonSize = breakpoints.sm ? "extra-small" : breakpoints.md ? "small" : "medium";

  const settingsOpen = drawerTab === "settings";
  const chatOpen = drawerTab === "chat";

  return (
    <Slide in={visible || true} appear direction="up">
      <section className={`controls ${fixed ? "fixed" : ""}`}>
        <IconButton
          icon={!audioDisabled ? <IoMic /> : <IoMicOff />}
          color={!audioDisabled ? "gray-2" : "black-3"}
          size={buttonSize}
          onClick={() => localParticipant && toggleParticipantAudio(localParticipant)}
          disableHover={audioDisabled}
          disabled={audioIsToggling}
          rounded
        />
        <IconButton
          icon={!videoDisabled ? <IoVideocam /> : <IoVideocamOff />}
          color={!videoDisabled ? "gray-2" : "black-3"}
          size={buttonSize}
          onClick={() => localParticipant && toggleParticipantVideo(localParticipant)}
          disableHover={videoDisabled}
          disabled={videoIsToggling}
          rounded
        />
        <IconButton icon={<IoCall />} color="red" size={buttonSize} onClick={disconnect} rounded />

        {!isFullscreen && (
          <IconButton
            icon={<IoChatbubbleEllipses />}
            color={chatOpen ? "black-3" : "gray-2"}
            size={buttonSize}
            onClick={() => setDrawerTab(chatOpen ? undefined : "chat")}
            disableHover={chatOpen}
            count={unreadMessages.length}
            rounded
          />
        )}
        {!isFullscreen && (
          <IconButton
            icon={<IoSettings />}
            color={settingsOpen ? "black-3" : "gray-2"}
            size={buttonSize}
            onClick={() => setDrawerTab(settingsOpen ? undefined : "settings")}
            disableHover={settingsOpen}
            rounded
          />
        )}
        <IconButton
          icon={isFullscreen ? <AiOutlineCompress /> : <AiOutlineExpand />}
          color={isFullscreen ? "black-3" : "gray-2"}
          size={buttonSize}
          onClick={() => {
            if (isFullscreen) screenfull.exit();
            else goFullscreen();
          }}
          disableHover={isFullscreen}
          rounded
        />
      </section>
    </Slide>
  );
}
