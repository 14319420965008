import { PropsWithChildren } from "react";

interface Props {}

const CigProfileSubTitle = (props: PropsWithChildren<Props>) => {
  const { children } = props;

  return <h3 className="section-sub-title">{children}</h3>;
};

export default CigProfileSubTitle;
