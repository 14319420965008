import React from "react";
import { CurrentReqState } from "../../hooks/useRequestState";
import { CSSProps } from "../../lib/types/generic";
import { IoAlertCircleOutline } from "react-icons/io5";

interface Props extends CSSProps {
  reqState?: CurrentReqState<any>;
  textOverride?: string;
}

export default function FormError(props: Props) {
  const { reqState, textOverride, ...cssProps } = props;

  if (textOverride != null) {
    return <Error text={textOverride} {...cssProps} />;
  } else if (reqState?.error != null) {
    return <Error text={reqState.error.toString()} {...cssProps} />;
  }

  return null;
}

interface ErrorProps extends CSSProps {
  text: string;
}

const Error = ({ className = "", id, style, text }: ErrorProps) => (
  <p className={`form-error ${className}`} id={id} style={style}>
    <IoAlertCircleOutline />
    {text}
  </p>
);
