import Avatar from "@components/common/Avatar";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { ReactNode } from "react";
import SplashBox from "../SplashBox";
import SplashText from "../SplashText";

const timeouts = [500, 700, 800];

interface Testimonial {
  displayName: ReactNode;
  text: ReactNode;
  avatarImg: string;
}

interface Props {
  testimonials: [Testimonial, Testimonial, Testimonial];
}

const TestimonialsSplash = (props: Props) => {
  const { testimonials } = props;

  const { breakpoints } = useGlobalContext();

  return (
    <section className="section testimonials">
      {testimonials.map((testimonial, i) => {
        if (i === 2 && breakpoints.lg && !breakpoints.md) return null;
        return <TestimonialBox key={i} {...testimonial} timeout={timeouts[i]} />;
      })}
    </section>
  );
};

interface TestimonialProps {
  avatarImg: string;
  displayName: ReactNode;
  text: ReactNode;
  timeout: number;
}

const TestimonialBox = (props: TestimonialProps) => {
  const { avatarImg, displayName, text, timeout } = props;

  const { breakpoints } = useGlobalContext();

  return (
    <SlideOnIntersect direction="up" timeout={timeout} useParent={!breakpoints.lg}>
      <SplashBox className="testimonial">
        <div className="cig-details">
          <Avatar
            src={avatarImg}
            size={breakpoints.sm ? 50 : breakpoints.sl ? 60 : breakpoints.ul ? 80 : 120}
          />
          <p className="display-name">{displayName}</p>
        </div>
        <SplashText className="testimonial-text">{text}</SplashText>
      </SplashBox>
    </SlideOnIntersect>
  );
};

export default TestimonialsSplash;
