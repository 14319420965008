import { UIColor } from "@lib/types/generic";
import { PropsWithChildren } from "react";

interface Props {
  bgColor?: UIColor;
}

const TicketBottom = (props: PropsWithChildren<Props>) => {
  const { bgColor, children } = props;

  const id = Math.random().toString();

  return (
    <>
      <svg
        viewBox="0 0 434 141"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="ticket-svg bottom-svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.523438 6.23709C0.563484 6.23786 0.603624 6.23825 0.643855 6.23825C3.93997 6.23825 6.62757 3.63285 6.75957 0.369141H427.528C427.659 3.59302 430.283 6.17455 433.523 6.23709V121C433.523 132.046 424.569 141 413.523 141H20.5234C9.47774 141 0.523438 132.046 0.523438 121V6.23709Z"
          fill={`url(#paint_linear_${id})`}
          className={`bg-path ${bgColor ? bgColor : ""}`}
        />
        <defs>
          <linearGradient
            id={`paint_linear_${id}`}
            x1="203.633"
            y1="0.36913"
            x2="211.525"
            y2="140.556"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.01365" stopColor="#49BEFD" />
            <stop offset="0.267502" stopColor="#6188FE" />
            <stop offset="0.711858" stopColor="#8140FF" />
          </linearGradient>
        </defs>
      </svg>
      {children}
    </>
  );
};

export default TicketBottom;
