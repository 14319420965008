import useGetCurrency from "@api/public/get/hooks/useGetCurrency";
import { ConsultancyPackageType } from "@api/public/misc/registerConsultancyInterest";
import YakkrGrowthBanner from "@components/common/YakkrGrowthBanner";
import { useState } from "react";
import ConsultancySignUpModal from "./modal/ConsultancySignUpModal";
import Cards from "./panes/cards/Cards";
import Explanation from "./panes/explanation/Explanation";
import Features from "./panes/features/Features";
import HowItWorks from "./panes/howItWorks/HowItWorks";
import LastQuestions from "./panes/lastQuestions/LastQuestions";
import TopSplash from "./panes/topSplash/TopSplash";
import WhyUseUs from "./panes/whyUseUs/WhyUseUs";
import IconButton from "@components/form/IconButton";
import { FaDiscord } from "react-icons/fa";
import { growthDiscordUrl } from "@lib/constants/generic";
import HoverTooltip from "@components/common/HoverTooltip";

const ConsultancyLanding = () => {
  const [selectedPackage, setSelectedPackage] = useState<ConsultancyPackageType | undefined>(
    undefined,
  );

  const {
    data: currencyRes,
    isLoading: currencyIsLoading,
    error: currencyError,
  } = useGetCurrency(true);

  const { currency } = currencyRes ?? {};

  return (
    <div id="consultancy-landing-page">
      <TopSplash />
      <Explanation />
      <HowItWorks />
      <Cards
        currency={currency}
        currencyIsLoading={currencyIsLoading}
        currencyError={currencyError}
        onPackageSelect={setSelectedPackage}
      />
      <Features />
      <WhyUseUs />
      <LastQuestions />

      <HoverTooltip content={<p>Join our Discord</p>} relativePosition="left" noWrap>
        <IconButton
          size="large"
          color="gray-1"
          iconColor="white"
          rounded
          icon={<FaDiscord />}
          href={growthDiscordUrl}
          openInNewTab
          className="discord-icon"
        />
      </HoverTooltip>
      <YakkrGrowthBanner />

      <ConsultancySignUpModal
        selectedPackage={selectedPackage}
        onClose={() => setSelectedPackage(undefined)}
      />
    </div>
  );
};

export default ConsultancyLanding;
