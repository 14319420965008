import useCombinedRefs from "@hooks/useCombinedRefs";
import { TransitionEvents } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  useRef,
} from "react";
import { CSSTransition } from "react-transition-group";

interface Props extends TransitionEvents {
  in: boolean;
  appear?: boolean;
  properties?: string;
  timeout?: number;
  delay?: number;
  easing?: string;
  disable?: boolean;
  unmountOnExit?: boolean;
  mountOnEnter?: boolean;
}

const Collapse = forwardRef<HTMLElement, PropsWithChildren<Props>>((props, forwardedRef) => {
  const {
    in: _in,
    appear,
    properties = "height",
    timeout = 300,
    delay = 0,
    easing = "ease",
    disable,

    unmountOnExit = false,
    mountOnEnter = false,

    children,

    ...transitionEvents
  } = props;

  const nodeRef = useRef<HTMLElement>(null);
  const ref = useCombinedRefs(nodeRef, forwardedRef);

  const height = Array.from(ref.current?.children ?? []).reduce(
    (acc, { clientHeight }) => acc + clientHeight,
    0,
  );

  if (disable) return <>{children}</>;

  return (
    <CSSTransition
      classNames="collapse"
      in={_in}
      appear={appear}
      timeout={timeout + delay}
      unmountOnExit={unmountOnExit}
      mountOnEnter={mountOnEnter}
      nodeRef={ref}
      {...transitionEvents}>
      {isValidElement(children) &&
        cloneElement(Children.only(children) as any, {
          style: {
            transitionDuration: `${timeout}ms`,
            transitionTimingFunction: easing,
            transitionProperty: properties,
            transitionDelay: `${delay}ms`,
            height: _in ? height : 0,
            ...children.props.style,
          },
          className: classNames("collapse-transition", children.props.className),
          ref,
        })}
    </CSSTransition>
  );
});

export default Collapse;
