import useUploadToS3 from "@api/other/put/hooks/useUploadToS3";
import useGetProfilePictureUploadUrl from "@api/private/get/hooks/useGetProfilePictureUploadUrl";
import { useState } from "react";

const useUploadProfiePicture = () => {
  const [profilePictureFile, setProfilePictureFile] = useState<File | null>(null);

  const { send: getUploadUrl } = useGetProfilePictureUploadUrl();
  const { send: uploadToS3 } = useUploadToS3();

  const uploadProfilePicture = async () => {
    if (profilePictureFile == null) return;
    const { data: profilePictureUploadUrl } = await getUploadUrl();
    if (profilePictureUploadUrl == null) return;

    const { url, processedImageURL } = profilePictureUploadUrl;

    const { error } = await uploadToS3(url, profilePictureFile);
    if (error) throw error;

    return processedImageURL;
  };

  return { profilePictureFile, uploadProfilePicture, setProfilePictureFile };
};

export default useUploadProfiePicture;
