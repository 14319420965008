import { FormElement } from "@components/form/Form";
import RatingSelector, { RatingSelectorProps } from "@components/form/RatingSelector";
import { UIColor } from "@lib/types/generic";
import { useState } from "react";
import { Group, Showcase } from "./StyleGuide";

const RatingSelectors = () => {
  const sizes = [
    "extra-small",
    "small",
    "medium",
    "large",
    "extra-large",
  ] as RatingSelectorProps["size"][];

  const [value, setValue] = useState(0);
  const [color, setColor] = useState<UIColor>("purple");
  const [disabled, setDisabled] = useState<boolean>(false);

  const props = {
    value,
    onChange: ({ value }: FormElement) => setValue(value),
    color,
    disabled,
  };

  return (
    <Showcase
      title="Rating Selectors"
      className="rating-selectors"
      colors={[
        {
          color: color,
          label: "Color",
          onChange: setColor,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
      ]}
      inputs={[
        {
          value: value,
          label: "Value",
          onChange: setValue,
        },
      ]}>
      {sizes.map(size => (
        <Group key={size}>
          <RatingSelector size={size} {...props} />
        </Group>
      ))}
    </Showcase>
  );
};

export default RatingSelectors;
