import useForgotPassword from "@api/auth/hooks/useForgotPassword";
import useResendConfirmCode from "@api/auth/hooks/useResendConfirmCode";
import Button from "@components/form/Button";
import FormBar from "@components/form/FormBar";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createErrorSnackbar, createSuccessSnackbar } from "@lib/utils/generic";
import React, { useCallback, useState } from "react";
import { IoArrowForward } from "react-icons/io5";
import { contactEmail } from "@lib/constants/generic";

interface Props {
  type: "confirmation" | "forgot-password";
  email?: string;
  disabled?: boolean;
}

export default function CigRegisterResendCodeBar(props: Props) {
  const { type, email, disabled } = props;

  const { pushSnackbar } = useGlobalContext();

  const confirmCodeRes = useResendConfirmCode(email);
  const forgotPasswordRes = useForgotPassword(email);

  const res = {
    confirmation: confirmCodeRes,
    "forgot-password": forgotPasswordRes,
  }[type];

  const { send, isLoading } = res;

  const [sent, setSent] = useState(false);

  const resend = useCallback(async () => {
    const { error } = await send();
    if (error) {
      pushSnackbar(createErrorSnackbar(error, 5000));
    } else {
      setSent(true);
      pushSnackbar(createSuccessSnackbar("New email sent", 3000));
    }
  }, [send, setSent, pushSnackbar]);

  return (
    <FormBar
      text={
        sent ? (
          <>
            Still can't find it? <a href={`mailto:${contactEmail}`}>Contact us</a>
          </>
        ) : (
          "Can't find the email?"
        )
      }
      buttons={
        <>
          <Button
            color="purple"
            textColor="white"
            endIcon={<IoArrowForward />}
            onClick={resend}
            isLoading={isLoading}
            disabled={disabled}>
            Resend
          </Button>
        </>
      }
    />
  );
}
