import { api } from "@api/api";

export interface GamePlatformSelection {
  gameID: string;
  platformID: string;
}

export interface RequestAvailableTime {
  start: string;
  end: string;
  day: RequestAvailabilityDay;
}

export enum RequestAvailabilityDay {
  Monday = "MON",
  Tuesday = "TUE",
  Wednesday = "WED",
  Thursday = "THU",
  Friday = "FRI",
  Saturday = "SAT",
  Sunday = "SUN",
}

export interface UpdateExperienceCustomRes {}

const updateExperienceCustom = async (
  timezoneName: string,
  customerOffersPrice: boolean,
  pricePerPeriod: number,
  streamOptionEnabled: boolean,
  streamOptionPricePerPeriod: number,
  gamePlatformSelections: GamePlatformSelection[],
  requestAvailableTimes: RequestAvailableTime[],
  anyGame: boolean,
  anyTime: boolean,
) => {
  const { data } = await api.post<UpdateExperienceCustomRes>("/saveOrReplaceRequestSessionConfig", {
    timezoneName,
    customerOffersPrice,
    pricePerPeriod,
    streamOptionEnabled,
    streamOptionPricePerPeriod,
    gamePlatformSelections,
    requestAvailableTimes,
    anyGame,
    anyTime,
  });
  return data;
};

export default updateExperienceCustom;
