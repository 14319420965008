import { CSSProps } from "@lib/types/generic";
import { log } from "@lib/utils/generic";
import React, { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import RoomParticipant from "../objects/RoomParticipant";

interface Props extends CSSProps {
  participant?: RoomParticipant;
}

export default function ParticipantAudio(props: PropsWithChildren<Props>) {
  const { participant } = props;

  const { videoParticipant, audioTrack, volume } = participant ?? {};

  const audioRef = useRef<HTMLMediaElement>(null);

  const attach = useCallback(() => {
    const current = audioRef.current;
    if (!current || !audioTrack) return;

    audioTrack.attach(current);
    log("Audio attached", audioTrack);
  }, [audioTrack]);

  const detach = useCallback(() => {
    const current = audioRef.current;
    if (!current || !audioTrack) return;

    audioTrack.detach(current);
    current.srcObject = null;
    log("Audio detached", audioTrack);
  }, [audioTrack]);

  useLayoutEffect(() => {
    attach();
    return () => detach();
  }, [attach, detach]);

  useEffect(() => {
    const current = audioRef.current;
    if (!current || volume == null || volume > 100 || volume < 0) return;
    current.volume = volume / 100;
  }, [volume, audioTrack]);

  if (!videoParticipant) return null;

  return <audio ref={audioRef} muted={participant?.audioDisabled} />;
}
