import { api } from "@api/api";

interface CreateAvailabilityRes {
  id: string;
}

const createAvailability = async (
  gameID: string,
  platformID: string,
  fromDate: string,
  toDate: string,
  exclusive: boolean,
  charity: boolean,
  slotsAvailable: number,
  title: string,
  price: number,
  free: boolean,
  extraInfo?: string,
  repeatingWeeks?: number,
  repeatingDays?: number[],
  charityName?: string,
) => {
  const { data } = await api.post<CreateAvailabilityRes>("/createAvailability", {
    gameID,
    platformID,
    fromDate,
    toDate,
    exclusive,
    charity,
    charityName,
    slotsAvailable,
    title,
    extraInfo,
    price,
    free,
    repeatingWeeks,
    repeatingDays,
  });
  return data;
};

export default createAvailability;
