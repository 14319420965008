import Checkbox from "@components/form/Checkbox";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import Input from "@components/form/Input";
import { privacyUrl, termsUrl } from "@lib/constants/generic";
import Button from "@components/form/Button";
import useConsultancySignUpContext from "../context/useConsultancySignUpContext";
import { IoArrowForward } from "react-icons/io5";

export default function SignUpStep() {
  const {
    values,
    validations,
    onChange,
    onBlur,

    signUpIsLoading,
  } = useConsultancySignUpContext();

  return (
    <>
      <FormRow>
        <FormEntry label="EMAIL" validation={validations.email} showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            name="email"
            placeholder="example@email.com"
            type="email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
            disabled={signUpIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label="TWITCH DISPLAY NAME"
          validation={validations.twitchUsername}
          showValidationBeneath>
          <Input
            size="medium"
            color="black-3"
            textColor="white"
            name="twitchUsername"
            placeholder="Dave"
            value={values.twitchUsername}
            onChange={onChange}
            onBlur={onBlur}
            disabled={signUpIsLoading}
          />
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry validation={validations.terms} showValidationBeneath>
          <Checkbox
            size="medium"
            color="black-4"
            textColor="purple-5"
            checkmarkColor="gray-3"
            name="terms"
            label={
              <span>
                I agree to the Yakkr{" "}
                <a target="_blank" rel="noreferrer" href={privacyUrl} className="text-underline">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a target="_blank" rel="noreferrer" href={termsUrl} className="text-underline">
                  Terms of Service
                </a>
                .
              </span>
            }
            value={values.terms}
            onChange={onChange}
            onBlur={onBlur}
            disabled={signUpIsLoading}
          />
        </FormEntry>
      </FormRow>

      <div className="auth-button-row">
        <Button
          color="purple"
          textColor="white"
          size="large"
          type="submit"
          justifyContent="center"
          endIcon={<IoArrowForward />}
          isLoading={signUpIsLoading}>
          Register
        </Button>
      </div>
    </>
  );
}
