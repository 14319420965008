import Divider from "@components/common/Divider";
import IoFlashGradient from "@components/icons/IoFlashGradient";
import { classNames } from "@lib/utils/generic";
import { Fragment } from "react";
import { IoMegaphone, IoScanOutline } from "react-icons/io5";
import RoomParticipant from "../../objects/RoomParticipant";
import useRoomContext from "../../roomContext/hooks/useRoomContext";
import Participant from "./Participant";

interface Props {
  name: string;
  active: boolean;
  participants: RoomParticipant[];
  disable?: boolean;
  onClick?: () => void;
}

const Channel = (props: Props) => {
  const { name, active, participants, disable, onClick } = props;

  const { drawerParticipantId } = useRoomContext();

  return (
    <div
      className={classNames("channel", disable && "disabled", active && "active")}
      onClick={onClick}>
      <div className="channel-header">
        <div className="left">
          <IoMegaphone />
          <p className="channel-name">Channel {name}</p>
        </div>

        <div className="right">
          <IoFlashGradient gradient={active ? "gradient-faded-horizontal" : undefined} />
        </div>
      </div>
      <div className="participants">
        {[...participants]
          .sort(({ videoParticipant }) => (videoParticipant ? -1 : 1))
          .sort(({ chatParticipant }) => (chatParticipant?.attributes.isCIG ? -1 : 1))
          .map((participant, i) => (
            <Fragment key={i}>
              <Participant
                participant={participant}
                key={i}
                highlight={drawerParticipantId === participant.identity}
              />
              {i !== participants.length - 1 && <Divider color="black-4" />}
            </Fragment>
          ))}
        {participants.length === 0 && (
          <div className="participant">
            <div className="empty-wrapper">
              <IoScanOutline />
              <p className="empty-text">Empty - Click to join</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Channel;
