import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import changePassword from "../changePassword";

const useChangePassword = (oldPassword?: string, newPassword?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<null>(null);

  const { user } = useGlobalContext();

  const send = useCallback(async () => {
    if (user == null || oldPassword == null || newPassword == null) {
      return setError("Old password and new password are required");
    }

    setLoading();
    try {
      await changePassword(user, oldPassword, newPassword);
      return setSuccess(null);
    } catch (err: any) {
      return setError(err.message);
    }
  }, [user, oldPassword, newPassword, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useChangePassword;
