import useGetCompatibility from "@api/public/get/hooks/useGetCompatibility";
import useGetGames from "@api/public/get/hooks/useGetGames";
import DataCheck from "@components/common/DataCheck";
import Divider from "@components/common/Divider";
import Button from "@components/form/Button";
import ButtonSelect from "@components/form/ButtonSelect";
import Checkbox from "@components/form/Checkbox";
import CurrencyInput from "@components/form/CurrencyInput";
import Form from "@components/form/Form";
import FormEntry from "@components/form/FormEntry";
import FormRow from "@components/form/FormRow";
import useFormManager, { mergeValidations } from "@components/form/hooks/useFormManager";
import Input from "@components/form/Input";
import Select from "@components/form/Select";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import usePrevious from "@hooks/usePrevious";
import { isoDayNames, isoToDay } from "@lib/constants/generic";
import { Currency } from "@lib/enums/generic";
import { createSuccessSnackbar, getCurrencySymbol } from "@lib/utils/generic";
import dayjs, { Dayjs } from "dayjs";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import ActionPanel from "../../ActionPanel";
import useCreateSessionHandles from "./hooks/useCreateSessionHandles";
import { IoDesktopOutline, IoGameController, IoPencil } from "react-icons/io5";
import DatePicker from "@components/form/DatePicker";
import TimePicker from "@components/form/TimePicker";
import FormGroup from "@components/form/FormGroup";
import IconButton from "@components/form/IconButton";
import Search from "@components/form/Search";

interface Props {
  active?: boolean;
  onClose?: () => void;
  startDate?: Dayjs;
  endDate?: Dayjs;
  onStartDateChange?: (startDate: Dayjs) => void;
  onEndDateChange?: (endDate: Dayjs) => void;
  onChange?: (values: CreateSessionValues) => void;
  onCompletion?: () => void;
}

const initialValues = {
  currency: Currency.NotSet,
  price: 0,
  free: false,
  platformId: undefined as undefined | string,
  isCharity: false,
  charityName: "",
  isExclusive: false,
  gameId: undefined as undefined | string,
  startDate: dayjs(),
  endDate: dayjs(),
  totalSlots: undefined,
  repeatDays: [] as number[],
  repeat: false,
  repeatCount: 1,
  title: "",
  message: "",
};

export type CreateSessionValues = typeof initialValues;

const CreateSession = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    active,
    onClose = () => {},
    startDate = dayjs().startOf("hour"),
    endDate = dayjs().startOf("hour").add(15, "minutes"),
    onStartDateChange = () => {},
    onEndDateChange = () => {},
    onChange: onValuesChange = () => {},
    onCompletion = () => {},
  } = props;

  const { pushSnackbar, cigProfile, breakpoints } = useGlobalContext();

  const { defaultCurrency } = cigProfile ?? {};

  const [gameSearchValue, setGameSerchValue] = useState("");
  const [platformSearchValue, setPlatformSearchValue] = useState("");

  const { data: games, error, isLoading } = useGetGames(true);

  const formManager = useFormManager(initialValues, "createSession");
  const { values, validations, onChange, onChangeMultiple, onBlur, resetValidations, setValues } =
    formManager;

  const {
    data: compatibility,
    error: compatibilityError,
    isLoading: compatibilityIsLoading,
  } = useGetCompatibility(values.platformId, values.gameId, !!values.platformId && !!values.gameId);

  const compatiblePlatforms = compatibility?.compatiblePlatforms.items ?? [];

  const resetValues = useCallback(
    () => setValues({ ...initialValues, startDate, endDate }),
    [startDate, endDate, setValues],
  );

  const handleCompletion = useCallback(() => {
    resetValues();
    onCompletion();
    onClose();
    pushSnackbar(createSuccessSnackbar("Session created", 2500));
  }, [onCompletion, onClose, pushSnackbar, resetValues]);

  if (defaultCurrency) initialValues.currency = defaultCurrency;
  initialValues.startDate = startDate;
  initialValues.endDate = endDate;

  const prevStartDate = usePrevious(startDate);
  const prevEndDate = usePrevious(endDate);

  useEffect(() => {
    const changes = [];
    if (!startDate.isSame(prevStartDate)) changes.push({ name: "startDate", value: startDate });
    if (!endDate.isSame(prevEndDate)) changes.push({ name: "endDate", value: endDate });
    if (changes.length > 0) onChangeMultiple(changes);
  }, [startDate, prevStartDate, endDate, prevEndDate, onChangeMultiple]);

  useEffect(() => {
    onValuesChange(values);
  }, [values, onValuesChange]);

  useEffect(() => {
    if (values.free)
      onChangeMultiple([
        { name: "price", value: 0 },
        { name: "isCharity", value: false },
        { name: "charityName", value: "" },
      ]);
    // eslint-disable-next-line
  }, [values.free]);
  // Must omit onChangeMultiple to prevent loop

  const handleClose = useCallback(() => {
    resetValues();
    onClose();
    resetValidations();
  }, [onClose, resetValidations, resetValues]);

  const platforms = games.find(({ id }) => id === values.gameId)?.platforms?.items ?? [];

  const [handle, createIsLoading] = useCreateSessionHandles(formManager);

  const gameOptions = games.map(({ id, longName }) => ({ value: id, label: longName }));
  const platformOptions = platforms.map(({ platform: { id, name } }) => ({
    value: id,
    label: name,
  }));

  useEffect(() => {
    const game = games.find(({ id }) => values.gameId === id);
    const platform = game?.platforms.items.find(({ platform: { id } }) => values.platformId === id);

    if (!platform && values.gameId !== "") {
      onChange({ name: "platformId", value: "" });
      setPlatformSearchValue("");
    }

    // eslint-disable-next-line
  }, [games.length, values.gameId, values.platformId]);
  // cannot use games directly to prevent loop

  const children = (
    <>
      {/* <FormRow>
        <FormEntry validation={validations.isExclusive} showValidationBeneath>
          <Checkbox
            variant="contained"
            label="Yakkr+ exclusive session"
            name="isExclusive"
            value={values.isExclusive}
            onChange={onChange}
            onBlur={onBlur}
            disabled={createIsLoading}
          />
        </FormEntry>
      </FormRow> */}

      <FormRow>
        <FormEntry validation={validations.isCharity} showValidationBeneath>
          <Checkbox
            size="medium"
            variant="contained"
            color="black-4"
            textColor="white"
            checkmarkColor="gray-3"
            label="Free session"
            name="free"
            value={values.free}
            onChange={onChange}
            onBlur={onBlur}
            disabled={createIsLoading}
          />
        </FormEntry>
      </FormRow>

      <Divider color="gray-3" />

      <FormRow className="price-slots-row">
        <FormEntry
          label="Price / ticket"
          className="price-entry"
          validation={values.free ? undefined : validations.price}
          showValidationBeneath
          required>
          {values.free ? (
            <Input
              size="medium"
              variant="contained"
              color="black-4"
              textColor="white"
              value="Free Session"
              disabled={true}
              style={{ textAlign: "center" }}
            />
          ) : (
            <CurrencyInput
              size="medium"
              variant="contained"
              color="black-4"
              textColor="white"
              name="price"
              placeholder="0.00"
              currency={getCurrencySymbol(defaultCurrency)}
              value={values.price}
              onChange={onChange}
              onBlur={onBlur}
              disabled={createIsLoading || values.free}
            />
          )}
          {!values.free && <p className="footnote">(ex. sales tax)</p>}
        </FormEntry>

        <FormEntry
          label="Tickets Available"
          className="slots-entry"
          validation={validations.totalSlots}
          showValidationBeneath
          required>
          <Select
            size="medium"
            variant="contained"
            color="black-4"
            textColor="white"
            name="totalSlots"
            value={values.totalSlots}
            options={new Array(10)
              .fill(null)
              .map((_, i) => ({ value: i + 1, label: (i + 1).toString() }))}
            onChange={onChange}
            onBlur={onBlur}
            disabled={createIsLoading}
            useEmptyOption
          />
        </FormEntry>
      </FormRow>

      <FormRow>
        <FormEntry label="Message" validation={validations.message} showValidationBeneath>
          <Input
            size="medium"
            variant="contained"
            color="black-4"
            textColor="white"
            name="message"
            multiline
            placeholder="Any extra details..."
            value={values.message}
            onChange={onChange}
            onBlur={onBlur}
            disabled={createIsLoading}
          />
        </FormEntry>
      </FormRow>

      {!values.free && (
        <>
          <FormRow>
            <FormEntry validation={validations.isCharity} showValidationBeneath>
              <Checkbox
                size="medium"
                variant="contained"
                color="black-4"
                textColor="white"
                checkmarkColor="gray-3"
                label="Charity session"
                name="isCharity"
                value={values.isCharity}
                onChange={onChange}
                onBlur={onBlur}
                disabled={createIsLoading}
              />
            </FormEntry>
          </FormRow>
          {values.isCharity && (
            <FormRow>
              <FormEntry
                label="Charity name"
                validation={validations.charityName}
                showValidationBeneath>
                <Input
                  size="medium"
                  variant="contained"
                  color="black-4"
                  textColor="white"
                  name="charityName"
                  placeholder="The name of the charity"
                  value={values.charityName}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={createIsLoading}
                />
              </FormEntry>
            </FormRow>
          )}
        </>
      )}

      <Divider color="gray-3" />

      <FormRow>
        <FormEntry label="Game" validation={validations.gameId} showValidationBeneath required>
          <Search
            size="medium"
            variant="contained"
            color="black-4"
            textColor="white"
            name="gameId"
            placeholder="Game"
            startIcon={<IoGameController />}
            results={gameOptions}
            value={values.gameId}
            searchValue={gameSearchValue}
            onChange={({ value }) => setGameSerchValue(value)}
            onSelect={onChange}
            autoFilter
            sortResults
            error={error}
            isLoading={isLoading}
            disabled={createIsLoading}
          />
        </FormEntry>
      </FormRow>

      <FormRow>
        <FormEntry
          label="Gaming System"
          validation={validations.platformId}
          showValidationBeneath
          required>
          <Search
            size="medium"
            variant="contained"
            color="black-4"
            textColor="white"
            name="platformId"
            placeholder="Platform"
            startIcon={<IoDesktopOutline />}
            results={platformOptions}
            value={values.platformId}
            searchValue={platformSearchValue}
            onChange={({ value }) => setPlatformSearchValue(value)}
            onSelect={onChange}
            autoFilter
            sortResults
            error={error}
            isLoading={isLoading}
            disabled={createIsLoading}
          />
        </FormEntry>
      </FormRow>

      <FormRow className="platforms-row">
        <FormEntry label="Compatible Platforms">
          <ul
            className="platforms"
            style={{
              gridTemplateColumns: new Array(compatiblePlatforms.length).fill("1fr").join(" "),
            }}>
            <DataCheck
              isEmpty={!values.platformId || !values.gameId}
              isLoading={compatibilityIsLoading}
              error={compatibilityError}
              emptyMessage="Select a game and platform to view compatibility"
              loadingIndicator="spinner">
              {compatiblePlatforms.map(({ platform: { shortName, icon } }, i) => (
                <li className="platform" key={i}>
                  <div
                    className="icon"
                    style={{ maskImage: `url(${icon})`, WebkitMaskImage: `url(${icon})` }}
                  />
                  <p className="name">{shortName}</p>
                </li>
              ))}
            </DataCheck>
          </ul>
        </FormEntry>
      </FormRow>

      <Divider color="gray-3" />

      <FormRow>
        <FormEntry
          label="From"
          validation={validations.startDate}
          showValidationBeneath
          required
          className="dates-entry">
          <FormGroup orientation={breakpoints.sm ? "vertical" : "horizontal"}>
            <DatePicker
              size="medium"
              variant="contained"
              color="black-4"
              textColor="white"
              name="startDate"
              value={values.startDate}
              minDate={dayjs().set("minutes", Math.floor(dayjs().minute() / 15) * 15 + 30)}
              maxDate={values.endDate.subtract(15, "minutes")}
              onChange={onChange}
              onClose={onStartDateChange}
              disabled={createIsLoading}
            />
            <Divider color="gray-2" orientation={breakpoints.sm ? "horizontal" : "vertical"} />
            <TimePicker
              size="medium"
              variant="contained"
              color="black-4"
              textColor="white"
              name="startDate"
              value={values.startDate}
              minTime={dayjs()}
              maxTime={values.endDate.subtract(15, "minutes")}
              onChange={onChange}
              onClose={onStartDateChange}
              minuteInterval={15}
              disabled={createIsLoading}
            />
          </FormGroup>
        </FormEntry>
      </FormRow>
      <FormRow>
        <FormEntry
          label="To"
          validation={validations.endDate}
          showValidationBeneath
          required
          className="dates-entry">
          <FormGroup orientation={breakpoints.sm ? "vertical" : "horizontal"}>
            <DatePicker
              size="medium"
              variant="contained"
              color="black-4"
              textColor="white"
              position="absolute"
              name="endDate"
              value={values.endDate}
              minDate={values.startDate}
              onChange={onChange}
              onClose={onEndDateChange}
              disabled={createIsLoading}
            />
            <Divider color="gray-2" orientation={breakpoints.sm ? "horizontal" : "vertical"} />
            <TimePicker
              size="medium"
              variant="contained"
              color="black-4"
              textColor="white"
              position="absolute"
              name="endDate"
              value={values.endDate}
              minTime={values.startDate}
              onChange={onChange}
              onClose={onEndDateChange}
              minuteInterval={15}
              disabled={createIsLoading}
            />
          </FormGroup>
        </FormEntry>
      </FormRow>

      <FormRow>
        <FormEntry
          className="repeat-entry align-center"
          validation={mergeValidations(validations.repeat, validations.repeatCount)}
          showValidationBeneath>
          <Checkbox
            size="small"
            variant="contained"
            color="black-4"
            textColor="white"
            checkmarkColor="gray-3"
            name="repeat"
            value={values.repeat}
            label={
              <span className={`label-wrapper ${!values.repeat ? "disabled" : ""}`}>
                Repeat for
                <Select
                  size="small"
                  variant="contained"
                  color="black-4"
                  textColor="white"
                  name="repeatCount"
                  options={Array(12)
                    .fill(null)
                    .map((_, i) => ({
                      value: (i + 1).toString(),
                      label: (i + 1).toString(),
                    }))}
                  value={values.repeatCount}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={!values.repeat}
                />
                weeks
              </span>
            }
            onChange={onChange}
            onBlur={onBlur}
            disabled={createIsLoading}
          />
        </FormEntry>
      </FormRow>

      <FormRow>
        <FormEntry
          validation={validations.repeatDays}
          showValidationBeneath
          className="align-center">
          <ButtonSelect
            type="multi"
            name="repeatDays"
            value={values.repeatDays}
            onChange={onChange}
            onBlur={onBlur}
            disabled={!values.repeat || createIsLoading}
            selectedButtonProps={{
              color: "purple",
            }}>
            {Object.values(isoDayNames).map((day, i) => (
              <IconButton
                key={day}
                size="small"
                variant="contained"
                color="black-4"
                iconColor="white"
                name={isoToDay[i].toString()}
                icon={<p>{day.charAt(0).toUpperCase()}</p>}
              />
            ))}
          </ButtonSelect>
        </FormEntry>
      </FormRow>
    </>
  );

  return (
    <ActionPanel
      ref={ref}
      active={active}
      onClose={handleClose}
      className="create-session-panel"
      titleOverride={
        <FormRow>
          <FormEntry validation={validations.title} showValidationBeneath required>
            <Input
              variant="flat"
              color="white"
              textColor="gray-3"
              name="title"
              size="small"
              placeholder="What will fans get to do?"
              value={values.title}
              onChange={onChange}
              onBlur={onBlur}
              disabled={createIsLoading}
              endIcon={<IoPencil />}
            />
          </FormEntry>
        </FormRow>
      }
      prompt="Click and drag to create a session"
      buttons={
        <>
          <Button color="white" onClick={handleClose} disabled={createIsLoading}>
            Cancel
          </Button>
          <Button
            color="purple"
            textColor="white"
            type="submit"
            form="create-session-form"
            isLoading={createIsLoading}>
            Create
          </Button>
        </>
      }>
      <Form
        id="create-session-form"
        steps={[
          {
            children,
            handle,
          },
        ]}
        resetValidations={resetValidations}
        validation={validations.generic}
        onCompletion={handleCompletion}
      />
    </ActionPanel>
  );
});

export default CreateSession;
