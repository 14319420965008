import React from "react";
import IconButton from "@components/form/IconButton";
import Input from "@components/form/Input";
import { IoSend } from "react-icons/io5";
import Form from "@components/form/Form";
import FormEntry from "@components/form/FormEntry";
import useFormManager from "@components/form/hooks/useFormManager";
import FormRow from "@components/form/FormRow";
import useMessageBoxHandles from "./hooks/useMessageBoxHandles";

interface Props {}

const initialValues = {
  message: "",
};

export type MessageBoxValues = typeof initialValues;

export default function MessageBox(props: Props) {
  const formManager = useFormManager(initialValues, "messageBox");

  const { values, validations, onChange, onBlur, resetValidations } = formManager;

  const [handle, isLoading] = useMessageBoxHandles(formManager);

  return (
    <Form
      id="message-box-form"
      handle={handle}
      resetOnCompletion
      resetValidations={resetValidations}
      validation={validations.generic}
      onCompletion={() => onChange({ name: "message", value: "" })}>
      <FormRow>
        <FormEntry validation={validations.message} showValidationBeneath>
          <Input
            variant="contained"
            color="black-3"
            textColor="white"
            size="large"
            name="message"
            value={values.message}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="Send a message..."
            endIcon={
              <IconButton
                type="submit"
                size="small"
                variant="flat"
                icon={<IoSend />}
                color="white"
              />
            }
            isLoading={isLoading}
            autocomplete={false}
          />
        </FormEntry>
      </FormRow>
    </Form>
  );
}
