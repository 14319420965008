import NumberInput, { NumberInputProps } from "@components/form/NumberInput";
import { UIColor } from "@lib/types/generic";
import { useState } from "react";
import { IoChevronBack, IoChevronForward, IoClose } from "react-icons/io5";
import { Group, Showcase } from "./StyleGuide";

const NumberInputs = () => {
  const sizes = [
    "extra-small",
    "small",
    "medium",
    "large",
    "extra-large",
  ] as NumberInputProps["size"][];

  const [value, setValue] = useState(0);
  const [color, setColor] = useState<UIColor>("purple");
  const [textColor, setTextColor] = useState<UIColor>("white");
  const [variant, setVariant] = useState<NumberInputProps["variant"]>("contained");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rounded, setRounded] = useState<boolean>(false);

  const props = {
    value,
    onChange: ({ value }: { value: number }) => setValue(value),
    onBlur: ({ value }: { value: number }) => setValue(value),
    color,
    textColor,
    variant,
    disabled,
    isLoading,
    rounded,
  };

  return (
    <Showcase
      title="Number Inputs"
      className="number-inputs"
      colors={[
        {
          color: color,
          label: "Color",
          onChange: setColor,
        },
        {
          color: textColor,
          label: "Text Color",
          onChange: setTextColor,
        },
      ]}
      selects={[
        {
          value: variant ?? "contained",
          optionsArray: ["contained", "outlined", "flat", "transparent"],
          label: "Variant",
          onChange: setVariant,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
        {
          value: isLoading,
          label: "Is loading",
          onChange: setIsLoading,
        },
        {
          value: rounded,
          label: "Rounded",
          onChange: setRounded,
        },
      ]}>
      {sizes.map(size => (
        <Group key={size}>
          <NumberInput size={size} {...props} />
          <NumberInput size={size} {...props} numberType="integer" />
          <NumberInput size={size} startIcon={<IoChevronForward />} {...props} min={1} />
          <NumberInput size={size} startIcon={<IoChevronBack />} {...props} max={10} />
          <NumberInput size={size} startIcon={<IoClose />} {...props} min={1} max={10} />
        </Group>
      ))}
    </Showcase>
  );
};

export default NumberInputs;
