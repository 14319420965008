import { api } from "@api/api";
import { CigFlowState } from "@lib/enums/generic";

export interface CigFlowStateRes {
  state: CigFlowState;
}

const getCigFlowState = async () => {
  const { data } = await api.post<CigFlowStateRes>("/getCIGFlowState");
  return data;
};

export default getCigFlowState;
