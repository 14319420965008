import useSetExperienceRequestState from "@api/private/update/hooks/useSetExperienceRequestState";
import useGetGames from "@api/public/get/hooks/useGetGames";
import DataCheck from "@components/common/DataCheck";
import ScrollCarousel from "@components/common/ScrollCarousel";
import Button from "@components/form/Button";
import IconButton from "@components/form/IconButton";
import { Option } from "@components/form/Options";
import Select from "@components/form/Select";
import SortArrowIcon from "@components/icons/SortArrowIcon";
import ExperienceRequestCard from "@components/sections/ExperienceRequestCard";
import { sortDirectionInvert } from "@lib/constants/generic";
import { SortDirection } from "@lib/enums/generic";
import {
  createErrorSnackbar,
  createSuccessSnackbar,
  isEmpty,
  padArray,
  sortMap,
} from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { IoCalendar, IoSettings } from "react-icons/io5";
import ExperiencesHeader from "../ExperiencesHeader";
import ExperiencesRequestsRejectModal from "./modals/ExperiencesRequestsRejectModal";

const sortOptions: Option[] = [
  {
    value: "createdAt",
    label: "Created at",
  },
  {
    value: "start",
    label: "Start Date",
  },
  {
    value: "cigPrice",
    label: "Price",
  },
  {
    value: "packageName",
    label: "Package Name",
  },
];

export default function ExperiencesRequests() {
  const {
    breakpoints,
    pushSnackbar,
    cigExperienceRequests,
    cigExperienceRequestsIsLoading,
    cigExperienceRequestsError,
    getAllExperienceRequests,

    customerPendingExperienceRequests,
  } = useGlobalContext();

  const { data: games, isLoading: gamesIsLoading, error: gamesError } = useGetGames(true);

  const [acceptRequestId, setAcceptRequestId] = useState<string>();
  const [rejectRequestId, setRejectRequestId] = useState<string>();
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState(SortDirection.Descending);

  const isLoading = cigExperienceRequestsIsLoading || gamesIsLoading;
  const error = cigExperienceRequestsError ?? gamesError;

  const cardProps = {
    width: 250,
  };

  const { send: acceptRequest, isLoading: acceptIsLoading } = useSetExperienceRequestState(
    acceptRequestId,
    true,
  );

  const handleAcceptRequest = async (requestId: string) => {
    setAcceptRequestId(requestId);

    const { error } = await acceptRequest(requestId);
    if (error) return pushSnackbar(createErrorSnackbar(error, 5000));
    else {
      pushSnackbar(createSuccessSnackbar("Request accepted", 3000));
      getAllExperienceRequests();
    }

    setAcceptRequestId(undefined);
  };

  useEffect(() => {
    getAllExperienceRequests();
    // eslint-disable-next-line
  }, []);
  // must omit getAllExperienceRequests to execute on mount only

  return (
    <div id="experiences-requests-page">
      <div className="pending-requests">
        <ExperiencesHeader
          title="Pending Requests"
          subTitle="Here’s a list of pending requests. Make sure to accept before they timeout."
          actions={
            <>
              <Button
                color="gray-3"
                textColor="black"
                size={breakpoints.md ? "medium" : "large"}
                to="/my-experiences"
                justifyContent="center"
                startIcon={<IoCalendar />}>
                My Requests
                {customerPendingExperienceRequests.length > 0 && (
                  <> ({customerPendingExperienceRequests.length})</>
                )}
              </Button>
              <Button
                color="purple"
                textColor="white"
                size={breakpoints.md ? "medium" : "large"}
                to="/experiences"
                justifyContent="center"
                startIcon={<IoSettings />}>
                Experiences
              </Button>
              <Select
                size={breakpoints.md ? "medium" : "large"}
                color="black-2"
                textColor="white"
                rounded
                startIcon={
                  <IconButton
                    variant="flat"
                    color="white"
                    iconColor="white"
                    rounded
                    icon={<SortArrowIcon sortDirection={sortDirection} />}
                    onClick={() => setSortDirection(sortDirectionInvert[sortDirection])}
                  />
                }
                value={sortKey}
                options={sortOptions}
                onChange={({ value }) => setSortKey(value)}
              />
            </>
          }
        />

        <div className="scroll-carousel-wrapper">
          <DataCheck
            error={error}
            isEmpty={isEmpty(cigExperienceRequests) && !isLoading}
            emptyMessage="No requests outstanding">
            <ScrollCarousel gap={16}>
              {padArray(cigExperienceRequests, null, 7)
                .sort(sortMap(sortKey, sortDirection))
                .map((pendingExperienceRequest, i) => {
                  if (isLoading) return <ExperienceRequestCard key={i} {...cardProps} isLoading />;

                  if (!pendingExperienceRequest) return null;

                  const {
                    packageName,
                    extraCustomerRequestDetails,

                    createdAt,
                    start,
                    end,
                    tickets,

                    cigPrice,
                    cigCurrency,

                    requestID,
                    gameID,
                    platformID,

                    state,
                  } = pendingExperienceRequest;

                  const game = games.find(({ id }) => id === gameID);
                  const platform = game?.platforms.items.find(
                    ({ platform: { id } }) => id === platformID,
                  );

                  return (
                    <ExperienceRequestCard
                      key={i}
                      {...cardProps}
                      packageName={packageName ?? "Custom"}
                      extraCustomerRequestDetails={extraCustomerRequestDetails}
                      createdAt={dayjs(createdAt)}
                      start={dayjs(start)}
                      end={dayjs(end)}
                      tickets={tickets}
                      price={cigPrice}
                      currency={cigCurrency}
                      gameName={game?.shortName}
                      gameImg={game?.tertiaryImageURL}
                      platformName={platform?.platform.shortName}
                      state={state}
                      actions={
                        <>
                          <Button
                            size="medium"
                            color="green"
                            textColor="black"
                            onClick={() => handleAcceptRequest(requestID)}
                            isLoading={acceptIsLoading && acceptRequestId === requestID}>
                            Accept
                          </Button>
                          <Button
                            size="medium"
                            color="black-4"
                            textColor="white"
                            onClick={() => setRejectRequestId(requestID)}
                            disabled={acceptIsLoading && acceptRequestId === requestID}>
                            Reject
                          </Button>
                        </>
                      }
                    />
                  );
                })}
            </ScrollCarousel>
          </DataCheck>
        </div>
      </div>
      <ExperiencesRequestsRejectModal
        requestId={rejectRequestId}
        onReject={getAllExperienceRequests}
        onClose={() => setRejectRequestId(undefined)}
      />
    </div>
  );
}
