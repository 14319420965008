import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createReviewRating, { CreateReviewRatingRes } from "../createReviewRating";

const useCreateReviewRating = (availabilityID?: string, score?: number) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CreateReviewRatingRes | null>(null);

  const send = useCallback(async () => {
    if (availabilityID == null || score == null) return reqState;
    setLoading();
    try {
      const data = await createReviewRating(availabilityID, score);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [availabilityID, score, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateReviewRating;
