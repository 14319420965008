import Section from "@components/section/Section";
import FormEntry from "@components/form/FormEntry";
import Input from "@components/form/Input";
import FormRow from "@components/form/FormRow";
import Button from "@components/form/Button";
import Form from "@components/form/Form";
import useFormManager from "@components/form/hooks/useFormManager";
import useChangePasswordHandles from "./hooks/useChangePasswordHandles";
import { useCallback } from "react";
import SectionBar from "@components/section/SectionBar";
import equal from "fast-deep-equal";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/utils/generic";

const initialValues = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
};

export type ChangePasswordValues = typeof initialValues;

export default function Password() {
  const { pushSnackbar } = useGlobalContext();

  const formManager = useFormManager(
    {
      ...initialValues,
    },
    "changePassword",
  );

  const { values, validations, setValues, onChange, onBlur, resetValidations } = formManager;

  const [handle, isLoading] = useChangePasswordHandles(formManager);

  const discard = useCallback(() => {
    setValues(initialValues);
    resetValidations();
  }, [setValues, resetValidations]);

  return (
    <Section
      title="Password"
      footerActions={
        (!equal(values, initialValues) || isLoading) && (
          <>
            <Button
              color="purple"
              textColor="white"
              size="small"
              type="submit"
              form="change-password-form"
              isLoading={isLoading}>
              Change Password
            </Button>
            <Button
              color="red"
              textColor="red"
              size="small"
              variant="outlined"
              onClick={discard}
              disabled={isLoading}>
              Discard
            </Button>
          </>
        )
      }>
      <Form
        id="change-password-form"
        handle={handle}
        validation={validations.generic}
        onCompletion={() => {
          pushSnackbar(createSuccessSnackbar("Password changed", 3000));
          setValues(initialValues);
        }}>
        <SectionBar>
          <FormRow>
            <label>
              <p>Current Password</p>
            </label>
            <FormEntry validation={validations.oldPassword} showValidationBeneath>
              <Input
                variant="contained"
                color="black-4"
                textColor="white"
                size="medium"
                name="oldPassword"
                type="password"
                value={values.oldPassword}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FormEntry>
          </FormRow>
        </SectionBar>
        <SectionBar>
          <FormRow>
            <label>
              <p>New Password</p>
            </label>
            <FormEntry validation={validations.password} showValidationBeneath>
              <Input
                variant="contained"
                color="black-4"
                textColor="white"
                size="medium"
                name="password"
                type="password"
                value={values.password}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FormEntry>
          </FormRow>
        </SectionBar>
        <SectionBar>
          <FormRow>
            <label>
              <p>Verify Password</p>
            </label>
            <FormEntry validation={validations.confirmPassword} showValidationBeneath>
              <Input
                variant="contained"
                color="black-4"
                textColor="white"
                size="medium"
                name="confirmPassword"
                type="password"
                value={values.confirmPassword}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FormEntry>
          </FormRow>
        </SectionBar>
      </Form>
    </Section>
  );
}
