import Divider from "@components/common/Divider";
import { Position } from "@hooks/useRelativeCoordsAndSize";
import { monthNames } from "@lib/constants/generic";
import dayjs, { Dayjs, UnitType } from "dayjs";
import { forwardRef, useState } from "react";
import { CSSProps, UIColor } from "../../lib/types/generic";
import { daysInMonth, rangeAsArray } from "../../lib/utils/generic";
import { FormElement } from "./Form";
import FormGroup from "./FormGroup";
import Select from "./Select";

interface Props extends CSSProps {
  position?: Position;
  color?: UIColor;
  textColor?: UIColor;
  dividerColor?: UIColor;
  variant?: "contained" | "outlined";
  size?: "small" | "medium" | "large";
  orientation?: "horizontal" | "vertical";
  name?: string;

  minDate?: Dayjs;
  maxDate?: Dayjs;
  defaultDate?: Dayjs;

  disabled?: boolean;

  value?: Dayjs;
  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
}

export type SimpleDatePickerProps = Props;

const SimpleDatePicker = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    position,
    color,
    textColor,
    dividerColor = "gray-2",
    variant,
    size,
    orientation = "horizontal",
    name = "",

    minDate = dayjs("1900-01-01"),
    maxDate = dayjs(),

    disabled,

    value,
    onChange = () => {},
    onBlur = () => {},
  } = props;

  const [date, setDate] = useState<Dayjs>(value ?? dayjs());

  const handleBoth = (onFunc: (target: FormElement) => void) => (target: FormElement) => {
    let value = parseInt(target.value);
    if (isNaN(value)) return;
    if (target.name === "month") value--;
    const newDate = date.set(target.name as UnitType, value);
    onFunc({ value: newDate, name, type: "select" });
    setDate(newDate);
  };

  const days = rangeAsArray(1, daysInMonth(date, minDate, maxDate.endOf("month"))).map(day => ({
    value: day.toString(),
    label: day.toString(),
  }));
  const months = Object.entries(monthNames).map(([key, month]) => ({
    value: key.toString(),
    label: month.slice(0, 3),
  }));
  const years = rangeAsArray(minDate.year(), maxDate.year())
    .map(day => ({
      value: day.toString(),
      label: day.toString(),
    }))
    .reverse();

  const dividerOrientation = {
    horizontal: "vertical",
    vertical: "horizontal",
  }[orientation] as "horizontal" | "vertical";

  return (
    <div ref={ref} className="simple-date-picker">
      <FormGroup orientation={orientation}>
        <Select
          position={position}
          name="date"
          color={color}
          textColor={textColor}
          variant={variant}
          size={size}
          onChange={handleBoth(onChange)}
          onBlur={handleBoth(onBlur)}
          value={value?.date().toString()}
          options={days}
          disabled={disabled}
        />
        <Divider color={dividerColor} orientation={dividerOrientation} />
        <Select
          position={position}
          name="month"
          color={color}
          textColor={textColor}
          variant={variant}
          size={size}
          onChange={handleBoth(onChange)}
          onBlur={handleBoth(onBlur)}
          value={((value?.month() ?? 0) + 1).toString()}
          options={months}
          disabled={disabled}
        />
        <Divider color={dividerColor} orientation={dividerOrientation} />
        <Select
          position={position}
          name="year"
          color={color}
          textColor={textColor}
          variant={variant}
          size={size}
          onChange={handleBoth(onChange)}
          onBlur={handleBoth(onBlur)}
          value={value?.year().toString()}
          options={years}
          disabled={disabled}
        />
      </FormGroup>
    </div>
  );
});

export default SimpleDatePicker;
