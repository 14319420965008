import SplashTitle from "@components/splash/SplashTitle";

const Cta1 = () => {
  return (
    <section className="section cta-1">
      <SplashTitle center>
        Play your favourite games with top streamers - a truly fun and unique experience.{" "}
        <span className="dark-gradient">Book a gaming session</span> on Yakkr today!
      </SplashTitle>
    </section>
  );
};

export default Cta1;
