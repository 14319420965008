import { CognitoUser } from "@aws-amplify/auth";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import register from "../register";

export enum RegisterError {
  EmailTaken = "EMAIL_TAKEN",
}

const useRegister = (email?: string, password?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CognitoUser | null>(null);

  const send = useCallback(async () => {
    if (email == null || password == null) {
      return setError("Email and password are required");
    }

    setLoading();
    try {
      const user = await register(email, password);
      return setSuccess(user);
    } catch (err: any) {
      switch (err.code) {
        case "UsernameExistsException":
          return setError(RegisterError.EmailTaken.toString());
        default:
          return setError(err.message);
      }
    }
  }, [email, password, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useRegister;
