import BarProgress from "@components/common/BarProgress";
import { pathHasLayout } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import { useLocation } from "react-router-dom";

export default function TopProgress() {
  const { loading } = useGlobalContext();

  const location = useLocation();
  const hasLayout = pathHasLayout(location.pathname);

  if (!hasLayout) return null;

  return <BarProgress active={loading} variant="linear" color="purple" className="top-progress" />;
}
