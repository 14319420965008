import useFormManager from "@components/form/hooks/useFormManager";
import useQuery from "@hooks/useQuery";
import { CountryCode } from "@lib/enums/generic";
import dayjs from "dayjs";
import useCigRegisterHandles from "./useCigRegisterHandles";

const initialValues = {
  firstName: "",
  surname: "",
  email: "",
  password: "",
  confirmPassword: "",
  displayName: "",
  dob: dayjs(),
  terms: false,
  emailConfirmCode: "",

  country: undefined as CountryCode | undefined,
  twitchHandle: undefined,
};

export type CigRegisterValues = typeof initialValues;

const useCigRegister = () => {
  const email = useQuery("email");

  if (email) initialValues.email = email;

  const formManager = useFormManager(initialValues, "cigRegister");

  const { values, validations, onChange, onBlur, onChangeMultiple, resetValidations } = formManager;

  const [
    registerHandle,
    emailConfirmHandle,
    userExtensionHandle,
    cigCreateHandle,

    registerIsLoading,
    emailConfirmIsLoading,
    userExtensionIsLoading,
    cigCreateIsLoading,
  ] = useCigRegisterHandles(formManager);

  return {
    values,
    validations,

    onChange,
    onBlur,
    onChangeMultiple,

    handles: [registerHandle, emailConfirmHandle, userExtensionHandle, cigCreateHandle],
    handlesIsLoading: [
      registerIsLoading,
      emailConfirmIsLoading,
      userExtensionIsLoading,
      cigCreateIsLoading,
    ],
    resetValidations,
  };
};

export default useCigRegister;
