import DialogueModal from "@components/modals/DialogueModal";
import Divider from "@components/common/Divider";
import Pill from "@components/common/Pill";
import Button from "@components/form/Button";
import IconButton from "@components/form/IconButton";
import Menu from "@components/form/Menu";
import NumberInput from "@components/form/NumberInput";
import Slider from "@components/form/Slider";
import { stopPropagation } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import {
  IoBan,
  IoEllipsisHorizontal,
  IoMic,
  IoMicOff,
  IoPerson,
  IoVideocam,
  IoVideocamOff,
  IoWarning,
} from "react-icons/io5";
import RoomParticipant from "../../objects/RoomParticipant";
import useRoomContext from "../../roomContext/hooks/useRoomContext";
import useHandleKick from "./hooks/useHandleKick";
import useHandleReport from "./hooks/useHandleReport";

interface Props {
  participant: RoomParticipant;
}

export default function ParticipantMenu(props: Props) {
  const { participant } = props;

  const {
    isLocal,
    identity,
    chatParticipant,
    videoDisabled,
    audioDisabled,
    videoTrack,
    audioTrack,
    volume,
  } = participant;

  const videoIsToggling = videoDisabled === !!videoTrack?.isEnabled && isLocal;
  const audioIsToggling = audioDisabled === !!audioTrack?.isEnabled && isLocal;

  const { breakpoints } = useGlobalContext();
  const {
    session,
    localIdentity,
    activeParticipants,
    toggleParticipantAudio,
    toggleParticipantVideo,
    setParticipantVolume,
  } = useRoomContext();

  const { cig } = session ?? {};

  const { handleReport } = useHandleReport(identity);

  const { kickModalActive, kickIsLoading, toggleKickModal, handleKick } = useHandleKick(
    participant.chatParticipant?.sid,
  );

  const isCig = chatParticipant?.attributes.isCIG;
  const isSelf = localIdentity === identity;
  const isOwner = activeParticipants.find(participant => participant.identity === localIdentity)
    ?.chatParticipant?.attributes.isCIG;

  return (
    <>
      <Menu
        style={{ minWidth: 180, gap: 4 }}
        relativePosition={breakpoints.sm ? "center" : "bottom left"}
        variant="contained"
        color="black-1"
        content={
          <>
            {isCig && (
              <Button
                size="small"
                variant="flat"
                color="purple"
                textColor="white"
                endIcon={<IoPerson />}
                href={`/@${cig?.displayName}`}
                openInNewTab>
                View Profile
              </Button>
            )}
            <Button
              size="small"
              variant="flat"
              color="purple"
              textColor="white"
              endIcon={!audioDisabled ? <IoMic /> : <IoMicOff />}
              onClick={() => toggleParticipantAudio(participant)}
              stopPropagation
              disabled={audioIsToggling}>
              {!audioDisabled ? "Mute" : "Unmute"}
            </Button>
            {isSelf && (
              <Button
                size="small"
                variant="flat"
                color="purple"
                textColor="white"
                endIcon={!videoDisabled ? <IoVideocam /> : <IoVideocamOff />}
                onClick={() => toggleParticipantVideo(participant)}
                stopPropagation
                disabled={videoIsToggling}>
                {!videoDisabled ? "Disable Video" : "Enable Video"}
              </Button>
            )}
            {!isSelf && (
              <Pill
                size="small"
                variant="flat"
                color="purple"
                textColor="white"
                spanClassName="flex-grow flex-column align-start overflow-visible"
                style={{ width: "100%" }}>
                <span
                  onClick={stopPropagation}
                  style={{ width: "100%", gap: 8 }}
                  className="flex-row align-center">
                  Volume{" "}
                  <span className="text-gray-3 flex-row align-center">
                    (
                    <NumberInput
                      wrapperClassName="display-inline"
                      className="text-center"
                      style={{ width: `calc(${[volume.toString().length]}ch + 11px)` }}
                      color="gray-3"
                      textColor="white"
                      size="extra-small"
                      variant="flat"
                      value={volume}
                      min={0}
                      max={100}
                      numberType="integer"
                      onChange={({ value }) => setParticipantVolume(participant, value)}
                      onBlur={({ value }) => setParticipantVolume(participant, value)}
                    />
                    )
                  </span>
                </span>
                <Slider
                  size="extra-small"
                  activeTrackColor="purple"
                  inactiveTrackColor="gray-3"
                  thumbColor="white"
                  value={volume}
                  onChange={({ value }) => setParticipantVolume(participant, value)}
                  stopPropgation
                  style={{ marginTop: 10 }}
                />
              </Pill>
            )}

            {!isSelf && <Divider color="gray-2" />}

            {!isSelf && (
              <Button
                size="small"
                variant="flat"
                color="red"
                textColor="red"
                endIcon={<IoWarning />}
                onClick={handleReport}>
                Report
              </Button>
            )}
            {!isSelf && isOwner && (
              <Button
                size="small"
                variant="flat"
                color="red"
                textColor="red"
                endIcon={<IoBan />}
                onClick={toggleKickModal}>
                Kick
              </Button>
            )}
          </>
        }>
        <IconButton size="small" rounded icon={<IoEllipsisHorizontal />} />
      </Menu>

      <DialogueModal
        title={`Kicking ${participant.chatParticipant?.attributes?.displayName ?? "Unknown"}`}
        subTitle="Once kicked, they will not be able to rejoin the room!"
        icon={<IoBan />}
        active={kickModalActive}
        onCancel={toggleKickModal}
        onConfirm={handleKick}
        confirmButtonText="Kick 'Em"
        confirmButtonProps={{ isLoading: kickIsLoading }}
      />
    </>
  );
}
