import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { Conversation } from "@twilio/conversations";
import { useCallback, useEffect } from "react";

const useSendChatRoomMessage = (chatRoom?: Conversation, body?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<number | null>(null);

  const send = useCallback(async () => {
    if (chatRoom == null || body == null) return reqState;
    setLoading();
    try {
      const data = await chatRoom.sendMessage(body);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [chatRoom, body, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [chatRoom, body, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useSendChatRoomMessage;
