import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import RequestExperienceContextProvider from "./context/RequestExperienceContext";
import ExperiencesRequestDesktop from "./ExperiencesRequestDesktop";
import ExperiencesRequestMobile from "./ExperiencesRequestMobile";

export default function ExperiencesRequest() {
  const { breakpoints } = useGlobalContext();

  return (
    <RequestExperienceContextProvider>
      {breakpoints.sm ? <ExperiencesRequestMobile /> : <ExperiencesRequestDesktop />}
    </RequestExperienceContextProvider>
  );
}
