import DataCheck from "@components/common/DataCheck";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import React, { ReactNode } from "react";
import BecomeTalentOldHeader from "../BecomeTalentOldHeader";

import chatImg from "@images/becomeTalentOld/features/chat.png";
import experiencesImg from "@images/becomeTalentOld/features/experiences.png";
import communitySessionsImg from "@images/becomeTalentOld/features/community_sessions.png";
import pricingImg from "@images/becomeTalentOld/features/pricing.png";
import customSessionsImg from "@images/becomeTalentOld/features/custom_sessions.png";
import streamNotificationsImg from "@images/becomeTalentOld/features/stream_notifications.png";
import schedulingImg from "@images/becomeTalentOld/features/scheduling.png";
import notificationsImg from "@images/becomeTalentOld/features/notifications.png";
import calendarImg from "@images/becomeTalentOld/features/calendar.png";

export default function Features() {
  return (
    <section className="section features">
      <BecomeTalentOldHeader
        title={
          <>
            Some of our cool <span>features</span>
          </>
        }
        subTitle="Offer experiences, have fun, stay safe and earn money with our range of features."
      />

      <div className="features-wrapper">
        <Feature
          img={chatImg}
          title="Live Chat and Messaging"
          content="Host sessions in our secure, private voice and video chat rooms."
          timeout={500}
        />
        <Feature
          img={experiencesImg}
          title="Experience Packages"
          content="Formulate the perfect package to share experiences with your audience."
          timeout={700}
        />
        <Feature
          img={communitySessionsImg}
          title="Community Sessions"
          content="Organise community sessions for your viewers to hop in and meet."
          timeout={800}
        />

        <Feature
          img={pricingImg}
          title="Pricing Flexibility"
          content="Play for free, charge a fee or raise money for charity and receive tips."
          timeout={850}
        />
        <Feature
          img={customSessionsImg}
          title="Custom Sessions"
          content="Fans can tailor their own experiences and submit them for your approval."
          timeout={875}
        />
        <Feature
          img={streamNotificationsImg}
          title="Stream Notifications"
          content="Notifies viewers when your community games are taking place, when a session has been gifted or when a review left."
          timeout={900}
        />

        <Feature
          img={schedulingImg}
          title="Scheduling System"
          content="Stay in control with our fully customised calendar."
          timeout={925}
        />
        <Feature
          img={notificationsImg}
          title="Notification System"
          content="Stay in the know with our helpful notification system. "
          timeout={950}
        />
        <Feature
          img={calendarImg}
          title="Calendar Integrations"
          content="Hook up to your Google and Outlook calendar so you never miss a session."
          timeout={975}
        />
      </div>
    </section>
  );
}

interface FeatureProps {
  img: string;
  title: ReactNode;
  content: ReactNode;
  timeout: number;
}

const Feature = ({ img, title, content, timeout }: FeatureProps) => (
  <SlideOnIntersect direction="left" timeout={timeout}>
    <div className="feature">
      <DataCheck imgs={[img]} loadingIndicator="gloss">
        <img src={img} alt="Flex" className="feature-img" />
      </DataCheck>

      <div className="content-wrapper">
        <h3 className="title">{title}</h3>
        <p className="text">{content}</p>
      </div>
    </div>
  </SlideOnIntersect>
);
