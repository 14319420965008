import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useRequestState from "@hooks/useRequestState";
import { createErrorSnackbar } from "@lib/utils/generic";
import { useCallback, useEffect } from "react";
import Video, { Room } from "twilio-video";

const useVideoRoom = (token?: string, autoSend?: boolean, isHost?: boolean) => {
  const { reqState, setError, setLoading, setSuccess } = useRequestState<Room | undefined>(
    undefined,
  );

  const { breakpoints, pushSnackbar } = useGlobalContext();

  const connect = useCallback(async () => {
    if (token) {
      try {
        setLoading();
        const videoRoom = await Video.connect(token, {
          tracks: [],
          audio: true,
          video: breakpoints.md
            ? { height: 480, frameRate: 24, width: 640 }
            : { height: 720, frameRate: 24, width: 1280 },
          bandwidthProfile: {
            video: {
              ...(breakpoints.md ? { maxSubscriptionBitrate: 2500000 } : {}),
              mode: "presentation",
              dominantSpeakerPriority: "standard",
            },
          },
          dominantSpeaker: true,
          maxAudioBitrate: 16000,
          preferredVideoCodecs: [{ codec: "VP8", simulcast: isHost }],
          networkQuality: { local: 1, remote: 1 },
        });

        setSuccess(videoRoom);
      } catch (err: any) {
        setError(err.message);
        pushSnackbar(createErrorSnackbar(err.message, 5000));
      }
    }
  }, [token, breakpoints.md, isHost, pushSnackbar, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) connect();
    // eslint-disable-next-line
  }, [token, autoSend]);
  // must omit connect to avoid loop

  return {
    connect,
    error: reqState.error,
    isLoading: reqState.isLoading,
    videoRoom: reqState.data,
  };
};

export default useVideoRoom;
