import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CSSProps, UIColor } from "../../lib/types/generic";
import RelativePortal from "@components/common/RelativePortal";
import { Position, RelativePosition } from "../../hooks/useRelativeCoordsAndSize";
import useCombinedRefs from "@hooks/useCombinedRefs";
import useOnScroll from "@hooks/useOnScroll";
import PageCover from "@components/common/PageCover";
import { classNames } from "@lib/utils/generic";

interface Props extends CSSProps {
  color?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent";
  size?: "no-padding" | "extra-small" | "small" | "medium" | "large" | "extra-large";
  content?: ReactNode;
  position?: Position;
  relativePosition?: RelativePosition;
  killMenu?: boolean;
}

export type MenuProps = PropsWithChildren<Props>;

const Menu = forwardRef<HTMLDivElement, MenuProps>((props, forwardedRef) => {
  const {
    color = "black-2",
    variant = "contained",
    size = "medium",
    content,
    position,
    relativePosition = "top",
    killMenu,

    className = "",
    id,
    style,
    children,
  } = props;

  const [active, setActive] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  const ref = useCombinedRefs(menuRef, forwardedRef);

  useOnScroll(undefined, () => setActive(false), true, !active || position === "absolute");

  useLayoutEffect(() => {
    if (active) {
      setTimeout(() => {
        ref.current?.focus();
      }, 50);
    }
  }, [active, ref]);

  if (killMenu) return <>{children}</>;

  const portalRoot = document.getElementById("portal-root");
  if (portalRoot == null) return null;

  const nodes = (
    <div
      tabIndex={0}
      className={classNames("menu", color, variant, size, className)}
      id={id}
      style={style}
      ref={ref}
      onMouseDown={event => event.stopPropagation()}
      onClick={event => {
        event.stopPropagation();
        setActive(false);
      }}>
      {content}
      <PageCover variant="transparent" onClose={() => setActive(false)} className="z-menu-cover" />
    </div>
  );

  return (
    <RelativePortal
      active={active}
      content={nodes}
      relativePosition={relativePosition}
      position={position}
      className="z-select">
      {Children.map(
        children,
        child =>
          isValidElement(child) &&
          cloneElement(child as any, {
            onClick: () => setActive(true),
          }),
      )}
    </RelativePortal>
  );
});

export default Menu;
