import Divider from "@components/common/Divider";
import Drawer from "@components/common/Drawer";
import Tabs, { Tab } from "@components/common/Tabs";
import usePrevious from "@hooks/usePrevious";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import useRoomContext from "../roomContext/hooks/useRoomContext";
import ChatTab from "./chatTab/ChatTab";
import ChannelsTab from "./channelsTab/ChannelsTab";
import SettingsTab from "./settingsTab/SettingsTab";

export default function RoomDrawer() {
  const { breakpoints } = useGlobalContext();
  const { unreadMessages, drawerTab, setDrawerTab, setDrawerParticipantId } = useRoomContext();

  const prevDrawerTab = usePrevious(drawerTab);

  const tabs: Tab[] = [
    {
      value: "channels",
      label: "Channels",
    },
    {
      value: "chat",
      label: "Chat",
      count: unreadMessages?.length,
    },
    {
      value: "settings",
      label: "Settings",
    },
  ];

  return (
    <Drawer
      className="room-drawer"
      side="right"
      type="temporary"
      width={breakpoints.sm ? "90%" : 450}
      style={{ height: window.innerHeight }}
      noPageCover={!breakpoints.sm}
      drawerActive={drawerTab != null}
      onClose={() => {
        setDrawerTab(undefined);
        setDrawerParticipantId(undefined);
      }}>
      <div className="drawer-wrapper">
        <h2 className="drawer-title">Room Management</h2>

        <div>
          <Tabs
            size="medium"
            color="gray-3"
            textColor="gray-3"
            indicatorColor="white"
            indicatorTextColor="white"
            variant="underlined"
            selectedTab={drawerTab}
            tabs={tabs}
            onChange={tab => {
              setDrawerTab(tab);
              setDrawerParticipantId(undefined);
            }}
          />
        </div>

        <Divider color="black-4" />

        <ChannelsTab visible={drawerTab === "channels"} />
        <ChatTab visible={drawerTab === "chat"} drawerJustOpened={prevDrawerTab == null} />
        {drawerTab === "settings" && <SettingsTab />}
      </div>
    </Drawer>
  );
}
