import React, { PropsWithChildren } from "react";
import { CSSProps } from "../../lib/types/generic";

interface Props extends CSSProps {}

export default function FormRow(props: PropsWithChildren<Props>) {
  const { children, className = "", style, id } = props;

  return (
    <div className={`form-row ${className}`} id={id} style={style}>
      {children}
    </div>
  );
}
