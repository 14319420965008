import SlideOnIntersect from "@components/common/SlideOnIntersect";
import { classNames } from "@lib/utils/generic";
import React, { ReactNode } from "react";

interface Props {
  title?: ReactNode;
  subTitle?: ReactNode;
  center?: boolean;
}

export default function BecomeTalentOldHeader(props: Props) {
  const { title, subTitle, center } = props;

  return (
    <SlideOnIntersect direction="down">
      <div className={classNames("become-talent-old-header", center && "center")}>
        {title && <h3 className="title">{title}</h3>}
        {subTitle && <h4 className="sub-title">{subTitle}</h4>}
      </div>
    </SlideOnIntersect>
  );
}
