import useUserIsCig from "@hooks/session/useUserIsCig";
import useUserIsRegistered from "@hooks/session/useUserIsRegistered";
import useUserIsLoggedIn from "@hooks/session/useUserIsLoggedIn";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import { RegisterModalData } from "../context/RegisterContextProvider";

const useInitialStep = () => {
  const isCig = useUserIsCig();
  const isRegistered = useUserIsRegistered();
  const isLoggedIn = useUserIsLoggedIn();

  const { email } = useAuthModalData<RegisterModalData>();

  const initialStep = isCig ? 4 : isRegistered ? 3 : isLoggedIn ? 2 : email ? 1 : 0;

  return initialStep;
};

export default useInitialStep;
