import { RefObject, useLayoutEffect } from "react";
import useForceRerender from "./useForceRerender";
import useTimeout from "./useTimeout";

const useIntersection = (
  ref: RefObject<HTMLElement>,
  onIntersect?: IntersectionObserverCallback,
  options?: IntersectionObserverInit,
  useParent?: boolean,
) => {
  const [key, rerender] = useForceRerender();
  useTimeout(rerender, 50);

  useLayoutEffect(() => {
    const current = useParent ? ref.current?.parentElement : ref.current;
    if (current == null || onIntersect == null) return;
    const observer = new IntersectionObserver(onIntersect, options);
    observer.observe(current);
    return () => observer.unobserve(current);
  }, [ref, useParent, onIntersect, options, key]);
};

export default useIntersection;
