import Modal, { ModalProps } from "./Modal";

import { IoWarningOutline } from "react-icons/io5";
import Input from "@components/form/Input";
import FormEntry from "@components/form/FormEntry";
import useFormManager from "@components/form/hooks/useFormManager";
import { FormHandle } from "@lib/types/form";
import Form from "@components/form/Form";
import FormRow from "@components/form/FormRow";
import { useCallback } from "react";
import { classNames } from "@lib/utils/generic";

interface Props extends ModalProps {
  requiredConfirmText?: string;
  noIcon?: boolean;

  onConfirm?: () => void;
  onCancel?: () => void;
}

const initialValues = {
  confirmText: "",
};

export type DialogModalProps = Props;

export default function DialogueModal(props: Props) {
  const {
    icon = <IoWarningOutline />,
    color = "red",

    title = "Are you sure?",
    subTitle = "This action cannot be undone.",

    requiredConfirmText,
    noIcon,

    onConfirm = () => {},
    onCancel = () => {},
    onClose = () => {},
    className = "",
    ...modalProps
  } = props;

  const {
    values,
    validations,
    onChange,
    onBlur,
    validateCustom,
    validateField,
    setValues,
    setValidation,
  } = useFormManager(initialValues);

  const handle: FormHandle = async () =>
    validateCustom("confirmText", validation => {
      if (values.confirmText !== requiredConfirmText) {
        validateField("confirmText");
        validation.list.push({
          type: "error",
          text: `Input does not match '${requiredConfirmText}'`,
        });
      }
    });

  const reset = useCallback(() => {
    setValues({ confirmText: "" });
    setValidation("confirmText", { key: "confirmText", list: [] });
  }, [setValues, setValidation]);

  const handleCancel = useCallback(() => {
    reset();
    onCancel();
    onClose();
  }, [reset, onCancel, onClose]);

  const handleConfirm = useCallback(() => {
    reset();
    onConfirm();
    onClose();
  }, [reset, onConfirm, onClose]);

  return (
    <Modal
      shake={validations.confirmText?.failed ?? false}
      icon={noIcon ? <></> : icon}
      color={color}
      title={title}
      subTitle={subTitle}
      onClose={handleCancel}
      {...modalProps}
      className={classNames("dialogue-modal", className)}
      cancelButtonProps={{
        onClick: handleCancel,
        ...modalProps.cancelButtonProps,
      }}
      confirmButtonProps={{
        type: requiredConfirmText != null ? "submit" : "button",
        form: requiredConfirmText != null ? "dialogue-modal-form" : undefined,
        onClick: requiredConfirmText != null ? undefined : handleConfirm,
        ...modalProps.confirmButtonProps,
      }}>
      {requiredConfirmText != null && (
        <div>
          <Form handle={handle} id="dialogue-modal-form" onCompletion={handleConfirm}>
            <FormRow>
              <FormEntry validation={validations.confirmText} showValidationBeneath>
                <Input
                  variant="contained"
                  color="black-4"
                  textColor="white"
                  size="medium"
                  name="confirmText"
                  value={values.confirmText}
                  placeholder={`Type '${requiredConfirmText}' to confirm`}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </FormEntry>
            </FormRow>
          </Form>
        </div>
      )}
    </Modal>
  );
}
