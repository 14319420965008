import Button, { ButtonProps } from "@components/form/Button";
import { JustifyContent, UIColor } from "@lib/types/generic";
import { useState } from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { Group, Showcase } from "./StyleGuide";

const Buttons = () => {
  const sizes = ["extra-small", "small", "medium", "large", "extra-large"] as ButtonProps["size"][];

  const [color, setColor] = useState<UIColor>("purple");
  const [textColor, setTextColor] = useState<UIColor>("white");
  const [iconColor, setIconColor] = useState<UIColor>("white");
  const [variant, setVariant] = useState<ButtonProps["variant"]>("contained");
  const [justifyContent, setJustifyContent] = useState<JustifyContent>("space-between");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rounded, setRounded] = useState<boolean>(false);

  const props = {
    color,
    textColor,
    iconColor,
    variant,
    justifyContent,
    disabled,
    isLoading,
    rounded,
  };

  return (
    <Showcase
      title="Buttons"
      className="buttons"
      colors={[
        {
          color: color,
          label: "Color",
          onChange: setColor,
        },
        {
          color: textColor,
          label: "Text Color",
          onChange: setTextColor,
        },
        {
          color: iconColor,
          label: "Icon Color",
          onChange: setIconColor,
        },
      ]}
      selects={[
        {
          value: variant,
          optionsArray: ["contained", "outlined", "flat", "transparent"],
          label: "Variant",
          onChange: setVariant,
        },
        {
          value: justifyContent,
          optionsArray: ["space-between", "flex-start", "center", "flex-end"],
          label: "Justify",
          onChange: setJustifyContent,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
        {
          value: isLoading,
          label: "Is loading",
          onChange: setIsLoading,
        },
        {
          value: rounded,
          label: "Rounded",
          onChange: setRounded,
        },
      ]}>
      {sizes.map(size => (
        <Group key={size}>
          <Button size={size} {...props}>
            Button
          </Button>
          <Button size={size} endIcon={<IoArrowForward />} {...props}>
            Button
          </Button>
          <Button size={size} startIcon={<IoArrowBack />} {...props}>
            Button
          </Button>
          <Button size={size} startIcon={<IoArrowBack />} endIcon={<IoArrowForward />} {...props}>
            Button
          </Button>
        </Group>
      ))}
    </Showcase>
  );
};

export default Buttons;
