import { useEffect } from "react";

const useTimeout = (callback: Function, ms: number) => {
  useEffect(() => {
    const timeout = setTimeout(callback, ms);
    return () => clearInterval(timeout);
  }, [ms, callback]);
};

export default useTimeout;
