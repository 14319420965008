import { RefObject, useEffect } from "react";

const useOnMouseMove = (ref: RefObject<HTMLElement>, callback: (event: MouseEvent) => void) => {
  useEffect(() => {
    const current = ref.current;
    if (current == null) return;

    current.addEventListener("mousemove", callback);

    return () => {
      current.removeEventListener("mousemove", callback);
    };
  }, [ref, callback]);
};

export default useOnMouseMove;
