import { CSSProps } from "@lib/types/generic";

export default function FluentSparkles(cssProps: CSSProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 256 253"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...cssProps}>
      <g filter="url(#filter0_ddd_8250_82483)">
        <path
          d="M126.573 87.507C125.213 92.723 120.581 96.6075 115.109 99.3101C99.5967 104.589 87.3487 109.679 77.2047 114.611C68.2287 118.984 68.3247 132.797 77.3247 137.107C87.8447 142.141 101.053 146.971 115.725 152.707C119.669 154.244 125.245 158.593 126.629 163.943C131.106 180.932 136.617 197.64 143.133 213.976C148.101 226.48 160.397 226.464 165.397 213.976C171.877 197.753 176.813 180.237 182.373 163.573C183.049 161.044 184.347 158.718 186.152 156.801C187.958 154.884 190.215 153.435 192.725 152.581C205.494 148.075 218.092 143.11 230.493 137.697C240.573 133.325 240.781 119.134 230.829 114.485C218.362 108.63 205.625 103.348 192.661 98.6561C190.129 97.7657 187.843 96.3066 185.984 94.3946C184.124 92.4827 182.743 90.1703 181.949 87.641C175.917 69.44 172.093 53.9968 165.677 37.9705C160.677 25.4741 147.565 25.8602 142.557 38.3487C136.173 54.3277 131.717 70.2201 126.573 87.507Z"
          fill="url(#paint0_linear_8250_82483)"
        />
      </g>
      <path
        d="M126.573 87.507C125.213 92.723 120.581 96.6075 115.109 99.3101C99.5967 104.589 87.3487 109.679 77.2047 114.611C68.2287 118.984 68.3247 132.797 77.3247 137.107C87.8447 142.141 101.053 146.971 115.725 152.707C119.669 154.244 125.245 158.593 126.629 163.943C131.106 180.932 136.617 197.64 143.133 213.976C148.101 226.48 160.397 226.464 165.397 213.976C171.877 197.753 176.813 180.237 182.373 163.573C183.049 161.044 184.347 158.718 186.152 156.801C187.958 154.884 190.215 153.435 192.725 152.581C205.494 148.075 218.092 143.11 230.493 137.697C240.573 133.325 240.781 119.134 230.829 114.485C218.362 108.63 205.625 103.348 192.661 98.6561C190.129 97.7657 187.843 96.3066 185.984 94.3946C184.124 92.4827 182.743 90.1703 181.949 87.641C175.917 69.44 172.093 53.9968 165.677 37.9705C160.677 25.4741 147.565 25.8602 142.557 38.3487C136.173 54.3277 131.717 70.2201 126.573 87.507Z"
        fill="url(#paint1_linear_8250_82483)"
      />
      <g filter="url(#filter1_dd_8250_82483)">
        <path
          d="M62.1494 45.8253C61.7094 47.5035 60.2214 48.7563 58.4614 49.623C53.4694 51.3249 49.5254 52.9638 46.2614 54.5554C43.3734 55.9579 43.3974 60.4018 46.3014 61.7885C49.6854 63.4116 53.9334 64.9638 58.6534 66.8076C59.9334 67.3039 61.7174 68.7064 62.1734 70.432C63.5414 75.7347 65.4134 81.3447 67.4774 86.5292C69.0774 90.5554 73.0374 90.5476 74.6454 86.5292C76.7254 81.3132 78.3254 75.6716 80.1094 70.3059C80.3273 69.4915 80.7457 68.7426 81.3276 68.1256C81.9095 67.5085 82.6369 67.0422 83.4454 66.7682C87.5566 65.3203 91.6126 63.725 95.6054 61.9855C96.3342 61.6648 96.9547 61.1455 97.3935 60.4889C97.8324 59.8322 98.0714 59.0656 98.0824 58.2795C98.0933 57.4934 97.8757 56.7207 97.4552 56.0524C97.0348 55.3842 96.429 54.8483 95.7094 54.5081C92.2854 52.9165 88.0294 51.0649 83.4214 49.4182C82.6065 49.1317 81.8706 48.6621 81.2721 48.0466C80.6736 47.4311 80.2289 46.6868 79.9734 45.8725C78.0294 40.0104 76.7974 35.0465 74.7334 29.8778C73.1334 25.8594 68.9014 25.9854 67.2934 30.0038C65.2374 35.149 63.8054 40.2625 62.1494 45.8253Z"
          fill="url(#paint2_linear_8250_82483)"
        />
      </g>
      <path
        d="M62.1494 45.8253C61.7094 47.5035 60.2214 48.7563 58.4614 49.623C53.4694 51.3249 49.5254 52.9638 46.2614 54.5554C43.3734 55.9579 43.3974 60.4018 46.3014 61.7885C49.6854 63.4116 53.9334 64.9638 58.6534 66.8076C59.9334 67.3039 61.7174 68.7064 62.1734 70.432C63.5414 75.7347 65.4134 81.3447 67.4774 86.5292C69.0774 90.5554 73.0374 90.5476 74.6454 86.5292C76.7254 81.3132 78.3254 75.6716 80.1094 70.3059C80.3273 69.4915 80.7457 68.7426 81.3276 68.1256C81.9095 67.5085 82.6369 67.0422 83.4454 66.7682C87.5566 65.3203 91.6126 63.725 95.6054 61.9855C96.3342 61.6648 96.9547 61.1455 97.3935 60.4889C97.8324 59.8322 98.0714 59.0656 98.0824 58.2795C98.0933 57.4934 97.8757 56.7207 97.4552 56.0524C97.0348 55.3842 96.429 54.8483 95.7094 54.5081C92.2854 52.9165 88.0294 51.0649 83.4214 49.4182C82.6065 49.1317 81.8706 48.6621 81.2721 48.0466C80.6736 47.4311 80.2289 46.6868 79.9734 45.8725C78.0294 40.0104 76.7974 35.0465 74.7334 29.8778C73.1334 25.8594 68.9014 25.9854 67.2934 30.0038C65.2374 35.149 63.8054 40.2625 62.1494 45.8253Z"
        fill="url(#paint3_linear_8250_82483)"
      />
      <g filter="url(#filter2_d_8250_82483)">
        <path
          d="M41.8085 158.104C41.2005 160.444 39.1205 162.185 36.6645 163.399C29.7045 165.763 24.2005 168.048 19.6485 170.269C15.6245 172.231 15.6645 178.424 19.7045 180.355C24.4245 182.624 30.3525 184.791 36.9365 187.367C38.7125 188.053 41.2085 190.007 41.8325 192.41C43.7525 199.801 46.3605 207.633 49.2405 214.866C51.4725 220.476 56.9925 220.468 59.2325 214.866C62.1445 207.585 64.3525 199.722 66.8485 192.237C67.1518 191.101 67.7346 190.058 68.5453 189.197C69.3561 188.337 70.3697 187.687 71.4965 187.304C77.2305 185.284 82.8876 183.058 88.4565 180.631C89.4701 180.181 90.3325 179.456 90.9423 178.541C91.5522 177.626 91.8842 176.558 91.8993 175.464C91.9144 174.369 91.6121 173.293 91.0277 172.362C90.4434 171.43 89.6013 170.683 88.6005 170.206C83.0058 167.58 77.2903 165.211 71.4725 163.107C70.3355 162.708 69.3089 162.052 68.4742 161.193C67.6395 160.334 67.0199 159.295 66.6645 158.159C63.9525 149.988 62.2405 143.063 59.3605 135.861C57.1205 130.267 51.2245 130.432 48.9845 136.042C46.1205 143.212 44.1125 150.343 41.8085 158.104Z"
          fill="url(#paint4_radial_8250_82483)"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddd_8250_82483"
          x="68.8734"
          y="27.2412"
          width="171.88"
          height="198.685"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.515625" dy="-0.515625" />
          <feGaussianBlur stdDeviation="0.515625" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.937255 0 0 0 0 0.482353 0 0 0 0 0.329412 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8250_82483" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.515625" dy="0.515625" />
          <feGaussianBlur stdDeviation="1.03125" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.737255 0 0 0 0 0.615686 0 0 0 0 0.415686 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8250_82483"
            result="effect2_dropShadow_8250_82483"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.825" dy="0.515625" />
          <feGaussianBlur stdDeviation="0.4125" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.901961 0 0 0 0 0.458824 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_8250_82483"
            result="effect3_dropShadow_8250_82483"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_8250_82483"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_8250_82483"
          x="43.4906"
          y="26.72"
          width="55.2102"
          height="63.4446"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.20625" dy="0.20625" />
          <feGaussianBlur stdDeviation="0.20625" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.803922 0 0 0 0 0.6 0 0 0 0 0.262745 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8250_82483" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.20625" dy="0.20625" />
          <feGaussianBlur stdDeviation="0.20625" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.882353 0 0 0 0 0.423529 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8250_82483"
            result="effect2_dropShadow_8250_82483"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8250_82483"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8250_82483"
          x="16.343"
          y="130.821"
          width="76.4836"
          height="88.5588"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.309375" dy="-0.309375" />
          <feGaussianBlur stdDeviation="0.309375" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.458824 0 0 0 0 0.462745 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8250_82483" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8250_82483"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8250_82483"
          x1="209.037"
          y1="88.8071"
          x2="121.837"
          y2="182.004"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFCF5A" />
          <stop offset="1" stop-color="#FEA254" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8250_82483"
          x1="118.037"
          y1="230.782"
          x2="165.152"
          y2="129.878"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF886D" />
          <stop offset="1" stop-color="#FF886D" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8250_82483"
          x1="88.6934"
          y1="46.2429"
          x2="60.6293"
          y2="76.2399"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFDA72" />
          <stop offset="1" stop-color="#F7A967" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8250_82483"
          x1="71.0934"
          y1="94.3926"
          x2="71.0934"
          y2="61.8909"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#FDA071" />
          <stop offset="1" stop-color="#FDA071" stop-opacity="0" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_8250_82483"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(80.1125 146.293) rotate(127.642) scale(56.0673 54.8164)">
          <stop offset="0.365" stop-color="#FFBC64" />
          <stop offset="1" stop-color="#FF8F6B" />
        </radialGradient>
      </defs>
    </svg>
  );
}
