import useGetBooking from "@api/private/get/hooks/useGetBooking";
import useBindUserToTickets from "@api/private/misc/hooks/useBindUserToTickets";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { createSuccessSnackbar, pluralise } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useHistory } from "react-router-dom";
import { ClaimTicketsValues } from "../../ClaimTicketsModal";

const useClaimTicketsHandles: UseFormHandles<ClaimTicketsValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const history = useHistory();
  const { pushSnackbar } = useGlobalContext();

  const { isLoading: claimIsLoading, send: bindUserToTickets } = useBindUserToTickets(
    values.linkingID,
  );
  const { isLoading: getIsLoading, send: getBooking } = useGetBooking();

  const handle = async () => {
    let failed = validateFields(["linkingID"]);

    if (!failed) {
      const { error, data } = await bindUserToTickets();

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      const { bookingID } = data ?? {};

      if (!failed) {
        const { error, data } = await getBooking({
          bookingID,
        });

        if (error) {
          failed = validateCustom("generic", validation => {
            validation.list.push({
              type: "error",
              text: error,
            });
          });
        }

        if (data) {
          pushSnackbar(
            createSuccessSnackbar(
              `${data.ticketsOwned} ${pluralise("ticket", data.ticketsOwned)} claimed`,
              2500,
            ),
          );

          history.push(`/bookings/payment-completion/${data.bookingID}?state=success&type=booking`);
        }
      }
    }

    return failed;
  };

  return [handle, claimIsLoading || getIsLoading];
};

export default useClaimTicketsHandles;
