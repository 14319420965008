import { gradients } from "@lib/constants/colors";
import { CSSProps, GradientDefinition, UIGradient } from "@lib/types/generic";
import React, { Children, cloneElement, isValidElement, PropsWithChildren } from "react";

interface Props extends CSSProps {
  gradient?: UIGradient;
}

export type GradientIconProps = Props;

export interface GradientIconInnerProps extends Props {
  gradientDefinition?: GradientDefinition;
  label?: string;
}

export const generateLabelFromGradient = ({ rotation, stopColors }: GradientDefinition) =>
  `${rotation}_${stopColors.map(({ stopColor, offset }) => `${stopColor}-${offset}`).join("_")}`;

export default function GradientIcon(props: PropsWithChildren<Props>) {
  const { gradient, children, ...cssProps } = props;

  const childProps = {
    gradientDefinition: gradient ? gradients[gradient] : undefined,
    label: gradient ? generateLabelFromGradient(gradients[gradient]) : undefined,
    ...cssProps,
  };

  return (
    <>
      {typeof children === "function"
        ? children(childProps)
        : isValidElement(children) && cloneElement(Children.only(children), childProps)}
    </>
  );
}
