import SlideOnIntersect from "@components/common/SlideOnIntersect";
import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import { PropsWithChildren } from "react";

interface Props extends CSSProps {
  center?: boolean;
  noTransition?: boolean;
}

const SplashTitle = (props: PropsWithChildren<Props>) => {
  const { center, className, noTransition, children, ...cssProps } = props;

  const nodes = (
    <h3 className={classNames("splash-title", center && "center", className)} {...cssProps}>
      {children}
    </h3>
  );

  if (noTransition) return nodes;

  return <SlideOnIntersect direction="down">{nodes}</SlideOnIntersect>;
};

export default SplashTitle;
