import ScrollCarousel from "@components/common/ScrollCarousel";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import GameCard from "@components/sections/GameCard";
import { randomise } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { ReactNode, useMemo } from "react";
import SplashTitle from "../SplashTitle";

interface Props {
  title?: ReactNode;
  games?: Array<{
    id: string;
    tertiaryImageURL: string | null;
    statisticName: string | null;
    statisticValue: string | null;
  }>;
  isLoading?: boolean;
}

export default function GamesCarouselSplash(props: Props) {
  const { title = "Featured games", games = [], isLoading } = props;

  const { breakpoints } = useGlobalContext();

  const cardWidth = breakpoints.sm ? 175 : 215;

  const randomisedGames = useMemo(() => randomise(games ?? []), [games]);

  return (
    <section className="section games-carousel">
      <SplashTitle>
        <span className="light-gradient">{title}</span>
      </SplashTitle>

      <ScrollCarousel>
        {isLoading
          ? new Array(20).fill(null).map((_, i) => (
              <SlideOnIntersect key={i} direction="left" timeout={i * 100 + 300} useParent>
                <div>
                  <GameCard scaffold width={cardWidth} />
                </div>
              </SlideOnIntersect>
            ))
          : randomisedGames.map(({ tertiaryImageURL, statisticName, statisticValue }, i) => (
              <SlideOnIntersect key={i} direction="left" timeout={i * 100 + 300} useParent>
                <div>
                  <GameCard
                    gameImg={tertiaryImageURL}
                    statisticName={statisticName}
                    statisticValue={statisticValue}
                    width={cardWidth}
                  />
                </div>
              </SlideOnIntersect>
            ))}
      </ScrollCarousel>
    </section>
  );
}
