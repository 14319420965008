import { log } from "@lib/utils/generic";
import { useEffect, useState } from "react";

export const defaultBreakpoints = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  sl: false,
  ul: false,
};

export type Breakpoints = typeof defaultBreakpoints;

const useBreakpoints = () => {
  const [breakpoints, setBreakpoints] = useState(defaultBreakpoints);

  const onResize = () => {
    const viewWidth = document.body.clientWidth;
    const breakpoints = {
      xs: viewWidth <= 350,
      sm: viewWidth <= 600,
      md: viewWidth <= 960,
      lg: viewWidth <= 1280,
      xl: viewWidth <= 1780,
      sl: viewWidth <= 2560,
      ul: viewWidth <= 3840,
    };
    log(breakpoints);
    setBreakpoints(breakpoints);
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return window.addEventListener("resize", onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { breakpoints, setBreakpoints };
};

export default useBreakpoints;
