import { Children, cloneElement, isValidElement, PropsWithChildren, ReactNode } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { createPortal } from "react-dom";

import { IoClose } from "react-icons/io5";
import Slide from "@components/transitions/Slide";
import { CSSProps, UIColor } from "@lib/types/generic";
import useRelativeCoordsAndSize, {
  Position,
  RelativePosition,
} from "@hooks/useRelativeCoordsAndSize";
import IconButton from "@components/form/IconButton";

interface Props extends CSSProps {
  active?: boolean;
  position?: Position;
  relativePosition?: RelativePosition;
  content?: ReactNode;
  timeout?: number;
  color?: UIColor;
  noWrap?: boolean;
  killTooltip?: boolean;
  onClose?: () => void;
}

export default function Tooltip(props: PropsWithChildren<Props>) {
  const {
    children,
    active = false,
    position = "portal-fixed",
    relativePosition = "right",
    content = <></>,
    timeout = 300,
    color = "black-3",
    noWrap,
    killTooltip,
    style,
    className = "",
    id,

    onClose = () => {},
  } = props;

  const rootRef = useRef<HTMLDivElement | null>(null);

  const [closed, setClosed] = useState(false);

  useEffect(() => setClosed(false), [setClosed, active]);

  useEffect(() => {
    if (closed) onClose();
  }, [closed, onClose]);

  const portalRoot = document.getElementById("portal-root");

  const { coords, size, reCalculate } = useRelativeCoordsAndSize(
    rootRef,
    relativePosition,
    position,
    !(active && !closed),
  );

  useEffect(() => {
    if (active) reCalculate();
  }, [active, reCalculate]);

  if (killTooltip) return <>{children}</>;

  if (portalRoot == null) return null;

  const nodes = (
    <Slide in={active && !closed && !!coords} appear timeout={timeout} distance="short">
      <div
        className={`tooltip-base ${position} ${className}`}
        style={{
          ...style,
          ...(coords && { left: coords.x, top: coords.y }),
        }}
        id={id}>
        <div
          className={`tooltip ${color} ${noWrap ? "no-wrap" : ""} ${relativePosition}`}
          style={{
            maxWidth: noWrap ? "unset" : size.width,
            flexDirection: ["top", "bottom"].includes(relativePosition) ? "column" : "row",
          }}>
          {content}
          <IconButton
            icon={<IoClose />}
            color="white"
            variant="flat"
            size="extra-small"
            className="close"
            onClick={() => setClosed(true)}
          />
        </div>
      </div>
    </Slide>
  );

  return (
    <>
      {Children.map(
        children,
        child => isValidElement(child) && cloneElement(child as any, { ref: rootRef }),
      )}
      {createPortal(nodes, portalRoot)}
    </>
  );
}
