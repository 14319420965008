import bannerImg1 from "@images/misc/homeBanner/banner_img1.jpg";
import bannerImg2 from "@images/misc/homeBanner/banner_img2.jpg";
import bannerImg3 from "@images/misc/homeBanner/banner_img3.jpg";
import bannerImg4 from "@images/misc/homeBanner/banner_img4.jpg";
import bannerImg5 from "@images/misc/homeBanner/banner_img5.jpg";
import bannerImg6 from "@images/misc/homeBanner/banner_img6.jpg";
import bannerImg7 from "@images/misc/homeBanner/banner_img7.jpg";
import bannerImg8 from "@images/misc/homeBanner/banner_img8.jpg";
import bannerImg9 from "@images/misc/homeBanner/banner_img9.jpg";
import bannerImg10 from "@images/misc/homeBanner/banner_img10.jpg";
import bannerImg11 from "@images/misc/homeBanner/banner_img11.jpg";
import bannerImg12 from "@images/misc/homeBanner/banner_img12.jpg";
import bannerImg13 from "@images/misc/homeBanner/banner_img13.jpg";
import bannerImg14 from "@images/misc/homeBanner/banner_img14.jpg";
import bannerImg15 from "@images/misc/homeBanner/banner_img15.jpg";
import bannerImg16 from "@images/misc/homeBanner/banner_img16.jpg";
import bannerImg17 from "@images/misc/homeBanner/banner_img17.jpg";
import bannerImg18 from "@images/misc/homeBanner/banner_img18.jpg";
import bannerImg19 from "@images/misc/homeBanner/banner_img19.jpg";
import bannerImg20 from "@images/misc/homeBanner/banner_img20.jpg";
import useElementSize from "@hooks/useElementSize";

const images = [
  bannerImg1,
  bannerImg2,
  bannerImg3,
  bannerImg4,
  bannerImg5,
  bannerImg6,
  bannerImg7,
  bannerImg8,
  bannerImg9,
  bannerImg10,
  bannerImg11,
  bannerImg12,
  bannerImg13,
  bannerImg14,
  bannerImg15,
  bannerImg16,
  bannerImg17,
  bannerImg18,
  bannerImg19,
  bannerImg20,
];

const useBannerImages = () => {
  const { width } = useElementSize();
  const imageWidth = 142;
  const imageHeight = Math.floor(imageWidth * 1.36);
  const imageGap = 22;

  const numOfColumns = Math.floor((width + imageGap) / (imageWidth + imageGap)) + 1;

  let imagesCopy = [...images];

  const getImage = () => {
    if (imagesCopy.length === 0) imagesCopy = [...images];
    const src = imagesCopy[0];
    imagesCopy.shift();
    return src;
  };

  const imageColumns = new Array(numOfColumns).fill("").map((_, i) => {
    const array = [getImage(), getImage(), getImage()];

    if (i % 2 === 0) array.push(getImage());
    return array;
  });

  return { imageColumns, imageWidth, imageHeight, imageGap };
};

export default useBannerImages;
