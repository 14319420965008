import Avatar from "@components/common/Avatar";
import BackgroundImage from "@components/common/BackgroundImage";
import DataCheck from "@components/common/DataCheck";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import Button, { ButtonProps } from "@components/form/Button";
import { CSSProps } from "@lib/types/generic";
import { classNames, createParams, pluralise } from "@lib/utils/generic";
import { Dayjs } from "dayjs";
import { IoArrowForward, IoCalendar, IoGameController, IoPerson } from "react-icons/io5";
import { Link } from "react-router-dom";

interface Props extends CSSProps {
  eventName?: string;

  sessionId?: string;

  title?: string;
  extraDetails?: string;

  gameName?: string;
  gameImg?: string | null;

  cigName?: string;
  cigImg?: string | null;
  cigTagLine?: string;

  startDate?: Dayjs;
  endDate?: Dayjs;

  totalTickets?: number;
  ticketsLeft?: number;

  isMobile?: boolean;

  width?: number | string;
  buttonSize?: ButtonProps["size"];

  isLoading?: boolean;

  onClick?: () => void;
}

const CigEventSpread = (props: Props) => {
  const {
    eventName = "COMMUNITY SESSION",

    sessionId,

    title,
    extraDetails,

    gameName,
    gameImg,
    cigTagLine,

    startDate,
    endDate,

    totalTickets,
    ticketsLeft,

    cigName,
    cigImg,

    isMobile,

    width = 443,
    buttonSize,

    isLoading,

    onClick,

    className,
    id,
    style,
  } = props;

  return (
    <div
      className={classNames(
        "cig-event-spread",
        isLoading && "is-loading",
        isMobile && "is-mobile",
        className,
      )}
      id={id}
      style={{ fontSize: width, ...style }}
      onClick={onClick}>
      <SlideOnIntersect direction="right" timeout={500}>
        <Link
          to={`/@${cigName}${createParams(
            { sessionId },
            { periodDate: startDate?.toISOString() },
          )}`}
          className="game-wrapper">
          <DataCheck isLoading={isLoading} imgs={[cigImg, gameImg]} loadingIndicator="gloss">
            <BackgroundImage src={gameImg} opacity={1} />

            {!isMobile && (
              <div className="game-details">
                <Link to={`/@${cigName}`}>
                  <Avatar src={cigImg} />
                </Link>

                <div className="text-wrapper">
                  <p className="game-name">{gameName}</p>
                  <Link to={`/@${cigName}`} className="playing-with">
                    with <span className="cig-name">{cigName}</span>
                  </Link>
                </div>
              </div>
            )}
          </DataCheck>
        </Link>
      </SlideOnIntersect>

      <SlideOnIntersect direction="left" timeout={800}>
        <div className="details-wrapper">
          <DataCheck isLoading={isLoading} imgs={[cigImg]} loadingIndicator="spinner">
            <p className="event-type">{eventName}</p>
            <p className="title">{title}</p>
            <p className="extra-details">{extraDetails}</p>

            {!isMobile && (
              <div className="cig-details">
                <Link to={`/@${cigName}`}>
                  <Avatar src={cigImg} />
                </Link>

                <div className="text-wrapper">
                  <Link to={`/@${cigName}`} className="cig-name">
                    {cigName}
                  </Link>
                  <p className="tag-line">{cigTagLine}</p>
                </div>
              </div>
            )}

            <div className="event-details">
              <div className="info-snippet">
                <IoCalendar />
                <p className="info-snippet-text">
                  {startDate?.format("DD MMM")}
                  <br />
                  {startDate?.format("mm:HH")} - {endDate?.format("mm:HH")}
                </p>
              </div>

              <div className="info-snippet">
                <IoGameController />
                <p className="info-snippet-text">{gameName}</p>
              </div>

              <div className="info-snippet">
                <IoPerson />
                <p className="info-snippet-text">
                  {totalTickets} people
                  <br />
                  <span className="gradient">
                    {ticketsLeft} {pluralise("ticket", ticketsLeft ?? 0)} left
                  </span>
                </p>
              </div>

              {isMobile && (
                <div className="info-snippet">
                  <Link to={`/@${cigName}`}>
                    <Avatar src={cigImg} />
                  </Link>
                  <Link to={`/@${cigName}`}>
                    <p className="info-snippet-text">
                      <span className="gradient">{cigName}</span>
                    </p>
                  </Link>
                </div>
              )}
            </div>

            <Button
              size={buttonSize}
              color="gray-1"
              textColor="white"
              endIcon={<IoArrowForward />}
              to={`/@${cigName}${createParams(
                { sessionId },
                { periodDate: startDate?.toISOString() },
              )}`}
              className="book-button">
              Book now
            </Button>
          </DataCheck>
        </div>
      </SlideOnIntersect>
    </div>
  );
};

export default CigEventSpread;
