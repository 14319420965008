import { api } from "@api/api";
import { CancellationStatus, Currency } from "@lib/enums/generic";

export interface MyBookingPartial {
  bookingID: string;
  availabilityID: string;

  cancellation: CancellationStatus | null;

  price: string;
  currency: Currency;

  ticketsOwned: number;
  tickets: Ticket[];

  session: Session;
}

interface Session {
  id: string;
  cigID: string;

  createdAt: string;
  to: string;
  from: string;

  title: string;
  extraInfo: string;

  cig: Cig;
  game: Game;
  platform: Platform;

  maxSlots: number;
  slotsAvailable: number;

  charity: boolean;
  charityName: string;
  private: boolean;

  free: boolean;
  price: number;
  currency: Currency;

  exclusive: boolean;
}

interface Cig {
  id: string;

  displayName: string;
  tagLine: string;
  category: string;
  subCategory: string;

  profilePictureURL: string;
  bannerPictureURL: string;
}

interface Game {
  id: string;

  shortName: string;
  longName: string;
  description: string;
  category: string;

  primaryImageURL: string;
  secondaryImageURL: string;
}

interface Platform {
  id: string;

  name: string;
  shortName: string;

  icon: string;
}

interface Ticket {
  ticketID: string;
}

const getAllBookings = async () => {
  const { data } = await api.post<MyBookingPartial[]>("/getAllBookings");
  return data;
};

export default getAllBookings;
