import useCreateClaim from "@api/private/create/hooks/useCreateClaim";
import { UseFormHandles } from "@components/form/hooks/useFormManager";
import { useHistory } from "react-router-dom";
import { CreateClaimValues } from "../CreateClaimModal";

const useCreateClaimHandles: UseFormHandles<CreateClaimValues> = (
  { values, validateFields, validateCustom },
  redirect: boolean,
) => {
  const history = useHistory();

  const { isLoading, send: createClaim } = useCreateClaim(
    values.bookingID?.toString(),
    values.reason,
    values.reasonClass,
  );

  const handle = async () => {
    let failed = validateFields(["reasonClass", "bookingID", "reason"]);

    if (!failed) {
      const { error, data } = await createClaim();

      if (error) {
        failed = validateCustom("generic", validator => {
          validator.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (!failed && redirect) history.push(`/settings/support/${data?.claimID}?action=view`);
    }

    return failed;
  };

  return [handle, isLoading];
};

export default useCreateClaimHandles;
