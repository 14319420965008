import AuthWrapper from "../AuthWrapper";
import useRegisterContext from "./context/useRegisterContext";
import RegisterForm from "./RegisterForm";

const RegisterInner = () => {
  const { registerIsLoading, emailConfirmIsLoading, userExtensionIsLoading } = useRegisterContext();

  return (
    <AuthWrapper
      title="Sign up"
      isLoading={registerIsLoading || emailConfirmIsLoading || userExtensionIsLoading}>
      <RegisterForm />
    </AuthWrapper>
  );
};

export default RegisterInner;
