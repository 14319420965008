import { api } from "@api/api";

export interface CreateReportRes {}

const createReport = async (targetUserID: string, reason: string) => {
  const { data } = await api.post<CreateReportRes>("/createReport", {
    targetUserID,
    reason,
  });
  return data;
};

export default createReport;
