import ScrollCarousel from "@components/common/ScrollCarousel";
import SlideOnIntersect from "@components/common/SlideOnIntersect";
import CigCard from "@components/sections/CigCard";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { ReactNode } from "react";
import SplashTitle from "../SplashTitle";

interface Props {
  title?: ReactNode;
  cigs?: Array<{
    id: string;
    displayName: string;
    tagLine: string;
    profilePictureURL: string | null;
  }>;
  isLoading?: boolean;
}

export default function CigCardCarouselSplash(props: Props) {
  const { title = "Who's ready to play?", cigs = [], isLoading } = props;

  const { breakpoints } = useGlobalContext();

  const cardWidth = breakpoints.sm ? 175 : 215;

  return (
    <section className="section cig-card-carousel" id="streamers">
      <SplashTitle>
        <span className="light-gradient">{title}</span>
      </SplashTitle>

      <ScrollCarousel>
        {isLoading
          ? new Array(20).fill(null).map((_, i) => (
              <SlideOnIntersect key={i} direction="left" timeout={i * 100 + 300} useParent>
                <div>
                  <CigCard scaffold width={cardWidth} />
                </div>
              </SlideOnIntersect>
            ))
          : cigs.map(({ displayName, tagLine, profilePictureURL }, i) => (
              <SlideOnIntersect key={i} direction="left" timeout={i * 100 + 300} useParent>
                <div>
                  <CigCard
                    displayName={displayName}
                    tagLine={tagLine}
                    profilePictureURL={profilePictureURL}
                    width={cardWidth}
                  />
                </div>
              </SlideOnIntersect>
            ))}
      </ScrollCarousel>
    </section>
  );
}
