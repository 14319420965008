import { api } from "@api/api";
import { Currency, ExperienceRequestState } from "@lib/enums/generic";

export interface CigPendingExperienceRequest {
  requestID: string;
  userID: string;
  gameID: string;
  platformID: string;

  packageName?: string;
  extraCustomerRequestDetails: string;

  state: ExperienceRequestState;
  createdAt: string;

  start: string;
  end: string;

  tickets: number;
  streamOption: boolean;
  cigPrice: number;
  cigCurrency: Currency;
}

const getCigPendingExperienceRequests = async () => {
  const { data } = await api.post<CigPendingExperienceRequest[]>("/getCIGPendingSessionRequests");
  return data;
};

export default getCigPendingExperienceRequests;
