import Button, { ButtonProps } from "@components/form/Button";
import React, { ReactNode } from "react";

interface Props {
  body?: ReactNode;
  buttonText?: string;
  buttonProps?: ButtonProps;
}

export default function NoAccessCover(props: Props) {
  const { body, buttonText, buttonProps } = props;

  return (
    <div className="no-access-cover">
      <p>{body}</p>
      <Button size="small" color="purple" textColor="white" {...buttonProps}>
        {buttonText}
      </Button>
    </div>
  );
}
