import IconButton from "./IconButton";
import { forwardRef, useCallback, useRef } from "react";

import { CSSProps, UIColor } from "../../lib/types/generic";
import { ReactElement } from "react";
import { FormElement } from "./Form";
import useCombinedRefs from "@hooks/useCombinedRefs";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import Divider from "@components/common/Divider";
import { classNames } from "@lib/utils/generic";
import NumberInput from "./NumberInput";
import Input from "./Input";

interface Props extends CSSProps {
  color?: UIColor;
  textColor?: UIColor;
  iconColor?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  name?: string;
  value?: any;
  placeholder?: string;
  type?: "text" | "number";

  valuePrefix?: string;
  valueSuffix?: string;

  backIcon?: ReactElement;
  forwardIcon?: ReactElement;

  rounded?: boolean;
  disabled?: boolean;
  disableHover?: boolean;
  backDisabled?: boolean;
  forwardDisabled?: boolean;

  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
  onFocus?: (target: FormElement) => void;
  onBack?: () => void;
  onForward?: () => void;

  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export type IncrementPickerProps = Props;

const IncrementPicker = forwardRef<HTMLDivElement | null, Props>((props, forwardedRef) => {
  const {
    color = "white",
    textColor = "black",
    iconColor = textColor,
    variant = "contained",
    size = "medium",
    name,
    value,
    placeholder,
    type = "text",

    valuePrefix = "",
    valueSuffix = "",

    backIcon = <IoChevronBack />,
    forwardIcon = <IoChevronForward />,

    rounded,
    disabled,
    disableHover,
    backDisabled,
    forwardDisabled,

    style,
    className = "",
    id,

    onChange,
    onBlur = () => {},
    onFocus = () => {},
    onBack = () => {},
    onForward = () => {},

    onMouseEnter,
    onMouseLeave,
  } = props;

  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const ref = useCombinedRefs(forwardedRef, inputRef);

  const InputComponent = {
    text: Input,
    number: NumberInput,
  }[type];

  const handleBlur = useCallback(() => {
    onBlur({ name: name ?? "", value, type: "increment-picker" });
  }, [name, value, onBlur]);

  const handleFocus = useCallback(() => {
    onFocus({ name: name ?? "", value, type: "increment-picker" });
  }, [name, value, onFocus]);

  return (
    <div
      ref={ref}
      className={classNames(
        "increment-picker",
        color,
        variant,
        size,
        className,
        rounded && "rounded",
        disabled && "disabled",
        disableHover && "hover-disabled",
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <IconButton
        tabIndex={-1}
        variant="flat"
        size={size}
        color={iconColor}
        iconColor={iconColor}
        icon={backIcon}
        rounded={rounded}
        disabled={backDisabled || disabled}
        disableHover={disableHover}
        className="increment-icon"
        onClick={onBack}
        onBlur={handleBlur}
      />
      {variant === "outlined" && (
        <Divider orientation="vertical" color={color} className="increment-divider" />
      )}
      <InputComponent
        variant="flat"
        size={size}
        color={iconColor}
        textColor={textColor}
        placeholder={placeholder}
        name={name}
        value={onChange || type === "number" ? value : `${valuePrefix} ${value} ${valueSuffix}`}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        wrapperClassName="increment-input-wrapper"
        className={classNames("increment-input", !onChange && "read-only")}
        id={id}
        style={style}
        rounded={rounded}
        disabled={disabled}
        disableHover={disableHover}
        noError
      />
      {variant === "outlined" && (
        <Divider orientation="vertical" color={color} className="increment-divider" />
      )}
      <IconButton
        tabIndex={-1}
        variant="flat"
        size={size}
        color={iconColor}
        iconColor={iconColor}
        icon={forwardIcon}
        rounded={rounded}
        disabled={forwardDisabled || disabled}
        disableHover={disableHover}
        className="increment-icon"
        onClick={onForward}
        onBlur={handleBlur}
      />
    </div>
  );
});

export default IncrementPicker;
