import TestimonialsSplash from "@components/splash/sections/TestimonialsSplash";

import ar1seAvatar from "@images/becomeTalent/testimonials/ar1se.jpg";
import cancelAvatar from "@images/becomeTalent/testimonials/canceldota.jpg";
import nic0leAvatar from "@images/becomeTalent/testimonials/nic0le.jpg";

const Testimonials = () => {
  return (
    <TestimonialsSplash
      testimonials={[
        {
          avatarImg: ar1seAvatar,
          displayName: "Ar1se",
          text: (
            <>
              Making a living on twitch can be quite difficult. So being able to give a great
              experience to my viewers by gaming with them whilst earning from that is a win-win.
            </>
          ),
        },
        {
          avatarImg: cancelAvatar,
          displayName: "canceldota",
          text: (
            <>
              Yakkr has been a great way for me to talk and play with my viewers. I know my chat
              really like when I host Yakkr events.
            </>
          ),
        },
        {
          avatarImg: nic0leAvatar,
          displayName: "Nic0le",
          text: (
            <>
              I love that I'm able to game with my viewers through Yakkr. I've had so much fun and
              my community really enjoy it. So glad I signed up.
            </>
          ),
        },
      ]}
    />
  );
};

export default Testimonials;
