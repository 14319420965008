import Tabs from "@components/common/Tabs";
import Form from "@components/form/Form";
import React from "react";
import useRequestExperienceContext from "./context/useRequestExperienceContext";
import ExperiencesRequestSummaryPanel from "./ExperiencesRequestSummaryPanel";

export default function ExperiencesRequestDesktop() {
  const { validations, createRequest, resetValidations, tabs, selectedTab, setSelectedTab } =
    useRequestExperienceContext();

  return (
    <div id="experiences-request-page" className="desktop">
      <div className="left-wrapper">
        <Tabs
          size="medium"
          variant="contained"
          color="black-1"
          textColor="gray-2"
          indicatorColor="black-4"
          indicatorTextColor="white"
          rounded
          tabs={tabs}
          selectedTab={selectedTab}
          onChange={setSelectedTab}
        />
        <Form
          id="request-experience"
          onCompletion={createRequest}
          resetValidations={resetValidations}
          validation={validations.generic}>
          {tabs.find(tab => tab?.value === selectedTab)?.component}
        </Form>
      </div>

      <ExperiencesRequestSummaryPanel />
    </div>
  );
}
