import { BsBarChartFill } from "react-icons/bs";
import { FaBrain, FaDiscord, FaGripHorizontal } from "react-icons/fa";
import { IoLaptop, IoPerson, IoShareSocial, IoStar, IoTrophy } from "react-icons/io5";
import { RiVideoAddFill, RiVipCrown2Fill } from "react-icons/ri";
import FeatureBox from "./FeatureBox";
import FeatureSet from "./FeatureSet";

const Features = () => {
  return (
    <section className="pane features">
      <div className="inner">
        <h2 className="flow-sub-title text-gray-1">Our game changing features</h2>

        <div className="feature-sets">
          <FeatureSet color="gray-2" icon={<BsBarChartFill />} title="Basic">
            <FeatureBox title="Yakkr Platform" icon={<IoLaptop />}>
              <p>
                Our platform makes the process of growing your stream a breeze. Follow along with
                our audit process, and the daily / weekly / monthly tasks.
              </p>
            </FeatureBox>
            <FeatureBox title="Monthly Live Event" icon={<FaGripHorizontal />}>
              <ul>
                <li>
                  Exclusive access to a live discussion through Discord with a Twitch expert.{" "}
                </li>
                <li>Additional private curated content</li>
              </ul>
            </FeatureBox>

            <FeatureBox
              title="Network"
              icon={<FaDiscord />}
              className="bg-gradient-blend-horizontal">
              <p>
                Access to our private Discord which holds a wide variety of established streamers
                looking to collab and share their insights.
              </p>
            </FeatureBox>
          </FeatureSet>

          <FeatureSet color="purple" icon={<IoStar />} title="Standard">
            <FeatureBox icon={<IoShareSocial />} title="Partnered Content">
              <p>
                We will partner you with a number of creators of a similar size to collaborate and
                benefit from each others audiences. Networking is one of the most effect ways to
                grow.
              </p>
            </FeatureBox>
            <FeatureBox icon={<IoPerson />} title="Consultant">
              <p>
                You will have monthly private consultations with one of our team to build and
                execute a content strategy suited to your interests and time commitment.
              </p>
            </FeatureBox>
            <FeatureBox icon={<IoTrophy />} title="Growth Tools">
              <p>
                Being a successful creator is like being a successful business. We will exposure you
                to more advanced growth techniques to help accelerate your success.
              </p>
            </FeatureBox>
          </FeatureSet>

          <FeatureSet color="gold" icon={<RiVipCrown2Fill />} title="Premium">
            <FeatureBox
              icon={<RiVipCrown2Fill />}
              title="Private Mentorship from a Successful Creator"
              className="bg-gradient-gold-vertical">
              <p>
                Monthly private consultations with a successful creator who is already making a full
                time living out of their love for gaming.
              </p>
            </FeatureBox>
            <FeatureBox icon={<FaBrain />} title="Branding and Design">
              <p>
                Depending on your current progress, we will assess the state of your current brand
                personality. If needed, we will propose a new brand strategy and provide assets for
                your social channels.
              </p>
            </FeatureBox>
            <FeatureBox icon={<RiVideoAddFill />} title="Sponsorship Ready">
              <p>
                Helping you to create a plan to secure brand deals by marketing yourself in the
                right way and helping you identify brands that your audience will engage with.
              </p>
            </FeatureBox>
          </FeatureSet>
        </div>
      </div>
    </section>
  );
};

export default Features;
