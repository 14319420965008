import { SortDirection } from "@lib/enums/generic";
import { classNames } from "@lib/utils/generic";
import React from "react";
import { IconBaseProps } from "react-icons";
import { IoArrowDown } from "react-icons/io5";

interface Props extends IconBaseProps {
  sortDirection: SortDirection;
}

export default function SortArrowIcon(props: Props) {
  const { sortDirection, ...iconProps } = props;

  return (
    <IoArrowDown
      {...iconProps}
      className={classNames(
        "sort-arrow",
        sortDirection === SortDirection.Ascending ? "up" : "down",
        iconProps.className,
      )}
    />
  );
}
