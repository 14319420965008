import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getRequestAvailability, { RequestAvailability } from "../getRequestAvailability";

const useGetRequestAvailability = (
  cigID?: string,
  month?: string,
  year?: number,
  duration?: number,
  packageID?: string,
  autoSend?: boolean,
) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<RequestAvailability | null>(null);

  const send = useCallback(async () => {
    if (cigID == null || month == null || year == null || duration == null) return reqState;
    setLoading();
    try {
      const requestAvailability = await getRequestAvailability(
        cigID,
        month,
        year,
        duration,
        packageID,
      );
      setSuccess(requestAvailability);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [cigID, month, year, duration, packageID, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [cigID, month, year, duration, packageID, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetRequestAvailability;
