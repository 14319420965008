import StorageItem, { ItemResponse } from "@lib/utils/StorageItem";
import { useCallback, useEffect, useMemo, useState } from "react";

const useStorageItem = <D>(key: string, initial?: D) => {
  const storageItem = useMemo(() => new StorageItem<D>(key), [key]);

  const prevItem = storageItem.get();

  const initialValue = prevItem.data != null ? prevItem.data : initial ?? null;

  const [item, setStore] = useState<ItemResponse<D>>({ ...prevItem, data: initialValue });

  const set = (data: D | null) => {
    setStore(storageItem.set(data));
    window.dispatchEvent(new Event("storage"));
  };
  const remove = () => {
    setStore(storageItem.remove());
  };

  const handleStorageChange = useCallback(() => {
    setStore(storageItem.get());
  }, [storageItem]);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [handleStorageChange]);

  return { item, set, remove };
};

export default useStorageItem;
