import YakkrBanner from "@components/common/YakkrBanner";
import Slide from "@components/transitions/Slide";
import React, { ReactNode } from "react";
import { OverlayAlertProps } from "../hooks/useCigOverlayAlerts";

interface Props extends OverlayAlertProps {
  icon?: ReactNode;
  title?: ReactNode;
  subTitle?: ReactNode;
}

const timeout = 2000;

export default function OverlayAlert(props: Props) {
  const { icon, title, subTitle, onFinish = () => {} } = props;

  return (
    <Slide in appear timeout={timeout} direction="down" onEntered={onFinish}>
      <div className="overlay-alert-wrapper">
        <div className="overlay-alert">
          <div className="icon-wrapper">
            {icon}
            <YakkrBanner color="white" size="extra-small" />
          </div>
          <div className="text-wrapper">
            <h1 className="title">{title}</h1>
            <h2 className="sub-title">{subTitle}</h2>
          </div>
        </div>
      </div>
    </Slide>
  );
}
