import React from "react";

import stripe from "@images/brands/stripe.svg";
import Button from "@components/form/Button";
import useCigPaymentPortal from "@hooks/stripe/useCigPaymentPortal";
import { IoArrowForward } from "react-icons/io5";
import useUserIsCig from "@hooks/session/useUserIsCig";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";

// import useCustomerSubscriptionPortal from "@hooks/stripe/useCustomerSubscriptionPortal";
// import { IoCard} from "react-icons/io5";

export default function Payment() {
  const { userExtension } = useGlobalContext();

  const { stripeOnboardingPending, stripeRequiresInformationUpdate } = userExtension ?? {};

  const isCig = useUserIsCig();

  // const { isLoading: customerPortalIsLoading, viewCustomerSubscriptionPortal } =
  //   useCustomerSubscriptionPortal();
  const { isLoading: cigPortalIsLoading, viewCigPaymentPortal } = useCigPaymentPortal();

  return (
    <div className="payment">
      <div className="wrapper">
        <div className="brand-wrapper">
          <img className="stripe-img" src={stripe} alt="Stripe" />
        </div>

        <div className="title-wrapper">
          <h2 className="title">Your payments are managed by our partner, Stripe</h2>
        </div>

        <div className="buttons">
          {/* <Button
            color="purple"
            endIcon={<IoCard />}
            onClick={viewCustomerSubscriptionPortal}
            isLoading={customerPortalIsLoading}>
            My Subscriptions
          </Button> */}

          {isCig && (
            <div className="group">
              <Button
                color="purple"
                textColor="white"
                size="large"
                endIcon={<IoArrowForward />}
                onClick={viewCigPaymentPortal}
                isLoading={cigPortalIsLoading}
                disabled={!!stripeOnboardingPending || !!stripeRequiresInformationUpdate}>
                Go to Dashboard
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
