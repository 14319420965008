import CalendarContextProvider from "@components/calendar/context/CalendarContextProvider";
import CalendarManage from "@components/calendar/manage/CalendarManage";
import Modal from "@components/modals/Modal";
import React from "react";
import useCigProfileContext from "../context/hooks/useCigProfileContext";

export default function Manage() {
  const {
    cigProfile,
    calendarOpen,
    setCalendarOpen,

    sessionId,
    periodDate,

    setSessionId,
    setPeriodDate,

    getUpcomingSessions,

    isOwnProfile,
  } = useCigProfileContext();

  const handleClose = () => {
    setCalendarOpen(false);
    setSessionId(null);
    setPeriodDate(null);
  };

  if (!isOwnProfile) return null;

  return (
    <Modal active={calendarOpen} onClose={handleClose} bare className="cig-calendar-modal">
      <CalendarContextProvider
        sessionId={sessionId}
        periodDate={periodDate}
        onSessionIdChange={setSessionId}
        onPeriodDateChange={setPeriodDate}
        active={!!sessionId}
        cigProfile={cigProfile ?? undefined}
        onClose={handleClose}
        onUpdate={getUpcomingSessions}>
        <CalendarManage />
      </CalendarContextProvider>
    </Modal>
  );
}
