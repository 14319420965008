import { forwardRef, useCallback } from "react";
import { CSSProps, UIColor } from "../../lib/types/generic";
import { FormElement } from "./Form";
import { Dayjs } from "dayjs";
import { isoDayNames } from "@lib/constants/generic";
import IconButton from "./IconButton";
import { classNames } from "@lib/utils/generic";
import HoverTooltip from "@components/common/HoverTooltip";

export interface SimpleCalendarCount {
  date: Dayjs;
  count: number;
}

interface Props extends CSSProps {
  color?: UIColor;
  headTextColor?: UIColor;
  bodyTextColor?: UIColor;
  variant?: "contained" | "outlined" | "flat" | "transparent";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";

  name?: string;
  maxDate?: Dayjs;
  minDate?: Dayjs;

  counts?: SimpleCalendarCount[];
  countText?: string;

  rounded?: boolean;
  disabled?: boolean;
  disableHover?: boolean;

  month: Dayjs;
  value: Dayjs;

  dayChars?: number;

  width?: number | string;
  height?: number | string;

  onChange?: (target: FormElement<Dayjs>) => void;
}

export type SimpleCalendarProps = Props;

const SimpleCalendar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    color = "white",
    headTextColor = "black",
    bodyTextColor = "black",
    variant = "contained",
    size = "medium",

    name,
    maxDate,
    minDate,

    counts,
    countText,

    rounded,
    disabled,
    disableHover,

    month,
    value,

    dayChars = 1,

    width,
    height,

    onChange = () => {},

    className,
    id,
    style,
  } = props;

  const handleChange = useCallback(
    (newValue: Dayjs) => {
      if (maxDate != null && newValue.isAfter(maxDate))
        return onChange({ name: name ?? "", value: maxDate });
      if (minDate != null && newValue.isBefore(minDate))
        return onChange({ name: name ?? "", value: minDate });

      onChange({ name: name ?? "", value: newValue });
    },
    [maxDate, minDate, name, onChange],
  );

  return (
    <div
      className={classNames(
        "simple-calendar",
        color,
        `${headTextColor}-head-text`,
        `${bodyTextColor}-body-text`,
        variant,
        size,
        rounded && "rounded",
        className,
      )}
      style={{ width, height, ...style }}
      id={id}>
      <div className="simple-calendar-inner" ref={ref} tabIndex={0}>
        <table className="simple-calendar-table">
          <thead className="simple-calendar-table-header">
            <tr>
              {Object.values(isoDayNames).map(day => (
                <th key={day}>{day.slice(0, dayChars)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array(6)
              .fill(null)
              .map((_, i) => {
                const startOfWeek = month
                  .startOf("isoWeek")
                  .add(i, "week")
                  .set("hour", value.hour())
                  .set("minute", value.minute())
                  .set("second", value.second());

                return (
                  <tr key={i}>
                    {Array(7)
                      .fill(null)
                      .map((_, ii) => {
                        const day = startOfWeek.add(ii, "day");
                        const selected = day.isSame(value, "day");
                        const isAfter = maxDate != null && day.isAfter(maxDate);
                        const isBefore = minDate != null && day.endOf("day").isBefore(minDate);

                        const count =
                          counts?.find(({ date }) => date.isSame(day, "day"))?.count ?? 0;

                        const noCount = counts && count === 0;

                        return (
                          <td key={ii}>
                            <HoverTooltip
                              killTooltip={noCount || !countText}
                              content={<p>{countText?.replace("{count}", count.toString())}</p>}>
                              <IconButton
                                size={size}
                                color={bodyTextColor}
                                iconColor={bodyTextColor}
                                variant="flat"
                                icon={<p>{day.format("D")}</p>}
                                onClick={() => handleChange(day)}
                                rounded={rounded}
                                active={selected}
                                disabled={isAfter || isBefore || disabled || noCount}
                                disableHover={disableHover}
                              />
                            </HoverTooltip>
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default SimpleCalendar;
