import useCancelCustomerExperienceRequest from "@api/private/cancel/hooks/useCancelCustomerExperienceRequest";
import DialogueModal from "@components/modals/DialogueModal";
import { createErrorSnackbar, createSuccessSnackbar } from "@lib/utils/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";

interface Props {
  requestId?: string;
  onRequestCancel: () => void;
  onClose: () => void;
}

export default function ExperienceRequestCancelModal(props: Props) {
  const { requestId, onRequestCancel, onClose } = props;

  const { pushSnackbar } = useGlobalContext();

  const { send, isLoading } = useCancelCustomerExperienceRequest(requestId);

  const cancelRequest = async () => {
    const { error } = await send();
    if (error) pushSnackbar(createErrorSnackbar(error, 5000));
    else {
      pushSnackbar(createSuccessSnackbar("Request cancelled", 3000));
      onRequestCancel();
    }
    onClose();
  };

  return (
    <DialogueModal
      color="red"
      subTitle="Once cancelled, it cannot be undone."
      titleDesign="condensed"
      buttonsDesign="flat"
      active={!!requestId}
      confirmButtonText="Cancel Session"
      confirmButtonProps={{
        textColor: "black",
        isLoading,
      }}
      onConfirm={cancelRequest}
      onCancel={onClose}
    />
  );
}
