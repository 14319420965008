import { api } from "@api/api";
import { Currency, ExperienceRequestState } from "@lib/enums/generic";

export interface ExperienceRequest {
  requestID: string;
  cigID: string;
  gameID: string;
  platformID: string;
  bookingID: string | null;

  packageName?: string;
  extraCustomerRequestDetails: string;

  state: ExperienceRequestState;
  createdAt: string;

  start: string;
  end: string;

  tickets: number;
  streamOption: boolean;
  customerPrice: number;
  customerCurrency: Currency;
}

const getExperienceRequests = async () => {
  const { data } = await api.post<ExperienceRequest[]>("/getUserSessionRequests");
  return data;
};

export default getExperienceRequests;
