import { api } from "@api/api";

export interface CreateExperienceRequestRes {
  url: string;
  checkoutID: string;
  paymentIntentID: string;
  requestID: string;
}

const createExperienceRequest = async (
  gameID: string,
  platformID: string,
  cigID: string,
  customerRequestDetails: string,

  tickets?: number,
  streamOption?: boolean,

  startTime?: string,
  endTime?: string,

  packageID?: string,
) => {
  const { data } = await api.post<CreateExperienceRequestRes>("/createRequestAndGetCheckout", {
    gameID,
    platformID,
    cigID,
    customerRequestDetails,

    startTime,
    endTime,

    ...(packageID == null ? { tickets, streamOption } : { packageID }),
  });
  return data;
};

export default createExperienceRequest;
