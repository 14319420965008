import Ribbon from "@components/common/Ribbon";
import Button from "@components/form/Button";
import useGAConsent from "@hooks/useGAConsent";
import { privacyUrl } from "@lib/constants/generic";
import { pathHasLayout } from "@lib/utils/generic";
import React from "react";
import { BiCookie } from "react-icons/bi";
import { useLocation } from "react-router-dom";

export default function CookieConsentBanner() {
  const { consented, setConsent } = useGAConsent();

  const location = useLocation();
  const hasLayout = pathHasLayout(location.pathname);

  if (!hasLayout) return null;

  return (
    <Ribbon active={consented == null} color="purple" className="cookie-consent-ribbon">
      <div className="wrapper">
        <div className="text-wrapper">
          <div className="title-wrapper">
            <BiCookie />
            <h3 className="title">Cookie Consent</h3>
          </div>
          <p className="text">
            We use cookies to enhance your experience, and for other analytical purposes. By
            choosing 'Accept', you are consenting to the storage these cookies on your device. For
            more information, please see our <a href={privacyUrl}>Privacy Policy</a>.
          </p>
        </div>
        <div className="button-row">
          <Button
            variant="flat"
            color="white"
            textColor="white"
            size="small"
            onClick={() => setConsent(false)}>
            Reject
          </Button>
          <Button color="white" size="small" onClick={() => setConsent(true)}>
            Accept
          </Button>
        </div>
      </div>
    </Ribbon>
  );
}
