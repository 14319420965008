import { forwardRef, PropsWithChildren, ReactNode } from "react";

import { IoCloseCircleOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import { useState } from "react";
import { useLayoutEffect } from "react";
import { FormValidation } from "../../lib/types/form";
import { CSSProps } from "../../lib/types/generic";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { RelativePosition } from "@hooks/useRelativeCoordsAndSize";
import Tooltip from "@components/common/Tooltip";

interface Props extends CSSProps {
  label?: ReactNode;
  validation?: FormValidation;
  required?: boolean;
  showValidationBeneath?: boolean;
  relativePosition?: RelativePosition;

  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const FormEntry = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    children,
    validation,
    label,
    required,
    showValidationBeneath,
    relativePosition,

    className = "",
    style,
    id,

    ...events
  } = props;

  const { breakpoints } = useGlobalContext();
  const [loaded, setLoaded] = useState(false);

  const validationContent = (
    <div>
      {validation &&
        validation.list.map((item, i) => (
          <div key={i} className={`entry validation ${item.type}`}>
            {item.type === "error" ? (
              <IoCloseCircleOutline fontSize={22} />
            ) : (
              <IoCheckmarkCircleOutline fontSize={22} />
            )}
            <p className="text">{item.text}</p>
          </div>
        ))}
    </div>
  );

  const validationFailed = validation && validation.failed && validation.list.length > 0;

  useLayoutEffect(() => setLoaded(true), [setLoaded]);

  const shouldShowBeneath = showValidationBeneath == null ? breakpoints.sm : showValidationBeneath;

  return (
    <div
      className={`form-entry ${className} ${validationFailed ? "error" : ""}`}
      id={id}
      style={style}
      ref={ref}
      {...events}>
      {label && (
        <div className="form-label">
          {typeof label === "string" && required ? `${label}*` : label}
        </div>
      )}
      {loaded &&
        (shouldShowBeneath ? (
          children
        ) : (
          <Tooltip
            active={validationFailed}
            content={validationContent}
            relativePosition={
              relativePosition ? relativePosition : breakpoints.sm ? "top" : "right"
            }>
            {children}
          </Tooltip>
        ))}
      {shouldShowBeneath && validation?.failed && (
        <div className="validation not-tooltip error">
          {validation.list.map(({ text }, i) => (
            <p className="text" key={i}>
              {text}
            </p>
          ))}
        </div>
      )}
    </div>
  );
});

export default FormEntry;
