import { RefObject, useCallback, useLayoutEffect } from "react";

const useScrollToBottom = (ref: RefObject<HTMLElement>, disabled?: boolean) => {
  const scrollToBottom = useCallback(() => {
    const current = ref.current;
    if (!current) return;
    const top = current.scrollHeight;
    current.scrollTo({ top, behavior: "smooth" });
  }, [ref]);

  useLayoutEffect(() => {
    if (disabled) return;
    const timeout = setTimeout(() => scrollToBottom(), 50);
    return () => clearTimeout(timeout);
  }, [ref, disabled, scrollToBottom]);

  return scrollToBottom;
};

export default useScrollToBottom;
