import Select, { SelectProps } from "@components/form/Select";
import { Position } from "@hooks/useRelativeCoordsAndSize";
import { JustifyContent, UIColor } from "@lib/types/generic";
import { useState } from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { Group, Showcase } from "./StyleGuide";

const Selects = () => {
  const sizes = ["extra-small", "small", "medium", "large", "extra-large"] as SelectProps["size"][];

  const [value, setValue] = useState("");
  const [color, setColor] = useState<UIColor>("purple");
  const [textColor, setTextColor] = useState<UIColor>("white");
  const [optionColor, setOptionColor] = useState<UIColor>("purple");
  const [optionTextColor, setOptionTextColor] = useState<UIColor>("white");
  const [variant, setVariant] = useState<SelectProps["variant"]>("contained");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [justifyContent, setJustifyContent] = useState<JustifyContent>("space-between");
  const [rounded, setRounded] = useState<boolean>(false);

  const options = new Array(10).fill(null).map((_, i) => ({
    value: `option-${i + 1}`,
    label: `Option ${i + 1}`,
    startIcon: <IoArrowBack />,
  }));

  const props = {
    position: "portal-absolute" as Position,
    value,
    onChange: ({ value }: { value: string }) => setValue(value),
    color,
    textColor,
    optionColor,
    optionTextColor,
    variant,
    placeholder: "Placeholder",
    disabled,
    isLoading,
    justifyContent,
    rounded,
    options,
  };

  return (
    <Showcase
      title="Selects"
      className="selects"
      colors={[
        {
          color: color,
          label: "Color",
          onChange: setColor,
        },
        {
          color: textColor,
          label: "Text Color",
          onChange: setTextColor,
        },
        {
          color: optionColor,
          label: "Option Color",
          onChange: setOptionColor,
        },
        {
          color: optionTextColor,
          label: "Option Text Color",
          onChange: setOptionTextColor,
        },
      ]}
      selects={[
        {
          value: variant ?? "contained",
          optionsArray: ["contained", "outlined", "flat", "transparent"],
          label: "Variant",
          onChange: setVariant,
        },
        {
          value: justifyContent,
          optionsArray: ["space-between", "flex-start", "center", "flex-end"],
          label: "Justify",
          onChange: setJustifyContent,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
        {
          value: isLoading,
          label: "Is loading",
          onChange: setIsLoading,
        },
        {
          value: rounded,
          label: "Rounded",
          onChange: setRounded,
        },
      ]}>
      {sizes.map(size => (
        <Group key={size}>
          <Select size={size} {...props} />
          <Select size={size} {...props} endIcon={<IoArrowForward />} />
          <Select size={size} {...props} startIcon={<IoArrowBack />} />
          <Select size={size} {...props} startIcon={<IoArrowBack />} endIcon={<IoArrowForward />} />
        </Group>
      ))}
    </Showcase>
  );
};

export default Selects;
