import IconButton from "@components/form/IconButton";
import Menu, { MenuProps } from "@components/form/Menu";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React, { Children, cloneElement, isValidElement, PropsWithChildren } from "react";
import { IoMenu } from "react-icons/io5";

interface Props extends Omit<MenuProps, "content" | "position"> {}

export default function SectionRowButtons(props: PropsWithChildren<Props>) {
  const { children, ...menuProps } = props;

  const { breakpoints } = useGlobalContext();

  if (!breakpoints.md) return <>{children}</>;

  return (
    <Menu
      relativePosition="left"
      color="black-3"
      size="small"
      {...menuProps}
      content={Children.map(
        children,
        child =>
          isValidElement(child) &&
          cloneElement(child as any, {
            variant: "flat",
            size: "small",
          }),
      )}>
      <IconButton variant="flat" size="small" icon={<IoMenu />} />
    </Menu>
  );
}
