import React from "react";

export default function ClockIcon() {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M100 0C44.7768 0 0 44.7768 0 100C0 155.223 44.7768 200 100 200C155.223 200 200 155.223 200 100C200 44.7768 155.223 0 100 0ZM139.397 130.737L133.013 139.442C132.875 139.632 132.7 139.792 132.499 139.914C132.298 140.035 132.075 140.116 131.842 140.151C131.61 140.187 131.373 140.176 131.145 140.119C130.917 140.062 130.702 139.961 130.513 139.821L93.5937 112.902C93.3637 112.737 93.1768 112.519 93.0486 112.266C92.9204 112.014 92.8547 111.734 92.8571 111.451V50C92.8571 49.0179 93.6607 48.2143 94.6429 48.2143H105.379C106.362 48.2143 107.165 49.0179 107.165 50V105.246L138.996 128.259C139.799 128.817 139.978 129.933 139.397 130.737Z"
        fill="white"
      />
    </svg>
  );
}
