import React, { PropsWithChildren, ReactNode } from "react";
import CalendarHeader from "./CalendarHeader";
import { CSSProps } from "@lib/types/generic";

interface Props extends CSSProps {
  title: string;
  actionPanel?: ReactNode;
}

export default function Calendar(props: PropsWithChildren<Props>) {
  const {
    title,
    actionPanel,

    className = "",
    id,
    style,

    children,
  } = props;

  return (
    <div className={`calendar ${className}`} id={id} style={style}>
      <div className="calendar-wrapper">
        <CalendarHeader title={title} />

        {children}
      </div>
      {actionPanel}
    </div>
  );
}
