import ProgressBar from "@components/common/ProgressBar";
import Button, { ButtonProps } from "@components/form/Button";
import IoCardGradient from "@components/icons/IoCardGradient";
import IoPersonGradient from "@components/icons/IoPersonGradient";
import IoSparklesGradient from "@components/icons/IoSparklesGradient";
import StickyBar from "@components/layout/StickyBar";
import useUserIsCig from "@hooks/session/useUserIsCig";
import useQuery from "@hooks/useQuery";
import { CigFlowState } from "@lib/enums/generic";
import { createParams, pathMatchSome } from "@lib/utils/generic";
import { isValidElement, useCallback, useEffect, useMemo, useState } from "react";
import { IoArrowForward, IoCheckmark } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import useGlobalContext from "../../globalContext/hooks/useGlobalContext";

const useCigFlow = () => {
  const { stickyBar, setStickyBar, breakpoints, cigFlowState, progressCigFlowState } =
    useGlobalContext();

  const redirect = useQuery<"stripe">("redirect");

  const { pathname } = useLocation();

  const isCig = useUserIsCig();

  const [hidden, setHidden] = useState(false);

  const buttonSize = (
    breakpoints.md ? "medium" : !breakpoints.sl ? "extra-large" : "large"
  ) as ButtonProps["size"];

  const hide = useCallback(
    (hide?: boolean) => {
      setHidden(!!hide);
      setStickyBar(null);
    },
    [setHidden, setStickyBar],
  );

  const commonProps = useMemo(
    () => ({
      buttonSize,
      hide,
      id: "cig-flow-sticky-bar",
    }),
    [buttonSize, hide],
  );

  useEffect(() => {
    if (isValidElement(stickyBar)) {
      if (stickyBar.props.id !== "cig-flow-sticky-bar") return;
      if (
        ![CigFlowState.Unknown, CigFlowState.Ignored, CigFlowState.Finished].includes(cigFlowState)
      )
        return;
      if (!stickyBar) return;

      setStickyBar(null);
    }
  }, [cigFlowState, stickyBar, setStickyBar]);

  useEffect(() => {
    setHidden(false);
  }, [cigFlowState, setHidden]);

  useEffect(() => {
    if (!isCig || hidden) return;

    switch (cigFlowState) {
      case CigFlowState.StripeSetup: {
        if (pathMatchSome(pathname, ["/cig/register"])) setStickyBar(null);
        else setStickyBar(<StripePromptBar {...commonProps} />);

        break;
      }
      case CigFlowState.ExperiencesSetup: {
        if (pathMatchSome(pathname, ["/experiences/*/*/*"]))
          setStickyBar(<ExperiencesSetupBar {...commonProps} />);
        else setStickyBar(<ExperiencesPromptBar {...commonProps} />);

        break;
      }
      case CigFlowState.ProfileSetup: {
        if (pathMatchSome(pathname, ["/@*/*/*"]))
          setStickyBar(<ProfileSetupBar {...commonProps} />);
        else setStickyBar(<ProfilePromptBar {...commonProps} />);

        break;
      }
      case CigFlowState.PreFinished: {
        setStickyBar(<FinishedBar {...commonProps} />);
        break;
      }
    }
  }, [cigFlowState, isCig, pathname, hidden, commonProps, setStickyBar]);

  useEffect(() => {
    if (redirect === "stripe") progressCigFlowState(CigFlowState.StripeSetup);
  }, [progressCigFlowState, redirect]);
};

export default useCigFlow;

interface CommonStickyBarProps {
  buttonSize: ButtonProps["size"];
  hide: (hide?: boolean) => void;
}

const StripePromptBar = (props: CommonStickyBarProps) => {
  const { buttonSize, hide } = props;

  const [closed, setClosed] = useState(false);

  const { cigProfile } = useGlobalContext();

  return (
    <StickyBar
      closed={closed}
      icon={<IoCardGradient gradient="gradient-faded-horizontal" />}
      actions={
        <>
          <Button
            color="purple"
            textColor="white"
            size={buttonSize}
            to={`/cig/register?cig=${cigProfile?.category}`}>
            Set up
          </Button>
          <CancelButton buttonSize={buttonSize} onCancel={() => setClosed(true)} />
        </>
      }
      pagePersistent
      onClose={() => hide(true)}>
      Let's get your Yakkr journey started by getting your{" "}
      <span className="gradient">payment details</span> out the way.
    </StickyBar>
  );
};

const ExperiencesPromptBar = (props: CommonStickyBarProps) => {
  const { buttonSize, hide } = props;

  const [closed, setClosed] = useState(false);

  return (
    <StickyBar
      closed={closed}
      icon={<IoSparklesGradient gradient="gradient-faded-horizontal" />}
      actions={
        <>
          <Button color="purple" textColor="white" size={buttonSize} to="/experiences">
            Set up
          </Button>
          <CancelButton buttonSize={buttonSize} onCancel={() => setClosed(true)} />
        </>
      }
      pagePersistent
      onClose={() => hide(true)}>
      Get some <span className="gradient">experience bundles</span> setup so you can start playing
      with your fans as soon as possible.
    </StickyBar>
  );
};

const ProfilePromptBar = (props: CommonStickyBarProps) => {
  const { buttonSize, hide } = props;

  const [closed, setClosed] = useState(false);

  const { cigProfile } = useGlobalContext();

  return (
    <StickyBar
      closed={closed}
      icon={<IoPersonGradient gradient="gradient-faded-horizontal" />}
      actions={
        <>
          <Button
            color="purple"
            textColor="white"
            size={buttonSize}
            to={`/@${cigProfile?.displayName}${createParams({
              action: "edit",
            })}`}>
            Set up
          </Button>
          <CancelButton buttonSize={buttonSize} onCancel={() => setClosed(true)} />
        </>
      }
      pagePersistent
      onClose={() => hide(true)}>
      A lick of paint will go a long way to making your fans feel at home when they visit your{" "}
      <span className="gradient">profile</span>.
    </StickyBar>
  );
};

const ExperiencesSetupBar = (props: CommonStickyBarProps) => {
  const { buttonSize, hide } = props;

  const { progressCigFlowState } = useGlobalContext();

  return (
    <StickyBar
      actions={
        <Button
          color="purple"
          textColor="white"
          size={buttonSize}
          endIcon={<IoArrowForward />}
          onClick={() => progressCigFlowState(CigFlowState.ExperiencesSetup)}>
          Next
        </Button>
      }
      pagePersistent
      onClose={hide}>
      <FlowProgressBar step={6} />
      Let's start with your <span className="gradient">experience bundles</span>. These let your
      fans choose the games they play and when. We've created some basic bundles to give you a head
      start, just add some games, and your good to go!
    </StickyBar>
  );
};

const ProfileSetupBar = (props: CommonStickyBarProps) => {
  const { buttonSize, hide } = props;

  const { progressCigFlowState } = useGlobalContext();

  return (
    <StickyBar
      actions={
        <Button
          color="purple"
          textColor="white"
          size={buttonSize}
          endIcon={<IoArrowForward />}
          onClick={() => progressCigFlowState(CigFlowState.ProfileSetup)}>
          Skip
        </Button>
      }
      pagePersistent
      onClose={hide}>
      <FlowProgressBar step={7} />
      Now let's get your <span className="gradient">profile</span> filled out. We recommend using
      clean and high quality images for both your profile and banner. Good imagery shows you mean
      business.
    </StickyBar>
  );
};

const FinishedBar = (props: CommonStickyBarProps) => {
  const { buttonSize, hide } = props;

  const { progressCigFlowState, cigProfile } = useGlobalContext();

  return (
    <StickyBar
      actions={
        <Button
          color="purple"
          textColor="white"
          size={buttonSize}
          endIcon={<IoCheckmark />}
          onClick={() => progressCigFlowState(CigFlowState.PreFinished)}>
          Done
        </Button>
      }
      pagePersistent
      onClose={() => {
        hide();
        progressCigFlowState(CigFlowState.PreFinished);
      }}>
      <FlowProgressBar step={8} />
      You're all done and ready to go.{" "}
      {cigProfile?.accepted ? (
        <>
          Link your <span className="gradient">profile</span> to your fans and they can begin
          booking sessions.
        </>
      ) : (
        <>
          Once you've been accepted, your <span className="gradient">profile</span> will become
          visible to your fans.
        </>
      )}
    </StickyBar>
  );
};

interface CancelButtonProps {
  buttonSize: ButtonProps["size"];
  onCancel: () => void;
}

const CancelButton = (props: CancelButtonProps) => {
  const { buttonSize, onCancel } = props;

  const { setCigFlowState } = useGlobalContext();

  return (
    <Button
      name="close-button"
      color="gray-1"
      textColor="white"
      size={buttonSize}
      onClick={() => {
        setCigFlowState(CigFlowState.Ignored);
        onCancel();
      }}>
      Cancel
    </Button>
  );
};

interface FlowProgressBarProps {
  step: number;
}

const FlowProgressBar = (props: FlowProgressBarProps) => {
  const { step } = props;

  const { breakpoints } = useGlobalContext();

  return (
    <ProgressBar
      progressColor="purple"
      barColor="black-3"
      height={breakpoints.sm ? 8 : 14}
      width={breakpoints.sm ? 80 : 150}
      percent={12.5 * step}
      style={{ marginBottom: 20 }}
    />
  );
};
