import IconButton, { IconButtonProps } from "@components/form/IconButton";
import { UIColor } from "@lib/types/generic";
import { useState } from "react";
import { IoAlert, IoArrowForward, IoClose } from "react-icons/io5";
import { Group, Showcase } from "./StyleGuide";

const IconButtons = () => {
  const sizes = [
    "no-padding",
    "extra-small",
    "small",
    "medium",
    "large",
    "extra-large",
  ] as IconButtonProps["size"][];

  const [color, setColor] = useState<UIColor>("purple");
  const [iconColor, setIconColor] = useState<UIColor>("white");
  const [variant, setVariant] = useState<IconButtonProps["variant"]>("contained");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rounded, setRounded] = useState<boolean>(false);

  const props = {
    color,
    iconColor,
    variant,
    disabled,
    isLoading,
    rounded,
  };

  return (
    <Showcase
      title="Icon Buttons"
      className="icon-buttons"
      colors={[
        {
          color: color,
          label: "Color",
          onChange: setColor,
        },
        {
          color: iconColor,
          label: "Icon Color",
          onChange: setIconColor,
        },
      ]}
      selects={[
        {
          value: variant ?? "contained",
          optionsArray: ["contained", "outlined", "flat", "transparent"],
          label: "Variant",
          onChange: setVariant,
        },
      ]}
      checkboxes={[
        {
          value: disabled,
          label: "Disabled",
          onChange: setDisabled,
        },
        {
          value: isLoading,
          label: "Is loading",
          onChange: setIsLoading,
        },
        {
          value: rounded,
          label: "Rounded",
          onChange: setRounded,
        },
      ]}>
      {sizes.map(size => (
        <Group key={size}>
          <IconButton size={size} {...props} icon={<IoAlert />} />
          <IconButton size={size} {...props} icon={<IoClose />} />
          <IconButton size={size} {...props} icon={<IoArrowForward />} />
        </Group>
      ))}
    </Showcase>
  );
};
export default IconButtons;
