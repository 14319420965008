export enum CIG {
  Gamer = "GAMER",
  Celebrity = "CELEBRITY",
  Influencer = "INFLUENCER",
}

export enum Platform {
  Twitter = "TWITTER",
  YouTube = "YOUTUBE",
  Twitch = "TWITCH",
  TikTok = "TIKTOK",
  Facebook = "FACEBOOK",
  Instagram = "INSTAGRAM",
  Discord = "DISCORD",
}

export enum CIGPlatform {
  Twitter = "TWITTER",
  YouTube = "YOUTUBE",
  Twitch = "TWITCH",
  TikTok = "TIKTOK",
  Facebook = "FACEBOOK",
  Instagram = "INSTAGRAM",
}

export enum VerificationPlatform {
  Twitter = "TWITTER",
  YouTube = "YOUTUBE",
  Twitch = "TWITCH",
  TikTok = "TIKTOK",
  Facebook = "FACEBOOK",
  Instagram = "INSTAGRAM",
}

export enum YakkrSocialPlatform {
  Twitter = "TWITTER",
  Instagram = "INSTAGRAM",
  TikTok = "TIKTOK",
  Facebook = "FACEBOOK",
  Discord = "DISCORD",
}

export enum Currency {
  USD = "USD",
  AED = "AED",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BMD = "BMD",
  BND = "BND",
  BSD = "BSD",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CNY = "CNY",
  CZK = "CZK",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  GBP = "GBP",
  GEL = "GEL",
  GIP = "GIP",
  GMD = "GMD",
  GYD = "GYD",
  HKD = "HKD",
  HRK = "HRK",
  HTG = "HTG",
  IDR = "IDR",
  ILS = "ILS",
  ISK = "ISK",
  JMD = "JMD",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KRW = "KRW",
  KYD = "KYD",
  KZT = "KZT",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  MAD = "MAD",
  MDL = "MDL",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRO = "MRO",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SEK = "SEK",
  SGD = "SGD",
  SLL = "SLL",
  SOS = "SOS",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UZS = "UZS",
  WST = "WST",
  XCD = "XCD",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  NotSet = "NOT_SET",
}

export enum SnackbarType {
  Info = "INFO",
  Error = "ERROR",
  Success = "SUCCESS",
  Warn = "WARN",
}

export enum CountryCode {
  Australia = "AU",
  Austria = "AT",
  Belgium = "BE",
  Bulgaria = "BG",
  Canada = "CA",
  Croatia = "HR",
  Cyprus = "CY",
  CzechRepublic = "CZ",
  Denmark = "DK",
  Estonia = "EE",
  Finland = "FI",
  France = "FR",
  Germany = "DE",
  Gibraltar = "GI",
  Greece = "GR",
  HongKongSARChina = "HK",
  Hungary = "HU",
  Ireland = "IE",
  Italy = "IT",
  Japan = "JP",
  Latvia = "LV",
  Liechtenstein = "LI",
  Lithuania = "LT",
  Luxembourg = "LU",
  Malta = "MT",
  Mexico = "MX",
  Netherlands = "NL",
  NewZealand = "NZ",
  Norway = "NO",
  Poland = "PL",
  Portugal = "PT",
  Romania = "RO",
  Singapore = "SG",
  Slovakia = "SK",
  Slovenia = "SI",
  Spain = "ES",
  Sweden = "SE",
  Switzerland = "CH",
  UnitedArabEmirates = "AE",
  UnitedKingdom = "GB",
  UnitedStates = "US",
}

export enum CardBrand {
  AmericanExpress = "amex",
  CartesBancaires = "cartes_bancaires",
  DinersClub = "diners_club",
  Discover = "discover",
  JCB = "jcb",
  Mastercard = "mastercard",
  Visa = "visa",
  UnionPay = "unionpay",
}

export enum SortDirection {
  Ascending = "ASC",
  Descending = "DESC",
}

export enum ClaimState {
  Resolved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Created = "CREATED",
}

export enum ClaimReason {
  TechnicalDifficulties = "TECHNICAL_ISSUE",
  CigNoShow = "MISSED_APPOINTMENT",
  Other = "OTHER",
}

export enum NotificationType {
  BookingConfirmed = "BOOKING_CONFIRMED",
  BookingCancelled = "BOOKING_CANCELLED",
  BookingCancelledAndRefunded = "BOOKING_CANCELLED_AND_REFUNDED",
  DisputeResolved = "DISPUTE_RESOLVED",
  DisputeRejected = "DISPUTE_REJECTED",
  SubscriptionCreated = "SUBSCRIPTION_CREATED",
  SubscriptionCancelled = "SUBSCRIPTION_CANCELLED",
  SessionStarting = "SESSION_STARTING",
  VerificationRequested = "VERIFICATION_REQUESTED",
  VerificationRejected = "VERIFICATION_REJECTED",
  VerificationAccepted = "VERIFICATION_ACCEPTED",
  CigAccepted = "CIG_ACCEPTED",
  CigRejected = "CIG_REJECTED",

  CigSessionCreate = "CIG_SESSION_CREATED",
  GuestBookingConfirmed = "GUEST_BOOKING_CONFIRMED",
  BookingConfirmedNotifyCig = "BOOKING_CONFIRMED_NOTIFY_CIG",
  CigSessionStartingSoon = "CIG_SESSION_STARTING_REMINDER",
  DayReminder = "DAY_REMINDER",
  HourReminder = "HOUR_REMINDER",

  NewRequestRecieved = "NEW_REQUEST_RECEIVED",
  CigAcceptedRequest = "CIG_ACCEPTED_REQUEST",
  CigDeclinedRequest = "CIG_DECLINED_REQUEST",
  RequestExpired = "REQUEST_EXPIRED",
  RequestWithdrawn = "REQUEST_WITHDRAWN",
  RequestAcknowledged = "REQUEST_ACKNOWLEDGED",
  CustomerCancelledAcceptedRequest = "CUSTOMER_CANCELLED_ACCEPTED_REQUEST",
  CigAcceptedRequestReminder = "CIG_ACCEPTED_REQUEST_REMINDER",
}

export enum CancellationStatus {
  CustomerCancel = "CUSTOMER_CANCEL",
  CigCancel = "CIG_CANCEL",
}

export enum StreamNotificationType {
  Tipped = "TIP",
  Reviewed = "REVIEW",
  Booked = "BOOKED",
  StartingSoon = "STARTING_SOON",
  SessionGifted = "GIFTED",
}

export enum ExperienceRequestState {
  PendingCardAuth = "PENDING_CARD_AUTH",
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  CigCancelled = "CIG_CANCELLED",
  CustomerCancelled = "CUSTOMER_CANCELLED",
  Expired = "EXPIRED",
  Completed = "COMPLETED",
  ExpiredCardAuth = "EXPIRED_CARD_AUTH",
}

export enum AuthState {
  Registering = "REGISTERING",
  LoggingIn = "LOGGING_IN",
  CigRegistering = "CIG_REGISTERING",
  CigLoggingIn = "CIG_LOGGING_IN",
  None = "NONE",
}

export enum CigFlowState {
  StripeSetup = "STRIPE_SETUP",
  ExperiencesSetup = "EXPERIENCES_SETUP",
  ProfileSetup = "PROFILE_SETUP",
  PreFinished = "PRE_FINISHED",
  Finished = "FINISHED",
  Ignored = "IGNORED",
  Unknown = "UNKNOWN",
}
