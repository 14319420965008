import { CreateSessionValues } from "@components/calendar/manage/create/CreateSession";
import { Currency } from "@lib/enums/generic";
import { FormValidation } from "@lib/types/form";
import dayjs, { Dayjs } from "dayjs";
import { Validator } from "../Validator";

const createSessionValidators = {
  price: (validation: FormValidation, value: any, values: CreateSessionValues) => {
    new Validator(validation, value)
      .exists()
      .is("number")
      .skipNextIf(values.currency === Currency.CZK)
      .number.greaterThanOrEqualTo(10)
      .skipNextIf(values.currency !== Currency.CZK)
      .number.greaterThanOrEqualTo(15);
  },
  platformId: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string");
  },
  isCharity: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("boolean");
  },
  charityName: (validation: FormValidation, value: any, values: CreateSessionValues) => {
    new Validator(validation, value).is("string").skipIf(!values.isCharity).exists();
  },
  gameId: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string");
  },
  startDate: (validation: FormValidation, value: any) => {
    new Validator(validation, value)
      .exists()
      .instanceOf(dayjs)
      .custom((value: Dayjs) => {
        if (value.isBefore(dayjs())) return "Start cate cannot be in the past";
      });
  },
  endDate: (validation: FormValidation, value: any) => {
    new Validator(validation, value)
      .exists()
      .instanceOf(dayjs)
      .custom((value: Dayjs) => {
        if (value.isBefore(dayjs())) return "Start cate cannot be in the past";
      });
  },
  totalSlots: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("number");
  },
  repeatDays: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("array");
  },
  repeat: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("boolean");
  },
  repeatCount: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().canBe("number");
  },
  title: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(100);
  },
  message: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").length.lessThanOrEqualTo(500);
  },
};

export default createSessionValidators;
