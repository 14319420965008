import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createTipCheckout, { CreateTipCheckoutRes } from "../createTipCheckout";

const useCreateTipCheckout = (sessionID: string, amount: number) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<CreateTipCheckoutRes | null>(null);

  const send = useCallback(async () => {
    if (sessionID == null || amount == null) return reqState;
    setLoading();
    try {
      const data = await createTipCheckout(sessionID, amount);
      return setSuccess(data);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [sessionID, amount, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useCreateTipCheckout;
