import { api } from "@api/api";

export interface CreateReviewMessageRes {}

const createReview = async (availabilityID: string, showDisplayName?: boolean, review?: string) => {
  const { data } = await api.post<CreateReviewMessageRes>("/createReview", {
    availabilityID,
    showDisplayName,
    review,
  });
  return data;
};

export default createReview;
