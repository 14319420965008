import React from "react";
import { CSSProps } from "../../lib/types/generic";
import { FormValidation } from "@lib/types/form";
import FormError from "./FormError";

interface Props extends CSSProps {
  validation?: FormValidation;
}

export default function FormErrors(props: Props) {
  const { validation, ...cssProps } = props;

  if (validation == null) return null;

  const { failed, list } = validation;

  if (!failed) return null;

  return (
    <>
      {list.map(
        ({ text, type }, i) =>
          type === "error" && <FormError key={i} textOverride={text} {...cssProps} />,
      )}
    </>
  );
}
