import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const forgotPasswordValidators = {
  forgotPasswordCode: (validation: FormValidation, value: any) => {
    new Validator(validation, value)
      .exists()
      .is("string")
      .length.equalTo(6)
      .custom(() => {
        if (isNaN(parseInt(value))) return "Must be made up of numbers only";
      });
  },
};

export default forgotPasswordValidators;
