import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import React from "react";
import SelectExperienceContextProvider from "./context/SelectExperienceContext";
import ExperiencesManageSelectDesktop from "./ExperiencesManageSelectDesktop";
import ExperiencesManageSelectMobile from "./ExperiencesManageSelectMobile";

export default function ExperiencesManageSelect() {
  const { breakpoints } = useGlobalContext();

  return (
    <SelectExperienceContextProvider>
      {breakpoints.sm ? <ExperiencesManageSelectMobile /> : <ExperiencesManageSelectDesktop />}
    </SelectExperienceContextProvider>
  );
}
