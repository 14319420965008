import Form from "@components/form/Form";
import GAEvent from "@lib/utils/GAEvent";
import { createSuccessSnackbar } from "@lib/utils/generic";
import useAuthModalData from "@src/globalContext/hooks/useAuthModalData";
import useGlobalContext from "@src/globalContext/hooks/useGlobalContext";
import { useHistory } from "react-router-dom";
import { RegisterModalData } from "./context/RegisterContextProvider";
import useRegisterContext from "./context/useRegisterContext";
import EmailConfirmStep from "./steps/EmailConfirmStep";
import RegisterStep from "./steps/RegisterStep";
import UserExtensionStep from "./steps/UserExtensionStep";

export default function RegisterForm() {
  const history = useHistory();

  const { setAuthModal, pushSnackbar } = useGlobalContext();

  const { handles, resetValidations, validations, setStep, initialStep } = useRegisterContext();

  const { redirect } = useAuthModalData<RegisterModalData>();

  const [registerHandle, emailConfirmHandle, userExtensionHandle] = handles;

  return (
    <Form
      id="register-form"
      onStepChange={setStep}
      resetValidations={resetValidations}
      onCompletion={() => {
        setAuthModal(null);
        pushSnackbar(createSuccessSnackbar("Account created!", 5000));
        GAEvent.login("Email");
        history.push(redirect ?? "/");
      }}
      validation={validations.generic}
      initialStep={initialStep}
      triggerGlobalLoading
      steps={[
        {
          children: <RegisterStep />,
          handle: registerHandle,
        },
        {
          children: <EmailConfirmStep />,
          handle: emailConfirmHandle,
        },
        {
          children: <UserExtensionStep />,
          handle: userExtensionHandle,
        },
      ]}
    />
  );
}
