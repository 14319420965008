import { pathHasHeader } from "@lib/utils/generic";
import { useLocation } from "react-router-dom";

const useHasHeader = () => {
  const location = useLocation();

  const hasHeader = pathHasHeader(location.pathname);

  return hasHeader;
};

export default useHasHeader;
